define("backalley/pods/components/user-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    roleOptions: ['hub', 'retail', 'admin'],
    permissionOptions: ['user_management', 'lookback_access', 'feeds_advanced_settings', 'feeds_advanced_usage'],
    isNewUser: Ember.computed('user._id', function () {
      return !this.user._id;
    }),
    actions: {
      setRole(user, role) {
        user.set('role', role);

        if (role === 'hub') {
          user.set('permissions', []);
        }
      },

      generatePassword(user) {
        const password = btoa(Math.random()).slice(0, 10);
        Ember.setProperties(user, {
          password,
          password_confirmation: password
        });
      },

      setPermissions(user, permissions) {
        user.set('permissions', permissions);
      }

    }
  });

  _exports.default = _default;
});