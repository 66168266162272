define("backalley/pods/components/manufacturer-controls/component", ["exports", "ember-keyboard"], function (_exports, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    init() {
      this._super();

      this.set('keyboardActivated', true);
    },

    approveShortcut: Ember.on((0, _emberKeyboard.keyUp)('shift+KeyA'), function () {
      const status = {
        value: 'approved'
      };
      return this.updateManufacturerStatus(this.manufacturer, status);
    }),
    nextShortcut: Ember.on((0, _emberKeyboard.keyUp)('shift+KeyD'), function () {
      return this.triggerRefresh();
    }),
    approveAndNextShortcut: Ember.on((0, _emberKeyboard.keyUp)('shift+KeyW'), function () {
      const status = {
        value: 'approved'
      };
      return this.updateManufacturerStatusAndRefresh(this.manufacturer, status);
    }),
    mergeShortcut: Ember.on((0, _emberKeyboard.keyUp)('shift+KeyS'), function () {
      return this.merge();
    })
  });

  _exports.default = _default;
});