define("backalley/pods/components/order-edit/current-adjustment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1KO+RcfJ",
    "block": "{\"symbols\":[\"adjustment\"],\"statements\":[[7,\"p\",true],[10,\"class\",\"box-view-heading\"],[8],[0,\"Manual Adjustment For Order\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"adjustments\",\"manual_adjustments\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"box-pair\",\"main.manual_adjustments.edit\",[23,1,[\"_id\"]]]],{\"statements\":[[0,\"    \"],[1,[23,1,[\"adjustment_type\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/order-edit/current-adjustment/template.hbs"
    }
  });

  _exports.default = _default;
});