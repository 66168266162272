define("backalley/pods/products/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      readableType(type) {
        return type.split('_').map(i => i[0].toUpperCase() + i.substring(1)).join(' ');
      }

    }
  });

  _exports.default = _default;
});