define("backalley/pods/components/landing-page/partials/product-search/component", ["exports", "backalley/util/default-to", "backalley/util/escape-regex", "backalley/models/product"], function (_exports, _defaultTo, _escapeRegex, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    boutique: (0, _defaultTo.default)(undefined),
    searchResults: (0, _defaultTo.default)([]),
    onSelect: (0, _defaultTo.default)(() => {}),
    actions: {
      searchProducts(q) {
        if (Ember.isEmpty(q)) return;
        return _product.default.find({
          name: {
            $regex: `^${(0, _escapeRegex.default)(q)}`,
            $options: 'i'
          },
          limit: 10,
          'boutiques.boutique': this.boutique
        }).then(res => {
          Ember.set(this, 'searchResults', res);
        });
      }

    }
  });

  _exports.default = _default;
});