define("backalley/pods/components/shipping-group-configuration-raw-editor/component", ["exports", "papaparse", "ember-concurrency", "ember-concurrency-decorators", "backalley/models/shipping-group-configuration", "backalley/util/path-selector"], function (_exports, _papaparse, _emberConcurrency, _emberConcurrencyDecorators, _shippingGroupConfiguration, _pathSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _temp;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShippingGroupConfigurationRawEditor = (_dec = Ember.inject.service, _dec2 = Ember.computed('sgc', 'sgc.{__clientRevision,modifier_value_type,modifier_value_default}', 'sgc.path_groups_and_settings.@each.{modifier_value}'), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class ShippingGroupConfigurationRawEditor extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "notify", _descriptor, this);

      _defineProperty(this, "sgc", undefined);

      _defineProperty(this, "shippingPaths", undefined);

      _defineProperty(this, "onAcceptChanges", undefined);

      _defineProperty(this, "tidyGapInSpaces", 5);

      _defineProperty(this, "isEditable", false);

      _defineProperty(this, "_rawData", '');
    }

    get rawData() {
      // we don't want to run this expensive function on every change
      Ember.run.debounce(this, this.updateRawDataDebounced, this.sgc, 1000);
      return this._rawData;
    }

    set rawData(value) {
      this.set('_rawData', value);
      return value;
    }
    /**
     * It seems this was the best method -- allowing me to avoid the
     * use of an observer. We don't want this processing every key
     * press the user types in the main form.
     */


    updateRawDataDebounced(sgc) {
      this.set('rawData', this.flattenSGCToRawData(sgc));
    }
    /**
     * Take an SGC object and convert it down into a string with nice
     * spacing for display.
     */


    flattenSGCToRawData(sgc) {
      const data = sgc && sgc.path_groups_and_settings && sgc.path_groups_and_settings.map(pgas => {
        return {
          title: pgas.path_group.name,
          value: pgas.modifier_value,
          paths: (0, _pathSelector.convertPathCodesToPathSelector)(pgas.path_group.shipping_path_codes)
        };
      });
      return this.flattenCSVDataToRawData({
        data
      });
    }
    /**
     * Take the CSV data structure and convert it down into a string
     * with nice spacing for display.
     */


    flattenCSVDataToRawData({
      data = [],
      errors = []
    }) {
      if (errors.length) {
        this.notify.show('warning', errors.shift().message, 5000);
        return;
      }

      const keys = ['title', 'value', 'paths'];
      const maxLengths = keys.reduce((acc, key) => {
        acc[key] = -Infinity;
        return acc;
      }, {});
      data.forEach(row => {
        keys.forEach(key => {
          const val = String(row[key]).trim() + ',';
          const columnWidth = val.length + this.tidyGapInSpaces;

          if (columnWidth > maxLengths[key]) {
            maxLengths[key] = columnWidth;
          }
        });
      });

      const tidyRow = ({
        title,
        value,
        paths
      }) => `${title.trim()},`.padEnd(maxLengths.title) + `${value.trim()},`.padEnd(maxLengths.value) + `${paths.trim()},`;

      const tidyRows = data.map(tidyRow);
      tidyRows.unshift(tidyRow({
        title: 'title',
        value: 'value',
        paths: 'paths'
      }));
      return tidyRows.join('\n').trim();
    }
    /**
     * Before we can use the rawData in CSV parse, need to remove some
     * bits that can cause issues.
     */


    getRawDataForParse() {
      let safeData = this.rawData.replace(/\s{2,}/g, '');
      safeData = safeData.replace(/^.*title.+value.+paths.*\n/i, '');
      return 'title,value,paths,' + '\n' + safeData;
    }
    /**
     * The main workings behind the 'accept changes' button
     */


    convertRawDataIntoSGC(sgc) {
      return new Promise((res, rej) => {
        const replacementSgc = new _shippingGroupConfiguration.default(_objectSpread(_objectSpread({}, sgc.getAsRaw()), {}, {
          // wipe out the pgas
          path_groups_and_settings: []
        }));

        _papaparse.default.parse(this.getRawDataForParse(), {
          header: true,
          delimiter: ',',
          skipEmptyLines: true,
          error: error => rej(error),
          complete: async results => {
            const rawData = this.flattenCSVDataToRawData(results);
            results.data.forEach((row, i) => {
              const paths = this.shippingPaths.findByPathSelector(row.paths);
              replacementSgc.path_groups_and_settings[i] = {
                modifier_value: row.value,
                path_group: {
                  name: row.title,
                  shipping_path_codes: paths.map(path => path.path_codes)
                }
              };
            });
            res({
              replacementSgc,
              rawData
            });
          }
        });
      });
    }
    /**
     * The main workings behind the 'tidy' button
     */


    convertRawDataToTidyData(rawData) {
      return new Promise((res, rej) => {
        _papaparse.default.parse(rawData, {
          header: true,
          delimiter: ',',
          skipEmptyLines: true,
          error: async error => rej(error),
          complete: async results => {
            const rawData = this.flattenCSVDataToRawData(results);
            res(rawData);
          }
        });
      });
    }

    *tidyFormatting() {
      try {
        // give UI time to redraw before intensive parsing & update
        yield (0, _emberConcurrency.timeout)(100);
        const tidyData = yield this.convertRawDataToTidyData(this.getRawDataForParse());

        if (!tidyData) {
          this.notify.show('warning', 'unexpectedly given no tidy data to work with', 5000);
          return;
        }

        this.set('rawData', tidyData);
        this.notify.show('success', 'All tidied!', 5000);
      } catch (exception) {
        this.notify.show('warning', exception, 5000);
      }
    }

    *acceptChanges() {
      try {
        // give UI time to redraw before intensive parsing & update
        yield (0, _emberConcurrency.timeout)(100);
        const {
          replacementSgc,
          rawData
        } = yield this.convertRawDataIntoSGC(this.sgc);

        if (!replacementSgc || !rawData) {
          this.notify.show('warning', 'unexpectedly given no new SGC to work with', 5000);
          return;
        }

        this.set('rawData', rawData);
        this.onAcceptChanges && this.onAcceptChanges(replacementSgc);
        this.notify.show('success', 'Changes have been saved, close the modal to see', 5000);
      } catch (exception) {
        this.notify.show('warning', exception, 5000);
      }
    }

    onCSVImportFail(error) {
      this.notify.show('warning', error, 5000);
    }

    onCSVImportCompleted(results) {
      const rawData = this.flattenCSVDataToRawData(results);

      if (rawData) {
        this.set('rawData', rawData);
        this.notify.show('success', 'CSV loaded and parsed successfully', 5000);
      }
    }

    onCopySuccess() {
      this.notify.show('success', 'Copied Raw SGC Data');
    }

    onCopyFail() {
      this.notify.show('warning', 'Failed to copy');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "rawData", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "rawData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tidyFormatting", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "tidyFormatting"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "acceptChanges", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "acceptChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCSVImportFail", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onCSVImportFail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCSVImportCompleted", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onCSVImportCompleted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCopySuccess", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onCopySuccess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCopyFail", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onCopyFail"), _class.prototype)), _class));
  _exports.default = ShippingGroupConfigurationRawEditor;
});