define("backalley/pods/main/transactions-view/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TransactionsViewRoute extends Ember.Route {
    async model() {
      return {};
    }

  }

  _exports.default = TransactionsViewRoute;
});