define("backalley/pods/main/currency-statements/view/route", ["exports", "backalley/models/currency-statement"], function (_exports, _currencyStatement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      currency: {
        refreshModel: true
      },
      boutique: {
        refreshModel: true
      },
      period: {
        refreshModel: true
      },
      year: {
        refreshModel: true
      },
      regenerate: {
        refreshModel: true
      }
    },

    model({
      boutique,
      period,
      year,
      currency,
      regenerate
    }) {
      return _currencyStatement.default.find({
        period,
        year,
        boutique,
        currency,
        regenerate
      }).then(({
        currency_statements
      }) => {
        currency_statements[0].renderPreview();
        return currency_statements[0];
      });
    },

    actions: {
      previewStatement() {
        this.set('controller.editMode', false); // Re-render the statement

        this.currentModel.renderPreview();
      },

      generateStatement(statement) {
        return statement.save().then(() => {
          this.notify.show('success', 'Statement was generated successfully');
        }).catch(error => {
          this.notify.show('warning', error.responseJSON.errors[0].detail);
        });
      }

    }
  });

  _exports.default = _default;
});