define("backalley/util/matcher", ["exports", "lodash.matches", "fuse.js"], function (_exports, _lodash, _fuse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ObjectQuery = _exports.FuzzyMatch = void 0;

  const and = (...funcs) => args => funcs.every(fn => fn(args));
  /**
   * You're either part of the pattern, or part of the functional-checks!
   * It all forms a solution in the end...
   *
   * @param {object} matchObject - the object to use with lodash matches
   * @param {[function]} matchMethods - the list of additional function-based
   *   checks that matches() doesn't support.
   */


  function makeCombinedMatcher(matchObject, matchMethods) {
    const objectMatcher = (0, _lodash.default)(matchObject);
    return value => {
      return objectMatcher(value) && matchMethods.every(method => method(value));
    };
  }
  /**
   * Per each FuzzyMatch found in the query pattern, create a layer that
   * will process a fuzzy match against a particular key.
   */


  function buildListFuzzyFilter(fuzzyMatch, path) {
    return list => {
      const fuse = new _fuse.default(list, {
        includeScore: true,
        keys: [path]
      });

      const findMatch = value => {
        const matches = fuse.search(value).filter(({
          score
        }) => score < fuzzyMatch.maxScore).map(({
          item
        }) => item);

        if (fuzzyMatch.maxHits) {
          return matches.slice(0, fuzzyMatch.maxHits);
        }

        return matches;
      };

      if (Array.isArray(fuzzyMatch.value)) {
        return fuzzyMatch.value.map(findMatch);
      }

      return findMatch(fuzzyMatch.value);
    };
  }
  /**
   * Take a check function, and a string path. Combine that with a lodash
   * get. This can then run the check function against any passed in context
   * using the path as an attribute selector.
   *
   * @param {function} check
   * @param {string} path
   */


  function buildFunctionChecker(check, path) {
    return obj => check(Ember.get(obj, path));
  }
  /**
   * Same as the functional checker, but instead takes a regexp. It then
   * runs the regexp again against the passed in context using the path as
   * an attribute selector.
   *
   * @param {function} check
   * @param {string} path
   */


  function buildRegExpChecker(exp, path) {
    return obj => String(Ember.get(obj, path)).match(exp);
  }
  /**
   * Steps through a combination of object, with possible values set to
   * functions or regular expressions. If it detects either of those, it
   * removes them from the object and pushes a "checker function" into the
   * checks array based on that value. The remaining object is safe to use
   * with lodash.matches and the check functions can be run at the same time.
   * This gives us filtering/testing ability that supports both an object
   * pattern (for simple value tests), with internal function/exp checkers.
   *
   * @param {object} patternAndChecks
   * @param {string} path -- extends as we recurse deeper
   * @param {array} checks -- list of checks beyond deep equality matching
   * @param {array} perList -- list of checks to run on entire list
   */


  function buildMatcher(patternAndChecks, path, checks, perList) {
    const layer = {};
    Object.entries(patternAndChecks).forEach(([key, patternOrCheck]) => {
      const nextpath = path ? `${path}.${key}` : key;

      switch (true) {
        // specific FuzzyMatch instances
        case patternOrCheck instanceof FuzzyMatch:
          perList.push(buildListFuzzyFilter(patternOrCheck, nextpath));
          break;
        // regexps count as a check

        case patternOrCheck instanceof RegExp:
          checks.push(buildRegExpChecker(patternOrCheck, nextpath));
          break;
        // functions count as a check

        case typeof patternOrCheck === 'function':
          checks.push(buildFunctionChecker(patternOrCheck, nextpath));
          break;
        // any object could be an array or object, recurse

        case typeof patternOrCheck === 'object':
          if (patternOrCheck.join) {
            layer[key] = patternOrCheck.map(item => buildMatcher(item, nextpath, checks));
          } else {
            layer[key] = buildMatcher(patternOrCheck, nextpath, checks);
          }

          break;
        // all other things, count as part of the pattern

        default:
          layer[key] = patternOrCheck;
          break;
      }
    });
    return layer;
  }
  /**
   * Used as a flag to highlight particular keys that we want to fuzzy
   * match with Fuse. These have to be taken out of the pattern and checks
   * and run by themselves.
   *
   * @class
   * @classdesc creates and instance of FuzzyMatch
   */


  class FuzzyMatch {
    /**
     * @param {string|[string]} value - array values act as a multiplier
     *   in that the entire list will be re-searched for each value.
     *   Another way of putting this is that if you set maxHits to 1
     *   but pass three values. You will get a maximum of three results
     * @param {number} [maxScore] - Fuse uses scoring, the lower the
     *   score the more accurate the match, 0 being exact.
     * @param {number} [maxHits] - Fuse will return many results, in
     *   ascending score order, setting this will limit the number of
     *   results returned to the top N items.
     */
    constructor({
      value,
      maxScore = 0.3,
      maxHits = null
    }) {
      this.value = value;
      this.maxScore = maxScore;
      this.maxHits = maxHits;
    }

  }
  /**
   * Creates a matcher that supports value testers as functions. Lodash
   * gives us the pattern match for free. But the function/regexp handling
   * we need to add ourselves. Fuse gives us fuzzy matching for free too.
   *
   * @class
   * @classdesc creates and instance of ObjectQuery
   */


  _exports.FuzzyMatch = FuzzyMatch;

  class ObjectQuery {
    /**
     * @param {object} patternAndChecks - an partial matching object, any
     *   value as a function is treated as a test function, any value as a
     *   regexp is also treated as a test.
     */
    constructor(patternAndChecks) {
      this.perListFilter = null;
      this.perItemfilter = null;
      this.preprocess(patternAndChecks);
    }

    preprocess(patternAndChecks) {
      if (Array.isArray(patternAndChecks)) {
        this.perItemfilter = and(...patternAndChecks.map(this.preprocess.bind(this)));
        return;
      }

      if (patternAndChecks.call) {
        this.perItemfilter = patternAndChecks;
        return;
      }

      const path = '';
      const justPerList = [];
      const justChecks = [];
      const justPattern = buildMatcher(patternAndChecks, path, justChecks, justPerList);

      if (justPerList.length) {
        this.perListFilter = justPerList[0];
      }

      if (justChecks.length) {
        this.perItemfilter = makeCombinedMatcher(justPattern, justChecks);
        return;
      }

      this.perItemfilter = (0, _lodash.default)(justPattern);
      return;
    }

    performAgainst(list) {
      if (this.perItemfilter) {
        list = list.filter(this.perItemfilter);
      }

      if (this.perListFilter) {
        list = this.perListFilter(list);
      } // return the found items, removing empty spaces


      return list.filter(v => v);
    }

  }

  _exports.ObjectQuery = ObjectQuery;
  ObjectQuery.FuzzyMatch = FuzzyMatch;
});