define("backalley/pods/main/manufacturers/new/route", ["exports", "backalley/models/manufacturer"], function (_exports, _manufacturer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return {
        manufacturer: _manufacturer.default.create({
          name: '',
          alternate_names: []
        })
      };
    },

    actions: {
      create(manufacturer) {
        return manufacturer.post().then(() => {
          this.transitionTo('main.manufacturers');
        }).catch(message => {
          this.notify.show('error', message, 5000);
        });
      },

      goBack() {
        this.transitionTo('main.manufacturers');
      },

      removeAlternate(manufacturer, alternate) {
        manufacturer.get('alternate_names').removeObject(alternate);
      },

      addAlternate(manufacturer, alternate = '') {
        if (alternate.length < 3) return this.set('currentModel.alternateValidationError', 'Must be at least 3 characters');
        manufacturer.get('alternate_names').addObject(alternate);
        this.set('currentModel.alternate', '');
        this.set('currentModel.alternateValidationError', '');
      }

    }
  });

  _exports.default = _default;
});