define("backalley/models/common/path-or-lane-collection-base", ["exports", "backalley/util/matcher", "backalley/util/group-by", "backalley/util/path-selector", "backalley/enums/country-aliases"], function (_exports, _matcher, _groupBy, _pathSelector, _countryAliases) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  /**
   * Because Paths and Lanes share many behaviours, quite a bit of code to handle
   * them is best shared. This code is based around a collection of either paths
   * or lanes, and allows us to filter/search that data using path_codes but
   * also country names + fuzzy matching.
   * @class
   * @classdesc Forms the base, of shared methods, for a Path or Lane Collection
   */
  class PathOrLaneCollectionBase {
    /**
     * Convenience method to find paths by country based on name or
     * iso code. It sources its information from the current Shipping
     * path collection.
     *
     * @param {string|[string]} countryNameOrCode
     * @param {object} options
     * @param {boolean} options.isSupply - denotes if search supply,
     *   otherwise we search demand instead.
     * @return {[object]} list of found paths
     */
    findByCountryOrCode(countryNameOrCode, _ref = {}) {
      let {
        isSupply = true
      } = _ref,
          restQuery = _objectWithoutProperties(_ref, ["isSupply"]);

      if (!countryNameOrCode) {
        return [];
      }

      const query = (0, _countryAliases.checkAgainstCountryAliases)(countryNameOrCode);
      const prefix = isSupply ? 'source' : 'destination';

      const shouldBeTreatedAsIsoCode = query => query.length < 4;

      const calculateKey = query => shouldBeTreatedAsIsoCode(query) ? `${prefix}_country_code` : prefix;

      const performQuery = ([key, searchValue]) => {
        const calculatedQuery = new _matcher.ObjectQuery(_objectSpread(_objectSpread({}, restQuery), {}, {
          [key]: new _matcher.ObjectQuery.FuzzyMatch({
            value: searchValue,
            maxHits: 1
          })
        }));
        return calculatedQuery.performAgainst(this.list);
      };

      if (Array.isArray(query)) {
        const byKeys = (0, _groupBy.default)(query, calculateKey);
        return Object.entries(byKeys).map(performQuery).flat(2);
      }

      return performQuery([calculateKey(query), query]);
    }
    /**
     * Path selectors are just a simple expressions based around the
     * path code convention. See path-selector.js for more info.
     *
     * > ! This is a find method, it will only be able to find those
     *     paths that exist in this collection. Other codes are ignored.
     *
     * > ! Examples use iso codes, but country names are also supported.
     *     They operate on a fuzzy match basis.
     *
     * @param {string} pathSelector
     * @return {[object]} list of found paths
     */


    findByPathSelector(pathSelector) {
      const segments = (0, _pathSelector.pathSelectorSplitIntoSegments)(pathSelector);
      return segments.map(querySegment => this.findByPathSelectorSegment(querySegment)).flat(1).filter(v => v);
    }
    /**
     * Read the comment for findByPathSelector() the following function
     * handles a selector once it has been split per segment. If we
     * started with 'ITA>;>GBR;FRA>FRA|DEU' each item here is a segment:
     * - ITA>
     * - >GBR
     * - FRA>FRA|DEU
     *
     * @param {string} pathSelectorSegment
     * @return {[object]} list of found paths
     */


    findByPathSelectorSegment(pathSelectorSegment) {
      const parts = (0, _pathSelector.pathSelectorExplodeSegment)(pathSelectorSegment);

      if (parts.length === 1) {
        return this.findBySupplyAndDemandQuery(parts[0], parts[0], {
          is_domestic: true
        });
      }

      if (parts.length === 2 && parts[0] === '>') {
        return this.findBySupplyAndDemandQuery(null, parts[1]);
      }

      if (parts.length === 2 && parts[1] === '>') {
        return this.findBySupplyAndDemandQuery(parts[0], null);
      }

      if (parts.length === 3 && parts[1] === '>') {
        return this.findBySupplyAndDemandQuery(parts[0], parts[2]);
      }

      return [];
    }
    /**
     * Find paths based on specific queries for the supply or demand
     * country. Accepts ISO3 codes and fuzzy matching country names
     *
     * > ! IOS2 not yet fully supported.
     *
     * @param {string|null} [supplyQuery] - controls the supply search
     *   country name or iso code.
     * @param {string|null} [demandQuery] - controls the demand search
     *   country name or iso code.
     * @param {object} [filterQuery] - allows to tweak the final search
     *   this should be in object form and contain shipping path
     *   matchers. The same ones supported by filterByQuery().
     * @return {[object]} list of found paths
     */


    findBySupplyAndDemandQuery(supplyQuery, demandQuery, filterQuery = {}) {
      const isNotDomestic = supplyQuery !== demandQuery;
      const supply = isNotDomestic ? this.findByCountryOrCode(supplyQuery) : this.findByCountryOrCode(supplyQuery, {
        is_domestic: true
      });
      const demand = isNotDomestic ? this.findByCountryOrCode(demandQuery, {
        isSupply: false
      }) : supply;

      if (demandQuery && !demand.length) {
        return [];
      }

      if (supplyQuery && !supply.length) {
        return [];
      }

      const sourceCodes = Array.isArray(supply) ? supply.map(s => s.source_country_code) : [supply.source_country_code];
      const destinationCodes = Array.isArray(demand) ? demand.map(d => d.destination_country_code) : [demand.destination_country_code];

      const query = _objectSpread({}, filterQuery);

      if (supplyQuery && sourceCodes) {
        query.source_country_code = countryCode => sourceCodes.includes(countryCode);
      }

      if (demandQuery && destinationCodes) {
        query.destination_country_code = countryCode => destinationCodes.includes(countryCode);
      }

      const list = this.filterByQuery(query);

      if (!list || !list.length) {
        return [];
      }

      return list;
    }
    /**
     * Hooks into the ObjectQuery system, allowing us to search Paths
     * using object matching, regular expressions, function matchers
     * and fuzzy text search.
     *
     * @param {object} query
     * @return {[object]} list of remaining paths
     */


    filterByQuery(query) {
      const calculatedQuery = new _matcher.ObjectQuery(query);
      return calculatedQuery.performAgainst(this.list);
    }
    /**
     * Convenience method, considering we use this exact method of
     * finding paths and lanes for the PathGroupForm.
     *
     * @param {[string]} pathCodes
     * @return {[object]} list of remaining paths
     */


    filterByPathCodes(pathCodes) {
      const calculatedQuery = new _matcher.ObjectQuery({
        path_codes: pathCode => pathCodes.includes(pathCode)
      });
      return calculatedQuery.performAgainst(this.list);
    }

  }

  _exports.default = PathOrLaneCollectionBase;
});