define("backalley/pods/main/curate/categories/index/route", ["exports", "backalley/models/curate/category"], function (_exports, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MainCurateCategoriesIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        },
        sort: {
          refreshModel: true
        },
        status: {
          refreshModel: true
        },
        parent: {
          refreshModel: true
        },
        query: {
          refreshModel: true
        }
      });
    }

    model({
      page,
      limit,
      sort,
      status,
      parent,
      query
    }) {
      const queryParams = {
        page,
        limit,
        sort,
        status: Ember.isPresent(status) ? status : undefined,
        parent: parent === 'none' ? null : parent,
        populate: 'ancestors'
      };

      if (Ember.isPresent(query)) {
        const parsedQuery = parseInt(query, 10);
        const queryParam = Number.isNaN(parsedQuery) ? {
          free_text_search: query
        } : {
          google_shop_taxonomy_id: parsedQuery
        };
        Object.assign(queryParams, queryParam);
      }

      return _category.default.find(queryParams);
    }

  }

  _exports.default = MainCurateCategoriesIndexRoute;
});