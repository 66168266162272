define("backalley/pods/main/discounts/edit/route", ["exports", "moment", "backalley/models/currency", "backalley/models/discount", "backalley/enums/discount-type", "backalley/enums/discount-channel", "backalley/util/escape-regex", "backalley/models/manufacturer", "backalley/models/tag"], function (_exports, _moment, _currency, _discount, _discountType, _discountChannel, _escapeRegex, _manufacturer, _tag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const KEYCODE_ENTER = 13;

  var _default = Ember.Route.extend({
    async model({
      discount_id
    }) {
      const [gbpCurrency] = await _currency.default.find({
        iso_4217: 'GBP'
      });
      return _discount.default.findById(discount_id).then(discount => {
        return {
          discount,
          toPenceValue: discount.type === 'amount' ? discount.value / 100 : discount.value,
          toPenceMinimumValue: discount.minimum_value / 100,
          expired_at: discount.expired_at ? (0, _moment.default)(discount.expired_at).format('YYYY/MM/DD') : undefined,
          discountTypes: Object.values(_discountType.default),
          discountChannels: Object.values(_discountChannel.default),
          gbpCurrency
        };
      });
    },

    actions: {
      async searchManufacturers(query) {
        if (Ember.isEmpty(query)) {
          return;
        }

        ;
        const manufacturers = await _manufacturer.default.find({
          $text: {
            $search: query
          },
          archived: {
            $ne: true
          },
          limit: 10
        });
        return manufacturers;
      },

      async searchTags(query) {
        if (Ember.isEmpty(query)) {
          return;
        }

        const tags = await _tag.default.find({
          label: {
            $regex: `^${(0, _escapeRegex.default)(query)}`,
            $options: 'i'
          },
          limit: 10
        });
        return tags;
      },

      preventDefaultEnter(options, e) {
        if (e.keyCode === KEYCODE_ENTER) {
          e.preventDefault();
        }
      },

      updateChannel(value) {
        Ember.set(this.currentModel.discount, 'channel', value);
        Ember.set(this.currentModel.discount, 'currency', this.currentModel.gbpCurrency);
      },

      updateType(value) {
        Ember.set(this.currentModel.discount, 'type', value);
      },

      updateDiscount(discount) {
        if (this.currentModel.discount.type === 'amount') {
          discount.set('value', this.currentModel.toPenceValue * 100);
        } else {
          discount.set('value', this.currentModel.toPenceValue || 0);
        }

        discount.set('minimum_value', this.currentModel.toPenceMinimumValue * 100);

        if (this.currentModel.expired_at) {
          discount.set('expired_at', (0, _moment.default)(this.currentModel.expired_at, 'YYYY/MM/DD').toDate());
        }

        return discount.save().then(() => this.transitionTo('main.discounts')).catch(err => this.notify.show('err', err.message));
      }

    }
  });

  _exports.default = _default;
});