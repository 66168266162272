define("backalley/pods/main/manual-adjustments/edit/route", ["exports", "backalley/models/manual-adjustment"], function (_exports, _manualAdjustment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model({
      adjustment_id
    }) {
      const {
        manual_adjustments
      } = await _manualAdjustment.default.findWithMeta({
        _id: adjustment_id,
        populate: 'order boutique reservation created_by'
      });
      return {
        adjustment: manual_adjustments[0]
      };
    },

    actions: {
      onCompleteAdjustmentUpdate() {
        this.transitionTo('main.manual_adjustments.index');
      }

    }
  });

  _exports.default = _default;
});