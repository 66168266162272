define("backalley/pods/main/curate/orders/edit/route", ["exports", "backalley/models/curate/order"], function (_exports, _order) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      id
    }) {
      return _order.default.findById(id);
    }

  });

  _exports.default = _default;
});