define("backalley/pods/components/import-csv/component", ["exports", "papaparse"], function (_exports, _papaparse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    skipEmptyLines: true,
    header: true,
    uploading: false,
    uploadStatus: null,
    delimiter: '',

    onError() {},

    onComplete() {},

    onFinishUpload({
      uploadFailed
    } = {}) {
      this.set('uploadStatus', uploadFailed ? 'fail' : 'success');
    },

    filterEmptyValues(obj) {
      let newObj = {};
      Object.keys(obj).forEach(prop => {
        if (obj[prop]) {
          newObj[prop] = obj[prop];
        }
      });
      return newObj;
    },

    transform(results) {
      results.data = results.data.map(this.filterEmptyValues.bind(this));
      return results;
    },

    actions: {
      importCSV({
        target
      }) {
        const csvFile = target.files[0];
        this.set('uploading', true);

        _papaparse.default.parse(csvFile, {
          header: this.header,
          delimiter: this.delimiter,
          skipEmptyLines: this.skipEmptyLines,
          error: async err => {
            await this.onError(err);
            this.set('uploading', false);
            this.onFinishUpload({
              uploadFailed: true
            });
          },
          complete: async results => {
            await this.onComplete(this.transform(results));
            this.set('uploading', false);
            this.onFinishUpload();
          }
        });
      }

    }
  });

  _exports.default = _default;
});