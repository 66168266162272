define("backalley/pods/components/show-transactions-item/component", ["exports", "backalley/util/default-to", "backalley/models/transaction", "@streethub/calcs", "ember-concurrency"], function (_exports, _defaultTo, _transaction, _calcs, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isExpanded: (0, _defaultTo.default)(false),
    ordernumber: (0, _defaultTo.default)(null),
    boutiqueId: (0, _defaultTo.default)(null),
    transactions: (0, _defaultTo.default)([]),
    reservationBoutiqueCurrency: (0, _defaultTo.default)(''),
    totalShippingFees: (0, _defaultTo.default)(0),
    totalShippingReimbursements: (0, _defaultTo.default)(0),
    totalReservationsSales: (0, _defaultTo.default)(0),
    totalReservationCommissions: (0, _defaultTo.default)(0),
    isLoading: Ember.computed.alias('fetchTransactionsTask.isRunning'),
    classNames: ['show-transactions-container'],
    fetchTransactionsTask: (0, _emberConcurrency.task)(function* () {
      this.resetAmounts();
      const ordernumber = this.ordernumber;
      const boutiqueId = this.boutiqueId;
      const transactionResult = yield _transaction.default.find({
        entity_type: 'order',
        entity_id: ordernumber,
        target_type: 'boutique',
        target_id: boutiqueId
      });
      const transactions = transactionResult.transactions;
      yield this.set('transactions', transactions);
      yield this.calculateTotals(transactions);
    }).restartable(),

    resetAmounts() {
      this.setProperties({
        transactions: [],
        totalShippingFees: 0,
        totalShippingReimbursements: 0,
        totalReservationsSales: 0,
        totalReservationCommissions: 0
      });
    },

    calculateTotals(transactions) {
      this.setProperties({
        totalShippingFees: this.calculateTotal(transactions, 'shipping_fee'),
        totalShippingReimbursements: this.calculateTotal(transactions, 'shipping_reimbursement'),
        totalReservationsSales: this.calculateTotal(transactions, 'reservation'),
        totalReservationCommissions: this.calculateTotal(transactions, 'reservation_commission')
      });
    },

    calculateTotal(transactions, subEntityType) {
      return transactions.reduce((accummulator, transaction) => {
        if (transaction.sub_entity_type === subEntityType) {
          if (transaction.type === 'debit') {
            return _calcs.default.m`${accummulator} - (${transaction.amount} * ${transaction.quantity})`.toDP(0, 2).toNumber();
          } else {
            return _calcs.default.m`${accummulator} + (${transaction.amount} * ${transaction.quantity})`.toDP(0, 2).toNumber();
          }
        }

        return accummulator;
      }, 0);
    },

    actions: {
      toggleTransactions() {
        const isExpanded = this.isExpanded; // Fetch the transactions every time the toggle expands the section

        if (!isExpanded) {
          this.fetchTransactionsTask.perform();
        }

        this.toggleProperty('isExpanded');
      },

      forceRefresh() {
        const isExpanded = this.isExpanded;
        const isLoading = this.isLoading;

        if (isExpanded && !isLoading) {
          this.fetchTransactionsTask.perform();
        }
      }

    }
  });

  _exports.default = _default;
});