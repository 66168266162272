define("backalley/enums/country-aliases", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.checkAgainstCountryAliases = void 0;

  /**
   * We should fill this with any shortforms that people generally use
   * but that do not align well to either a code or fuzzy match on a name.
   */
  const countryAliases = {
    UK: 'GBR'
  };

  const checkAgainstCountryAliases = input => {
    const aka = input => {
      const inputUpper = input.toUpperCase();

      if (countryAliases[inputUpper]) {
        return countryAliases[inputUpper];
      }

      return input;
    };

    if (Array.isArray(input)) {
      return input.map(aka);
    }

    return aka(input);
  };

  _exports.checkAgainstCountryAliases = checkAgainstCountryAliases;
  var _default = countryAliases;
  _exports.default = _default;
});