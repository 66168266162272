define("backalley/pods/components/paths-and-lanes/new/upload/component", ["exports", "papaparse", "backalley/pods/components/paths-and-lanes/new/upload/validation", "backalley/enums/shipping-path-variant", "backalley/enums/path-variant", "backalley/enums/carrier-lane-variant", "backalley/enums/lane-variant", "backalley/models/shipping-path-release", "backalley/models/carrier-lane-release"], function (_exports, _papaparse, _validation, _shippingPathVariant, _pathVariant, _carrierLaneVariant, _laneVariant, _shippingPathRelease, _carrierLaneRelease) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const MAX_TAG_NAME_LENGTH = 25;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    country: Ember.inject.service(),
    isUploadingCsv: false,
    pathsUpload: {
      validationErrorMessages: undefined,
      processingErrorMessages: undefined
    },
    lanesUpload: {
      validationErrorMessages: undefined,
      processingErrorMessages: undefined
    },
    correlation: {
      isValid: false,
      errorMessages: undefined
    },

    async init() {
      this._super();

      const activeShippingPathRelease = await _shippingPathRelease.default.findActive();
      this.storeActiveShippingPathRelease(activeShippingPathRelease);

      if (!activeShippingPathRelease || !activeShippingPathRelease.length) {
        this.notify.show('warning', 'Loading the current Shipping Path active release.' + ' Please wait a few seconds. If the problem persists, contact the Product Team');
      }

      const activeCarrierLaneRelease = await _carrierLaneRelease.default.findActive();
      this.storeActiveCarrierLaneRelease(activeCarrierLaneRelease);

      if (!activeCarrierLaneRelease || !activeCarrierLaneRelease.length) {
        this.notify.show('warning', 'Loading the current Carrier Lane active release.' + ' Please wait a few seconds. If the problem persists, contact the Product Team');
      }
    },

    pathsFileNameTag: Ember.computed('pathsFileName', function () {
      if (!this.pathsFileName) {
        return '';
      }

      return this.pathsFileName.length > MAX_TAG_NAME_LENGTH ? this.pathsFileName.substring(0, MAX_TAG_NAME_LENGTH - 3) + '...' : this.pathsFileName;
    }),
    lanesFileNameTag: Ember.computed('lanesFileName', function () {
      if (!this.lanesFileName) {
        return '';
      }

      return this.lanesFileName.length > MAX_TAG_NAME_LENGTH ? this.lanesFileName.substring(0, MAX_TAG_NAME_LENGTH - 3) + '...' : this.lanesFileName;
    }),
    canValidateCorrelation: Ember.computed('isUploadingCsv', 'shippingPaths', 'carrierLanes', 'pathsUpload.{validationErrorMessages,processingErrorMessages}', 'lanesUpload.{validationErrorMessages,processingErrorMessages}', 'correlation.isValid', function () {
      const isCsvUploaded = !Ember.isEmpty(this.shippingPaths) || !Ember.isEmpty(this.carrierLanes);
      const hasUploadErrors = this.pathsUpload.validationErrorMessages || this.pathsUpload.processingErrorMessages || this.lanesUpload.validationErrorMessages || this.lanesUpload.processingErrorMessages;
      return !this.isUploadingCsv && isCsvUploaded && !hasUploadErrors && !this.correlation.isValid;
    }),
    canProceed: Ember.computed('isUploadingCsv', 'shippingPaths', 'carrierLanes', 'correlation.isValid', function () {
      const isCsvUploaded = !Ember.isEmpty(this.shippingPaths) || !Ember.isEmpty(this.carrierLanes);
      return !this.isUploadingCsv && isCsvUploaded && this.correlation.isValid;
    }),

    onErrorImportCsv(fileName) {
      this.set('isUploadingCsv', false);
      this.notify.show('warning', `Failed to upload CSV '${fileName}'. Please try again`);
    },

    async importCsv({
      target
    }, onComplete, onError) {
      const csvFile = target.files[0];
      this.setProperties({
        isUploadingCsv: true
      });

      _papaparse.default.parse(csvFile, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: false,
        error: Ember.run.bind(this, onError, csvFile.name),
        complete: Ember.run.bind(this, onComplete, csvFile.name)
      });
      /**
       * Allow uploading the same file twice in a row
       */


      target.value = null;
    },

    convertPathFields(path) {
      return _objectSpread(_objectSpread({}, path), {}, {
        additional_product_margin: path.additional_product_margin ? Number(path.additional_product_margin) : null,
        additional_shipping_fee: path.additional_shipping_fee ? Number(path.additional_shipping_fee) : 0,
        // @TODO replace 0 with null once this field is fully supported
        additional_shipping_fee_currency: path.additional_shipping_fee_currency ? String(path.additional_shipping_fee_currency) : 'GBP',
        // @TODO replace 'GBP' with null once this field is fully supported
        christmas_cost_cap: path.christmas_cost_cap ? Number(path.christmas_cost_cap) : null,
        christmas_enabled: path.christmas_enabled ? path.christmas_enabled.toLowerCase() === 'true' : false,
        christmas_has_cost_cap: path.christmas_has_cost_cap ? path.christmas_has_cost_cap.toLowerCase() === 'true' : null,
        christmas_transit_time_max_days: path.christmas_transit_time_max_days ? Number(path.christmas_transit_time_max_days) : null,
        christmas_transit_time_min_days: path.christmas_transit_time_min_days ? Number(path.christmas_transit_time_min_days) : null,
        christmas_unit_cost: path.christmas_unit_cost ? Number(path.christmas_unit_cost) : null,
        cost_cap: path.cost_cap ? Number(path.cost_cap) : null,
        cost_curve_base_rate: path.cost_curve_base_rate ? Number(path.cost_curve_base_rate) : null,
        enabled: path.enabled ? path.enabled.toLowerCase() === 'true' : false,
        express_cost_cap: path.express_cost_cap ? Number(path.express_cost_cap) : null,
        express_enabled: path.express_enabled ? path.express_enabled.toLowerCase() === 'true' : false,
        express_has_cost_cap: path.express_has_cost_cap ? path.express_has_cost_cap.toLowerCase() === 'true' : null,
        express_transit_time_max_days: path.express_transit_time_max_days ? Number(path.express_transit_time_max_days) : null,
        express_transit_time_min_days: path.express_transit_time_min_days ? Number(path.express_transit_time_min_days) : null,
        express_unit_cost: path.express_unit_cost ? Number(path.express_unit_cost) : null,
        free_shipping_available: path.free_shipping_available ? path.free_shipping_available.toLowerCase() === 'true' : false,
        free_shipping_threshold: path.free_shipping_threshold ? Number(path.free_shipping_threshold) : null,
        has_click_and_collect: path.has_click_and_collect ? path.has_click_and_collect.toLowerCase() === 'true' : false,
        has_cost_cap: path.has_cost_cap ? path.has_cost_cap.toLowerCase() === 'true' : false,
        has_marketplace_rule: path.has_marketplace_rule ? path.has_marketplace_rule.toLowerCase() === 'true' : false,
        is_domestic: path.is_domestic ? path.is_domestic.toLowerCase() === 'true' : false,
        is_standard_disabled: path.is_standard_disabled ? path.is_standard_disabled.toLowerCase() === 'true' : false,
        transit_time_max_days: path.transit_time_max_days ? Number(path.transit_time_max_days) : null,
        transit_time_min_days: path.transit_time_min_days ? Number(path.transit_time_min_days) : null,
        unit_cost: path.unit_cost ? Number(path.unit_cost) : null,
        upsap_cost_cap: path.upsap_cost_cap ? Number(path.upsap_cost_cap) : null,
        upsap_enabled: path.upsap_enabled ? path.upsap_enabled.toLowerCase() === 'true' : false,
        upsap_has_cost_cap: path.upsap_has_cost_cap ? path.upsap_has_cost_cap.toLowerCase() === 'true' : null,
        upsap_transit_time_max_days: path.upsap_transit_time_max_days ? Number(path.upsap_transit_time_max_days) : null,
        upsap_transit_time_min_days: path.upsap_transit_time_min_days ? Number(path.upsap_transit_time_min_days) : null,
        upsap_unit_cost: path.upsap_unit_cost ? Number(path.upsap_unit_cost) : null
      });
    },

    preprocessPathsCsv(data) {
      if (!data || !data.length) {
        return data;
      }

      const preprocessedData = data.filter(row => String(row.ignore).toLowerCase() !== 'true') // Use 'ignore' field to skip rows
      .map(row => this.convertPathFields(row));
      return preprocessedData;
    },

    processPathsCsv(data) {
      let shippingPaths;
      const preprocessedData = this.preprocessPathsCsv(data);

      try {
        const pathsFileValidation = (0, _validation.validatePathsFile)(preprocessedData, this.country);
        const invalidPathCount = Object.keys(pathsFileValidation.errorsByPath).length;

        if (invalidPathCount) {
          this.notify.show('warning', `Found ${invalidPathCount} invalid ${invalidPathCount > 1 ? 'paths' : 'path'}`);
          this.set('pathsUpload.validationErrorMessages', this.convertProcessingErrorsToDisplayMessages(pathsFileValidation.errorsByPath, 'path'));
          return;
        }
      } catch (error) {
        this.notify.show('warning', `Failed to upload file. ${error}`);
        return;
      }

      try {
        shippingPaths = this.createShippingPaths(preprocessedData);
      } catch (err) {
        this.set('pathsUpload.processingErrorMessages', err.details && err.details.map(detail => detail.message).join('\n') || 'Unexpected error processing Shipping Paths. Please contact the Product team.');
      }

      const pathsValidation = (0, _validation.validateShippingPaths)(shippingPaths);

      if (pathsValidation.error) {
        this.notify.show('warning', 'Unexpected error');
        this.set('pathsUpload.processingErrorMessages', pathsValidation.error.details && pathsValidation.error.details.map(detail => detail.message).join('\n') || 'Unexpected error processing Shipping Paths. Please contact the Product team.');
        return;
      }

      return shippingPaths;
    },

    createShippingPaths(paths) {
      const shippingPaths = [];
      const errors = [];
      paths.forEach(path => {
        if (!path.enabled) {
          // Ignore disabled paths
          return;
        }

        const sourceCountry = this.country.findCountryByProperty('iso_code_alpha_3', path.source_country_code);
        const destinationCountry = this.country.findCountryByProperty('iso_code_alpha_3', path.destination_country_code);

        if (!sourceCountry) {
          errors.push({
            message: `Source Country code '${path.source_country_code}' from lane ${path.path_codes} is not recognized`
          });
          return;
        }

        if (!destinationCountry) {
          errors.push({
            message: `Destination Country code '${path.destination_country_code}' from path ${path.path_codes} is not recognized`
          });
          return;
        }

        const newShippingPath = {
          source: sourceCountry.short_name || sourceCountry.weengs_name || sourceCountry.name,
          destination: destinationCountry.short_name || destinationCountry.weengs_name || destinationCountry.name,
          source_country_id: sourceCountry._id,
          destination_country_id: destinationCountry._id,
          source_country_code: sourceCountry.iso_code_alpha_3,
          source_country_code_iso_2: sourceCountry.iso_code,
          destination_country_code: destinationCountry.iso_code_alpha_3,
          destination_country_code_iso_2: destinationCountry.iso_code,
          path_codes: path.path_codes,
          is_standard_disabled: path.is_standard_disabled,
          unit_cost: path.unit_cost,
          is_domestic: path.is_domestic,
          has_cost_cap: path.has_cost_cap,
          cost_cap: Number.isFinite(path.cost_cap) ? path.cost_cap : undefined,
          free_shipping_available: path.free_shipping_available,
          free_shipping_threshold: Number.isFinite(path.free_shipping_threshold) ? path.free_shipping_threshold : 0,
          free_shipping_threshold_currency: path.free_shipping_threshold_currency ? path.free_shipping_threshold_currency : undefined,
          transit_time_min_days: path.transit_time_min_days,
          transit_time_max_days: path.transit_time_max_days,
          additional_product_margin: path.additional_product_margin,
          additional_shipping_fee: path.additional_shipping_fee,
          additional_shipping_fee_currency: path.additional_shipping_fee_currency,
          cost_curve_base_rate: path.cost_curve_base_rate,
          has_express_shipping: path.express_enabled,
          has_click_and_collect: path.has_click_and_collect,
          express_shipping_unit_cost: path.express_unit_cost ? path.express_unit_cost : undefined,
          express_shipping_transit_min_days_taken: path.express_transit_time_min_days ? path.express_transit_time_min_days : undefined,
          express_shipping_transit_max_days_taken: path.express_transit_time_max_days ? path.express_transit_time_max_days : undefined,
          has_marketplace_rule: path.has_marketplace_rule,
          incoterm: path.incoterm || ''
        };
        const shippingPathVariantToPathVariantMapping = {};
        Object.keys(_shippingPathVariant.default).forEach(variant => shippingPathVariantToPathVariantMapping[_shippingPathVariant.default[variant]] = _pathVariant.default[variant]);
        Object.keys(shippingPathVariantToPathVariantMapping).forEach(shippingPathVariant => {
          const pathVariant = shippingPathVariantToPathVariantMapping[shippingPathVariant];

          if (path[`${pathVariant}_enabled`]) {
            newShippingPath[shippingPathVariant] = {
              enabled: path[`${pathVariant}_enabled`],
              unit_cost: path[`${pathVariant}_unit_cost`],
              has_cost_cap: path[`${pathVariant}_has_cost_cap`],
              cost_cap: Number.isFinite(path[`${pathVariant}_cost_cap`]) ? path[`${pathVariant}_cost_cap`] : undefined,
              transit_time_min_days: path[`${pathVariant}_transit_time_min_days`],
              transit_time_max_days: path[`${pathVariant}_transit_time_max_days`]
            };
          }
        });
        shippingPaths.push(newShippingPath);
      });

      if (errors.length) {
        const error = new Error(`Found ${errors.length} processing errors`);
        error.details = errors;
        throw error;
      }

      return shippingPaths;
    },

    convertLaneFields(lane) {
      return _objectSpread(_objectSpread({}, lane), {}, {
        christmas_enabled: lane.christmas_enabled.toLowerCase() === 'true',
        enabled: lane.enabled.toLowerCase() === 'true',
        express_enabled: lane.express_enabled.toLowerCase() === 'true',
        express_max_allowed_pence: lane.express_max_allowed_pence ? Number(lane.express_max_allowed_pence) : null,
        extra_shipping_enabled: lane.extra_shipping_enabled.toLowerCase() === 'true',
        extra_shipping_max_allowed_pence: lane.extra_shipping_max_allowed_pence ? Number(lane.extra_shipping_max_allowed_pence) : null,
        force_easypost_return: lane.force_easypost_return.toLowerCase() === 'true',
        manual_booking_only: lane.manual_booking_only.toLowerCase() === 'true',
        max_allowed_pence: lane.max_allowed_pence ? Number(lane.max_allowed_pence) : null,
        no_fallback: lane.no_fallback.toLowerCase() === 'true',
        skip_pickup_failure: lane.skip_pickup_failure.toLowerCase() === 'true',
        upsap_enabled: lane.upsap_enabled.toLowerCase() === 'true',
        upsap_max_allowed_pence: lane.upsap_max_allowed_pence ? Number(lane.upsap_max_allowed_pence) : null
      });
    },

    preprocessLanesCsv(data) {
      if (!data || !data.length) {
        return data;
      }

      const preprocessedData = data.filter(row => String(row.ignore).toLowerCase() !== 'true') // Use 'ignore' field to skip rows
      .map(lane => this.convertLaneFields(lane));
      return preprocessedData;
    },

    processCarrierLanesCsv(data) {
      let carrierLanes;
      const preprocessedData = this.preprocessLanesCsv(data);

      try {
        const lanesFileValidation = (0, _validation.validateLanesFile)(preprocessedData, this.country);
        const invalidLaneCount = Object.keys(lanesFileValidation.errorsByLane).length;

        if (invalidLaneCount) {
          this.notify.show('warning', `Found ${invalidLaneCount} invalid ${invalidLaneCount > 1 ? 'lanes' : 'lane'}`);
          this.set('lanesUpload.validationErrorMessages', this.convertProcessingErrorsToDisplayMessages(lanesFileValidation.errorsByLane, 'lane'));
          return;
        }
      } catch (error) {
        this.notify.show('warning', `Failed to upload file. ${error}`);
        return;
      }

      try {
        carrierLanes = this.createCarrierLanes(preprocessedData);
      } catch (err) {
        this.set('lanesUpload.processingErrorMessages', err.details && err.details.map(detail => detail.message).join('\n') || 'Unexpected error processing Carrier Lanes. Please contact the Product team.');
        return;
      }

      const lanesValidation = (0, _validation.validateCarrierLanes)(carrierLanes);

      if (lanesValidation.error) {
        this.notify.show('warning', 'Unexpected error');
        this.set('lanesUpload.processingErrorMessages', lanesValidation.error.details && lanesValidation.error.details.map(detail => detail.message).join('\n') || 'Unexpected error processing Carrier Lanes. Please contact the Product team.');
        return;
      }

      return carrierLanes;
    },

    createCarrierLanes(lanes) {
      const carrierLanes = [];
      const errors = [];
      lanes.forEach(lane => {
        if (!lane.enabled) {
          return;
        }

        const sourceCountry = this.country.findCountryByProperty('iso_code_alpha_3', lane.source_country_code);
        const destinationCountry = this.country.findCountryByProperty('iso_code_alpha_3', lane.destination_country_code);

        if (!sourceCountry) {
          errors.push({
            message: `Source Country code '${lane.source_country_code}' from lane ${lane.path_codes} is not recognized`
          });
          return;
        }

        if (!destinationCountry) {
          errors.push({
            message: `Destination Country code '${lane.destination_country_code}' from lane ${lane.path_codes} is not recognized`
          });
          return;
        }

        const newCarrierLane = {
          enabled: true,
          source: sourceCountry.short_name || sourceCountry.weengs_name || sourceCountry.name,
          destination: destinationCountry.short_name || destinationCountry.weengs_name || destinationCountry.name,
          source_country_id: sourceCountry._id,
          destination_country_id: destinationCountry._id,
          source_country_code: sourceCountry.iso_code_alpha_3,
          source_country_code_iso_2: sourceCountry.iso_code,
          destination_country_code: destinationCountry.iso_code_alpha_3,
          destination_country_code_iso_2: destinationCountry.iso_code,
          path_codes: lane.path_codes,
          provider: lane.provider,
          carrier: lane.carrier,
          service_id: lane.service_id,
          rate_2kg: lane.rate_2kg,
          manual_booking_only: lane.manual_booking_only,
          no_fallback: lane.no_fallback,
          carrier_account: lane.carrier_account,
          force_easypost_return: lane.force_easypost_return,
          skip_pickup_failure: lane.skip_pickup_failure,
          max_allowed_pence: lane.max_allowed_pence
        };
        const carrierLaneVariantToLaneVariantMapping = {};
        Object.keys(_carrierLaneVariant.default).forEach(variant => carrierLaneVariantToLaneVariantMapping[_carrierLaneVariant.default[variant]] = _laneVariant.default[variant]);
        Object.keys(carrierLaneVariantToLaneVariantMapping).forEach(carrierLaneVariant => {
          const laneVariant = carrierLaneVariantToLaneVariantMapping[carrierLaneVariant];

          if (lane[`${laneVariant}_enabled`]) {
            newCarrierLane[carrierLaneVariant] = {
              provider: lane[`${laneVariant}_provider`],
              carrier: lane[`${laneVariant}_carrier`],
              service_id: lane[`${laneVariant}_service_id`],
              max_allowed_pence: lane[`${laneVariant}_max_allowed_pence`],
              carrier_account: lane[`${laneVariant}_carrier_account`]
            };

            if (laneVariant === _laneVariant.default.UPS_AP) {
              newCarrierLane[carrierLaneVariant].carrier = 'UPS';
              newCarrierLane[carrierLaneVariant].provider = 'upsap';
            } // Remove keys with undefined value, which are picked up by Joi validation


            Object.keys(newCarrierLane[carrierLaneVariant]).forEach(key => newCarrierLane[carrierLaneVariant][key] === undefined ? delete newCarrierLane[carrierLaneVariant][key] : {});
          }
        });
        carrierLanes.push(newCarrierLane);
      });

      if (errors.length) {
        const error = new Error(`Found ${errors.length} processing errors`);
        error.details = errors;
        throw error;
      }

      return carrierLanes;
    },

    onCompleteImportPathsCsv(fileName, results) {
      const {
        data
      } = results;

      if (Ember.isEmpty(data)) {
        this.notify.show('warning', 'Unable to read content from file. Confirm file is not empty and try again');
        return;
      }

      const shippingPaths = this.processPathsCsv(data);
      this.storeShippingPaths(shippingPaths, fileName);
      this.set('isUploadingCsv', false);
    },

    onCompleteImportLanesCsv(fileName, results) {
      const {
        data
      } = results;

      if (Ember.isEmpty(data)) {
        this.notify.show('warning', 'Unable to read content from file. Confirm file is not empty and try again');
        return;
      }

      const carrierLanes = this.processCarrierLanesCsv(data);
      this.storeCarrierLanes(carrierLanes, fileName);
      this.set('isUploadingCsv', false);
    },

    convertValidationErrorsToDisplayMessages(validationErrors) {
      if (!validationErrors) {
        return;
      }

      return validationErrors.join('\n\n');
    },

    /**
     * Validates whether Shipping Paths and Carrier Lanes are consistent with each other.
     * If one of them hasn't been uploaded, then the current active release is used.
     *
     * We currently only block Shipping Path (variants) that don't have the corresponding lane
     * (variant) enabled.
     */
    validateCorrelation() {
      this.set('correlation.errorMessages', undefined);
      const shippingPathsToValidate = this.shippingPaths ? this.shippingPaths : this.activeShippingPaths;
      const carrierLanesToValidate = this.carrierLanes ? this.carrierLanes : this.activeCarrierLanes;
      let blockCorrelationValidation = false;

      if (!shippingPathsToValidate || !shippingPathsToValidate.length) {
        this.notify.show('warning', 'Loading the current Shipping Path active release.' + ' Please wait a few seconds. If the problem persists, contact the Product Team');
        blockCorrelationValidation = true;
      }

      if (!carrierLanesToValidate || !carrierLanesToValidate.length) {
        this.notify.show('warning', 'Loading the current Carrier Lane active release.' + ' Please wait a few seconds. If the problem persists, contact the Product Team');
        blockCorrelationValidation = true;
      }

      if (blockCorrelationValidation) {
        return;
      }

      const getCLVariantEquivalentToSPVariant = spVariant => {
        for (const variantKey in _shippingPathVariant.default) {
          if (_shippingPathVariant.default[variantKey] === spVariant) {
            return _carrierLaneVariant.default[variantKey];
          }
        }

        return;
      };

      const correlationErrors = [];
      shippingPathsToValidate.forEach(sp => {
        const cl = carrierLanesToValidate.find(cl => cl.path_codes === sp.path_codes);

        if (!cl) {
          correlationErrors.push(`Path ${sp.path_codes}: Shipping Path is enabled but Carrier Lane is not`);
          return;
        }

        Object.values(_shippingPathVariant.default).forEach(spVariant => {
          if (!sp[spVariant] || !sp[spVariant].enabled) {
            return;
          }

          const clVariant = getCLVariantEquivalentToSPVariant(spVariant);

          if (!cl[clVariant]) {
            correlationErrors.push(`Path ${sp.path_codes}: Shipping Path's variant ${spVariant} is enabled but Carrier Lane's variant ${clVariant} is not`);
          }
        });
      });
      const errorMessages = this.convertValidationErrorsToDisplayMessages(correlationErrors);

      if (errorMessages) {
        this.set('correlation.errorMessages', errorMessages);
        this.set('correlation.isValid', false);
        this.notify.show('warning', 'Shipping Paths and Carrier Lanes do not correlate');
        return;
      }

      this.set('correlation.isValid', true);
      this.notify.show('success', 'Shipping Paths and Carrier Lanes successfully correlate');
    },

    resetPaths() {
      this.set('correlation.isValid', false);
      this.set('pathsUpload.validationErrorMessages', undefined);
      this.set('pathsUpload.processingErrorMessages', undefined);
      this.storeShippingPaths(undefined, undefined);
    },

    resetLanes() {
      this.set('correlation.isValid', false);
      this.set('lanesUpload.validationErrorMessages', undefined);
      this.set('lanesUpload.processingErrorMessages', undefined);
      this.storeCarrierLanes(undefined, undefined);
    },

    resetCorrelation() {
      this.set('correlation.isValid', false);
      this.set('correlation.errorMessages', undefined);
    },

    /**
     *
     * @param {Object[]} validationErrors
     * @param {Object} validationErrors[].[pathCode] - error to be processed,
     * whose key is the Path Code that identifies the path/lane
     * @param {String} entity - expecting "path" or "lane", which will be included
     * in error messages
     */
    convertProcessingErrorsToDisplayMessages(validationErrors, entity = 'path') {
      const prettifyMessage = message => {
        return message.replace(/must be (one of \[null\]|\[\])/, 'must be empty');
      };

      let displayMessage = '';

      for (const pathCode in validationErrors) {
        displayMessage += `${Ember.String.capitalize(entity)} ${pathCode} is invalid:\n` + `  ‣ ${validationErrors[pathCode].map(error => prettifyMessage(error)).join('\n  ‣ ')}` + '\n\n';
      }

      return displayMessage;
    },

    actions: {
      importPathsCsv(ev) {
        this.resetPaths();
        this.resetCorrelation();
        return this.importCsv(ev, this.onCompleteImportPathsCsv, this.onErrorImportCsv);
      },

      importLanesCsv(ev) {
        this.resetLanes();
        this.resetCorrelation();
        return this.importCsv(ev, this.onCompleteImportLanesCsv, this.onErrorImportCsv);
      },

      completeStep() {
        let blockUser = false;

        if (!this.activeShippingPaths || !this.activeShippingPaths.length) {
          this.notify.show('warning', 'Loading the current Shipping Path active release.' + ' Please wait a few seconds. If the problem persists, contact the Product Team');
          blockUser = true;
        }

        if (!this.activeCarrierLanes || !this.activeCarrierLanes.length) {
          this.notify.show('warning', 'Loading the current Carrier Lane active release.' + ' Please wait a few seconds. If the problem persists, contact the Product Team');
          blockUser = true;
        }

        if (blockUser) {
          return;
        }

        return this.completeStep();
      },

      validateCorrelation() {
        this.validateCorrelation();
      },

      resetPaths() {
        this.resetCorrelation();
        this.resetPaths();
      },

      resetLanes() {
        this.resetCorrelation();
        this.resetLanes();
      }

    }
  });

  _exports.default = _default;
});