define("backalley/models/googleimage", ["exports", "backalley/mixins/request", "backalley/config/environment"], function (_exports, _request, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    GOOGLE_IMAGE_SEARCH_API_KEY,
    GOOGLE_SEARCH_ENGINE_ID,
    GOOGLE_API_URL
  } = _environment.default;
  const Googleimage = Ember.Object.extend(_request.default, {
    find(term) {
      const params = [`key=${GOOGLE_IMAGE_SEARCH_API_KEY}`, `cx=${GOOGLE_SEARCH_ENGINE_ID}`, `q=${term}`, 'searchType=image', 'imgsz=medium'];
      const url = `${GOOGLE_API_URL}${params.join('&')}`;
      return this.request({
        method: 'GET',
        url
      }).then(({
        items = []
      }) => {
        const buffer = [];
        items.forEach(item => {
          if (item.mime !== 'image') {
            buffer.push(item.link);
          }
        });
        return buffer;
      }).catch(err => {
        console.error(err);
      });
    }

  });
  Googleimage.reopenClass(_request.default, {});
  var _default = Googleimage;
  _exports.default = _default;
});