define("backalley/services/notify", ["exports", "backalley/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    notifications: Ember.A([]),

    /**
     * Show message for the specified time
     * @param  {<String>'success'|'danger'} Type
     * @param  {String} message
     * @param  {Number} timeout Optional
     */
    show(type, message, timeout = 2500) {
      var notification = {
        type: type,
        message: message
      };
      if (_environment.default.environment === 'test') timeout = 10;
      this.notifications.addObject(notification);
      if (timeout) Ember.run.later(() => this.hide(notification), timeout);
    },

    showError(message, timeout) {
      this.show('error', message, timeout);
    },

    showWarning(message, timeout) {
      this.show('warn', message, timeout);
    },

    showSuccess(message, timeout) {
      this.show('success', message, timeout);
    },

    /**
     * Hide message
     * @param  {notification} message
     */
    hide(message) {
      let notifications = this.notifications;
      if (notifications) notifications.removeObject(message);
    }

  });

  _exports.default = _default;
});