define("backalley/pods/main/landing/new/actions", ["exports", "backalley/models/landing", "backalley/config/environment", "backalley/util/clone-deep"], function (_exports, _landing, _environment, _cloneDeep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.set = set;
  _exports.remove = remove;
  _exports.removeRow = removeRow;
  _exports.setWithLimit = setWithLimit;
  _exports.addRow = addRow;
  _exports.selectEntry = selectEntry;
  _exports.setEntryType = setEntryType;
  _exports.setBoutiqueOnEntry = setBoutiqueOnEntry;
  _exports.selectLink = selectLink;
  _exports.duplicatePage = duplicatePage;
  _exports.savePageAndPreview = savePageAndPreview;
  _exports.savePage = savePage;
  _exports.removePage = removePage;

  function set(obj, k, v) {
    Ember.set(obj, k, v);
  }

  function remove(arr, obj) {
    arr.removeObject(obj);
  }

  function removeRow(arr, obj) {
    remove(arr, obj); // Unselect row

    this.setProperties({
      "currentModel.selectedRow": undefined,
      "currentModel.selectedEntryType": undefined
    });
  }

  function setWithLimit(limit, object, key, value) {
    const valueToSet = value.length < limit ? value : value.slice(0, limit);
    object.set(key, valueToSet);
  }

  function addRow(arr, obj) {
    arr.addObject(Ember.Object.create((0, _cloneDeep.default)(obj))); // select the first column in the new row for editing

    selectEntry.call(this, this.get("currentModel.page.itemArr.lastObject"), this.get("currentModel.page.itemArr.lastObject.itemArr.firstObject"));
  }

  function selectEntry(row, entry) {
    this.set("currentModel.selectedEntry", entry);
    this.set("currentModel.selectedRow", row);
    let rows = this.get("currentModel.page.itemArr"); // deselect rows

    rows.forEach(row => {
      row.get("itemArr").forEach(entry => {
        set(entry, "selected", false); // deselect links in entry as well

        if (Ember.get(entry, "content.length")) Ember.get(entry, "content").setEach("selected", false);
      });
      row.set("selected", false);
    }); // set active class for row and entry

    row.set("selected", true);
    set(entry, "selected", true); // set type of entry

    this.set("currentModel.selectedEntryType", Ember.get(entry, "type")); // set type of linked content if this entry alread has some

    this.set("currentModel.selectedLinkTypeValue", Ember.get(entry, "content.type"));
  }

  function setEntryType(type) {
    const clonedType = (0, _cloneDeep.default)(type); // set type of entry: box, list, boutique

    this.set("currentModel.selectedEntryType", type.value.type); // set if the entry is a box, list, boutique

    this.set("currentModel.selectedEntry.type", clonedType.value.type); // // // set appropriate content for the entry based on the type

    this.set("currentModel.selectedEntry.content", clonedType.value.content); // clear selected Link

    this.set("currentModel.selectedListLink", Ember.Object.create());
  }

  function setBoutiqueOnEntry(link) {
    this.set("currentModel.selectedEntry.content.id", link._id);
    this.set("currentModel.selectedEntry.content.type", "boutique");
    this.set("currentModel.selectedEntry.content.name", link.name);
    this.set("currentModel.selectedEntry.content.title", link.name);
    this.set("currentModel.selectedEntry.content.link_to_title", link.name);
    this.set("currentModel.selectedEntry.content.description", link.description);
    this.set("currentModel.selectedEntry.content.location", Ember.get(link, "nearest_stations.firstObject.key"));
    this.set("currentModel.selectedEntry.content.desktop", link.cover.desktop);
    this.set("currentModel.selectedEntry.content.mobile", link.cover.mobile);
    this.set("currentModel.selectedEntry.content.tags", link.tags);
    this.set("currentModel.selectedEntry.content.products", [Ember.Object.create({}), Ember.Object.create({}), Ember.Object.create({}), Ember.Object.create({})]);
  }

  function selectLink(link, row, entry) {
    this.set("currentModel.selectedLinkTypeValue", Ember.get(link, "type"));
    this.set("currentModel.selectedListLink", link); // if this link is already in the list highlight it

    selectEntry.call(this, row, entry);
    Ember.run.next(() => {
      set(link, "selected", true);
    });
  }

  function duplicatePage(page) {
    this.set("currentModel.loading", true);
    return _landing.default.duplicatePage(page.id).then(res => {
      this.transitionTo("main.landing.new", res.newIndex);
    });
  }

  function savePageAndPreview(page) {
    return savePage.call(this, page).then(() => {
      window.open(`${_environment.default.trouvaWebURL}/discover/${page.id}`, "Landing page preview");
    });
  }

  function savePage(page) {
    const pageIdOnUrl = this.get("currentModel.page_id");
    this.set("currentModel.loading", true);
    return page.save().then(() => {
      this.set("currentModel.loading", false);
      this.notify.show("success", "Landing page saved successfully!"); // Go back if this is a newly created page, to avoid creating this page multiple times

      if (!pageIdOnUrl) {
        this.transitionTo("main.landing.index");
      }
    }).catch(err => {
      this.set("currentModel.loading", false);

      if (typeof err === "string") {
        this.notify.show("danger", err, 4000);
      } else {
        alert("Error saving landing page changes! Contact the tech on Slack #tech-questions");
      }

      console.error(err);
    });
  }
  /**
   * Removes the tab from the navigation and stores the object
   */


  function removePage() {
    let remove = confirm("Are you sure you want to remove this landing page? (think of SEO yo.)"); // If we got confirmation

    if (remove) {
      this.set("currentModel.loading", true);
      this.get("currentModel.page").remove().then(() => {
        this.set("currentModel.loading", false);
        this.transitionTo("main.landing");
      }).catch(err => {
        this.set("currentModel.loading", false);
        alert("Error saving navbar changes! Contact the tech on Slack #tech-questions");
        console.error(err);
      });
    }
  }
});