define("backalley/pods/components/page-main-orders-edit/component", ["exports", "backalley/models/product", "backalley/mixins/request", "backalley/config/environment"], function (_exports, _product, _request, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const UPDATED_MESSAGE = 'Updated 👍';

  var _default = Ember.Component.extend(_request.default, {
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    config: _environment.default,
    zendeskTickets: Ember.computed.alias('model.zendeskTickets'),
    orderIdentifierChannelSpecific: Ember.computed('order.{ordernumber,channel_specific.order_reference,channel}', function () {
      if (this.order.channel_specific.order_reference == this.order.ordernumber) {
        return '';
      }

      return this.order.channel_specific.order_reference;
    }),
    orderIdentifierAndChannelSpecific: Ember.computed('order.{ordernumber,channel_specific.order_reference,channel}', function () {
      if (this.order.channel_specific.order_reference != this.order.ordernumber) {
        return `${this.order.ordernumber}/${this.order.channel_specific.order_reference}`;
      }

      return this.order.ordernumber;
    }),
    orderIdentifier: Ember.computed('order.{ordernumber,channel_specific.order_reference,channel}', function () {
      if (this.order.isChannelMadeDotCom) {
        return this.order.channel_specific.order_reference;
      }

      return this.order.ordernumber;
    }),

    updateOrderInternals(orderData) {
      const order = this.order; // update the order._orig with the new _ver and state
      // that reservation updates return!!

      order.setOriginalData(_objectSpread(_objectSpread({}, orderData), {}, {
        // but do not update the populated fields
        user: order.user
      }));
    },

    updateOrder() {
      return this.order.put().then(() => this.notify.showSuccess(UPDATED_MESSAGE)).catch(err => this.notify.showError(err));
    },

    orderAlreadyFinalisedWarning() {
      this.notify.showWarning('Note: This order was already finalized!');
    },

    _limitToManual: function (action, ...args) {
      const order = this.order;

      if (order.completed) {
        // This triggers multiple times because we call this method once for each: order, reservation, reservationBoutique
        // By scheduling the warning once, ember takes care of debouncing and showing it only once
        Ember.run.once(this, 'orderAlreadyFinalisedWarning');
      }

      if (!order.manual_mode) {
        Ember.setProperties(order, {
          manual_mode: true,
          assigned: this.get('session.user.email').replace(/@streethub.com|@trouva.com/gi, '')
        });
      }

      return action(...args);
    },
    _setOnReservation: function (reservation) {
      this.set('order.loading', true);
      return this._limitToManual(() => {
        return reservation.put({
          populate: 'product,boutique'
        }) //updates the order as well
        .then(({
          order
        }) => {
          this.updateOrderInternals(order);
          this.updateOrder();
        }).catch(err => this.notify.showError(err)).finally(() => this.set('order.loading', false));
      });
    },
    _changeToEditMode: function (key) {
      Object.keys(this.get('model.editing')).forEach(prev => this.set(`model.editing.${prev}`, false));
      this.set(`model.editing.${key}`, true);
    },
    actions: {
      notifySuccess(message) {
        return this.notify.showSuccess(message);
      },

      togglePending() {
        this._limitToManual(() => {
          this.toggleProperty('order.pending');
          return this.updateOrder();
        });
      },

      toggleGift() {
        this._limitToManual(() => {
          this.toggleProperty('order.gift');
          return this.updateOrder();
        });
      },

      edit(key) {
        // disable editing for automatic order handling
        if (this.get('order.manual_mode')) {
          this._changeToEditMode(key);
        } else {
          this.notify.showError('To edit this value, first assign somebody to enter manual order processing!');
        }
      },

      editWithoutModeCheck(key) {
        this._changeToEditMode(key);
      },

      limitToManual(action, ...args) {
        return this._limitToManual(action, ...args);
      },

      // updates
      setOnBoutique(boutique) {
        return this._limitToManual(() => {
          // return the promise to the editing component so it can change it's initial value and close the editable field
          return boutique.postOrPut().then(() => this.notify.showSuccess(UPDATED_MESSAGE)).catch(err => this.notify.showError(err));
        });
      },

      setOnReservationBoutique(reservationBoutique) {
        return this._limitToManual(() => {
          // return the promise to the editing component so it can change it's initial value and close the editable field
          return reservationBoutique.put().then(() => this.notify.showSuccess(UPDATED_MESSAGE)).catch(err => this.notify.showError(err));
        });
      },

      setStatusOnReservation(reservation, value) {
        const atomicReservation = reservation.reservationGroup[0];
        return this._limitToManual(() => {
          atomicReservation.set('status', value);
          return this._setOnReservation(atomicReservation);
        });
      },

      setShippedOnReservation(reservation) {
        return this._limitToManual(() => {
          reservation.set('shipped', !reservation.shipped);
          return this._setOnReservation(reservation);
        });
      },

      setOnReservation(reservation, elementToForceClose) {
        return this._setOnReservation(reservation).then(() => {
          if (elementToForceClose) {
            this.set(`model.editing.${elementToForceClose}`, false);
          }
        });
      },

      saveOrder() {
        return this.updateOrder();
      },

      saveOrderWithManualMode() {
        return this._limitToManual(() => this.updateOrder());
      },

      finalizeOrder() {
        return this._limitToManual(() => {
          if (this.get('order.status') === 'active') return this.notify.showError('Some reservations are still active!');

          if (this.get('model.editing.customer_recharged') || this.get('model.editing.customer_refunded') || Math.abs(this.get('order.transaction_customer_refund.amount') || this.get('order.transaction_customer_recharge.amount') || 0) > 2) {
            return this.notify.showError('Please refund/charge the customer before finalizing, so the email will be up to date!');
          }

          if (window.confirm('Finalizing an order means you won\'t be able to edit after! Are you sure?')) {
            return this.order.sendConfirmation({
              populate: 'user'
            }).then(() => this.notify.showSuccess(UPDATED_MESSAGE)).catch(err => this.notify.showError(err));
          }
        });
      },

      onUpdateFy() {
        return this.order.sendUpdateToFy();
      },

      setItemOutOfStockOnWholeSite(reservation) {
        if (!window.confirm('Do you really want to set this product out of stock on the whole site?')) return;
        return _product.default.findById(reservation.product._id).then(product => {
          product.stock_lines.forEach(stock_line => {
            Ember.setProperties(stock_line, {
              quantity: 0,
              stock: false
            });
          });
          return product;
        }).then(product => product.save()).then(() => this.notify.showSuccess(UPDATED_MESSAGE)).catch(err => this.notify.showError(err));
      },

      updateReturnLabelTickets(ticket) {
        this.zendeskTickets.pushObject(ticket);
      },

      /*
          Update the transaction value & the value for a given transaction property
      */
      updateTransactionValue(variable, value) {
        this.order.setProperties({
          [variable]: value,
          [`transaction_${variable}.amount`]: value,
          [`transaction_${variable}.currency`]: this.order.transaction_currency
        });
      },

      updateDeliveryCountry(selectedDeliveryCountry) {
        const weengsName = selectedDeliveryCountry.weengs_name;
        return this._limitToManual(() => {
          this.set('order.delivery_country', weengsName);
          return this.updateOrder();
        });
      }

    }
  });

  _exports.default = _default;
});