define("backalley/pods/components/task-form/component", ["exports", "jquery", "backalley/enums/product-pipeline-sort-type", "backalley/models/boutique", "backalley/models/tag", "backalley/models/category", "backalley/util/escape-regex", "backalley/util/default-to"], function (_exports, _jquery, _productPipelineSortType, _boutique, _tag, _category, _escapeRegex, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    tagName: 'form',
    task: null,
    isEdit: false,

    onSubmit() {},

    onCancel() {},

    results: (0, _defaultTo.default)({}),
    users: (0, _defaultTo.default)([]),
    filterOptions: (0, _defaultTo.default)([{
      label: 'Boutique',
      field: 'boutique',
      queryParam: 'q_boutique',
      autoComplete: true,
      resource: _boutique.default,
      key: 'name',
      dropdownKey: 'name',
      queryExtraData: {
        archived: {
          $ne: true
        }
      }
    }, {
      label: 'Tag',
      field: 'tag',
      queryParam: 'q_tag',
      autoComplete: true,
      resource: _tag.default,
      key: 'label',
      dropdownKey: 'label',
      queryExtraData: {}
    }, {
      label: 'Category',
      field: 'category',
      queryParam: 'q_category',
      autoComplete: true,
      resource: _category.default,
      key: 'name',
      dropdownKey: 'full_hierarchy_name',
      queryExtraData: {}
    }]),
    typeOptions: (0, _defaultTo.default)([{
      label: 'Published to feature',
      value: 'published_to_feature'
    }, {
      label: 'Not published to feature',
      value: 'not_published_to_feature'
    }, {
      label: 'Published to review',
      value: 'published_to_review'
    }]),
    taskId: Ember.computed.alias('task._id'),
    filters: Ember.computed.alias('task.filters'),
    url: Ember.computed.alias('task.url'),
    type: Ember.computed.alias('task.type'),
    assignee: Ember.computed.alias('task.assignee'),
    availableOptions: Ember.computed('filters.@each.field', function () {
      const usedFilters = this.filters.map(({
        field
      }) => field);
      return this.filterOptions.filter(option => !usedFilters.includes(option.field));
    }),
    typeSelected: Ember.computed('type', function () {
      return this.typeOptions.findBy('value', this.type);
    }),
    assigneeSelected: Ember.computed('users', 'assignee', function () {
      return this.users.findBy('_id', this.assignee);
    }),
    actions: {
      valueChanged(filter, value) {
        filter.set('value', this[`_${filter.field}Value`](value));

        this._updateUrl();
      },

      selectedField(field) {
        return this.filterOptions.findBy('field', field);
      },

      changeField(filter, {
        field
      }) {
        filter.set('field', field);
        filter.set('value', null);

        this._updateUrl();
      },

      changeType({
        value
      }) {
        this.set('type', value);

        this._updateUrl();
      },

      selectUser({
        _id
      }) {
        this.set('assignee', _id);
      },

      search(type, name) {
        let filterOption = this.filterOptions.findBy('field', type);

        if (filterOption.autoComplete) {
          return this._searchWithAutoComplete(filterOption, type, name);
        }
      },

      addFilterFields() {
        const element = Ember.Object.create({
          deleting: false,
          adding: true
        });
        this.filters.pushObject(element);
      },

      deleteFilterFields(index) {
        const filter = this.filters.objectAt(index);
        Ember.set(filter, 'deleting', true);
        Ember.run.later(() => {
          this.filters.removeAt(index);

          this._updateUrl();
        }, 500);
      }

    },

    _tagValue({
      _id,
      label
    }) {
      return `${_id}|${label}`;
    },

    _boutiqueValue({
      _id,
      name
    }) {
      return `${_id}|${name}`;
    },

    _categoryValue({
      _id,
      full_hierarchy_name
    }) {
      return `${_id}|${full_hierarchy_name}`;
    },

    _searchWithAutoComplete({
      resource,
      key,
      queryExtraData
    }, type, name) {
      if (!name) return this.set(`results.${type}`, []);

      let query = _objectSpread({
        limit: 3
      }, queryExtraData);

      query[key] = {
        $regex: `^${(0, _escapeRegex.default)(name)}`,
        $options: 'i'
      };
      return resource.find(query).then(results => this.set(`results.${type}`, results));
    },

    _updateUrl() {
      let queryParams = 'archived=false&stock=true';

      let type = _productPipelineSortType.default.toValues().findBy('sortProperty', this.type);

      if (type) {
        queryParams += `&${this._convertQueryToQueryParam(type.query)}`;
      }

      if (this.isEdit) {
        queryParams += `&task=${this.taskId}`;
      }

      queryParams = this.filters.reduce((accumulator, {
        field,
        value
      }) => {
        if (!field || !value) {
          return accumulator;
        }

        let {
          queryParam
        } = this.filterOptions.findBy('field', field);
        return `${accumulator}&${queryParam}=${value}`;
      }, queryParams);
      this.set('url', `${window.location.origin}/products?${encodeURI(queryParams)}`);
    },

    _convertQueryToQueryParam(query) {
      return _jquery.default.param(query);
    }

  });

  _exports.default = _default;
});