define("backalley/mirage/factories/comment", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.default.Factory.extend({
    _id: i => `${i + 1}`,
    text: i => `comment ${i}`,
    product: () => 1,
    updated_by: 'michael',
    order: () => 1,
    created_at: () => {
      return new Date();
    }
  });

  _exports.default = _default;
});