define("backalley/pods/components/boutique-form/profile-info/component", ["exports", "ember-concurrency", "backalley/models/boutique-category"], function (_exports, _emberConcurrency, _boutiqueCategory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    user: {},
    boutique: {},

    addTag() {},

    deleteTag() {},

    searchTags() {},

    __loadBoutiqueCategoriesTask: (0, _emberConcurrency.task)(function* (name) {
      yield (0, _emberConcurrency.timeout)(150);
      return yield _boutiqueCategory.default.find({
        name: {
          $regex: `^${name}`,
          $options: 'i'
        }
      });
    }).restartable(),
    actions: {
      selectBoutiqueCategory(boutiqueCategory) {
        this.get('boutique.boutique_categories').addObject(boutiqueCategory);
      },

      removeBoutiqueCategory(boutiqueCategory) {
        this.get('boutique.boutique_categories').removeObject(boutiqueCategory);
      },

      removeTag(tag) {
        return this.deleteTag(tag);
      }

    }
  });

  _exports.default = _default;
});