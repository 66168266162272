define("backalley/pods/components/comma-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['comma-list'],
    // Public
    commaString: '',
    // Private
    list: Ember.computed('commaString', function () {
      return this.commaString.split(',');
    })
  });

  _exports.default = _default;
});