define("backalley/mixins/category-search", ["exports", "backalley/models/category"], function (_exports, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
     * Categories are modeled by the following structure: macro > parent > child.
     * Most categories tree are 3 level deep (have a child), but some have only 2
     * (stops at parent). There's no way in the backend to query those specific
     * categories. We have to do it client-side.
     */
    subCategories: [],

    init() {
      _category.default.find({
        isMacro: false,
        limit: 500
      }).then(this.filtersubCategories.bind(this));
    },

    filtersubCategories(categories) {
      // Children
      let children = categories.filter(cat => !cat.isMacro && !cat.isParent);
      this.subCategories.addObjects(children); // Find two level ones

      let parents = categories.filterBy('isParent');
      let subs = parents.filter(parent => !children.findBy('parent', parent._id));
      this.subCategories.addObjects(subs);
    },

    searchCategories(q) {
      let regexp = new RegExp(`^${q}`, 'i');
      let results = this.subCategories.filter(cat => regexp.test(cat.name));
      return Ember.RSVP.resolve(results);
    }

  });

  _exports.default = _default;
});