define("backalley/mirage/factories/boutique-restock-information", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    id() {
      return _faker.default.random.uuid();
    },

    _id() {
      return this.id;
    }

  });

  _exports.default = _default;
});