define("backalley/enums/boutique-restock-information-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    NOT_AVAILABLE: 'not-available',
    RESTOCKING: 'restocking',
    RESTOCKED: 'restocked'
  };
  _exports.default = _default;
});