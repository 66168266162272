define("backalley/pods/main/countries/edit/controller", ["exports", "backalley/util/clone-deep"], function (_exports, _cloneDeep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend({
    isUpdateRates: false,
    isUpdateSurcharges: false,
    hasRatesDoc: Ember.computed.notEmpty('model.country.documents.shipping_rates.url'),
    hasSurchargesDoc: Ember.computed.notEmpty('model.country.documents.shipping_surcharges.url'),

    calculateTagLabel(tag, isNotYetAttached = false) {
      let {
        type,
        method,
        value,
        min,
        max
      } = tag;
      type = type && type.trim && type.trim();
      method = method && method.trim && method.trim();
      value = value && value.trim && value.trim(); // min & max can switch between strings or numbers

      min = min && min.trim ? min.trim() : min;
      max = max && max.trim ? max.trim() : max;

      const generateLabel = () => {
        if (method === 'range') {
          return type && min && max ? `${type}: ${min} to ${max}` : '';
        }

        if (method === 'prefix') {
          return type && value ? `${type}: starts with ${value}` : '';
        }

        if (method === 'single') {
          return type && value ? `${type}: ${value}` : '';
        }

        return '';
      };

      const label = generateLabel();

      if (label && isNotYetAttached) {
        return label + ' (NEW TAG)';
      }

      return label;
    },

    editingDeliveryTagLabelUpdate: Ember.observer('model.editingDeliveryTag.{type,method,value,min,max}', function () {
      const {
        editingDeliveryTag = {}
      } = this.model;
      const label = this.calculateTagLabel(editingDeliveryTag, editingDeliveryTag.__isNotYetAttached);

      if (label) {
        this.set('model.editingDeliveryTag.label', label);
      }
    }),
    liveDeliveryTagsWithNewOption: Ember.computed('model.country.delivery_tags.[]', function () {
      if (!this.model.country.delivery_tags) {
        return [];
      }

      return [...this.model.country.delivery_tags, (0, _cloneDeep.default)(this.model.newDeliveryTagOption)];
    }),

    closeDrawer() {
      Ember.set(this, 'isUpdateSurcharges', false);
      Ember.set(this, 'isUpdateRates', false);
    },

    actions: {
      setDocument(type, {
        url
      }) {
        const {
          country: {
            documents
          }
        } = this.model;

        if (Ember.isPresent(documents)) {
          Ember.set(documents, type, _objectSpread(_objectSpread({}, documents[type]), {}, {
            url
          }));
          return this.closeDrawer();
        }

        const docs = {};
        docs[type] = {
          url
        };
        Ember.set(this.model.country, 'documents', docs);
        return this.closeDrawer();
      }

    }
  });

  _exports.default = _default;
});