define("backalley/pods/components/google-it/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "04nslT2e",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[11,\"href\",[22,\"link\"]],[10,\"target\",\"_blank\"],[8],[0,\" \"],[1,[22,\"label\"],false],[0,\" \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/google-it/template.hbs"
    }
  });

  _exports.default = _default;
});