define("backalley/pods/components/zendesk-search/component", ["exports", "backalley/models/ticket", "backalley/util/default-to"], function (_exports, _ticket, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public API
    extraTickets: (0, _defaultTo.default)([]),
    ordernumber: '',

    notifySuccess() {},

    // Private API
    classNameBindings: ['_loading:loading', 'hasTickets:has-tickets'],
    tickets: (0, _defaultTo.default)([]),
    previousOrderNumber: null,
    _insertedTicket: null,
    _loading: true,
    hasTickets: Ember.computed.gt('tickets.length', 0),
    onExtraTickets: Ember.observer('extraTickets.[]', function () {
      const newTickets = this.getNewTickets(this.extraTickets);
      this.set('tickets', this.tickets.concat(newTickets));
    }),

    didInsertElement() {
      this._super(...arguments);

      this.refreshList();
    },

    didReceiveAttrs() {
      this._super(...arguments);

      if (Ember.isPresent(this.previousOrderNumber) && this.ordernumber !== this.previousOrderNumber) {
        this.refreshList();
      }
    },

    refreshList() {
      this.setProperties({
        tickets: [],
        previousOrderNumber: this.ordernumber
      });
      this.search();
    },

    getNewTickets(tickets) {
      return tickets.filter(({
        id
      }) => {
        const ticket = this.tickets.findBy('id', id);
        return Ember.isEmpty(ticket);
      });
    },

    search() {
      this.set('_loading', true);
      return _ticket.default.search({
        query: this.ordernumber
      }).then(tickets => {
        const newTickets = this.getNewTickets(tickets);
        return this.tickets.addObjects(newTickets);
      }).finally(() => Ember.trySet(this, '_loading', false));
    },

    actions: {
      refreshList() {
        this.refreshList();
      },

      refreshAndInsertTicket(ticket) {
        return this.search().then(tickets => {
          const exists = tickets.find(t => t.id === ticket.id);

          if (!exists) {
            this.tickets.insertAt(0, ticket);
            this.set('_insertedTicket', ticket);
          }
        });
      }

    }
  });

  _exports.default = _default;
});