define("backalley/pods/main/feeds/trigger/route", ["exports", "backalley/models/boutique", "backalley/models/sync-integration", "snakecase-keys"], function (_exports, _boutique, _syncIntegration, _snakecaseKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  async function parseSnapshot({
    snapshotRaw,
    syncIntegration
  }) {
    if (!snapshotRaw || !syncIntegration.settings.boutique) {
      return undefined;
    } // Awkwardly the communication between Backalley, Promenade and
    // Sync Reconciler alters between snake_case and camelCase. Meaning our
    // captured snapshot is in camelCase, but we are expected to send it from
    // Backalley as snake_case. We could align the entire process to snake_case
    // but... that would mean quite a few changes for the Sync Reconciler.
    // Easiest option is to convert back to snake_case here.


    const snapshotParsed = JSON.parse(snapshotRaw);
    const snapshot = (0, _snakecaseKeys.default)(snapshotParsed); // imbue snapshot with data we can only get at the point of it being viewed.

    if (!snapshot.stats || !snapshot.stats.generated_at) {
      return snapshot;
    }

    const {
      relative_product_stats: relStats
    } = await _boutique.default.findRelativeProductStats({
      _id: syncIntegration.settings.boutique._id,
      since: snapshot.stats.generated_at
    });
    snapshot.stats = _objectSpread(_objectSpread({}, relStats), snapshot.stats);
    return snapshot;
  }

  var _default = Ember.Route.extend({
    queryParams: {
      snapshot: {
        refreshModel: true
      }
    },

    async model({
      syncintegration_id,
      snapshot: snapshotRaw = null
    }) {
      const syncIntegration = await _syncIntegration.default.findById(syncintegration_id, {
        populate: 'settings.boutique'
      });
      const snapshot = await parseSnapshot({
        syncIntegration,
        snapshotRaw
      });
      return {
        syncIntegration,
        snapshot
      };
    },

    actions: {
      goBack() {
        this.transitionTo('main.feeds');
      }

    }
  });

  _exports.default = _default;
});