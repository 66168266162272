define("backalley/pods/components/order-notes/quick/component", ["exports", "backalley/util/default-to", "backalley/pods/components/order-notes/quick/issueOptions"], function (_exports, _defaultTo, _issueOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    requesters: (0, _defaultTo.default)([]),
    boutiques: (0, _defaultTo.default)([]),
    products: (0, _defaultTo.default)([]),
    reservations: (0, _defaultTo.default)([]),
    order: (0, _defaultTo.default)({}),
    issueList: _issueOptions.issueList,
    comment: Ember.computed.reads('notes.comment'),
    availableProducts: Ember.computed('selectedBoutique.email', function () {
      const selectedBoutique = this.selectedBoutique;
      const filterProducts = this.products.filter(product => product.boutique_email === selectedBoutique.email);
      return filterProducts;
    }),

    notifyMissing(name) {
      this.notify.show('warning', `Please select a ${name}.`);
      return null;
    },

    truncateText(text, limit = 20) {
      if (!text || !text.substring || text.length < limit) {
        return text || '';
      }

      return text.substring(0, limit) + '...';
    },

    actions: {
      save(comment) {
        const saveAction = Ember.get(this, 'notes.actions.save');
        const {
          selectedRequester = this.notifyMissing('requester'),
          selectedBoutique = this.notifyMissing('boutique'),
          selectedProduct = this.notifyMissing('product'),
          selectedIssue = this.notifyMissing('issue')
        } = this;
        const isAnythingMissing = !selectedRequester || !selectedBoutique || !selectedProduct || !selectedIssue;

        if (isAnythingMissing) {
          return;
        }

        const BR = '<br/>';
        const text = [`Requester: ${selectedRequester.name}${BR}`, `Boutique: ${selectedBoutique.name}${BR}`, `Product: ${this.truncateText(selectedProduct.name, 20)}${BR}`, `<p class="bold-text">Issue: ${selectedIssue.value || 'Unknown'}</p>`];
        Ember.set(comment, 'text', text.join(''));
        Ember.set(comment, 'type', selectedIssue.value);
        Ember.set(comment, 'boutique', selectedBoutique.id);
        Ember.set(comment, 'product', selectedProduct.id);

        if (this.selectedProduct && this.reservations) {
          const productId = this.selectedProduct.id;
          const {
            _id: reservationId = undefined
          } = this.reservations.find(reservation => reservation.product == productId || reservation.product.id == productId || false) || {};
          Ember.set(comment, 'reservation', reservationId);
        }

        return saveAction(comment);
      },

      selectRequester(requester) {
        const {
          name,
          email,
          label
        } = requester;
        this.setProperties({
          'notes.requester_name': name,
          'notes.requester_email': email,
          selectedRequester: requester
        });

        if (label === 'Boutique') {
          const selectedBoutique = this.boutiques.find(boutique => boutique.email === email);
          this.send('selectBoutique', selectedBoutique);
        }
      },

      selectBoutique(boutique) {
        const {
          name,
          primary_name,
          address_city,
          address
        } = boutique;
        this.setProperties({
          'notes.boutique_name': name,
          'notes.boutique_primary_name': primary_name,
          'notes.boutique_city': address_city,
          'notes.boutique_address': address,
          selectedBoutique: boutique,
          selectedProduct: null
        });
      },

      selectProduct(product) {
        const {
          name,
          slug
        } = product;
        this.setProperties({
          'notes.product_name': name,
          'notes.product_slug': slug,
          selectedProduct: product
        });
      },

      selectIssue(issue) {
        this.setProperties({
          selectedIssue: issue
        });
      }

    }
  });

  _exports.default = _default;
});