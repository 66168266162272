define("backalley/pods/main/transactions-view/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _temp;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let TransactionsViewIndexController = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class TransactionsViewIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['ordernumber']);

      _defineProperty(this, "ordernumber", null);

      _defineProperty(this, "subEntityTypes", '');

      _defineProperty(this, "subEntityIds", '');

      _defineProperty(this, "projection", 'none');
    }

    getBaseQueryParams() {
      return {
        ordernumber: this.ordernumber,
        subEntityTypes: this.subEntityTypes,
        subEntityIds: this.subEntityIds,
        projection: this.projection
      };
    }

    onChangeOrderNumber(ordernumber) {
      return this.transitionToRoute({
        queryParams: _objectSpread(_objectSpread({}, this.getBaseQueryParams()), {}, {
          ordernumber
        })
      });
    }

    onChangeSubEntityTypeFilter(selectedSubEntityTypes) {
      return this.transitionToRoute({
        queryParams: _objectSpread(_objectSpread({}, this.getBaseQueryParams()), {}, {
          subEntityTypes: selectedSubEntityTypes ? selectedSubEntityTypes.join(',') : ''
        })
      });
    }

    onChangeProjection(selectedProjection) {
      return this.transitionToRoute({
        queryParams: _objectSpread(_objectSpread({}, this.getBaseQueryParams()), {}, {
          projection: selectedProjection
        })
      });
    }

    onChangeSubEntityIdFilter(selectedSubEntityIds) {
      return this.transitionToRoute({
        queryParams: _objectSpread(_objectSpread({}, this.getBaseQueryParams()), {}, {
          subEntityIds: selectedSubEntityIds.join(',')
        })
      });
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "onChangeOrderNumber", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeOrderNumber"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeSubEntityTypeFilter", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeSubEntityTypeFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeProjection", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeProjection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeSubEntityIdFilter", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeSubEntityIdFilter"), _class.prototype)), _class));
  _exports.default = TransactionsViewIndexController;
});