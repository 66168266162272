define("backalley/pods/components/boutique-reservation-item/boutique-reservation-item-product/edit-reservations-status-modal/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EditReservationsStatusModalComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = (_temp = class EditReservationsStatusModalComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "styleNamespace", _podNames.default['boutique-reservation-item/boutique-reservation-item-product/edit-reservations-status-modal']);

      _initializerDefineProperty(this, "selectedQuantity", _descriptor, this);

      _initializerDefineProperty(this, "selectedStatus", _descriptor2, this);
    }

    get selectQuantityOptions() {
      let options = [];

      for (let i = 1; i <= this.args.reservation.quantity; i++) {
        options.push({
          label: i,
          value: i
        });
      }

      return options;
    }

    get selectStatusOptions() {
      return this.args.reservationStatusOptions;
    }

    updateReservation() {
      return this.args.onSubmit(this.args.reservation, this.selectedQuantity.value, this.selectedStatus.value).then(() => this.args.onClose()).catch(() => this.notify.show('error', 'Error updating reservation.'));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedQuantity", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.reservation.quantity;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedStatus", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.reservation.status;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateReservation", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateReservation"), _class.prototype)), _class));
  _exports.default = EditReservationsStatusModalComponent;
});