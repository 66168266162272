define("backalley/util/default-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = defaultTo;

  function defaultTo(value) {
    return Ember.computed({
      get() {
        return value;
      },

      set(_, newVal) {
        return newVal === undefined ? value : newVal;
      }

    });
  }
});