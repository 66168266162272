define("backalley/pods/main/discounts/new/route", ["exports", "moment", "backalley/models/currency", "backalley/models/discount", "backalley/enums/discount-type", "backalley/util/escape-regex", "backalley/models/manufacturer", "backalley/models/tag", "backalley/enums/discount-channel"], function (_exports, _moment, _currency, _discount, _discountType, _escapeRegex, _manufacturer, _tag, _discountChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    queryParams: {
      duplicate: {
        refreshModel: false
      }
    },

    async model({
      duplicate
    }) {
      const currencies = await _currency.default.find({
        iso_4217: {
          $in: ['GBP', 'EUR']
        }
      });
      const gbpCurrency = currencies.find(({
        iso_4217
      }) => iso_4217 === 'GBP');

      if (duplicate) {
        return _discount.default.findById(duplicate).then(discount => ({
          discount: _objectSpread(_objectSpread({}, discount), {}, {
            code: ''
          }),
          toPenceValue: discount.type === 'amount' ? discount.value / 100 : discount.value,
          toPenceMinimumValue: discount.minimum_value / 100,
          expired_at: discount.expired_at ? (0, _moment.default)(discount.expired_at).format('YYYY/MM/DD') : undefined,
          discountTypes: Object.values(_discountType.default),
          discountChannels: Object.values(_discountChannel.default),
          gbpCurrency
        }));
      }

      return {
        discount: _discount.default.create({
          type: _discountType.default.AMOUNT,
          channel: _discountChannel.default.TROUVA,
          currency: gbpCurrency
        }),
        gbpCurrency,
        currencies,
        toPenceValue: 0,
        toPenceMinimumValue: 0,
        expired_at: undefined,
        discountTypes: Object.values(_discountType.default),
        discountChannels: Object.values(_discountChannel.default)
      };
    },

    actions: {
      async searchManufacturers(query) {
        if (Ember.isEmpty(query)) {
          return;
        }

        ;
        const manufacturers = await _manufacturer.default.find({
          $text: {
            $search: query
          },
          archived: {
            $ne: true
          },
          limit: 10
        });
        return manufacturers;
      },

      async searchTags(query) {
        if (Ember.isEmpty(query)) return;
        const tags = await _tag.default.find({
          label: {
            $regex: `^${(0, _escapeRegex.default)(query)}`,
            $options: 'i'
          },
          limit: 10
        });
        return tags;
      },

      preventDefaultEnter(options, e) {
        if (e.keyCode === 13) {
          e.preventDefault();
        }
      },

      updateType(value) {
        Ember.set(this.currentModel.discount, 'type', value);
      },

      updateChannel(value) {
        Ember.set(this.currentModel.discount, 'channel', value);
        Ember.set(this.currentModel.discount, 'currency', this.currentModel.gbpCurrency);
      },

      create(discount) {
        discount.set('value', this.currentModel.discount.type === _discountType.default.AMOUNT ? this.currentModel.toPenceValue * 100 : this.currentModel.toPenceValue);
        discount.set('minimum_value', this.currentModel.toPenceMinimumValue * 100);

        if (this.currentModel.expired_at) {
          discount.set('expired_at', new Date(this.currentModel.expired_at));
        }

        return discount.post().then(() => this.transitionTo('main.discounts')).catch(err => this.notify.show('err', err.message));
      }

    }
  });

  _exports.default = _default;
});