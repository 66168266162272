define("backalley/pods/components/box-pair-checkbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    _value: Ember.computed.oneWay('value'),
    actions: {
      toggleState() {
        this.sendAction('action');
      }

    }
  });

  _exports.default = _default;
});