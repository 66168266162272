define("backalley/util/cast-object-fields-to-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = castObjectFieldsToString;

  /**
   * Casts every field's value in object to a string by applying String()
   * and does the same for any nested field that constains an object.
   * In the process, deletes undefined fields.
   */
  function castObjectFieldsToString(obj) {
    if (!isObject(obj)) {
      return obj;
    }

    for (const key in obj) {
      if (obj[key] === undefined || obj[key] === null) {
        delete obj[key];
        continue;
      }

      if (isObject(obj[key])) {
        obj[key] = castObjectFieldsToString(obj[key]);
        continue;
      }

      obj[key] = String(obj[key]);
    }

    return obj;
  }

  function isObject(value) {
    return value !== null && typeof value === 'object';
  }
});