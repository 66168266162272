define("backalley/pods/components/comma-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KACYMDSq",
    "block": "{\"symbols\":[\"entry\",\"&default\"],\"statements\":[[1,[28,\"component\",[[28,\"hot-load\",[\"form-text\",[23,0,[]],[24,[\"form-text\"]],\"form-text\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"type\",\"value\",\"action\"],[[23,0,[]],\"form-text\",[24,[\"form-text\"]],false,true,\"text\",[24,[\"commaString\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"commaString\"]]],null]],null]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"commaString\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"comma-list-entries\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"list\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"comma-list-entry\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/comma-list/template.hbs"
    }
  });

  _exports.default = _default;
});