define("backalley/enums/packaging-third-party-provider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    RAJAPACK: 'rajapack',
    DS_SMITH: 'ds-smith'
  };
  _exports.default = _default;
});