define("backalley/enums/curate-product-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    PUBLISHED: 'published',
    ARCHIVED: 'archived',
    UNPUBLISHED: 'unpublished'
  };
  _exports.default = _default;
});