define("backalley/pods/main/shipment-rates/index/route", ["exports", "backalley/models/shipment-rate"], function (_exports, _shipmentRate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    queryParams: {
      source: {
        refreshModel: true
      },
      upsZone: {
        refreshModel: true
      },
      weight: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },

    model({
      source,
      upsZone,
      weight,
      page
    }) {
      const parameters = this.getSearchParameters(source, upsZone, weight);
      return _shipmentRate.default.find(_objectSpread(_objectSpread({}, parameters), {}, {
        page,
        limit: 16,
        sort: 'weight'
      }));
    },

    getSearchParameters(source, upsZone, weight) {
      const parameters = {};

      if (Ember.isPresent(source) && Ember.isPresent(upsZone) && Ember.isPresent(weight)) {
        return {
          rate_id: `${source}_${Ember.String.dasherize(upsZone)}#${weight}`
        };
      }

      if (Ember.isPresent(source)) {
        parameters.zone = Ember.isPresent(upsZone) ? `${source}_${Ember.String.dasherize(upsZone)}` : {
          '$regex': `${source}_(.*)`
        };
      }

      if (Ember.isPresent(weight)) {
        parameters.weight = weight;
      }

      return parameters;
    },

    actions: {
      async onImportCsv({
        data,
        meta
      }) {
        const staticHeaders = ['source', 'currency', 'weight', 'ups_zone'];
        const upsZones = meta.fields.removeObjects(staticHeaders);
        const rates = [];
        upsZones.forEach(upsZone => {
          data.forEach(entry => {
            rates.pushObject({
              source: entry.source,
              ups_zone: Ember.String.dasherize(upsZone),
              weight: parseInt(entry.weight, 10),
              rate: {
                amount: Math.round(parseFloat(entry[upsZone], 10) * 100),
                currency: entry.currency
              }
            });
          });
        });
        return _shipmentRate.default.bulkUpdateOrCreate(rates).then(() => this.refresh());
      }

    }
  });

  _exports.default = _default;
});