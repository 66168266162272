define("backalley/enums/path-and-lane-release-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    SHIPPING_PATH: 'Shipping Path',
    CARRIER_LANE: 'Carrier Lane'
  };
  _exports.default = _default;
});