define("backalley/pods/main/curate/orders/index/controller", ["exports", "ember-concurrency", "ember-concurrency-decorators", "backalley/models/boutique", "backalley/models/curate/brand", "backalley/enums/curate-order-status"], function (_exports, _emberConcurrency, _emberConcurrencyDecorators, _boutique, _brand, _curateOrderStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurateOrdersIndexController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class CurateOrdersIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['page', 'limit', 'sort', 'status', 'boutique', 'brand', {
        orderNumber: 'order-number'
      }]);

      _defineProperty(this, "limit", 16);

      _defineProperty(this, "sort", '-created_at');

      _initializerDefineProperty(this, "page", _descriptor, this);

      _initializerDefineProperty(this, "status", _descriptor2, this);

      _initializerDefineProperty(this, "boutique", _descriptor3, this);

      _initializerDefineProperty(this, "brand", _descriptor4, this);

      _initializerDefineProperty(this, "orderNumber", _descriptor5, this);

      _defineProperty(this, "statusOptions", Object.values(_curateOrderStatus.default));
    }

    get selectedBoutique() {
      return this.boutique ? _boutique.default.findById(this.boutique) : null;
    }

    get selectedCurateBrand() {
      return this.brand ? _brand.default.findById(this.brand) : null;
    }

    *searchBoutique(query) {
      yield (0, _emberConcurrency.timeout)(500);
      return _boutique.default.find({
        name: {
          $regex: `^${query.trim()}`,
          $options: 'i'
        }
      });
    }

    *searchCurateBrand(query) {
      yield (0, _emberConcurrency.timeout)(500);
      const {
        brands
      } = yield _brand.default.find({
        name: query
      });
      return brands;
    }

    exportOrder(order) {
      return order.exportToCsv();
    }

    onChangeQueryParameter(parameter, value) {
      this[parameter] = Ember.isPresent(value) ? value : undefined;
      this.page = 1;
    }

    onChangeBoutique(value) {
      this.onChangeQueryParameter('boutique', value === null || value === void 0 ? void 0 : value._id);
    }

    onChangeCurateBrand(value) {
      this.onChangeQueryParameter('brand', value === null || value === void 0 ? void 0 : value._id);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "page", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "boutique", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "orderNumber", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "searchBoutique", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchBoutique"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchCurateBrand", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchCurateBrand"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exportOrder", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "exportOrder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeQueryParameter", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeQueryParameter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeBoutique", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeBoutique"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeCurateBrand", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeCurateBrand"), _class.prototype)), _class));
  _exports.default = CurateOrdersIndexController;
});