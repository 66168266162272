define("backalley/pods/components/order-edit/order-events/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EYxC+ZO1",
    "block": "{\"symbols\":[\"eventFired\",\"&default\"],\"statements\":[[7,\"p\",false],[12,\"class\",\"box-view-heading\"],[3,\"action\",[[23,0,[]],[28,\"toggle\",[\"_expandSection\",[23,0,[]]],null]]],[8],[0,\"\\n  \"],[7,\"a\",true],[10,\"tabindex\",\"-1\"],[8],[0,\"Toggle\"],[9],[0,\"\\n  Order Events\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"_expandSection\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"eventsStatus\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[28,\"hot-load\",[\"box-pair\",[23,0,[]],[24,[\"box-pair\"]],\"box-pair\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"key\",\"value\",\"valuePrepend\"],[[23,0,[]],\"box-pair\",[24,[\"box-pair\"]],false,true,[23,1,[\"name\"]],[23,1,[\"completed\"]],\"Completed \"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/order-edit/order-events/template.hbs"
    }
  });

  _exports.default = _default;
});