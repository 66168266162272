define("backalley/enums/shipping-providers", ["exports", "backalley/enums/types"], function (_exports, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computeShippingProviderLabel = computeShippingProviderLabel;
  _exports.computeShippingProviderOption = computeShippingProviderOption;
  _exports.computeActiveShippingProviders = computeActiveShippingProviders;
  _exports.computeCanShippingProviderBeChanged = computeCanShippingProviderBeChanged;
  _exports.shippingProvidersByValue = _exports.default = void 0;
  const SHIPPING_PROVIDER_FIELD = 'shipping_provider';

  const shippingProvidersEnum = _types.default.create({
    DECIDED_AT_COURIER_BOOKING: {
      value: 'decided_at_courier_booking',
      label: 'Auto',
      description: {
        forBoutiqueEdit: 'Decision left till booking via the Retail panel',
        forOrderEdit: 'Decision left till booking via the Retail panel'
      },
      canSwitchTo: ['decided_at_courier_booking', 'easypost', 'other']
    },
    EASYPOST: {
      value: 'easypost',
      label: 'Easypost',
      description: {
        forBoutiqueEdit: 'Boutique will use only Easypost services',
        forOrderEdit: 'Order will use Easypost'
      },
      canSwitchTo: ['decided_at_courier_booking', 'easypost', 'other']
    },
    P2G: {
      value: 'p2g',
      label: 'Parcel2Go',
      description: {
        forBoutiqueEdit: 'Boutique was using P2G',
        forOrderEdit: 'Order was using P2G'
      },
      canSwitchTo: [],
      deprecated: true
    },
    OTHER: {
      value: 'other',
      label: 'Self-shipping',
      description: {
        forBoutiqueEdit: 'Boutique will always Self-ship',
        forOrderEdit: 'Order will Self-ship'
      },
      canSwitchTo: ['decided_at_courier_booking', 'easypost', 'other']
    },
    WEENGS: {
      value: 'weengs',
      label: 'Weengs',
      description: {
        forBoutiqueEdit: 'Boutique was using weengs',
        forOrderEdit: 'Order was using weengs'
      },
      canSwitchTo: [],
      deprecated: true
    },
    UPS_ACCESS_POINT: {
      value: 'ups_access_point',
      label: 'UPS Access Point',
      description: {
        forBoutiqueEdit: 'Boutique will use UPS Access Point',
        forOrderEdit: 'Order will use UPS Access Point'
      },
      canSwitchTo: ['decided_at_courier_booking', 'easypost', 'other']
    }
  });

  var _default = shippingProvidersEnum;
  _exports.default = _default;
  const shippingProvidersArray = shippingProvidersEnum.toValues(); // just a quick look-up object so we can find via values used in db

  const shippingProvidersByValue = Object.keys(shippingProvidersEnum).reduce((snowball, key) => {
    const item = shippingProvidersEnum[key];
    snowball[item.value] = item;
    return snowball;
  }, {});
  /**
   * Cut down duplication, share the compute to get the pretty label
   * for shipping provider here. Relies on this.shipping_provider
   *
   * @return {String}
   */

  _exports.shippingProvidersByValue = shippingProvidersByValue;

  function computeShippingProviderLabel() {
    const shippingProvider = this.get(SHIPPING_PROVIDER_FIELD);
    const enumData = shippingProvidersByValue[shippingProvider];

    if (enumData) {
      return enumData.label;
    }

    return `(${shippingProvider})`;
  }
  /**
   * Based on the selected shipping provider value, return the option
   * from the enum.
   */


  function computeShippingProviderOption() {
    const shippingProvider = this.get(SHIPPING_PROVIDER_FIELD);
    return shippingProvidersByValue[shippingProvider];
  }
  /**
   * Boutique selection should only allow for currenct active providers,
   * but allow for any switching between. So we should use
   * computeEnabledShippingProviders().
   *
   * Whereas reservation boutique selection should allow for legacy, but
   * be conditional on what we can and can't switch between. So we should
   * use computeCanShippingProviderBeChanged().
   *
   * @return {[Object]}
   */


  function computeActiveShippingProviders() {
    return shippingProvidersArray.filter(option => !option.deprecated);
  }
  /**
   * Work out if we are a certain shipping provider, what we are allowed
   * to switch to. Essentially stops weengs from switching to anything
   * else.
   *
   * @return {[Object]}
   */


  function computeCanShippingProviderBeChanged() {
    const shippingProvider = this.get(SHIPPING_PROVIDER_FIELD);
    const enumData = shippingProvidersByValue[shippingProvider]; // if we are set to something deprecated, don't allow change

    if (enumData && enumData.deprecated) {
      return [];
    } // calculate what we can switch to based on current value


    if (enumData && enumData.canSwitchTo) {
      return enumData.canSwitchTo.map(value => shippingProvidersByValue[value]);
    }

    return [];
  }
});