define("backalley/pods/components/landing-page/boutique/options/component", ["exports", "backalley/pods/main/landing/new/selectOptions", "backalley/util/default-to"], function (_exports, _selectOptions, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public API
    selectedEntry: (0, _defaultTo.default)(null),
    imageFolder: (0, _defaultTo.default)(null),
    selectedRow: (0, _defaultTo.default)(null),
    // Private API
    availableTypes: _selectOptions.availableTypes,
    productSearchResults: (0, _defaultTo.default)([]),
    boutiqueLinkType: Ember.computed('availableTypes', function () {
      return this.availableTypes.find(type => type.value === 'boutique');
    }),
    actions: {
      setTitle(title) {
        const selectedEntry = this.selectedEntry;
        Ember.set(selectedEntry, 'content.title', title);
      },

      setSubtitle(subtitle) {
        const selectedEntry = this.selectedEntry;
        Ember.set(selectedEntry, 'content.subtitle', subtitle);
      },

      setDescription(description) {
        const selectedEntry = this.selectedEntry;
        Ember.set(selectedEntry, 'content.description', description);
      },

      setProductsInBoutique(index, link) {
        const selectedEntry = this.selectedEntry;
        if (!selectedEntry.content.products) selectedEntry.content.products = [];
        Ember.setProperties(selectedEntry.content.products[index], {
          id: link.boutiques[0]._id,
          slug: link.slug,
          has_product_unique_slug: !!link.has_product_unique_slug,
          thumbnail: link.thumbnail.url,
          name: link.name
        });
      },

      // TODO: Generalise linkType search
      searchLinkType(type, q) {
        if (Ember.isEmpty(q)) return;
        if (Ember.isEmpty(type)) return;
        if (type.value === 'customUrl') return;
        return type.model.find(type.query(q)).then(res => {
          // Landing pages && Info pages for now until refactor
          // TODO: Normalise these across the board
          if (res.pages) res = res.pages;
          if (res.static && res.static.pages) res = res.static.pages;
          Ember.set(this, 'linkTypeSearchResults', res);
        });
      },

      // TODO: Generalise setLinkTypeValue
      setLinkTypeValue(selectedLinkType, linkTo) {
        const selectedEntry = this.selectedEntry;

        if (selectedLinkType.value === 'product') {
          Ember.set(selectedEntry, 'content.id', linkTo.boutiques[0]._id);
          Ember.set(selectedEntry, 'content.slug', linkTo.slug);
          Ember.set(selectedEntry, 'content.has_product_unique_slug', Boolean(linkTo.has_product_unique_slug));
        } else {
          Ember.set(selectedEntry, 'content.id', linkTo._id);
        }

        Ember.setProperties(selectedEntry.content, {
          type: selectedLinkType.value,
          title: linkTo[selectedLinkType.key],
          link_to_title: linkTo[selectedLinkType.key]
        });
      },

      // TODO: Generalise all component's set/remove functions
      removeDesktopImage() {
        const selectedEntry = this.selectedEntry;
        Ember.set(selectedEntry, 'content.desktop', '');
      },

      removeMobileImage() {
        const selectedEntry = this.selectedEntry;
        Ember.set(selectedEntry, 'content.mobile', '');
      },

      setDesktopImage(image) {
        const selectedEntry = this.selectedEntry;
        Ember.set(selectedEntry, 'content.desktop', image.url);
      },

      setMobileImage(image) {
        const selectedEntry = this.selectedEntry;
        Ember.set(selectedEntry, 'content.mobile', image.url);
      }

    }
  });

  _exports.default = _default;
});