define("backalley/models/campaign", ["exports", "backalley/mixins/request", "moment"], function (_exports, _request, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const Campaign = Ember.Object.extend(_request.default, {
    currentlyRunning: Ember.computed('start_at', 'end_at', function () {
      return (0, _moment.default)().isBetween(this.start_at, this.end_at);
    }),

    /**
     * Save campaign
     * @return {Object} Promise
     */
    save() {
      return this.request({
        method: 'PUT',
        url: this.apiURL(`campaigns/${this._id}`),
        // Before we save, need to convert dates back to proper format
        data: JSON.stringify(_objectSpread(_objectSpread({}, this), {}, {
          start_at: (0, _moment.default)(this.start_at).toISOString(),
          end_at: (0, _moment.default)(this.end_at).toISOString()
        }))
      });
    },

    /**
     * Create new campaign
     * @return {Object} Promise
     */
    post() {
      return this.request({
        method: 'POST',
        url: this.apiURL('campaigns'),
        data: JSON.stringify(_objectSpread({}, this))
      });
    },

    async updateAndDelete(campaign) {
      Ember.set(campaign, 'end_at', (0, _moment.default)().toISOString());
      await campaign.save();
      await Campaign.remove(campaign._id);
    }

  });
  Campaign.reopenClass(_request.default, {
    /**
     * Find campaigns
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data = {}) {
      return this.request({
        method: 'GET',
        url: this.apiURL('campaigns'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => ({
        campaigns: res.campaigns.map(campaign => this.create(campaign)),
        meta: res.meta
      }));
    },

    /**
     * Find campaign by id
     * @param  {String} id campaign _id
     * @return {Object}    Promise
     */
    findById(id) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`campaigns/${id}`),
        data: {
          plain: true
        }
      }).then(res => this.create(res.campaigns[0]));
    },

    /**
     * Remove campaign
     * @return {Object} Promise
     */
    remove(id) {
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`campaigns/${id}`)
      });
    }

  });
  var _default = Campaign;
  _exports.default = _default;
});