define("backalley/models/notifications-centre", ["exports", "joi", "tracked-built-ins", "backalley/util/request", "backalley/util/models"], function (_exports, _joi, _trackedBuiltIns, _request, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Notification = _exports.CalloutBanner = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _class2, _temp, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class3, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _class4, _temp2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const notificationValidations = {
    _id: _joi.default.string(),
    title: _joi.default.string(),
    body: _joi.default.string(),
    button: _joi.default.string().allow('', null),
    route: _joi.default.string().allow('', null),
    external_link: _joi.default.string().allow('', null),
    featured: _joi.default.boolean(),
    created_by: _joi.default.string(),
    created_at: _joi.default.string(),
    updated_by: _joi.default.string(),
    updated_at: _joi.default.string(),
    schedule: _joi.default.object({
      start_date: _joi.default.date().allow('', null),
      end_date: _joi.default.date().allow('', null)
    }),
    filters: _joi.default.object({
      countries: _joi.default.array(),
      zones: _joi.default.array(),
      b2b_services: _joi.default.array(),
      boutiques: _joi.default.array(),
      promotions: _joi.default.array()
    })
  };
  const calloutBannerValidations = {
    title: _joi.default.string().required(),
    body: _joi.default.string().required(),
    icon: _joi.default.string().required(),
    enabled: _joi.default.boolean().required(),
    updated_at: _joi.default.string().required(),
    updated_by: _joi.default.string().required()
  };
  let CalloutBanner = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, (_class = (_temp = _class2 = class CalloutBanner {
    constructor({
      title,
      body,
      icon,
      enabled,
      updated_at,
      updated_by
    }) {
      _initializerDefineProperty(this, "title", _descriptor, this);

      _initializerDefineProperty(this, "body", _descriptor2, this);

      _initializerDefineProperty(this, "icon", _descriptor3, this);

      _initializerDefineProperty(this, "enabled", _descriptor4, this);

      _initializerDefineProperty(this, "updated_at", _descriptor5, this);

      _initializerDefineProperty(this, "updated_by", _descriptor6, this);

      this.title = title;
      this.body = body;
      this.icon = icon;
      this.enabled = enabled;
      this.updated_at = updated_at;
      this.updated_by = updated_by;
      this.enumerableFields = Object.keys(calloutBannerValidations);
    }

    static async find() {
      try {
        const {
          static: {
            callout_banner
          }
        } = await (0, _request.request)({
          url: (0, _request.apiURL)(`static/${this.s3FileName}`)
        });
        return new CalloutBanner(callout_banner);
      } catch (error) {
        (0, _request.throwError)(error);
      }
    }

    async save() {
      try {
        const data = Ember.getProperties(this, Object.keys(calloutBannerValidations));
        await (0, _models.validateSchema)(CalloutBanner.schema, data, {
          abortEarly: false
        });
        await (0, _request.request)({
          method: 'POST',
          url: (0, _request.apiURL)(`static/${CalloutBanner.s3FileName}`),
          data: JSON.stringify({
            callout_banner: data
          })
        });
      } catch (error) {
        (0, _request.throwError)(error);
      }
    }

    toJSON() {
      return this.enumerableFields.reduce((json, field) => {
        json[field] = this[field];
        return json;
      }, {});
    }

  }, _defineProperty(_class2, "s3FileName", 'flaneur-callout-banner.json'), _defineProperty(_class2, "schema", _joi.default.object(calloutBannerValidations)), _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "body", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "icon", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "enabled", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "updated_by", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.CalloutBanner = CalloutBanner;
  let Notification = (_dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, (_class3 = (_temp2 = _class4 = class Notification {
    constructor({
      _id,
      title,
      body,
      button,
      route,
      external_link,
      schedule = {
        start_date: null,
        end_date: null
      },
      filters = {
        countries: [],
        zones: [],
        b2b_services: [],
        boutiques: [],
        tiers: [],
        promotions: [],
        feed_boutiques: []
      },
      featured = false,
      created_by,
      created_at,
      updated_by,
      updated_at
    } = {}) {
      _initializerDefineProperty(this, "title", _descriptor7, this);

      _initializerDefineProperty(this, "body", _descriptor8, this);

      _initializerDefineProperty(this, "button", _descriptor9, this);

      _initializerDefineProperty(this, "route", _descriptor10, this);

      _initializerDefineProperty(this, "external_link", _descriptor11, this);

      _initializerDefineProperty(this, "featured", _descriptor12, this);

      _initializerDefineProperty(this, "created_by", _descriptor13, this);

      _initializerDefineProperty(this, "created_at", _descriptor14, this);

      _initializerDefineProperty(this, "updated_by", _descriptor15, this);

      _initializerDefineProperty(this, "updated_at", _descriptor16, this);

      this._id = _id;
      this.title = title;
      this.body = body;
      this.button = button;
      this.route = route;
      this.external_link = external_link;
      this.featured = featured;
      this.created_by = created_by;
      this.created_at = created_at;
      this.updated_by = updated_by;
      this.updated_at = updated_at;
      this.schedule = new _trackedBuiltIns.TrackedObject(schedule);
      this.filters = new _trackedBuiltIns.TrackedObject(filters);
      this.enumerableFields = Object.keys(notificationValidations);
    }

    toJSON() {
      return this.enumerableFields.reduce((json, field) => {
        json[field] = this[field];
        return json;
      }, {});
    }

  }, _defineProperty(_class4, "schema", _joi.default.object(notificationValidations)), _temp2), (_descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "title", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "body", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class3.prototype, "button", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class3.prototype, "route", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class3.prototype, "external_link", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class3.prototype, "featured", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class3.prototype, "created_by", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class3.prototype, "created_at", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class3.prototype, "updated_by", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class3.prototype, "updated_at", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class3));
  _exports.Notification = Notification;

  class NotificationsCentre {
    static async getAll({
      page,
      limit
    } = {}) {
      try {
        const {
          static: {
            notifications
          }
        } = await (0, _request.request)({
          url: (0, _request.apiURL)(`static/${this.s3FileName}`)
        });
        const allNotifications = notifications.map(notification => new Notification(notification));
        let sortedNotifications = allNotifications.sort((a, b) => -a.created_at.localeCompare(b.created_at));

        if (page) {
          sortedNotifications = sortedNotifications.slice(limit * (page - 1), limit * page);
        }

        return {
          notifications: sortedNotifications,
          meta: {
            page,
            limit,
            page_total: allNotifications.length === 0 ? 1 : Math.ceil(allNotifications.length / limit)
          }
        };
      } catch (error) {
        (0, _request.throwError)(error);
      }
    }

    static getNextId(notifications) {
      const [lastId] = notifications.map(({
        _id
      }) => parseInt(_id, 10)).sort((a, b) => b - a);
      return (lastId + 1).toString();
    }

    static saveAll(notifications = []) {
      return (0, _request.request)({
        method: 'POST',
        url: (0, _request.apiURL)(`static/${this.s3FileName}`),
        data: JSON.stringify({
          notifications
        })
      });
    }

  }

  _exports.default = NotificationsCentre;

  _defineProperty(NotificationsCentre, "s3FileName", 'flaneur-notifications-centre.json');
});