define("backalley/models/app", ["exports", "backalley/util/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class App {
    static notify(appName, data = {}) {
      return (0, _request.request)({
        method: 'POST',
        url: (0, _request.apiURL)(`apps/${appName}`),
        data: JSON.stringify(data)
      });
    }

  }

  _exports.default = App;
});