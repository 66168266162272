define("backalley/pods/components/form-text/component", ["exports", "backalley/util/default-to"], function (_exports, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['form-text'],
    value: '',
    debounce: false,
    type: (0, _defaultTo.default)('text'),
    disabled: false,
    required: false,
    parseNumber: null,
    // Default max length
    maxlength: (0, _defaultTo.default)(524288),
    // readonly: true specifies that an input field is read-only
    readonly: (0, _defaultTo.default)(false),
    inputClass: Ember.computed('disabled', function () {
      const disabledClass = this.disabled ? 'disabled' : '';
      return `input ${disabledClass}`;
    }),
    debounced: function (value) {
      if (!this.action) {
        return;
      }

      if (this.type === 'number' && typeof this.parseNumber === 'function') {
        value = Ember.isPresent(value) ? this.parseNumber(value) : null;
      }

      this.sendAction('action', value);
    },
    actions: {
      _onInput(e) {
        const value = Ember.get(e, 'target.value');
        if (this.debounce && !Ember.testing) Ember.run.debounce(this, this.debounced, value, 1000);else this.debounced(value);
      },

      blur(e) {
        const value = Ember.get(e, 'target.value');
        this.sendAction('blur', value);
      },

      keypress(e) {
        const value = Ember.get(e, 'target.value');
        if (e.keyCode === 13 && this.submit) this.sendAction('submit', value);
      }

    }
  });

  _exports.default = _default;
});