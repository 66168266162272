define("backalley/pods/components/frame-form/lifestyle/component", ["exports", "ember-component-css/pod-names"], function (_exports, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FrameFormLifestyleComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = (_temp = class FrameFormLifestyleComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "url", _descriptor, this);
    }

    didInsertElement() {
      super.didInsertElement(...arguments);

      if (this.item) {
        this.url = this.item.url;
      }
    }

    get styleNamespace() {
      return _podNames.default['frame-form/lifestyle'];
    }

    get imageFolder() {
      return 'frames/lifestyle';
    }

    get backgroundImage() {
      return !this.url ? '' : Ember.String.htmlSafe(`background-image: url("${this.url}");`);
    }

    get hasImage() {
      return Ember.isPresent(this.url);
    }

    onUpload({
      url
    }) {
      this.url = url;
      this.onUpdateLifestyle(url);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "url", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onUpload", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onUpload"), _class.prototype)), _class));
  _exports.default = FrameFormLifestyleComponent;
});