define("backalley/pods/main/landing/edit/route", ["exports", "backalley/pods/main/landing/new/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Edit functionality is the same as new, so re-exporting the /new route
  var _default = _route.default.extend({
    templateName: 'main/landing/new'
  });

  _exports.default = _default;
});