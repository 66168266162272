define("backalley/pods/main/tasks/new/route", ["exports", "backalley/models/task", "backalley/models/user"], function (_exports, _task, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        task: _task.default.create(),
        users: _user.default.find({
          upworker: true
        })
      });
    },

    actions: {
      backToTasks() {
        return this.transitionTo('main.tasks');
      },

      create(task) {
        return task.post().then(() => this.transitionTo('main.tasks'), err => this.notify.show('err', err.message));
      }

    }
  });

  _exports.default = _default;
});