define("backalley/mirage/config", ["exports", "moment", "ember-cli-mirage", "backalley/config/environment", "backalley/enums/fieldExcludedFromAutomation"], function (_exports, _moment, _emberCliMirage, _environment, _fieldExcludedFromAutomation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function sortByKey(array, sortKey, sortAsc) {
    return array.sort((a, b) => {
      if (sortAsc) {
        return a[sortKey] > b[sortKey];
      } else {
        return a[sortKey] < b[sortKey];
      }
    });
  }

  function requestHeaderMatches(requestHeaders, name = '', value = '') {
    const lowerCaseName = name.toLowerCase();
    const lowerCaseValue = value.toLowerCase();
    return Ember.getWithDefault(requestHeaders, lowerCaseName, '').toLowerCase() === lowerCaseValue;
  }

  function _default() {
    let loggedIn = true;
    this.namespace = '/api_test/v2'; // Product Delivery Tags mock request

    this.get('product_delivery_tags', function () {
      return {
        product_delivery_tags: [{
          _id: '5f2159f322e61f708366b9e5',
          type: 'dropship',
          label: 'dropship: generic'
        }]
      };
    }); // Delivery Modifiers mock request

    this.get('delivery_modifiers', function (_, request) {
      let type = request.queryParams.type;

      if (type !== _fieldExcludedFromAutomation.default.WEIGHT_BRACKET) {
        return;
      }

      return {
        delivery_modifiers: [{
          _id: '5f215b657688137171fd9aba',
          name: 'light',
          type: 'weight_bracket',
          shipping_group_configuration: null,
          created_at: '2020-07-29T11:20:05.320Z',
          updated_at: '2020-07-29T11:20:05.321Z'
        }, {
          _id: '5f215b657688137171fd9abb',
          name: 'heavy',
          type: 'weight_bracket',
          shipping_group_configuration: null,
          created_at: '2020-07-29T11:20:05.348Z',
          updated_at: '2020-07-29T11:20:05.349Z'
        }]
      };
    });
    /**
     * Currency statements api
     */

    this.get('currency_statements/config', function (db, server) {
      const periods = [{
        period: 24,
        year: 2017,
        start: (0, _moment.default)('2017-12-15').toDate(),
        end: (0, _moment.default)('2017-12-31').toDate()
      }, {
        period: 1,
        year: 2018,
        start: (0, _moment.default)('2018-01-01').toDate(),
        end: (0, _moment.default)('2018-01-15').toDate()
      }];
      let selectedPeriod = periods[periods.length - 1];

      if (server.queryParams.period && server.queryParams.year) {
        selectedPeriod = periods.find(p => {
          return p.year === Number(server.queryParams.year) && p.period === Number(server.queryParams.period);
        });
      }

      return {
        selectedPeriod,
        selectedCurrency: 'EUR',
        periods,
        currencies: ['EUR', 'GBP']
      };
    });
    this.get('currency_statements/boutiques', function ({
      db
    }, request) {
      const {
        currency = 'EUR'
      } = request.queryParams;
      const boutiques = db.boutiques.where({
        currency
      }).map(boutique => boutique._id);
      return {
        boutiques
      };
    });
    this.get('currency_statements', function ({
      db
    }, request) {
      const {
        boutique
      } = request.queryParams;
      const currency_statements = db.currencyStatements.where({
        boutique
      });
      return {
        currency_statements
      }; // Delay responding back for this api call by 1000ms
    }, {
      timing: 1000
    });
    /*
    Comments mock api
    */

    this.get('comments', function ({
      db
    }, request) {
      const params = request.queryParams;
      delete params.sort;
      const comments = db.comments.where(params);
      return {
        comments
      };
    });
    this.post('comments', function (_, request) {
      const comment = server.create('comment', JSON.parse(request.requestBody));
      return {
        comments: [comment]
      };
    });
    /*
    Tags mock api
    */

    this.get('tags', function ({
      db
    }, request) {
      let sort = request.queryParams.sort;
      let page = Number(request.queryParams.page);
      let limit = Number(request.queryParams.limit);
      let label = request.queryParams['label[$regex]'];
      let search = request.queryParams['$text[$search]'];
      let is_filter = request.queryParams.is_filter;
      let _id = request.queryParams._id;
      let tags = _id ? db.tags.where({
        _id
      }) : db.tags; // Support both full-text search and label search.

      if (label && label !== '^') {
        tags = tags.filter(tag => tag.label.match(label));
      }

      if (search) {
        tags = tags.filter(tag => tag.label.match(search));
      }

      if (sort) {
        let sortType = sort.indexOf('-') === -1 ? true : false;
        let sortKey = sortType === false ? sort.substr(1) : sort;
        sortKey = sortKey === 'label' ? 'label' : sort;
        tags = sortByKey(tags, sortKey, sortType);
      }

      if (is_filter) {
        tags = tags.filter(tag => tag.is_filter === true);
      }

      if (limit && page) {
        tags = page === 1 ? tags.slice(0, limit) : tags.slice(limit * (page - 1), limit * page);
      }

      return {
        tags: tags,
        meta: {
          total: db.tags.length
        }
      };
    });
    this.get('tags/:id', function ({
      db
    }, request) {
      const tag = db.tags.find(Number(request.params.id));

      if (!tag) {
        return {
          tags: []
        };
      }

      return {
        tags: [tag]
      };
    });
    this.del('tags/:id', function ({
      db
    }, request) {
      db.tags.remove({
        _id: request.params.id
      });
    });
    this.post('tags', function (_, request) {
      let tag = server.create('tag', JSON.parse(request.requestBody));
      return {
        tags: [tag]
      };
    });
    this.put('tags/:id', function ({
      db
    }, request) {
      var data = JSON.parse(request.requestBody);
      var tag = db.tags.update(Number(request.params.id), data);
      return {
        tags: [tag]
      };
    });
    /*
    Tasks mock api
    */

    this.get('tasks', function ({
      db
    }, request) {
      let sort = request.queryParams.sort;
      let page = Number(request.queryParams.page);
      let limit = Number(request.queryParams.limit);
      let _id = request.queryParams._id;
      let tasks = _id ? db.tasks.where({
        _id
      }) : db.tasks;

      if (sort) {
        let sortType = sort.indexOf('-') === -1 ? true : false;
        let sortKey = sortType === false ? sort.substr(1) : sort;
        sortKey = sortKey === 'label' ? 'label' : sort;
        tasks = sortByKey(tasks, sortKey, sortType);
      }

      if (limit && page) {
        tasks = page === 1 ? tasks.slice(0, limit) : tasks.slice(limit * (page - 1), limit * page);
      }

      return {
        tasks,
        meta: {
          total: db.tasks.length
        }
      };
    });
    this.get('tasks/:id', function ({
      db
    }, request) {
      const task = db.tasks.find(Number(request.params.id));

      if (!task) {
        return {
          tasks: []
        };
      }

      return {
        tasks: [task]
      };
    });
    this.del('tasks/:id', function ({
      db
    }, request) {
      db.tasks.remove({
        _id: request.params.id
      });
    });
    this.post('tasks', function (_, request) {
      let task = server.create('task', JSON.parse(request.requestBody));
      return {
        tasks: [task]
      };
    });
    this.put('tasks/:id', function ({
      db
    }, request) {
      let data = JSON.parse(request.requestBody);
      let task = db.tasks.update(Number(request.params.id), data);
      return {
        tasks: [task]
      };
    });
    /*
    Products mock api
    */

    const searchProducts = function ({
      db
    }, request) {
      let products = db.products;
      let total = db.products.length;
      let tags = request.queryParams.tags;

      if (request.queryParams._id) {
        return {
          products: [products.find(Number(request.queryParams._id))]
        };
      }

      products.forEach(product => {
        ['categories', 'colors', 'tags'].forEach(key => {
          const populate = request.queryParams.populate || '';

          if (populate.includes(key)) {
            product[key] = [db[key].find(1)];
          }
        });
      });

      if (tags && products) {
        products = products.filter(product => {
          return product.tags.some(tag => tag._id === product.id);
        });
        total = products.length;
      }

      return {
        products,
        meta: {
          total
        }
      };
    };

    this.get('products', searchProducts);
    this.put('products/:id', function ({
      db
    }, request) {
      var data = JSON.parse(request.requestBody);
      var product = db.products.update(Number(request.params.id), data);
      return {
        products: [product]
      };
    });
    this.post('products', function ({
      db
    }, request) {
      if (requestHeaderMatches(request.requestHeaders, 'X-HTTP-Method-Override', 'GET')) {
        request.queryParams = JSON.parse(request.requestBody);
        return searchProducts({
          db
        }, request);
      }

      var product = JSON.parse(request.requestBody);
      return {
        products: [product]
      };
    });
    this.put('products/:id', function ({
      db
    }, request) {
      var data = JSON.parse(request.requestBody);
      var product = db.tags.update(Number(request.params.id), data);
      return {
        products: [product]
      };
    });
    /*
    Static mock api
    */

    this.get('static/info.json', function ({
      db
    }) {
      return db.infojsons.find(1);
    });
    this.post('static/info.json', function ({
      db
    }, request) {
      return db.infojsons.update(1, JSON.parse(request.requestBody));
    });
    let staticFormat = {
      meta: {
        status: true,
        name: 'landing.json'
      },
      static: {
        pages: []
      }
    };

    function createOrUpdateLandingPage(db, landingRequest) {
      const requestBodyId = landingRequest.id.replace('.json', '');
      const landingPageExists = db.landingjsons.find(requestBodyId);

      if (landingRequest.id && landingPageExists) {
        db.landingjsons.update(requestBodyId, landingRequest);
      } else {
        db.landingjsons.insert(landingRequest);
      }
    }

    this.get('static/landing.json', function ({
      db
    }) {
      const response = _objectSpread(_objectSpread({}, staticFormat), {}, {
        static: {
          pages: db.landingjsons
        }
      });

      return response;
    });
    this.post('static/landing.json', function ({
      db
    }, request) {
      let requestBody = JSON.parse(request.requestBody);
      requestBody.pages.forEach(page => {
        createOrUpdateLandingPage(db, page);
      });
      return _objectSpread(_objectSpread({}, staticFormat), {}, {
        static: {
          pages: db.landingjsons
        }
      });
    });
    this.post('static/landing/:landingpage', function ({
      db
    }, request) {
      const requestBody = JSON.parse(request.requestBody);
      createOrUpdateLandingPage(db, requestBody);
      return _objectSpread(_objectSpread({}, staticFormat), {}, {
        static: requestBody
      });
    });
    this.get('static/team-notes/**', function () {
      return {};
    });
    this.post('static/team-notes/**', function () {
      return {};
    });
    /*
    Categories mock api
    */

    this.get('categories', function ({
      db
    }, request) {
      let sort = request.queryParams.sort;
      let page = Number(request.queryParams.page);
      let limit = Number(request.queryParams.limit);
      let name = request.queryParams['name[$regex]'];
      let search = request.queryParams['$text[$search]'];
      let _id = request.queryParams._id;
      let categories = _id ? db.categories.where({
        _id
      }) : db.categories;
      let tag = request.queryParams.tags; // If we need to get all categories assosiated with a tag

      if (tag) {
        categories = db.categories.filter(cat => {
          return cat.tags.some(currentTag => currentTag._id === Number(tag));
        });
        return {
          categories: categories,
          meta: {
            total: categories.length
          }
        };
      } // In any other scenario, just find the categories


      if (name && name !== '^') {
        categories = categories.filter(cat => cat.name.indexOf(name.substr(1)) === 0);
      }

      if (search) {
        categories = categories.filter(cat => {
          return cat.name.indexOf(search) > -1;
        });
      }

      if (sort) {
        let sortType = sort.indexOf('-') === -1 ? true : false;
        let sortKey = sortType === false ? sort.substr(1) : sort;
        sortKey = sortKey === 'label' ? 'name' : sort;
        categories = sortByKey(categories, sortKey, sortType);
      }

      if (limit && page) {
        categories = page === 1 ? categories.slice(0, limit) : categories.slice(limit * (page - 1), limit * page);
      }

      return {
        categories: categories,
        meta: {
          total: db.categories.length
        }
      };
    });
    this.post('categories', function (_, request) {
      var category = JSON.parse(request.requestBody);
      return {
        categories: [category]
      };
    });
    this.del('categories/:id', function ({
      db
    }, request) {
      db.categories.remove(request.params.id);
      return {
        categories: db.categories
      };
    });
    this.get('categories/:id', function ({
      db
    }, request) {
      var category = db.categories.find(Number(request.params.id));

      if (!category) {
        return {
          categories: []
        };
      }

      return {
        categories: [category]
      };
    });
    this.put('categories/:id', function ({
      db
    }, request) {
      var data = JSON.parse(request.requestBody);
      var category = db.categories.update(Number(request.params.id), _objectSpread(_objectSpread({}, data), {
        updated_at: new Date()
      }));
      return {
        categories: [category]
      };
    });
    /*
    Colors mock api
    */

    this.get('colors', function ({
      db
    }, request) {
      let colors = db.colors;
      let sort = request.queryParams.sort;
      let label = request.queryParams['label[$regex]'];
      let search = request.queryParams['$text[$search]'];
      let page = Number(request.queryParams.page);
      let limit = Number(request.queryParams.limit);

      if (label) {
        colors = colors.filter(color => color.label.match(label));
      }

      if (search) {
        colors = colors.filter(color => color.label.match(search));
      }

      if (sort) {
        let sortType = sort.indexOf('-') === -1 ? true : false;
        let sortKey = sortType === false ? sort.substr(1) : sort;
        colors = sortByKey(colors, sortKey, sortType);
      }

      if (limit && page) {
        colors = page === 1 ? colors.slice(0, limit) : colors.slice(limit * (page - 1), limit * page);
      }

      return {
        colors,
        meta: {
          total: db.colors.length,
          page
        }
      };
    });
    this.post('colors', function (_, request) {
      let color = server.create('color', JSON.parse(request.requestBody));
      return {
        colors: [color]
      };
    });
    this.get('colors/:id', function ({
      db
    }, request) {
      const color = db.colors.find(request.params.id);

      if (!color) {
        return {
          colors: []
        };
      }

      return {
        colors: [color]
      };
    });
    this.del('colors/:id', function ({
      db
    }, request) {
      db.colors.remove({
        _id: request.params.id
      });
    });
    this.put('colors/:id', function ({
      db
    }, request) {
      let color = db.colors.find(request.params.id);
      let data = JSON.parse(request.requestBody);
      let label = data.label ? data.label : color.label;
      let value = data.value ? data.value : color.value;
      db.colors.update(color.id, _objectSpread(_objectSpread({}, {
        label,
        value
      }), {
        updated_at: new Date()
      }));
      return {
        colors: [db.colors.find(request.params.id)]
      };
    });
    /*
    Manufacturer mock api
    */

    this.get('manufacturers', function () {
      return {
        manufacturers: [{
          name: 'Something'
        }],
        meta: {
          total: 1
        }
      };
    });
    this.post('manufacturers', function (_, request) {
      if (requestHeaderMatches(request.requestHeaders, 'X-HTTP-Method-Override', 'GET')) {
        return {
          manufacturers: [{
            name: 'Something'
          }],
          meta: {
            total: 1
          }
        };
      }
    });
    /*
    Currencies mock api
    */

    this.get('currencies', function ({
      db
    }, request) {
      let currencies = db.currencies;
      let sortKey = request.queryParams.sort;
      let page = Number(request.queryParams.page);
      let limit = Number(request.queryParams.limit);

      if (request.queryParams['label[$regex]']) {
        currencies = currencies.filter(currency => currency.label.match(request.queryParams['label[$regex]']));
      }

      if (sortKey) {
        let sortType = sortKey.indexOf('-') === -1 ? true : false;
        currencies = sortByKey(currencies, sortKey, sortType);
      }

      if (limit && page) {
        currencies = page === 1 ? currencies.slice(0, limit) : currencies.slice(limit * (page - 1), limit * page);
      }

      return {
        currencies,
        meta: {
          total: db.currencies.length,
          page
        }
      };
    });
    this.post('currencies', function (_, request) {
      let currency = server.create('currency', JSON.parse(request.requestBody));
      return {
        currencies: [currency]
      };
    });
    this.get('currencies/:id', function ({
      db
    }, request) {
      const currency = db.currencies.find(request.params.id);

      if (!currency) {
        return {
          currencies: []
        };
      }

      return {
        currencies: [currency]
      };
    });
    this.del('currencies/:id', function ({
      db
    }, request) {
      db.currencies.remove({
        _id: request.params.id
      });
    });
    this.put('currencies/:id', function ({
      db
    }, request) {
      let currency = db.currencies.find(request.params.id);
      let data = JSON.parse(request.requestBody);
      let label = data.label ? data.label : currency.label;
      let value = data.value ? data.value : currency.value;
      db.currencies.update(currency.id, _objectSpread(_objectSpread({}, {
        label,
        value
      }), {
        updated_at: new Date()
      }));
      return {
        currencies: [db.currencies.find(request.params.id)]
      };
    });
    /*
    Colors mock api
    */

    this.get('boutiques', function ({
      db
    }, request) {
      let boutiques = db.boutiques;
      let sortKey = request.queryParams.sort;
      let page = Number(request.queryParams.page);
      let limit = Number(request.queryParams.limit);
      let name = request.queryParams['name[$regex]'];
      let search = request.queryParams['$text[$search]'];

      if (name) {
        boutiques = boutiques.filter(boutique => boutique.name.match(name));
      }

      if (search) {
        boutiques = boutiques.filter(boutique => boutique.name.match(search));
      }

      if (sortKey) {
        let sortType = sortKey.indexOf('-') === -1 ? true : false;
        let sortKey = sortType === false ? sortKey.substr(1) : sortKey;
        sortKey = sortKey === 'label' ? 'name' : sortKey;
        boutiques = sortByKey(boutiques, sortKey, sortType);
      }

      const allBoutiques = boutiques.length;

      if (limit && page) {
        boutiques = page === 1 ? boutiques.slice(0, limit) : boutiques.slice(limit * (page - 1), limit * page);
      }

      return {
        boutiques,
        meta: {
          page,
          total: db.boutiques.length,
          page_total: allBoutiques === 0 || !limit ? 1 : Math.ceil(allBoutiques / limit)
        }
      };
    });
    this.post('boutiques', function ({
      db
    }, request) {
      let boutique = server.create('boutique', JSON.parse(request.requestBody));
      return {
        boutiques: [boutique]
      };
    });
    this.get('boutiques/:id', function ({
      db
    }, request) {
      const boutique = db.boutiques.find(request.params.id);

      if (!boutique) {
        return {
          boutiques: []
        };
      }

      return {
        boutiques: [boutique]
      };
    });
    this.del('boutiques/:id', function ({
      db
    }, request) {
      db.boutiques.remove({
        _id: request.params.id
      });
    });
    this.put('boutiques/:id', function ({
      db
    }, request) {
      let boutique = db.boutiques.find(request.params.id);
      let data = JSON.parse(request.requestBody);
      let label = data.label ? data.label : boutique.label;
      let value = data.value ? data.value : boutique.value;
      db.boutiques.update(boutique.id, {
        label,
        value
      });
      return {
        boutiques: [db.boutiques.find(request.params.id)]
      };
    });
    /*
    Auth
    */

    this.get('isloggedin', function () {
      return {
        isLoggedIn: loggedIn,
        user: {
          role: 'admin'
        }
      };
    });
    this.post('login', function (_, request) {
      const data = JSON.parse(request.requestBody);
      const adminUser = {
        email: 'admin@trouva.com',
        password: 'password',
        role: 'admin'
      };
      const hubUser = {
        email: 'hub@trouva.com',
        password: 'password',
        role: 'hub'
      };
      const isAdmin = data.email === adminUser.email && data.password === adminUser.password;
      const isHub = data.email === hubUser.email && data.password === hubUser.password;
      loggedIn = isAdmin || isHub;
      const user = isAdmin ? adminUser : hubUser;

      if (loggedIn) {
        return _objectSpread(_objectSpread({}, user), {}, {
          isLoggedIn: loggedIn
        });
      } else {
        return new _emberCliMirage.Response(403, {}, {
          errors: [{
            code: 'AuthError',
            detail: 'Credentials incorrect',
            status: 403,
            title: 'Not authorized'
          }]
        });
      }
    });
    this.get('logout', function () {
      loggedIn = false;
    });
    this.get('statements', function ({
      db
    }, req) {
      const statements = db.statementindices.filter(s => s.boutique === req.queryParams.boutique);
      return {
        statements
      };
    });
    /**
     * Zones
     */

    function buildZones(zones, db) {
      const buildZone = zone => {
        zone.countries = db.countries.where({
          zone: zone._id
        });
        return zone;
      };

      if (Ember.isArray(zones)) return zones.map(buildZone);
      return buildZone(zones);
    } // Create zone


    this.post('zones', function ({
      db
    }, request) {
      const body = JSON.parse(request.requestBody);
      const countries = body.countries;
      delete body.countries;
      const zone = db.zones.insert(_objectSpread({}, body)); // Update country zones

      countries.forEach(curr => {
        db.countries.update({
          _id: curr._id
        }, {
          zone: zone._id
        });
      });
      return {
        zones: [buildZones(zone, db)]
      };
    }); // Delete zone

    this.del('zones/:id', function ({
      db
    }, request) {
      const _id = request.params.id;
      db.zones.remove(_id);
      return {};
    }); // Find zones

    this.get('zones', function ({
      db
    }) {
      const zones = db.zones;
      return {
        zones: buildZones(zones, db)
      };
    }); // Get single zone

    this.get('zones/:id', function ({
      db
    }, request) {
      const _id = request.params.id;
      const zone = db.zones.find(_id);

      if (!zone) {
        return {
          zones: []
        };
      }

      return {
        zones: [buildZones(zone, db)]
      };
    });
    this.put('zones/:id', function ({
      db
    }, request) {
      const _id = request.params.id;
      const body = JSON.parse(request.requestBody);
      const newCountries = body.countries;
      const oldCountries = db.countries.where({
        zone: _id
      });
      const zone = db.zones.find(_id); // Remove countries from body, update zone.

      delete body.countries;
      db.zones.update({
        _id
      }, _objectSpread({}, body)); // Remove old countries

      oldCountries.forEach(curr => {
        db.countries.update({
          _id: curr._id
        }, {
          zone: 0
        });
      });
      newCountries.forEach(curr => {
        db.countries.update({
          _id: curr._id
        }, {
          zone: zone._id
        });
      });
      return {
        zones: [buildZones(zone, db)]
      };
    });
    /**
     * Countries
     */
    // Create country

    this.post('countries', function ({
      db
    }, request) {
      const body = JSON.parse(request.requestBody);
      const country = db.countries.insert(_objectSpread({}, body));
      country.zone = db.zones.where({
        _id: country.zone
      })[0];
      country.currency = db.currencies.where({
        _id: country.currency
      })[0];
      return {
        countries: [country]
      };
    }); // Delete country

    this.del('countries/:id', function ({
      db
    }, request) {
      const _id = request.params.id;
      db.countries.remove(_id);
      return {};
    }); // Find countries

    this.get('countries', function ({
      db
    }) {
      const countries = db.countries.map((_ref) => {
        let {
          zone,
          currency
        } = _ref,
            rest = _objectWithoutProperties(_ref, ["zone", "currency"]);

        return _objectSpread({
          zone: db.zones.where({
            _id: zone
          })[0],
          currency: db.zones.where({
            _id: currency
          })[0]
        }, rest);
      });
      return {
        countries
      };
    }); // Get single country

    this.get('countries/:id', function ({
      db
    }, request) {
      const _id = request.params.id;
      const country = db.countries.find(_id);
      country.zone = db.zones.where({
        _id: country.zone
      })[0];

      if (!country) {
        return {
          countries: []
        };
      }

      return {
        countries: [country]
      };
    }); // Brand

    this.get('brands/:id', function () {
      return {
        brands: [{}]
      };
    });
    this.put('brands/:id', function () {
      return {
        brands: [{}]
      };
    }); // Zendesk tickets
    // Search

    this.get('zendesk/search', function ({
      db
    }, request) {
      const query = request.queryParams.query;
      const tickets = db.tickets.where({
        ordernumber: query
      });
      return tickets;
    });
    this.get('zendesk/users/:id', function () {
      return {};
    });
    this.get('product_amendments', function () {
      return {
        product_amendments: []
      };
    });
    this.get('quotes', function () {
      return {
        quotes: []
      };
    });
    this.post('quotes', function () {
      return {
        quotes: []
      };
    });
    this.post('shipping_group_configurations', (schema, request) => {
      const {
        requestBody
      } = request;
      const body = JSON.parse(requestBody);
      const allSGCs = schema.shippingGroupConfigurations.all().models;
      return {
        shipping_group_configurations: [_objectSpread({
          _id: 'FAKEID'
        }, body)]
      };
    });
    this.get('publisherlinks', function () {
      return {
        publisherlinks: []
      };
    });
    this.get('shipment_rates', (schema, request) => {
      const {
        queryParams: {
          page,
          limit,
          weight
        }
      } = request;
      let allRates = schema.shipmentRates.all();
      let rates = allRates;

      if (Ember.isPresent(weight)) {
        rates = schema.shipmentRates.where({
          weight
        });
      }

      if (limit && page) {
        rates = rates.slice(limit * (page - 1), limit * page);
      }

      return {
        shipment_rates: rates.models,
        meta: {
          page,
          page_total: allRates.length === 0 ? 1 : Math.ceil(allRates.length / limit),
          total: allRates.length
        }
      };
    });
    this.post('delighted/search', () => {
      return {
        responses: []
      };
    });

    const getOrders = (schema, request) => {
      const {
        queryParams: {
          page,
          limit,
          ordernumber
        }
      } = request;
      let allOrders = schema.orders.all();
      let orders = allOrders;

      if (Ember.isPresent(ordernumber)) {
        orders = schema.orders.where({
          ordernumber
        });
      }

      if (limit && page) {
        orders = orders.slice(limit * (page - 1), limit * page);
      }

      return {
        orders: orders.models,
        meta: {
          page,
          page_total: allOrders.length === 0 ? 1 : Math.ceil(allOrders.length / limit),
          total: allOrders.length
        }
      };
    };

    this.get('orders', getOrders);
    this.post('orders', (schema, request) => {
      if (requestHeaderMatches(request.requestHeaders, 'X-HTTP-Method-Override', 'GET')) {
        return getOrders(schema, request);
      }
    });
    /* curate-service */

    this.urlPrefix = _environment.default.curateApiURL;
    this.namespace = '/api/v1';
    this.get('orders/:id', (schema, request) => {
      const order = schema.curateOrders.find(request.params.id);
      return {
        orders: [order]
      };
    });
    this.get('orders', (schema, request) => {
      const {
        queryParams: {
          page,
          limit,
          sort,
          populate
        }
      } = request,
            params = _objectWithoutProperties(request.queryParams, ["page", "limit", "sort", "populate"]);

      let allOrders = schema.curateOrders.all();
      let orders = allOrders;

      if (Object.keys(params).length) {
        orders = schema.curateOrders.where(params);
      }

      if (limit && page) {
        orders = orders.slice(limit * (page - 1), limit * page);
      }

      return {
        orders: orders.models,
        meta: {
          page,
          page_total: allOrders.length === 0 ? 1 : Math.ceil(allOrders.length / limit),
          total: allOrders.length
        }
      };
    });
    this.get('brand_tags', () => {
      return {
        brand_tags: [],
        meta: {
          page: 1,
          page_total: 1,
          total: 0
        }
      };
    });
  }
});