define("backalley/mirage/factories/country", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.default.Factory.extend({
    _id: i => `${i + 1}`,
    name: i => `Country ${i}`,
    iso_code: i => `${i}`,
    weengs_name: i => `weengs-country-${i}`,
    zone: 1,
    currency: 1,
    created_at: i => {
      return new Date();
    }
  });

  _exports.default = _default;
});