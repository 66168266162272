define("backalley/pods/components/input-percentage/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['input-percentage'],
    value: null,
    basePercentage: 0,
    min: 0,
    max: 100,
    showError: false,

    onChange() {},

    valueInPercentage: Ember.computed('value', function () {
      const percentage = Number(this.value);

      if (percentage <= 0) {
        return 0;
      }

      return percentage * 100 - this.basePercentage;
    }),
    actions: {
      updatePercentage({
        target: {
          value
        }
      }) {
        value = Number(value);

        if (value < this.min || value > this.max) {
          return this.set('showError', true);
        }

        const percentage = (value + this.basePercentage) / 100;
        this.set('showError', false);
        this.onChange(percentage);
      }

    }
  });

  _exports.default = _default;
});