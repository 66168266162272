define("backalley/helpers/add-one", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addOne = addOne;
  _exports.default = void 0;

  function addOne([first, second, ...rest]) {
    return Number(first) + 1;
  }

  var _default = Ember.Helper.helper(addOne);

  _exports.default = _default;
});