define("backalley/pods/components/currency-statement-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u8GOxLzc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"iframe\",true],[11,\"srcdoc\",[22,\"statementHtmlString\"]],[10,\"style\",\"width:100%;\"],[10,\"scrolling\",\"no\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/currency-statement-preview/template.hbs"
    }
  });

  _exports.default = _default;
});