define("backalley/pods/components/frame-form/product/component", ["exports", "ember-component-css/pod-names", "backalley/models/product", "backalley/models/boutique", "ember-concurrency-decorators", "ember-concurrency"], function (_exports, _podNames, _product, _boutique, _emberConcurrencyDecorators, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FrameFormProductComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = (_temp = class FrameFormProductComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "productFields", ['name', 'local_price', 'thumbnail', 'archived', 'boutiques'].join(','));

      _defineProperty(this, "stockFilters", [{
        label: 'Any stock status',
        stock: null
      }, {
        label: 'In stock',
        stock: 'in'
      }, {
        label: 'Out of stock',
        stock: 'out'
      }]);

      _initializerDefineProperty(this, "product", _descriptor, this);

      _initializerDefineProperty(this, "isModalOpen", _descriptor2, this);

      _initializerDefineProperty(this, "page", _descriptor3, this);

      _initializerDefineProperty(this, "limit", _descriptor4, this);

      _initializerDefineProperty(this, "pageTotal", _descriptor5, this);

      _initializerDefineProperty(this, "checkedProduct", _descriptor6, this);

      _initializerDefineProperty(this, "boutiqueSelected", _descriptor7, this);

      _initializerDefineProperty(this, "searchResults", _descriptor8, this);

      _initializerDefineProperty(this, "searchQuery", _descriptor9, this);

      _initializerDefineProperty(this, "selectedStockFilter", _descriptor10, this);
    }

    async didInsert() {
      const productId = this.item.product_id;

      if (Ember.isPresent(productId)) {
        const products = await _product.default.find({
          _id: productId,
          fields: this.productFields
        });
        const [product] = products;
        this.product = product;
      }
    }

    get styleNamespace() {
      return _podNames.default['frame-form/product'];
    }

    get noResultsFound() {
      return Ember.isPresent(this.searchQuery) && Ember.isEmpty(this.searchResults);
    }

    *searchBoutique(query) {
      yield (0, _emberConcurrency.timeout)(250);
      return _boutique.default.find({
        name: {
          $regex: `^${query.trim()}`,
          $options: 'i'
        }
      });
    }

    clearSearch() {
      this.checkedProduct = null;
      this.searchQuery = '';
      this.searchResults = [];
    }

    *searchProducts() {
      const query = this.searchQuery;
      yield (0, _emberConcurrency.timeout)(300);

      if (Ember.isEmpty(query)) {
        return this.clearSearch();
      }

      const page = query !== this.searchQuery ? 1 : this.page;
      const parameters = {
        brand: this.boutiqueSelected.brand,
        free_text_search: query,
        page,
        limit: this.limit,
        use_new_filter: true,
        archived: false,
        published: true,
        populate_standard_manufacturer: true,
        fields: this.productFields,
        attach_related: 'stock_lines',
        sort: '-created_at'
      };
      const {
        stock
      } = this.selectedStockFilter;

      if (stock !== null) {
        Ember.assign(parameters, {
          stock_lines_stock_status: stock
        });
      }

      const {
        products = [],
        meta = {}
      } = yield _product.default.findWithMetaAndCreate(parameters);
      const {
        page_total
      } = meta;
      this.searchResults = products;
      this.searchQuery = query;
      this.checkedProduct = null;
      this.pageTotal = page_total;
    }

    resetPage() {
      this.page = 1;
    }

    onChangePage(page) {
      this.page = page;
      this.searchProducts.perform();
    }

    incrementPage() {
      this.page = this.page + 1;
      this.searchProducts.perform();
    }

    decrementPage() {
      this.page = this.page - 1;
      this.searchProducts.perform();
    }

    onChangeBoutique(boutique) {
      this.resetPage();
      this.boutiqueSelected = boutique;
      this.searchProducts.perform();
    }

    onChangeStockFilter(filter) {
      this.resetPage();
      this.selectedStockFilter = filter;
      this.searchProducts.perform();
    }

    selectProduct() {
      this.product = this.checkedProduct;
      this.onUpdateProduct(this.item, this.product);
      this.closeModal();
    }

    openModal() {
      this.isModalOpen = true;
    }

    closeModal() {
      this.clearSearch();
      this.isModalOpen = false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "product", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isModalOpen", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 4;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "pageTotal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "checkedProduct", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "boutiqueSelected", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "searchResults", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "searchQuery", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "selectedStockFilter", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.stockFilters[0];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchBoutique", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchBoutique"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchProducts", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchProducts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangePage", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onChangePage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "incrementPage", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "incrementPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "decrementPage", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "decrementPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeBoutique", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeBoutique"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeStockFilter", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeStockFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectProduct", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "selectProduct"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openModal", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "openModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class));
  _exports.default = FrameFormProductComponent;
});