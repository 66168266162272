define("backalley/pods/components/curate-order-form/component", ["exports", "@glimmer/component", "backalley/enums/curate-order-status", "backalley/enums/curate-order-cancelled-reason"], function (_exports, _component, _curateOrderStatus, _curateOrderCancelledReason) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurateOrderFormComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class CurateOrderFormComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "notify", _descriptor, this);

      _initializerDefineProperty(this, "isAddReservationModalOpen", _descriptor2, this);

      _defineProperty(this, "cancelledReasonOptions", Object.values(_curateOrderCancelledReason.default));

      _defineProperty(this, "statusOptions", [{
        value: _curateOrderStatus.default.CREATED,
        description: "The order has been placed by the boutique",
        disabled: true
      }, {
        value: _curateOrderStatus.default.ACKNOWLEDGED,
        description: "The brand has acknowledged the order and will provide information about product availability, shipping, tax, etc.",
        action: "acknowledge"
      }, {
        value: _curateOrderStatus.default.CONFIRMED,
        description: "The order has been confirmed by the brand as is. No further changes can be made to reservations"
      }, {
        value: _curateOrderStatus.default.SHIPPED,
        description: "The order has been shipped by the brand"
      }, {
        value: _curateOrderStatus.default.PAID,
        description: "The boutique has paid for the order"
      }, {
        value: _curateOrderStatus.default.CANCELLED,
        description: "The order has been cancelled"
      }]);
    }

    get selectedStatus() {
      return this.statusOptions.find(({
        value
      }) => value === this.args.order.status);
    }

    get isReservationChangeStatusDisabled() {
      return [_curateOrderStatus.default.CONFIRMED, _curateOrderStatus.default.SHIPPED].includes(this.args.order.status);
    }

    get subTotal() {
      return this.args.order.reservations.reduce(({
        amount,
        currency
      }, {
        quantity,
        stock_line: {
          wholesale_price: {
            amount: productAmount
          }
        }
      }) => {
        return {
          amount: amount + productAmount * quantity,
          currency
        };
      }, {
        amount: 0,
        currency: this.args.order.reservations[0].stock_line.wholesale_price.currency
      });
    }

    onChangeStatus({
      value
    }) {
      this.args.order.status = value;
      const data = this.args.order.isCancelled ? {
        reason: _curateOrderCancelledReason.default.OTHER
      } : {};
      return this.args.order.update(data).then(() => this.notify.show('success', 'Order\'s status successfully updated! 🎉')).catch(error => this.notify.show('error', error));
    }

    onChangeReservationStatus(reservation, option) {
      return this.args.order.updateReservation(reservation, {
        status: option.value,
        reason: option.reason
      });
    }

    save() {
      const order = this.args.order;
      const {
        tax_price,
        shipping_price,
        discount_price
      } = order;
      return order.update({
        tax_price,
        shipping_price,
        discount_price
      }).then(() => this.notify.show('success', 'Order\'s successfully updated! 🎉')).catch(error => this.notify.show('error', error));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isAddReservationModalOpen", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onChangeStatus", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeReservationStatus", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeReservationStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = CurateOrderFormComponent;
});