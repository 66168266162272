define("backalley/services/paths-and-lanes", ["exports", "backalley/models/carrier-lane", "backalley/models/shipping-path", "backalley/models/path-and-lane-release"], function (_exports, _carrierLane, _shippingPath, _pathAndLaneRelease) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This has been created as a service because there are a few different
   * UIs in development that will all depend on Paths & Lanes. They will
   * require the Path information (and releases) to be managed across
   * locations. So a service made the most sense.
   */
  var _default = Ember.Service.extend({
    /**
     * Loads the latest release of Paths & Lanes from Inventory.
     *
     * This is much faster than requesting from Promenade because the resources
     * have already been fully calculated and indexed in Algolia. Algolia tends
     * to be behind/out-of-date however when performing a new CLASP release.
     * So inventory should only be used for accessing the latest live release.
     */
    fetchPathsAndLanesFromInventory() {
      const lanePromise = _carrierLane.default.findFromInventory({
        variant: 'standard'
      });

      const pathPromise = _shippingPath.default.findFromInventory({
        variant: 'standard'
      });

      return Promise.all([lanePromise, pathPromise]).then(([carrierLanes, shippingPaths]) => {
        return new _pathAndLaneRelease.default({
          release: {
            name: 'environment'
          },
          carrierLanes: carrierLanes.carrier_lanes,
          shippingPaths: shippingPaths.shipping_paths
        });
      });
    },

    /**
     * Loads the latest release of Paths & Lanes from Promenade.
     *
     * Paths & Lanes _can_ be loaded direct from Promenade. But it isn't fast.
     * This is because there is quite a bit of processing that occurs when
     * requesting these resources. This Promenade route should only really be
     * used when performing a CLASP release. Everything else should load the
     * data from Inventory (see above).
     */
    fetchPathsAndLanesFromPromenade() {
      const lanePromise = _carrierLane.default.find({
        variant: 'standard',
        limit: 499
      });

      const pathPromise = _shippingPath.default.find({
        variant: 'standard',
        limit: 499
      });

      return Promise.all([lanePromise, pathPromise]).then(([carrierLanes, shippingPaths]) => {
        return new _pathAndLaneRelease.default({
          release: {
            name: 'environment'
          },
          carrierLanes: carrierLanes.carrier_lanes,
          shippingPaths: shippingPaths.shipping_paths
        });
      });
    }

  });

  _exports.default = _default;
});