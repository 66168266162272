define("backalley/pods/main/prelaunch/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'q', 'limit', 'show_all_boutiques'],
    page: 1,
    q: '',
    limit: 30,
    show_all_boutiques: false
  });

  _exports.default = _default;
});