define("backalley/pods/main/currency-statements/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['period', 'year', 'currency', 'statement', 'statements_version', 'published', 'archived', 'page', 'limit', 'boutique'],

    init() {
      this._super(...arguments);

      this.statementVersionOptions = [{
        value: 'all',
        label: 'Statement System: All'
      }, {
        value: '2',
        label: 'Statement System: v2'
      }, {
        value: '3',
        label: 'Statement System: v3'
      }];
      this.publishedOptions = [{
        value: 'all',
        label: 'Published: All'
      }, {
        value: 'true',
        label: 'Published'
      }, {
        value: 'false',
        label: 'Not published'
      }];
      this.archivedOptions = [{
        value: 'all',
        label: 'Archived: All'
      }, {
        value: 'true',
        label: 'Archived'
      }, {
        value: 'false',
        label: 'Not archived'
      }];
      this.limitOptions = [{
        value: 24,
        label: 'Limit: 24 per page'
      }, {
        value: 48,
        label: 'Limit: 48 per page'
      }];
    },

    period: '',
    year: '',
    currency: '',
    statements_version: 'all',
    published: 'true',
    archived: 'false',
    page: 1,
    limit: 24,
    boutique: '',
    showCSV: false,
    routeName: 'main.currency_statements.index',
    selectedStatementVersion: Ember.computed('statements_version', function () {
      return this.statementVersionOptions.find(option => option.value === this.statements_version);
    }),
    selectedPublished: Ember.computed('published', function () {
      return this.publishedOptions.find(option => option.value === this.published);
    }),
    selectedArchived: Ember.computed('archived', function () {
      return this.archivedOptions.find(option => option.value === this.archived);
    }),
    selectedBoutique: Ember.computed('boutique', function () {
      return this.model.boutiquesOptions.find(boutique => boutique._id === this.boutique);
    })
  });

  _exports.default = _default;
});