define("backalley/enums/currencies", ["exports", "backalley/enums/types"], function (_exports, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _types.default.create({
    currencies: [{
      name: 'Euro',
      currency_code: 'EUR',
      sign: '€'
    }, {
      name: 'Pound sterling',
      currency_code: 'GBP',
      sign: '£'
    }, {
      name: 'Swedish krona',
      currency_code: 'SEK',
      sign: 'kr'
    }, {
      name: 'Danish krona',
      currency_code: 'DKK',
      sign: 'kr.'
    }]
  });

  _exports.default = _default;
});