define("backalley/util/joi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    string() {
      return this;
    },

    object() {
      return this;
    },

    boolean() {
      return this;
    },

    allow() {
      return this;
    },

    validate() {
      return this;
    },

    array() {
      return this;
    },

    items() {
      return this;
    },

    date() {
      return this;
    },

    min() {
      return this;
    },

    max() {
      return this;
    },

    keys() {
      return this;
    },

    number() {
      return this;
    },

    optional() {
      return this;
    },

    required() {
      return this;
    }

  };
  _exports.default = _default;
});