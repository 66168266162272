define("backalley/pods/main/areas/edit/route", ["exports", "backalley/models/area", "backalley/models/boutique"], function (_exports, _area, _boutique) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    serialize(model) {
      return {
        area_id: model._id
      };
    },

    model(params) {
      return Ember.RSVP.hash({
        area: _area.default.findById(params.area_id),
        actions: {
          updateArea: updateArea.bind(this)
        }
      }).then(Ember.Object.create.bind(Ember.Object));
    },

    afterModel(model) {
      return _boutique.default.find({
        areas: model.area._id,
        limit: 100
      }).then(boutiques => model.set('boutiques', boutiques));
    }

  });

  _exports.default = _default;

  function updateArea(area) {
    return area.save().then(() => this.transitionTo('main.areas'));
  }
});