define("backalley/models/event-store", ["exports", "joi", "backalley/util/request", "backalley/util/models"], function (_exports, _joi, _request, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const findValidations = {
    entityId: _models.resourceRef.required(),
    entity: _joi.default.string().valid('order')
  };

  class EventStore {
    static async findByEntityAndId({
      entity,
      entityId
    }) {
      await (0, _models.validateSchema)(EventStore.findSchema, {
        entity,
        entityId
      }, {
        abortEarly: false
      });

      try {
        const {
          events
        } = await (0, _request.request)({
          method: 'GET',
          url: (0, _request.apiURL)(`events-store/${entity}`),
          data: {
            entity_id: entityId
          }
        });
        return events;
      } catch (error) {
        (0, _request.throwError)(error);
      }
    }

  }

  _exports.default = EventStore;

  _defineProperty(EventStore, "findSchema", _joi.default.object().keys(_objectSpread({}, findValidations)));
});