define("backalley/models/googlemap", ["exports", "backalley/mixins/request", "fuse.js"], function (_exports, _request, _fuse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global google */
  const Googlemap = Ember.Object.extend(_request.default, {
    client: google.maps,

    findLocation(address) {
      const client = this.client;
      const geocoder = new client.Geocoder();
      return new Ember.RSVP.Promise(resolve => {
        geocoder.geocode({
          address
        }, (results, status) => {
          resolve({
            status,
            results
          });
        });
      });
    },

    nearbySearch(el, params) {
      const client = this.client;
      const service = new client.places.PlacesService(el); // Error handling if no name

      return new Ember.RSVP.Promise(resolve => {
        service.nearbySearch(params, (results, status) => {
          resolve({
            status,
            results
          });
        });
      });
    },

    getPlaceDetails(el, place) {
      const client = this.client;
      const service = new client.places.PlacesService(el);
      return new Ember.RSVP.Promise(resolve => {
        service.getDetails(place, (result, status) => {
          resolve({
            status,
            result
          });
        });
      });
    },

    fuzzyMatchPlace(array, term) {
      const options = {
        keys: ['name'],
        minMatchCharLength: 1,
        maxPatternLength: 32,
        threshold: 0.9
      };
      const fuse = new _fuse.default(array, options);
      return fuse.search(term);
    }

  });
  Googlemap.reopenClass(_request.default, {});
  var _default = Googlemap;
  _exports.default = _default;
});