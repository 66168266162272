define("backalley/pods/components/landing-page/partials/text/component", ["exports", "backalley/util/default-to", "backalley/pods/main/landing/new/selectOptions"], function (_exports, _defaultTo, _selectOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const noop = () => {};

  var _default = Ember.Component.extend({
    // Public API
    item: (0, _defaultTo.default)(null),
    onTextColorSelected: (0, _defaultTo.default)(noop),
    onPositionSelected: (0, _defaultTo.default)(noop),
    onTextAlignmentSelected: (0, _defaultTo.default)(noop),
    // Private API
    availableColorPickerOptions: (0, _defaultTo.default)(_selectOptions.availableColorPickerOptions),
    availableTextAligns: (0, _defaultTo.default)(_selectOptions.availableTextAligns),
    tagLabel: Ember.computed('item.tag', function () {
      const tag = this.get('item.tag');

      switch (tag) {
        case 'h1':
          return 'Heading 1';

        case 'h2':
          return 'Heading 2';

        case 'h3':
          return 'Heading 3';

        case 'h4':
          return 'Heading 4';

        case 'p':
          return 'Paragraph';

        default:
          return tag;
      }
    }),
    selectedTextAlign: Ember.computed('item.style.textAlign', function () {
      const selectedTextAlignOption = this.get('item.style.textAlign') || '';
      const availableTextAligns = this.availableTextAligns;
      return availableTextAligns.find(textAlign => textAlign.value === selectedTextAlignOption);
    })
  });

  _exports.default = _default;
});