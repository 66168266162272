define("backalley/pods/main/triggers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "leqOKL+D",
    "block": "{\"symbols\":[\"HotLoadPageHeading1d9a62wdw7z\"],\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"PageHeading\",[23,0,[]],\"PageHeading\",\"PageHeading\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"PageHeading\",\"PageHeading\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[],[[\"@fa\"],[\"circle\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"Triggers\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"\\n\\n\"],[1,[28,\"page-title\",[\"Triggers\"],null],false],[0,\"\\n\\n\"],[7,\"h2\",true],[8],[0,\"Retail Panel\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"triggers__section\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"Critical update\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"This will force a refresh on every browser with the Retail Panel open when the user transitions to another route.\"],[9],[0,\"\\n\\n  \"],[7,\"form\",false],[3,\"on\",[\"submit\",[23,0,[\"sendCriticalUpdateNotification\"]]]],[8],[0,\"\\n    \"],[7,\"button\",true],[10,\"class\",\"button button-primary\"],[10,\"type\",\"submit\"],[8],[0,\"\\n      Force refresh\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/triggers/template.hbs"
    }
  });

  _exports.default = _default;
});