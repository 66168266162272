define("backalley/models/curate/payment-company", ["exports", "backalley/util/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CuratePaymentCompany {
    constructor({
      id,
      name,
      status,
      identifiers
    }) {
      var _identifiers$find;

      const {
        value: registeredNumber
      } = (_identifiers$find = identifiers.find(({
        idtype
      }) => idtype === "reg_number")) !== null && _identifiers$find !== void 0 ? _identifiers$find : {};
      this.id = id;
      this.name = name;
      this.status = status;
      this.registered_number = registeredNumber;
    }

    static async find(query = {}) {
      return (0, _request.request)({
        url: (0, _request.curateApiURL)('payments/companies'),
        data: _objectSpread({}, query)
      }).then(({
        matches: companies
      }) => ({
        companies: companies.map(company => new CuratePaymentCompany(company))
      })).catch(error => (0, _request.throwCurateError)(error));
    }

    static async findById(id) {
      return (0, _request.request)({
        url: (0, _request.curateApiURL)(`payments/companies/${id}`)
      }).then(company => new CuratePaymentCompany(company)).catch(error => (0, _request.throwCurateError)(error));
    }

  }

  _exports.default = CuratePaymentCompany;
});