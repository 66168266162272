define("backalley/pods/main/sizes/edit/route", ["exports", "backalley/models/size"], function (_exports, _size) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    serialize(model) {
      return {
        size_id: model._id
      };
    },

    model(params) {
      return Ember.RSVP.hash(Ember.Object.create({
        size: _size.default.findById(params.size_id),
        actions: {
          updateSize: updateSize.bind(this)
        }
      }));
    }

  });

  _exports.default = _default;

  function updateSize(size) {
    return size.save().then(() => this.transitionTo('main.sizes'), err => this.notify.show('err', err.message));
  }
});