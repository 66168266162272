define("backalley/pods/main/manual-adjustments/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'period', 'year', 'adjustmentType', 'statusType'],
    page: 1,
    statusType: {
      label: 'Status: Show all',
      value: 'all'
    }
  });

  _exports.default = _default;
});