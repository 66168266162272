define("backalley/pods/components/basic-tooltip/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['info-tooltip', 'tooltip-box-view'],
    title: null,
    icon: null,
    hasQuestionMark: Ember.computed('icon', function () {
      return Ember.isEmpty(this.icon);
    }),
    hasIcon: Ember.computed('icon', function () {
      return Ember.isPresent(this.icon);
    }),
    hasTitle: Ember.computed('title', function () {
      return Ember.isPresent(this.title);
    })
  });

  _exports.default = _default;
});