define("backalley/pods/components/basic-notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UHJBKejn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"list\",\"new\",\"comments\"],[[28,\"component\",[[28,\"hot-load\",[[24,[\"listComponent\"]],[23,0,[]],[24,[\"listComponent\"]],\"listComponent\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"notes\",\"comments\"],[[23,0,[]],\"listComponent\",[24,[\"listComponent\"]],true,true,[28,\"readonly\",[[24,[\"publicAPI\"]]],null],[28,\"readonly\",[[24,[\"_comments\"]]],null]]]],[28,\"component\",[[28,\"hot-load\",[[24,[\"newComponent\"]],[23,0,[]],[24,[\"newComponent\"]],\"newComponent\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"notes\"],[[23,0,[]],\"newComponent\",[24,[\"newComponent\"]],true,true,[28,\"readonly\",[[24,[\"publicAPI\"]]],null]]]],[28,\"readonly\",[[24,[\"_comments\"]]],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/basic-notes/template.hbs"
    }
  });

  _exports.default = _default;
});