define("backalley/pods/main/currency_conversion_rates/index/route", ["exports", "backalley/models/currency_conversion_rates"], function (_exports, _currency_conversion_rates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ALL_CURRENCIES = 'All currencies';

  var _default = Ember.Route.extend({
    queryParams: {
      sort: {
        refreshModel: true
      },
      source: {
        refreshModel: true
      },
      destination: {
        refreshModel: true
      }
    },

    init() {
      this._super(...arguments);

      this.set('availableFilters', [{
        label: 'Source currency: A-Z',
        value: 'source_iso_4217'
      }, {
        label: 'Source currency: Z-A',
        value: '-source_iso_4217'
      }, {
        label: 'Dest. currency: A-Z',
        value: 'destination_iso_4217'
      }, {
        label: 'Dest. currency: Z-A',
        value: '-destination_iso_4217'
      }, {
        label: 'Date: last updated',
        value: '-updated_at'
      }, {
        label: 'Date: first updated',
        value: 'updated_at'
      }]);
    },

    getSelectableCurrencies() {
      return _currency_conversion_rates.default.find().then(({
        currency_conversion_rates
      }) => {
        let currencySet = new Set([ALL_CURRENCIES]);
        currency_conversion_rates.forEach(rate => {
          currencySet.add(rate.source_iso_4217);
        });
        return Array.from(currencySet);
      });
    },

    model({
      sort = {},
      source,
      destination
    }) {
      return _currency_conversion_rates.default.find({
        sort: sort.value,
        source_iso_4217: source === ALL_CURRENCIES ? undefined : source,
        destination_iso_4217: destination === ALL_CURRENCIES ? undefined : destination
      }).then(({
        currency_conversion_rates
      }) => {
        return {
          currency_conversion_rates,
          availableFilters: this.availableFilters,
          availableCurrencies: this.getSelectableCurrencies(),
          selectedSourceCurrency: source,
          selectedDestinationCurrency: destination
        };
      });
    },

    serializeQueryParam(value, urlKey) {
      if (urlKey === 'sort') return value.value;
      return value;
    },

    deserializeQueryParam(value, urlKey) {
      if (urlKey === 'sort') return this.availableFilters.findBy('value', value);
      return value;
    },

    actions: {
      updateFilter(filter) {
        this.set('controller.sort', filter);
      },

      selectSourceCurrency(currency) {
        this.set('controller.source', currency);
      },

      selectDestinationCurrency(currency) {
        this.set('controller.destination', currency);
      }

    }
  });

  _exports.default = _default;
});