define("backalley/util/dates", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.niceDate = niceDate;

  function niceDate(isoString) {
    return '' + (0, _moment.default)(isoString).format('LLLL');
  }
});