define("backalley/pods/main/feeds/permanent_rules/route", ["exports", "backalley/models/sync-integration"], function (_exports, _syncIntegration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model({
      syncintegration_id
    }) {
      const syncIntegration = await _syncIntegration.default.findById(syncintegration_id, {
        populate: 'settings.boutique'
      });
      return {
        syncIntegration
      };
    }

  });

  _exports.default = _default;
});