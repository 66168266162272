define("backalley/pods/components/tab-nav/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Navbar Component
   *
   * How?
   * - Uses a `show` class to display:block, otherwise hidden
   *
   * Example usage:
      {{#tab-nav tabs=(array 'Home' 'Analytics')}}
        <div class="tab-content">
          <content>
        </div>
        <div class="tab-content">
          <content>
        </div>
      {{/tab-nav}}
   *
   * Parameters pass in:
   * - `tabs`: Array list of strings for naming of tabs
   * - `default`: Default tab to show first, assumes 0 (first tab)
   *
   */
  var _default = Ember.Component.extend({
    default: 0,
    tabs: [],
    currentIndex: 0,

    onShowTab() {},

    init() {
      this._super(...arguments);

      this.set('currentIndex', this.default);
    },

    didInsertElement() {
      this._exec(this.default);
    },

    _exec(targetIndex) {
      this.set('currentIndex', targetIndex);
      this.$('.tab-content').each((index, element) => {
        if (index === targetIndex) {
          this.$(element).addClass('show');
        } else {
          this.$(element).removeClass('show');
        }
      });
    },

    actions: {
      showTab(num) {
        this._exec(num);

        this.onShowTab(num);
      }

    }
  });

  _exports.default = _default;
});