define("backalley/pods/main/statements/all/route", ["exports", "backalley/models/statement", "backalley/models/boutique", "moment"], function (_exports, _statement, _boutique, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      until: {
        refreshModel: true
      },
      lock_until: {
        refreshModel: true
      }
    },

    serializeQueryParam(value = {}, urlKey = '') {
      if (urlKey === 'until') {
        return (0, _moment.default)(value).endOf('day').toISOString();
      }

      if (urlKey === 'lock_until') {
        return (0, _moment.default)(value).endOf('day').toISOString();
      }

      return value;
    },

    model({
      until,
      lock_until
    }) {
      return _boutique.default.find({
        fields: '_id'
      }).then(boutiques => {
        // serial execution
        return boutiques.reduce((promises, boutique) => {
          return promises.then(statements => {
            const query = {
              until,
              boutique: boutique._id
            };

            if (lock_until) {
              query.lock_until = lock_until;
            }

            return _statement.default.find(query).then(statement => statements.concat(statement));
          });
        }, Ember.RSVP.resolve([]));
      }).then(res => {
        const queryParams = {
          until: (0, _moment.default)(until).format('YYYY-MM-DD')
        };

        if (lock_until) {
          queryParams.lock_until = (0, _moment.default)(lock_until).format('YYYY-MM-DD');
        }

        return {
          until,
          lock_until,
          queryParams,
          statements: res,
          actions: {
            setDate: setDate.bind(this),
            markAsPaid: markAsPaid.bind(this),
            sendStatement: sendStatement.bind(this)
          }
        };
      });
    }

  });

  _exports.default = _default;

  function setDate(event) {
    const date = event.target ? event.target.value : event;

    if ((0, _moment.default)(date).isValid() && (0, _moment.default)(date).isBefore((0, _moment.default)().startOf('day').subtract(1, 'days'))) {
      this.set('controller.until', date);
    } else {
      this.notify.show('danger', 'Make sure that the date chosen is more than 1 day ago.');
    }
  }

  function markAsPaid(statement) {
    return statement.markAsPaid().then(() => {
      this.notify.show('success', 'Marked as paid!');
    }).catch(() => {
      this.notify.show('danger', 'Oops. Something went wrong.');
    });
  }

  function sendStatement(statement) {
    statement.set('until', this.get('currentModel.until'));
    statement.set('lock_until', this.get('currentModel.lock_until'));
    if (statement.get('total_payout') > 0) return this.notify.show('danger', 'There is no outstanding amount, only the boutique owns us, so no statement is being sent.');
    return statement.send().then(() => {
      this.notify.show('success', 'Relax, your statement went through! Going back to statements.');
    }).catch(() => {
      this.notify.show('danger', 'Oops. Something went wrong.');
    });
  }
});