define("backalley/pods/components/boutique-form/detailed-info/component", ["exports", "backalley/models/user", "ember-concurrency", "backalley/enums/boutique-promotion-name-type"], function (_exports, _user, _emberConcurrency, _boutiquePromotionNameType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    boutique: {},
    boutiquePromotionNameType: _boutiquePromotionNameType.default.toValues(),
    signedBy: Ember.computed('boutique.signed_by', function () {
      return this._findUserById(this.boutique.signed_by);
    }),

    _findUserById(userId) {
      let users = userId ? _user.default.find({
        _id: userId
      }) : Ember.RSVP.resolve([null]);
      return users.then(([user]) => user);
    },

    _searchUser: (0, _emberConcurrency.task)(function* (email) {
      yield (0, _emberConcurrency.timeout)(500);
      return yield _user.default.find({
        by_username: true,
        only_staff: true,
        username: email
      });
    }).restartable(),
    actions: {
      selectSignedBy(signedBy) {
        Ember.set(this.boutique, 'signed_by', signedBy._id);
      },

      addPromotion() {
        if (Ember.isNone(this.boutique.promotions)) {
          Ember.set(this.boutique, 'promotions', []);
        }

        const promotion = {
          name: null,
          start_date: null,
          end_date: null
        };
        return this.boutique.promotions.addObject(promotion);
      },

      removePromotion(promotion) {
        return this.boutique.promotions.removeObject(promotion);
      }

    }
  });

  _exports.default = _default;
});