define("backalley/pods/main/statements/index/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.statementVersionOptions = [{
        value: 'all',
        label: 'All'
      }, {
        value: '2',
        label: 'v2'
      }, {
        value: '3',
        label: 'v3'
      }];
    },

    queryParams: ['until', 'lock_until', 'statements_version'],
    statements_version: '2',
    until: (0, _moment.default)().startOf('day').subtract({
      weeks: 2,
      seconds: 1
    }).toISOString(),
    lock_until: null,
    selectedStatementVersion: Ember.computed('statements_version', function () {
      const statements_version = this.statements_version;
      return this.statementVersionOptions.find(option => option.value === statements_version);
    })
  });

  _exports.default = _default;
});