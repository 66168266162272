define("backalley/pods/main/user-management/edit/route", ["exports", "backalley/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model({
      user_id
    }) {
      const [user] = await _user.default.find({
        _id: user_id
      });
      return {
        user
      };
    },

    actions: {
      async onSave(user) {
        try {
          await user.save();
          this.notify.showSuccess('User created successfully');
        } catch (err) {
          if (err.responseJSON && err.responseJSON.errors) {
            this.notify.showError(err.responseJSON.errors[0].detail);
          }

          this.notify.showError(err.message);
        }
      }

    }
  });

  _exports.default = _default;
});