define("backalley/pods/components/landing-page/partials/button/component", ["exports", "backalley/pods/main/landing/new/selectOptions", "backalley/util/default-to"], function (_exports, _selectOptions, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const noop = () => {};

  var _default = Ember.Component.extend({
    // Public API
    item: (0, _defaultTo.default)(null),
    onTypeSelected: (0, _defaultTo.default)(noop),
    onLabelInput: (0, _defaultTo.default)(noop),
    // Private API
    buttonTypes: (0, _defaultTo.default)(_selectOptions.buttonTypes),
    buttonSizes: (0, _defaultTo.default)(_selectOptions.buttonSizes),
    selectedButtonType: Ember.computed('item.design', function () {
      return this.buttonTypes.find(type => type.value === this.get('item.design'));
    }),
    selectedButtonSize: Ember.computed('item.size', function () {
      return this.buttonSizes.find(type => type.value === this.get('item.size'));
    })
  });

  _exports.default = _default;
});