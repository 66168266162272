define("backalley/pods/components/order-quick-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/QZ56SXi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"order-quick-search clearfix\"],[8],[0,\"\\n  \"],[7,\"form\",false],[3,\"on\",[\"submit\",[23,0,[\"searchOrders\"]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-text\"],[8],[0,\"\\n      \"],[5,\"input\",[[12,\"class\",\"input\"],[3,\"on\",[\"paste\",[23,0,[\"onPaste\"]]]]],[[\"@type\",\"@value\",\"@placeholder\"],[\"text\",[23,0,[\"query\"]],\"Paste order # here\"]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"button\",true],[11,\"class\",[29,[\"button button-primary \",[28,\"if\",[[23,0,[\"searchOrdersTask\",\"isRunning\"]],\"loading\"],null]]]],[10,\"type\",\"submit\"],[8],[0,\"\\n      Go\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/order-quick-search/template.hbs"
    }
  });

  _exports.default = _default;
});