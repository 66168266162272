define("backalley/models/curate/brand-tag", ["exports", "joi", "backalley/util/request", "backalley/util/models", "backalley/enums/curate-brand-tag-status"], function (_exports, _joi, _request, _models, _curateBrandTagStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _class2, _temp;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const createValidations = {
    name: _joi.default.string().required()
  };
  const updateValidations = {
    name: _joi.default.string().required(),
    status: _joi.default.string().required(),
    display_order: _joi.default.number().min(0)
  };
  let CurateBrandTag = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = _class2 = class CurateBrandTag {
    constructor({
      _id = null,
      name = '',
      slug,
      created_at,
      display_order,
      status
    } = {}) {
      _initializerDefineProperty(this, "status", _descriptor, this);

      _initializerDefineProperty(this, "name", _descriptor2, this);

      _initializerDefineProperty(this, "display_order", _descriptor3, this);

      this._id = _id;
      this.name = name;
      this.slug = slug;
      this.status = status;
      this.display_order = display_order;
      this.created_at = created_at;
    }

    isEqual(other) {
      return this._id === other._id;
    }

    get isArchived() {
      return this.status === _curateBrandTagStatus.default.ARCHIVED;
    }

    get isUnpublished() {
      return this.status === _curateBrandTagStatus.default.UNPUBLISHED;
    }

    get displayOrder() {
      return this.display_order;
    }

    set displayOrder(value) {
      this.display_order = parseInt(value, 10);
    }

    async create() {
      const data = Ember.getProperties(this, Object.keys(createValidations));

      try {
        await (0, _models.validateSchema)(CurateBrandTag.createSchema, data);
        const curateBrandTag = await (0, _request.request)({
          method: 'POST',
          url: (0, _request.curateApiURL)('brand_tags'),
          data: JSON.stringify(data)
        });
        Ember.setProperties(this, curateBrandTag);
        return this;
      } catch (error) {
        (0, _request.throwError)(error);
      }
    }

    static find(params = {}) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.curateApiURL)('brand_tags'),
        data: _objectSpread({}, params)
      }).then(({
        brand_tags: brandTags,
        meta
      }) => {
        return {
          brandTags: brandTags.map(curateBrandTag => new CurateBrandTag(curateBrandTag)),
          meta
        };
      }).catch(error => {
        (0, _request.throwError)(error);
      });
    }

    static findById(id) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.curateApiURL)(`brand_tags/${id}`)
      }).then(({
        brand_tags: [brandTag]
      }) => {
        return new CurateBrandTag(brandTag);
      }).catch(error => {
        (0, _request.throwError)(error);
      });
    }

    async archive() {
      try {
        const {
          brand_tags: [brandTag]
        } = await (0, _request.request)({
          method: 'DELETE',
          url: (0, _request.curateApiURL)(`brand_tags/${this._id}`)
        });
        this.status = brandTag.status;
      } catch (error) {
        (0, _request.throwCurateError)(error);
      }

      ;
    }

    async update() {
      const data = Ember.getProperties(this, Object.keys(updateValidations));

      try {
        await (0, _models.validateSchema)(CurateBrandTag.updateSchema, data);
        const {
          brand_tags: [brandTag]
        } = await (0, _request.request)({
          method: 'PUT',
          url: (0, _request.curateApiURL)(`brand_tags/${this._id}`),
          data: JSON.stringify(data)
        });
        Ember.setProperties(this, brandTag);
        return this;
      } catch (error) {
        (0, _request.throwCurateError)(error);
      }
    }

  }, _defineProperty(_class2, "createSchema", _joi.default.object().keys(_objectSpread({}, createValidations))), _defineProperty(_class2, "updateSchema", _joi.default.object().keys(_objectSpread({}, updateValidations))), _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "status", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "display_order", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "archive", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "archive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "update", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype)), _class));
  _exports.default = CurateBrandTag;
});