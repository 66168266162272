define("backalley/pods/main/feeds/trigger/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FeedsTriggerController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = (_temp = class FeedsTriggerController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "checkForBoutiqueProductActivitySince", _descriptor2, this);

      _initializerDefineProperty(this, "errorMessage", _descriptor3, this);

      _initializerDefineProperty(this, "isTriggerDisabled", _descriptor4, this);

      _initializerDefineProperty(this, "isIgnoringSnapshot", _descriptor5, this);

      _initializerDefineProperty(this, "_advancedSettings", _descriptor6, this);
    }

    get isUserAllowedToTriggerSyncWithAdvancedSettings() {
      return this.session.user.hasFeedsAdvancedSettingsPermission || this.session.user.hasFeedsAdvancedUsagePermission;
    }

    get isUserAllowedToEditSyncWithAdvancedSettings() {
      return this.session.user.hasFeedsAdvancedSettingsPermission;
    }

    get designatedDesignForUser() {
      if (!this.isUserAllowedToEditSyncWithAdvancedSettings) {
        return 'standard';
      }

      return 'advanced';
    }

    get advancedSettings() {
      if (!this.isIgnoringSnapshot && this.model.snapshot) {
        return this.model.snapshot.settings;
      }

      return this._advancedSettings;
    }

    set advancedSettings(value) {
      this.isIgnoringSnapshot = true;
      this._advancedSettings = value;
    }

    updateErrorMessageAfterDebounce({
      errorMessage
    }) {
      this.errorMessage = errorMessage;
    }

    updateIsTriggerDisabledAfterDebounce({
      isTriggerDisabled
    }) {
      this.isTriggerDisabled = Boolean(isTriggerDisabled);
    }

    updateBoutiqueProductActivitySinceValue(sinceDate) {
      this.checkForBoutiqueProductActivitySince = sinceDate || new Date();
    }

    clearErrorMessage() {
      this.updateErrorMessage('');
    }

    updateErrorMessage(errorMessage) {
      Ember.run.debounce(this, this.updateErrorMessageAfterDebounce, {
        errorMessage
      }, 150);
    }

    updateIsTriggerDisabled(isTriggerDisabled) {
      Ember.run.debounce(this, this.updateIsTriggerDisabledAfterDebounce, {
        isTriggerDisabled
      }, 150);
    }

    updateAdvancedSettingsFromString(advancedSettingsAsString) {
      try {
        this.advancedSettings = JSON.parse(advancedSettingsAsString);
        this.updateErrorMessage('');
        this.updateIsTriggerDisabled(false);
      } catch (exception) {
        this.updateIsTriggerDisabled(true);
        this.updateErrorMessage(exception.message);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "checkForBoutiqueProductActivitySince", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Date();
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isTriggerDisabled", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isIgnoringSnapshot", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_advancedSettings", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        archive_existing: true,
        rules: []
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateBoutiqueProductActivitySinceValue", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "updateBoutiqueProductActivitySinceValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearErrorMessage", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "clearErrorMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateErrorMessage", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "updateErrorMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateIsTriggerDisabled", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "updateIsTriggerDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateAdvancedSettingsFromString", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "updateAdvancedSettingsFromString"), _class.prototype)), _class));
  _exports.default = FeedsTriggerController;
});