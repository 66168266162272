define("backalley/models/transaction", ["exports", "backalley/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Transaction = Ember.Object.extend(_request.default, {});
  Transaction.reopenClass(_request.default, {
    /**
     * Retrieve transactions
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('transactions'),
        data
      }).then(({
        transactions
      }) => {
        return {
          transactions: transactions.map(transaction => Transaction.create(transaction))
        };
      });
    },

    /**
     * Export transactions
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    getExportTransactionsUrl(data) {
      return this.apiURL(`transactions/export?start_date=${data.start_date}&end_date=${data.end_date}${data.currency ? `&currency=${data.currency}` : ''}`);
    }

  });
  var _default = Transaction;
  _exports.default = _default;
});