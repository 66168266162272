define("backalley/util/path-selector", ["exports", "backalley/util/group-by"], function (_exports, _groupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pathSelectorSplitIntoSegments = pathSelectorSplitIntoSegments;
  _exports.pathSelectorExplodeSegment = pathSelectorExplodeSegment;
  _exports.convertPathCodesToPathSelector = convertPathCodesToPathSelector;

  /**
   * @file Path selectors are just simple expressions based around the
   * path code convention that we already had for Shipping paths.
   *
   * @example
   * - A path selector is just a string
   * - Special characters are '>' '|' and ';'
   * - To find all paths going to the UK use '>GBR'
   * - To find all paths coming from France use 'FRA>'
   * - To find specific paths use 'ITA>USA'
   * - To find paths from many source or dest. use 'ITA>;>GBR;FRA>FRA'
   * - To find specific paths tied to a source or dest 'ITA>GBR|FRA'
   *
   * > ! Examples use iso codes, but country names are also supported.
   *     They operate on a fuzzy match basis.
   *
   * Particular focus has been kept to path codes rather than say country
   * ids (or specifically searching based on codes) for a few reasons.
   *
   * 1. We will at some point need to go beyond country codes, this is
   *    to support things like Northern Ireland and Scotland (as UK-based
   *    examples). Keeping path selectors as strings means we can
   *    introduce other affectations that can represent these other
   *    divides e.g. GB-NIR, GB-SCT, GB-WLS
   *
   * 2. ...
   */

  /**
   * First level of a path selector split
   */
  function pathSelectorSplitIntoSegments(pathSelector) {
    return pathSelector.replace('~', '').replace(/(^|\s*);(\s*|$)/g, '~;~').split('~');
  }
  /**
   * Second level of a path selector split
   */


  function pathSelectorExplodeSegment(pathSelectorSegment) {
    const bits = pathSelectorSegment.replace(/(^|\s+)to(\s+|$)/g, '~>~').replace(/(^|\s*)>+(\s*|$)/g, '~>~').split('~').filter(v => v);
    return bits.map(bit => {
      if (bit.includes('|')) {
        return bit.split(/\s*\|+\s*/);
      }

      return bit;
    });
  }

  function convertPathCodesToPathSelector(pathCodeList) {
    const grouped = (0, _groupBy.default)(pathCodeList, pathCode => pathCode.split('>')[0]);
    return Object.entries(grouped).map(([supplyCode, pathCodes]) => {
      const demandCodes = pathCodes.map(pathCode => pathCode.split('>')[1]).join('|');
      return `${supplyCode}>${demandCodes}`;
    }).join(';');
  }
});