define("backalley/pods/components/shipping-group-configuration-form/component", ["exports", "backalley/models/shipping-group-configuration", "backalley/util/form-errors", "backalley/enums/diff-types"], function (_exports, _shippingGroupConfiguration, formError, _diffTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    router: Ember.inject.service(),
    shippingGroupConfigurationService: Ember.inject.service('shipping-group-configuration-service'),
    classNames: ['shipping-group-configuration-form'],
    // component arguments
    formType: 'update',
    sgc: undefined,
    sgcBeforeChanges: undefined,
    shippingPaths: undefined,
    // internal records
    lockedDueToProcessing: false,
    targetPathGroup: undefined,
    errorMarkers: [],
    showRawEdit: false,
    hasBeenReleased: Ember.computed.alias('sgc.has_been_released'),
    hasNotBeenReleased: Ember.computed.not('sgc.has_been_released'),
    targetPathGroupIsEditable: Ember.computed('hasBeenReleased', 'targetPathGroup.is_default', function () {
      if (this.hasBeenReleased) {
        return false;
      }

      return this.targetPathGroup && this.targetPathGroup.is_default ? false : true;
    }),
    targetPathGroupLabel: Ember.computed('targetPathGroup', function () {
      if (!this.targetPathGroup) {
        return '';
      }

      return `Paths editor: ${this.get('targetPathGroup.path_group.name')}`;
    }),

    /**
     * We calculate the unused path codes by this SGC, so that we can
     * list them on the default group. This also helps to highlight when
     * a group config may not be covering all the possible paths.
     *
     * NOTE: these are not saved with the default setting, nor should
     * they be.
     */
    shippingPathUnusedPathCodes: Ember.computed('shippingPaths', 'sgc.{path_groups_and_settings.[],__clientRevision}', function () {
      const {
        shippingPaths: {
          listOfCodes,
          byCode
        } = {}
      } = this;
      const {
        path_groups_and_settings: pgasList
      } = this.sgc || {};

      if (!listOfCodes || !listOfCodes.length) {
        return [];
      }

      if (!pgasList || !pgasList.length) {
        return listOfCodes;
      }

      const checkOffCodes = _objectSpread({}, byCode);

      pgasList.forEach(pgas => {
        const {
          path_group: {
            shipping_path_codes: spcList
          }
        } = pgas || {};

        if (!spcList || !spcList.length) {
          return;
        }

        spcList.forEach(assignedPath => {
          delete checkOffCodes[assignedPath];
        });
      });
      return Object.keys(checkOffCodes);
    }),
    modifierValueTypes: ['multiplier'],
    modifierValueTypeLabel: Ember.computed('sgc.modifier_value_type', function () {
      const {
        modifier_value_type: modifierValueType
      } = this.sgc || {};

      if (modifierValueType === 'shippable') {
        return 'Can Ship ?';
      }

      if (modifierValueType === 'price') {
        return 'Amount (£ GBP)';
      }

      if (modifierValueType === 'multiplier') {
        return 'Scaling factor';
      }

      return 'Amount';
    }),
    defaultRow: Ember.computed('sgc.modifier_value_default', 'shippingPathUnusedPathCodes', function () {
      return {
        is_first_row: true,
        is_default: true,
        path_group: {
          name: 'Default',
          shipping_path_codes: this.shippingPathUnusedPathCodes
        },
        modifier_value_type: 'multiplier',
        modifier_value: this.sgc ? this.sgc.modifier_value_default || '' : ''
      };
    }),
    targetPathGroupsAndSettingsRows: Ember.computed('sgc.path_groups_and_settings.[]', 'defaultRow', function () {
      const {
        defaultRow,
        sgc = {}
      } = this;
      const {
        path_groups_and_settings: pathGroupsAndSettings = []
      } = sgc;
      return [defaultRow, ...pathGroupsAndSettings];
    }),

    async errorBoundary(callback) {
      try {
        formError.clearErrorMarkers(this);
        return await callback();
      } catch (exception) {
        this.set('lockedDueToProcessing', false);
        let message = formError.setErrorMarkersFromException(exception, ['sgc'], this);

        if (!message) {
          message = formError.createMessageFromException(exception);
        }

        this.notify.show('error', message || String(exception), 8000);
      }
    },

    /**
     * When cloning we don't want to preserve references or ids.
     */
    createRawCloneOfSGCWithoutIds() {
      return JSON.parse(JSON.stringify(this.sgc, (key, value) => {
        if (key === '_id') return undefined;
        return value;
      }));
    },

    /**
     * This field - that is not stored in the database - has been added
     * just to aid updating certain computed properties. Mainly when
     * they rely on an update deeper in the structure. One that may
     * incur the use of more than one .[] or .@each (which ember doesn't
     * support). This has mainly been done to avoid observers and to
     * keep recalcs flowing in one direction.
     */
    incrementClientRevision() {
      this.set('sgc.__clientRevision', this.sgc.__clientRevision ? this.sgc.__clientRevision + 1 : 1);
    },

    updatePathGroupRowViaCheckbox: Ember._action(function (pathGroupAndSettings, key) {
      const value = !pathGroupAndSettings[key];
      Ember.set(pathGroupAndSettings, key, value);

      if (pathGroupAndSettings.is_default) {
        // required because this change also triggers the recompute
        // on defaultRow, which in turn forces a re-render that
        // interrupts the nice checkbox animation from on <-> off.
        setTimeout(() => {
          this.set('sgc.modifier_value_default', value);
        }, 500);
      }

      return null;
    }),
    updatePathGroupRow: Ember._action(function (pathGroupAndSettings, key, value) {
      if (!pathGroupAndSettings) {
        return;
      }

      if (pathGroupAndSettings.is_default) {
        this.set('sgc.modifier_value_default', value);
      }

      return null;
    }),
    removePathGroupRow: Ember._action(function (pathGroupAndSettings, confirmed = false) {
      if (!pathGroupAndSettings) {
        return;
      }

      if (!confirmed && pathGroupAndSettings.path_group && pathGroupAndSettings.path_group.shipping_path_codes && pathGroupAndSettings.path_group.shipping_path_codes.length) {
        if (!confirm('Are you sure? this path group has contents!')) {
          return;
        }
      }

      this.sgc.path_groups_and_settings.removeObject(pathGroupAndSettings);
      this.incrementClientRevision();
      return null;
    }),
    addPathGroupRow: Ember._action(function () {
      if (!this.sgc) {
        return this.notify.show('error', 'Please load an SGC before editing');
      }

      this.sgc.path_groups_and_settings.pushObject({
        path_group: {
          name: '',
          shipping_path_codes: []
        },
        modifier_value: ''
      });
      return null;
    }),
    focusPathGroupRow: Ember._action(function (pathGroupAndSettings) {
      this.set('targetPathGroup', pathGroupAndSettings);
    }),
    unfocusPathGroupRow: Ember._action(function () {
      this.set('targetPathGroup', undefined);
    }),
    sortPathGroupRows: Ember._action(function () {
      const list = this.sgc.path_groups_and_settings.sort((a, b) => {
        if (!b.path_group || !b.path_group.name) {
          return -1;
        }

        if (!a.path_group || !a.path_group.name) {
          return 1;
        }

        if (a.path_group.name > b.path_group.name) {
          return 1;
        }

        if (a.path_group.name < b.path_group.name) {
          return -1;
        }

        return 0;
      });
      this.set('sgc.path_groups_and_settings', list); // tell targetPathGroupsAndSettingsRows to update

      this.notifyPropertyChange('sgc');
    }),
    goBack: Ember._action(function () {
      return this.router.transitionTo('main.shipping-group-configurations');
    }),
    createDuplicate: Ember._action(function () {
      const duplicateName = `duplicate${Math.floor(Math.random() * 9999)}`;
      const duplicateData = this.createRawCloneOfSGCWithoutIds(); // make sure a clone of live data isn't edit-disabled by default

      duplicateData.has_been_released = false;
      this.shippingGroupConfigurationService.storeByName(duplicateName, duplicateData);
      const newTab = this.router.urlFor('main.shipping-group-configurations.new', {
        queryParams: {
          loadFromSource: duplicateName
        }
      });
      window.open(newTab);
    }),

    /**
     * Temporary ability. In future SGCs will be immutable once put live
     * However, for now, it is possible to save just the values.
     */
    saveValuesOnly: Ember._action(function () {
      const {
        hasDifferences,
        differences
      } = this.sgc.compareTo(this.sgcBeforeChanges);

      if (hasDifferences) {
        const hasIllegalChanges = ([field, type, value]) => {
          const isAllowedField = field.match(/^path_groups_and_settings.[0-9]+$/i);
          const isAllowedType = type === _diffTypes.default.DIFF_CHANGE;
          const isAllowedChange = value && Object.keys(value).every(v => v === 'modifier_value');

          if (isAllowedField && isAllowedType && isAllowedChange) {
            return false;
          }

          return true;
        };

        const illegalChanges = differences.filter(hasIllegalChanges); // temp storing for debug in the console, without logging

        if (typeof window !== 'undefined') {
          window.__lastFormDifferences = {
            illegalChanges,
            hasDifferences,
            differences
          };
        }

        if (illegalChanges.length) {
          this.notify.show('warning', 'Unable to save, more changes detected than just values', 5000);
          this.notify.show('warning', 'Reload this SGC and try your edits again', 6000);
          return;
        }

        this.notify.show('success', 'Saving changes...');
        return this.saveToPromenade();
      }

      this.notify.show('warning', 'No changes detected to save??');
    }),
    saveToPromenade: Ember._action(function () {
      if (this.lockedDueToProcessing) {
        this.notify.show('warning', 'This form is processing, please wait...');
        return;
      }

      return this.errorBoundary(async () => {
        this.set('lockedDueToProcessing', true);
        const sgc = this.formType === 'create' ? await _shippingGroupConfiguration.default.create(this.sgc) : await _shippingGroupConfiguration.default.promote(this.sgc).update();

        if (sgc && sgc._id) {
          this.notify.show('success', 'Shipping Group Configuration Saved Successfully!');
          await this.router.transitionTo('main.shipping-group-configurations.edit', sgc._id);
        }

        this.set('lockedDueToProcessing', false);
        return sgc;
      });
    }),
    removeFromPromenade: Ember._action(function (confirmed = false) {
      if (!confirmed && !confirm('Are you sure? there is NO undo...')) {
        return;
      } // save a back up to local storage, so recovery is possible


      const backupName = `backup${Math.floor(Math.random() * 9999)}`;
      this.shippingGroupConfigurationService.storeByName(backupName, this.createRawCloneOfSGCWithoutIds());
      return this.errorBoundary(async () => {
        const sgc = _shippingGroupConfiguration.default.promote(this.sgc);

        await sgc.remove();
        this.notify.show('success', 'Shipping Group Configuration Removed Successfully!');
        this.notify.show('success', 'A quick backup has been stored in your browser, just in case...');
        this.router.transitionTo('main.shipping-group-configurations.index');
        return sgc;
      });
    }),
    toggleRawView: Ember._action(function () {
      this.set('showRawEdit', !this.showRawEdit);
    }),
    replaceSGC: Ember._action(function (replacementSgc) {
      this.set('sgc', replacementSgc);
    }),
    targetPathGroupWasUpdated: Ember._action(function () {
      this.incrementClientRevision();
    })
  });

  _exports.default = _default;
});