define("backalley/pods/components/boutique-restock-information-form/component", ["exports", "@glimmer/component", "ember-concurrency", "ember-concurrency-decorators", "backalley/models/boutique", "backalley/models/product", "backalley/models/stock-line", "backalley/enums/boutique-restock-information-status", "backalley/enums/boutique-restock-information-report", "backalley/enums/boutique-restock-information-reason"], function (_exports, _component, _emberConcurrency, _emberConcurrencyDecorators, _boutique, _product, _stockLine, _boutiqueRestockInformationStatus, _boutiqueRestockInformationReport, _boutiqueRestockInformationReason) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BoutiqueRestockInformationFormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class BoutiqueRestockInformationFormComponent extends _component.default {
    get selectedReasonType() {
      return this.boutiqueRestockInformationReasonOptions.find(option => {
        return option.value === this.args.boutiqueRestockInformation.reason.type;
      });
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notify", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "productStockLines", _descriptor3, this);

      _defineProperty(this, "boutiqueRestockInformationReports", Object.values(_boutiqueRestockInformationReport.default));

      _defineProperty(this, "boutiqueRestockInformationStatuses", Object.values(_boutiqueRestockInformationStatus.default));

      _defineProperty(this, "boutiqueRestockInformationReasonOptions", [{
        value: _boutiqueRestockInformationReason.default.SEASONAL,
        label: 'Seasonal'
      }, {
        value: _boutiqueRestockInformationReason.default.DISCONTINUED,
        label: 'Discontinued'
      }, {
        value: _boutiqueRestockInformationReason.default.SUPPLIER_ISSUES,
        label: 'Supplier issues'
      }, {
        value: _boutiqueRestockInformationReason.default.INSECURITY,
        label: 'Unsure about investing capital'
      }, {
        value: _boutiqueRestockInformationReason.default.OTHER,
        label: 'Other'
      }]);

      this.productStockLines = this.getStockLines(this.args.boutiqueRestockInformation.product);
    }

    async getStockLines(product) {
      if (!product) {
        return {
          stock_lines: []
        };
      }

      const {
        stockLines
      } = await _stockLine.default.find({
        product: product._id,
        archived: false,
        populate: 'size',
        skip_links: true
      });
      return stockLines;
    }

    *searchBoutique(query) {
      yield (0, _emberConcurrency.timeout)(250);
      return _boutique.default.find({
        name: {
          $regex: `^${query.trim()}`,
          $options: 'i'
        }
      });
    }

    *searchProduct(query) {
      yield (0, _emberConcurrency.timeout)(250);
      const {
        boutique
      } = this.args.boutiqueRestockInformation;

      if (!boutique) {
        return;
      }

      return _product.default.find({
        'boutiques.boutique': boutique._id,
        archived: false,
        name: {
          $regex: `^${query.trim()}`,
          $options: 'i'
        },
        limit: 6,
        populate: 'standard_manufacturer'
      });
    }

    onChangeBoutique(boutique) {
      this.args.boutiqueRestockInformation.boutique = boutique;
      this.args.boutiqueRestockInformation.product = null;
      this.args.boutiqueRestockInformation.stock_line = null;
    }

    async onChangeProduct(product) {
      this.args.boutiqueRestockInformation.product = product;
      const stockLines = await this.getStockLines(product);
      this.productStockLines = stockLines;
      this.args.boutiqueRestockInformation.stock_line = stockLines.length === 1 ? stockLines[0] : null;
    }

    onChangeStatus(status) {
      this.args.boutiqueRestockInformation.status = status;

      if (status === _boutiqueRestockInformationStatus.default.NOT_AVAILABLE) {
        this.args.boutiqueRestockInformation.quantity = null;
        this.args.boutiqueRestockInformation.lead_date = null;
      }

      if (status === _boutiqueRestockInformationStatus.default.RESTOCKING) {
        this.args.boutiqueRestockInformation.reason = {};
      }
    }

    onChangeReasonType({
      value: reasonType
    }) {
      this.args.boutiqueRestockInformation.reason = _objectSpread(_objectSpread({}, this.args.boutiqueRestockInformation.reason), {}, {
        type: reasonType
      });
    }

    async save() {
      try {
        await this.args.boutiqueRestockInformation.create();
        this.notify.show('success', 'The boutique restock information was successfully created!');
        this.router.transitionTo('main.boutique-restock-information');
      } catch (err) {
        const messsage = err && err.message ? err.message : err;
        this.notify.show('error', messsage);
      }
    }

    async update() {
      try {
        await this.args.boutiqueRestockInformation.update();
        this.notify.show('success', 'The boutique restock information was successfully updated!');
        this.router.transitionTo('main.boutique-restock-information');
      } catch (err) {
        const messsage = err && err.message ? err.message : err;
        this.notify.show('error', messsage);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "productStockLines", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "searchBoutique", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchBoutique"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchProduct", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchProduct"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeBoutique", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeBoutique"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeProduct", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeProduct"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeStatus", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeReasonType", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeReasonType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "update", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype)), _class));
  _exports.default = BoutiqueRestockInformationFormComponent;
});