define("backalley/models/curate/brand", ["exports", "joi", "backalley/util/request", "backalley/util/models", "backalley/enums/curate-brand-status", "backalley/models/curate/brand-tag"], function (_exports, _joi, _request, _models, _curateBrandStatus, _brandTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _class2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const createValidations = {
    name: _joi.default.string().required(),
    email: _joi.default.string().email({
      tlds: false
    }),
    description: _joi.default.string().required(),
    currency: _joi.default.string().min(3).max(3),
    logo: _joi.default.object({
      url: _joi.default.string().uri()
    }),
    images: _joi.default.array().items(_joi.default.object({
      url: _joi.default.string().uri()
    })).min(1),
    catalogues: _joi.default.array().items(_joi.default.object({
      filename: _joi.default.string().required(),
      url: _joi.default.string().uri()
    })),
    tags: _joi.default.array().items(_models.resourceRef),
    manufacturer: _models.resourceRef,
    payment_terms: _joi.default.string(),
    delivery_info: _joi.default.string(),
    discount_message: _joi.default.string().allow(''),
    lead_time_message: _joi.default.string().allow(''),
    minimum_order_value: _joi.default.object({
      value: _joi.default.object({
        amount: _joi.default.number().min(0),
        currency: _joi.default.string().allow('GBP', 'EUR', 'USD')
      }),
      description: _joi.default.string().required()
    }),
    terms_and_conditions_url: _joi.default.string().uri(),
    status: _joi.default.string().allow(...Object.values(_curateBrandStatus.default)),
    is_retail_price_incl_vat: _joi.default.boolean(),
    is_wholesale_price_incl_vat: _joi.default.boolean()
  };

  const updateValidations = _objectSpread(_objectSpread({}, createValidations), {}, {
    email: _joi.default.string().email({
      tlds: false
    }).allow('').allow(null)
  });

  let CurateBrand = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed('minimum_order_value.value.amount'), (_class = (_temp = _class2 = class CurateBrand {
    get minimumOrderValueAmount() {
      let amount = this.minimum_order_value.value.amount;
      return Ember.isEmpty(amount) ? null : amount / 100;
    }

    set minimumOrderValueAmount(value) {
      const parsedValue = Ember.isEmpty(value) ? null : Math.round(parseFloat(value).toFixed(2) * 100);
      Ember.set(this, 'minimum_order_value.value.amount', parsedValue);
      Ember.set(this, 'minimum_order_value.value.currency', this.currency);
    }

    get minimumFirstOrderValueAmount() {
      var _this$minimum_order_v, _this$minimum_order_v2;

      let amount = (_this$minimum_order_v = (_this$minimum_order_v2 = this.minimum_order_value.first_order_value) === null || _this$minimum_order_v2 === void 0 ? void 0 : _this$minimum_order_v2.amount) !== null && _this$minimum_order_v !== void 0 ? _this$minimum_order_v : null;
      return Ember.isEmpty(amount) ? null : amount / 100;
    }

    set minimumFirstOrderValueAmount(value) {
      Ember.set(this, 'minimum_order_value.first_order_value', Ember.isEmpty(value) ? null : {
        amount: Math.round(parseFloat(value).toFixed(2) * 100),
        currency: this.currency
      });
    }

    constructor(brand = {}) {
      _initializerDefineProperty(this, "tags", _descriptor, this);

      _initializerDefineProperty(this, "currency", _descriptor2, this);

      _initializerDefineProperty(this, "manufacturer", _descriptor3, this);

      _initializerDefineProperty(this, "lead_time_message", _descriptor4, this);

      _initializerDefineProperty(this, "terms_and_conditions_url", _descriptor5, this);

      _initializerDefineProperty(this, "is_retail_price_incl_vat", _descriptor6, this);

      _initializerDefineProperty(this, "is_wholesale_price_incl_vat", _descriptor7, this);

      const {
        _id,
        slug,
        name,
        email,
        description,
        currency = 'GBP',
        logo = {
          url: ''
        },
        images = [],
        catalogues = [],
        tags = [],
        discount_message,
        lead_time_message,
        delivery_info,
        minimum_order_value = {
          value: {},
          description: ''
        },
        payment_terms,
        manufacturer,
        terms_and_conditions_url,
        created_at,
        status = _curateBrandStatus.default.UNPUBLISHED,
        is_retail_price_incl_vat = true,
        is_wholesale_price_incl_vat = false
      } = brand;
      this._id = _id;
      this.slug = slug;
      this.name = name;
      this.email = email;
      this.description = description;
      this.currency = currency;
      this.logo = logo;
      this.images = images.sort((a, b) => a.display_order - b.display_order);
      this.catalogues = catalogues;
      this.tags = tags.map(tag => new _brandTag.default(tag));
      this.discount_message = discount_message;
      this.lead_time_message = lead_time_message;
      this.delivery_info = delivery_info;
      this.minimum_order_value = minimum_order_value;
      this.payment_terms = payment_terms;
      this.terms_and_conditions_url = terms_and_conditions_url;
      this.created_at = created_at;
      this.status = status;
      this.manufacturer = manufacturer;
      this.is_retail_price_incl_vat = is_retail_price_incl_vat;
      this.is_wholesale_price_incl_vat = is_wholesale_price_incl_vat;
    }

    async create() {
      const data = Ember.getProperties(this, Object.keys(createValidations));

      try {
        await (0, _models.validateSchema)(CurateBrand.createSchema, data);
        const brand = await (0, _request.request)({
          method: 'POST',
          url: (0, _request.curateApiURL)('brands'),
          data: JSON.stringify(data)
        });
        Ember.setProperties(this, brand);
        return this;
      } catch (error) {
        (0, _request.throwCurateError)(error);
      }
    }

    static find(query = {}) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.curateApiURL)('brands'),
        data: _objectSpread(_objectSpread({}, query), {}, {
          populate: 'tags,manufacturer'
        })
      }).then(({
        brands,
        meta
      }) => ({
        brands: brands.map(brand => new CurateBrand(brand)),
        meta
      })).catch(error => (0, _request.throwCurateError)(error));
    }

    static findById(id) {
      return (0, _request.request)({
        url: (0, _request.curateApiURL)(`brands/${id}`),
        data: {
          populate: 'tags,manufacturer'
        }
      }).then(({
        brands: [brand]
      }) => Ember.isPresent(brand) ? new CurateBrand(brand) : null).catch(error => (0, _request.throwCurateError)(error));
    }

    async update() {
      const data = Ember.getProperties(this, Object.keys(updateValidations));

      try {
        await (0, _models.validateSchema)(CurateBrand.updateSchema, data);
        const brand = await (0, _request.request)({
          method: 'PUT',
          url: (0, _request.curateApiURL)(`brands/${this._id}`),
          data: JSON.stringify(data)
        });
        Ember.setProperties(this, brand);
        return this;
      } catch (error) {
        (0, _request.throwCurateError)(error);
      }
    }

    addCatalogue(filename, url) {
      this.catalogues.addObject({
        filename,
        url
      });
      return this.catalogues;
    }

  }, _defineProperty(_class2, "createSchema", _joi.default.object(createValidations)), _defineProperty(_class2, "updateSchema", _joi.default.object(updateValidations)), _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tags", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currency", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "manufacturer", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "lead_time_message", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "terms_and_conditions_url", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "is_retail_price_incl_vat", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "is_wholesale_price_incl_vat", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "minimumOrderValueAmount", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "minimumOrderValueAmount"), _class.prototype)), _class));
  _exports.default = CurateBrand;
});