define("backalley/mirage/factories/curate-order", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    status: 'created',
    agrees_with_brand_terms: true,
    has_brand_account: false,

    order_number(i) {
      return i + 1;
    },

    contact_details() {
      return {
        contact_name: _faker.default.name.findName(),
        email_address: _faker.default.internet.email(),
        phone_number: _faker.default.phone.phoneNumber()
      };
    },

    invoice_details() {
      return {
        boutique_name: _faker.default.company.companyName(),
        vat_number: 'PT1234567890',
        address: {
          line_1: _faker.default.address.streetAddress(),
          line_2: `${_faker.default.address.zipCode()} ${_faker.default.address.city()}`
        }
      };
    },

    delivery_address() {
      return {
        line_1: _faker.default.address.streetAddress(),
        line_2: `${_faker.default.address.zipCode()} ${_faker.default.address.city()}`
      };
    },

    created_at() {
      return _faker.default.date.recent();
    },

    created_by() {
      return _faker.default.random.uuid();
    },

    updated_at() {
      return this.created_at;
    },

    updated_by() {
      return this.created_by;
    },

    firstOrder: (0, _emberCliMirage.trait)({
      first_order: true
    }),

    afterCreate(order, server) {
      const currency = _faker.default.random.arrayElement(['GBP', 'EUR']);

      const {
        attrs: brand
      } = server.create('curate-brand');
      const products = server.createList('curate-product', 8, {
        brand
      }).map(({
        attrs
      }) => attrs);
      const reservations = products.map(product => ({
        product,
        quantity: 1,
        stock_line: product.stock_lines[0],
        status: 'created'
      }));
      const totalPriceAmount = reservations.reduce((total, {
        stock_line: {
          wholesale_price: {
            amount
          }
        },
        quantity
      }) => {
        return total + amount * quantity;
      }, 0);
      order.update({
        _id: order.id,
        reservations,
        brands: [brand],
        skus: reservations.map(({
          sku
        }) => sku),
        total_price: {
          amount: totalPriceAmount,
          currency
        }
      });
    }

  });

  _exports.default = _default;
});