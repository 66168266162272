define("backalley/pods/components/order-edit/charge-refund-box/component", ["exports", "ember-keyboard"], function (_exports, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function NOOP() {}

  var _default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    tagName: '',
    order: null,
    onSave: NOOP,
    _customerRefundEditToggle: false,

    init() {
      this._super();

      this.set('keyboardActivated', true);
    },

    braintreeShortcut: Ember.on((0, _emberKeyboard.keyUp)('alt+KeyB'), function () {
      window.open(this.model.braintreeTransactionURL, '_blank');
    }),
    actions: {
      onUpdateCustomerRefund() {
        Ember.set(this.order, 'transaction_customer_refunded.amount', this.editing_customer_refunded);
        this.onSave();
      },

      onUpdateCustomerRecharge() {
        Ember.set(this.order, 'transaction_customer_recharged.amount', this.editing_customer_recharged);
        this.onSave();
      },

      refund(amount) {
        return this.order.refund(Math.abs(amount));
      },

      updateTransactionCustomerRefunded(amount) {
        const preExistingAmount = this.order.transaction_customer_refunded.amount || 0;
        Ember.set(this.order, 'transaction_customer_refunded.amount', preExistingAmount + Math.abs(amount));
        this.onSave();
      }

    }
  });

  _exports.default = _default;
});