define("backalley/pods/components/calendar-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public API
    dateValue: null,
    // The date for the picker
    dateFormat: 'DD MMM YYYY',
    // The date format
    showPicker: false,
    // Show picker or not show that is the question
    label: 'Date',
    // Label to render next to date
    emptyMessage: null,
    // Label to render if there's no date
    editLabel: 'Edit',

    // Label to use for edit button
    onSelect() {},

    // Private API
    classNames: ['calendar-picker'],
    empty: Ember.computed.empty('dateValue'),

    init() {
      this._super(...arguments);

      this.set('month', this.dateValue);
    },

    actions: {
      togglePicker() {
        this.toggleProperty('showPicker');
      },

      onSelect({
        moment
      }) {
        this.onSelect(moment.format());
        this.toggleProperty('showPicker');
      }

    }
  });

  _exports.default = _default;
});