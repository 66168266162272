define("backalley/pods/components/form-textarea/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YWMBKZFe",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"error\"],[8],[1,[22,\"error\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[28,\"textarea\",null,[[\"class\",\"value\",\"key-up\",\"autofocus\",\"placeholder\",\"maxlength\"],[\"textarea\",[24,[\"value\"]],[24,[\"action\"]],[24,[\"autofocus\"]],[24,[\"placeholder\"]],[24,[\"maxlength\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/form-textarea/template.hbs"
    }
  });

  _exports.default = _default;
});