define("backalley/enums/curate-order-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    CREATED: "created",
    ACKNOWLEDGED: "acknowledged",
    APPROVED: "approved",
    PAID: "paid",
    CANCELLED: "cancelled",
    CONFIRMED: "confirmed",
    SHIPPED: "shipped",
    COMPLETED: "completed"
  };
  _exports.default = _default;
});