define("backalley/pods/components/currency-statement-preview/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['currency-statement-preview'],

    didInsertElement() {
      const iframeElement = this.element.querySelector('iframe'); // Need to wait for iframe content to load to dynamically assign height

      iframeElement.addEventListener('load', function () {
        iframeElement.height = iframeElement.contentWindow.document.body.scrollHeight;
      });
    }

  });

  _exports.default = _default;
});