define("backalley/pods/components/packaging-category-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    router: Ember.inject.service(),
    classNames: ['packaging-category-form'],
    actions: {
      save() {
        const promise = Ember.isEmpty(this.category.id) ? this.category.create() : this.category.update();
        return promise.then(() => {
          this.notify.show('success', 'Category successfully created!');
          this.router.transitionTo('main.packaging.categories');
        }).catch(() => {
          this.notify.show('error', 'An error ocurred while saving the category. Please try again.');
        });
      }

    }
  });

  _exports.default = _default;
});