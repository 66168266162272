define("backalley/models/product-delivery-tag", ["exports", "joi", "backalley/util/models", "backalley/util/request"], function (_exports, _joi, _models, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ProductDeliveryTag {
    constructor({
      _id,
      type,
      label
    }) {
      this.type = type;
      this.label = label;
      this._id = _id;
    }

    static create(params = {}) {
      return (0, _models.validateSchema)(this.schema, params).then(() => {
        return (0, _request.request)({
          method: 'POST',
          url: (0, _request.apiURL)('product_delivery_tags'),
          data: JSON.stringify(params)
        });
      });
    }

    static find(params = {}) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.apiURL)('product_delivery_tags'),
        data: params
      }).then(({
        product_delivery_tags,
        meta
      }) => {
        return {
          productDeliveryTags: product_delivery_tags.map(productDeliveryTag => new ProductDeliveryTag(productDeliveryTag)),
          meta
        };
      });
    }

    delete() {
      return (0, _request.request)({
        method: 'DELETE',
        url: (0, _request.apiURL)(`product_delivery_tags/${this._id}`)
      });
    }

  }

  _exports.default = ProductDeliveryTag;

  _defineProperty(ProductDeliveryTag, "schema", _joi.default.object().keys({
    type: _joi.default.string().required(),
    label: _joi.default.string().required()
  }));
});