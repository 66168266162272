define("backalley/models/statement", ["exports", "backalley/mixins/request", "joi", "backalley/util/models"], function (_exports, _request, _joi, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const Statement = Ember.Object.extend(_request.default, {
    adjustment_amount: 0,
    set_adjustment_amount: Ember.observer('adjustment_amount_negated', function () {
      let val = this.adjustment_amount_negated;
      this.set('adjustment_amount', val && val !== '' ? val * -1 : 0);
    }),
    set_manual_fee_amount_exc_vat: Ember.observer('manual_fee_amount_exc_vat_negated', function () {
      let val = this.manual_fee_amount_exc_vat_negated;
      this.set('manual_fee_amount_exc_vat', val && val !== '' ? val * -1 : 0);
    }),

    send() {
      return this.validate().then(statement => {
        return this.request({
          method: 'POST',
          url: this.apiURL('statements'),
          data: JSON.stringify(statement)
        });
      }).then(res => {
        const data = res.statements[0];
        this.setProperties(data);
      });
    },

    validate() {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(Statement.schema, data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    markAsPaid() {
      return this.request({
        method: 'PUT',
        url: this.apiURL(`statements/markAsPaid/${this._id || this.sent_in_statement}`),
        data: JSON.stringify({})
      }).then(res => {
        const data = res.statements[0];
        this.setProperties(data);
      });
    }

  });
  Statement.reopenClass(_request.default, {
    schema: _joi.default.object().min(2).max(7).keys({
      boutique: _joi.default.string().required(),
      adjustment_amount: _joi.default.number().default(0),
      adjustment_notes: _joi.default.string().default('').allow(''),
      manual_fee_amount_exc_vat: _joi.default.number().default(0),
      manual_fee_description: _joi.default.string().default('').allow(''),
      until: _joi.default.date(),
      lock_until: _joi.default.date()
    }),

    find(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('statements'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => res.statements.map(r => {
        return this.create(r);
      }));
    },

    findByBoutiqueId(id, data) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`statements/findNew/${id}`),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => this.create(res.statements[0]));
    },

    create(statement = {}) {
      return this._super(_objectSpread(_objectSpread({}, statement), {}, {
        adjustment_amount_negated: statement.adjustment_amount * -1,
        manual_fee_amount_exc_vat_negated: statement.manual_fee_amount_exc_vat * -1
      }));
    }

  });
  var _default = Statement;
  _exports.default = _default;
});