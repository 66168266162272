define("backalley/pods/main/packaging/products/new/route", ["exports", "backalley/models/packaging/product"], function (_exports, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PackagingProductsNewRoute extends Ember.Route {
    model() {
      return new _product.default({});
    }

  }

  _exports.default = PackagingProductsNewRoute;
});