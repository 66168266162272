define("backalley/models/product_amendment", ["exports", "backalley/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const ProductAmendment = Ember.Object.extend(_request.default, {
    note: '',
    status: 'pending',
    created_at: new Date(),
    schemaFields: ['_id', 'note', 'status', 'attribute', 'product'],

    /**
     * Get schema fields when no arguments passed to getProperties
     *
     * @return {Object} Object of key/value pairs
     */
    getProperties() {
      if (!arguments.length) return this._super(this.schemaFields);
      return this._super(...arguments);
    },

    /**
     * Creates an amendment if the amendment is not stored,
     * Updates the amendment if it's already saved.
     *
     * @return {Object} Saved/Created amendment
     */
    save(extraData = {}) {
      const data = this.getProperties();
      const method = data._id ? 'put' : 'post';
      const url = method === 'put' ? `product_amendments/${data._id}` : 'product_amendments';
      return this.request({
        method,
        url: this.apiURL(url),
        data: JSON.stringify(_objectSpread(_objectSpread({}, data), extraData))
      }).then(res => {
        this.setProperties(res.product_amendments[0]);
        return res.product_amendments[0];
      });
    },

    /**
     * Deletes the amendment
     *
     * @return {null} Empty response
     */
    delete() {
      const data = this.getProperties('_id');
      return this.request({
        method: 'delete',
        url: this.apiURL(`product_amendments/${data._id}`)
      });
    }

  });
  ProductAmendment.reopenClass(_request.default, {
    /**
     * Finds all pending or modified amendments and groups them per attribute.
     *
     * @param  {String} product_id Product _id of the product
     * @return {Object}            Amendments grouped per attribute
     */
    findPerField(product_id) {
      return this.request({
        method: 'POST',
        url: this.apiURL('product_amendments'),
        headers: {
          'X-HTTP-Method-Override': 'GET'
        },
        data: JSON.stringify({
          product: product_id,
          status: {
            $nin: ['rejected', 'approved']
          }
        })
      }).then(res => this.groupPerField(res.product_amendments));
    },

    groupPerField(amendments) {
      const amendmentsPerField = {}; // Remove rejected and approved amendments

      const filteredAmendments = amendments.filter(am => !['rejected', 'approved'].includes(am.status)); // Get all the attributes

      const attributes = filteredAmendments.map(am => am.attribute); // Compose the amendmentsPerField object

      attributes.forEach(at => {
        amendmentsPerField[at] = filteredAmendments.find(am => am.attribute === at);
      });
      return amendmentsPerField;
    },

    /**
     * Retrieve amendments for product
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findByProduct(product_id) {
      return this.request({
        method: 'GET',
        url: this.apiURL('product_amendments'),
        data: {
          product: product_id,
          sort: '-created_at'
        }
      }).then(res => {
        const amendments = res.product_amendments.map(am => this.create(am));
        return {
          product_amendments: amendments,
          field_product_amendments: this.groupPerField(amendments)
        };
      });
    },

    find(query = {}) {
      return this.request({
        method: 'POST',
        url: this.apiURL('product_amendments'),
        headers: {
          'X-HTTP-Method-Override': 'GET'
        },
        data: JSON.stringify(query)
      }).then(res => {
        const amendments = res.product_amendments.map(am => this.create(am));
        return {
          product_amendments: amendments,
          meta: res.meta || {}
        };
      });
    }

  });
  var _default = ProductAmendment;
  _exports.default = _default;
});