define("backalley/pods/components/landing-page/legacy-partials/image-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hd94ekC3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"component\",[[28,\"hot-load\",[\"landing-page/partials/image-upload\",[23,0,[]],[24,[\"landing-page/partials/image-upload\"]],\"landing-page/partials/image-upload\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"imageFolder\",\"item\",\"onUploadDesktopImage\",\"onUploadMobileImage\",\"onRemoveDesktopImage\",\"onRemoveMobileImage\"],[[23,0,[]],\"landing-page/partials/image-upload\",[24,[\"landing-page/partials/image-upload\"]],false,true,[24,[\"imageFolder\"]],[24,[\"item\"]],[28,\"action\",[[23,0,[]],\"onUploadDesktopImage\"],null],[28,\"action\",[[23,0,[]],\"onUploadMobileImage\"],null],[28,\"action\",[[23,0,[]],\"onRemoveDesktopImage\"],null],[28,\"action\",[[23,0,[]],\"onRemoveMobileImage\"],null]]]],false],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/landing-page/legacy-partials/image-upload/template.hbs"
    }
  });

  _exports.default = _default;
});