define("backalley/pods/main/transactions-view/index/route", ["exports", "backalley/models/order", "backalley/models/transaction"], function (_exports, _order, _transaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TransactionsViewIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        ordernumber: {
          refreshModel: true,
          replace: true
        },
        subEntityIds: {
          refreshModel: true
        },
        subEntityTypes: {
          refreshModel: true
        },
        projection: {
          refreshModel: true
        }
      });
    }

    async model({
      ordernumber,
      subEntityIds,
      subEntityTypes,
      projection
    }) {
      let transactions = [];
      let possibleSubEntityIds = [];
      let possibleSubEntityTypes = [];
      const knownSubEntityTypes = ['customer_payment', 'discount_cost', 'manual_adjustment', 'oos_charge', 'product_discount', 'product_revenue', 'product_revenue_duties', 'product_revenue_duties_import_vat', 'product_revenue_import_vat', 'product_revenue_local_vat', 'product_revenue_net', 'reservation', 'reservation_commission', 'reservation_payout_before_fees', 'reservation_customer', 'reservation_customer_vat', 'shipping_discount_per_reservation', 'shipping_fee', 'shipping_reimbursement', 'shipping_revenue', 'shipping_revenue_per_reservation_duties', 'shipping_revenue_per_reservation_gross', 'shipping_revenue_per_reservation_import_vat', 'shipping_revenue_per_reservation_local_vat', 'shipping_revenue_per_reservation_net', 'vat_delta'];
      const selectedProjection = projection;
      const selectedSubEntityTypes = subEntityTypes ? subEntityTypes.split(',') : [];
      const selectedSubEntityIds = subEntityIds ? subEntityIds.split(',') : [];

      const handleReturn = ({
        order = {},
        ordernumber = '',
        transactions = []
      } = {}) => ({
        order,
        ordernumber,
        transactions,
        selectedSubEntityIds,
        selectedSubEntityTypes,
        selectedProjection,
        possibleSubEntityIds,
        possibleSubEntityTypes,
        possibleProjections: ['none', 'reporting', 'reporting-EUR', 'transaction']
      });

      if (!ordernumber) {
        return handleReturn();
      }

      const {
        orders: [order]
      } = (await _order.default.find({
        ordernumber: ordernumber
      })) || {
        orders: []
      };

      if (!order) {
        return handleReturn({
          ordernumber
        });
      }

      ({
        transactions = []
      } = (await _transaction.default.find({
        entity_id: [ordernumber, order._id],
        sort: 'created_at'
      })) || {}); // simple patch for now, that will hide away those transactions that
      // have been set as 'ignored' by the repair system. This is done to
      // avoid deletes on a hot table, and also allows us to look back at
      // what was calculated to make sure it makes sense. It does mean the
      // transaction table gets messy to view if the ingores are not ignored.
      // Going forward, we can either decide to delete all those things marked
      // as ignore in one go (out of hours). If things look good. Or, we can
      // decide to keep the analysis field and support ignoring in a more
      // permanent way.

      const transactionsIgnored = [];
      transactions = transactions.filter(transaction => {
        const isIgnored = transaction.analysis && (transaction.analysis.includes('ignore') || transaction.analysis.includes('ignoreAsDuplicate'));

        if (isIgnored) {
          transactionsIgnored.push(transaction);
        }

        return !isIgnored;
      });
      const uniqueSubEntityIds = new Set(transactions.map(t => t.sub_entity_id));
      possibleSubEntityIds = [...uniqueSubEntityIds].filter(v => v);
      const uniqueSubEntityTypes = new Set([...knownSubEntityTypes, ...transactions.map(t => t.sub_entity_type)]);
      possibleSubEntityTypes = [...uniqueSubEntityTypes];

      if (selectedSubEntityIds.length) {
        transactions = transactions.filter(transaction => {
          return selectedSubEntityIds.includes(transaction.sub_entity_id);
        });
      }

      if (selectedSubEntityTypes.length) {
        transactions = transactions.filter(transaction => {
          return selectedSubEntityTypes.includes(transaction.sub_entity_type);
        });
      }

      if (selectedProjection) {
        transactions = transactions.map(transaction => {
          if (!transaction.projections) {
            return transaction;
          }

          const proj = transaction.projections.find(p => {
            return p.projection === selectedProjection;
          });

          if (!proj) {
            return transaction;
          }

          return _objectSpread(_objectSpread({}, transaction), proj);
        });
      }

      transactions.forEach(transaction => {
        transaction.stringified = JSON.stringify(transaction, null, 2);
      });
      return handleReturn({
        order,
        ordernumber,
        transactions,
        transactionsIgnored
      });
    }

  }

  _exports.default = TransactionsViewIndexRoute;
});