define("backalley/pods/main/shipment-zones/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    country: Ember.inject.service(),
    notify: Ember.inject.service(),
    queryParams: ['page', 'source', 'destination'],
    page: 1,
    source: 'GB',
    destination: undefined,
    countries: Ember.computed.readOnly('country.countries'),
    showBulkCreate: Ember.computed('model.shipmentZones', 'destination', function () {
      return Ember.isEmpty(this.model.shipmentZones) && Ember.isEmpty(this.destination);
    }),
    selectedSource: Ember.computed('source', 'countries.[]', function () {
      return this.countries.findBy('iso_code', this.source);
    }),
    selectedDestination: Ember.computed('destination', 'countries.[]', function () {
      return this.countries.findBy('iso_code', this.destination);
    }),
    onChangeSource: Ember._action(function (country) {
      this.setProperties({
        source: country ? country.iso_code : undefined,
        page: 1
      });
    }),
    onChangeDestination: Ember._action(function (country) {
      this.setProperties({
        destination: country ? country.iso_code : undefined,
        page: 1
      });
    }),
    onFinishImportCsv: Ember._action(function () {
      this.notify.show('success', 'Upload of the CSV completed ✅');
    })
  });

  _exports.default = _default;
});