define("backalley/pods/main/currency-conversion-margins/controller", ["exports", "backalley/models/currency"], function (_exports, _currency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ALL_CURRENCIES = 'All currencies';

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    queryParams: ['source', 'destination'],
    availableCurrenciesOptions: Ember.computed(async function () {
      const currencies = await _currency.default.find();
      const currencyOptions = currencies.map(({
        iso_4217,
        _id
      }) => ({
        label: iso_4217,
        value: _id
      }));
      return [{
        label: ALL_CURRENCIES,
        value: undefined
      }, ...currencyOptions];
    }),
    selectedSource: Ember.computed('source', 'availableCurrenciesOptions.[]', async function () {
      const availableCurrenciesOptions = await this.availableCurrenciesOptions;
      return availableCurrenciesOptions.findBy('value', this.source);
    }),
    selectedDestination: Ember.computed('destination', 'availableCurrenciesOptions.[]', async function () {
      const availableCurrenciesOptions = await this.availableCurrenciesOptions;
      return availableCurrenciesOptions.findBy('value', this.destination);
    }),

    save(margin) {
      return margin.save().catch(err => this.notify.showError(err));
    }

  });

  _exports.default = _default;
});