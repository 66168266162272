define("backalley/pods/main/currencies/index/route", ["exports", "backalley/models/currency", "backalley/mixins/loading", "backalley/util/escape-regex"], function (_exports, _currency, _loading, _escapeRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_loading.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },

    serializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      // don't show objects in url
      if (urlKey === 'sort') return value.value;
      if (urlKey === 'page') return Number(value);
      return value;
    },

    deserializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      if (urlKey === 'sort') return this.availableFilters.findBy('value', value);
      return value;
    },

    availableFilters: [{
      label: 'Name: A-Z',
      value: 'name'
    }, {
      label: 'Name: Z-A',
      value: '-name'
    }],

    model({
      q,
      isFeatured = false,
      page = 1,
      limit = 30,
      sort = {}
    }) {
      return _currency.default.findWithMeta({
        page,
        limit,
        name: {
          $regex: `^${(0, _escapeRegex.default)(q)}`,
          $options: 'i'
        },
        sort: sort.value
      }).then(({
        currencies,
        meta = {
          total: 3
        }
      }) => {
        return {
          currencies,
          total: Math.ceil(meta.total / limit),
          search: q,
          sort: sort,
          availableFilters: this.availableFilters,
          isFeatured: isFeatured,
          actions: {
            goToPage: goToPage.bind(this),
            nextPage: nextPage.bind(this),
            prevPage: prevPage.bind(this),
            updateSearch: updateSearch.bind(this),
            updateFilter: updateFilter.bind(this),
            remove: remove.bind(this)
          }
        };
      });
    }

  });

  _exports.default = _default;

  function goToPage(page) {
    this.transitionTo({
      queryParams: {
        page: page
      }
    });
  }

  function nextPage() {
    const page = this.get('controller.page');
    if (page < this.get('currentModel.total')) this.transitionTo({
      queryParams: {
        page: Number(page) + 1
      }
    });
  }

  function prevPage() {
    const page = this.get('controller.page');
    if (page > 1) this.transitionTo({
      queryParams: {
        page: Number(page) - 1
      }
    });
  }

  function updateSearch(q) {
    this.set('controller.q', q);
    this.set('controller.page', 1);
  }

  function updateFilter(filter) {
    this.set('controller.sort', filter);
  }

  function remove(currency) {
    if (confirm(`Are you sure you want to delete the currency: ${currency.name}`)) {
      currency.remove().then(() => {
        this.get('currentModel.currency').removeObject(currency);
      });
    }
  }
});