define("backalley/pods/components/site-header/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'header',
    classNames: ['site-header'],

    didInsertElement() {
      let $siteToggle = (0, _jquery.default)('#site-toggle');
      $siteToggle.click(function () {
        (0, _jquery.default)('html').toggleClass('sidemenu-open');
      });
    }

  });

  _exports.default = _default;
});