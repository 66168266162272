define("backalley/pods/components/maps-with-streetview/component", ["exports", "backalley/models/googlemap"], function (_exports, _googlemap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    geometry: {},
    classNames: ['maps-with-streetview'],

    didInsertElement() {
      const geometry = this.geometry;
      const place = this.place;
      this.renderMaps(geometry, place);
    },

    didUpdateAttrs() {
      const geometry = this.geometry;
      const place = this.place;
      this.renderMaps(geometry, place);
    },

    renderMaps(geometry, place) {
      const googleMap = new _googlemap.default();
      const client = googleMap.client; // If no geometry has been passed in, don't render anything

      if (!geometry) return; // Create map

      const map = new client.Map(this.$('.map')[0], {
        zoom: 17,
        center: geometry.location
      }); // Create panorama

      const panorama = new client.StreetViewPanorama(this.$('.panorama')[0], {
        position: geometry.location,
        pov: {
          heading: 34,
          pitch: 10
        },
        zoom: 2
      }); // Set the correct location of geometry

      map.fitBounds(geometry.viewport);
      map.setStreetView(panorama); // Set the marker if the place is found

      if (place) {
        const marker = this.renderMarker(map, place);
        const infoWindow = new client.InfoWindow();
        infoWindow.setContent(place.name);
        infoWindow.open(map, marker);
      }
    },

    renderMarker(map, place) {
      const googleMap = new _googlemap.default();
      const client = googleMap.client;
      const marker = new client.Marker({
        map,
        position: place.geometry.location,
        icon: {
          url: 'https://developers.google.com/maps/documentation/javascript/images/circle.png',
          anchor: new client.Point(10, 10),
          scaledSize: new client.Size(10, 17)
        }
      });
      return marker;
    }

  });

  _exports.default = _default;
});