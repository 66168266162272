define("backalley/pods/components/landing-page/youtube/options/component", ["exports", "backalley/util/default-to", "backalley/pods/main/landing/new/selectOptions", "backalley/util/clone-deep"], function (_exports, _defaultTo, _selectOptions, _cloneDeep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public API
    selectedRow: (0, _defaultTo.default)(null),
    selectedEntry: (0, _defaultTo.default)(null),
    // Private API
    selectedEntryGeneral: Ember.computed.alias('selectedEntry.content.general'),
    availablePaddings: (0, _defaultTo.default)(_selectOptions.availablePaddings),
    availableMargins: (0, _defaultTo.default)(_selectOptions.availableMargins),
    // Content padding options
    selectedGeneralPaddingTop: Ember.computed('selectedEntryGeneral.paddingTop', function () {
      const selectedPadding = this.get('selectedEntryGeneral.paddingTop');
      return this.availablePaddings.find(position => position.value === selectedPadding);
    }),
    selectedGeneralPaddingBottom: Ember.computed('selectedEntryGeneral.paddingBottom', function () {
      const selectedPadding = this.get('selectedEntryGeneral.paddingBottom');
      return this.availablePaddings.find(position => position.value === selectedPadding);
    }),
    selectedGeneralPaddingLeft: Ember.computed('selectedEntryGeneral.paddingLeft', function () {
      const selectedPadding = this.get('selectedEntryGeneral.paddingLeft');
      return this.availablePaddings.find(position => position.value === selectedPadding);
    }),
    selectedGeneralPaddingRight: Ember.computed('selectedEntryGeneral.paddingRight', function () {
      const selectedPadding = this.get('selectedEntryGeneral.paddingRight');
      return this.availablePaddings.find(position => position.value === selectedPadding);
    }),
    // Column margin options
    selectedGeneralMarginTop: Ember.computed('selectedEntryGeneral.marginTop', function () {
      const selectedPadding = this.get('selectedEntryGeneral.marginTop');
      return this.availableMargins.find(position => position.value === selectedPadding);
    }),
    selectedGeneralMarginBottom: Ember.computed('selectedEntryGeneral.marginBottom', function () {
      const selectedPadding = this.get('selectedEntryGeneral.marginBottom');
      return this.availableMargins.find(position => position.value === selectedPadding);
    }),
    selectedGeneralMarginLeft: Ember.computed('selectedEntryGeneral.marginLeft', function () {
      const selectedPadding = this.get('selectedEntryGeneral.marginLeft');
      return this.availableMargins.find(position => position.value === selectedPadding);
    }),
    selectedGeneralMarginRight: Ember.computed('selectedEntryGeneral.marginRight', function () {
      const selectedPadding = this.get('selectedEntryGeneral.marginRight');
      return this.availableMargins.find(position => position.value === selectedPadding);
    }),

    capitalizeFirstLetter(string) {
      return string.replace(string[0], string[0].toUpperCase());
    },

    actions: {
      reorderElements(groupModel, elements) {
        Ember.set(groupModel, 'elements', elements);
      },

      addElement(element) {
        const selectedEntry = this.selectedEntry;
        selectedEntry.content.elements.addObject((0, _cloneDeep.default)(element));
      },

      // General setters
      onGeneralBackgroundColorSelected(color) {
        const general = this.get('selectedEntry.content.general');
        Ember.set(general, 'backgroundHexColor', color);
      },

      onGeneralMarginSelected(location, option) {
        const general = this.get('selectedEntry.content.general');
        const capitalizedLocation = this.capitalizeFirstLetter(location);
        Ember.set(general, `margin${capitalizedLocation}`, option.value);
      },

      onGeneralPaddingSelected(location, option) {
        const general = this.get('selectedEntry.content.general');
        const capitalizedLocation = this.capitalizeFirstLetter(location);
        Ember.set(general, `padding${capitalizedLocation}`, option.value);
      },

      // Toggle checkbox values
      onToggleVideoCheckbox(type) {
        // Toggle property
        this.toggleProperty(`selectedEntry.content.video.${type}`); // Get value

        const value = this.get(`selectedEntry.content.video.${type}`); // If enabling autoplay, mute the video by default.
        // Google chrome implemented a feature to stop automatically playing audio

        if (type === 'autoplay' && value) {
          this.set('selectedEntry.content.video.mute', true);
        }
      }

    }
  });

  _exports.default = _default;
});