define("backalley/pods/main/materials/edit/route", ["exports", "backalley/models/material"], function (_exports, _material) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      material_id
    }) {
      return _material.default.findById(material_id).then(material => {
        return {
          material,
          user: this.session.user,
          actions: {
            update: update.bind(this)
          }
        };
      });
    }

  });

  _exports.default = _default;

  function update(material) {
    return material.save().then(() => this.transitionTo('main.materials'), err => {
      this.notify.show('err', err.message);
    });
  }
});