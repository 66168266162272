define("backalley/pods/main/prospective-boutique/index/route", ["exports", "backalley/util/escape-regex", "backalley/models/prospective_boutique"], function (_exports, _escapeRegex, _prospective_boutique) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      name: {
        refreshModel: true
      },
      email: {
        refreshModel: true
      }
    },

    model({
      name,
      email,
      page = 1,
      limit = 30,
      sort = '-created_at'
    }) {
      let query = {
        page,
        limit,
        sort,
        populate: 'signed_by'
      };

      if (name) {
        query.name = {
          $regex: `${(0, _escapeRegex.default)(name)}`,
          $options: 'i'
        };
      }

      if (email) {
        query.email = {
          $regex: `${(0, _escapeRegex.default)(email)}`,
          $options: 'i'
        };
      }

      return _prospective_boutique.default.findWithMeta(query).then(({
        prospectiveBoutiques,
        meta = {
          total: 1
        }
      }) => {
        return {
          prospectiveBoutiques,
          total: Math.ceil(meta.total / limit)
        };
      });
    },

    actions: {
      goToPage(page) {
        this.transitionTo({
          queryParams: {
            page: page
          }
        });
      },

      nextPage() {
        const page = this.controller.page;

        if (page < this.get('currentModel.total')) {
          this.transitionTo({
            queryParams: {
              page: Number(page) + 1
            }
          });
        }
      },

      prevPage() {
        const page = this.controller.page;

        if (page > 1) {
          this.transitionTo({
            queryParams: {
              page: Number(page) - 1
            }
          });
        }
      },

      updateSearch(field, q) {
        Ember.setProperties(this.controller, {
          name: '',
          email: ''
        });
        Ember.set(this.controller, field, q);
        Ember.set(this.controller, 'field', field);
        Ember.set(this.controller, 'page', 1);
      },

      remove(prospectiveBoutique) {
        if (confirm(`This operation archives the contract for ${prospectiveBoutique.name}, you won't be able to do any changes after. Archive?`)) {
          return prospectiveBoutique.remove().then(() => {
            Ember.set(prospectiveBoutique, 'status', 'archived');
          }).catch(() => {
            this.notify.show('error', `An error accorred while archiving contract for ${prospectiveBoutique.name}`, 5000);
          });
        }
      }

    }
  });

  _exports.default = _default;
});