define("backalley/pods/components/product-name-from-id/component", ["exports", "backalley/models/product", "ember-concurrency"], function (_exports, _product, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    productId: null,
    productName: null,

    didInsertElement() {
      this._super(...arguments);

      if (!this.productId) {
        return;
      }

      this.fetchProductName.perform();
    },

    fetchProductName: (0, _emberConcurrency.task)(function* () {
      const product = yield _product.default.findById(this.productId);
      this.set('productName', product.name);
    }).restartable()
  });

  _exports.default = _default;
});