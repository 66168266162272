define("backalley/pods/main/landing/new/selectOptions", ["exports", "backalley/models/tag", "backalley/models/category", "backalley/models/product", "backalley/models/boutique", "backalley/models/landing", "backalley/models/info", "backalley/models/sunflower-route", "backalley/util/escape-regex", "backalley/util/clone-deep"], function (_exports, _tag, _category, _product, _boutique, _landing, _info, _sunflowerRoute, _escapeRegex, _cloneDeep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.availableBoxElements = _exports.availableMargins = _exports.availableTextAligns = _exports.availablePaddings = _exports.availablePositions = _exports.buttonSizes = _exports.buttonTypes = _exports.rowTypes = _exports.colTypes = _exports.copyRowType = _exports.availableTypes = _exports.availableColors = _exports.availableColorsUnprifixed = _exports.availableColorPickerOptions = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let availableColorPickerOptions = [['transparent', 'white', 'black'], ['#EBEAEC', // Mist
  '#DCD7D2', // Navy 10
  '#9B99A4', // Navy 50
  '#5F5C6E', // Navy 75
  '#242038' // Navy
  ], ['#407899', // Teal
  '#48A9A6', // Aqua
  '#C1666B', // Red
  '#D4B483' // Gold
  ]];
  _exports.availableColorPickerOptions = availableColorPickerOptions;
  let availableColorsUnprifixed = [{
    value: 'black',
    label: 'Black'
  }, {
    value: 'pink',
    label: 'Pink'
  }, {
    value: 'purple',
    label: 'Purple'
  }, {
    value: 'red',
    label: 'Red'
  }, {
    value: 'blue',
    label: 'Blue'
  }, {
    value: 'green',
    label: 'Green'
  }, {
    value: 'yellow',
    label: 'Yellow'
  }, {
    value: 'grey',
    label: 'Grey'
  }, {
    value: 'white',
    label: 'White'
  }, {
    value: 'pink-light',
    label: 'Light Pink'
  }, {
    value: 'purple-light',
    label: 'Light Purple'
  }, {
    value: 'red-light',
    label: 'Light Red'
  }, {
    value: 'blue-light',
    label: 'Light Blue'
  }, {
    value: 'green-light',
    label: 'Light Green'
  }, {
    value: 'yellow-light',
    label: 'Light Yellow'
  }, {
    value: 'grey-light',
    label: 'Light Grey'
  }]; // Legacy prefixed colors: (Used in old components)
  // Prefix all values of colors with `color-`

  _exports.availableColorsUnprifixed = availableColorsUnprifixed;
  let availableColors = availableColorsUnprifixed.map(color => _objectSpread(_objectSpread({}, color), {}, {
    value: `color-${color.value}`
  }));
  _exports.availableColors = availableColors;
  let availableTypes = [{
    label: 'Tag',
    value: 'tag',
    key: 'label',
    model: _tag.default,
    query: function (q) {
      return {
        label: {
          $regex: `^${(0, _escapeRegex.default)(q)}`,
          $options: 'i'
        },
        fields: '_id,label,slug',
        limit: 10
      };
    }
  }, {
    label: 'Category',
    value: 'category',
    key: 'full_hierarchy_name',
    model: _category.default,
    query: function (q) {
      return {
        name: {
          $regex: `^${(0, _escapeRegex.default)(q)}`,
          $options: 'i'
        },
        fields: '_id,name,full_hierarchy_name,parent,macro,slug',
        populate: 'parent,macro',
        limit: 10
      };
    },

    transformResult(result) {
      const macroSlug = Ember.get(result, 'macro.slug');
      const parentSlug = Ember.get(result, 'parent.slug');
      delete result.macro;
      delete result.parent;
      return _objectSpread(_objectSpread({}, result), {}, {
        macroSlug,
        parentSlug
      });
    }

  }, {
    label: 'Product',
    value: 'product',
    key: 'name',
    model: _product.default,
    query: function (q) {
      return {
        name: {
          $regex: `^${(0, _escapeRegex.default)(q)}`,
          $options: 'i'
        },
        fields: '_id,name,slug,boutiques,has_product_unique_slug',
        limit: 10
      };
    },

    transformResult(result) {
      const boutique_slug = Ember.get(result, 'boutiques.0.boutique_slug');
      delete result.boutiques;
      return _objectSpread(_objectSpread({}, result), {}, {
        boutique_slug
      });
    }

  }, {
    label: 'Boutique',
    value: 'boutique',
    key: 'name',
    model: _boutique.default,
    query: function (q) {
      return {
        name: {
          $regex: `^${(0, _escapeRegex.default)(q)}`,
          $options: 'i'
        },
        fields: '_id,slug,name,brand',
        limit: 10,
        populate: 'tags'
      };
    }
  }, {
    label: 'Landing',
    value: 'discover',
    key: 'id',
    model: _landing.default,
    query: function () {
      return {};
    }
  }, {
    label: 'Info',
    value: 'pages',
    key: 'id',
    model: _info.default,
    query: function () {
      return {};
    }
  }, {
    label: 'Route',
    value: 'route',
    key: 'id',
    model: _sunflowerRoute.default,
    query: function () {
      return {};
    }
  }, {
    label: 'Custom URL',
    value: 'customUrl'
  }];
  _exports.availableTypes = availableTypes;
  let copyRowType = Ember.Object.create({
    title: '',
    class: 'row-copy',
    height: 1,
    length: 1,
    itemArr: Ember.A([Ember.Object.create({
      type: 'copy',
      content: {
        type: 'copy',
        copy_title: '',
        copy_text: ''
      },
      class: 'col-copy',
      height: 1,
      flex: 1
    })])
  });
  _exports.copyRowType = copyRowType;
  let colTypes = [{
    label: 'Box v2',
    value: {
      type: 'box-v2',
      content: {
        type: 'box-v2',
        image: {},
        link: {},
        video: {},
        mask: {
          enabled: false,
          opacity: 0.2,
          backgroundColor: 'black'
        },
        general: {
          position: 'middle-left',
          backgroundColor: 'white',
          wrapMobileElements: false,
          marginTop: '0px',
          marginRight: '0px',
          marginBottom: '0px',
          marginLeft: '0px',
          paddingTop: '25px',
          paddingRight: '25px',
          paddingBottom: '25px',
          paddingLeft: '25px'
        },
        elements: [{
          type: 'text',
          options: {
            tag: 'h2',
            text: '',
            color: 'color-white',
            style: {
              textAlign: 'left'
            }
          }
        }, {
          type: 'text',
          options: {
            tag: 'h3',
            text: '',
            color: 'color-white',
            style: {
              textAlign: 'left'
            }
          }
        }, {
          type: 'text',
          options: {
            tag: 'h4',
            text: '',
            color: 'color-white',
            style: {
              textAlign: 'left'
            }
          }
        }, {
          type: 'text',
          options: {
            tag: 'p',
            text: '',
            color: 'color-white',
            style: {
              textAlign: 'left'
            }
          }
        }, {
          type: 'button',
          options: {
            design: 'none',
            label: '',
            size: 'medium'
          }
        }]
      }
    }
  }, {
    label: 'Youtube',
    value: {
      type: 'youtube',
      content: {
        type: 'youtube',
        video: {
          id: '',
          ratio: '16:9',
          autoplay: true,
          mute: true
        },
        general: {
          marginTop: '0px',
          marginRight: '0px',
          marginBottom: '0px',
          marginLeft: '0px',
          paddingTop: '0px',
          paddingRight: '0px',
          paddingBottom: '0px',
          paddingLeft: '0px'
        }
      }
    }
  } // Disabled legacy landing page builder options
  // Only here to describe data structure
  // {
  //     label: 'box',
  //     value: {
  //         type: 'box',
  //         content: {
  //             type: 'category',
  //             id: '',
  //             title: '',
  //             subtitle: '',
  //             description: '',
  //             action_call: '',
  //             desktop: '',
  //             mobile: ''
  //         }
  //     }
  // },
  // {
  //     label: 'tile',
  //     value: {
  //         type: 'tile',
  //         content: {
  //             type: 'tile',
  //             text: '',
  //             backgroundColor: '',
  //             textColor: ''
  //         }
  //     }
  // },
  // {
  //     label: 'boutique',
  //     value: {
  //         type: 'boutique',
  //         content: {
  //             type: 'boutique',
  //             id: '',
  //             name: '',
  //             location: '',
  //             tags: [],
  //             // Show four products objects
  //             products: [{},{},{},{}],
  //             title: '',
  //             subtitle: '',
  //             description: '',
  //             action_call: '',
  //             desktop: '',
  //             mobile: ''
  //         }
  //     }
  // }
  ];
  _exports.colTypes = colTypes;
  let rowTypes = [{
    value: rowValue(50, [itemValue(50, 1)], '50-1'),
    display: [{
      width: 960,
      height: 480,
      width_percentage: '100%'
    }]
  }, {
    value: rowValue(50, [itemValue(50, 1), itemValue(50, 1)], '50-2'),
    display: [{
      width: 480,
      height: 480,
      width_percentage: '50%'
    }, {
      width: 480,
      height: 480,
      width_percentage: '50%'
    }]
  }, {
    value: rowValue(33, [itemValue(33, 1)], '33-1'),
    display: [{
      width: 960,
      height: 320,
      width_percentage: '100%'
    }]
  }, {
    value: rowValue(33, [itemValue(33, 1), itemValue(33, 1)], '33-2'),
    display: [{
      width: 480,
      height: 320,
      width_percentage: '50%'
    }, {
      width: 480,
      height: 320,
      width_percentage: '50%'
    }]
  }, {
    value: rowValue(33, [itemValue(33, 1), itemValue(33, 2)], '33-1-2'),
    display: [{
      width: 320,
      height: 320,
      width_percentage: 'calc(100% / 3)'
    }, {
      width: 640,
      height: 320,
      width_percentage: 'calc(100% * 2 / 3)'
    }]
  }, {
    value: rowValue(33, [itemValue(33, 2), itemValue(33, 1)], '33-2-1'),
    display: [{
      width: 640,
      height: 320,
      width_percentage: 'calc(100% * 2 / 3)'
    }, {
      width: 320,
      height: 320,
      width_percentage: 'calc(100% / 3)'
    }]
  }, {
    value: rowValue(33, [itemValue(33, 1), itemValue(33, 1), itemValue(33, 1)], '33-3'),
    display: [{
      width: 320,
      height: 320,
      width_percentage: 'calc(100% / 3)'
    }, {
      width: 320,
      height: 320,
      width_percentage: 'calc(100% / 3)'
    }, {
      width: 320,
      height: 320,
      width_percentage: 'calc(100% / 3)'
    }]
  }, {
    value: rowValue(25, [itemValue(25, 1)], '25-1'),
    display: [{
      width: 960,
      height: 240,
      width_percentage: '100%'
    }]
  }, {
    value: rowValue(25, [itemValue(25, 1), itemValue(25, 1)], '25-2'),
    display: [{
      width: 480,
      height: 240,
      width_percentage: '50%'
    }, {
      width: 480,
      height: 240,
      width_percentage: '50%'
    }]
  }, {
    value: rowValue(25, [itemValue(25, 1), itemValue(25, 1), itemValue(25, 1)], '25-3'),
    display: [{
      width: 320,
      height: 240,
      width_percentage: 'calc(100% / 3)'
    }, {
      width: 320,
      height: 240,
      width_percentage: 'calc(100% / 3)'
    }, {
      width: 320,
      height: 240,
      width_percentage: 'calc(100% / 3)'
    }]
  }, {
    value: rowValue(25, [itemValue(25, 1), itemValue(25, 1), itemValue(25, 1), itemValue(25, 1)], '25-4'),
    display: [{
      width: 240,
      height: 240,
      width_percentage: '25%'
    }, {
      width: 240,
      height: 240,
      width_percentage: '25%'
    }, {
      width: 240,
      height: 240,
      width_percentage: '25%'
    }, {
      width: 240,
      height: 240,
      width_percentage: '25%'
    }]
  }, {
    value: rowValue(12, [itemValue(12, 1)], '12-1'),
    display: [{
      width: 960,
      height: 120,
      width_percentage: '100%'
    }]
  }];
  _exports.rowTypes = rowTypes;
  let buttonTypes = [{
    value: 'none',
    label: 'None'
  }, {
    value: 'primary',
    label: 'Primary'
  }, {
    value: 'secondary',
    label: 'Secondary'
  }, {
    value: 'blue',
    label: 'Blue'
  }, {
    value: 'green',
    label: 'Green'
  }, {
    value: 'grey',
    label: 'Grey'
  }, {
    value: 'black',
    label: 'Black'
  } // {
  //     design: 'transparent'
  // }
  ];
  _exports.buttonTypes = buttonTypes;
  let buttonSizes = [{
    value: 'small',
    label: 'Small'
  }, {
    value: 'medium',
    label: 'Medium'
  }, {
    value: 'large',
    label: 'Large'
  }];
  _exports.buttonSizes = buttonSizes;
  let availablePositions = [{
    value: 'top-left',
    label: 'Top left'
  }, {
    value: 'top-center',
    label: 'Top center'
  }, {
    value: 'top-right',
    label: 'Top right'
  }, {
    value: 'middle-left',
    label: 'Middle left'
  }, {
    value: 'middle-center',
    label: 'Middle center'
  }, {
    value: 'middle-right',
    label: 'Middle right'
  }, {
    value: 'bottom-left',
    label: 'Bottom left'
  }, {
    value: 'bottom-center',
    label: 'Bottom center'
  }, {
    value: 'bottom-right',
    label: 'Bottom right'
  }];
  _exports.availablePositions = availablePositions;
  let availablePaddings = [{
    value: '0px',
    label: 'None'
  }, {
    value: '25px',
    label: 'Medium'
  }, {
    value: '50px',
    label: 'Big'
  }];
  _exports.availablePaddings = availablePaddings;
  let availableTextAligns = [{
    value: 'left',
    label: 'left'
  }, {
    value: 'center',
    label: 'center'
  }, {
    value: 'right',
    label: 'right'
  }];
  _exports.availableTextAligns = availableTextAligns;
  let availableMargins = [{
    value: '0px',
    label: 'None'
  }, {
    value: '25px',
    label: 'Medium'
  }, {
    value: '50px',
    label: 'Big'
  }];
  _exports.availableMargins = availableMargins;
  let availableBoxElements = [// Share element
  {
    label: 'Share',
    value: {
      type: 'share',
      options: {
        platforms: [{
          name: 'Whatsapp',
          platform: 'whatsapp',
          enabled: true
        }, {
          name: 'Facebook Messenger',
          platform: 'facebookMessenger',
          enabled: true
        }, {
          name: 'Pinterest',
          platform: 'pinterest',
          enabled: true
        }, {
          name: 'Facebook',
          platform: 'facebook',
          enabled: true
        }, {
          name: 'Twitter',
          platform: 'twitter',
          enabled: true
        }, {
          name: 'Email',
          platform: 'email',
          enabled: true
        }, {
          name: 'Copy link',
          platform: 'copy',
          enabled: true
        }]
      }
    }
  }, {
    label: 'Heading 2',
    value: {
      type: 'text',
      options: {
        tag: 'h2',
        text: '',
        color: 'color-white',
        style: {
          textAlign: 'left'
        }
      }
    }
  }, {
    label: 'Heading 3',
    value: {
      type: 'text',
      options: {
        tag: 'h3',
        text: '',
        color: 'color-white',
        style: {
          textAlign: 'left'
        }
      }
    }
  }, {
    label: 'Heading 4',
    value: {
      type: 'text',
      options: {
        tag: 'h4',
        text: '',
        color: 'color-white',
        style: {
          textAlign: 'left'
        }
      }
    }
  }, {
    label: 'Paragraph',
    value: {
      type: 'text',
      options: {
        tag: 'p',
        text: '',
        color: 'color-white',
        style: {
          textAlign: 'left'
        }
      }
    }
  }];
  _exports.availableBoxElements = availableBoxElements;

  function rowValue(height, itemArr, className) {
    return Ember.Object.create({
      title: '',
      class: `row-${className}`,
      height: height,
      length: itemArr.length,
      itemArr: Ember.A(itemArr)
    });
  }

  function itemValue(height, flex) {
    const defaultColumn = (0, _cloneDeep.default)(colTypes.find(col => col.value.type === 'box-v2').value);
    return Ember.Object.create(_objectSpread(_objectSpread({}, defaultColumn), {}, {
      class: `col-${height}-${flex}`,
      height: height,
      flex
    }));
  }
});