define("backalley/pods/components/quote-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['quote-form'],
    imageFolder: Ember.computed('quote', function () {
      return `quote/${this.get('quote._id')}`;
    })
  });

  _exports.default = _default;
});