define("backalley/pods/main/feeds/permanent_rules/controller", ["exports", "backalley/models/sync-integration"], function (_exports, _syncIntegration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _temp;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FeedsTriggerController = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class FeedsTriggerController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "errorMessage", _descriptor, this);
    }

    updateErrorMessageAfterDebounce({
      errorMessage
    }) {
      this.errorMessage = errorMessage;
    }

    clearErrorMessage() {
      this.updateErrorMessage('');
    }

    getUpdatedPayload() {
      const _this$model$syncInteg = this.model.syncIntegration,
            {
        _orig
      } = _this$model$syncInteg,
            rest = _objectWithoutProperties(_this$model$syncInteg, ["_orig"]);

      const commonPayload = _objectSpread(_objectSpread({}, rest), {}, {
        settings: _objectSpread(_objectSpread({}, rest.settings), {}, {
          boutique: rest.settings.boutique._id
        })
      });

      if (!this._permanentRules) {
        return commonPayload;
      }

      commonPayload.settings.rules = this._permanentRules ? this._permanentRules : [];
      return commonPayload;
    }

    async savePermanentRules() {
      const updatedSettings = this.getUpdatedPayload();
      await _syncIntegration.default.update(updatedSettings._id, updatedSettings);
    }

    updateErrorMessage(errorMessage) {
      Ember.run.debounce(this, this.updateErrorMessageAfterDebounce, {
        errorMessage
      }, 150);
    }

    updatePermanentRulesFromString(permanentRulesAsString) {
      try {
        this._permanentRules = permanentRulesAsString ? JSON.parse(permanentRulesAsString) : [];
        this.updateErrorMessage('');
      } catch (exception) {
        this.updateErrorMessage(exception.message);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "clearErrorMessage", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "clearErrorMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "savePermanentRules", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "savePermanentRules"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateErrorMessage", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "updateErrorMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePermanentRulesFromString", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "updatePermanentRulesFromString"), _class.prototype)), _class));
  _exports.default = FeedsTriggerController;
});