define("backalley/pods/components/auto-complete/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['auto-complete'],
    // Initial index
    index: -1,
    hideResults: false,
    query: '',
    // Reset when content changes
    resetActive: Ember.observer('content', function () {
      this.set('index', -1);
      this.set('hideResults', false);
    }),
    updateActiveState: Ember.observer('index', function () {
      const index = this.index;
      let content = this.content;

      if (!content) {
        return;
      }

      content.setEach('active', false); // Only set active states once we're
      // over the first element

      if (index >= 0) {
        content.set(index + '.active', true);
      }
    }),
    queryDidChange: Ember.observer('query', function () {
      this.search(this.query);
    }),

    /**
     * Monitors key events and increments / decrements
     * our lookup index
     */
    keyDown(e) {
      const index = this.index;
      let content = this.content;
      if (!content) return;
      const UP_ARROW = 38;
      const DOWN_ARROW = 40;
      const ENTER = 13;
      const DELETE = 8;
      const ESC = 27;
      const key = e.keyCode;
      if (key === ESC) return this.clear();
      if (key === UP_ARROW && index > 0) return this.decrementProperty('index');
      if (key === DOWN_ARROW && index < content.length - 1) return this.incrementProperty('index');
      if (key === DELETE && String(this.query).length <= 1) this.clear();

      if (key === ENTER) {
        const selected = content[index];
        this.clear();
        if (selected) this.select(selected);
        return false;
      }
    },

    clear() {
      if (this.content) Ember.trySet(this, 'content', []);
      if (this.query) Ember.trySet(this, 'query', '');
    },

    actions: {
      clear() {
        Ember.run.later(() => this.clear(), 800);
      },

      select(item) {
        this.select(item);
        this.clear();
      }

    }
  });

  _exports.default = _default;
});