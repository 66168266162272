define("backalley/mirage/factories/statementedit", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.default.Factory.extend({
    boutique: {
      name: 'hello',
      address: '20 Ropemaker st., London E1W3MN',
      postcode: 'E1W 1UN',
      bank_sort_code: '12-12-13',
      bank_account_number: '12345678'
    },
    reservations: [{
      created_at: '2015-12-03T13:01:09.746Z',
      ordernumber: 12345678,
      product_name: 'asdf - small',
      commission_exc_vat: 1,
      commission_inc_vat: 1,
      vat_commission: 1
    }, {
      created_at: '2015-12-03T13:01:09.746Z',
      ordernumber: 12345678,
      product_name: 'dfg - large',
      commission_exc_vat: 1,
      commission_inc_vat: 1,
      vat_commission: 1
    }, {
      created_at: '2015-12-03T13:01:09.746Z',
      ordernumber: 12345679,
      product_name: 'asdf1',
      commission_exc_vat: 1,
      commission_inc_vat: 1,
      vat_commission: 1
    }, {
      created_at: '2015-12-03T13:01:09.746Z',
      ordernumber: 12345679,
      product_name: 'asdf3',
      commission_exc_vat: 1,
      commission_inc_vat: 1,
      vat_commission: 1
    }],
    shipping_costs: [{
      ordernumber: 12345678,
      shipping_cost: -3500
    }],
    orders: [[{
      comments: 'blabla'
    }], [{
      comments: 'blabla'
    }]],
    sent: false,
    total_sales: -10000,
    total_shipping: -3500,
    total_commission_exc_vat: 5000,
    total_commission_vat: 500,
    total_commission_inc_vat: 5500,
    total_payout: -13500
  });

  _exports.default = _default;
});