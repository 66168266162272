define("backalley/pods/main/tags/new/route", ["exports", "backalley/models/tag"], function (_exports, _tag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return {
        tag: _tag.default.create({})
      };
    },

    actions: {
      create(tag) {
        return tag.post().then(() => this.transitionTo('main.tags'), err => this.notify.show('err', err.message));
      }

    }
  });

  _exports.default = _default;
});