define("backalley/pods/components/nav-pagination/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'nav',
    classNames: ['nav-pagination'],
    total: null,
    page: null,
    totalPagesArr: Ember.computed('total', 'page', function () {
      let pageTotal = Number(this.total) || 1;
      let page = Number(this.page);
      let pageNumbers = [];

      if (pageTotal <= 6) {
        // Show all the page numbers
        for (let i = 1; i <= pageTotal; i++) pageNumbers.push(i);
      } else {
        pageNumbers.push(1); // Always have 5 elements between the first and last page links

        if (page > pageTotal - 4) {
          pageNumbers.push('...');
          pageNumbers.push(pageTotal - 4, pageTotal - 3, pageTotal - 2, pageTotal - 1);
        } else if ([1, 2, 3, 4].includes(page)) {
          pageNumbers.push(2, 3, 4, 5);
          pageNumbers.push('...');
        } else if (page > 4) {
          pageNumbers.push('...');
          pageNumbers.push(page - 1, page, page + 1);
          pageNumbers.push('...');
        }

        pageNumbers.push(pageTotal);
      }

      return pageNumbers;
    }),
    hasPrev: Ember.computed.gt('page', 1),
    hasNext: Ember.computed('page', 'total', function () {
      return this.total > this.page;
    })
  });

  _exports.default = _default;
});