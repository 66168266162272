define("backalley/pods/main/packaging/orders/edit/route", ["exports", "backalley/models/packaging/order"], function (_exports, _order) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PackagingOrdersEditRoute extends Ember.Route {
    async model({
      id
    }) {
      try {
        return await _order.default.findById(id);
      } catch (e) {
        return this.transitionTo('main.packaging.orders');
      }
    }

  }

  _exports.default = PackagingOrdersEditRoute;
});