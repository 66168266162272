define("backalley/pods/application/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    country: Ember.inject.service(),

    beforeModel(transition) {
      // Check if user is logged in when the application boots (once)
      return this.session.getLoggedIn().then(() => this._super(transition)).catch(err => this.logger.error(err));
    },

    model() {
      if (!this.session.isLoggedIn) {
        return;
      }

      return Ember.RSVP.hash({
        countries: this.country.getCountries(),
        zones: this.country.getZones()
      });
    }

  });

  _exports.default = _default;
});