define("backalley/helpers/negated-price-with-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.negatedPriceWithLabel = negatedPriceWithLabel;
  _exports.default = void 0;

  function negatedPriceWithLabel([price, label = '']) {
    if (Ember.isEmpty(price)) return '';
    let amount = parseFloat(price / -100);
    return amount >= 0 ? `${label}${amount.toFixed(2)}` : `-${label}${Math.abs(amount).toFixed(2)}`;
  }

  var _default = Ember.Helper.helper(negatedPriceWithLabel);

  _exports.default = _default;
});