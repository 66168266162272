define("backalley/pods/components/order-audit-log/component", ["exports", "backalley/util/default-to", "backalley/models/order_versions", "backalley/models/reservation_versions", "backalley/models/reservation_boutique_versions", "backalley/models/order", "backalley/models/reservation", "backalley/models/reservation_boutique", "backalley/util/models"], function (_exports, _defaultTo, _order_versions, _reservation_versions, _reservation_boutique_versions, _order, _reservation, _reservation_boutique, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  var _default = Ember.Component.extend({
    classNames: ['list-events'],
    // Public API
    order: (0, _defaultTo.default)({}),
    boutiques: (0, _defaultTo.default)([]),
    // Private API
    _auditLog: [],
    _loading: false,

    didReceiveAttrs() {
      return this.refreshAuditLog();
    },

    refreshAuditLog() {
      Ember.set(this, '_loading', true);
      return Ember.RSVP.all([this.fetchOrderAuditLog(), this.fetchReservationBoutiqueAuditLog(), this.fetchReservationAuditLog()]).then(this.mergeAuditLog).then(log => this.set('_auditLog', log)).finally(() => Ember.set(this, '_loading', false));
    },

    keysFromSchema(Schema) {
      return Array.from(Schema._ids._byKey.keys());
    },

    fetchOrderAuditLog() {
      const order_id = Ember.get(this, 'order._id');
      const orderKeys = this.keysFromSchema(_order.default.put_schema);
      return _order_versions.default.find({
        refId: order_id
      }).then(versions => {
        let changes = versions.map((version, i) => {
          return {
            updatedFields: (0, _models.getChanges)(versions[i - 1] || versions[0], version, orderKeys),
            updatedBy: version.updated_by,
            updatedAt: version.updated_at,
            type: 'order'
          };
        }); // split comments to their own

        changes = changes.reduce((all, _ref) => {
          let {
            updatedFields
          } = _ref,
              rest = _objectWithoutProperties(_ref, ["updatedFields"]);

          let {
            comments,
            _id,
            _ver
          } = updatedFields,
              keys = _objectWithoutProperties(updatedFields, ["comments", "_id", "_ver"]);

          if (comments) {
            if (Object.keys(keys).length > 0) all.push(_objectSpread({
              updatedFields: keys
            }, rest));
            all.push(_objectSpread({
              updatedFields: {
                comments
              }
            }, rest));
          } else {
            all.push(_objectSpread({
              updatedFields: updatedFields
            }, rest));
          }

          return all;
        }, []);
        return changes;
      });
    },

    fetchReservationAuditLog() {
      const order_id = Ember.get(this, 'order._id');
      const reservationKeys = this.keysFromSchema(_reservation.default.put_schema);
      return _reservation_versions.default.find({
        order: order_id
      }).then(versions => {
        return versions.map((version, i) => {
          return {
            updatedFields: (0, _models.getChanges)(versions[i - 1] || versions[0], version, reservationKeys),
            updatedBy: version.updated_by,
            updatedAt: version.updated_at,
            type: 'reservation',
            name: version.product.name
          };
        });
      });
    },

    fetchReservationBoutiqueAuditLog() {
      const promises = [];
      const boutiques = Ember.get(this, 'boutiques');
      const order_id = Ember.get(this, 'order._id');
      const reservationBoutiqueKeys = this.keysFromSchema(_reservation_boutique.default.put_schema);
      boutiques.forEach(boutique => {
        const promise = _reservation_boutique_versions.default.find({
          order: order_id,
          boutique: boutique._id
        }).then(versions => {
          return versions.map((version, i) => {
            return {
              updatedFields: (0, _models.getChanges)(versions[i - 1] || versions[0], version, reservationBoutiqueKeys),
              updatedBy: version.updated_by,
              updatedAt: version.updated_at,
              type: 'reservation_boutique',
              name: boutique.name
            };
          });
        });

        promises.push(promise);
      });
      return Ember.RSVP.all(promises).then(([reservationBoutiqueChanges]) => reservationBoutiqueChanges);
    },

    mergeAuditLog([orderAuditLog, reservationBoutiqueAuditLog, reservationAuditLog]) {
      const removeUndefined = val => typeof val !== 'undefined';

      return [...orderAuditLog, ...reservationBoutiqueAuditLog, ...reservationAuditLog].filter(removeUndefined).sort((a, b) => {
        return new Date(a.updatedAt) > new Date(b.updatedAt) ? -1 : 1;
      });
    }

  });

  _exports.default = _default;
});