define("backalley/pods/components/packaging-product-form/variant/component", ["exports", "ember-concurrency", "backalley/models/country", "backalley/models/currency"], function (_exports, _emberConcurrency, _country, _currency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    country: Ember.inject.service(),
    tagName: '',

    async didInsertElement() {
      this._super(...arguments);

      const selectedCountry = this.variant.country ? this.country.findCountryByProperty('_id', this.variant.country) : null;
      const [selectedCurrency] = this.variant.pack.price.currency ? await _currency.default.find({
        iso_4217: this.variant.pack.price.currency
      }) : [];
      Ember.set(this, 'selectedCountry', selectedCountry);
      Ember.set(this, 'selectedCurrency', selectedCurrency);
    },

    searchCountry: (0, _emberConcurrency.task)(function* (query) {
      yield (0, _emberConcurrency.timeout)(500);
      return yield _country.default.find({
        name: {
          $regex: `^${query}`,
          $options: 'i'
        }
      });
    }).restartable(),
    searchCurrency: (0, _emberConcurrency.task)(function* (query) {
      yield (0, _emberConcurrency.timeout)(500);
      return yield _currency.default.find({
        iso_4217: {
          $regex: `^${query}`,
          $options: 'i'
        }
      });
    }).restartable(),
    packPriceAmount: Ember.computed('variant.pack.price.amount', {
      get() {
        let amount = this.variant.pack.price.amount;
        return amount ? amount / 100 : null;
      },

      set(_, value) {
        const parsedValue = Ember.isEmpty(value) ? null : Math.round(parseFloat(value).toFixed(2) * 100);
        Ember.set(this.variant, 'pack.price.amount', parsedValue);
        return value;
      }

    }),
    actions: {
      parseInt(value) {
        return parseInt(value, 10);
      },

      duplicate() {
        return this.duplicate(this.variant);
      },

      remove() {
        if (confirm('Are you sure you want to delete this variant?')) {
          return this.remove(this.variant);
        }
      },

      setCountry(country) {
        Ember.set(this, 'selectedCountry', country);
        Ember.set(this.variant, 'country', country._id);
      },

      setCurrency(currency) {
        Ember.set(this, 'selectedCurrency', currency);
        Ember.set(this.variant, 'pack.price.currency', currency.iso_4217);
      },

      setDimensionsUnit(unit) {
        Ember.set(this, 'variant.dimensions.length.unit', unit);
        Ember.set(this, 'variant.dimensions.width.unit', unit);
        Ember.set(this, 'variant.dimensions.height.unit', unit);
      }

    }
  });

  _exports.default = _default;
});