define("backalley/pods/main/paths-and-lanes/index/route", ["exports", "backalley/models/shipping-path-release", "backalley/models/carrier-lane-release", "backalley/models/team-note", "backalley/enums/path-and-lane-release-details-status-label"], function (_exports, _shippingPathRelease, _carrierLaneRelease, _teamNote, _pathAndLaneReleaseDetailsStatusLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TEAM_NOTES_BASE_FILE_PATH = 'paths-and-lanes';

  var _default = Ember.Route.extend({
    queryParams: {
      pathPage: {
        refreshModel: true
      },
      lanePage: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      }
    },

    async extendReleaseWithNotes(release, releaseType) {
      const DESCRIPTION_MAXIMUM_LENGTH = 400;
      const releaseDate = new Date(release.release_datetime).valueOf();

      try {
        const teamNote = await _teamNote.default.find(`${TEAM_NOTES_BASE_FILE_PATH}/${releaseType}/${releaseDate}`);
        release.description = teamNote.static && teamNote.static.text || 'Description unavailable';
      } catch (error) {
        release.description = 'Description unavailable';
        console.error(`Error fetching Team Notes: ${error}`);
      }

      if (release.description.length > DESCRIPTION_MAXIMUM_LENGTH) {
        release.description = release.description.substring(0, DESCRIPTION_MAXIMUM_LENGTH - 49) + '... (truncated. See full description in Details)';
      }
    },

    extendReleaseWithStatus(release) {
      const releasingStatuses = [_pathAndLaneReleaseDetailsStatusLabel.default.CREATED, _pathAndLaneReleaseDetailsStatusLabel.default.PROCESSING_ITEMS, _pathAndLaneReleaseDetailsStatusLabel.default.PROCESSING_RULES];

      if (releasingStatuses.includes(release.status_label)) {
        release.status = 'Being released';
        return;
      }

      if (release.status_label === _pathAndLaneReleaseDetailsStatusLabel.default.STABLE) {
        if (release.is_active) {
          release.status = 'Active';
          return;
        }

        release.status = 'Released';
        return;
      }

      if (release.status_label === _pathAndLaneReleaseDetailsStatusLabel.default.ACTIVE) {
        if (release.is_active) {
          release.status = 'Active';
          return;
        }

        release.status = 'Active, but should not be';
        return;
      }

      const failedStatuses = [_pathAndLaneReleaseDetailsStatusLabel.default.ERRORED, _pathAndLaneReleaseDetailsStatusLabel.default.CRITICAL];

      if (failedStatuses.includes(release.status_label)) {
        if (release.is_active) {
          release.status = 'Should be active, but release failed';
          return;
        }

        release.status = 'Failed';
        return;
      }

      if (release.status_label === _pathAndLaneReleaseDetailsStatusLabel.default.DECOMMISSIONING) {
        if (release.is_active) {
          release.status = 'Should be active, but is being decommissioned';
          return;
        }

        release.status = 'Being decommissioned';
        return;
      }

      if (release.status_label === _pathAndLaneReleaseDetailsStatusLabel.default.ARCHIVED) {
        if (release.is_active) {
          release.status = 'Should be active, but is archived';
          return;
        }

        release.status = 'Archived';
        return;
      }

      release.status = 'Status unavailable';
    },

    async extendManyReleasesWithMetadata(releaseContainer) {
      const {
        release_type: releaseType,
        releases
      } = releaseContainer;
      releaseContainer.releases = await Promise.all(releases.map(async release => {
        await this.extendReleaseWithNotes(release, releaseType);
        this.extendReleaseWithStatus(release);
        return release;
      }));
      return releaseContainer;
    },

    async model({
      variant = 'standard',
      pathPage,
      lanePage,
      limit
    }) {
      const promiseToListShippingPathReleases = params => _shippingPathRelease.default.list(params).then(shippingPathReleases => this.extendManyReleasesWithMetadata(shippingPathReleases));

      const promiseToListCarrierLaneReleases = params => _carrierLaneRelease.default.list(params).then(carrierLaneReleases => this.extendManyReleasesWithMetadata(carrierLaneReleases));

      const [shippingPathReleases, carrierLaneReleases] = await Promise.all([promiseToListShippingPathReleases({
        variant,
        limit,
        page: pathPage
      }), promiseToListCarrierLaneReleases({
        variant,
        limit,
        page: lanePage
      })]);
      return {
        shippingPath: shippingPathReleases,
        carrierLane: carrierLaneReleases,
        user: this.session.user,
        teamNotesBaseFilePath: TEAM_NOTES_BASE_FILE_PATH
      };
    }

  });

  _exports.default = _default;
});