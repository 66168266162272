define("backalley/pods/components/site-menu/link/component", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    favouriteTabs: (0, _emberLocalStorage.storageFor)('favourite-tabs'),
    tagName: 'li',
    isFavourite: Ember.computed('favouriteTabs.[]', 'link', function () {
      return this.favouriteTabs.includes(this.link);
    }),

    addFavourite() {
      this.favouriteTabs.addObject(this.link);
      this.notify.show('success', `Added "${this.link.label}" to favourites! ⭐`, 4000);
    },

    removeFavourite() {
      this.favouriteTabs.removeObject(this.link);
      this.notify.show('success', `Removed "${this.link.label}" from favourites!`, 4000);
    },

    actions: {
      toggleFavourite() {
        return this.favouriteTabs.includes(this.link) ? this.removeFavourite() : this.addFavourite();
      }

    }
  });

  _exports.default = _default;
});