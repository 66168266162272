define("backalley/models/path-and-lane-release", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PathAndLaneRelease {
    constructor({
      release,
      carrierLanes,
      shippingPaths
    } = {}) {
      this.release = release || {};
      this.carrierLanes = carrierLanes || {
        list: []
      };
      this.shippingPaths = shippingPaths || {
        list: []
      };
    }

  }

  _exports.default = PathAndLaneRelease;
});