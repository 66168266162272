define("backalley/pods/main/frames/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ptdtEBlu",
    "block": "{\"symbols\":[\"HotLoadFrameFormzy99877iim\",\"HotLoadPageHeading1fw6ole0d8wi\"],\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"PageHeading\",[23,0,[]],\"PageHeading\",\"PageHeading\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"PageHeading\",\"PageHeading\",true,false]]]],null,{\"statements\":[[6,[23,2,[]],[],[[\"@fa\"],[\"store\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"main.frames.index\"]],{\"statements\":[[0,\"\\n      Frames\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[1,[24,[\"model\",\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null],[0,\"\\n\\n\"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"FrameForm\",[23,0,[]],\"FrameForm\",\"FrameForm\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"FrameForm\",\"FrameForm\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[],[[\"@frame\",\"@isEdit\"],[[23,0,[\"model\"]],false]]]],\"parameters\":[1]},null],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/frames/new/template.hbs"
    }
  });

  _exports.default = _default;
});