define("backalley/pods/main/landing/index/route", ["exports", "backalley/models/landing"], function (_exports, _landing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return _landing.default.find();
    },

    actions: {
      recoverLandingPage() {
        this.set('currentModel.recoverLandingPageLoading', true);
        const idToRecover = prompt('Which landing page would you like to recover?');

        if (idToRecover) {
          return _landing.default.findIndividual(idToRecover).then(pageToRecover => {
            return _landing.default.find().then(({
              pages
            }) => {
              // Check if page exists
              const pageExists = pages.findIndex(p => p.id === pageToRecover.id) > -1;

              if (pageExists) {
                return Ember.RSVP.reject({
                  message: 'Page already recovered'
                });
              } // If page is not already recovered, add the recovered page into the array of pages


              return _landing.default.save({
                pages: [...pages, pageToRecover]
              });
            }).then(() => {
              alert(`Page "${idToRecover}" recovered`);
              return this.refresh();
            });
          }).catch(err => alert(err.message)).finally(() => this.set('currentModel.recoverLandingPageLoading', false));
        } else {
          this.set('currentModel.recoverLandingPageLoading', false);
        }
      }

    }
  });

  _exports.default = _default;
});