define("backalley/pods/main/manufacturers/index/route", ["exports", "backalley/models/manufacturer", "backalley/models/product", "backalley/mixins/loading", "backalley/util/escape-regex"], function (_exports, _manufacturer, _product, _loading, _escapeRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_loading.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      has_alternate: {
        refreshModel: true
      },
      has_content: {
        refreshModel: true
      },
      is_archived: {
        refreshModel: true
      },
      mode: {
        refreshModel: true
      }
    },

    serializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      // don't show objects in url
      if (urlKey === 'sort' || urlKey === 'status' || urlKey === 'has_alternate' || urlKey === 'is_archived' || urlKey === 'has_content') {
        return value.value;
      }

      if (urlKey === 'page') return Number(value);
      return value;
    },

    deserializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      if (urlKey === 'sort') return this.availableFilters.findBy('value', value);
      if (urlKey === 'status') return this.availableStatuses.findBy('value', value);
      if (urlKey === 'has_alternate') return this.availableHasAlternate.findBy('value', value);
      if (urlKey === 'is_archived') return this.isArchived.findBy('value', value);
      if (urlKey === 'has_content') return this.availableHasContent.findBy('value', value);
      return value;
    },

    availableFilters: [{
      label: 'Date: last updated',
      value: '-updated_at'
    }, {
      label: 'Date: first updated',
      value: 'updated_at'
    }, {
      label: 'Date: last created',
      value: '-created_at'
    }, {
      label: 'Date: first created',
      value: 'created_at'
    }, {
      label: 'Name: A-Z',
      value: 'normalized_name'
    }, {
      label: 'Name: Z-A',
      value: '-normalized_name'
    }, {
      label: 'Products: 0-X',
      value: 'product_count'
    }, {
      label: 'Products: X-0',
      value: '-product_count'
    }],
    availableStatuses: [{
      label: 'Status: All',
      value: 'all'
    }, {
      label: 'Status: Pending',
      value: 'pending'
    }, {
      label: 'Status: Approved',
      value: 'approved'
    }],
    availableHasAlternate: [{
      label: 'Alternate Names: All',
      value: 'all'
    }, {
      label: 'Only with alternate',
      value: 'alternate'
    }, {
      label: 'Only without alternate',
      value: 'no_alternate'
    }],
    isArchived: [{
      label: 'Archived: Show all',
      value: 'all'
    }, {
      label: 'Archived: Show only non archived',
      value: 'false'
    }, {
      label: 'Archived: Show only archived',
      value: 'true'
    }],
    availableHasContent: [{
      label: 'Content: Any',
      value: 'all'
    }, {
      label: 'Content: Show completed content',
      value: 'content_complete'
    }, {
      label: 'Content: Show missing content',
      value: 'content_incomplete'
    }],
    productCache: new Map(),

    model({
      q,
      page,
      limit,
      sort,
      status,
      has_alternate,
      is_archived,
      has_content,
      mode
    }) {
      const query = {
        page,
        limit,
        sort: sort.value
      };
      if (q) query.$or = [{
        name: {
          $regex: `^${(0, _escapeRegex.default)(q)}`,
          $options: 'i'
        }
      }, {
        alternate_names: {
          $regex: `^${(0, _escapeRegex.default)(q)}`,
          $options: 'i'
        }
      }];
      if (status.value !== 'all') query.status = status.value;
      if (has_alternate.value === 'alternate') query['alternate_names.0'] = {
        $exists: true
      };
      if (has_alternate.value === 'no_alternate') query['alternate_names.0'] = {
        $exists: false
      };

      if (is_archived.value === 'all') {
        delete query.archived;
      } else if (is_archived.value === 'true') {
        query['archived'] = true;
      } else {
        query['archived'] = {
          $ne: true
        };
      }

      if (has_content.value === 'content_complete') {
        query['url'] = {
          $exists: true
        };
        query['logo'] = {
          $exists: true
        };
      } else if (has_content.value === 'content_incomplete') {
        query.$or = [{
          url: {
            $exists: false
          }
        }, {
          logo: {
            $exists: false
          }
        }];
      }

      if (mode === 'missing_content') {
        query.$or = [{
          url: {
            $exists: false
          }
        }, {
          logo: {
            $exists: false
          }
        }];
      }
      /**
       * We've got 3 modes [standard, missing_content, approval]
       * In the first 2 modes, the query in the model is the same
       * For the approval mode though we utilise fuzzy matching to
       * prioritise the right manufacturer, 1 at a time. For this the
       * query is different.
       *
       * The following allows us to differentiate, bear in mind that
       * the end result in terms of the data consumed by the
       * fronted will be the same.
       */


      const previousShuffleManufacturer = this.getWithDefault('currentModel.shuffleManufacturer', {});
      let promise;

      if (mode === 'approval') {
        promise = _manufacturer.default.shuffle();
      } else {
        promise = _manufacturer.default.findWithMeta(query);
      }

      return promise.then(({
        manufacturers = [],
        meta = {
          total: 3
        },
        shuffleManufacturer = {}
      }) => {
        return {
          manufacturers,
          // Used in the approval process
          shuffleManufacturer,
          previousShuffleManufacturer,
          total: Math.ceil(meta.total / limit),
          search: q,
          sort,
          status,
          has_alternate,
          has_content,
          is_archived,
          mode,
          user: this.session.user,
          availableFilters: this.availableFilters,
          availableStatuses: this.availableStatuses,
          availableHasAlternate: this.availableHasAlternate,
          availableHasContent: this.availableHasContent,
          isArchived: this.isArchived,
          mergeLoading: false
        };
      });
    },

    actions: {
      goToPage,
      nextPage,
      prevPage,
      updateSearch,
      updateFilter,
      updateStatus,
      updateHasAlternate,
      updateIsArchived,
      updateHasContent,
      remove,
      setMode: function (mode) {
        this.controller.set('mode', mode);
      },
      triggerRefresh: function () {
        this.refresh();
      },
      toggle: function (manufacturer) {
        manufacturer.toggleProperty('isSelected');
        const anySelected = this.get('currentModel.manufacturers').any(m => m.isSelected);

        if (anySelected) {
          const mode = this.get('currentModel.mode');

          if (mode === 'approval') {
            this.set('currentModel.mergeTarget', this.get('currentModel.shuffleManufacturer'));
          }

          this.set('currentModel.mergeMode', true);
        } else {
          this.set('currentModel.mergeMode', false);
        }
      },
      close: function () {
        this.set('currentModel.mergeMode', false);
        this.set('currentModel.mergeTarget', null);
        this.get('currentModel.manufacturers').forEach(m => m.set('isSelected', false));
      },

      searchMergeTarget(q) {
        const selected = this.get('currentModel.manufacturers').filter(m => m.isSelected);
        const selectedIds = selected.map(m => m._id);
        return _manufacturer.default.findWithMeta({
          _id: {
            $nin: selectedIds
          },
          // should not be able to merge into these
          $or: [{
            name: {
              $regex: `^${(0, _escapeRegex.default)(q)}`,
              $options: 'i'
            }
          }, {
            alternate_names: {
              $regex: `^${(0, _escapeRegex.default)(q)}`,
              $options: 'i'
            }
          }],
          limit: 7,
          archived: {
            $ne: true
          }
        }).then(({
          manufacturers
        }) => manufacturers);
      },

      deSelectMergeTarget() {
        this.set('currentModel.mergeTarget', null);
      },

      merge() {
        const mode = this.get('currentModel.mode');
        const target = this.get('currentModel.mergeTarget');
        const selected = this.get('currentModel.manufacturers').filter(m => m.isSelected);
        const selectedIds = selected.map(m => m._id);
        if (!target) return this.notify.show('error', 'Select a target brand first!', 5000);
        this.set('currentModel.mergeLoading', true);
        return _manufacturer.default.merge(selectedIds, target._id).then(updatedManufacturer => {
          this.set('currentModel.mergeLoading', false);
          this.set('currentModel.mergeTarget', null);
          this.set('currentModel.mergeMode', false);
          this.get('currentModel.manufacturers').removeObjects(selected);
          const targetInPage = this.get('currentModel.manufacturers').findBy('_id', target._id);

          if (targetInPage) {
            targetInPage.set('products', targetInPage.get('products') + selected.reduce((sum, m) => sum + m.get('products'), 0));
          }

          if (mode === 'approval') {
            this.set('currentModel.shuffleManufacturer', updatedManufacturer);
          }

          this.notify.show('success', 'Merged successfully and updated all products!', 5000);
        });
      },

      updateManufacturerStatusAndRefresh(manufacturer, status = {}) {
        manufacturer.set('status', status.value);
        manufacturer.save().then(() => {
          this.notify.show('success', 'Updated manufacturer', 5000);
          this.refresh();
        }, () => {
          this.notify.show('error', 'Name or one of the alternate names are already in use! Please choose another one!', 5000);
        });
      },

      updateManufacturerStatus(manufacturer, status = {}) {
        manufacturer.set('status', status.value);
        manufacturer.save().then(() => this.notify.show('success', 'Updated manufacturer', 5000), () => this.notify.show('error', 'Name or one of the alternate names are already in use! Please choose another one!', 5000));
      },

      /**
       * In approval mode we provide the ability of
       * switching an item from the table with
       * the shuffleManufacturer to give the publishing
       * team more flexibility.
       */
      switchMain(index, target) {
        const list = this.get('currentModel.manufacturers');
        const main = this.get('currentModel.shuffleManufacturer');
        this.set('currentModel.shuffleManufacturer', target);
        list.replace(index, 1, main);
      },

      goToMain(values = {}) {
        this.controller.resetToDefaultsAndExtend(values);
      }

    }
  });

  _exports.default = _default;

  function goToPage(page) {
    this.transitionTo({
      queryParams: {
        page: page
      }
    });
  }

  function nextPage() {
    const page = this.get('controller.page');
    if (page < this.get('currentModel.total')) this.transitionTo({
      queryParams: {
        page: Number(page) + 1
      }
    });
  }

  function prevPage() {
    const page = this.get('controller.page');
    if (page > 1) this.transitionTo({
      queryParams: {
        page: Number(page) - 1
      }
    });
  }

  function resetParams() {
    this.set('controller.page');
  }

  function updateSearch(q) {
    this.set('controller.q', q);
    this.set('controller.page', 1);
  }

  function updateHasAlternate(status) {
    this.set('controller.has_alternate', status);
  }

  function updateIsArchived(status) {
    this.set('controller.is_archived', status);
  }

  function updateHasContent(status) {
    this.set('controller.has_content', status);
  }

  function updateStatus(status) {
    this.set('controller.status', status);
  }

  function updateFilter(filter) {
    this.set('controller.sort', filter);
  }

  function remove(manufacturer) {
    const mode = this.get('currentModel.mode');

    if (confirm(`Are you sure you want to archive the manufacturer: ${manufacturer.name}`)) {
      manufacturer.remove().then(() => {
        /**
         * If we're in the approval mode, we want to fetch
         * a new manufacturer given this one has been archived.
         * By refreshing the route, we're triggering
         * a new fetch from the shuffle endpoint
         */
        if (mode === 'approval') {
          this.refresh();
        } else {
          this.get('currentModel.manufacturers').removeObject(manufacturer);
        }
      });
    }
  }
  /**
   * In certain modes like the "Missing Content" mode
   * we want to sort on a page by page basis based on
   * the number of products the manufacturers have to
   * increase the value added by the publishing team.
   *
   * Ideally this would happen on server-side for all
   * manufacturers but even on a page-by-page basis
   * this would definitely help.
   *
   * This function gets the list of Manufacturers with Products
   * and sorts by product count.
   * @param  {Array} list List of manufacturers on current page with products
   * @return {Array}      If mode['missing_content'] returns sorted by # products
   */


  function sortProductsForMode(list, mode) {
    if (mode === 'missing_content') {
      // Highest product count first as Ember
      // doesn't do ASC / DESC we need to reverse
      list = list.sortBy('products').reverse();
    }

    return list;
  }
});