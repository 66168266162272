define("backalley/pods/components/landing-page/partials/product-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QZSvCWwv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"component\",[[28,\"hot-load\",[\"auto-complete\",[23,0,[]],[24,[\"auto-complete\"]],\"auto-complete\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"search\",\"select\",\"content\",\"placeholder\",\"key\"],[[23,0,[]],\"auto-complete\",[24,[\"auto-complete\"]],false,true,[28,\"action\",[[23,0,[]],\"searchProducts\"],null],[24,[\"onSelect\"]],[24,[\"searchResults\"]],\"Look up a product from this boutique\",\"name\"]]],false],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/landing-page/partials/product-search/template.hbs"
    }
  });

  _exports.default = _default;
});