define("backalley/enums/statement-invoice-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    BOUTIQUE_TO_MADE: 'boutique_to_made',
    MADE_TO_TROUVA: 'made_to_trouva',
    TROUVA_TO_MADE: 'trouva_to_made'
  };
  _exports.default = _default;
});