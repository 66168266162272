define("backalley/pods/components/basic-editable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NcUb4gGQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"edit\"]]],null,{\"statements\":[[0,\"  \"],[7,\"input\",true],[11,\"value\",[22,\"_value\"]],[10,\"class\",\"input input__basic-editable\"],[11,\"oninput\",[28,\"action\",[[23,0,[]],\"_onInput\"],null]],[8],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"button button-primary\"],[3,\"action\",[[23,0,[]],\"save\",[24,[\"_value\"]]]],[8],[0,\"Done\"],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"button button-secondary\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"Cancel\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"autoformatEnabled\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"button button-green button-auto-format\"],[3,\"action\",[[23,0,[]],\"_autoformat\"]],[8],[0,\"Auto-format title\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"edit\"],[[28,\"action\",[[23,0,[]],\"toggleEdit\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/basic-editable/template.hbs"
    }
  });

  _exports.default = _default;
});