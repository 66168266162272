define("backalley/pods/components/shipment-zone-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['shipment-zone-form'],
    save: Ember._action(function () {
      return this.onSave(this.shipmentZone);
    })
  });

  _exports.default = _default;
});