define("backalley/pods/components/basic-notes/new/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    comment: Ember.computed.reads('notes.comment'),
    actions: {
      save(comment) {
        const saveAction = Ember.get(this, 'notes.actions.save');
        return saveAction(comment);
      },

      reset(comment) {
        const resetAction = Ember.get(this, 'notes.actions.reset');
        return resetAction(comment);
      }

    }
  });

  _exports.default = _default;
});