define("backalley/pods/main/currency-conversion-margins/route", ["exports", "backalley/models/currency-conversion-margin"], function (_exports, _currencyConversionMargin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      source: {
        refreshModel: true
      },
      destination: {
        refreshModel: true
      }
    },

    model({
      source,
      destination
    }) {
      return _currencyConversionMargin.default.find({
        source,
        destination,
        populate: 'source,destination'
      }).then(({
        currencyConversionMargins
      }) => ({
        currencyConversionMargins
      }));
    },

    actions: {
      selectSourceCurrency(value) {
        this.transitionTo({
          queryParams: {
            source: value.value
          }
        });
      },

      selectDestinationCurrency(value) {
        this.transitionTo({
          queryParams: {
            destination: value.value
          }
        });
      }

    }
  });

  _exports.default = _default;
});