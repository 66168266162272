define("backalley/pods/main/product-amendments/route", ["exports", "backalley/models/boutique", "backalley/models/product_amendment", "backalley/util/escape-regex"], function (_exports, _boutique, _product_amendment, _escapeRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      boutique: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      }
    },
    availableStatus: [{
      label: 'Status: All',
      value: ''
    }, {
      label: 'Status: Pending and Awaiting review',
      value: 'pending||resolved'
    }, {
      label: 'Status: Pending',
      value: 'pending'
    }, {
      label: 'Status: Awaiting review',
      value: 'resolved'
    }, {
      label: 'Status: Approved',
      value: 'approved'
    }, {
      label: 'Status: Rejected',
      value: 'rejected'
    }],

    model({
      boutique,
      status,
      page,
      limit,
      sort
    }) {
      const boutiqueSelected = boutique ? _boutique.default.findById(boutique) : Ember.RSVP.resolve(); // Support multiple statuses

      const statuses = status.split('||');

      const productAmendments = _product_amendment.default.find({
        boutique: boutique ? boutique : undefined,
        status: status ? {
          $in: statuses
        } : undefined,
        sort,
        limit,
        page: Number(page),
        populate: 'boutique,product,user'
      });

      return Ember.RSVP.all([productAmendments, boutiqueSelected]).then(([{
        product_amendments,
        meta
      }, boutique]) => {
        const queryParams = arguments[0];
        return {
          queryParams,
          boutique,
          amendments: product_amendments,
          meta,
          // Dropdown options
          availableStatus: this.availableStatus,
          // Autcomplete options
          // Used for the search autocomplete
          boutiques: []
        };
      });
    },

    actions: {
      searchBoutiques(q) {
        if (Ember.isEmpty(q)) return;
        const query = {
          name: {
            $regex: `^${(0, _escapeRegex.default)(q)}`,
            $options: 'i'
          },
          limit: 10
        };
        return _boutique.default.find(query).then(boutiques => boutiques.map(({
          name,
          _id
        }) => ({
          label: name,
          value: _id
        }))).then(boutiques => this.set('currentModel.boutiques', boutiques));
      },

      updateQueryParam(field, value) {
        this.set(`controller.${field}`, value);
      },

      updateSelectQueryParam(field, value) {
        this.set(`controller.${field}`, Ember.get(value, 'value'));
      },

      nextPage() {
        this.set('controller.page', Number(Ember.get(this, 'controller.page')) + 1);
      },

      prevPage() {
        this.set('controller.page', Number(Ember.get(this, 'controller.page')) - 1);
      },

      goToPage(page) {
        this.set('controller.page', page);
      }

    }
  });

  _exports.default = _default;
});