define("backalley/pods/components/form-select-new/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['form-select-new'],
    // use selected if you need the whole selected object
    selected: null,
    // use selectedValue if you only need the selected[valuePath]
    selectedValue: null,
    valuePath: 'value',
    labelPath: 'label',
    s: function () {
      let valuePath = this.valuePath;
      let selectedValue = this.selectedValue;
      return typeof selectedValue !== 'undefined' ? selectedValue : this.get('selected.' + valuePath);
    }.property('selected', 'selectedValue', 'valuePath'),
    updateSelectedIndex: Ember.observer('s', 'valuePath', function () {
      let valuePath = this.valuePath;
      let prompt = this.prompt;
      let options = this.options || [];
      let selected = this.s;
      let optionsValues = options.map(obj => obj[valuePath]);
      let currentIndex = optionsValues.indexOf(selected); // set the proper selectedIndex of the element if the selection changes

      if (this.$('select').length > 0) this.$('select')[0].selectedIndex = prompt ? currentIndex - 1 : currentIndex;
    }).on('didInsertElement'),
    actions: {
      changed(event) {
        let valuePath = this.valuePath;
        let prompt = this.prompt;
        let options = this.options || [];
        let selectedValue = this.selectedValue; // get the selected item

        let selectedIndex = prompt ? event.target.selectedIndex - 1 : event.target.selectedIndex;
        let selectedItem = options ? options[selectedIndex] : null;
        if (selectedValue || selectedItem) selectedItem = selectedItem.value; // set the select to the previous value,
        // the action could change it to the one just selected if it wish to

        let previousValue = this.s;
        let optionsValues = options.map(obj => obj[valuePath]);
        let previousIndex = optionsValues.indexOf(previousValue);
        event.target.selectedIndex = prompt ? previousIndex - 1 : previousIndex;
        this.sendAction('onSelect', selectedItem);
      }

    }
  });

  _exports.default = _default;
});