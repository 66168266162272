define("backalley/pods/main/landing/new/route", ["exports", "backalley/models/landing", "backalley/pods/main/landing/new/selectOptions", "backalley/pods/main/landing/new/actions", "backalley/config/environment"], function (_exports, _landing, _selectOptions, _actions, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel() {
      this._super();

      if (!['development', 'test'].includes(_environment.default.environment)) {
        window.onbeforeunload = function () {
          return 'are you sure you want to exit the landing page builder?';
        };
      }
    },

    model({
      page_id
    }) {
      return _landing.default.findOneOrCreate(page_id).then(page => {
        return {
          imageFolder: 'landing',
          availableTypes: _selectOptions.availableTypes,
          availableColors: _selectOptions.availableColors,
          availableColorPickerOptions: _selectOptions.availableColorPickerOptions,
          rowTypes: _selectOptions.rowTypes,
          colTypes: _selectOptions.colTypes,
          selectedRowType: _selectOptions.rowTypes.get('firstObject'),
          selectedListLink: Ember.Object.create(),
          page,
          page_id,
          // Autocomplete
          searchResults: Ember.A(),
          productSearchResults: Ember.A()
        };
      });
    },

    // All exported actions from ./actions
    actions: _actions.default
  });

  _exports.default = _default;
});