define("backalley/pods/main/currencies/edit/route", ["exports", "backalley/models/currency"], function (_exports, _currency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    serialize(model) {
      return {
        currency_id: model._id
      };
    },

    model(params) {
      return _currency.default.findById(params.currency_id).then(currency => {
        if (!currency._id) {
          this.transitionTo('main.currencies');
        }

        return Ember.Object.create({
          currency,
          actions: {
            updateCurrency: updateCurrency.bind(this)
          }
        });
      });
    }

  });

  _exports.default = _default;

  function updateCurrency(currency) {
    return currency.save().then(() => this.transitionTo('main.currencies'), err => this.notify.show('err', err.message));
  }
});