define("backalley/util/sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sortCreatedAt = void 0;
  const sortCreatedAt = {
    latestFirst: (a, b) => {
      return new Date(a.created_at) > new Date(b.created_at) ? -1 : 1;
    },
    oldestFirst: (a, b) => {
      return new Date(a.created_at) > new Date(b.created_at) ? 1 : -1;
    }
  };
  _exports.sortCreatedAt = sortCreatedAt;
});