define("backalley/pods/main/curate/brand-tags/index/route", ["exports", "backalley/models/curate/brand-tag"], function (_exports, _brandTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MainCurateBrandTagsIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        },
        sort: {
          refreshModel: true
        },
        name: {
          refreshModel: true
        }
      });
    }

    model({
      page,
      limit,
      sort,
      name
    }) {
      const query = {
        page,
        limit,
        sort
      };

      if (name) {
        query.name = name;
      }

      if (sort) {
        query.sort = sort;
      }

      return _brandTag.default.find(query);
    }

  }

  _exports.default = MainCurateBrandTagsIndexRoute;
});