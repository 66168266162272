define("backalley/pods/components/order-edit/current-adjustment/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['box-view', 'box-manual-adjustment']
  });

  _exports.default = _default;
});