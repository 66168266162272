define("backalley/pods/components/order-edit/nav-controls/component", ["exports", "ember-keyboard", "backalley/pods/main/orders/options"], function (_exports, _emberKeyboard, _options) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function NOOP() {}

  var _default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    // Public API
    order: null,
    onSave: NOOP,
    onFinalizeOrder: NOOP,
    // Private API
    availableHandlingModes: _options.availableHandlingModes,
    tagName: '',
    session: Ember.inject.service(),
    orderStatusFailedPayment: Ember.computed.equal('order.status', 'fail payment'),
    orderTookPayment: Ember.computed.not('orderStatusFailedPayment'),

    init() {
      this._super();

      this.set('keyboardActivated', true);
    },

    manualModeShortcut: Ember.on((0, _emberKeyboard.keyUp)('alt+KeyM'), function () {
      const {
        manual_mode
      } = this.order;

      if (manual_mode !== true) {
        return this.setHandlingMode(true);
      }
    }),
    confirmOrderShortcut: Ember.on((0, _emberKeyboard.keyUp)('shift+Enter'), function () {
      return this.onFinalizeOrder();
    }),

    setHandlingMode(isManualMode) {
      Ember.setProperties(this.order, {
        manual_mode: isManualMode,
        assigned: this.session.user.email.replace(/@streethub.com|@trouva.com/gi, '')
      });
      return this.onSave();
    },

    actions: {
      setHandlingMode(isManualMode) {
        this.setHandlingMode(isManualMode);
      }

    }
  });

  _exports.default = _default;
});