define("backalley/pods/components/tab-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kn2MzJHk",
    "block": "{\"symbols\":[\"tab\",\"index\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tab-nav\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"tab-head clearfix\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"tabs\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[\"tab \",[28,\"if\",[[28,\"eq\",[[23,2,[]],[24,[\"currentIndex\"]]],null],\"tab--selected\"],null]]]],[8],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"button button-primary\"],[3,\"action\",[[23,0,[]],\"showTab\",[23,2,[]]]],[8],[0,\"\\n          \"],[1,[23,1,[]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"tab-body\"],[8],[0,\"\\n    \"],[14,3],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/tab-nav/template.hbs"
    }
  });

  _exports.default = _default;
});