define("backalley/pods/main/packaging/orders/index/route", ["exports", "backalley/models/packaging/order"], function (_exports, _order) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PackagingOrdersIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        },
        sort: {
          refreshModel: true
        },
        orderNumber: {
          refreshModel: true
        },
        boutique: {
          refreshModel: true
        }
      });
    }

    async model({
      page,
      limit,
      sort,
      orderNumber,
      boutique
    }) {
      const {
        orders,
        meta
      } = await _order.default.find({
        page,
        limit,
        sort,
        order_number: orderNumber,
        boutique
      });
      const queryParams = {
        page,
        limit,
        sort,
        orderNumber
      };
      return {
        orders,
        meta,
        queryParams
      };
    }

  }

  _exports.default = PackagingOrdersIndexRoute;
});