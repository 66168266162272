define("backalley/models/curate/order", ["exports", "moment", "papaparse", "tracked-built-ins", "backalley/util/price", "backalley/util/request", "backalley/enums/curate-order-status", "backalley/enums/curate-order-reservation-status", "backalley/models/common/money"], function (_exports, _moment, _papaparse, _trackedBuiltIns, _price, _request, _curateOrderStatus, _curateOrderReservationStatus, _money) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class3, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp2;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurateOrderReservation = (_dec = Ember._tracked, _dec2 = Ember._tracked, (_class = (_temp = class CurateOrderReservation {
    constructor({
      _id,
      product,
      stock_line,
      quantity,
      events = {},
      status = _curateOrderReservationStatus.default.CREATED
    }) {
      _initializerDefineProperty(this, "status", _descriptor, this);

      _initializerDefineProperty(this, "quantity", _descriptor2, this);

      this._id = _id;
      this.status = status;
      this.product = new _trackedBuiltIns.TrackedObject(product);
      this.stock_line = new _trackedBuiltIns.TrackedObject(stock_line);
      this.quantity = quantity;
      this.events = new _trackedBuiltIns.TrackedObject(events);
    }

    get totalPrice() {
      const {
        amount,
        currency
      } = this.stock_line.wholesale_price;
      return {
        amount: amount * this.quantity,
        currency
      };
    }

    get isNew() {
      return Ember.isEmpty(this._id);
    }

    get isCreated() {
      return this.status === _curateOrderReservationStatus.default.CREATED;
    }

    get isCancelled() {
      return this.status === _curateOrderReservationStatus.default.CANCELLED;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "status", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "quantity", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  let CurateOrder = (_dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class3 = (_temp2 = class CurateOrder {
    constructor({
      _id,
      status = _curateOrderStatus.default.CREATED,
      events = {},
      order_number,
      reservations,
      brands,
      total_price,
      created_at,
      created_by,
      boutique,
      delivery_address,
      contact_details,
      invoice_details,
      shipping_price,
      tax_price,
      discount_price
    }) {
      _initializerDefineProperty(this, "status", _descriptor3, this);

      _initializerDefineProperty(this, "events", _descriptor4, this);

      _initializerDefineProperty(this, "reservations", _descriptor5, this);

      _initializerDefineProperty(this, "total_price", _descriptor6, this);

      this._id = _id;
      this.status = status;
      this.events = events;
      this.order_number = order_number;
      this.boutique = boutique;
      this.brands = brands;
      this.reservations = reservations.map(reservation => new CurateOrderReservation(reservation));
      this.total_price = total_price;
      this.created_at = created_at;
      this.created_by = created_by;
      this.delivery_address = delivery_address;
      this.contact_details = contact_details;
      this.invoice_details = invoice_details;
      this.shipping_price = new _money.default(shipping_price || {
        amount: 0,
        currency: total_price.currency
      });
      this.discount_price = new _money.default(discount_price || {
        amount: 0,
        currency: total_price.currency
      });
      this.tax_price = new _money.default(tax_price || {
        amount: 0,
        currency: total_price.currency
      });
    }

    get allEvents() {
      return _objectSpread({
        [_curateOrderStatus.default.CREATED]: {
          at: this.created_at,
          by: this.created_by
        }
      }, this.events);
    }

    get brand() {
      return this.brands[0];
    }

    get formattedDeliveryAddress() {
      const {
        line_1,
        line_2
      } = this.delivery_address;
      return [line_1, line_2].join(', ');
    }

    get formattedInvoiceAddress() {
      const {
        address: {
          line_1,
          line_2
        }
      } = this.invoice_details;
      return [line_1, line_2].join(', ');
    }

    get isCreated() {
      return this.status === _curateOrderStatus.default.CREATED;
    }

    get isAcknowledged() {
      return this.status === _curateOrderStatus.default.ACKNOWLEDGED;
    }

    get isCancelled() {
      return this.status === _curateOrderStatus.default.CANCELLED;
    }

    getFormattedPrice(price) {
      return encodeURIComponent((0, _price.formatPrice)(price));
    }

    exportToCsv() {
      const total = this.getFormattedPrice(this.total_price);
      const csvLines = this.reservations.map((reservation, index) => {
        const productData = {
          'Product number': reservation.stock_line.sku,
          'Product name': reservation.product.name,
          'Product pack price (excl. VAT)': this.getFormattedPrice(reservation.stock_line.wholesale_price),
          'Pack quantity': reservation.quantity,
          'Total price (excl. VAT)': this.getFormattedPrice({
            amount: reservation.stock_line.wholesale_price.amount * reservation.quantity,
            currency: reservation.stock_line.wholesale_price.currency
          })
        };
        return index !== 0 ? productData : _objectSpread({
          'Order number': this.order_number,
          'Created at': (0, _moment.default)(this.created_at).format('ll'),
          Boutique: this.boutique.name,
          'Shipping address': this.formattedDeliveryAddress,
          'Invoice address': this.formattedInvoiceAddress,
          VAT: this.boutique.is_vat_registered ? this.boutique.vat_number : 'Not VAT registered',
          Brand: this.brand.name,
          Total: total
        }, productData);
      });

      const csv = _papaparse.default.unparse(csvLines, {
        quotes: true
      });

      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${csv}`;
      anchor.download = `curate-order-${this.order_number}`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }

    static find(params = {}) {
      const data = _objectSpread({
        populate: 'boutique,brands'
      }, params);

      return (0, _request.request)({
        url: (0, _request.curateApiURL)('orders'),
        data
      }).then(({
        orders: orders,
        meta
      }) => {
        return {
          orders: orders.map(order => new CurateOrder(order)),
          meta
        };
      }).catch(error => (0, _request.throwCurateError)(error));
    }

    static async findById(id) {
      try {
        const {
          orders: [order]
        } = await (0, _request.request)({
          url: (0, _request.curateApiURL)(`orders/${id}`)
        });
        return Ember.isPresent(order) ? new CurateOrder(order) : null;
      } catch (error) {
        (0, _request.throwCurateError)(error);
      }
    }

    async update(data = {}) {
      try {
        const {
          orders: [{
            status,
            events,
            reservations,
            total_price
          }]
        } = await (0, _request.request)({
          method: 'PUT',
          url: (0, _request.curateApiURL)(`orders/${this._id}`),
          data: JSON.stringify(_objectSpread({
            status: this.status
          }, data))
        });
        this.status = status;
        this.events = events;
        this.reservations = reservations.map(reservation => new CurateOrderReservation(reservation));
        this.total_price = total_price;
      } catch (error) {
        (0, _request.throwCurateError)(error);
      }
    }

    async updateReservation({
      _id: reservationId
    }, data = {}) {
      try {
        const {
          orders: [{
            reservations
          }]
        } = await (0, _request.request)({
          method: 'PUT',
          url: (0, _request.curateApiURL)(`orders/${this._id}`),
          data: JSON.stringify({
            reservations: [_objectSpread({
              _id: reservationId
            }, data)]
          })
        });
        this.reservations = reservations.map(reservation => new CurateOrderReservation(reservation));
      } catch (error) {
        (0, _request.throwCurateError)(error);
      }
    }

    async addReservation(data = {}) {
      try {
        const {
          orders: [{
            reservations
          }]
        } = await (0, _request.request)({
          method: 'PUT',
          url: (0, _request.curateApiURL)(`orders/${this._id}`),
          data: JSON.stringify({
            reservations: [_objectSpread({}, data)]
          })
        });
        this.reservations = reservations.map(reservation => new CurateOrderReservation(reservation));
      } catch (error) {
        (0, _request.throwCurateError)(error);
      }
    }

  }, _temp2), (_descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "status", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "events", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "reservations", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "total_price", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "exportToCsv", [_dec7], Object.getOwnPropertyDescriptor(_class3.prototype, "exportToCsv"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "updateReservation", [_dec8], Object.getOwnPropertyDescriptor(_class3.prototype, "updateReservation"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "addReservation", [_dec9], Object.getOwnPropertyDescriptor(_class3.prototype, "addReservation"), _class3.prototype)), _class3));
  _exports.default = CurateOrder;
});