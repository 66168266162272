define("backalley/services/country", ["exports", "backalley/models/country", "backalley/models/zone"], function (_exports, _country, _zone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    countries: null,
    zones: null,

    init() {
      this._super(...arguments);

      this.set('countries', []);
    },

    uk: Ember.computed('countries.@each._id', function () {
      const countries = this.countries;
      return countries.find(country => Ember.get(country, 'iso_code').toLowerCase() === 'gb');
    }),

    getCountries() {
      return _country.default.find().then(countries => {
        this.set('countries', countries);
        return countries;
      });
    },

    getZones() {
      return _zone.default.find().then(zones => {
        this.set('zones', zones);
        return zones;
      });
    },

    findCountryByProperty(property, value) {
      const countries = this.countries;
      if (!value) return undefined;
      return countries.find(curr => Ember.getWithDefault(curr, property, '').toLowerCase().indexOf(value.toLowerCase()) === 0);
    },

    findCountryByName(name) {
      return this.findCountryByProperty('name', name);
    },

    findCountryByIsoCode(iso_code) {
      return this.findCountryByProperty('iso_code', iso_code);
    }

  });

  _exports.default = _default;
});