define("backalley/pods/components/basic-notes/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZZHJ4C84",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-notes-add\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"Add a note\"],[9],[0,\"\\n\\n  \"],[1,[28,\"textarea\",null,[[\"placeholder\",\"class\",\"value\"],[\"Type here\",\"input\",[24,[\"comment\",\"text\"]]]]],false],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"button save-button\"],[3,\"action\",[[23,0,[]],\"save\",[24,[\"comment\"]]]],[8],[0,\"Save\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/basic-notes/new/template.hbs"
    }
  });

  _exports.default = _default;
});