define("backalley/pods/components/team-notes/component", ["exports", "backalley/mixins/request", "backalley/models/team-note"], function (_exports, _request, _teamNote) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_request.default, {
    // Public API
    label: 'How does this work?',
    // Label for UI
    filePath: null,
    // S3 FilePath, we create the path like so: `/explanations/{filePath}/content.json`
    userEmail: null,
    // Email of the current user
    reveal: false,
    // Initiate expanded
    editMode: false,
    // Initiate in edit mode by default
    readOnly: false,
    // Hide edit functionality
    // Private API
    content: '',
    loading: false,
    last_updated: '',
    email: '',

    init() {
      this._super(...arguments);

      if (!this.filePath || !this.userEmail) {
        this.logger.error(`Please provide a filePath and a userEmail for <TeamNotes> component with label "${this.label}"`);
      }

      this._fetchContent();
    },

    isEmpty: Ember.computed.empty('content'),
    isEmptyAndNotEditMode: Ember.computed('isEmpty', 'editMode', function () {
      return this.isEmpty && !this.editMode;
    }),

    _fetchContent() {
      return this._findPage().then(({
        content = '',
        email = '',
        last_updated
      }) => {
        Ember.trySet(this, 'content', content);
        Ember.trySet(this, 'email', email);
        Ember.trySet(this, 'last_updated', last_updated);
      });
    },

    _findPage() {
      this.set('loading', true);
      return _teamNote.default.find(this.filePath).then(res => {
        if (res.err) {
          return this._createPage();
        }

        const {
          last_updated = '',
          email = '',
          text = ''
        } = res.static;
        return {
          content: text,
          email,
          last_updated
        };
      }).catch(() => ({})).finally(() => Ember.trySet(this, 'loading', false));
    },

    _createPage() {
      return _teamNote.default.save(this.filePath, {
        email: this.userEmail,
        text: ''
      });
    },

    _savePage() {
      const data = {
        last_updated: new Date(),
        email: this.userEmail,
        text: this.content
      };
      this.set('loading', true);
      return _teamNote.default.save(this.filePath, data).then(() => this._fetchContent()).catch(() => alert('Something is broken! Bug report this ;D')).finally(() => {
        Ember.trySet(this, 'loading', false);
        Ember.trySet(this, 'editMode', false);
      });
    },

    actions: {
      toggleEditMode() {
        if (this.readOnly) {
          return;
        }

        this._fetchContent().then(() => this.toggleProperty('editMode'));
      },

      toggleReveal() {
        if (this.reveal) {
          return this.toggleProperty('reveal');
        }

        this._fetchContent().then(() => this.toggleProperty('reveal'));
      },

      savePage() {
        return this._savePage();
      }

    }
  });

  _exports.default = _default;
});