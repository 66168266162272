define("backalley/pods/main/colors/index/route", ["exports", "backalley/models/color", "backalley/util/escape-regex"], function (_exports, _color, _escapeRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MainColorsIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        },
        sort: {
          refreshModel: true
        },
        query: {
          refreshModel: true
        }
      });
    }

    model({
      query,
      page,
      limit,
      sort
    }) {
      const queryParams = {
        page,
        limit,
        sort
      };

      if (Ember.isPresent(query)) {
        const isSearchByHexCode = query.startsWith("#");
        const parameter = {
          $regex: `^${(0, _escapeRegex.default)(query)}`,
          $options: 'i'
        };
        Object.assign(queryParams, isSearchByHexCode ? {
          value: parameter
        } : {
          label: parameter
        });
      }

      return _color.default.findWithMeta(queryParams);
    }

  }

  _exports.default = MainColorsIndexRoute;
});