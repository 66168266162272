define("backalley/models/shipping-group-configuration", ["exports", "joi", "backalley/util/diff", "backalley/util/models", "backalley/util/request"], function (_exports, _joi, _diff, _models, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ShippingGroupConfigurationCollection = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const PGAS = 'path_groups_and_settings';
  /**
   * This is a wrapper for ShippingGroupConfiguration. Essentially a
   * multi-model helper. Any logic that should operate on many SGCs at
   * once should be added here.
   * @class
   * @classdesc Should be used as a wrapper to contain many
   *   ShippingGroupConfigurations
   */

  class ShippingGroupConfigurationCollection {
    static async request(options) {
      return (0, _request.request)(options);
    }

    constructor(list) {
      _defineProperty(this, "list", []);

      this.list = list.map(ShippingGroupConfiguration.promote);
    }
    /**
     * The only way currently an SGC can be "in use" or "released" is
     * if it is attached to a delivery modifier. We will move towards a
     * more permanent way of detecting "has_been_released" but in
     * iteration.
     */


    async calculateHasBeenReleased() {
      if (!this.list || !this.list.length) {
        return;
      }

      const response = await this.constructor.request({
        method: 'GET',
        url: (0, _request.apiURL)('delivery_modifiers'),
        data: {
          type: 'weight_bracket',
          shipping_group_configuration: this.list.filter(sgc => sgc._id).map(sgc => sgc._id)
        }
      });
      const {
        delivery_modifiers: deliveryModifiers
      } = response || {};
      this.list.forEach(sgc => {
        if (!sgc || !sgc._id) {
          sgc.has_been_released = false;
          return;
        }

        sgc.has_been_released = !!deliveryModifiers.find(dm => {
          if (!dm || !dm.shipping_group_configuration) {
            return false;
          }

          const a = dm.shipping_group_configuration.toString();

          const b = sgc._id.toString();

          return a === b;
        });
      });
    }

  }
  /**
   * @class
   * @classdesc Creates an instance of ShippingGroupConfiguration.
   *   A structure that groups Paths together, and also allows for
   *   specific configuration to be attached to those groups.
   */


  _exports.ShippingGroupConfigurationCollection = ShippingGroupConfigurationCollection;

  class ShippingGroupConfiguration {
    constructor(rawShippingGroupConfiguration) {
      this.constructor.mapPropsToTarget(rawShippingGroupConfiguration, this);
    }

    static promote(uncertainSgc) {
      if (uncertainSgc instanceof ShippingGroupConfiguration) {
        return uncertainSgc;
      }

      return new ShippingGroupConfiguration(uncertainSgc);
    }

    static getApiURL({
      _id
    } = {}) {
      const key = 'shipping_group_configurations';

      if (_id) {
        return (0, _request.apiURL)(`${key}/${_id}`);
      }

      return (0, _request.apiURL)(key);
    }

    static mapModifierValueDefaultBasedOnType({
      modifierValueType,
      modifierValueDefault
    }) {
      if (modifierValueType === 'shippable') {
        if (modifierValueDefault === true) {
          return '1';
        }

        if (modifierValueDefault === false) {
          return '0';
        }

        return '';
      }

      return (modifierValueDefault || '').toString();
    }

    static mapPropsToTarget(sgc, target) {
      const {
        _id = undefined,
        name = '',
        created_at: createdAt,
        path_groups_and_settings: pgasList = [],
        modifier_value_type: modifierValueType = 'multiplier',
        modifier_value_default: modifierValueDefault = ''
      } = sgc || {};
      target._id = _id;
      target.name = name;
      target.path_groups_and_settings = JSON.parse(JSON.stringify(pgasList));
      target.modifier_value_type = modifierValueType;
      target.modifier_value_default = this.mapModifierValueDefaultBasedOnType({
        modifierValueType,
        modifierValueDefault
      });

      if (createdAt) {
        target.created_at = createdAt;
      }

      return target;
    }

    static async request(options) {
      return (0, _request.request)(options);
    }

    static async create(params = {}) {
      const raw = this.mapPropsToTarget(params, {});
      await (0, _models.validateSchema)(this.schema, raw);
      const response = await this.request({
        method: 'POST',
        url: this.getApiURL(),
        data: JSON.stringify(raw)
      });
      const {
        shipping_group_configurations: [sgc]
      } = response || {};

      if (!Ember.isPresent(sgc)) {
        return null;
      }

      const instance = new ShippingGroupConfiguration(sgc);
      await instance.calculateHasBeenReleased();
      return instance;
    }

    static async findWithMeta(params = {}) {
      const response = await this.request({
        method: 'GET',
        url: this.getApiURL(),
        data: params
      });
      const {
        shipping_group_configurations: sgcList = [],
        meta
      } = response || {};
      const sgcCollection = new ShippingGroupConfigurationCollection(sgcList);
      await sgcCollection.calculateHasBeenReleased();
      return {
        shipping_group_configurations: sgcCollection.list,
        meta
      };
    }

    static async find(params = {}) {
      const response = await this.findWithMeta(params);
      const {
        shipping_group_configurations: sgcList
      } = response;
      return sgcList;
    }

    static async findById(_id) {
      const response = await this.request({
        method: 'GET',
        url: this.getApiURL({
          _id
        })
      });
      const {
        shipping_group_configurations: [sgc] = []
      } = response || {};

      if (!Ember.isPresent(sgc)) {
        return null;
      }

      const instance = new ShippingGroupConfiguration(sgc);
      await instance.calculateHasBeenReleased();
      return instance;
    }

    async calculateHasBeenReleased() {
      if (!this._id) {
        this.has_been_released = false;
        return false;
      } // because SGCCs promote the passed in SGC (which will preserve
      // references), the has_been_released field is mutated, this is
      // by design; and means we are affecting this.has_been_released


      const tempCollection = new ShippingGroupConfigurationCollection([this]);
      await tempCollection.calculateHasBeenReleased();
      return this.has_been_released;
    }

    async remove() {
      if (!this._id) {
        throw new Error('Unable to delete, cannot locate id');
      }

      return (0, _request.request)({
        method: 'DELETE',
        url: this.constructor.getApiURL({
          _id: this._id
        })
      });
    }

    async update() {
      await this.validate();
      const response = await (0, _request.request)({
        method: 'PUT',
        url: this.constructor.getApiURL({
          _id: this._id
        }),
        data: this.getAsJSON()
      });
      let {
        shipping_group_configurations: sgcList
      } = response;
      const sgcCollection = new ShippingGroupConfigurationCollection(sgcList);
      await sgcCollection.calculateHasBeenReleased();
      const [sgc] = sgcCollection.list;

      if (!Ember.isPresent(sgc)) {
        return null;
      }

      return sgc;
    }

    save() {
      if (this._id) {
        return this.update();
      }

      return this.create();
    }

    copyFrom(otherSgc) {
      return this.constructor.mapPropsToTarget(otherSgc, this);
    }

    getAsRaw() {
      return this.constructor.mapPropsToTarget(this, {});
    }

    getAsJSON() {
      return JSON.stringify(this.getAsRaw());
    }

    validate() {
      return (0, _models.validateSchema)(this.constructor.schema, this.getAsRaw());
    }

    compareOverviewTo(b, differences = []) {
      const a = this.getAsRaw();

      const toIdOrName = item => item._id || item.name;

      const aa = _objectSpread(_objectSpread({}, a), {}, {
        [PGAS]: a[PGAS].map(toIdOrName)
      });

      const bb = _objectSpread(_objectSpread({}, b), {}, {
        [PGAS]: b[PGAS].map(toIdOrName)
      });

      const changes = (0, _diff.generateDiffAsKeysAndValues)(bb, aa);
      differences.push(...changes);
      return changes;
    }

    comparePathGroupsAndSettingsTo(b, differences = []) {
      const a = this.getAsRaw();

      const byIdOrName = item => item._id || item.name;

      const aa = a[PGAS].sort(byIdOrName);
      const bb = b[PGAS].sort(byIdOrName);
      const changes = (0, _diff.generateDiffAsKeysAndValues)(bb, aa, PGAS);
      differences.push(...changes);
      return changes;
    }
    /**
     * Compare differences between two SGCs using json-diff
     */


    compareTo(b) {
      const bb = b && b.getAsRaw ? b.getAsRaw() : b;
      let differences = [];
      this.compareOverviewTo(bb, differences);
      this.comparePathGroupsAndSettingsTo(bb, differences);
      differences = (0, _diff.mergeComparisonDiffs)(differences);
      const hasNoDifferences = !differences.length;
      return {
        hasDifferences: !hasNoDifferences,
        hasNoDifferences,
        differences
      };
    }

  }

  _exports.default = ShippingGroupConfiguration;

  _defineProperty(ShippingGroupConfiguration, "schema", _joi.default.object().keys({
    name: _joi.default.string().min(3).required(),
    created_at: _joi.default.date().optional(),
    path_groups_and_settings: _joi.default.array().items(_joi.default.object().keys({
      modifier_value: _joi.default.string().min(1).required(),
      path_group: _joi.default.object().keys({
        name: _joi.default.string().min(3).required(),
        shipping_path_codes: _joi.default.array().items(_joi.default.string().min(7).required())
      })
    })),
    modifier_value_type: _joi.default.string().valid('multiplier').required(),
    modifier_value_default: _joi.default.string().optional().allow('')
  }));
});