define("backalley/pods/main/zones/new/route", ["exports", "backalley/models/zone", "backalley/models/country"], function (_exports, _zone, _country) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return _country.default.findWithMeta().then(({
        countries
      }) => {
        return {
          zone: _zone.default.create({
            countries: []
          }),
          countries
        };
      });
    },

    actions: {
      create(zone) {
        return zone.post().then(() => this.transitionTo('main.zones'), err => this.notify.show('err', err.message));
      }

    }
  });

  _exports.default = _default;
});