define("backalley/pods/components/date-handler/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CW9nW3xa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"_dateExists\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[8],[0,\" \"],[1,[28,\"moment-format\",[[24,[\"dateToFormat\"]],[24,[\"momentFormatString\"]]],null],false],[0,\" \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"boolean boolean-no\"],[8],[1,[22,\"emptyLabel\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/date-handler/template.hbs"
    }
  });

  _exports.default = _default;
});