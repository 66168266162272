define("backalley/pods/components/order-edit/order-events/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const relevantEvents = {
    'order:create:activate': 'Order Activated',
    'order:create:create_transactions': 'Transactions Created',
    'order:create:create_reservations_transactions': 'Reservation transactions created',
    'order:create:send_notifications:customer': 'Sent customer notification',
    'order:create:send_notifications:retail': 'Sent Boutique notification'
  };

  var _default = Ember.Component.extend({
    order: null,
    eventsFired: null,
    classNames: ['box-view', 'box-view-events'],
    eventsStatus: Ember.computed('order.events_fired', function () {
      const orderEvents = this.order.events_fired || [];
      return this.eventsFired.filter(eventFired => Object.keys(relevantEvents).includes(eventFired.event)).map(({
        event
      }) => ({
        name: relevantEvents[event],
        completed: !!orderEvents.find(orderEvent => orderEvent.name === event && orderEvent.status === 'completed')
      }));
    })
  });

  _exports.default = _default;
});