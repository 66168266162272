define("backalley/pods/components/box-pair-editable/component", ["exports", "@streethub/calcs"], function (_exports, _calcs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public attributes
    type: 'text',
    key: '',
    value: '',
    // divide the input price before showing and price back when setting (Boolean)
    price: false,
    // show '£' for example
    valuePrepend: '',
    // show '%' for example
    valueAppend: '',
    editing: false,

    save() {},

    // Internal attributes
    _initialValue: null,
    _val: Ember.computed('value', function () {
      if (this.price && this.value !== '') return this.value / 100;
      return this.value;
    }),

    didReceiveAttrs() {
      this._super(...arguments); // when component gets new values (initial render, update from the model, etc.)
      // save the value as _initialValue
      // but not when we are just rerendering because of typing


      if (!this.editing) this.set('_initialValue', this.value);
    },

    resetInitialValue() {
      Ember.run.next(this, () => this.set('editing', false), 0);
      this.sendAction('valueChanged', this._initialValue);
    },

    actions: {
      edit(key) {
        this.sendAction('edit', key);
      },

      change(val) {
        // price values should be stored in pences
        if (this.price && val.target.value !== '') {
          let inPences = _calcs.default.inPences({
            val: _calcs.default.math.eval(`${val.target.value} * 100`)
          }).val;

          return this.sendAction('valueChanged', inPences);
        }

        if (this.number && val.target.value !== '') {
          return this.sendAction('valueChanged', Number(val.target.value));
        } // empty fields has to mean `null` to be able to clear set prices in the db


        if (this.price && val.target.value === '') return this.sendAction('valueChanged', null); // update the model

        this.sendAction('valueChanged', val.target.value);
      },

      save() {
        this.set('editing', false);
        let res = this.attrs.save();
        if (res && res.then) res.then(() => {
          // reset initialValue to new data!!!
          this.set('_initialValue', this.value);
        }).catch(() => {
          this.resetInitialValue();
        });
      },

      cancel() {
        this.resetInitialValue();
      }

    }
  });

  _exports.default = _default;
});