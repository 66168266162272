define("backalley/pods/components/order-edit/payment/component", ["exports", "backalley/enums/cko-payment-channel"], function (_exports, _ckoPaymentChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    order: null,
    classNames: ['box-view', 'box-view-payment'],

    didReceiveAttrs() {
      switch (this.order.payment_channel) {
        case 'braintree':
          this.setProperties({
            paymentChannel: 'Braintree',
            transactionUrl: this.order.braintreeTransactionURL,
            transactionId: this.order.braintree_transaction_id
          });
          break;

        case _ckoPaymentChannel.default.OLD_KLARNA:
        case _ckoPaymentChannel.default.GOOGLE_PAY:
        case _ckoPaymentChannel.default.APPLE_PAY:
        case _ckoPaymentChannel.default.CREDITCARD:
        case _ckoPaymentChannel.default.KLARNA:
        case _ckoPaymentChannel.default.PAYPAL:
          this.setProperties({
            paymentChannel: 'Checkout.com',
            transactionUrl: this.order.ckoRefundUrl,
            transactionId: this.order.payment_transaction_id,
            paymentType: this.order.payment_channel
          });
          break;

        default:
          this.setProperties({
            paymentChannel: 'N/A',
            transactionUrl: undefined,
            transactionId: 'N/A'
          });
          break;
      }
    }

  });

  _exports.default = _default;
});