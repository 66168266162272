define("backalley/pods/components/landing-layout/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['landing-layout'],
    view: 'desktop',
    actions: {
      changeView(view) {
        this.set('view', view);
      }

    } // base:{
    //     entries: Ember.A().addObjects(
    //         [
    //         Ember.Object.create({ width: 1440, height: 720, placeholder: 'https://placehold.it/1440x720'}),
    //         Ember.Object.create({ width: 480, height: 480, placeholder: 'https://placehold.it/480x480'}),
    //         Ember.Object.create({ width: 480, height: 480, placeholder: 'https://placehold.it/480x480'}),
    //         Ember.Object.create({ width: 480, height: 480, placeholder: 'https://placehold.it/480x480'}),
    //         Ember.Object.create({ width: 960, height: 480, placeholder: 'https://placehold.it/960x480'}),
    //         Ember.Object.create({ width: 480, height: 480, placeholder: 'https://placehold.it/480x480'})]
    //     )
    // },
    // activeEntry: Ember.computed('pageEntries,pageEntries.[]',{
    //     get(){
    //         let index = this.get('selectedEntryIndex');
    //         let pageEntry = this.get('pageEntries')[index];
    //         let baseEntry = this.get('base.entries')[index];
    //
    //         return pageEntry || baseEntry;
    //     }
    // }),
    // updateBase: function(){
    //     let entries = this.get('base.entries');
    //     let pageEntries = this.get('pageEntries');
    //
    //     // Reset: Set as undefined / don't delete key - Ember doesn't seem
    //     // to update the template when an if is used and the key is deleted.
    //     // Set as undefined to overcome that
    //     entries.forEach( (entry) => {
    //         entry.set('desktop', undefined);
    //         entry.set('mobile', undefined);
    //     });
    //
    //     pageEntries.forEach((entry, index) => {
    //         entries[index].set('desktop', entry.desktop);
    //         entries[index].set('mobile', entry.mobile);
    //     });
    //
    //
    // }.observes('pageEntries,pageEntries.[]'),
    // setIndex: function(){
    //     let entries = this.get('base.entries');
    //     entries.forEach(entry => entry.set('active', false));
    //     entries[this.get('selectedEntryIndex')].set('active',true);
    // }.observes('selectedEntryIndex')

  });

  _exports.default = _default;
});