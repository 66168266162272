define("backalley/pods/components/frame-form/component", ["exports", "ember-component-css/pod-names"], function (_exports, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FrameFormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = (_temp = class FrameFormComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "notify", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);
    }

    get styleNamespace() {
      return _podNames.default['frame-form'];
    }

    onUpdateContentType(item, contentType) {
      Ember.set(item, 'content_type', contentType);
      Ember.set(item, 'url', '');
      Ember.set(item, 'product_id', '');
    }

    onUpdateProduct(item, product) {
      Ember.set(item, 'product_id', product._id);
    }

    onUpdateLifestyle(item, url) {
      Ember.set(item, 'url', url);
    }

    onUpdateBadge(item, badge) {
      Ember.set(item, 'badge', badge);
    }

    onUpdateTags(frame, standardTags) {
      Ember.set(frame, 'standard_tags', standardTags);
    }

    onUpdateMusic(frame, music) {
      Ember.set(frame, 'music', music);
    }

    async onSave() {
      const saveOrCreateRes = await this.saveOrCreate();

      if (!saveOrCreateRes.ok) {
        return;
      }

      return this.router.transitionTo('main.frames.index');
    }

    onCancel() {
      return this.router.transitionTo('main.frames.index');
    }

    async saveOrCreate() {
      try {
        await this.frame.createOrUpdate();
      } catch (error) {
        this.notify.show('error', 'Oops! An error ocurred while saving your frame. Please try again.');
        return {
          ok: false
        };
      }

      return {
        ok: true
      };
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onUpdateContentType", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdateContentType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdateProduct", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdateProduct"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdateLifestyle", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdateLifestyle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdateBadge", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdateBadge"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdateTags", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdateTags"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdateMusic", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdateMusic"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSave", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onSave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCancel", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onCancel"), _class.prototype)), _class));
  _exports.default = FrameFormComponent;
});