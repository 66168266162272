define("backalley/pods/main/info/new/route", ["exports", "backalley/models/info", "backalley/enums/language-type"], function (_exports, _info, _languageType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    infoPages: null,
    selectedPageIndex: null,

    model(params) {
      return _info.default.find().then(res => {
        this.set('infoPages', res.static); // Setup the tab for editing if tab_id exists

        params.page_id && params.page_id !== '' ? this.editPage(+params.page_id) : this.setDefaults();
        return {
          page: this.page,
          loading: false
        };
      });
    },

    /**
     * Selected page entries
     */
    pageEntries: Ember.A(),

    /**
     * Setup tab edit mode
     * @param  {Number} index Index of tab to be edited
     */
    editPage(index) {
      let page = this.get('infoPages.pages')[index];

      let content = _languageType.default.toValues().reduce((content, language) => {
        let contentLabel = `content_${language}`;
        let defaultContent = language === _languageType.default.ENGLISH ? page.content : '';
        content[contentLabel] = page[contentLabel] || defaultContent;
        return content;
      }, {});

      this.set('page', Ember.Object.create(_objectSpread({
        name: page.name,
        id: page.id,
        content: page.content
      }, content)));
      this.set('selectedPageIndex', index);
    },

    /**
     * Sets default values for page
     */
    setDefaults() {
      let content = _languageType.default.toValues().reduce((content, language) => {
        content[`content_${language}`] = '';
        return content;
      }, {});

      this.set('page', Ember.Object.create(_objectSpread({
        name: '',
        id: '',
        content: ''
      }, content)));
      this.set('selectedPageIndex', null);
    },

    actions: {
      /**
       * Save the new tab
       */
      savePage() {
        let info = this.infoPages;
        let page = this.get('currentModel.page');
        let pageIndex = this.selectedPageIndex; // Temporary validation

        if (!page.name || page.name === '' || !page.id || page.id === '') {
          alert('This page needs a name & an id yo.');
          return;
        } // Edit or create this is the question


        if (pageIndex !== null && pageIndex >= 0) {
          // If we're editing a page replace
          info.pages[pageIndex] = page;
        } else {
          info.pages.push(page); // Else push in the new page
        } // Sanitizing (removing all the stupid ember stuff from the object)


        let sanitized = JSON.parse(JSON.stringify(info));
        this.set('currentModel.loading', true);

        _info.default.save(sanitized).then(() => {
          this.set('currentModel.loading', false);
          this.transitionTo('main.info');
        }).catch(() => {
          this.set('currentModel.loading', false);
          alert('Error saving info page changes! Contact the tech on Slack #tech-questions');
        });
      },

      /**
       * Removes an info page
       */
      removePage() {
        let selectedPageIndex = this.selectedPageIndex; // If there's no index, there's nothing to delete

        if (!selectedPageIndex && selectedPageIndex !== 0) {
          return alert('No page selected!');
        }

        let remove = confirm('Are you sure you want to remove this landing page? (think of SEO yo.)'); // If we got confirmation

        if (remove) {
          let info = this.infoPages;
          info.pages.splice(selectedPageIndex, 1); // Sanitizing (removing all the stupid ember stuff from the object)

          let sanitized = JSON.parse(JSON.stringify(info));
          this.set('currentModel.loading', true);

          _info.default.save(sanitized).then(() => {
            this.set('currentModel.loading', false);
            this.transitionTo('main.info');
          }).catch(() => {
            this.set('currentModel.loading', false);
            alert('Error saving info page changes! Contact the tech on Slack #tech-questions');
          });
        }
      }

    }
  });

  _exports.default = _default;
});