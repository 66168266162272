define("backalley/pods/components/basic-notes/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eMQpvYaX",
    "block": "{\"symbols\":[\"comment\"],\"statements\":[[4,\"each\",[[24,[\"comments\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"form-notes-note\"],[8],[0,\"\\n    \"],[7,\"span\",true],[8],[7,\"span\",true],[10,\"class\",\"note-author\"],[8],[1,[23,1,[\"updated_by\"]],false],[9],[0,\" \"],[1,[28,\"moment-format\",[[23,1,[\"updated_at\"]],\"DD MMM YYYY h:mm a\"],null],false],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[23,1,[\"text\"]],true],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/basic-notes/list/template.hbs"
    }
  });

  _exports.default = _default;
});