define("backalley/enums/boutique-feed-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    SHOPIFY: 'Shopify',
    PRESTASHOP: 'PrestaShop',
    WOOCOMMERCE: 'WooCommerce',
    GOOGLESHEETS: 'Google Sheets',
    XML: 'XML',
    CSV_TXT: 'CSV/TXT',
    BIGCOMMERCE: 'BigCommerce'
  };
  _exports.default = _default;
});