define("backalley/pods/components/product-amendment-card/simple/component", ["exports", "backalley/pods/components/product-amendment-card/common_mixin", "ember-changeset", "backalley/util/default-to"], function (_exports, _common_mixin, _emberChangeset, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_common_mixin.default, {
    amendment: (0, _defaultTo.default)(null),
    amendmentChangeset: Ember.computed('amendment', function () {
      return new _emberChangeset.default(this.amendment);
    })
  });

  _exports.default = _default;
});