define("backalley/pods/components/form-price/component", ["exports", "@streethub/calcs"], function (_exports, _calcs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currency: '£',
    spacing: '',
    disabled: false,
    // _value is the pound representation
    // of the value being edited.
    _value: 0,

    init() {
      this._super();

      this.set('_value', this.value / 100 || 0);
    },

    value: Ember.computed('_value', {
      get() {
        let val = parseFloat(this._value, 10);
        if (isNaN(val)) val = 0;

        let value = _calcs.default.inPences({
          val: _calcs.default.math.eval(`${val}` * 100)
        }).val;

        return value;
      },

      set(_, val) {
        return val;
      }

    }),
    label: Ember.computed('value', 'currency', {
      get() {
        let spacing = this.spacing;
        let currency = this.currency;
        let value = this._value;
        let prefix = value < 0 ? '-' : '';
        value = value < 0 ? -value : value;
        value = parseFloat(value).toFixed(2);
        return `${prefix}${currency}${spacing}${value}`;
      },

      set(_, val) {
        return val;
      }

    }),
    actions: {
      change(v) {
        this.set('_value', v);
        let val = parseFloat(this._value, 10);
        if (isNaN(val)) val = 0;

        let value = _calcs.default.inPences({
          val: _calcs.default.math.eval(`${val}` * 100)
        }).val; // Convert to pences


        if (this.action) this.sendAction('action', value, this.label);
      }

    }
  });

  _exports.default = _default;
});