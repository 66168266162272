define("backalley/pods/components/manual-adjustment-edit/component", ["exports", "backalley/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['manual-adjustment-edit'],
    notify: Ember.inject.service(),
    zendeskTicketId: '',
    adjustment: null,
    boutiqueName: null,
    order: null,
    productName: null,
    productId: null,

    onComplete() {},

    dateFormat: 'YYYY-MM-DD',
    isCreate: Ember.computed.empty('adjustment._id'),
    // If enabled it hides certain fields
    // that Ops agents shouldn't be filling out
    restrictOps: false,
    disabled: Ember.computed.alias('adjustment.approved'),
    zendeskTicketIds: Ember.computed.alias('adjustment.zendesk_ticket_ids'),
    isEmptyZendeskTicketId: Ember.computed.empty('zendeskTicketId'),
    filterTransactions: Ember.computed('adjustment', function () {
      return {
        sub_entity_type: 'manual_adjustment',
        sub_entity_id: this.adjustment._id
      };
    }),
    formatedDate: Ember.computed('adjustment.date', function () {
      return (0, _moment.default)(this.adjustment.date).format(this.dateFormat);
    }),
    adjustmentsAPIEndpoint: Ember.computed(function () {
      return `${_environment.default.apiURL}/v2/manual_adjustments/?_id=`;
    }),
    transactionsAPIEndpoint: Ember.computed(function () {
      return `${_environment.default.apiURL}/v2/transactions/?_id=`;
    }),

    didInsertElement() {
      this.element.scrollTo(0, 0);
    },

    actions: {
      async createAdjustment() {
        try {
          const adjustment = await this.adjustment.post();
          this.onComplete(adjustment);
        } catch (error) {
          this.notify.show('warning', error.responseJSON.errors[0].detail, 5000);
        }
      },

      async saveAdjustment() {
        try {
          await this.adjustment.update();
          this.onComplete();
        } catch (error) {
          this.notify.show('warning', error.responseJSON.errors[0].detail, 5000);
        }
      },

      addTicket() {
        this.zendeskTicketIds.addObject(this.zendeskTicketId);
        this.set('zendeskTicketId', '');
      },

      setDate({
        target: {
          value
        }
      }) {
        this.adjustment.setProperties({
          date: (0, _moment.default)(value, this.dateFormat).toDate()
        });
      },

      updatePrice(amount) {
        /**
         * Update both properties: one to send on updates and the other one
         * to be used to pass the updated data down
         */
        this.set('adjustment.request_amount', amount);
        this.set('adjustment.converted_amount', amount);
      },

      removeFromZendeskTickets(ticket) {
        this.zendeskTicketIds.removeObject(ticket);
      }

    }
  });

  _exports.default = _default;
});