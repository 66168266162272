define("backalley/helpers/language-from-locale", ["exports", "backalley/pods/main/campaigns/locales"], function (_exports, _locales) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLanguageFromLocale = getLanguageFromLocale;
  _exports.default = void 0;

  function getLanguageFromLocale([locale]) {
    const language = _locales.languages.find(language => language.locale === locale);

    return language ? language.label : null;
  }

  var _default = Ember.Helper.helper(getLanguageFromLocale);

  _exports.default = _default;
});