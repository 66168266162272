define("backalley/pods/components/landing-page/legacy-partials/image-upload/component", ["exports", "backalley/util/default-to"], function (_exports, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    item: (0, _defaultTo.default)(null),
    imageFolder: (0, _defaultTo.default)(null),
    actions: {
      onUploadDesktopImage(image) {
        const item = this.item;
        Ember.set(item, 'desktop', image.url);
      },

      onUploadMobileImage(image) {
        const item = this.item;
        Ember.set(item, 'mobile', image.url);
      },

      onRemoveDesktopImage() {
        const item = this.item;
        Ember.set(item, 'desktop', '');
      },

      onRemoveMobileImage() {
        const item = this.item;
        Ember.set(item, 'mobile', '');
      }

    }
  });

  _exports.default = _default;
});