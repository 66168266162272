define("backalley/pods/components/price-with-currency/component", ["exports", "ember-concurrency", "backalley/models/currency"], function (_exports, _emberConcurrency, _currency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['price-with-currency'],
    price: null,
    disabled: false,
    currencyOptions: null,

    onChange() {},

    currency: Ember.computed.alias('price.currency'),
    amount: Ember.computed.alias('price.amount'),
    amountInPences: Ember.computed('amount', function () {
      return Number(this.amount) / 100;
    }),

    init() {
      this._super(...arguments);

      if (this.price !== null) {
        this.fetchCurrenciesTask.perform();
      }
    },

    fetchCurrenciesTask: (0, _emberConcurrency.task)(function* () {
      this.set('currencyOptions', yield _currency.default.find({}));
    }).restartable(),
    actions: {
      selectCurrency({
        iso_4217
      }) {
        const data = {
          currency: iso_4217,
          amount: Number(this.amount)
        };
        this.onChange(data);
      },

      amountChanged({
        target: {
          value
        }
      }) {
        const data = {
          currency: this.currency,
          amount: Number(value) * 100
        };
        this.onChange(data);
      }

    }
  });

  _exports.default = _default;
});