define("backalley/pods/components/boutique-form/basic-info/component", ["exports", "backalley/enums/boutique-tier", "backalley/enums/boutique-sign-up-tier"], function (_exports, _boutiqueTier, _boutiqueSignUpTier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BoutiqueFormBasicInfo extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "tierOptions", Object.values(_boutiqueTier.default));

      _defineProperty(this, "tierLabels", {
        [_boutiqueTier.default.STAR_GOLD]: 'Star - Gold',
        [_boutiqueTier.default.STAR_SILVER]: 'Star - Silver',
        [_boutiqueTier.default.STAR_BRONZE]: 'Star - Bronze'
      });

      _defineProperty(this, "signUpTierOptions", Object.values(_boutiqueSignUpTier.default));
    }

  }

  _exports.default = BoutiqueFormBasicInfo;
});