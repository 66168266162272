define("backalley/pods/components/packaging-product-form/component", ["exports", "ember-concurrency", "backalley/models/packaging/category", "backalley/enums/packaging-third-party-provider", "backalley/models/country"], function (_exports, _emberConcurrency, _category, _packagingThirdPartyProvider, _country) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    router: Ember.inject.service(),
    classNames: ['packaging-product-form'],
    thirdPartyProviders: Object.values(_packagingThirdPartyProvider.default),
    selectedThirdPartyProvider: Ember.computed.alias('product.third_party_provider'),
    variants: Ember.computed('product.variants.[]', 'filteredCountry._id', 'filteredSku', function () {
      var _this$filteredSku;

      let variants = this.product.variants;

      if (!this.filteredCountry && !this.filteredSku) {
        return variants;
      }

      const skuRegExp = new RegExp(`^${this.filteredSku}`, 'i');

      if (((_this$filteredSku = this.filteredSku) === null || _this$filteredSku === void 0 ? void 0 : _this$filteredSku.length) >= 0) {
        variants = variants.filter(({
          sku
        }) => skuRegExp.test(sku));
      }

      if (this.filteredCountry) {
        variants = variants.filter(({
          country
        }) => country === this.filteredCountry._id);
      }

      return variants;
    }),

    async init() {
      this._super(...arguments);

      if (this.product.hasPopulatedCategory) {
        this.set('selectedCategory', this.product.category);
        return;
      }

      if (this.product.category) {
        const category = await _category.default.findById(this.product.category);
        this.set('selectedCategory', category);
      }
    },

    searchCategory: (0, _emberConcurrency.task)(function* (query) {
      yield (0, _emberConcurrency.timeout)(300);
      const {
        categories
      } = yield _category.default.find({
        name: {
          $regex: `^${query}`,
          $options: 'i'
        }
      });
      return categories;
    }).restartable(),
    searchCountry: (0, _emberConcurrency.task)(function* (query) {
      yield (0, _emberConcurrency.timeout)(500);
      return yield _country.default.find({
        name: {
          $regex: `^${query}`,
          $options: 'i'
        }
      });
    }).restartable(),
    actions: {
      filterByCountry(country) {
        this.set('filteredCountry', country);
      },

      selectCategory(category) {
        Ember.set(this, 'selectedCategory', category);
        Ember.set(this.product, 'category', category.id);
      },

      toggleVariantsOutOfStock(outOfStock) {
        if (!confirm(`Are you sure you want to mark all variants as ${outOfStock ? 'out of' : 'in'} stock?`)) {
          return;
        }

        this.product.variants.setEach('out_of_stock', outOfStock);
        this.notify.show('success', `All product's variants successfully marked as ${outOfStock ? 'out of' : 'in'} stock!`);
      },

      addVariant() {
        var _this$filteredCountry;

        this.product.addVariant({
          country: (_this$filteredCountry = this.filteredCountry) === null || _this$filteredCountry === void 0 ? void 0 : _this$filteredCountry._id
        });
      },

      duplicateVariant(variant) {
        this.product.addVariant(variant);
      },

      removeVariant(variant) {
        this.product.variants.removeObject(variant);
      },

      addImages(images) {
        const uploaded = Ember.isArray(images) ? images : [images];
        const urls = uploaded.map(({
          url
        }) => ({
          url
        }));
        this.product.images.addObjects(urls);
      },

      deleteImage(image) {
        return this.product.images.removeObject(image);
      },

      save() {
        const promise = Ember.isEmpty(this.product.id) ? this.product.create() : this.product.update();
        return promise.then(() => {
          this.notify.show('success', 'Product successfully saved!');
          this.router.transitionTo('main.packaging.products');
        }).catch(err => {
          const defaultMessage = 'An error ocurred while saving the product. Please try again.';
          const message = err.message || defaultMessage;
          this.notify.show('error', message);
        });
      }

    }
  });

  _exports.default = _default;
});