define("backalley/models/variant", ["exports", "joi", "backalley/util/models", "backalley/util/request"], function (_exports, _joi, _models, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Variant {
    constructor({
      _id,
      name,
      value
    }) {
      this.id = _id;
      this.name = name;
      this.value = value;
    }

    static create(params = {}) {
      return (0, _models.validateSchema)(this.schema, params).then(() => {
        return (0, _request.request)({
          method: 'POST',
          url: (0, _request.apiURL)('variants'),
          data: JSON.stringify(params)
        });
      }).then(({
        variants
      }) => {
        return new Variant(variants[0]);
      });
    }

    static find(params = {}) {
      return (0, _request.request)({
        method: 'POST',
        url: (0, _request.apiURL)('variants'),
        headers: {
          'X-HTTP-METHOD-OVERRIDE': 'GET'
        },
        data: JSON.stringify(params)
      }).then(({
        variants,
        meta
      }) => {
        return {
          variants: variants.map(variant => new Variant(variant)),
          meta
        };
      });
    }

  }

  _exports.default = Variant;

  _defineProperty(Variant, "schema", _joi.default.object().keys({
    name: _joi.default.string(),
    value: _joi.default.string()
  }));
});