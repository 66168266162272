define("backalley/pods/components/site-menu/component", ["exports", "jquery", "@glimmer/component", "ember-local-storage", "backalley/config/environment"], function (_exports, _jquery, _component, _emberLocalStorage, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SiteMenuComponent = (_dec = Ember.inject.service, _dec2 = (0, _emberLocalStorage.storageFor)('favourite-tabs'), (_class = (_temp = class SiteMenuComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "favouriteTabs", _descriptor2, this);

      _defineProperty(this, "mainLinks", [{
        route: 'main.boutiques',
        icon: 'home',
        label: 'Boutiques'
      }, {
        route: 'main.orders',
        icon: 'file-alt',
        label: 'Orders',
        query: {
          "channel": "trouva"
        }
      }, {
        route: 'main.orders',
        icon: 'file-alt',
        label: 'FY! Orders',
        query: {
          "channel": "fy"
        }
      }, {
        route: 'main.made.orders',
        icon: 'receipt',
        label: 'MADE Orders'
      }, {
        route: `${_environment.default.backalleyUiURL}/feature-flags`,
        icon: 'paper-plane',
        label: 'Feature Flags',
        external: true
      }]);

      _defineProperty(this, "retailOperationsLinks", [{
        route: 'main.notifications',
        icon: 'bell',
        label: 'Notifications centre'
      }]);

      _defineProperty(this, "productLinks", [{
        route: 'products',
        icon: 'search-plus',
        label: 'Search'
      }, {
        route: 'main.product-amendments',
        icon: 'comments',
        label: 'Notes'
      }, {
        route: 'main.feeds',
        icon: 'file-alt',
        label: 'Feeds'
      }, {
        route: 'main.product-delivery-tags',
        icon: 'tags',
        label: 'Delivery tags'
      }, {
        route: 'main.boutique-restock-information',
        icon: 'stopwatch',
        label: 'Restock information'
      }]);

      _defineProperty(this, "financeLinks", [{
        route: 'main.statements.index',
        icon: 'envelope',
        label: 'Statements'
      }, {
        route: 'main.currency_statements',
        icon: 'envelope',
        label: 'Currency Statements'
      }, {
        route: 'main.manual_adjustments',
        icon: 'asterisk',
        label: 'Manual Adjustments'
      }, {
        route: 'main.transactions',
        icon: 'download',
        label: 'Export Transactions'
      }, {
        route: 'main.transactions-view',
        icon: 'eye',
        label: 'View Transactions'
      }, {
        route: 'main.made.invoices',
        icon: 'file-invoice',
        label: 'MADE Invoices'
      }]);

      _defineProperty(this, "contentLinks", [{
        route: 'main.tags',
        icon: 'tags',
        label: 'Tags'
      }, {
        route: 'main.categories',
        icon: 'sitemap',
        label: 'Categories'
      }, {
        route: 'main.colors',
        icon: 'paint-brush',
        label: 'Colors'
      }, {
        route: 'main.sizes',
        icon: 'cubes',
        label: 'Sizes'
      }, {
        route: 'main.manufacturers',
        icon: 'gem',
        label: 'Manufacturers'
      }, {
        route: 'main.materials',
        icon: 'cube',
        label: 'Materials'
      }, {
        route: 'main.discounts',
        icon: 'money-bill-alt',
        label: 'Discounts'
      }, {
        route: 'main.areas',
        icon: 'map-marker-alt',
        label: 'Areas'
      }]);

      _defineProperty(this, "editorialLinks", [{
        route: 'main.landing',
        icon: 'paper-plane',
        label: 'Landing Pages'
      }, {
        route: 'main.campaigns',
        icon: 'chart-line',
        label: 'Campaigns'
      }, {
        route: 'main.info',
        icon: 'newspaper',
        label: 'Info Pages'
      }, {
        route: 'main.quotes',
        icon: 'comment',
        label: 'Quotes'
      }, {
        route: 'main.publishers',
        icon: 'book',
        label: 'Publishers'
      }, {
        route: 'main.publisherlinks',
        icon: 'link',
        label: 'Publisher Links'
      }]);

      _defineProperty(this, "businessDevelopmentLinks", [{
        route: 'main.prospective-boutique',
        icon: 'file-alt',
        label: 'Contracts'
      }]);

      _defineProperty(this, "internationalLinks", [{
        route: 'main.currencies',
        icon: 'money-bill-alt',
        label: 'Currencies'
      }, {
        route: 'main.currency_conversion_rates',
        icon: 'money-bill-alt',
        label: 'FX Rates'
      }, {
        route: 'main.currency_conversion_margins',
        icon: 'money-bill-alt',
        label: 'FX Margins'
      }, {
        route: 'main.countries',
        icon: 'map-marker-alt',
        label: 'Countries'
      }, {
        route: 'main.zones',
        icon: 'globe',
        label: 'Zones'
      }]);

      _defineProperty(this, "logisticsLinks", [{
        route: 'main.paths-and-lanes',
        icon: 'paper-plane',
        label: 'Paths and Lanes'
      }, {
        route: 'main.shipment-rates',
        icon: 'money-bill-alt',
        label: 'Shipment rates'
      }, {
        route: 'main.shipment-zones',
        icon: 'list',
        label: 'Shipment zones'
      }, {
        route: 'main.shipping-group-configurations',
        icon: 'list',
        label: 'Shipping groups'
      }, {
        route: 'main.shipments',
        icon: 'list',
        label: 'Shipments'
      }]);

      _defineProperty(this, "curateLinks", [{
        route: 'main.curate.orders',
        icon: 'file-alt',
        label: 'Curate Orders'
      }, {
        route: 'main.curate.brands',
        icon: 'store',
        label: 'Curate Brands'
      }, {
        route: 'main.curate.brand-tags',
        icon: 'tags',
        label: 'Curate Brand Tags'
      }, {
        route: 'main.curate.products',
        icon: 'barcode-alt',
        label: 'Curate Products'
      }, {
        route: 'main.curate.categories',
        icon: 'sitemap',
        label: 'Curate Categories'
      }]);

      _defineProperty(this, "packagingLinks", [{
        route: 'main.packaging.orders',
        icon: 'file-alt',
        label: 'Packaging Orders'
      }, {
        route: 'main.packaging.categories',
        icon: 'tags',
        label: 'Packaging Categories'
      }, {
        route: 'main.packaging.products',
        icon: 'box-open',
        label: 'Packaging Products'
      }]);

      _defineProperty(this, "frameLinks", [{
        route: 'main.frames',
        icon: 'paint-brush',
        label: 'Frames'
      }, {
        route: 'main.frame-tags',
        icon: 'tags',
        label: 'Frame Tags'
      }]);

      _defineProperty(this, "helpersLinks", [{
        route: 'main.triggers',
        icon: 'circle',
        label: 'Triggers'
      }]);
    }

    get peopleLinks() {
      const {
        hasUserManagementPermission
      } = this.session.user;
      const links = [{
        route: 'main.customers',
        icon: 'users',
        label: 'Customer data'
      }, {
        route: `${_environment.default.backalleyUiURL}/user-groups`,
        icon: 'users',
        label: 'User Groups',
        external: true
      }];

      if (hasUserManagementPermission) {
        links.addObject({
          route: 'main.user_management',
          icon: 'user-secret',
          label: 'Staff access'
        });
      }

      return links;
    }

    onDidInsertElement() {
      let $siteMenuLinks = (0, _jquery.default)('#site-menu a');
      $siteMenuLinks.click(function () {
        (0, _jquery.default)('html').removeClass('sidemenu-open');
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "favouriteTabs", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SiteMenuComponent;
});