define("backalley/pods/components/material-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['material-form'],
    searchTerm: Ember.computed('material', function () {
      return this.get('material.name');
    }),
    imageFolder: Ember.computed('material', function () {
      return `material/${this.get('material._id')}`;
    })
  });

  _exports.default = _default;
});