define("backalley/util/models", ["exports", "joi", "json-keys-sort"], function (_exports, _joi, _jsonKeysSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getProperties = getProperties;
  _exports.getChanges = getChanges;
  _exports.validateSchema = validateSchema;
  _exports.amountWithCurrency = _exports.resourceRef = _exports.objectId = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function getProperties(model, properties) {
    return properties.reduce((data, key) => {
      let property = model[key];

      if (property._id || property.id) {
        property = property._id || property.id;
      }

      data[key] = property;
      return data;
    }, {});
  }

  const getDataFromProperties = (data, properties) => {
    const filteredData = {};
    properties.forEach(property => filteredData[property] = data[property]);
    return filteredData;
  };

  function getChanges(original, changed, properties) {
    const obj = {
      _id: changed._id
    };

    const originalSorted = _jsonKeysSort.default.sort(getDataFromProperties(original, properties));

    const changedSorted = _jsonKeysSort.default.sort(getDataFromProperties(changed, properties));

    for (let i = 0; i < properties.length; i++) {
      let property = properties[i];
      const originalProperty = originalSorted[property];
      const changedProperty = changedSorted[property];

      if (JSON.stringify(changedProperty) !== JSON.stringify(originalProperty)) {
        obj[property] = changedProperty;
      }
    }

    return obj;
  }

  function validateSchema(schema, data, options = {}) {
    return new Ember.RSVP.Promise((resolve, reject) => {
      const validation = schema.validate(data, _objectSpread({
        stripUnknown: true
      }, options));

      if (Ember.isEmpty(validation.error)) {
        return resolve(validation.value);
      }

      console.error(`Error validating schema: ${validation.error.message}`);
      const err = {
        type: 'model-validation',
        label: validation.error.details[0].context.label,
        failedPath: validation.error.details[0].path,
        failedType: validation.error.details[0].type,
        message: validation.error.message
      };
      return reject(err);
    });
  }

  const objectId = _joi.default.string().regex(/^[0-9a-fA-F]{24}$/).message('{{#label}} is not a valid ObjectID');

  _exports.objectId = objectId;

  const resourceRef = _joi.default.alternatives().try(objectId, _joi.default.object({
    _id: objectId.required()
  }).unknown());

  _exports.resourceRef = resourceRef;

  const amountWithCurrency = _joi.default.object({
    currency: _joi.default.string().required(),
    amount: _joi.default.number().integer().min(0).required()
  });

  _exports.amountWithCurrency = amountWithCurrency;
});