define("backalley/pods/components/order-edit/order-changes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XwJKK47L",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"p\",false],[12,\"class\",\"box-view-heading\"],[3,\"action\",[[23,0,[]],[28,\"toggle\",[\"_expandSection\",[23,0,[]]],null]]],[8],[0,\"\\n  \"],[7,\"a\",true],[10,\"tabindex\",\"-1\"],[8],[0,\"Toggle\"],[9],[0,\"\\n  Changes\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"_expandSection\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[28,\"hot-load\",[\"order-audit-log\",[23,0,[]],[24,[\"order-audit-log\"]],\"order-audit-log\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"order\",\"boutiques\"],[[23,0,[]],\"order-audit-log\",[24,[\"order-audit-log\"]],false,true,[24,[\"order\"]],[24,[\"boutiqueIds\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/order-edit/order-changes/template.hbs"
    }
  });

  _exports.default = _default;
});