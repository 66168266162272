define("backalley/models/boutique-category", ["exports", "backalley/mixins/request", "joi", "backalley/util/models"], function (_exports, _request, _joi, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const validations = {
    _id: _joi.default.string(),
    name: _joi.default.string().required(),
    description: _joi.default.string().allow(''),
    product_categories: _joi.default.array().items(_joi.default.object())
  };
  const BoutiqueCategory = Ember.Object.extend(_request.default, {
    /**
     * Save boutique category
     * @return {Object} Promise
     */
    save() {
      return this.validate().then(data => (0, _models.getChanges)(this._orig, data, Object.keys(validations))).then(boutiqueCategory => {
        // Create a new array reference so that the UI is not impacted
        const product_categories = Array.from(boutiqueCategory.product_categories || []).map(category => {
          // Convert product_category objects to mongo id's
          if (typeof category === 'object') {
            return category._id;
          }

          return category;
        }); // If the record has an '_id' property, update it, otherwise create it

        const method = boutiqueCategory._id ? 'put' : 'post';
        const endpoint = method === 'put' ? `boutique_categories/${boutiqueCategory._id}` : 'boutique_categories';
        return this.request({
          method,
          url: this.apiURL(endpoint),
          data: JSON.stringify(_objectSpread(_objectSpread({}, boutiqueCategory), {}, {
            product_categories
          }))
        });
      }).then(res => {
        const data = res.boutique_categories[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    validate() {
      let data = JSON.parse(JSON.stringify(this));

      if (data.populatedCountry && data.populatedCountry._id) {
        data.address_country = data.populatedCountry._id;
      }

      this.set('errors', {});
      return (0, _models.validateSchema)(BoutiqueCategory.schema, data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    /**
     * Remove boutique category
     * @return {Object} Promise
     */
    remove() {
      const boutiqueCategory = this;
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`boutique_categories/${boutiqueCategory._id}`)
      });
    }

  });
  BoutiqueCategory.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  BoutiqueCategory.reopenClass(_request.default, {
    defaults: {
      name: '',
      description: '',
      product_categories: []
    },
    schema: _joi.default.object().keys(validations),

    create(data = {}) {
      const originalData = _objectSpread(_objectSpread({}, this.defaults), data);

      let c = this._super(originalData);

      c.setOriginalData(originalData);
      return c;
    },

    /**
     * Find boutique categories
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.findWithMeta(data).then(res => res.boutique_categories);
    },

    /**
     * Find boutique categories and returns meta
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMeta(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('boutique_categories'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => {
        return {
          boutique_categories: res.boutique_categories.map(boutique => this.create(boutique)),
          meta: res.meta
        };
      });
    },

    /**
     * Find boutique category by id
     * @param  {String} id boutique category _id
     * @param  {Object} data extra data to be passed in the query
     * @return {Object}    Promise
     */
    findById(id, data = {}) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`boutique_categories/${id}`),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => this.create(res.boutique_categories[0]));
    }

  });
  var _default = BoutiqueCategory;
  _exports.default = _default;
});