define("backalley/pods/main/shipment-zones/edit/route", ["exports", "backalley/models/shipment-zone"], function (_exports, _shipmentZone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      id
    }) {
      return _shipmentZone.default.findById(id).then(shipmentZone => {
        if (Ember.isEmpty(shipmentZone)) {
          this.notify.show('error', 'The requested shipment zone does not exist.');
          this.transitionTo('main.shipment-zones');
          return;
        }

        return shipmentZone;
      });
    },

    actions: {
      save(shipmentZone) {
        return shipmentZone.update().then(() => this.transitionTo('main.shipment-zones')).catch(err => {
          let error = err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
          this.notify.show('error', error);
        });
      }

    }
  });

  _exports.default = _default;
});