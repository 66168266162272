define("backalley/pods/components/landing-page/youtube/display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l4OYjnKC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"youtube youtube-display\"],[12,\"style\",[22,\"boxStyle\"]],[3,\"action\",[[23,0,[]],[24,[\"onSelectEntry\"]]]],[8],[0,\"\\n  \"],[14,1],[0,\"\\n  Youtube video to be inserted here\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/landing-page/youtube/display/template.hbs"
    }
  });

  _exports.default = _default;
});