define("backalley/pods/components/international-order-flag/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    orderChannel: Ember.computed('channel', function () {
      const [, language] = this.channel.split('-');
      return language ? language : '';
    })
  });

  _exports.default = _default;
});