define("backalley/pods/components/auto-complete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZF0k333e",
    "block": "{\"symbols\":[\"item\",\"index\",\"&default\"],\"statements\":[[7,\"input\",true],[10,\"class\",\"input\"],[11,\"name\",[28,\"if\",[[24,[\"name\"]],[24,[\"name\"]],\"search\"],null]],[11,\"value\",[22,\"query\"]],[11,\"onblur\",[28,\"action\",[[23,0,[]],\"clear\"],null]],[11,\"placeholder\",[22,\"placeholder\"]],[11,\"oninput\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"query\"]]],null]],[[\"value\"],[\"target.value\"]]]],[10,\"type\",\"search\"],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"content\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[10,\"class\",\"auto-complete-results\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"content\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[0,\"      \"],[7,\"li\",false],[12,\"class\",[29,[[28,\"if\",[[23,1,[\"active\"]],\"active\"],null]]]],[3,\"action\",[[23,0,[]],\"select\",[23,1,[]]]],[8],[0,\"\\n        \"],[1,[28,\"get\",[[23,1,[]],[24,[\"key\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/auto-complete/template.hbs"
    }
  });

  _exports.default = _default;
});