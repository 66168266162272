define("backalley/pods/components/forms/boutique-category/component", ["exports", "backalley/models/category", "ember-concurrency"], function (_exports, _category, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const noop = function () {};

  var _default = Ember.Component.extend({
    // Public API
    boutiqueCategory: {},
    onSave: noop,
    onDelete: undefined,
    // Private API
    tagName: 'form',
    classNames: ['form-container', 'boutique-category-form'],
    // Product categories search
    __loadProductCategoriesTask: (0, _emberConcurrency.task)(function* (name) {
      return yield _category.default.find({
        name: {
          $regex: `^${name}`,
          $options: 'i'
        }
      });
    }).keepLatest(),
    actions: {
      selectProductCategory(selected) {
        this.get('boutiqueCategory.product_categories').addObject(selected);
      },

      removeProductCategory(productCategory) {
        this.get('boutiqueCategory.product_categories').removeObject(productCategory);
      }

    }
  });

  _exports.default = _default;
});