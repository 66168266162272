define("backalley/pods/components/form-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['form-select'],
    labelPath: Ember.computed('optionLabelPath', function () {
      const optionLabelPath = this.optionLabelPath;
      return optionLabelPath.replace('content.', '');
    }),
    valuePath: Ember.computed('optionValuePath', function () {
      const optionValuePath = this.optionValuePath;
      return optionValuePath.replace('content.', '');
    }),
    _value: Ember.computed('value', 'valuePath', 'content', {
      get() {
        const content = this.content;
        const value = this.value;
        const valuePath = this.valuePath;

        if (typeof value === 'object') {
          return value;
        } else {
          return content.find(option => Ember.get(option, valuePath) === value);
        }
      },

      set(_, value) {
        return value;
      }

    }),
    actions: {
      onSelect(value) {
        const action = this.action; // Update dropdown immediately

        this.set('_value', value); // Send the action to the parent

        if (typeof action === 'function') {
          return action(...arguments);
        }
      }

    }
  });

  _exports.default = _default;
});