define("backalley/pods/components/boutique-form/terms-and-conditions-info/component", ["exports", "backalley/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    termsAndConditionsAcceptedBy: Ember.computed('boutique.terms_and_conditions.accepted_by', function () {
      return this._findUserById(this.boutique.terms_and_conditions.accepted_by);
    }),
    otherCommercialTermsServiceFeeAsParagraphs: Ember.computed('boutique.other_commercial_terms_service_fee', function () {
      const terms = this.boutique.other_commercial_terms_service_fee;
      if (!terms) return '';
      return terms.split('\n\n');
    }),

    _findUserById(userId) {
      let users = userId ? _user.default.find({
        _id: userId
      }) : Ember.RSVP.resolve([null]);
      return users.then(([user]) => user);
    },

    actions: {
      setRegisteredCountry(country) {
        if (!country || !country._id) {
          return;
        }

        this.setProperties({
          'boutique.populatedRegisteredCountry': country,
          'boutique.registered_address_country': country._id
        });
      }

    }
  });

  _exports.default = _default;
});