define("backalley/enums/path-and-lane-release-details-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    CREATED: '00:created',
    ERRORED: '02:errored',
    PROCESSING_ITEMS: '02:processing-items',
    PROCESSING_RULES: '02:processing-rules',
    STABLE: '04:stable',
    ACTIVE: '06:active',
    CRITICAL: '06:critical',
    DECOMMISSIONING: '10:decommissioning',
    ARCHIVED: '12:archived'
  };
  _exports.default = _default;
});