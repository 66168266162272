define("backalley/enums/boutique-promotion-name-type", ["exports", "backalley/enums/types"], function (_exports, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _types.default.create({
    CHRISTMAS_EXPRESS_PROMO: 'christmas_express_promo'
  });

  _exports.default = _default;
});