define("backalley/pods/main/curate/products/edit/route", ["exports", "backalley/models/curate/product"], function (_exports, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MainCurateProductsEditRoute extends Ember.Route {
    async model({
      id
    }) {
      return _product.default.findById(id);
    }

  }

  _exports.default = MainCurateProductsEditRoute;
});