define("backalley/models/packaging/order", ["exports", "joi", "backalley/util/request"], function (_exports, _joi, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const validations = {};

  class PackagingOrder {
    constructor({
      _id,
      created_at,
      order_number,
      reservations,
      pricing,
      boutique_details,
      delivery_details,
      billing_details
    }) {
      this.id = _id;
      this.created_at = created_at;
      this.order_number = order_number;
      this.reservations = reservations;
      this.pricing = pricing;
      this.boutique_details = boutique_details;
      this.delivery_details = delivery_details;
      this.billing_details = billing_details;
    }

    static find(params = {}) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.packagingApiURL)('orders'),
        data: _objectSpread({}, params)
      }).then(({
        orders,
        meta
      }) => ({
        orders: orders.map(order => new PackagingOrder(order)),
        meta
      })).catch(err => {
        throw err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
      });
    }

    static findById(id) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.packagingApiURL)(`orders/${id}`)
      }).then(({
        orders
      }) => {
        const [order] = orders;
        return new PackagingOrder(order);
      }).catch(err => {
        throw err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
      });
    }

  }

  _exports.default = PackagingOrder;

  _defineProperty(PackagingOrder, "schema", _joi.default.object().keys(_objectSpread({}, validations)));
});