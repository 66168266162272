define("backalley/pods/main/categories/edit/route", ["exports", "backalley/models/category", "backalley/models/tag", "backalley/util/escape-regex"], function (_exports, _category, _tag, _escapeRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    serialize(model) {
      return {
        category_id: model._id
      };
    },

    model(params) {
      return Ember.RSVP.hash(Ember.Object.create({
        category: this.getCategory(params.category_id),
        tags: Ember.A()
      }));
    },

    getCategory(_id) {
      return _category.default.find({
        _id,
        populate: 'tags'
      }).then(categories => {
        let category = categories[0];
        category.set('populatedTags', Array.from(category.tags));
        category.set('tags', (category.tags || []).map(tag => tag._id));
        return category;
      });
    },

    actions: {
      updateCategory(category) {
        let tags = category.get('populatedTags').map(tag => tag._id);
        category.set('tags', tags);
        return category.save().then(() => this.transitionTo('main.categories')).catch(err => this.notify.show('err', err.message));
      },

      searchTags(q) {
        if (Ember.isEmpty(q)) {
          return;
        }

        return _tag.default.find({
          label: {
            $regex: `^${(0, _escapeRegex.default)(q)}`,
            $options: 'i'
          },
          limit: 10
        }).then(tags => this.set('currentModel.tags', tags));
      },

      addTag(tag) {
        let currentTags = this.get('currentModel.category.populatedTags');

        if (!currentTags.findBy('_id', tag._id)) {
          // prevent adding the same twice
          currentTags.addObject(tag);
          this.set('currentModel.category.populatedTags', currentTags);
        }
      },

      deleteTag(tag) {
        let categoryTags = this.get('currentModel.category.populatedTags').filter(cTag => cTag._id !== tag._id);
        this.set('currentModel.category.populatedTags', categoryTags);
      },

      cancel() {
        this.transitionTo('main.categories');
      }

    }
  });

  _exports.default = _default;
});