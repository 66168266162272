define("backalley/pods/components/price/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q4SBQfYS",
    "block": "{\"symbols\":[\"@price\"],\"statements\":[[1,[28,\"format-number\",[[28,\"to-pence\",[[23,1,[\"amount\"]]],null]],[[\"currency\",\"style\"],[[23,1,[\"currency\"]],\"currency\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/price/template.hbs"
    }
  });

  _exports.default = _default;
});