define("backalley/utils/matchers", ["exports", "ember-ast-hot-load/utils/matchers"], function (_exports, _matchers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _matchers.default;
    }
  });
});