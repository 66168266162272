define("backalley/pods/main/info/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X3L1Lr3O",
    "block": "{\"symbols\":[],\"statements\":[[4,\"component\",[[28,\"hot-load\",[\"page-heading\",[23,0,[]],[24,[\"page-heading\"]],\"page-heading\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"fa\"],[[23,0,[]],\"page-heading\",[24,[\"page-heading\"]],false,true,\"newspaper\"]],{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",[29,[\"button \",[28,\"if\",[[24,[\"model\",\"loading\"]],\"loading\"],null]]]],[3,\"action\",[[23,0,[]],[28,\"route-action\",[\"savePage\"],null]]],[8],[0,\"\\n    Save page\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"main.info\"]],{\"statements\":[[0,\"Info\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    New page\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"page-title\",[\"New info page\"],null],false],[0,\"\\n\\n\"],[1,[28,\"component\",[[28,\"hot-load\",[\"info-form\",[23,0,[]],[24,[\"info-form\"]],\"info-form\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"page\",\"loading\",\"onSave\",\"onRemove\"],[[23,0,[]],\"info-form\",[24,[\"info-form\"]],false,true,[24,[\"model\",\"page\"]],[24,[\"model\",\"loading\"]],[28,\"route-action\",[\"savePage\"],null],[28,\"route-action\",[\"removePage\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/info/new/template.hbs"
    }
  });

  _exports.default = _default;
});