define("backalley/util/diff", ["exports", "backalley/enums/diff-types", "json-diff"], function (_exports, _diffTypes, _jsonDiff) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateDiffAsKeysAndValues = generateDiffAsKeysAndValues;
  _exports.mergeComparisonDiffs = mergeComparisonDiffs;
  Object.defineProperty(_exports, "jsonDiff", {
    enumerable: true,
    get: function () {
      return _jsonDiff.diff;
    }
  });

  /**
   * @file code responsibe for intelligently understanding when there
   * have been changes to a complex JSON structure. This system works
   * without the need for unique identifiers and does so using json-diff.
   */
  const diffTypeList = (0, _diffTypes.getAsValues)();
  /**
   * Given a json-diff result, convert it to a structure that is easier
   * to parse in a general way. The result should be something like:
   *
   *     [
   *         ['a.new.field.was.added', '+', 'the new value added'],
   *         ['a.field.was.removed', '-', 'the value removed'],
   *         ['a.field.was.changed', '~', {
   *             __old: 'old value',
   *             __new: 'new value'
   *         }],
   *         ...
   *     ]
   *
   * The fieldPath allows us to detect changes using regular expressions.
   * Therefore we can easily check if a collection of deep properties
   * have altered without needing to know the higher specific structure.
   *
   * @param {object} diffObj - the diff object from json-diff to parse
   * @param {string} [filePath=''] - the initial root path, often ''
   * @param {array} [changeSet=[]] - the array that will get .pushed to
   * @param {number} [maxDepth=10] - safety limit on recursion, jic.
   */

  function convertDiffToKeysAndValues(diffObj, fieldPath = '', changeSet = [], maxDepth = 10) {
    if (!diffObj) {
      return changeSet;
    }

    const isArray = Array.isArray(diffObj);
    const isDiffNotation = isArray && diffTypeList.includes(diffObj[0]);
    const isObject = diffObj !== null && typeof diffObj === 'object' && !isDiffNotation;

    if (isDiffNotation) {
      if (diffObj[0] !== _diffTypes.default.DIFF_NONE) {
        changeSet.push([fieldPath, ...diffObj]);
      }

      return;
    }

    const isDeletion = fieldPath.substring(fieldPath.length - 9) === '__deleted';

    if (isDeletion) {
      changeSet.push([fieldPath.substring(0, fieldPath.length - 9), '-', diffObj]);
      return;
    }

    const isAddition = fieldPath.substring(fieldPath.length - 7) === '__added';

    if (isAddition) {
      changeSet.push([fieldPath.substring(0, fieldPath.length - 7), '+', diffObj]);
      return;
    }

    const keys = isObject ? Object.keys(diffObj) : [];
    const isFieldChange = keys.includes('__old') && keys.includes('__new');

    if (isFieldChange) {
      if (diffObj[0] !== _diffTypes.default.DIFF_NONE) {
        changeSet.push([fieldPath, '~', diffObj]);
      }

      return;
    }

    if (keys.length && maxDepth > 0) {
      keys.forEach(key => {
        const childFieldPath = fieldPath ? `${fieldPath}.${key}` : key;
        convertDiffToKeysAndValues(diffObj[key], childFieldPath, changeSet, maxDepth - 1);
      });
    }

    if (maxDepth <= 0) {
      console.error(`convert diff hit max depth at ${fieldPath}`);
    }

    if (!isObject) {
      console.error(`convert diff hit unexpected value ${diffObj} as ${fieldPath}`);
    }

    return changeSet;
  }
  /**
   * Simplified handler for convertDiffToKeysAndValues, so that external
   * code doesn't need to know the diff solution we are using.
   *
   * @see convertDiffToKeysAndValues
   * @param {object} b - the object were comparing against
   * @param {object} a - the object were comparing
   * @param {string} [filePath=''] - the initial root path, often ''
   * @param {array} [changeSet=[]] - the array that will get .pushed to
   */


  function generateDiffAsKeysAndValues(b, a, fieldPath = '', changeSet = []) {
    return convertDiffToKeysAndValues((0, _jsonDiff.diff)(b, a), fieldPath, changeSet);
  }
  /**
   * Take the result of multiple convertDiffToKeysAndValues() calls and
   * condense down to better understand the changes. It does this by
   * treating +/- (add/remove) at a higher priority than ~ (changes).
   *
   * Multiple convertDiffToKeysAndValues() calls should be used on deep
   * structures. You identify particular areas that may get confused
   * by diffs limitations, and break the processing up at that point.
   * Possible areas of confusion are when a removal from an array of
   * complex objects can look like a modification.
   */


  function mergeComparisonDiffs(differences) {
    const merge = {};
    differences.forEach(([field, type, values]) => {
      // a later plus or minus overrides
      if (merge[field] && type === _diffTypes.default.DIFF_ADD) {
        merge[field] = [field, type, values];
        return;
      }

      if (merge[field] && type === _diffTypes.default.DIFF_REMOVE) {
        merge[field] = [field, type, values];
        return;
      } // any other later change is ignored


      if (merge[field]) {
        return;
      } // first time we see a field, store it


      merge[field] = [field, type, values];
    });
    return Object.values(merge);
  }
});