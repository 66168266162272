define("backalley/pods/components/landing-page/box-version-two/options/component", ["exports", "backalley/util/default-to", "backalley/pods/main/landing/new/selectOptions", "backalley/util/clone-deep"], function (_exports, _defaultTo, _selectOptions, _cloneDeep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    // Public API
    selectedRow: (0, _defaultTo.default)(null),
    imageFolder: (0, _defaultTo.default)(null),
    selectedEntry: (0, _defaultTo.default)(null),
    // Private API
    availablePositions: (0, _defaultTo.default)(_selectOptions.availablePositions),
    availableColorsUnprifixed: (0, _defaultTo.default)(_selectOptions.availableColorsUnprifixed),
    availablePaddings: (0, _defaultTo.default)(_selectOptions.availablePaddings),
    availableMargins: (0, _defaultTo.default)(_selectOptions.availableMargins),
    availableBoxElements: (0, _defaultTo.default)(_selectOptions.availableBoxElements),
    availableColorPickerOptions: (0, _defaultTo.default)(_selectOptions.availableColorPickerOptions),
    cloudinaryFileTypes: /(\.|\/)(gif|jpe?g|png|bmp|ico|mp4)$/i,
    cloudinaryMaxDesktopSize: 30000000,
    // General options
    selectedEntryGeneral: Ember.computed.alias('selectedEntry.content.general'),
    selectedContentPosition: Ember.computed('selectedEntryGeneral.position', function () {
      const selectedPosition = this.get('selectedEntryGeneral.position');
      return this.availablePositions.find(position => position.value === selectedPosition);
    }),
    selectedGeneralBackgroundColor: Ember.computed('selectedEntryGeneral.{backgroundColor,backgroundHexColor}', function () {
      const {
        backgroundHexColor,
        backgroundColor
      } = this.get('selectedEntry.content.general');
      return backgroundHexColor ? backgroundHexColor : backgroundColor;
    }),
    maskOpacityPercentage: Ember.computed('selectedEntry.content.mask.opacity', function () {
      return this.get('selectedEntry.content.mask.opacity') * 100;
    }),
    // Content padding options
    selectedGeneralPaddingTop: Ember.computed('selectedEntryGeneral.paddingTop', function () {
      const selectedPadding = this.get('selectedEntryGeneral.paddingTop');
      return this.availablePaddings.find(position => position.value === selectedPadding);
    }),
    selectedGeneralPaddingBottom: Ember.computed('selectedEntryGeneral.paddingBottom', function () {
      const selectedPadding = this.get('selectedEntryGeneral.paddingBottom');
      return this.availablePaddings.find(position => position.value === selectedPadding);
    }),
    selectedGeneralPaddingLeft: Ember.computed('selectedEntryGeneral.paddingLeft', function () {
      const selectedPadding = this.get('selectedEntryGeneral.paddingLeft');
      return this.availablePaddings.find(position => position.value === selectedPadding);
    }),
    selectedGeneralPaddingRight: Ember.computed('selectedEntryGeneral.paddingRight', function () {
      const selectedPadding = this.get('selectedEntryGeneral.paddingRight');
      return this.availablePaddings.find(position => position.value === selectedPadding);
    }),
    // Column margin options
    selectedGeneralMarginTop: Ember.computed('selectedEntryGeneral.marginTop', function () {
      const selectedPadding = this.get('selectedEntryGeneral.marginTop');
      return this.availableMargins.find(position => position.value === selectedPadding);
    }),
    selectedGeneralMarginBottom: Ember.computed('selectedEntryGeneral.marginBottom', function () {
      const selectedPadding = this.get('selectedEntryGeneral.marginBottom');
      return this.availableMargins.find(position => position.value === selectedPadding);
    }),
    selectedGeneralMarginLeft: Ember.computed('selectedEntryGeneral.marginLeft', function () {
      const selectedPadding = this.get('selectedEntryGeneral.marginLeft');
      return this.availableMargins.find(position => position.value === selectedPadding);
    }),
    selectedGeneralMarginRight: Ember.computed('selectedEntryGeneral.marginRight', function () {
      const selectedPadding = this.get('selectedEntryGeneral.marginRight');
      return this.availableMargins.find(position => position.value === selectedPadding);
    }),

    capitalizeFirstLetter(string) {
      return string.replace(string[0], string[0].toUpperCase());
    },

    actions: {
      reorderElements(orderedElements) {
        Ember.set(this.selectedEntry.content, 'elements', orderedElements);
      },

      addElement({
        value
      }) {
        this.selectedEntry.content.elements.addObject((0, _cloneDeep.default)(value));
      },

      removeElement(element) {
        this.selectedEntry.content.elements.removeObject(element);
      },

      // General setters
      onPositionSelected(position) {
        const general = this.get('selectedEntry.content.general');
        Ember.set(general, 'position', position.value);
      },

      onGeneralBackgroundColorSelected(color) {
        const general = this.get('selectedEntry.content.general');
        Ember.set(general, 'backgroundHexColor', color);
      },

      onGeneralMarginSelected(location, option) {
        const general = this.get('selectedEntry.content.general');
        const capitalizedLocation = this.capitalizeFirstLetter(location);
        Ember.set(general, `margin${capitalizedLocation}`, option.value);
      },

      onGeneralPaddingSelected(location, option) {
        const general = this.get('selectedEntry.content.general');
        const capitalizedLocation = this.capitalizeFirstLetter(location);
        Ember.set(general, `padding${capitalizedLocation}`, option.value);
      },

      // Mask setters
      onMaskToggle() {
        const enabled = this.get('selectedEntry.content.mask.enabled');
        this.set('selectedEntry.content.mask.enabled', !enabled);
      },

      // Image setters/removers
      onUploadDesktopMedia(resource) {
        const selectedEntry = this.selectedEntry;

        if (resource.resource_type === 'video') {
          Ember.set(selectedEntry, 'content.video.desktop', resource);
        } else {
          Ember.set(selectedEntry, 'content.image.desktop', resource);
        }
      },

      onUploadMobileImage(resource) {
        const selectedEntry = this.selectedEntry;
        Ember.set(selectedEntry, 'content.image.mobile', resource);
      },

      onRemoveDesktopMedia() {
        const selectedEntry = this.selectedEntry;
        Ember.set(selectedEntry, 'content.image.desktop', {});
        Ember.set(selectedEntry, 'content.video.desktop', {});
      },

      onRemoveMobileImage() {
        const selectedEntry = this.selectedEntry;
        Ember.set(selectedEntry, 'content.image.mobile', {});
      },

      // Button related setters
      onButtonTypeSelected(obj, type) {
        Ember.set(obj, 'design', type.value);
      },

      onButtonSizeSelected(obj, type) {
        Ember.set(obj, 'size', type.value);
      },

      onButtonLabelInput(obj, text) {
        Ember.set(obj, 'label', text);
      },

      // Text setters
      onTextInput(obj, text) {
        Ember.set(obj, 'text', text);
      },

      onTextColorSelected(obj, color) {
        Ember.set(obj, 'hexColor', color);
      },

      onTextAlignmentSelected(obj, alignment) {
        // Create the style object if it doesn't exist
        if (!obj.style) Ember.set(obj, 'style', {});
        Ember.set(obj, 'style.textAlign', alignment);
      },

      // Link setters
      onLinkTypeSelected(type) {
        const selectedEntry = this.selectedEntry;
        Ember.set(selectedEntry, 'content.link.type', type.value);
      },

      onLinkTypeOptionSelected(type, option) {
        const selectedEntry = this.selectedEntry;
        const link = this.get('selectedEntry.content.link');
        Ember.set(selectedEntry, 'content.link', _objectSpread(_objectSpread({}, link), option));
      },

      onLinkRemoved() {
        const selectedEntry = this.selectedEntry;
        Ember.set(selectedEntry, 'content.link', {});
      }

    }
  });

  _exports.default = _default;
});