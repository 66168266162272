define("backalley/mirage/factories/color", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // mirage/factories/category.js
  var _default = _emberCliMirage.default.Factory.extend({
    label: i => `label ${i}`,
    value: '#ffffff',

    updated_at() {
      return new Date(_emberCliMirage.faker.date.past(Math.round(Math.random() * 10)));
    },

    created_at() {
      return new Date(_emberCliMirage.faker.date.past(Math.round(Math.random() * 10)));
    },

    _id: i => String(i + 1) // id starts at 1

  });

  _exports.default = _default;
});