define("backalley/services/globals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    displayGlobalSearch: false,
    iframePreview: {
      isOpen: false,
      title: '',
      src: ''
    },

    toggleGlobalSearch() {
      this.toggleProperty('displayGlobalSearch');
    },

    setGlobalSearch(value) {
      this.set('displayGlobalSearch', value);
    },

    openIframePreview(title, src) {
      const iframePreview = {
        isOpen: true,
        title,
        src
      };
      this.set('iframePreview', iframePreview);
    },

    closeIframePreview() {
      this.set('iframePreview.isOpen', false);
    }

  });

  _exports.default = _default;
});