define("backalley/models/comment", ["exports", "backalley/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Comment = Ember.Object.extend(_request.default, {
    post() {
      return this.request({
        method: 'post',
        url: this.apiURL('comments'),
        data: JSON.stringify(this.sanitize())
      }).then(({
        comments
      }) => {
        const [comment] = comments;
        Comment.trigger('created', comment);
        return {
          comment
        };
      });
    }

  });
  Comment.reopenClass(_request.default, Ember.Evented, {
    // Class methods

    /**
     * Find comments
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.request({
        method: 'get',
        url: this.apiURL('comments'),
        data
      }).then(res => {
        return {
          comments: res.comments.map(c => this.create(c))
        };
      });
    }

  });
  var _default = Comment;
  _exports.default = _default;
});