define("backalley/pods/components/checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0gYUjxIJ",
    "block": "{\"symbols\":[\"@indeterminate\",\"@checked\",\"&attrs\",\"@disabled\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"checkbox \",[23,0,[\"styleNamespace\"]],\" \",[28,\"if\",[[23,2,[]],\"checkbox--checked\"],null],\" \",[28,\"if\",[[23,1,[]],\"checkbox--indeterminate\"],null]]]],[13,3],[8],[0,\"\\n  \"],[7,\"input\",false],[12,\"id\",[23,0,[\"checkboxId\"]]],[12,\"checked\",[23,2,[]]],[12,\"disabled\",[23,4,[]]],[12,\"type\",\"checkbox\"],[3,\"on\",[\"click\",[23,0,[\"onClick\"]]]],[8],[9],[0,\"\\n\\n  \"],[7,\"label\",true],[11,\"for\",[23,0,[\"checkboxId\"]]],[8],[0,\"\\n    \"],[14,5],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});