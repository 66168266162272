define("backalley/models/sunflower-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Route = Ember.Object.extend({});
  Route.reopenClass({
    find(q) {
      return Ember.RSVP.resolve([{
        id: 'landing',
        name: 'Home page'
      }, {
        id: 'login',
        name: 'Login'
      }, {
        id: 'signup',
        name: 'Signup'
      }, {
        id: 'boutiques',
        name: 'Boutiques'
      }, {
        id: 'team',
        name: 'Team'
      }, {
        id: 'friends',
        name: 'Friends'
      }, {
        id: 'our-story',
        name: 'Our story'
      }, {
        id: 'shop.new-in',
        name: 'New in'
      }, {
        id: 'shop.sale',
        name: 'Sale page'
      }]);
    }

  });
  var _default = Route;
  _exports.default = _default;
});