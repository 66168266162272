define("backalley/pods/main/packaging/products/index/controller", ["exports", "ember-concurrency", "backalley/models/country", "backalley/models/packaging/category", "backalley/enums/packaging-product-status", "ember-concurrency-decorators"], function (_exports, _emberConcurrency, _country, _category, _packagingProductStatus, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PackagingProductsIndexController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class PackagingProductsIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['page', 'limit', 'sort', 'country', 'category', 'status', 'full_text_search']);

      _defineProperty(this, "availableStatus", [{
        label: 'All',
        value: null
      }, {
        label: 'Status: Published',
        value: _packagingProductStatus.default.PUBLISHED
      }, {
        label: 'Status: Archived',
        value: _packagingProductStatus.default.ARCHIVED
      }]);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "limit", 16);

      _defineProperty(this, "sort", '-created_at');

      _initializerDefineProperty(this, "status", _descriptor, this);

      _initializerDefineProperty(this, "country", _descriptor2, this);

      _initializerDefineProperty(this, "category", _descriptor3, this);
    }

    get selectedCountry() {
      if (!this.country) {
        return null;
      }

      return _country.default.findById(this.country);
    }

    get selectedCategory() {
      if (!this.category) {
        return null;
      }

      return _category.default.findById(this.category);
    }

    get selectedStatus() {
      return this.availableStatus.find(({
        value
      }) => value === this.status);
    }

    getQueryParams() {
      return {
        page: 1,
        limit: this.limit,
        sort: this.sort,
        country: this.country,
        category: this.category,
        status: this.status
      };
    }

    *searchCountry(query) {
      yield (0, _emberConcurrency.timeout)(500);
      const countries = yield _country.default.find({
        name: {
          $regex: `^${query}`,
          $options: 'i'
        }
      });
      return countries;
    }

    *searchCategory(query) {
      yield (0, _emberConcurrency.timeout)(500);
      const {
        categories
      } = yield _category.default.find({
        name: {
          $regex: `^${query}`,
          $options: 'i'
        }
      });
      return categories;
    }

    selectCountry(country) {
      return this.transitionToRoute({
        queryParams: _objectSpread(_objectSpread({}, this.queryParams), {}, {
          country: country ? country._id : undefined
        })
      });
    }

    selectCategory(category) {
      return this.transitionToRoute({
        queryParams: _objectSpread(_objectSpread({}, this.getQueryParams()), {}, {
          category: category ? category.id : undefined
        })
      });
    }

    selectStatus(status) {
      return this.transitionToRoute({
        queryParams: _objectSpread(_objectSpread({}, this.getQueryParams()), {}, {
          status: status.value
        })
      });
    }

    *archiveProduct(product) {
      if (!confirm('Are you sure you want to permanently archive this product?')) {
        return;
      }

      yield (0, _emberConcurrency.timeout)(500);
      yield product.archive();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "status", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _packagingProductStatus.default.PUBLISHED;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "category", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "searchCountry", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchCountry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchCategory", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectCountry", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectCountry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectCategory", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "selectCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectStatus", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "selectStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "archiveProduct", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "archiveProduct"), _class.prototype)), _class));
  _exports.default = PackagingProductsIndexController;
});