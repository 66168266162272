define("backalley/pods/main/product-amendments/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['boutique', 'page', 'limit', 'sort', 'status'],
    page: 1,
    limit: 30,
    sort: '-created_at',
    status: 'pending||resolved',
    boutique: null,
    user: null
  });

  _exports.default = _default;
});