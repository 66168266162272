define("backalley/models/team-note", ["exports", "backalley/util/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const S3_ROOT_FOLDER = 'team-notes';

  class TeamNote {
    static find(filePath) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.apiURL)(`static/${S3_ROOT_FOLDER}/${filePath}/content.json`)
      });
    }

    static save(filePath, content) {
      return (0, _request.request)({
        method: 'POST',
        url: (0, _request.apiURL)(`static/${S3_ROOT_FOLDER}/${filePath}/content.json`),
        data: JSON.stringify(content)
      });
    }

  }

  _exports.default = TeamNote;
});