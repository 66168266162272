define("backalley/models/currency", ["exports", "backalley/mixins/request", "joi", "backalley/util/models"], function (_exports, _request, _joi, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const validations = {
    name: _joi.default.string().required(),
    symbol: _joi.default.string().required(),
    transactions_enabled: _joi.default.boolean().required(),
    braintree_merchant_account_id: _joi.default.string(),
    iso_4217: _joi.default.string().required(),
    conversion_rate: _joi.default.number().positive().greater(0).required(),
    address_mismatch_fraud_threshold_in_cents: _joi.default.number().positive().greater(0).required(),
    total_order_fraud_threshold_in_cents: _joi.default.number().positive().greater(0).required(),
    _id: _joi.default.string(),
    created_at: _joi.default.date(),
    updated_at: _joi.default.date()
  };
  const Currency = Ember.Object.extend(_request.default, {
    /**
     * Create new color
     * @return {Object} Promise
     */
    post() {
      return this.validate().then(currency => {
        return this.request({
          method: 'POST',
          url: this.apiURL('currencies'),
          data: JSON.stringify(currency)
        });
      }).then(res => {
        const data = res.currencies[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    /**
     * Save color
     * @return {Object} Promise
     */
    save() {
      return this.validate().then(data => (0, _models.getChanges)(this._orig, data, Object.keys(validations))).then(currency => {
        return this.request({
          method: 'PUT',
          url: this.apiURL(`currencies/${currency._id}`),
          data: JSON.stringify(currency)
        });
      }).then(res => {
        const data = res.currencies[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    validate() {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(Currency.schema, data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    /**
     * Remove color
     * @return {Object} Promise
     */
    remove() {
      const currency = this;
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`currencies/${currency._id}`)
      });
    }

  });
  Currency.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  Currency.reopenClass(_request.default, {
    schema: _joi.default.object().keys(validations).and('name', 'symbol', 'iso_4217', 'conversion_rate', 'address_mismatch_fraud_threshold_in_cents', 'total_order_fraud_threshold_in_cents'),

    create(data = {}) {
      let c = this._super(data);

      c.setOriginalData(data);
      return c;
    },

    /**
     * Find colors
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.findWithMeta(data).then(res => res.currencies);
    },

    /**
     * Find colors and returns meta too
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMeta(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('currencies'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => {
        return {
          currencies: res.currencies.map(currency => this.create(currency)),
          meta: res.meta
        };
      });
    },

    /**
     * Find color by id
     * @param  {String} id color _id
     * @return {Object}    Promise
     */
    findById(id) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`currencies/${id}`),
        data: {
          plain: true
        }
      }).then(res => this.create(res.currencies[0]));
    }

  });
  var _default = Currency;
  _exports.default = _default;
});