define("backalley/pods/main/statements/index/route", ["exports", "backalley/models/boutique", "moment"], function (_exports, _boutique, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      until: {
        refreshModel: true
      },
      lock_until: {
        refreshModel: true
      },
      statements_version: {
        refreshModel: true
      }
    },

    serializeQueryParam(value, urlKey = '') {
      if (urlKey === 'until') {
        return (0, _moment.default)(value).endOf('day').toISOString();
      }

      if (urlKey === 'lock_until') {
        return (0, _moment.default)(value).endOf('day').toISOString();
      }

      return value;
    },

    model({
      until,
      lock_until,
      statements_version
    }) {
      const statementVersion = statements_version === 'all' ? undefined : statements_version;
      return _boutique.default.find({
        statements_version: statementVersion,
        fields: 'name,_id,bank_account_number,bank_sort_code,statement_email,published,featured'
      }).then(boutiques => {
        return {
          loading: false,
          boutiques,
          until,
          lock_until,
          queryParams: {
            until: (0, _moment.default)(until).format('YYYY-MM-DD'),
            lock_until: lock_until ? (0, _moment.default)(lock_until).format('YYYY-MM-DD') : null
          }
        };
      });
    },

    actions: {
      setStatementsVersion(option) {
        this.controller.set('statements_version', option.value);
      },

      setUntilDate(event) {
        this.set('currentModel.loading', true);

        function debounce() {
          const date = event.target ? event.target.value : event;

          if ((0, _moment.default)(date).isValid() && (0, _moment.default)(date).isBefore((0, _moment.default)().startOf('day').subtract(1, 'days'))) {
            this.set('currentModel.loading', false);
            this.set('controller.until', date);
          } else {
            this.notify.show('danger', 'Make sure that the date chosen is more than 1 day ago.');
          }
        }

        debounce(this, debounce, 3000);
      },

      setLockUntilDate(event) {
        this.set('currentModel.loading', true);

        function debounce() {
          const date = event.target ? event.target.value : event;
          this.set('controller.lock_until', date);
          this.set('currentModel.loading', false);
        }

        debounce(this, debounce, 3000);
      },

      goToAll() {
        const until = this.get('currentModel.queryParams.until');
        const lock_until = this.get('currentModel.queryParams.lock_until');
        const queryParams = {};

        if ((0, _moment.default)(until).isValid() && (0, _moment.default)(until).isBefore((0, _moment.default)().startOf('day').subtract(1, 'days'))) {
          queryParams.until = until;
        } else {
          return this.notify.show('danger', 'Make sure that the date chosen is more than 1 day ago.');
        }

        if ((0, _moment.default)(until).isValid() && (0, _moment.default)(until).isBefore((0, _moment.default)().startOf('day').subtract(1, 'days'))) {
          queryParams.lock_until = lock_until;
        }

        return this.transitionTo('main.statements.all', {
          queryParams
        });
      }

    }
  });

  _exports.default = _default;
});