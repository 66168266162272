define("backalley/pods/main/shipment-zones/index/route", ["exports", "backalley/models/shipment-zone"], function (_exports, _shipmentZone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      source: {
        refreshModel: true
      },
      destination: {
        refreshModel: true
      }
    },

    model({
      source,
      destination,
      page
    }) {
      return _shipmentZone.default.find({
        source,
        destination,
        limit: 16,
        page
      });
    },

    onImportCsv: Ember._action(function (csv) {
      const {
        data
      } = csv;
      const zones = [];
      data.forEach(zone => {
        let allDestinations = zone.destinations.split(',').map(destination => destination.trim());
        let parsedZone = {
          source: zone.source,
          ups_zone: Ember.String.dasherize(zone.ups_zone)
        };
        allDestinations.forEach(destination => zones.pushObject(_objectSpread(_objectSpread({}, parsedZone), {}, {
          destination
        })));
      });
      return Ember.RSVP.allSettled(zones.map(zone => _shipmentZone.default.create(zone))).then(result => {
        const errors = result.filter(({
          state
        }) => state === 'rejected');
        this.controller.set('bulkErrors', errors);
        return this.refresh();
      });
    })
  });

  _exports.default = _default;
});