define("backalley/mirage/factories/ticket", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // mirage/factories/category.js
  var _default = _emberCliMirage.default.Factory.extend({
    ordernumber: 1,
    // Testing purposes - doesn't exist in reality
    subject: i => `subject ${i}`,
    status: 'success',

    updated_at() {
      return new Date(_emberCliMirage.faker.date.past(Math.round(Math.random() * 10)));
    },

    created_at() {
      return new Date(_emberCliMirage.faker.date.past(Math.round(Math.random() * 10)));
    }

  });

  _exports.default = _default;
});