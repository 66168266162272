define("backalley/models/packaging/product", ["exports", "joi", "backalley/util/models", "backalley/util/request", "backalley/enums/packaging-third-party-provider", "lodash.clonedeep", "backalley/enums/packaging-product-status"], function (_exports, _joi, _models, _request, _packagingThirdPartyProvider, _lodash, _packagingProductStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _class2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const dimensionValidation = _joi.default.object({
    value: _joi.default.number().empty(null),
    unit: _joi.default.string().messages({
      'string.empty': 'dimension units is required'
    })
  });

  const variantValidations = {
    title: _joi.default.string(),
    dimensions: _joi.default.object({
      height: dimensionValidation,
      length: dimensionValidation,
      width: dimensionValidation
    }).or('height.value', 'length.value', 'width.value').messages({
      'object.missing': 'at least one dimension is required for {{#label}}'
    }),
    pack: _joi.default.object({
      price: _joi.default.object({
        amount: _joi.default.number().required(),
        currency: _joi.default.string().valid('GBP', 'EUR', 'USD')
      }),
      units: _joi.default.number()
    }),
    sku: _joi.default.string().required(),
    color: _joi.default.string().allow(''),
    country: _joi.default.string().required(),
    out_of_stock: _joi.default.boolean()
  };
  const validations = {
    name: _joi.default.string().required(),
    description: _joi.default.string().required(),
    category: _joi.default.string().required(),
    featured: _joi.default.boolean(),
    third_party_provider: _joi.default.string().valid(_packagingThirdPartyProvider.default.RAJAPACK, _packagingThirdPartyProvider.default.DS_SMITH),
    images: _joi.default.array().items({
      url: _joi.default.string().uri().required()
    }).min(1).message('at least one image is required'),
    variants: _joi.default.array().items(_objectSpread({}, variantValidations)).min(1).message('at least one variant is required')
  };
  let PackagingProduct = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = (_temp = _class2 = class PackagingProduct {
    constructor({
      _id = null,
      name = '',
      description = '',
      category = null,
      images = [],
      variants = [],
      featured = false,
      status = 'published',
      third_party_provider = null
    } = {}) {
      _initializerDefineProperty(this, "category", _descriptor, this);

      _initializerDefineProperty(this, "status", _descriptor2, this);

      this.id = _id;
      this.name = name;
      this.description = description;
      this.category = category;
      this.images = images;
      this.variants = variants;
      this.featured = featured;
      this.status = status;
      this.third_party_provider = third_party_provider;
    }

    get hasPopulatedCategory() {
      return Ember.isPresent(this.category) && Ember.isPresent(this.category.id);
    }

    get isArchived() {
      return this.status === _packagingProductStatus.default.ARCHIVED;
    }

    addVariant(payload = {}) {
      const newDimension = {
        value: null,
        unit: null
      };
      const newVariant = {
        pack: {
          price: {
            amount: null,
            currency: 'GBP'
          },
          units: null
        },
        dimensions: {
          width: _objectSpread({}, newDimension),
          height: _objectSpread({}, newDimension),
          length: _objectSpread({}, newDimension)
        },
        third_party_provider: _packagingThirdPartyProvider.default.RAJAPACK,
        color: '',
        out_of_stock: false
      };
      const deepClonedPayload = (0, _lodash.default)(payload);
      this.variants.pushObject(_objectSpread(_objectSpread({}, newVariant), deepClonedPayload));
      return newVariant;
    }

    create() {
      const data = Ember.getProperties(this, Object.keys(validations));
      return (0, _models.validateSchema)(PackagingProduct.schema, data).then(() => {
        return (0, _request.request)({
          method: 'POST',
          url: (0, _request.packagingApiURL)('products'),
          data: JSON.stringify(data)
        }).catch(err => {
          throw err.message ? err.message : err;
        });
      });
    }

    update() {
      const data = Ember.getProperties(this, Object.keys(validations));
      return (0, _models.validateSchema)(PackagingProduct.schema, data).then(() => {
        return (0, _request.request)({
          method: 'PUT',
          url: (0, _request.packagingApiURL)(`products/${this.id}`),
          data: JSON.stringify(data)
        }).catch(err => {
          throw err.message ? err.message : err;
        });
      });
    }

    archive() {
      return (0, _request.request)({
        method: 'DELETE',
        url: (0, _request.packagingApiURL)(`products/${this.id}`)
      }).then(() => this.status = _packagingProductStatus.default.ARCHIVED).catch(err => {
        throw err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
      });
    }

    static find(params = {}) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.packagingApiURL)('products'),
        data: _objectSpread({}, params)
      }).then(({
        products,
        meta
      }) => {
        return {
          products: products.map(product => new PackagingProduct(product)),
          meta
        };
      }).catch(err => {
        throw err.message ? err.message : err;
      });
    }

    static findById(id) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.packagingApiURL)(`products/${id}`)
      }).then(({
        products
      }) => {
        let [product] = products;
        return Ember.isPresent(product) ? new PackagingProduct(product) : null;
      }).catch(err => {
        throw err.message ? err.message : err;
      });
    }

  }, _defineProperty(_class2, "schema", _joi.default.object().keys(validations)), _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "category", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "archive", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "archive"), _class.prototype)), _class));
  _exports.default = PackagingProduct;
});