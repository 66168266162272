define("backalley/pods/components/google-it/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Public
    query: '',
    // Query to ask the Google God
    label: 'Search',
    // Label for component
    type: '',
    // Search types [image] => Will enable Google Image search
    keywords: '',
    // Keywords to append to query
    // Private
    link: Ember.computed('query', function () {
      const query = encodeURIComponent(this.query);
      const keywords = encodeURIComponent(this.keywords);
      const params = [`q=${query}+${keywords}`];

      if (this.type === 'image') {
        params.push('tbm=isch');
      }

      return `https://www.google.com/search?${params.join('&')}`;
    })
  });

  _exports.default = _default;
});