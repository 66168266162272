define("backalley/models/ticket", ["exports", "backalley/mixins/request", "joi", "backalley/util/models"], function (_exports, _request, _joi, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const Ticket = Ember.Object.extend(_request.default, {
    save() {
      // Default the request to update the ticket
      let validationSchema = 'put_schema';
      let request = {
        method: 'PUT',
        url: this.apiURL('zendesk/tickets')
      }; // If ticket does not have an id, the ticket is new
      // Change to POST.

      if (this.id === undefined) {
        validationSchema = 'post_schema';
        request = {
          method: 'POST',
          url: this.apiURL('zendesk/tickets/enriched')
        };
      } // Validate and then make request


      return this.validate(validationSchema).then(data => {
        return this.request(_objectSpread(_objectSpread({}, request), {}, {
          data: JSON.stringify(data)
        }));
      });
    },

    validate(schema = 'post_schema') {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(Ticket[schema], data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    }

  });
  Ticket.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  Ticket.reopenClass(_request.default, {
    put_schema: _joi.default.object().keys({}),
    post_schema: _joi.default.object().keys({
      ordernumber: _joi.default.number().required(),
      subject: _joi.default.string().required(),
      order_date: _joi.default.string(),
      order_summary: _joi.default.string(),
      requester_email: _joi.default.string().email({
        tlds: false
      }).required(),
      requester_name: _joi.default.string().required(),
      boutique_name: _joi.default.string().allow(null),
      boutique_city: _joi.default.string().allow(null),
      boutique_address: _joi.default.string().allow(null),
      boutique_primary_name: _joi.default.string().allow(null),
      product_name: _joi.default.string().allow(null),
      product_slug: _joi.default.string().allow(null),
      tags: _joi.default.array().items(_joi.default.string()),
      comment: _joi.default.string(),
      channel_specific_order_reference: _joi.default.string(),
      channel: _joi.default.string()
    }),

    /**
     * Search tickets
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    search(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('zendesk/search'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      });
    }

  });
  Ticket.reopenClass(_request.default, {
    getRequesterById(id) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`zendesk/users/${id}`)
      });
    }

  });
  var _default = Ticket;
  _exports.default = _default;
});