define("backalley/models/quote", ["exports", "backalley/mixins/request", "joi", "backalley/util/models"], function (_exports, _request, _joi, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const Quote = Ember.Object.extend(_request.default, {
    /**
     * Create new quote
     * @return {Object} Promise
     */
    post() {
      return this.validate().then(quote => {
        return this.request({
          method: 'POST',
          url: this.apiURL('quotes'),
          data: JSON.stringify(quote)
        });
      }).then(res => {
        const data = res.quotes[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    /**
     * Save quote
     * @return {Object} Promise
     */
    save() {
      return this.validate().then(quote => {
        return this.request({
          method: 'PUT',
          url: this.apiURL(`quotes/${quote._id}`),
          data: JSON.stringify(quote)
        });
      }).then(res => {
        const data = res.quotes[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    validate() {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(Quote.schema, data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    /**
     * Remove quote
     * @return {Object} Promise
     */
    remove() {
      const quote = this;
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`quotes/${quote._id}`)
      });
    }

  });
  Quote.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  Quote.reopenClass(_request.default, {
    schema: _joi.default.object().keys({
      _id: _joi.default.string(),
      comment: _joi.default.string(),
      url: _joi.default.string(),
      published: _joi.default.boolean(),
      about_us: _joi.default.boolean(),
      created_at: _joi.default.date(),
      user: _joi.default.string().allow(null),
      product: _joi.default.string().allow(null),
      boutique: _joi.default.string().allow(null),
      manufacturer: _joi.default.string().allow(null),
      material: _joi.default.string().allow(null),
      publisher: _joi.default.string().allow(null)
    }),

    create(data = {}) {
      let c = this._super(data);

      c.setOriginalData(data);
      return c;
    },

    /**
     * Find colors
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.findWithMeta(data).then(res => res.quotes);
    },

    /**
     * Find colors and returns meta too
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMeta(data) {
      return this.request({
        method: 'POST',
        headers: {
          'X-HTTP-Method-Override': 'GET'
        },
        url: this.apiURL('quotes'),
        data: JSON.stringify(_objectSpread(_objectSpread({}, data), {}, {
          plain: true
        }))
      }).then(res => {
        return {
          quotes: res.quotes.map(quote => this.create(quote)),
          meta: res.meta
        };
      });
    },

    /**
     * Find quote by id
     * @param  {String} id quote _id
     * @return {Object}    Promise
     */
    findById(id) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`quotes/${id}?populate=material,manufacturer,product,boutique,publisher,user`),
        data: {
          plain: true
        }
      }).then(res => this.create(res.quotes[0]));
    }

  });
  var _default = Quote;
  _exports.default = _default;
});