define("backalley/enums/curate-order-reservation-cancelled-reason", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    OUT_OF_STOCK: "out-of-stock",
    DISCONTINUED: "discontinued",
    UNAVAILABLE: "unavailable",
    PAYMENT_FAILED: "payment-failed",
    SUBSTITUTE_DECLINED: "substitute-declined",
    BOUTIQUE_CANCELLED: "boutique-cancelled"
  };
  _exports.default = _default;
});