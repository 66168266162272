define("backalley/pods/components/order-edit/additional-charges/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function NOOP() {}

  var _default = Ember.Component.extend({
    // Public API
    order: null,
    onSave: NOOP,
    // Private API
    classNames: ['box-view', 'box-view-refund'],
    _expandSection: false,
    orderFailedBeforePayment: Ember.computed.equal('order.status', 'fail payment'),
    actions: {
      onUpdateCustomerRefund() {
        Ember.set(this.order, 'transaction_customer_refunded.amount', this.editing_customer_refunded);
        this.onSave();
      },

      onUpdateCustomerRecharge() {
        Ember.set(this.order, 'transaction_customer_recharged.amount', this.editing_customer_recharged);
        this.onSave();
      }

    }
  });

  _exports.default = _default;
});