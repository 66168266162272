define("backalley/pods/components/notification-form/component", ["exports", "@glimmer/component", "ember-concurrency", "ember-concurrency-decorators", "backalley/models/boutique", "backalley/enums/boutique-tier", "backalley/models/notifications-centre", "backalley/enums/boutique-promotion-name-type", "backalley/models/app"], function (_exports, _component, _emberConcurrency, _emberConcurrencyDecorators, _boutique, _boutiqueTier, _notificationsCentre, _boutiquePromotionNameType, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const MAXIMUM_FILTER_BOUTIQUES = 10;
  let NotificationFormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = (_temp = class NotificationFormComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "country", _descriptor, this);

      _initializerDefineProperty(this, "notify", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _defineProperty(this, "routeOptions", [{
        name: 'Home',
        value: 'dashboard'
      }, {
        name: 'Orders',
        value: 'orders'
      }, {
        name: 'Product catalogue',
        value: 'products'
      }, {
        name: 'Insights',
        value: 'insights'
      }, {
        name: 'Settings > Order fulfilment',
        value: 'settings.order-fulfilment'
      }, {
        name: 'Settings > Operational status',
        value: 'settings.operational-status'
      }, {
        name: 'Curate',
        value: 'curate'
      }, {
        name: 'QuickShip',
        value: 'shipments'
      }, {
        name: 'Packaging',
        value: 'packaging'
      }, {
        name: 'Packaging > Products',
        value: 'packaging.products'
      }]);

      _defineProperty(this, "b2bServiceOptions", ['curate', 'packaging', 'quickship']);

      _defineProperty(this, "promotionOptions", Object.values(_boutiquePromotionNameType.default));

      _defineProperty(this, "tierOptions", [_boutiqueTier.default.STAR_BRONZE, _boutiqueTier.default.STAR_SILVER, _boutiqueTier.default.STAR_GOLD]);

      _defineProperty(this, "feedOptions", [{
        name: 'Only feed boutiques',
        value: true
      }, {
        name: 'Only non-feed boutiques',
        value: false
      }]);
    }

    get selectedFilterCountries() {
      return this.country.countries.filter(({
        _id
      }) => {
        return this.args.notification.filters.countries.includes(_id);
      });
    }

    get selectedFilterZones() {
      return this.country.zones.filter(({
        _id
      }) => {
        return this.args.notification.filters.zones.includes(_id);
      });
    }

    *searchBoutique(query) {
      yield (0, _emberConcurrency.timeout)(300);
      return _boutique.default.find({
        name: {
          $regex: `^${query.trim()}`,
          $options: 'i'
        }
      });
    }

    onChangeRoute(route) {
      var _route$value;

      this.args.notification.route = (_route$value = route === null || route === void 0 ? void 0 : route.value) !== null && _route$value !== void 0 ? _route$value : undefined;
    }

    onChangeScheduleDate(date, event) {
      const {
        value
      } = event.target;
      this.args.notification.schedule[`${date}_date`] = Ember.isEmpty(value) ? null : value;
    }

    onChangeFilterCountries(countries) {
      this.args.notification.filters.countries = countries.map(({
        _id
      }) => _id);
    }

    onChangeFilterZones(zones) {
      this.args.notification.filters.zones = zones.map(({
        _id
      }) => _id);
    }

    onChangeFilterBoutiques(boutiques) {
      if (boutiques.length > MAXIMUM_FILTER_BOUTIQUES) {
        this.notify.show('error', 'Oops! You can only select up to 10 individual boutiques. Please try removing one.', 10000);
        return;
      }

      this.args.notification.filters.boutiques = boutiques;
    }

    onChangeFilterFeedBoutiques(feedBoutiques) {
      this.args.notification.filters.feed_boutiques = Ember.isEmpty(feedBoutiques) ? [] : [feedBoutiques.value];
    }

    sendNotification() {
      return _app.default.notify('flaneur', {
        type: 'notification',
        title: this.args.notification.title,
        description: this.args.notification.body
      });
    }

    *update() {
      const now = new Date().toISOString();
      const {
        notifications
      } = yield _notificationsCentre.default.getAll();
      const index = notifications.findIndex(({
        _id
      }) => _id === this.args.notification._id);
      this.args.notification.filters.boutiques = this.args.notification.filters.boutiques.map(({
        _id
      }) => _id);
      this.args.notification.updated_by = this.session.user._id;
      this.args.notification.updated_at = now;
      notifications.splice(index, 1, this.args.notification);
      yield _notificationsCentre.default.saveAll(notifications);
      yield (0, _emberConcurrency.timeout)(250);
      yield this.router.transitionTo('main.notifications');
    }

    *save() {
      const now = new Date().toISOString();
      const userId = this.session.user._id;
      const {
        notifications
      } = yield _notificationsCentre.default.getAll();
      this.args.notification.created_by = userId;
      this.args.notification.created_at = now;
      this.args.notification.updated_by = userId;
      this.args.notification.updated_at = now;
      this.args.notification._id = _notificationsCentre.default.getNextId(notifications);
      this.args.notification.filters.boutiques = this.args.notification.filters.boutiques.map(({
        _id
      }) => _id);
      notifications.push(this.args.notification);
      yield _notificationsCentre.default.saveAll(notifications);
      yield (0, _emberConcurrency.timeout)(250);
      yield this.router.transitionTo('main.notifications');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "country", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "searchBoutique", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchBoutique"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeRoute", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeScheduleDate", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeScheduleDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeFilterCountries", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeFilterCountries"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeFilterZones", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeFilterZones"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeFilterBoutiques", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeFilterBoutiques"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeFilterFeedBoutiques", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeFilterFeedBoutiques"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "update", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = NotificationFormComponent;
});