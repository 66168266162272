define("backalley/pods/main/curate/brand-tags/new/route", ["exports", "backalley/models/curate/brand-tag"], function (_exports, _brandTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MainCurateBrandTagsNewRoute extends Ember.Route {
    model() {
      return new _brandTag.default();
    }

  }

  _exports.default = MainCurateBrandTagsNewRoute;
});