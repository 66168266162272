define("backalley/pods/components/form-button-long-press/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    buttonClass: '',
    customClass: 'button-primary',
    classNames: ['form-button', 'form-button-long-press'],
    tagName: 'span',
    onClick: undefined,
    onLongPressComplete: undefined,
    onLongPressStart: undefined,
    onLongPressAbort: undefined,
    longWait: 1000,
    title: null,
    loading: false,
    disabled: false,
    isPressed: false,
    isNormalClick: false,
    isLongPressAchieved: false,
    clickTimeout: undefined,
    longPressTimeout: undefined,
    calculatedClass: Ember.computed('buttonClass', 'isPressed', 'isDisabled', 'isLongPressAchieved', function () {
      const classes = ['button', 'button-longpress'];

      if (this.buttonClass) {
        classes.push(...this.buttonClass.split(' '));
      }

      if (this.customClass) {
        classes.push(...this.customClass.split(' '));
      }

      if (this.isPressed) {
        classes.push('pressed');
      }

      if (this.isLongPressAchieved) {
        classes.push('long-pressed');
      }

      if (this.isDisabled) {
        classes.push('disabled');
      }

      return classes.filter(v => v).join(' ');
    }),
    isDisabled: Ember.computed('loading', 'disabled', function () {
      return this.loading || this.disabled;
    }),
    longPressComplete: function () {
      this.set('isNormalClick', false);
      this.set('isLongPressAchieved', true);
    },
    longPressReset: function () {
      if (!this.isPressed) {
        return;
      }

      if (this.clickTimeout) {
        clearTimeout(this.clickTimeout);
      }

      if (this.longPressTimeout) {
        clearTimeout(this.longPressTimeout);
      }

      this.onLongPressAbort && this.onLongPressAbort();
      this.set('isPressed', false);
      this.set('isNormalClick', false);
      this.set('clickTimeout', undefined);
      this.set('longPressTimeout', undefined);
      this.set('isLongPressAchieved', false);
    },
    mouseleave: Ember._action(function () {
      if (!this.isPressed) {
        return;
      }

      this.longPressReset();
    }),
    mousedown: Ember._action(function () {
      if (this.isPressed) {
        return;
      }

      this.set('isPressed', true);
      this.set('isNormalClick', true);

      if (this.onLongPressComplete) {
        const toid1 = setTimeout(() => {
          this.set('isNormalClick', false);
        }, 100);
        const toid2 = setTimeout(() => {
          this.set('isLongPressAchieved', true);
        }, this.longWait);
        this.set('isLongPressAchieved', false);
        this.set('clickTimeout', toid1);
        this.set('longPressTimeout', toid2);
        this.onLongPressStart && this.onLongPressStart();
      }
    }),
    mouseup: Ember._action(function () {
      if (!this.isPressed) {
        return;
      }

      if (this.isNormalClick) {
        this.onClick && this.onClick();
      } else if (this.isLongPressAchieved) {
        this.onLongPressComplete && this.onLongPressComplete();
      }

      this.longPressReset();
    })
  });

  _exports.default = _default;
});