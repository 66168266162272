define("backalley/helpers/boolean-highlight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.booleanHighlight = booleanHighlight;
  _exports.default = void 0;

  function booleanHighlight(b) {
    var text;

    if (b == 'true') {
      text = '<span class="boolean boolean-yes">Yes</span>';
    } else {
      text = '<span class="boolean boolean-no">No</span>';
    }

    return Ember.String.htmlSafe(text);
  }

  var _default = Ember.Helper.helper(booleanHighlight);

  _exports.default = _default;
});