define("backalley/util/promise-all-chunked", ["exports", "backalley/util/chunk-array"], function (_exports, _chunkArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = promiseAllChunked;

  /**
   * Maps array items into promised work, splitting the array into chunks.
   * Each item in a chunk is handled by Promise.all() -- so in parallel.
   * Each chunk is processed in series, chunks are processed one by one.
   * This gives the upshot of being able to break a set of requests down
   * into some requests that happen in parallel, but then limits the hit
   * against the external service.
   */
  async function promiseAllChunked(arrayOfItems, {
    concurrency = 10
  }, mapToPromiseFn) {
    // allow the two arg version
    if (arguments.length === 2) {
      mapToPromiseFn = arguments[1];
    }

    const chunks = (0, _chunkArray.default)(arrayOfItems, concurrency);

    const getNextChunk = () => chunks.shift();

    const results = [];
    let stopFurtherChunks = false;
    let stopThisAndFurtherChunks = false; // allow the mapToPromiseFn to control the promise all chunked flow

    const pacControls = {
      stopFurtherChunks() {
        stopFurtherChunks = true;
      },

      stopThisAndFurtherChunks() {
        stopThisAndFurtherChunks = true;
      }

    };
    let chunk = getNextChunk();

    while (chunk) {
      const items = await Promise.all(chunk.map(mapToPromiseFn, pacControls));

      if (stopThisAndFurtherChunks) {
        break;
      }

      results.push(...items);

      if (stopFurtherChunks) {
        break;
      }

      chunk = getNextChunk();
    }

    return results;
  }
});