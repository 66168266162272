define("backalley/pods/main/boutique-categories/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MsYI/Lac",
    "block": "{\"symbols\":[],\"statements\":[[4,\"component\",[[28,\"hot-load\",[\"page-heading\",[23,0,[]],[24,[\"page-heading\"]],\"page-heading\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"fa\"],[[23,0,[]],\"page-heading\",[24,[\"page-heading\"]],false,true,\"icon-list\"]],{\"statements\":[[0,\"  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"main.boutique_categories\"]],{\"statements\":[[0,\"Boutique categories\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[1,[24,[\"model\",\"boutiqueCategory\",\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"page-title\",[\"Boutique categories\"],null],false],[0,\"\\n\"],[1,[28,\"page-title\",[[24,[\"model\",\"boutiqueCategory\",\"name\"]]],null],false],[0,\"\\n\\n\"],[1,[28,\"component\",[[28,\"hot-load\",[\"forms/boutique-category\",[23,0,[]],[24,[\"forms/boutique-category\"]],\"forms/boutique-category\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"boutiqueCategory\",\"onDelete\",\"onSave\"],[[23,0,[]],\"forms/boutique-category\",[24,[\"forms/boutique-category\"]],false,true,[24,[\"model\",\"boutiqueCategory\"]],[28,\"route-action\",[\"remove\"],null],[28,\"route-action\",[\"save\"],null]]]],false],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/boutique-categories/edit/template.hbs"
    }
  });

  _exports.default = _default;
});