define("backalley/pods/components/form-cloudinary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d50nfDd3",
    "block": "{\"symbols\":[\"file\"],\"statements\":[[7,\"input\",true],[10,\"name\",\"file\"],[10,\"style\",\"position: absolute; visibility: hidden;\"],[11,\"multiple\",[22,\"multiple\"]],[11,\"accept\",[22,\"fileTypes\"]],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"handleFiles\"],null]],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isUploading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"uploader-progress\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"files\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"uploader-progress-item\"],[8],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"uploader-progress-name\"],[8],[0,\"\\n          \"],[7,\"span\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\" (\"],[1,[23,1,[\"progress\"]],false],[0,\"%)\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",\"image-uploader\"],[12,\"ondrop\",[28,\"action\",[[23,0,[]],\"onDropzoneDrop\"],null]],[12,\"ondragover\",[28,\"action\",[[23,0,[]],\"onDropzoneDragOver\"],null]],[3,\"action\",[[23,0,[]],\"openUploadDialog\"]],[8],[0,\"\\n    \"],[7,\"a\",true],[10,\"class\",\"button button-primary\"],[8],[0,\"\\n      Choose file\"],[1,[28,\"if\",[[24,[\"multiple\"]],\"s\"],null],false],[0,\" to upload\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/form-cloudinary/template.hbs"
    }
  });

  _exports.default = _default;
});