define("backalley/pods/main/paths-and-lanes/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EpI5B0MO",
    "block": "{\"symbols\":[\"HotLoadPathsAndLanes___New5x7deovbydg\"],\"statements\":[[1,[28,\"page-title\",[\"Paths and Lanes\"],null],false],[0,\"\\n\"],[1,[28,\"page-title\",[\"New\"],null],false],[0,\"\\n\\n\"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"PathsAndLanes/New\",[23,0,[]],\"PathsAndLanes/New\",\"PathsAndLanes/New\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"PathsAndLanes/New\",\"PathsAndLanes/New\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[],[[],[]]]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/paths-and-lanes/new/template.hbs"
    }
  });

  _exports.default = _default;
});