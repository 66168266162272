define("backalley/pods/components/curate-order-form/add-reservation-modal/component", ["exports", "joi", "@glimmer/component", "ember-component-css/pod-names", "backalley/models/curate/product", "backalley/enums/curate-product-status"], function (_exports, _joi, _component, _podNames, _product, _curateProductStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurateOrderFormAddReservationModalComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = (_temp = class CurateOrderFormAddReservationModalComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "notify", _descriptor, this);

      _defineProperty(this, "styleNamespace", _podNames.default['curate-order-form/add-reservation-modal']);

      _defineProperty(this, "imageTransformation", _product.default.imageTransformation);

      _initializerDefineProperty(this, "searchQuery", _descriptor2, this);

      _initializerDefineProperty(this, "searchResults", _descriptor3, this);

      _initializerDefineProperty(this, "searchPage", _descriptor4, this);

      _initializerDefineProperty(this, "searchPageTotal", _descriptor5, this);

      _initializerDefineProperty(this, "selectedProduct", _descriptor6, this);

      _initializerDefineProperty(this, "selectedStockLine", _descriptor7, this);

      _initializerDefineProperty(this, "selectedQuantity", _descriptor8, this);
    }

    get validations() {
      const schema = _joi.default.object({
        product: _joi.default.object().required(),
        stockLine: _joi.default.object().required(),
        quantity: _joi.default.number().positive().required()
      });

      return schema.validate({
        product: this.selectedProduct,
        stockLine: this.selectedStockLine,
        quantity: this.selectedQuantity
      }, {
        abortEarly: false
      });
    }

    get isFormValid() {
      return Ember.isEmpty(this.validations.error);
    }

    get alreadyExistsReservation() {
      return this.args.order.reservations.some(({
        product: {
          _id: productId
        },
        stock_line: {
          sku: stockLineSku
        }
      }) => {
        var _this$selectedProduct, _this$selectedStockLi;

        return productId === ((_this$selectedProduct = this.selectedProduct) === null || _this$selectedProduct === void 0 ? void 0 : _this$selectedProduct._id) && stockLineSku === ((_this$selectedStockLi = this.selectedStockLine) === null || _this$selectedStockLi === void 0 ? void 0 : _this$selectedStockLi.sku);
      });
    }

    async searchProducts(searchQuery) {
      this.searchQuery = searchQuery;

      if (Ember.isEmpty(searchQuery)) {
        this.searchResults = [];
      }

      const {
        products,
        meta: {
          page_total: pageTotal
        }
      } = await _product.default.find({
        free_text_search: searchQuery,
        brand: this.args.order.brand._id,
        status: _curateProductStatus.default.PUBLISHED,
        page: this.searchPage,
        limit: 4
      });
      this.searchResults = products;
      this.searchPageTotal = pageTotal;
    }

    changeSearchPage(page) {
      this.searchPage = page;
      this.searchProducts(this.searchQuery);
    }

    selectProduct(product) {
      this.selectedProduct = product;
      this.selectedStockLine = product.stock_lines[0];
    }

    clearProduct() {
      this.selectedProduct = undefined;
      this.selectedStockLine = undefined;
    }

    addReservation() {
      return this.args.order.addReservation({
        product_id: this.selectedProduct._id,
        sku: this.selectedStockLine.sku,
        quantity: this.selectedQuantity
      }).then(() => this.args.onClose()).catch(() => this.notify.show('error', 'Oops! There was an error adding a reservation to the order. Please try again.'));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "searchQuery", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "searchResults", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "searchPage", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "searchPageTotal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedProduct", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedStockLine", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selectedQuantity", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "searchProducts", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "searchProducts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSearchPage", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "changeSearchPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectProduct", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "selectProduct"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearProduct", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "clearProduct"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addReservation", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "addReservation"), _class.prototype)), _class));
  _exports.default = CurateOrderFormAddReservationModalComponent;
});