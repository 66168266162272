define("backalley/models/shipment-zone", ["exports", "joi", "backalley/util/models", "backalley/util/request"], function (_exports, _joi, _models, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ShipmentZone {
    constructor({
      _id,
      source,
      destination,
      zone,
      ups_zone
    }) {
      this.source = source;
      this.destination = destination;
      this.zone = zone;
      this.upsZone = ups_zone;
      this.id = _id;
    }

    static create(params = {}) {
      return (0, _models.validateSchema)(this.schema, params).then(() => {
        return (0, _request.request)({
          method: 'POST',
          url: (0, _request.apiURL)('shipment_zones'),
          data: JSON.stringify(params)
        });
      });
    }

    static find(params = {}) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.apiURL)('shipment_zones'),
        data: params
      }).then(({
        shipment_zones,
        meta
      }) => {
        return {
          shipmentZones: shipment_zones.map(shipmentZone => new ShipmentZone(shipmentZone)),
          meta
        };
      });
    }

    static findById(id) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.apiURL)(`shipment_zones/${id}`)
      }).then(({
        shipment_zones
      }) => {
        let [shipmentZone] = shipment_zones;
        return Ember.isPresent(shipmentZone) ? new ShipmentZone(shipmentZone) : null;
      });
    }

    update() {
      return (0, _request.request)({
        method: 'PUT',
        url: (0, _request.apiURL)(`shipment_zones/${this.id}`),
        data: JSON.stringify({
          ups_zone: this.upsZone
        })
      });
    }

  }

  _exports.default = ShipmentZone;

  _defineProperty(ShipmentZone, "schema", _joi.default.object().keys({
    source: _joi.default.string().length(2).required(),
    destination: _joi.default.string().length(2).required(),
    ups_zone: _joi.default.string().min(0).required()
  }));
});