define("backalley/models/discount", ["exports", "backalley/mixins/request", "joi", "backalley/util/models", "backalley/enums/discount-channel"], function (_exports, _request, _joi, _models, _discountChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const validations = {
    code: _joi.default.string().min(3).max(200).required().label('code').messages({
      'string.min': 'must be at least {#limit} characters'
    }),
    type: _joi.default.string().regex(/[amount|percentage|free_shipping|free_delivery]/).label('type').messages({
      'string.regex': 'type must be one of the following: amount, percentage, free_shipping, free_delivery'
    }),
    channel: _joi.default.string().allow(...Object.values(_discountChannel.default)),
    currency: _joi.default.alternatives().try(_joi.default.string(), _joi.default.object({
      _id: _joi.default.string().required()
    }).unknown()).required(),
    value: _joi.default.number().min(0),
    notes: _joi.default.string().min(0).required(),
    minimum_value: _joi.default.number().required().min(0),
    single_usage: _joi.default.boolean(),
    once_per_email: _joi.default.boolean(),
    allow_subscribers: _joi.default.boolean(),
    allowed_emails: _joi.default.array().items(_joi.default.string().email({
      tlds: false
    })),
    allowed_manufacturers: _joi.default.array(),
    allowed_tags: _joi.default.array(),
    first_time: _joi.default.boolean(),
    limit_to_x_usage: _joi.default.number().min(0),
    expired_at: _joi.default.date().messages({
      date: 'expired_at must be a date eg: "2015-01-31" or "2015-01-31 13:34:12"'
    }),
    _id: _joi.default.string(),
    created_at: _joi.default.date(),
    updated_at: _joi.default.date()
  };
  const Discount = Ember.Object.extend(_request.default, {
    /**
     * set default
     * @type {String}
     */
    raw_allowed_emails: Ember.computed(function () {
      const allowed_emails = this.allowed_emails || [];
      return allowed_emails.join('\n');
    }),

    /**
     * Set real value after parsing input
     */
    parseRawAllowedEmails: Ember.observer('raw_allowed_emails', function () {
      const input = this.raw_allowed_emails || ''; // create an array from a comma/new line seperated list of emails

      const parsed = String(input).split(/,|\r|\n|\s/).map(email => email.trim()).filter(email => email.length > 0);
      this.set('allowed_emails', parsed);
    }),

    /**
     * Create new discount
     * @return {Object} Promise
     */
    post() {
      return this.validate().then(discount => {
        return this.request({
          method: 'POST',
          url: this.apiURL('discounts', 'v2'),
          data: JSON.stringify(discount)
        });
      }).then(res => {
        const data = res.discounts[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    /**
     * Save discount
     * @return {Object} Promise
     */
    save() {
      return this.validate().then(data => (0, _models.getChanges)(this._orig, data, Object.keys(validations))).then(discount => {
        return this.request({
          method: 'PUT',
          url: this.apiURL(`discounts/${discount._id}`, 'v2'),
          data: JSON.stringify(discount)
        });
      }).then(res => {
        const data = res.discounts[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    validate() {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(Discount.schema, data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    /**
     * Remove discount
     * @return {Object} Promise
     */
    remove() {
      const discount = this;
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`discounts/${discount._id}`)
      });
    }

  });
  Discount.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  Discount.reopenClass(_request.default, {
    schema: _joi.default.object().keys(validations),

    create(data = {}) {
      let c = this._super(data);

      c.setOriginalData(data);
      return c;
    },

    /**
     * Find discounts
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.findWithMeta(data).then(res => res.discounts);
    },

    /**
     * Find discounts and returns meta too
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMeta(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('discounts', 'v3'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => {
        return {
          discounts: res.discounts.map(discount => this.create(discount)),
          meta: res.meta
        };
      });
    },

    /**
     * Find discount by id
     * @param  {String} id discount _id
     * @return {Object}    Promise
     */
    findById(id) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`discounts`, 'v3'),
        data: {
          plain: true,
          _id: id,
          populate: ['currency', 'allowed_manufacturers', 'allowed_tags'].join(',')
        }
      }).then(res => this.create(res.discounts[0]));
    }

  });
  var _default = Discount;
  _exports.default = _default;
});