define("backalley/pods/main/campaigns/index/route", ["exports", "backalley/models/campaign"], function (_exports, _campaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MainCampaignsRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        },
        sort: {
          refreshModel: true
        }
      });
    }

    model({
      page,
      limit,
      sort
    }) {
      return _campaign.default.find({
        page,
        limit,
        sort
      });
    }

  }

  _exports.default = MainCampaignsRoute;
});