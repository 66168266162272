define("backalley/pods/main/curate/products/index/controller", ["exports", "ember-concurrency", "ember-concurrency-decorators", "backalley/models/curate/brand", "backalley/enums/curate-product-status", "backalley/models/curate/product"], function (_exports, _emberConcurrency, _emberConcurrencyDecorators, _brand, _curateProductStatus, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MainCurateProductsController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = (_temp = class MainCurateProductsController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _defineProperty(this, "queryParams", ['page', 'limit', 'sort', 'brand', 'query', 'status']);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "limit", 24);

      _defineProperty(this, "sort", '-created_at');

      _defineProperty(this, "statusOptions", Object.values(_curateProductStatus.default));

      _defineProperty(this, "imageTransformation", _product.default.imageTransformation);

      _initializerDefineProperty(this, "brand", _descriptor2, this);

      _initializerDefineProperty(this, "status", _descriptor3, this);
    }

    *searchCurateBrand(query) {
      yield (0, _emberConcurrency.timeout)(500);
      const {
        brands
      } = yield _brand.default.find({
        name: query
      });
      return brands;
    }

    get selectedCurateBrand() {
      return this.brand ? _brand.default.findById(this.brand) : null;
    }

    selectCurateBrand(brand) {
      return this.router.transitionTo({
        queryParams: {
          page: 1,
          brand: brand ? brand._id : undefined
        }
      });
    }

    *archiveProduct(product) {
      if (!confirm('Are you sure you want to permanently archive this Curate\'s product?')) {
        return;
      }

      yield (0, _emberConcurrency.timeout)(500);
      yield product.archive();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "searchCurateBrand", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchCurateBrand"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectCurateBrand", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectCurateBrand"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "archiveProduct", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "archiveProduct"), _class.prototype)), _class));
  _exports.default = MainCurateProductsController;
});