define("backalley/pods/main/countries/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'q', 'sort'],
    page: 1,
    q: '',
    sort: {
      label: 'Name: A-Z',
      value: 'name'
    }
  });

  _exports.default = _default;
});