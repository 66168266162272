define("backalley/pods/components/iframe-preview/component", ["exports", "ember-keyboard"], function (_exports, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    classNames: ['iframe-preview'],
    globals: Ember.inject.service(),
    isOpen: Ember.computed.alias('globals.iframePreview.isOpen'),
    isOpenChanged: Ember.observer('isOpen', function () {
      const isOpen = this.isOpen;
      this.set('keyboardActivated', isOpen);
    }),
    onKeyboardDismiss: Ember.on((0, _emberKeyboard.keyDown)('Escape'), function () {
      this.globals.closeIframePreview();
    }),
    actions: {
      onOpenNewTab() {
        const src = this.get('globals.iframePreview.src');
        window.open(src, '_blank');
        this.globals.closeIframePreview();
      },

      onCloseClick() {
        this.globals.closeIframePreview();
      }

    }
  });

  _exports.default = _default;
});