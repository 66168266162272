define("backalley/pods/main/curate/products/upload/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MainCurateProductsUploadRoute extends Ember.Route {
    resetController(controller, isExiting, transition) {
      if (isExiting && transition.targetName !== 'error') {
        controller.setProperties({
          uploadStatus: null,
          products: [],
          errors: [],
          warnings: []
        });
      }
    }

  }

  _exports.default = MainCurateProductsUploadRoute;
});