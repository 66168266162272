define("backalley/pods/main/sizes/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'q', 'sort'],
    page: 1,
    q: '',
    sort: {
      label: 'Date: last updated',
      value: '-updated_at'
    }
  });

  _exports.default = _default;
});