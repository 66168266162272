define("backalley/models/frame-tag", ["exports", "joi", "backalley/util/request", "backalley/enums/frame-tag-type", "backalley/util/models"], function (_exports, _joi, _request, _frameTagType, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const validations = {
    name: _joi.default.string().required(),
    type: _joi.default.string().valid(...Object.values(_frameTagType.default)).required(),
    image: _joi.default.object({
      url: _joi.default.string().uri()
    })
  };

  class FrameTag {
    constructor({
      _id = null,
      name = '',
      slug,
      created_at,
      image,
      type
    } = {}) {
      this._id = _id;
      this.id = _id;
      this.name = name;
      this.slug = slug;
      this.type = type;
      this.image = image;
      this.created_at = created_at;
    }

    async create() {
      const data = Ember.getProperties(this, Object.keys(validations));

      try {
        await (0, _models.validateSchema)(FrameTag.schema, data);
        const {
          frame_tags: frameTags
        } = await (0, _request.request)({
          method: 'POST',
          url: (0, _request.apiURL)('frame_tags'),
          data: JSON.stringify(data)
        });
        const frameTag = frameTags[0];
        Ember.setProperties(this, frameTag);
        return this;
      } catch (e) {
        throw e.responseJSON ? e.responseJSON.errors[0].detail : e.message;
      }
    }

    static find(params = {}) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.apiURL)('frame_tags'),
        data: _objectSpread({}, params)
      }).then(({
        frame_tags: frameTags,
        meta
      }) => {
        return {
          frameTags: frameTags.map(frameTag => new FrameTag(frameTag)),
          meta
        };
      }).catch(err => {
        throw err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
      });
    }

  }

  _exports.default = FrameTag;

  _defineProperty(FrameTag, "schema", _joi.default.object().keys(_objectSpread({}, validations)));
});