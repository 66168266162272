define("backalley/pods/components/create-publisherlink/component", ["exports", "backalley/util/default-to", "backalley/models/publisher", "backalley/models/publisherlink", "backalley/util/escape-regex"], function (_exports, _defaultTo, _publisher, _publisherlink, _escapeRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    classNames: ['create-publisherlink'],
    // Public API
    user: (0, _defaultTo.default)({}),
    // Current user
    publisher: (0, _defaultTo.default)(null),
    // Current publisher
    entityId: (0, _defaultTo.default)(null),
    // Id of entity
    entityType: (0, _defaultTo.default)(null),
    // [material, product, boutique, manufacturer]
    list: (0, _defaultTo.default)([]),
    published: true,
    // Published flag
    comment: (0, _defaultTo.default)(null),
    // Publisherlink comment
    url: (0, _defaultTo.default)(null),
    // Publisherlink comment
    created_at: (0, _defaultTo.default)(new Date()),
    showForm: false,
    showHeadline: true,
    canBeAboutUs: false,
    // Private API
    loading: false,
    _publishers: (0, _defaultTo.default)([]),
    emptyList: Ember.computed.empty('list'),

    didReceiveAttrs() {
      this._super(...arguments);

      this._fetchContent();
    },

    _fetchContent() {
      const entityType = this.entityType;
      const data = {
        populate: 'publisher',
        [entityType]: this.entityId,
        sort: '-created_at'
      };
      this.set('loading', true);
      return _publisherlink.default.find(data).then(res => this.set('list', res)).finally(() => this.set('loading', false));
    },

    actions: {
      toggleShowForm() {
        this.toggleProperty('showForm');
      },

      searchPublishers(query) {
        if (Ember.isEmpty(query)) {
          return;
        }

        return _publisher.default.find({
          name: {
            $regex: `^${(0, _escapeRegex.default)(query)}`,
            $options: 'i'
          },
          limit: 10
        }).then(publishers => this.set('_publishers', publishers));
      },

      selectPublisher(publisher) {
        this.set('publisher', publisher);
      },

      create() {
        const publisherlink = _publisherlink.default.create({
          publisher: this.publisher._id,
          comment: this.comment,
          published: this.published
        });

        if (this.url) {
          publisherlink.url = this.url;
        }

        if (this.created_at) {
          publisherlink.created_at = this.created_at;
        }

        publisherlink.set(this.entityType, this.entityId);
        this.set('loading', true);
        publisherlink.post().then(() => {
          this.set('comment', '');
          this.set('url', '');
          this.set('created_at', new Date());
          this.set('showForm', false); // Reload information

          this._fetchContent();

          this.notify.show('success', 'Your publisherlink has been added!');
        }).catch(err => this.notify.show('danger', err)).finally(() => this.set('loading', false));
      },

      remove(id) {
        const publisherlink = _publisherlink.default.create({
          _id: id
        });

        if (confirm('Are you sure you want to delete this publisherlink?')) {
          publisherlink.remove().then(() => this._fetchContent());
        }
      }

    }
  });

  _exports.default = _default;
});