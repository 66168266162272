define("backalley/enums/path-and-lane-release-details-status-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    CREATED: 'created',
    ERRORED: 'errored',
    PROCESSING_ITEMS: 'processing-items',
    PROCESSING_RULES: 'processing-rules',
    STABLE: 'stable',
    ACTIVE: 'active',
    CRITICAL: 'critical',
    DECOMMISSIONING: 'decommissioning',
    ARCHIVED: 'archived'
  };
  _exports.default = _default;
});