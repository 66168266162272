define("backalley/pods/components/paths-and-lanes/new/upload/validation", ["exports", "joi", "backalley/enums/shipping-path-variant", "backalley/enums/carrier-lane-variant"], function (_exports, _joi, _shippingPathVariant, _carrierLaneVariant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validatePathsFile = validatePathsFile;
  _exports.validateShippingPaths = validateShippingPaths;
  _exports.validateLanesFile = validateLanesFile;
  _exports.validateCarrierLanes = validateCarrierLanes;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const carriersAllowed = {
    UPS: 'UPS',
    DHL: 'DHLExpress'
  };
  const providersAllowed = ['Easypost', 'upsap'];
  const currencies = ['GBP', 'EUR', 'USD', 'AUD', 'CAD', 'CHF', 'DKK', 'SEK']; // TODO use enum. Unsure the current currency enum can be extended...

  const {
    shippingPathSchema,
    pathSchema,
    carrierLaneSchema,
    laneSchema
  } = getSchemas();

  function getSchemas() {
    const excludeSheetErrorsRegex = / *^(?!#ERROR!|#N\/A|#DIV\/0|#VALUE!|#REF!|#NAME\?|#NUM!|#NULL!).*/;
    const shippingPathKeys = {
      source: _joi.default.string().required(),
      destination: _joi.default.string().required(),
      source_country_id: _joi.default.string().required(),
      destination_country_id: _joi.default.string().required(),
      source_country_code: _joi.default.string().required(),
      source_country_code_iso_2: _joi.default.string().required(),
      destination_country_code: _joi.default.string().required(),
      destination_country_code_iso_2: _joi.default.string().required(),
      path_ids: _joi.default.string(),
      path_codes: _joi.default.string().required(),
      is_standard_disabled: _joi.default.boolean().required(),
      unit_cost: _joi.default.number().required(),
      is_domestic: _joi.default.boolean(),
      has_cost_cap: _joi.default.boolean().required(),
      cost_cap: _joi.default.number(),
      free_shipping_available: _joi.default.boolean().required(),
      free_shipping_threshold: _joi.default.number().required(),
      free_shipping_threshold_currency: _joi.default.string(),
      transit_time_min_days: _joi.default.number().required(),
      transit_time_max_days: _joi.default.number().required(),
      additional_product_margin: _joi.default.number().min(0).required(),
      additional_shipping_fee: _joi.default.number().min(0).optional(),
      // @TODO should be required once this field is fully supported
      additional_shipping_fee_currency: _joi.default.string().optional(),
      // @TODO should be required once this field is fully supported,
      cost_curve_base_rate: _joi.default.number().min(0).required(),
      has_express_shipping: _joi.default.boolean(),
      has_click_and_collect: _joi.default.boolean(),
      express_shipping_unit_cost: _joi.default.number(),
      express_shipping_transit_min_days_taken: _joi.default.number(),
      express_shipping_transit_max_days_taken: _joi.default.number(),
      has_marketplace_rule: _joi.default.boolean().required(),
      incoterm: _joi.default.string().allow('').required()
    };
    Object.values(_shippingPathVariant.default).forEach(shippingPathVariant => {
      shippingPathKeys[shippingPathVariant] = {
        enabled: _joi.default.boolean(),
        unit_cost: _joi.default.number().integer(),
        has_cost_cap: _joi.default.boolean(),
        cost_cap: _joi.default.number().integer(),
        transit_time_min_days: _joi.default.number().integer(),
        transit_time_max_days: _joi.default.number().integer()
      };
    });

    const shippingPathSchema = _joi.default.object().keys(shippingPathKeys);

    const pathSchema = _joi.default.object().keys({
      ignore: _joi.default.boolean().allow(''),
      source_country_code: _joi.default.string().length(3).uppercase().pattern(/^[A-Z]+$/).required(),
      destination_country_code: _joi.default.string().length(3).uppercase().pattern(/^[A-Z]+$/).required(),
      enabled: _joi.default.boolean().required(),
      is_standard_disabled: _joi.default.boolean().required(),
      path_codes: _joi.default.string().length(7).required(),
      unit_cost: _joi.default.number().integer().min(0).required(),
      is_domestic: _joi.default.boolean().required(),
      has_cost_cap: _joi.default.boolean().required(),
      cost_cap: _joi.default.when('has_cost_cap', {
        is: true,
        then: _joi.default.number().integer().min(1).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      free_shipping_available: _joi.default.boolean().required(),
      free_shipping_threshold: _joi.default.when('free_shipping_available', {
        is: true,
        then: _joi.default.number().integer().min(1).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      free_shipping_threshold_currency: _joi.default.when('free_shipping_threshold', {
        is: _joi.default.number(),
        then: _joi.default.valid(...currencies).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      transit_time_min_days: _joi.default.when('enabled', {
        is: true,
        then: _joi.default.number().integer().min(1).max(60).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      transit_time_max_days: _joi.default.when('enabled', {
        is: true,
        then: _joi.default.number().integer().min(_joi.default.ref('transit_time_min_days')).max(60).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      additional_product_margin: _joi.default.when('enabled', {
        is: true,
        then: _joi.default.number().min(0).required(),
        otherwise: _joi.default.number().min(0).allow('', null).required()
      }),
      additional_shipping_fee: _joi.default.when('enabled', {
        is: true,
        then: _joi.default.number().min(0).required(),
        otherwise: _joi.default.number().min(0).allow('', null).required()
      }),
      additional_shipping_fee_currency: _joi.default.when('additional_shipping_fee', {
        is: _joi.default.number(),
        then: _joi.default.valid(...currencies).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      cost_curve_base_rate: _joi.default.when('enabled', {
        is: true,
        then: _joi.default.number().integer().required(),
        otherwise: _joi.default.number().integer().allow('', null).required()
      }),
      has_click_and_collect: _joi.default.boolean().required(),
      express_enabled: _joi.default.boolean().required(),
      express_unit_cost: _joi.default.when('express_enabled', {
        is: true,
        then: _joi.default.number().integer().min(_joi.default.ref('unit_cost')).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      express_has_cost_cap: _joi.default.when('express_enabled', {
        is: true,
        then: _joi.default.boolean().required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      express_cost_cap: _joi.default.when('express_has_cost_cap', {
        is: true,
        then: _joi.default.number().min(1).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      express_transit_time_min_days: _joi.default.when('express_enabled', {
        is: true,
        then: _joi.default.number().integer().min(1).max(_joi.default.ref('transit_time_min_days')).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      express_transit_time_max_days: _joi.default.when('express_enabled', {
        is: true,
        then: _joi.default.number().integer().min(_joi.default.ref('express_transit_time_min_days')).max(_joi.default.ref('transit_time_max_days')).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      christmas_enabled: _joi.default.boolean().required(),
      christmas_unit_cost: _joi.default.when('christmas_enabled', {
        is: true,
        then: _joi.default.number().integer().min(0).integer(),
        otherwise: _joi.default.valid('', null).required()
      }),
      christmas_has_cost_cap: _joi.default.when('christmas_enabled', {
        is: true,
        then: _joi.default.boolean().required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      christmas_cost_cap: _joi.default.when('christmas_has_cost_cap', {
        is: true,
        then: _joi.default.number().min(1).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      christmas_transit_time_min_days: _joi.default.when('christmas_enabled', {
        is: true,
        then: _joi.default.number().integer().min(1).max(60).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      christmas_transit_time_max_days: _joi.default.when('christmas_enabled', {
        is: true,
        then: _joi.default.number().integer().min(_joi.default.ref('christmas_transit_time_min_days')).max(60).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      upsap_enabled: _joi.default.boolean(),
      upsap_unit_cost: _joi.default.when('upsap_enabled', {
        is: true,
        then: _joi.default.number().integer().min(0).integer(),
        otherwise: _joi.default.valid('', null).required()
      }),
      upsap_has_cost_cap: _joi.default.when('upsap_enabled', {
        is: true,
        then: _joi.default.boolean().required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      upsap_cost_cap: _joi.default.when('upsap_has_cost_cap', {
        is: true,
        then: _joi.default.number().min(1).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      upsap_transit_time_min_days: _joi.default.when('upsap_enabled', {
        is: true,
        then: _joi.default.number().integer().min(1).max(60).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      upsap_transit_time_max_days: _joi.default.when('upsap_enabled', {
        is: true,
        then: _joi.default.number().integer().min(_joi.default.ref('upsap_transit_time_min_days')).max(60).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      has_marketplace_rule: _joi.default.boolean().required(),
      incoterm: _joi.default.string().regex(excludeSheetErrorsRegex).allow('', null).required(),
      // Papaparse.parse adds the key '__parsed_extra' automatically if there are more fields than the number of headers in a row
      __parsed_extra: _joi.default.forbidden().label('more fields than headers')
    });

    const carrierLaneKeys = {
      source: _joi.default.string().optional(),
      destination: _joi.default.string().optional(),
      source_country_id: _joi.default.string().required(),
      source_country_code: _joi.default.string().required(),
      source_country_code_iso_2: _joi.default.string().optional(),
      destination_country_id: _joi.default.string().optional(),
      destination_country_code: _joi.default.string().required(),
      destination_country_code_iso_2: _joi.default.string().optional(),
      enabled: _joi.default.boolean().required(),
      is_domestic: _joi.default.boolean().optional(),
      path_codes: _joi.default.string().required(),
      provider: _joi.default.string().required(),
      carrier: _joi.default.string().required(),
      service_id: [_joi.default.string().required(), _joi.default.number().integer().required()],
      rate_2kg: _joi.default.string().required(),
      // @TODO change to number() when Promenade allows it
      manual_booking_only: _joi.default.boolean().required(),
      no_fallback: _joi.default.boolean().required(),
      carrier_account: _joi.default.string().required(),
      force_easypost_return: _joi.default.boolean().required(),
      max_allowed_pence: _joi.default.number().integer().min(1).allow('', null).required(),
      skip_pickup_failure: _joi.default.boolean().required()
    };
    Object.values(_carrierLaneVariant.default).forEach(carrierLaneVariant => {
      carrierLaneKeys[carrierLaneVariant] = {
        provider: _joi.default.valid(...providersAllowed).required(),
        carrier: _joi.default.string().required(),
        service_id: [_joi.default.string(), _joi.default.number().integer()],
        max_allowed_pence: _joi.default.number().integer().min(1).allow('', null),
        carrier_account: [_joi.default.string().regex(excludeSheetErrorsRegex).required(), _joi.default.number().unsafe().required()]
      };
    });

    const carrierLaneSchema = _joi.default.object().keys(carrierLaneKeys);

    const laneSchema = _joi.default.object().keys({
      ignore: _joi.default.boolean().allow(''),
      source_country_code: _joi.default.string().length(3).uppercase().pattern(/^[A-Z]+$/).required(),
      destination_country_code: _joi.default.string().length(3).uppercase().pattern(/^[A-Z]+$/).required(),
      enabled: _joi.default.boolean().required(),
      path_codes: _joi.default.string().length(7).required(),
      provider: _joi.default.when('enabled', {
        is: true,
        then: _joi.default.string().valid(...providersAllowed).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      carrier: _joi.default.when('enabled', {
        is: true,
        then: _joi.default.string().valid(...Object.values(carriersAllowed)).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      service_id: _joi.default.alternatives(_joi.default.string(), _joi.default.number().integer(), _joi.default.valid('', null)),
      rate_2kg: _joi.default.when('enabled', {
        is: true,
        then: _joi.default.number().integer().min(1).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      manual_booking_only: _joi.default.when('enabled', {
        is: true,
        then: _joi.default.boolean().required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      whitelist: _joi.default.valid('', null),
      // "whitelist_base" is currently not implemented
      whitelist_base: _joi.default.valid('', null),
      // "whitelist_base_base" is currently not implemented
      no_fallback: _joi.default.when('enabled', {
        is: true,
        then: _joi.default.boolean().required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      carrier_account: _joi.default.when('enabled', {
        is: true,
        then: _joi.default.string().regex(excludeSheetErrorsRegex).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      force_easypost_return: _joi.default.when('enabled', {
        is: true,
        then: _joi.default.boolean().required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      skip_pickup_failure: _joi.default.when('enabled', {
        is: true,
        then: _joi.default.boolean().required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      max_allowed_pence: _joi.default.number().integer().min(1).allow('', null).required(),
      christmas_enabled: _joi.default.when('enabled', {
        is: true,
        then: _joi.default.boolean().required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      christmas_provider: _joi.default.when('christmas_enabled', {
        is: true,
        then: _joi.default.valid(...providersAllowed).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      christmas_carrier: _joi.default.when('christmas_enabled', {
        is: true,
        then: _joi.default.valid(...Object.values(carriersAllowed)).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      christmas_service_id: _joi.default.alternatives(_joi.default.string(), _joi.default.number().integer(), _joi.default.valid('', null)),
      christmas_carrier_account: _joi.default.when('christmas_enabled', {
        is: true,
        then: _joi.default.string().regex(excludeSheetErrorsRegex).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      christmas_max_allowed_pence: _joi.default.number().integer().min(1).allow('', null).required(),
      express_enabled: _joi.default.when('enabled', {
        is: true,
        then: _joi.default.boolean().required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      express_provider: _joi.default.when('express_enabled', {
        is: true,
        then: _joi.default.valid(...providersAllowed).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      express_carrier: _joi.default.when('express_enabled', {
        is: true,
        then: _joi.default.valid(...Object.values(carriersAllowed)).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      express_service_id: _joi.default.alternatives(_joi.default.string(), _joi.default.number().integer(), _joi.default.valid('', null)),
      express_max_allowed_pence: _joi.default.number().integer().min(1).allow('', null).required(),
      express_carrier_account: _joi.default.when('express_enabled', {
        is: true,
        then: _joi.default.string().regex(excludeSheetErrorsRegex).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      upsap_enabled: _joi.default.when('enabled', {
        is: true,
        then: _joi.default.boolean().required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      upsap_service_id: _joi.default.alternatives(_joi.default.string(), _joi.default.number().integer(), _joi.default.valid('', null)),
      upsap_carrier_account: _joi.default.when('upsap_enabled', {
        is: true,
        then: [_joi.default.string().regex(excludeSheetErrorsRegex).required(), _joi.default.number().unsafe().required()],
        otherwise: _joi.default.valid('', null).required()
      }),
      upsap_max_allowed_pence: _joi.default.number().integer().min(1).allow('', null).required(),
      extra_shipping_enabled: _joi.default.when('enabled', {
        is: true,
        then: _joi.default.boolean().required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      extra_shipping_provider: _joi.default.when('extra_shipping_enabled', {
        is: true,
        then: _joi.default.valid(...providersAllowed).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      extra_shipping_carrier: _joi.default.when('extra_shipping_enabled', {
        is: true,
        then: _joi.default.valid(...Object.values(carriersAllowed)).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      extra_shipping_service_id: _joi.default.alternatives(_joi.default.string(), _joi.default.number().integer(), _joi.default.valid('', null)),
      extra_shipping_max_allowed_pence: _joi.default.number().integer().min(1).allow('', null).required(),
      extra_shipping_carrier_account: _joi.default.when('extra_shipping_enabled', {
        is: true,
        then: _joi.default.string().regex(excludeSheetErrorsRegex).required(),
        otherwise: _joi.default.valid('', null).required()
      }),
      // Papaparse.parse adds the key '__parsed_extra' automatically if there are more fields than the number of headers in a row
      __parsed_extra: _joi.default.forbidden().label('more fields than headers')
    });

    return {
      shippingPathSchema,
      pathSchema,
      carrierLaneSchema,
      laneSchema
    };
  }

  function validatePathsFile(paths, country) {
    const validation = {
      value: undefined,
      errorsByPath: {}
    };

    if (!paths || !paths.length) {
      throw new Error('Unable to validate empty file');
    }

    let index = 0;
    const pathCodes = [];
    paths.forEach(path => {
      index++; // Only validate path if it has path_codes

      if (!path.path_codes) {
        validation.errorsByPath[index] = [`path_codes is missing on path #${index}`];
        return;
      }

      if (pathCodes.includes(path.path_codes)) {
        validation.errorsByPath[index] = [`path_codes '${path.path_codes}' is duplicated on path #${index})`];
        return;
      }

      pathCodes.push(path.path_codes);
      const pathValidation = pathSchema.validate(path, {
        abortEarly: false
      });
      const pathValidationErrorDetails = pathValidation.error && pathValidation.error.details && pathValidation.error.details.map(errorDetail => errorDetail.message) || [];
      validation.errorsByPath[path.path_codes] = [];
      validation.errorsByPath[path.path_codes] = validation.errorsByPath[path.path_codes].concat(pathValidationErrorDetails);
      validation.errorsByPath[path.path_codes] = validation.errorsByPath[path.path_codes].concat(additionalPathValidation(path, country));

      if (!validation.errorsByPath[path.path_codes].length) {
        // Remove path_codes key from the errors object if it doesn't have any errors
        delete validation.errorsByPath[path.path_codes];
      }
    });
    return validation;
  }

  function validatePathWithStandardDisabled(path) {
    if (!path.is_standard_disabled) {
      return [];
    }

    if (path.christmas_enabled && path.christmas_unit_cost !== path.unit_cost) {
      return ['when "is_standard_disabled" and "christmas_enabled" are enabled, then "unit_cost" and "christmas_unit_cost" must have the same value'];
    }

    if (path.christmas_enabled) {
      return [];
    }

    if (!path.upsap_enabled && !path.express_enabled) {
      return ['when "is_standard_disabled" is enabled, then an alternative shipping option must be enabled'];
    }

    if (path.upsap_enabled && path.upsap_unit_cost !== path.unit_cost) {
      return ['when "is_standard_disabled" and "upsap_enabled" are enabled, then "unit_cost" and "upsap_unit_cost" must have the same value'];
    }

    if (path.upsap_enabled) {
      return [];
    }

    if (path.express_enabled && path.express_unit_cost !== path.unit_cost) {
      return ['when "is_standard_disabled" and "express_enabled" are enabled, then "unit_cost" and "express_unit_cost" must have the same value'];
    }

    return [];
  }

  function additionalPathValidation(path, country) {
    const validationErrors = [];
    const sourceCountry = country.findCountryByProperty('iso_code_alpha_3', path.source_country_code);

    if (!sourceCountry) {
      validationErrors.push(`"source_country_code" value '${path.source_country_code}' is unknown`);
    }

    const destinationCountry = country.findCountryByProperty('iso_code_alpha_3', path.destination_country_code);

    if (!destinationCountry) {
      validationErrors.push(`"destination_country_code" value '${path.destination_country_code}' is unknown`);
    }

    const pathCodeComputedFromCountries = `${path.source_country_code}>${path.destination_country_code}`;

    if (pathCodeComputedFromCountries !== path.path_codes) {
      validationErrors.push(`"path_codes" value '${path.path_codes}' does not match "source_country_code" ('${path.source_country_code}') and "destination_country_code" ('${path.destination_country_code}')`);
    }

    if (path.is_standard_disabled) {
      const pathWithStandardDisabledValidationErrors = validatePathWithStandardDisabled(path);
      validationErrors.push(...pathWithStandardDisabledValidationErrors);
    }

    return validationErrors;
  }

  function validateShippingPaths(shippingPaths) {
    const shippingPathValidation = _joi.default.array().items(shippingPathSchema).validate(shippingPaths, {
      abortEarly: false
    });

    const shippingPathValidationErrorDetails = shippingPathValidation.error && shippingPathValidation.error.details && shippingPathValidation.error.details.map(errorDetail => errorDetail.message) || [];
    return _objectSpread(_objectSpread({}, shippingPathValidation), {}, {
      errorMessages: shippingPathValidationErrorDetails
    });
  }

  function validateLanesFile(lanes, country) {
    const validation = {
      value: undefined,
      errorsByLane: {}
    };

    if (!lanes || !lanes.length) {
      throw new Error('Unable to validate empty file');
    }

    let index = 0;
    const pathCodes = [];
    lanes.forEach(lane => {
      index++; // Only validate lane if it has path_codes

      if (!lane.path_codes) {
        validation.errorsByLane[index] = [`path_codes is missing on lane #${index}`];
        return;
      }

      if (pathCodes.includes(lane.path_codes)) {
        validation.errorsByLane[index] = [`path_codes '${lane.path_codes}' is duplicated on lane #${index})`];
        return;
      }

      pathCodes.push(lane.path_codes);
      const laneValidation = laneSchema.validate(lane, {
        abortEarly: false
      });
      const laneValidationErrorDetails = laneValidation.error && laneValidation.error.details && laneValidation.error.details.map(errorDetail => errorDetail.message) || [];
      validation.errorsByLane[lane.path_codes] = [];
      validation.errorsByLane[lane.path_codes] = validation.errorsByLane[lane.path_codes].concat(laneValidationErrorDetails);
      validation.errorsByLane[lane.path_codes] = validation.errorsByLane[lane.path_codes].concat(additionalLaneValidation(lane, country));

      if (!validation.errorsByLane[lane.path_codes].length) {
        // Remove path_codes key from the errors object if it doesn't have any errors
        delete validation.errorsByLane[lane.path_codes];
      }
    });
    return validation;
  }

  function additionalLaneValidation(lane, country) {
    const validationErrors = [];
    const sourceCountry = country.findCountryByProperty('iso_code_alpha_3', lane.source_country_code);

    if (!sourceCountry) {
      validationErrors.push(`"source_country_code" value '${lane.source_country_code}' is unknown`);
    }

    const destinationCountry = country.findCountryByProperty('iso_code_alpha_3', lane.destination_country_code);

    if (!destinationCountry) {
      validationErrors.push(`"destination_country_code" value '${lane.destination_country_code}' is unknown`);
    }

    const pathCodeComputedFromCountries = `${lane.source_country_code}>${lane.destination_country_code}`;

    if (pathCodeComputedFromCountries !== lane.path_codes) {
      validationErrors.push(`"path_codes" value '${lane.path_codes}' does not match "source_country_code" ('${lane.source_country_code}') and "destination_country_code" ('${lane.destination_country_code}')`);
    }

    return validationErrors;
  }

  function validateCarrierLanes(carrierLanes) {
    const carrierLaneValidation = _joi.default.array().items(carrierLaneSchema).validate(carrierLanes, {
      abortEarly: false
    });

    const carrierLaneValidationErrorDetails = carrierLaneValidation.error && carrierLaneValidation.error.details && carrierLaneValidation.error.details.map(errorDetail => errorDetail.message) || [];
    return _objectSpread(_objectSpread({}, carrierLaneValidation), {}, {
      errorMessages: carrierLaneValidationErrorDetails
    });
  }
});