define("backalley/pods/components/product-amendment-card/component", ["exports", "backalley/util/default-to", "backalley/models/product_amendment", "ember-changeset", "backalley/pods/components/product-amendment-card/common_mixin"], function (_exports, _defaultTo, _product_amendment, _emberChangeset, _common_mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend(_common_mixin.default, {
    amendment: (0, _defaultTo.default)(null),
    amendmentChangeset: Ember.computed('amendment', function () {
      return new _emberChangeset.default(this.amendment);
    }),
    save: (0, _defaultTo.default)(() => {}),
    delete: (0, _defaultTo.default)(() => {}),
    setAmendmentOnField: (0, _defaultTo.default)(() => {}),
    actions: {
      delete(amendment) {
        const deleteAction = Ember.get(this, 'delete');
        return deleteAction(amendment);
      },

      approve(amendment) {
        const save = Ember.get(this, 'save'); // Set the status to approved and save

        Ember.set(amendment, 'status', 'approved');
        return save(amendment);
      },

      reject(amendment) {
        // Set the status to rejected but don't save.
        Ember.setProperties(amendment, {
          status: 'rejected',
          _edit: true
        });
      },

      save(amendment) {
        const save = Ember.get(this, 'save');
        Ember.set(amendment, '_edit', false);
        return save(amendment);
      },

      cancelEdit(amendment) {
        // If the amendment isn't saved, cancel should remove the card
        if (!Ember.get(amendment, '_id')) {
          const deleteAction = Ember.get(this, 'delete');
          return deleteAction(amendment);
        } // If the amendment is saved, cancel should toggle the edit


        Ember.setProperties(amendment, {
          status: 'resolved',
          _edit: false
        });
        return amendment;
      },

      submitRejectAndCreateNew(amendment) {
        const save = Ember.get(this, 'save');
        const setAmendmentOnField = Ember.get(this, 'setAmendmentOnField'); // ember-changeset doesn't support rollback of specific fields yet

        const modifiedNote = amendment.get('note');
        amendment.rollback();
        amendment.set('status', 'rejected'); // Need to make sure we don't modify original amendment.note

        return save(amendment).then(savedAmendment => {
          const newAmendment = _product_amendment.default.create(_objectSpread(_objectSpread({}, savedAmendment), {}, {
            _id: undefined,
            status: 'pending',
            note: modifiedNote
          }));

          return save(newAmendment);
        }).then(setAmendmentOnField);
      }

    }
  });

  _exports.default = _default;
});