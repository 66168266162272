define("backalley/pods/components/google-map/component", ["exports", "backalley/models/googlemap", "backalley/util/default-to"], function (_exports, _googlemap, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    // Public api
    markers: (0, _defaultTo.default)([]),
    center: (0, _defaultTo.default)({
      lat: 0,
      lng: 0
    }),
    zoom: (0, _defaultTo.default)(1),
    // Private api
    classNames: ['google-map'],
    _map: null,

    renderMarkers(client, map, markers) {
      if (!markers || !markers.length) {
        return [];
      }

      return markers.map(marker => {
        return new client.Marker(_objectSpread(_objectSpread({}, marker), {}, {
          map
        }));
      });
    },

    renderMap() {
      const googleMap = new _googlemap.default();
      const client = googleMap.client;
      const center = {
        lat: this.get('center.lat'),
        // Google maps expects an 'lng' property, but all locations on promenade are stored using 'lon' property.
        // Fall-back to 'lng' if a 'lon' property is not found
        lng: this.get('center.lon') || this.get('center.lng')
      }; // Render map

      const map = new client.Map(this.$('.map')[0], {
        zoom: this.zoom,
        center
      }); // Set map on component so we can retrieve it later

      this.set('_map', map);
      return {
        googleMap,
        map,
        client
      };
    },

    didUpdateAttrs() {
      const oldMarkers = this._oldMarkers;
      const newMarkers = this.markers; // Update map and markers only if there's new markers

      if (oldMarkers.value !== newMarkers.value) {
        // Render map
        const mapInstance = this.renderMap(); // Render markers

        this.renderMarkers(mapInstance.client, mapInstance.map, newMarkers);
      }
    },

    didInsertElement() {
      const markers = this.markers; // Render map

      const mapInstance = this.renderMap(); // Render markers

      this.renderMarkers(mapInstance.client, mapInstance.map, markers);
    }

  });

  _exports.default = _default;
});