define("backalley/pods/main/statements/edit/route", ["exports", "@streethub/calcs", "moment", "backalley/models/statement"], function (_exports, _calcs, _moment, _statement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    deserializeQueryParam(value = {}, urlKey = '') {
      // NOTE: use endOf day
      if (urlKey === 'until') return (0, _moment.default)(value).endOf('day').toISOString();
      if (urlKey === 'lock_until') return (0, _moment.default)(value).endOf('day').toISOString();
      return value;
    },

    model({
      boutique_id,
      until,
      lock_until
    }) {
      const query = {
        until
      };
      if (lock_until) query.lock_until = lock_until;
      return _statement.default.findByBoutiqueId(boutique_id, query).then(res => {
        return {
          editMode: false,
          commentMode: false,
          statement: res,
          todays_date: new Date(),
          // store a copy of the _total_payout.
          _total_payout: res.total_payout,
          _original_adjustment_amount: res.adjustment_amount,
          _total_payout_bignum: res.bignumber_total_payout,
          _original_boutique_total_fees_exc_vat: res.bignumber_boutique_total_fees_exc_vat,
          _original_boutique_total_fees: res.bignumber_boutique_total_fees,
          _original_manual_fee_amount_exc_vat: res.manual_fee_amount_exc_vat,
          _original_manual_fee_amount: res.manual_fee_amount,
          _original_boutique_vat_on_fees: res.bignumber_boutique_vat_on_fees,
          actions: {
            setEditMode(state) {
              this.set('model.editMode', state);
            },

            toggleCommentMode() {
              this.toggleProperty('model.commentMode');
            },

            setAdjustments(new_adjustment_amount) {
              _calculateTotalPayout.call(this, this.get('model.statement.manual_fee_amount_exc_vat_negated'), new_adjustment_amount);
            },

            setManualFee(new_manual_fee_amount_exc_vat) {
              _calculateTotalPayout.call(this, new_manual_fee_amount_exc_vat, this.get('model.statement.adjustment_amount_negated'));
            },

            sendStatement() {
              this.set('model.statement.until', until);
              this.set('model.statement.lock_until', lock_until);
              if (this.get('model.statement.total_payout') > 0) return this.notify.show('danger', 'There is no outstanding amount, only the boutique owns us, so no statement is being sent.');
              return this.get('model.statement').send().then(() => {
                this.notify.show('success', 'Relax, your statement went through! Going back to statements.');
                this.transitionToRoute('main.statements.index');
              }).catch(() => {
                this.notify.show('danger', 'Oops. Something went wrong.');
              });
            }

          }
        };
      });
    }

  });

  _exports.default = _default;

  function _calculateTotalPayout(new_manual_fee_amount_exc_vat, new_adjustment_amount) {
    const original_boutique_total_fees_exc_vat = this.get('model._original_boutique_total_fees_exc_vat');
    const original_boutique_total_fees = this.get('model._original_boutique_total_fees');
    const original_manual_fee_amount_exc_vat = this.get('model._original_manual_fee_amount_exc_vat');
    const original_manual_fee_amount = this.get('model._original_manual_fee_amount');
    const original_total_payout = this.get('model._total_payout_bignum');
    const original_boutique_vat_on_fees = this.get('model._original_boutique_vat_on_fees');
    const original_adjustment_amount = this.get('model._original_adjustment_amount');

    const new_manual_fee_amount = _calcs.default.sum([_calcs.default.m`-${_calcs.default.calculateVAT(new_manual_fee_amount_exc_vat)}`, _calcs.default.m`-${new_manual_fee_amount_exc_vat}`]);

    const new_boutique_total_fees_exc_vat = _calcs.default.sum([original_boutique_total_fees_exc_vat.value, _calcs.default.m`-${original_manual_fee_amount_exc_vat}`, // need to negate the new_manual_fee_amount_exc_vat because the values from the input
    // are always negated in comparison to the ones from the database
    _calcs.default.m`-${new_manual_fee_amount_exc_vat}`]);

    const new_boutique_vat_on_fees = _calcs.default.calculateVAT(new_boutique_total_fees_exc_vat);

    const manualFeeAmounts = _calcs.default.inPences({
      manual_fee_amount: new_manual_fee_amount,
      boutique_total_fees_exc_vat: new_boutique_total_fees_exc_vat,
      boutique_total_fees: _calcs.default.sum([original_boutique_total_fees.value, _calcs.default.m`-${original_manual_fee_amount}`, // no need to negate the new_manual_fee_amount because it is not coming from the input
      // and it was calculated as the values in the database
      new_manual_fee_amount]),
      boutique_vat_on_fees: new_boutique_vat_on_fees,
      total_payout: _calcs.default.sum([original_total_payout.value, _calcs.default.m`-${original_boutique_total_fees_exc_vat.value}`, _calcs.default.m`-${original_boutique_vat_on_fees.value}`, new_boutique_total_fees_exc_vat, new_boutique_vat_on_fees, _calcs.default.m`-${original_adjustment_amount}`, _calcs.default.m`-${new_adjustment_amount}`])
    });

    Ember.setProperties(this.get('model.statement'), _objectSpread({}, manualFeeAmounts));
  }
});