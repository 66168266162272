define("backalley/services/shipping-group-configuration-service", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * I originally started down the route of using the storageFor handler
   * with class-based services/components. However, I could not find how
   * to get it to activate in the same way as with the older .extend()
   * method. This is why we have an interim "service" which I could only
   * get working using .extend(). If we know of a way that we can use
   * storageFor correctly with classes we can drop this layer.
   */
  var _default = Ember.Service.extend({
    store: (0, _emberLocalStorage.storageFor)('sgc-storage'),

    tidyUp() {
      const now = Date.now();
      const msInDay = 1000 * 60 * 60 * 24;
      const keysToDelete = [];
      Object.entries(this.store.content).forEach(([key, entry]) => {
        if (entry && entry.created_at) {
          const daysAgo = (now - entry.created_at) / msInDay;

          if (daysAgo > 10) {
            keysToDelete.push(key);
          }
        }
      });

      if (keysToDelete.length) {
        keysToDelete.forEach(key => {
          this.store.set(key, undefined);
        });
      }
    },

    storeByName(name, data) {
      const wrapper = {
        created_at: Date.now(),
        data
      };
      this.store.set(name, wrapper);
    },

    getFromStorageByName(name) {
      this.tidyUp();
      const wrapper = this.store.get(name);
      return wrapper ? wrapper.data : null;
    }

  });

  _exports.default = _default;
});