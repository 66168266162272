define("backalley/pods/components/feed-sync-permanent-rule-settings/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FeedSyncPermanentRuleSettingsComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = (_temp = class FeedSyncPermanentRuleSettingsComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "_permanentRulesAsString", _descriptor, this);
    }

    get styleNamespace() {
      return _podNames.default['feed-sync-permanent-rule-settings'];
    }

    get permanentRulesAsString() {
      if (this._permanentRulesAsString) {
        return this._permanentRulesAsString;
      }

      return JSON.stringify(this.args.permanentRules, null, 2);
    }

    set permanentRulesAsString(value) {
      var _this$args$onPermanen, _this$args;

      this._permanentRulesAsString = value;
      (_this$args$onPermanen = (_this$args = this.args).onPermanentRulesChange) === null || _this$args$onPermanen === void 0 ? void 0 : _this$args$onPermanen.call(_this$args, value);
    }

    triggerPreset(type) {
      let state;

      try {
        // we get type === 'format' for free, due to JSON parse/stringify
        state = JSON.parse(this.permanentRulesAsString);
      } catch (exception) {
        return; // same if we error whilst parsing
      }

      if (type === 'clear') {
        state = [];
      }

      if (type === 'add-example') {
        state.push({
          "params": ["overwrite"],
          "target_fields": ["price"],
          "target_products": ["TROUVA:product-id-3"],
          "target_products_by_brand": ["TROUVA:product-id-2"],
          "target_products_by_category": ["TROUVA:product-id-3"],
          "action": "set-field",
          "target_products_min_price_in_local_currency_pence": "XXX",
          "target_products_max_price_in_local_currency_pence": "XXX"
        });
      }

      if (type === 'pop') {
        state.pop();
      }

      this.permanentRulesAsString = JSON.stringify(state, null, 2);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_permanentRulesAsString", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "triggerPreset", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "triggerPreset"), _class.prototype)), _class));
  _exports.default = FeedSyncPermanentRuleSettingsComponent;
});