define("backalley/pods/components/order-edit/manual-adjustment/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public API
    order: null,
    reservations: null,
    reservationBoutiques: null,
    // Private API
    notify: Ember.inject.service(),
    classNames: ['box-view', 'box-view-zendesk'],
    products: Ember.computed('reservations.@each._id', function () {
      const {
        reservations = []
      } = this;
      return reservations.map(reservation => {
        const {
          product,
          boutique
        } = reservation;
        return {
          id: product.id,
          boutique_email: boutique.order_email,
          name: product.name,
          slug: product.slug,
          boutique_name: boutique.name,
          unit_price: reservation.local_price.amount
        };
      });
    }),
    boutiques: Ember.computed('reservationBoutiques.@each._id', function () {
      const {
        reservationBoutiques = []
      } = this;
      return reservationBoutiques.map(reservationBoutique => {
        const boutique = Ember.get(reservationBoutique, 'reservations.0.boutique');
        const shippingProvider = Ember.get(reservationBoutique, 'reservations.0.shipping_provider');
        const address = [boutique.address_line_1, boutique.address_line_2, boutique.address_line_3, boutique.address_postcode, boutique.address_city];
        return {
          id: reservationBoutique.boutique,
          email: boutique.order_email,
          name: boutique.name,
          commission_rate: boutique.commission_rate,
          shipping_provider: shippingProvider,
          address: address.join('\n'),
          currency: reservationBoutique.boutique_currency,
          address_city: boutique.adress_city || ' ',
          primary_name: boutique.primary_contact_name || ' ',
          statements_version: boutique.statements_version,
          address_country: boutique.address_country
        };
      });
    }),
    actions: {
      onCopyURL(status) {
        const message = status === 'success' ? 'Form URL copied to clipboard' : 'Unable to copy URL';
        this.notify.show(status, message);
      }

    }
  });

  _exports.default = _default;
});