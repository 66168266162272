define("backalley/pods/main/discounts/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let MainDiscountsIndexController = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class MainDiscountsIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['page', 'q', 'sort', 'type', 'channel']);

      _defineProperty(this, "q", '');

      _defineProperty(this, "sort", '-updated_at');

      _defineProperty(this, "type", 'null');

      _defineProperty(this, "channel", 'null');

      _defineProperty(this, "page", 1);

      _defineProperty(this, "limit", 32);

      _defineProperty(this, "availableFilters", [{
        label: 'Date: last updated',
        value: '-updated_at'
      }, {
        label: 'Date: first updated',
        value: 'updated_at'
      }, {
        label: 'Date: last created',
        value: '-created_at'
      }, {
        label: 'Date: first created',
        value: 'created_at'
      }, {
        label: 'Name: A-Z',
        value: 'code'
      }, {
        label: 'Name: Z-A',
        value: '-code'
      }]);

      _defineProperty(this, "typeFilters", [{
        label: 'Filter by type',
        value: 'null'
      }, {
        label: 'Type: amount',
        value: 'amount'
      }, {
        label: 'Type: percentage',
        value: 'percentage'
      }, {
        label: 'Type: Free shipping',
        value: 'free_shipping'
      }, {
        label: 'Type: free delivery',
        value: 'free_delivery'
      }]);

      _defineProperty(this, "channelFilters", [{
        label: 'Filter by channel',
        value: 'null'
      }, {
        label: 'Channel: Trouva',
        value: 'trouva'
      }, {
        label: 'Channel: Packaging',
        value: 'packaging'
      }]);
    }

    updateQuery(query) {
      return this.transitionToRoute({
        queryParams: {
          q: query,
          page: 1
        }
      });
    }

    updateFilter(key, value) {
      this.set(key, value.value);
      this.set('page', 1);
    }

    remove(discount) {
      if (!confirm(`Are you sure you want to delete the discount: ${discount.code}`)) {
        return;
      }

      discount.remove().then(() => this.model.discounts.removeObject(discount));
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "updateQuery", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFilter", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype)), _class));
  _exports.default = MainDiscountsIndexController;
});