define("backalley/models/delivery-modifier", ["exports", "joi", "backalley/util/request"], function (_exports, _joi, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class DeliveryModifier {
    constructor({
      _id,
      name,
      type,
      path_group_configuration
    }) {
      this.name = name;
      this.type = type;
      this.path_group_configuration = path_group_configuration, this._id = _id;
    }

    static find(params = {}) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.apiURL)('delivery_modifiers'),
        data: params
      }).then(({
        delivery_modifiers,
        meta
      }) => {
        return {
          deliveryModifiers: delivery_modifiers.map(deliveryModifier => new DeliveryModifier(deliveryModifier)),
          meta
        };
      });
    }

  }

  _exports.default = DeliveryModifier;

  _defineProperty(DeliveryModifier, "schema", _joi.default.object().keys({
    name: _joi.default.string().required(),
    type: _joi.default.string().required(),
    path_group_configuration: _joi.default.string()
  }));
});