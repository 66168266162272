define("backalley/models/brain", ["exports", "backalley/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Brain = Ember.Object.extend(_request.default, {});
  Brain.reopenClass(_request.default, {
    /**
     * Fetch secrets (keys etc.)
     * @return {Object} Promise
     */
    getSecrets() {
      return this.request({
        method: 'GET',
        url: this.apiURL('brain/secrets')
      });
    }

  });
  var _default = Brain;
  _exports.default = _default;
});