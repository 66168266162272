define("backalley/pods/components/feed-sync-advanced-settings/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FeedSyncAdvancedSettingsComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = (_temp = class FeedSyncAdvancedSettingsComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "_advancedSettingsAsString", _descriptor, this);
    }

    get styleNamespace() {
      return _podNames.default['feed-sync-advanced-settings'];
    }

    get classNames() {
      return _objectSpread({
        wrapper: `feed-sync-advanced-settings feed-sync-advanced-settings--${this.args.design || 'standard'}`,
        presets: 'feed-sync-advanced-settings__presets'
      }, this.args.classNames);
    }

    get advancedSettingsAsString() {
      if (this._advancedSettingsAsString) {
        return this._advancedSettingsAsString;
      }

      return JSON.stringify(this.args.advancedSettings, null, 2);
    }

    set advancedSettingsAsString(value) {
      this._advancedSettingsAsString = value;

      if (this.args.onAdvancedSettingsChange) {
        this.args.onAdvancedSettingsChange(value);
      }
    }

    triggerPreset(type) {
      let state;

      try {
        // we get type === 'format' for free, due to JSON parse/stringify
        state = JSON.parse(this.advancedSettingsAsString);
      } catch (exception) {
        return; // same if we error whilst parsing
      }

      if (type === 'clear') {
        state = {
          archive_existing: true,
          rules: []
        };
      }

      if (type === 'limited-run') {
        // globally ignore products
        state.rules.push({
          action: 'set-product',
          params: 'ignore',
          target_fields: [],
          target_products: []
        });
        state.rules.push({
          action: 'set-product',
          params: 'conditional-update',
          target_fields: [],
          target_products: ['TROUVA:XXXXXXXXXXXXXXXXX']
        });
      }

      if (type === 'overwrite-images') {
        state.rules.push({
          action: 'set-field',
          params: 'overwrite',
          target_fields: ['images', 'thumbnail'],
          target_products: []
        });
      }

      if (type === 'overwrite-names') {
        state.rules.push({
          action: 'set-field',
          params: 'overwrite',
          target_fields: ['name'],
          target_products: []
        });
      }

      if (type === 'overwrite-descriptions') {
        state.rules.push({
          action: 'set-field',
          params: 'overwrite',
          target_fields: ['description', 'details'],
          target_products: []
        });
      }

      if (type === 'overwrite-manufacturer') {
        state.rules.push({
          action: 'set-field',
          params: 'overwrite',
          target_fields: ['standard_manufacturer'],
          target_products: []
        });
      }

      if (type === 'dry-run') {
        state.use_dry_run = true;
      }

      if (type === 'pop') {
        state.rules.pop();
      }

      this.advancedSettingsAsString = JSON.stringify(state, null, 2);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_advancedSettingsAsString", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "triggerPreset", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "triggerPreset"), _class.prototype)), _class));
  _exports.default = FeedSyncAdvancedSettingsComponent;
});