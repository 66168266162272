define("backalley/helpers/and-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.andSwitch = andSwitch;
  _exports.default = void 0;

  function andSwitch([...args]) {
    return args.reduce((sum, elem) => {
      return sum && elem;
    }, true);
  }

  var _default = Ember.Helper.helper(andSwitch);

  _exports.default = _default;
});