define("backalley/pods/components/landing-page/partials/link/component", ["exports", "backalley/pods/main/landing/new/selectOptions", "backalley/util/default-to", "backalley/config/environment"], function (_exports, _selectOptions, _defaultTo, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public API
    item: (0, _defaultTo.default)(null),

    onSelectLinkType() {},

    onSelectLinkTypeOption() {},

    onCustomUrlInput() {},

    onRemoveLink() {},

    // Private API
    searchResults: (0, _defaultTo.default)([]),
    availableTypes: _selectOptions.availableTypes,
    selectedLinkType: Ember.computed('availableTypes', 'item.type', function () {
      return this.availableTypes.find(type => type.value === this.get('item.type'));
    }),
    hasLink: Ember.computed('item.{type,slug,id,customUrl}', function () {
      const item = this.item;
      return item.type && (item.slug || item.id || item.customUrl);
    }),
    linkUrl: Ember.computed('item.{type,slug,id,customUrl}', function () {
      const item = this.item;

      switch (item.type) {
        case 'category':
          {
            const {
              slug,
              parentSlug,
              macroSlug
            } = item;
            if (!slug) return null;
            return `${_environment.default.trouvaWebURL}/category${macroSlug ? `/${macroSlug}` : ''}${parentSlug ? `/${parentSlug}` : ''}${slug ? `/${slug}` : ''}`;
          }

        case 'tag':
          {
            const slug = item.slug;
            if (!slug) return null;
            return `${_environment.default.trouvaWebURL}/tag/${slug}`;
          }

        case 'product':
          {
            const {
              slug,
              boutique_slug
            } = item;
            if (!slug) return null;
            return `${_environment.default.trouvaWebURL}/boutique/${boutique_slug}/${slug}`;
          }

        case 'boutique':
          {
            const {
              slug
            } = item;
            if (!slug) return null;
            return `${_environment.default.trouvaWebURL}/boutique/${slug}`;
          }

        case 'discover':
          {
            const {
              id
            } = item;
            if (!id) return null;
            return `${_environment.default.trouvaWebURL}/discover/${id}`;
          }

        case 'pages':
          {
            const {
              id
            } = item;
            if (!id) return null;
            return `${_environment.default.trouvaWebURL}/pages/${id}`;
          }

        case 'route':
          {
            switch (item.id) {
              case 'landing':
                return _environment.default.trouvaWebURL;

              case 'login':
              case 'signup':
              case 'boutiques':
              case 'team':
              case 'friends':
              case 'our-story':
                return `${_environment.default.trouvaWebURL}/${item.id}`;

              case 'shop.new-in':
                return `${_environment.default.trouvaWebURL}/new-in`;

              case 'shop.sale':
                return `${_environment.default.trouvaWebURL}/sale`;

              default:
                return null;
            }
          }

        case 'customUrl':
          {
            const {
              customUrl
            } = item;
            if (!customUrl) return null;
            return customUrl;
          }

        default:
          return '';
      }
    }),
    actions: {
      searchLinkType(type, q) {
        if (Ember.isEmpty(q)) return;
        if (Ember.isEmpty(type)) return;
        if (type.value === 'customUrl') return;
        return type.model.find(type.query(q)).then(res => {
          // Landing pages && Info pages for now until refactor
          // TODO: Normalise these across the board
          if (res.pages) res = res.pages;
          if (res.static && res.static.pages) res = res.static.pages; // If a transform result function is defined,
          // modify the resulting object to conform with
          // the standard it should be using.

          const results = [...res].map(result => {
            if (type.transformResult) {
              return type.transformResult(result);
            }

            return result;
          });
          Ember.set(this, 'searchResults', results);
        });
      }

    }
  });

  _exports.default = _default;
});