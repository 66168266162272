define("backalley/util/form-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.clearErrorMarkers = clearErrorMarkers;
  _exports.setErrorMarkersFromException = setErrorMarkersFromException;
  _exports.createMessageFromException = createMessageFromException;

  /**
   * These functions can be used to map joi validation failures into
   * the same heirachy as the data that tripped them. Allowing templates
   * to hook in and display errors at the right level.
   *
   * It can be used in two ways:
   *
   *   1. Track the errors reported against a new structure attached to
   *      your component, which your template tracks (in order to
   *      display errors in the right location). Keeps your model tidy.
   *
   *   2. Track the errors reported against your target model, this is
   *      how Shipping Group Configuration has been implmented. This means
   *      error properties are added directly to your model. Which saves
   *      recreating complex structure to navigate just for errors. These
   *      additions are tidied up before save however, but even if that
   *      were to fail, our ecosystem ignores unknown schema.
   *
   * Integration just requires using following in the right places.
   *
   *   - clearErrorMarkers(this);
   *   - setErrorMarkersFromException(
   *          exception,
   *          [], // base path keys
   *          this
   *      );
   *   - createMessageFromException(exception);
   */
  function convertValidationErrorToPropInfo(exception, basePath = [''], fieldPrefix = '__', fieldSuffix = '__error') {
    if (exception.type !== 'model-validation') {
      return;
    }

    if (!exception.failedPath) {
      return;
    }

    const setPath = [...exception.failedPath];
    const fieldName = setPath.pop();
    const targetField = `${fieldPrefix}${fieldName}${fieldSuffix}`;
    const targetFieldPath = [...basePath, ...setPath, targetField].join('.');
    const generalFieldPath = [...basePath, ...setPath, fieldSuffix].join('.');
    return {
      type: exception.failedType,
      path: targetFieldPath,
      generalPath: generalFieldPath,
      field: fieldName,
      message: exception.message
    };
  }

  function convertResponseErrorsToString(responseJSON = {}) {
    const {
      errors = []
    } = responseJSON;

    if (!errors || !errors.length) {
      return;
    }

    return errors.map(err => err.detail).join('\n');
  }

  function clearErrorMarkers(formComponent) {
    const {
      errorMarkers = []
    } = formComponent;
    errorMarkers.forEach(validationTarget => {
      formComponent.set(validationTarget.path, undefined);
      formComponent.set(validationTarget.generalPath, undefined);
    });
  }

  function setErrorMarkersFromException(exception, basePath, formComponent) {
    const validationTarget = convertValidationErrorToPropInfo(exception, basePath);

    if (!formComponent.errorMarkers) {
      formComponent.errorMarkers = [];
    }

    if (!validationTarget) {
      return '';
    }

    formComponent.set(validationTarget.path, validationTarget.message);
    formComponent.set(validationTarget.generalPath, validationTarget.message);
    formComponent.errorMarkers.push(validationTarget);
    return validationTarget.message;
  }

  function createMessageFromException(exception) {
    if (!exception) {
      return '';
    }

    if (exception.responseJSON && exception.responseJSON.errors) {
      return convertResponseErrorsToString(exception.responseJSON);
    } else {
      return exception.message || 'an unexpected error occurred';
    }
  }
});