define("backalley/pods/main/publisherlinks/edit/route", ["exports", "backalley/models/publisherlink"], function (_exports, _publisherlink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      publisherlink_id: id
    }) {
      return _publisherlink.default.findById(id);
    },

    actions: {
      update(publisherlink) {
        const newPublisherlink = _publisherlink.default.create({
          _id: publisherlink._id,
          comment: publisherlink.comment,
          published: publisherlink.published,
          created_at: publisherlink.created_at
        });

        if (publisherlink.url) {
          newPublisherlink.url = publisherlink.url;
        }

        return newPublisherlink.save().then(() => this.transitionTo('main.publisherlinks'), err => this.notify.show('err', err.message));
      }

    }
  });

  _exports.default = _default;
});