define("backalley/models/manual-adjustment", ["exports", "backalley/mixins/request", "joi", "backalley/util/models"], function (_exports, _request, _joi, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const validations = {
    _id: _joi.default.string(),
    adjustment_type: _joi.default.string(),
    boutique: _joi.default.string(),
    order: _joi.default.string().allow(null),
    reservation: _joi.default.string().allow(null),
    reservation_boutique: _joi.default.string().allow(null),
    transaction_type: _joi.default.string(),
    request_amount: _joi.default.object().keys({
      amount: _joi.default.number(),
      currency: _joi.default.string()
    }),
    vat_rate: _joi.default.number(),
    product_quantity: _joi.default.number(),
    date: _joi.default.string().allow(null),
    description: _joi.default.string().allow('').allow(null),
    invoices_supplied: _joi.default.boolean(),
    internal_notes: _joi.default.string().allow('').allow(null),
    zendesk_ticket_ids: _joi.default.array().items(_joi.default.string()).allow(null),
    created_at: _joi.default.string(),
    created_by: _joi.default.string(),
    approved: _joi.default.boolean(),
    approved_at: _joi.default.string(),
    approved_by: _joi.default.string()
  };
  const updateValidations = {
    _id: _joi.default.string(),
    request_amount: _joi.default.object().keys({
      amount: _joi.default.number(),
      currency: _joi.default.string()
    }),
    product_quantity: _joi.default.number(),
    zendesk_ticket_ids: _joi.default.array().items(_joi.default.string()).allow(null),
    vat_rate: _joi.default.number(),
    description: _joi.default.string().allow('').allow(null),
    invoices_supplied: _joi.default.boolean(),
    internal_notes: _joi.default.string().allow('').allow(null),
    approved: _joi.default.boolean()
  };
  const ManualAdjustment = Ember.Object.extend(_request.default, {
    post() {
      return this.validate().then(manualAdjustment => {
        return this.request({
          method: 'POST',
          url: this.apiURL('manual_adjustments'),
          data: JSON.stringify(manualAdjustment)
        });
      }).then(res => {
        const data = res.manual_adjustments[0];
        this.setOriginalData(data);
        this.setProperties(data);
        return this;
      });
    },

    update() {
      return this.validate('update_schema').then(data => (0, _models.getChanges)(this._orig, data, Object.keys(updateValidations))).then(adjustment => this.request({
        method: 'PUT',
        url: this.apiURL(`manual_adjustments/${adjustment._id}`),
        data: JSON.stringify(adjustment)
      })).then(() => {
        const query = {
          _id: this._id,
          populate: 'boutique order reservation created_by'
        };
        return ManualAdjustment.findWithMeta(query).then(({
          manual_adjustments
        }) => {
          this.setOriginalData(manual_adjustments[0]);
          this.setProperties(manual_adjustments[0]);
        });
      });
    },

    remove() {
      const adjustment = this;
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`manual_adjustments/${adjustment._id}`)
      });
    },

    validate(schema = 'schema') {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(ManualAdjustment[schema], data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    }

  });
  ManualAdjustment.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  ManualAdjustment.reopenClass(_request.default, {
    /**
     * Find manual adjustments and returns meta too
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMeta(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('manual_adjustments'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => {
        return {
          manual_adjustments: res.manual_adjustments.map(manualAdjustment => this.create(manualAdjustment)),
          meta: res.meta
        };
      });
    },

    getConfig() {
      return this.request({
        method: 'GET',
        url: this.apiURL('manual_adjustments/config')
      }).then(({
        config
      }) => {
        // Set the config on the manual adjustment instance
        this.config = config;
        return config;
      });
    },

    create(data = {}) {
      let c = this._super(data);

      c.setOriginalData(data);
      return c;
    },

    // The schema for the ManualAdjustment
    schema: _joi.default.object().keys(validations),
    // The schema to update the ManualAdjustment
    update_schema: _joi.default.object().keys(updateValidations)
  });
  var _default = ManualAdjustment;
  _exports.default = _default;
});