define("backalley/models/zone", ["exports", "backalley/mixins/request", "joi", "backalley/util/models"], function (_exports, _request, _joi, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const validations = {
    _id: _joi.default.string(),
    name: _joi.default.string().min(2).required().messages({
      'string.min': 'must be at least {#limit} characters'
    }),
    countries: _joi.default.array()
  };
  const Zone = Ember.Object.extend(_request.default, {
    /**
     * Create new zone
     * @return {Object} Promise
     */
    post() {
      const countries = this.countries;
      const countriesArr = this.countries.map(country => country._id);
      return this.validate().then(zone => {
        return this.request({
          method: 'POST',
          url: this.apiURL('zones'),
          data: JSON.stringify(_objectSpread(_objectSpread({}, zone), {}, {
            countries: countriesArr
          }))
        });
      }).then(res => {
        const zone = res.zones[0];
        delete zone.countries;
        zone.countries = countries;
        return zone;
      }).then(zone => {
        this.setOriginalData(zone);
        this.setProperties(zone);
      });
    },

    /**
     * Save zone
     * @return {Object} Promise
     */
    save() {
      const countries = this.countries;
      const countriesArr = this.countries.map(country => country._id);
      return this.validate().then(data => (0, _models.getChanges)(this._orig, data, Object.keys(validations))).then(zone => {
        return this.request({
          method: 'PUT',
          url: this.apiURL(`zones/${zone._id}`),
          data: JSON.stringify(_objectSpread(_objectSpread({}, zone), {}, {
            countries: countriesArr
          }))
        });
      }).then(res => {
        const zone = res.zones[0];
        delete zone.countries;
        zone.countries = countries;
        return zone;
      }).then(zone => {
        this.setOriginalData(zone);
        this.setProperties(zone);
      });
    },

    validate() {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(Zone.schema, data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    /**
     * Remove zone
     * @return {Object} Promise
     */
    remove() {
      const zone = this;
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`zones/${zone._id}`)
      });
    }

  });
  Zone.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data = {}) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  Zone.reopenClass(_request.default, {
    schema: _joi.default.object().min(1).keys(validations),

    create(data) {
      let c = this._super(data);

      c.setOriginalData(data);
      return c;
    },

    /**
     * Find zones
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.findWithMeta(data).then(res => res.zones);
    },

    /**
     * Find zones and returns meta too
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMeta(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('zones'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => {
        return {
          zones: res.zones.map(zone => this.create(zone)),
          meta: res.meta
        };
      });
    },

    /**
     * Find zone by id
     * @param  {String} id zone _id
     * @return {Object}    Promise
     */
    findById(id, data = {}) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`zones/${id}`),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => this.create(res.zones[0]));
    }

  });
  var _default = Zone;
  _exports.default = _default;
});