define("backalley/pods/main/areas/index/route", ["exports", "backalley/models/area", "backalley/models/boutique", "backalley/mixins/loading", "backalley/util/escape-regex"], function (_exports, _area, _boutique, _loading, _escapeRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_loading.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      featured: {
        refreshModel: true
      },
      published: {
        refreshModel: true
      }
    },

    serializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      // don't show objects in url
      if (urlKey === 'sort') return value.value;
      if (urlKey === 'page') return Number(value);
      return value;
    },

    deserializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      if (urlKey === 'sort') return this.availableFilters.findBy('value', value);
      return value;
    },

    availableFilters: [{
      label: 'Date: last updated',
      value: '-updated_at'
    }, {
      label: 'Date: first updated',
      value: 'updated_at'
    }, {
      label: 'Date: last created',
      value: '-created_at'
    }, {
      label: 'Date: first created',
      value: 'created_at'
    }, {
      label: 'Name: A-Z',
      value: 'name'
    }, {
      label: 'Name: Z-A',
      value: '-name'
    }],
    areaCache: new Map(),

    model({
      q,
      page = 1,
      limit = 30,
      sort = {},
      published,
      featured
    }) {
      return _area.default.findWithMeta({
        page,
        limit,
        published: published ? published : undefined,
        featured: featured ? featured : undefined,
        name: {
          $regex: `^${(0, _escapeRegex.default)(q)}`,
          $options: 'i'
        },
        sort: sort.value
      }).then(getBoutiqueAndProductCount.bind(this)).then(({
        areas,
        meta = {
          total: 0
        }
      }) => {
        return {
          featured,
          areas,
          published,
          search: q,
          sort: sort,
          total: Math.ceil(meta.total / limit),
          availableFilters: this.availableFilters,
          actions: {
            goToPage: goToPage.bind(this),
            nextPage: nextPage.bind(this),
            prevPage: prevPage.bind(this),
            updateFilter: updateFilter.bind(this),
            updateSearch: updateSearch.bind(this),
            remove: remove.bind(this),
            setFeatruedFilter: setFeatruedFilter.bind(this),
            setPublishedFilter: setPublishedFilter.bind(this)
          }
        };
      });
    }

  });

  _exports.default = _default;

  function getBoutiqueAndProductCount({
    areas,
    meta
  }) {
    return Ember.RSVP.map(areas, area => {
      // check in cache
      if (this.areaCache.has(area)) return this.areaCache.get(area._id);
      return Ember.RSVP.hash({
        boutiques: getBoutiqueCount(area),
        publishedBoutiques: getPublishedBoutiqueCount(area) // products: getProductCount(area)

      }).then(hash => {
        area.boutiques = hash.boutiques;
        area.publishedBoutiques = hash.publishedBoutiques; // area.products = hash.products;

        this.areaCache.set(area, area);
        return area;
      });
    }).then(areasWithProducts => {
      return {
        areas: areasWithProducts,
        meta
      };
    });
  } // function getProductCount(area) {
  //     return Product.findWithMetaAndCreate({ areas: area._id, limit: 1, fields: '_id' })
  //         .then(({meta={total:0}}) => meta.total);
  // }


  function getBoutiqueCount(area) {
    return _boutique.default.findWithMeta({
      areas: area._id,
      limit: 1,
      fields: '_id'
    }).then(({
      meta = {
        total: 0
      }
    }) => meta.total);
  }

  function getPublishedBoutiqueCount(area) {
    return _boutique.default.findWithMeta({
      areas: area._id,
      limit: 1,
      fields: '_id',
      published: true
    }).then(({
      meta = {
        total: 0
      }
    }) => meta.total);
  }

  function goToPage(page) {
    this.transitionTo({
      queryParams: {
        page: page
      }
    });
  }

  function nextPage() {
    const page = this.get('controller.page');
    if (page < this.get('currentModel.total')) this.transitionTo({
      queryParams: {
        page: Number(page) + 1
      }
    });
  }

  function prevPage() {
    const page = this.get('controller.page');
    if (page > 1) this.transitionTo({
      queryParams: {
        page: Number(page) - 1
      }
    });
  }

  function updateSearch(q) {
    this.set('controller.q', q);
    this.set('controller.page', 1);
  }

  function updateFilter(filter) {
    this.set('controller.sort', filter);
  }

  function remove(area) {
    if (confirm(`Are you sure you want to delete the area: ${area.label}`)) {
      area.remove().then(() => {
        this.get('currentModel.areas').removeObject(area);
      });
    }
  }

  function setFeatruedFilter(bool) {
    if (typeof bool === 'boolean') this.controller.set('featured', bool);
  }

  function setPublishedFilter(bool) {
    if (typeof bool === 'boolean') this.controller.set('published', bool);
  }
});