define("backalley/util/price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatPrice = formatPrice;

  function formatPrice({
    amount,
    currency
  }, locale = 'en-GB') {
    return Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    }).format(amount / 100);
  }
});