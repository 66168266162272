define("backalley/pods/main/colors/edit/route", ["exports", "backalley/models/color"], function (_exports, _color) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    serialize(model) {
      return {
        color_id: model._id
      };
    },

    model(params) {
      return _color.default.findById(params.color_id).then(color => {
        if (!color._id) {
          this.transitionTo('main.colors');
        }

        return Ember.Object.create({
          color,
          actions: {
            updateColor: updateColor.bind(this)
          }
        });
      });
    }

  });

  _exports.default = _default;

  function updateColor(color) {
    return color.save().then(() => this.transitionTo('main.colors'), err => this.notify.show('err', err.message));
  }
});