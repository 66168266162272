define("backalley/pods/components/delighted-customer/component", ["exports", "backalley/models/delighted"], function (_exports, _delighted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    responses: [],
    _loading: false,
    classNameBindings: ['_loading:loading'],

    didReceiveAttrs() {
      return this.search();
    },

    search() {
      if (!this.email) return;
      this.set('_loading', true);
      return _delighted.default.search({
        email: this.email
      }).then(res => this.set('responses', res.responses)).finally(() => this.set('_loading', false));
    },

    actions: {
      refreshList() {
        this.search();
      }

    }
  });

  _exports.default = _default;
});