define("backalley/mirage/factories/product", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // mirage/factories/product.js
  var _default = _emberCliMirage.default.Factory.extend({
    name: i => `name ${i}`,

    boutiques(i) {
      return [{
        _id: i,
        quantity: -1,
        stock: true,
        boutique: {
          _id: i
        }
      }];
    },

    categories(i) {
      return i % 2 !== 0 ? [] : [{
        _id: 1,
        label: 'label 0'
      }];
    },

    colors(i) {
      return i % 2 !== 0 ? [] : [{
        _id: 1,
        label: 'label 0'
      }, {
        _id: 2,
        label: 'label 1'
      }];
    },

    tags(i) {
      return i % 2 !== 0 ? [] : [{
        _id: 1,
        label: 'one'
      }, {
        _id: 2,
        label: 'too'
      }];
    },

    manufacturer(i) {
      return i % 2 !== 0 ? undefined : 'manufacturer name';
    },

    local_price: {
      amount: 50,
      currency: 'GBP'
    },

    stock_lines() {
      return [{
        _id: 1,
        dimensions: {
          width: 10,
          height: 10,
          length: 10
        },
        care_instructions: 'none'
      }];
    },

    qs: 50,
    _id: i => String(i + 1) // id starts at 1

  });

  _exports.default = _default;
});