define("backalley/pods/main/curate/brands/edit/route", ["exports", "backalley/models/curate/brand"], function (_exports, _brand) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MainCurateBrandsEditRoute extends Ember.Route {
    model({
      id
    }) {
      return _brand.default.findById(id);
    }

  }

  _exports.default = MainCurateBrandsEditRoute;
});