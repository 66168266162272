define("backalley/models/tag", ["exports", "backalley/mixins/request", "joi", "backalley/util/models"], function (_exports, _request, _joi, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const validations = {
    _id: _joi.default.string(),
    created_at: _joi.default.date(),
    updated_at: _joi.default.date(),
    label: _joi.default.string().min(3).required().messages({
      'string.min': 'must be at least {#limit} characters'
    }),
    description: _joi.default.string().min(3),
    image: _joi.default.object().keys({
      url: _joi.default.string().allow('').optional(),
      width: _joi.default.number(),
      height: _joi.default.number()
    }),
    seo_content: _joi.default.string().allow('').optional(),
    is_filter: _joi.default.boolean(),
    discoverable: _joi.default.array().items({
      page: _joi.default.string(),
      display_order: _joi.default.number()
    }).optional(),
    status: _joi.default.string()
  };
  const Tag = Ember.Object.extend(_request.default, {
    /**
     * Create new tag
     * @return {Object} Promise
     */
    post() {
      return this.validate().then(tag => {
        return this.request({
          method: 'POST',
          url: this.apiURL('tags'),
          data: JSON.stringify(tag)
        });
      }).then(res => {
        const data = res.tags[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    /**
     * Save tag
     * @return {Object} Promise
     */
    save() {
      return this.validate().then(data => (0, _models.getChanges)(this._orig, data, Object.keys(validations))).then(tag => {
        return this.request({
          method: 'PUT',
          url: this.apiURL(`tags/${tag._id}`),
          data: JSON.stringify(tag)
        });
      }).then(res => {
        const data = res.tags[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    validate() {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(Tag.schema, data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    /**
     * Remove tag
     * @return {Object} Promise
     */
    remove() {
      const tag = this;
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`tags/${tag._id}`)
      });
    },

    instantiateDiscoverable() {
      this.set('discoverable', [{
        page: 'edits-hub',
        display_order: 1
      }]);
    },

    destroyDiscoverable() {
      this.set('discoverable', []);
    },

    updateDisplayOrder(displayOrder) {
      this.set('discoverable.0.display_order', parseInt(displayOrder));
    },

    updateStatus(status) {
      this.set('status', status);
    },

    isArchived: Ember.computed.equal('status', 'archived')
  });
  Tag.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data = {}) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  Tag.reopenClass(_request.default, {
    schema: _joi.default.object().keys(validations),

    create(data) {
      let c = this._super(data);

      c.setOriginalData(data);
      return c;
    },

    /**
     * Find tags
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.findWithMeta(data).then(res => res.tags);
    },

    /**
     * Find tags and returns meta too
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMeta(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('tags'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => {
        return {
          tags: res.tags.map(tag => this.create(tag)),
          meta: res.meta
        };
      });
    },

    /**
     * Find tag by id
     * @param  {String} id tag _id
     * @return {Object}    Promise
     */
    findById(id) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`tags/${id}`),
        data: {
          plain: true
        }
      }).then(res => this.create(res.tags[0]));
    }

  });
  var _default = Tag;
  _exports.default = _default;
});