define("backalley/pods/main/materials/index/route", ["exports", "backalley/mixins/loading", "backalley/models/material", "backalley/config/environment", "backalley/util/escape-regex"], function (_exports, _loading, _material, _environment, _escapeRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_loading.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    availableFilters: [{
      label: 'Date: Last updated',
      value: '-updated_at'
    }, {
      label: 'Date: First updated',
      value: 'updated_at'
    }, {
      label: 'Date: Last created',
      value: '-created_at'
    }, {
      label: 'Date: First created',
      value: 'created_at'
    }, {
      label: 'Name: A-Z',
      value: '-name'
    }, {
      label: 'Name: Z-A',
      value: 'name'
    }],

    serializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      // don't show objects in url
      if (urlKey === 'sort') {
        return value.value;
      }

      if (urlKey === 'page') return Number(value);
      return value;
    },

    deserializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      if (urlKey === 'sort') return this.availableFilters.findBy('value', value);
      return value;
    },

    model({
      q,
      page = 1,
      limit = 30,
      sort = {}
    }) {
      let query = {
        page,
        limit,
        sort: sort.value
      };

      if (q) {
        query.name = {
          $regex: `${(0, _escapeRegex.default)(q)}`,
          $options: 'i'
        };
      }

      return _material.default.findWithMeta(query).then(({
        materials,
        meta = {
          total: 3
        }
      }) => {
        return {
          search: q,
          materials,
          total: Math.ceil(meta.total / limit),
          sort: sort,
          user: this.session.user,
          availableFilters: this.availableFilters
        };
      });
    },

    actions: {
      goToPage,
      nextPage,
      prevPage,
      updateSearch,
      updateFilter,
      remove
    }
  });

  _exports.default = _default;

  function goToPage(page) {
    this.transitionTo({
      queryParams: {
        page: page
      }
    });
  }

  function nextPage() {
    const page = this.get('controller.page');
    if (page < this.get('currentModel.total')) this.transitionTo({
      queryParams: {
        page: Number(page) + 1
      }
    });
  }

  function prevPage() {
    const page = this.get('controller.page');
    if (page > 1) this.transitionTo({
      queryParams: {
        page: Number(page) - 1
      }
    });
  }

  function updateSearch(q) {
    this.set('controller.q', q);
    this.set('controller.page', 1);
  }

  function updateFilter(filter) {
    this.set('controller.sort', filter);
  }

  function remove(material) {
    if (confirm(`Are you sure you want to delete material: ${material.name}`)) {
      material.remove().then(() => {
        this.get('currentModel.materials').removeObject(material);
      });
    }
  }
});