define("backalley/pods/main/campaigns/edit/route", ["exports", "backalley/models/campaign", "moment"], function (_exports, _campaign, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    model(params) {
      return _campaign.default.findById(params.campaign_id).then(campaign => {
        if (!campaign._id) {
          this.transitionTo('main.campaigns');
        }

        return {
          campaign: _campaign.default.create(this.formatDates(campaign))
        };
      });
    },

    // Format the campaign.start_at and campaign.end_at for use with local-datetime input
    formatDates(campaign) {
      return _objectSpread(_objectSpread({}, campaign), {}, {
        start_at: (0, _moment.default)(campaign.start_at).format('YYYY-MM-DDTHH:mm'),
        end_at: (0, _moment.default)(campaign.end_at).format('YYYY-MM-DDTHH:mm')
      });
    },

    actions: {
      setImage(property, cloudinaryImage) {
        this.set(`currentModel.campaign.${property}`, cloudinaryImage.url);
      },

      saveCampaign() {
        const campaign = this.currentModel.campaign; // Simple validations to make sure we have everything

        const requiredProperties = ['name', 'verticals', 'start_at', 'end_at', 'name', 'url', 'cta_show', 'display_in_product_page', 'product_card_position', 'image_desktop_url', 'image_tablet_url', 'image_mobile_landscape_url', 'image_mobile_url', 'image_card_url', 'locale'];
        const missingProperties = [];
        requiredProperties.forEach(prop => {
          if (campaign[prop] === undefined || campaign[prop] === null || campaign[prop] === '') {
            missingProperties.push(prop);
          }
        });

        if (missingProperties.length === 0) {
          // All good, save
          return campaign.save().then(() => {
            this.notify.show('success', 'Campaign successfully updated!');
            this.transitionTo('main.campaigns');
          }).catch(message => {
            this.notify.show('error', message, 5000);
          });
        }

        return this.notify.show('error', `Required fields missing: ${missingProperties.join(', ')}`, 5000);
      }

    }
  });

  _exports.default = _default;
});