define("backalley/mirage/factories/task", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // mirage/factories/tag.js
  var _default = _emberCliMirage.default.Factory.extend({
    _id: i => String(i + 1),
    // id starts at 1
    assignee: _emberCliMirage.faker.random.uuid(),
    description: i => `description ${i}`,

    updated_at() {
      return _emberCliMirage.faker.date.past(Math.round(Math.random() * 100));
    },

    created_at() {
      return _emberCliMirage.faker.date.past(Math.round(Math.random() * 100));
    },

    filter: [],
    completed: false
  });

  _exports.default = _default;
});