define("backalley/pods/main/orders/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'email', 'name', 'boutique_q', 'sort', 'status', 'pending', 'completed', 'charge', 'vip', 'order_ref'],
    email: '',
    name: '',
    boutique_q: '',
    sort: '-ordernumber',
    status: 'null',
    order_mode: 'null',
    order_ref: '',
    page: 1,
    pending: false,
    charge: false,
    vip: false,
    completed: 'null',
    channel: 'null'
  });

  _exports.default = _default;
});