define("backalley/pods/products/controller", ["exports", "backalley/mixins/product-build-query"], function (_exports, _productBuildQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_productBuildQuery.default, {
    init() {
      const queryParams = this.getProductQueryParamsNames() || [];
      const defaults = this.getProperties(queryParams) || {};
      this.setProperties({
        queryParams,
        defaults
      });

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});