define("backalley/pods/components/path-group-form/component", ["exports", "backalley/util/group-by", "backalley/util/array-unique"], function (_exports, _groupBy, _arrayUnique) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PathGroupForm = (_dec = Ember.computed('pathGroup.shipping_path_codes'), _dec2 = Ember.computed('pathCodesGroupedBySupply'), _dec3 = Ember.computed('pathCodesGroupedBySupply', 'shippingPaths'), _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class PathGroupForm extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "classNames", ['path-group-form']);

      _defineProperty(this, "isEditable", true);

      _defineProperty(this, "pathGroup", undefined);

      _defineProperty(this, "shippingPaths", undefined);

      _defineProperty(this, "onChange", undefined);
    }

    get pathCodesGroupedBySupply() {
      const {
        pathGroup = {}
      } = this;
      const {
        shipping_path_codes: pathCodeList = []
      } = pathGroup;

      if (!pathCodeList || !pathCodeList.length) {
        return [];
      }

      const grouped = (0, _groupBy.default)(pathCodeList, pathCode => pathCode.split('>')[0]);
      return grouped;
    }

    get pathGroupAsPathSelector() {
      const grouped = this.pathCodesGroupedBySupply;
      return Object.entries(grouped).map(([supplyCode, pathCodes]) => {
        const demandCodes = pathCodes.map(pathCode => pathCode.split('>')[1]).join('|');
        return `${supplyCode}>${demandCodes}`;
      }).join(';');
    }

    get pathsBySupplyMarket() {
      const groupedLists = Object.values(this.pathCodesGroupedBySupply);
      return groupedLists.map(pathCodes => {
        return this.shippingPaths.filterByPathCodes(pathCodes);
      });
    }

    removeSelectedPathsFromPathGroup(selection) {
      const lookup = selection.map(path => path.path_codes);
      const list = this.pathGroup.shipping_path_codes.filter(pathCode => {
        return !lookup.includes(pathCode);
      });
      this.set('pathGroup.shipping_path_codes', (0, _arrayUnique.default)(list));
      this.notifyPropertyChange('pathGroup');
      this.onChange && this.onChange({
        type: 'path.remove'
      });
    }

    appendSelectedPathsToPathGroup(selection) {
      const list = this.pathGroup.shipping_path_codes.concat(selection.map(path => path.path_codes));
      this.set('pathGroup.shipping_path_codes', (0, _arrayUnique.default)(list));
      this.notifyPropertyChange('pathGroup');
      this.onChange && this.onChange({
        type: 'path.add'
      });
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "pathCodesGroupedBySupply", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "pathCodesGroupedBySupply"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pathGroupAsPathSelector", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "pathGroupAsPathSelector"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pathsBySupplyMarket", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "pathsBySupplyMarket"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeSelectedPathsFromPathGroup", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "removeSelectedPathsFromPathGroup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "appendSelectedPathsToPathGroup", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "appendSelectedPathsToPathGroup"), _class.prototype)), _class));
  _exports.default = PathGroupForm;
});