define("backalley/pods/components/notify-popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aDn33O3k",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[4,\"each\",[[24,[\"notify\",\"notifications\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",[29,[\"alert alert-\",[23,1,[\"type\"]]]]],[3,\"action\",[[23,0,[]],\"hide\",[23,1,[]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"message\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,1,[\"message\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[\"danger\",[23,1,[\"type\"]]],null]],null,{\"statements\":[[0,\"      Oops, something went wrong!\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Oops, something went wrong! (Try to refresh the page!)\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/notify-popup/template.hbs"
    }
  });

  _exports.default = _default;
});