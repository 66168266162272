define("backalley/enums/shipping-path-variant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    EXPRESS: 'express',
    CHRISTMAS: 'christmas',
    UPS_AP: 'ups_access_point'
  };
  _exports.default = _default;
});