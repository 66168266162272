define("backalley/pods/components/box-pair-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "87AAoeVr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"box-pair\"],[8],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"box-pair-key\"],[8],[1,[22,\"key\"],false],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"box-pair-value\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-checkbox\"],[8],[0,\"\\n      \"],[7,\"label\",false],[12,\"class\",\"checkbox\"],[3,\"action\",[[23,0,[]],\"toggleState\"],[[\"preventDefault\"],[false]]],[8],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"type\",\"checked\",\"tabindex\"],[\"checkbox\",[24,[\"_value\"]],\"-1\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/box-pair-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});