define("backalley/pods/main/boutique-restock-information/new/route", ["exports", "backalley/models/boutique-restock-information"], function (_exports, _boutiqueRestockInformation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MainBoutiqueRestockInformationNewRoute extends Ember.Route {
    model() {
      return new _boutiqueRestockInformation.default();
    }

  }

  _exports.default = MainBoutiqueRestockInformationNewRoute;
});