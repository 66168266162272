define("backalley/pods/components/paths-and-lanes/new/component", ["exports", "backalley/enums/path-and-lane-release-type"], function (_exports, _pathAndLaneReleaseType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PATHS_AND_LANES_STEP = {
    upload: 'upload',
    review: 'review',
    publish: 'publish'
  };

  var _default = Ember.Component.extend({
    classNames: ['paths-and-lanes-new'],
    step: PATHS_AND_LANES_STEP.upload,
    shippingPaths: undefined,
    shippingPathsReleaseDescription: undefined,
    activeShippingPaths: undefined,
    pathsFileName: undefined,
    carrierLanes: undefined,
    lanesFileName: undefined,
    carrierLanesReleaseDescription: undefined,
    activeCarrierLanes: undefined,
    actions: {
      storeShippingPaths(shippingPaths, fileName) {
        this.setProperties({
          shippingPaths,
          pathsFileName: fileName
        });
      },

      storeCarrierLanes(carrierLanes, fileName) {
        this.setProperties({
          carrierLanes,
          lanesFileName: fileName
        });
      },

      storeDescription(releaseType, description) {
        this.storeDescription(releaseType, description);

        if (releaseType === _pathAndLaneReleaseType.default.SHIPPING_PATH) {
          this.set('shippingPathsReleaseDescription', description);
          return;
        }

        if (releaseType === _pathAndLaneReleaseType.default.CARRIER_LANE) {
          this.set('carrierLanesReleaseDescription', description);
          return;
        }
      },

      storeActiveShippingPathRelease(activeRelease) {
        this.set('activeShippingPaths', activeRelease);
      },

      storeActiveCarrierLaneRelease(activeRelease) {
        this.set('activeCarrierLanes', activeRelease);
      },

      completeStep() {
        const nextStep = new Map();
        nextStep.set(PATHS_AND_LANES_STEP.upload, PATHS_AND_LANES_STEP.review);
        nextStep.set(PATHS_AND_LANES_STEP.review, PATHS_AND_LANES_STEP.publish);

        if (!nextStep.has(this.step)) {
          return;
        }

        this.set('step', nextStep.get(this.step));
      },

      goBackStep() {
        const previousStep = new Map();
        previousStep.set(PATHS_AND_LANES_STEP.review, PATHS_AND_LANES_STEP.upload);
        previousStep.set(PATHS_AND_LANES_STEP.publish, PATHS_AND_LANES_STEP.review);

        if (!previousStep.has(this.step)) {
          return;
        }

        this.set('step', previousStep.get(this.step));
      }

    }
  });

  _exports.default = _default;
});