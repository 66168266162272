define("backalley/util/product", ["exports", "backalley/enums/fieldExcludedFromAutomation"], function (_exports, _fieldExcludedFromAutomation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fieldIsExcludedFromAutomation = fieldIsExcludedFromAutomation;
  _exports.addToFieldsExcludedFromAutomation = addToFieldsExcludedFromAutomation;
  _exports.removeFromFieldsExcludedFromAutomation = removeFromFieldsExcludedFromAutomation;

  function fieldIsExcludedFromAutomation(fieldName, fieldsExcludedFromAutomation) {
    if (!fieldsExcludedFromAutomation || !fieldsExcludedFromAutomation.filter) {
      return false;
    }

    return !!fieldsExcludedFromAutomation.filter(excluded => excluded.field_name === fieldName).length;
  }

  function addToFieldsExcludedFromAutomation(fieldName, fieldsExcludedFromAutomation) {
    let newFieldsExcludedFromAutomation = [];

    if (fieldsExcludedFromAutomation && fieldsExcludedFromAutomation.slice) {
      newFieldsExcludedFromAutomation = fieldsExcludedFromAutomation.slice(0);
    }

    if (!fieldIsExcludedFromAutomation(_fieldExcludedFromAutomation.default.WEIGHT_BRACKET, newFieldsExcludedFromAutomation)) {
      newFieldsExcludedFromAutomation.push({
        field_name: fieldName
      });
    }

    return newFieldsExcludedFromAutomation;
  }

  function removeFromFieldsExcludedFromAutomation(fieldName, fieldsExcludedFromAutomation) {
    if (!fieldsExcludedFromAutomation || !fieldsExcludedFromAutomation.filter) {
      return [];
    }

    return fieldsExcludedFromAutomation.filter(excluded => excluded.field_name !== fieldName);
  }
});