define("backalley/models/info", ["exports", "backalley/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Info = Ember.Object.extend(_request.default, {});
  Info.reopenClass(_request.default, {
    /**
     * Retrieve static file
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find() {
      return this.request({
        method: 'GET',
        url: this.apiURL(`static/info.json`)
      });
    },

    /**
     * Replace static file
     * @return {Object} Promise
     */
    save(data) {
      return this.request({
        method: 'POST',
        url: this.apiURL(`static/info.json`),
        data: JSON.stringify(data)
      });
    }

  });
  var _default = Info;
  _exports.default = _default;
});