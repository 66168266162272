define("backalley/pods/components/order-edit/order-overview/component", ["exports", "backalley/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function NOOP() {}

  var _default = Ember.Component.extend({
    country: Ember.inject.service(),
    // Public API
    order: null,
    editingToggles: null,
    onEditField: NOOP,
    onUpdateTransactionValue: NOOP,
    onSave: NOOP,
    onUpdateDeliveryCountry: NOOP,
    onToggleGift: NOOP,
    config: _environment.default,
    classNames: ['box-view', 'box-view-general'],
    selectedDeliveryCountry: Ember.computed('order.delivery_country', 'country.countries.@each._id', function () {
      const deliveryCountry = this.order.delivery_country;
      return this.country.findCountryByProperty('weengs_name', deliveryCountry);
    }),
    shippingMethod: Ember.computed('order.mode', function () {
      const {
        mode
      } = this.order;

      switch (mode) {
        case 'shipping':
          return {
            icon: 'truck',
            name: 'Shipping'
          };

        case 'delivery':
          return {
            icon: 'bolt',
            name: 'Same-day Delivery'
          };

        case 'clickcollect':
        case 'clickandcollect':
          return {
            icon: 'home',
            name: 'Click & Collect'
          };

        case 'ups_access_point':
          return {
            icon: 'shield',
            name: 'UPS Access Point'
          };

        default:
          return {
            icon: 'exclamation-triangle',
            name: 'UNSUPPORTED SHIPPING METHOD'
          };
      }
    })
  });

  _exports.default = _default;
});