define("backalley/mixins/shipping", ["exports", "backalley/enums/shipping-providers"], function (_exports, _shippingProviders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SHIPPING_PROVIDER_FIELD = 'shipping_provider';

  var _default = Ember.Mixin.create({
    isUnspecifiedShipping: Ember.computed.equal(SHIPPING_PROVIDER_FIELD, _shippingProviders.default.DECIDED_AT_COURIER_BOOKING.value),
    isEasypostSpecific: Ember.computed.equal(SHIPPING_PROVIDER_FIELD, _shippingProviders.default.EASYPOST.value),
    isP2GSpecific: Ember.computed.equal(SHIPPING_PROVIDER_FIELD, _shippingProviders.default.P2G.value),
    isUsingCourierService: Ember.computed.or('isUnspecifiedShipping', 'isEasypostSpecific', 'isP2GSpecific'),
    // 'other' can cover both self-shipping and click and collect
    // to know the difference you need to analyse order.mode
    isOther: Ember.computed.equal(SHIPPING_PROVIDER_FIELD, 'other'),
    // still should be kept to show historic orders
    isLegacyWeengs: Ember.computed.equal(SHIPPING_PROVIDER_FIELD, 'weengs'),
    // handles central display and change in choice of shipping provider
    // used by both boutique model and reservation_boutique (DMP switch)
    shippingProviderLabel: Ember.computed(SHIPPING_PROVIDER_FIELD, _shippingProviders.computeShippingProviderLabel),
    // lists active choices (enum objects), allowing to switch to any
    shippingProviderActiveChoices: Ember.computed(SHIPPING_PROVIDER_FIELD, _shippingProviders.computeActiveShippingProviders),
    // lists choices (enum objects) including switch checking logic
    shippingProviderSwitchableChoices: Ember.computed(SHIPPING_PROVIDER_FIELD, _shippingProviders.computeCanShippingProviderBeChanged),
    // contains current shipping_provider field mapped to the enum
    shippingProviderSelected: Ember.computed(SHIPPING_PROVIDER_FIELD, _shippingProviders.computeShippingProviderOption),

    /**
     * Allow the shipping value to be updated. Due to Ember closure
     * actions, we need to provide the target object as part of the
     * action's params.
     *
     * @param {Object} target - object that supports .shipping_provider
     * @param {Object\String} option - either {value: 'p2g'} or 'p2g'
     */
    shippingProviderAssignToTarget(target, option) {
      if (!option || !target) throw new Error('shippingProviderAssignToTarget - Missing target or option');

      if (typeof option === 'string') {
        target.set(SHIPPING_PROVIDER_FIELD, option);
      } else if (option && option.value) {
        target.set(SHIPPING_PROVIDER_FIELD, option.value);
      }
    },

    /**
     * Allow the shipping value to be updated. Operations using current
     * context.
     *
     * @param {Object\String} option - either {value: 'p2g'} or 'p2g'
     */
    shippingProviderAssignToSelf(option) {
      if (!option) throw new Error('shippingProviderAssignToSelf - Missing option');

      if (typeof option === 'string') {
        this.set(SHIPPING_PROVIDER_FIELD, option);
      } else if (option && option.value) {
        this.set(SHIPPING_PROVIDER_FIELD, option.value);
      }
    }

  });

  _exports.default = _default;
});