define("backalley/pods/components/boutique-reservation-item/boutique-reservation-item-product/component", ["exports", "moment", "backalley/config/environment", "backalley/models/comment", "backalley/pods/main/orders/options", "backalley/pods/components/boutique-reservation-item/boutique-reservation-item-product/options"], function (_exports, _moment, _environment, _comment, _options, _options2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const UPDATED_MESSAGE = 'Updated 👍';

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    trouvaWebURL: _environment.default.trouvaWebURL,
    returnOptions: _options2.returnOptions,
    availableReservationStatusWhileCreating: _options.availableReservationStatusWhileCreating,
    returnConfirmedClicked: false,
    showManualReturnButton: false,
    isEditReservationsStatusModalOpen: false,
    customerVatRate: Ember.computed.alias('reservation.transaction_details.customer.landed_costs_details.customer_vat_rate'),
    boutiqueVatRate: Ember.computed.alias('reservation.transaction_details.boutique.product_vat_rate'),
    orderFailedPayment: Ember.computed.equal('order.status', 'fail payment'),
    showManualReturnLabel: Ember.computed.equal('reservation.requested_return_reason', 'Outside return window'),
    showPendingReturnLabel: Ember.computed('reservation.{status,requested_return,requested_return_reason}', function () {
      return this.get('reservation.requested_return_reason') !== 'Outside return window' && this.get('reservation.requested_return') && this.get('reservation.status') === 'success';
    }),
    returnReason: Ember.computed('reservation.requested_return_reason', function () {
      const reason = this.get('reservation.requested_return_reason');

      if (reason === 'Other') {
        return this.get('reservation.requested_return_explanation');
      }

      return reason;
    }),
    selectedReturnOption: Ember.computed('reservation.requested_return_reason', function () {
      const selectedReturnOption = this.get('reservation.requested_return_reason');
      return _options2.returnOptions.find(option => option.value === selectedReturnOption);
    }),
    availableReservationStatus: Ember.computed('order.isChannelMadeDotCom', function () {
      if (!this.order.isChannelMadeDotCom) {
        return _options.availableReservationStatus;
      }

      return _options.availableReservationStatus.filter(({
        value
      }) => value !== 'fail returned');
    }),
    stockLineLabel: Ember.computed( // eslint-disable-next-line ember/use-brace-expansion
    'reservation', 'reservation.selected_size', 'reservation.stock_line', 'reservation.stock_line.variants', function () {
      if (!this.reservation) {
        return;
      }

      const {
        stock_line: stockLine,
        selected_size: selectedSize
      } = this.reservation;
      const {
        size,
        variants
      } = stockLine;

      if (variants && variants.length) {
        return variants.map(({
          value
        }) => value).join(' | ');
      }

      if (selectedSize) {
        return selectedSize;
      }

      if (size && size.label) {
        return size.label;
      }

      return;
    }),
    multipleQuantityReservationStatus: Ember.computed('reservation.status', function () {
      const status = this.get('reservation.status');
      return _options.availableReservationStatus.find(({
        value
      }) => value === status).label;
    }),
    thumbnail: Ember.computed('reservation', function () {
      const productThumbnail = this.get('reservation.product.thumbnail.url');
      const stockLine = this.get('reservation.stock_line');
      const sortedStockLineImages = stockLine !== null && stockLine !== void 0 && stockLine.images.length ? stockLine.images.sort((a, b) => a.display_order - b.display_order) : [];
      const stockLineImage = sortedStockLineImages.length ? sortedStockLineImages[0].url : '';
      return stockLineImage ? stockLineImage : productThumbnail;
    }),

    updateOrderInternals(orderData) {
      const order = this.order; // update the order._orig with the new _ver and state
      // that reservation updates return!!

      order.setOriginalData(_objectSpread(_objectSpread({}, orderData), {}, {
        // but do not update the populated fields
        user: order.user
      }));
    },

    updateOrder() {
      return this.order.put().then(() => this.notify.showSuccess(UPDATED_MESSAGE)).catch(err => this.notify.showError(err));
    },

    actions: {
      updateQuantity(reservation, quantity) {
        const quantityToSet = quantity !== '' ? Number(quantity) : 1;
        Ember.set(reservation, 'editing_quantity', quantityToSet);
      },

      updateReturnReason(reservation, reason) {
        reservation.set('requested_return_reason', reason);
        this.set('reservation.requested_return_reason', reason);
      },

      saveReturnReason(reservation) {
        const reservationBoutique = this.boutique;
        this.set('boutique.status', 'return');
        reservation.set('requested_return_at', _moment.default.now());
        reservation.set('requested_return', true);
        this.set('showManualReturnButton', 'false');
        this.set('showPendingReturnLabel', 'true');
        this.setOnReservationBoutique(reservationBoutique);
        this.setOnReservation(reservation);
      },

      cancelReturn(reservation) {
        const reservationBoutique = this.boutique;
        this.set('showManualReturnButton', 'false');
        this.set('boutique.status', 'active');
        reservation.set('status', 'success');
        reservation.set('requested_return_reason', 'Outside return window');
        reservation.set('requested_return_at', null);
        this.setOnReservationBoutique(reservationBoutique);
        this.setOnReservation(reservation);
      },

      confirmReturn(reservation) {
        this.set('returnConfirmedClicked', true);
        return reservation.confirmReturn() // Add a note to this order about the return
        .then(() => {
          let newComment = _comment.default.create({
            text: `The return for "${this.get('reservation.product.name')}"
                            from ${this.get('reservation.boutique.name')} has been confirmed.
                            </br>
                            An email was sent to ${this.get('order.email')} notifying them of this
                            confirmation.
                        `,
            order: this.get('order._id')
          });

          return newComment.post();
        });
      },

      showEditReservationsStatusModal(reservation) {
        if (reservation.quantity < 2) {
          return;
        }

        this.set('isEditReservationsStatusModalOpen', true);
      },

      async updateReservations(reservation, quantity, status) {
        const reservationsToUpdate = reservation.reservationGroup.slice(0, quantity);
        reservationsToUpdate.forEach(res => res.set('status', status));

        try {
          for (reservation of reservationsToUpdate) {
            const {
              order
            } = await reservation.put({
              populate: 'product,boutique'
            });
            this.updateOrderInternals(order);
          }

          this.updateOrder();
        } catch (err) {
          this.notify.showError(err);
        }
      }

    }
  });

  _exports.default = _default;
});