define("backalley/util/request", ["exports", "backalley/config/environment", "backalley/util/promise-all-chunked"], function (_exports, _environment, _promiseAllChunked) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.request = request;
  _exports.requestAllPagesFromInventory = requestAllPagesFromInventory;
  _exports.requestAllPages = requestAllPages;
  _exports.throwError = throwError;
  _exports.throwCurateError = throwCurateError;
  _exports.apiURL = apiURL;
  _exports.inventoryApiURL = inventoryApiURL;
  _exports.packagingApiURL = packagingApiURL;
  _exports.curateApiURL = curateApiURL;

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const generateArrayOfNumbersStartingAtOneGoingUpTo = n => Object.keys(Array(n).fill()).map(v => +v + 1);

  function serialize(obj = {}, prefix) {
    // Iterate over all of the values of an object
    return Object.entries(obj).reduce((str, [key, value]) => {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        // If the value of the property is a key, serialise it in an object style
        const stringKey = prefix ? prefix + '[' + key + ']' : key;

        if (value !== null && typeof value === 'object') {
          str.push(serialize(value, stringKey)); // Guard from sending undefined
        } else if (value !== undefined) {
          const property = encodeURIComponent(stringKey) + '=' + encodeURIComponent(value);
          str.push(property);
        }

        return str;
      }
    }, []).join('&');
  }

  function request(params = {}, allowLoginRedirects = true) {
    var _params$method$toLowe, _params$method;

    const method = (_params$method$toLowe = (_params$method = params.method) === null || _params$method === void 0 ? void 0 : _params$method.toLowerCase()) !== null && _params$method$toLowe !== void 0 ? _params$method$toLowe : 'get';
    const headers = params.headers || {};
    const contentType = params.contentType || 'application/json; charset=UTF-8';
    const data = method === 'get' ? serialize(params.data) : params.data || '{}';
    const url = method === 'get' ? `${params.url}?${data}` : params.url;
    const body = ['get', 'delete'].includes(method) ? undefined : data;
    const fetchOptions = {
      method,
      headers: _objectSpread(_objectSpread({}, headers), {}, {
        'Content-Type': contentType
      }),
      credentials: 'include',
      body
    };
    return fetch(url, fetchOptions).then(async res => {
      const response = await res.text();

      if (res.ok) {
        try {
          return JSON.parse(response);
        } catch (e) {
          return response;
        }
      } else {
        try {
          return Ember.RSVP.reject([res, JSON.parse(response)]);
        } catch (e) {
          return Ember.RSVP.reject([res, response]);
        }
      }
    }).catch(exception => {
      if (!Array.isArray(exception)) {
        console.error(exception);
        throw new Error(`No response from request due to '${exception.message}'`);
      }

      const [response, errResponse] = exception;

      if (allowLoginRedirects && Ember.get(errResponse, 'errors.0.status') === 403) {
        // get the path and the query params and encode them
        const location = encodeURI(window.location.pathname + window.location.search).replace('=', '@'); // go to login page

        window.location.replace(`/login?redirect=${location}`);
        throw new Error('You seem to be logged out, please login again.');
      } else {
        const errorObj = new Error(`${response.status}: ${response.statusText}`);
        errorObj.status = response.status;
        errorObj.statusText = response.statusText;
        errorObj.responseJSON = errResponse;
        errorObj.extra = {
          method: params.method,
          url: params.url,
          headers: params.headers,
          data: params.data
        };
        throw errorObj;
      }
    });
  }
  /**
   * We need this function because Inventory doesn't support the standard
   * Promenade meta object, or at least I can't see any implementation of
   * pagination data coming back over with the response. So rather than use
   * requestAllPages() we have to use a version of it that will paginate until
   * there are no more records left to load.
   *
   * Inventory does support pagination via from=<Number>, and size=<Number> params
   * @param {object} params
   * @param {number} [params.numberOfPages=10] - number of pages to load
   * @param {number} [params.numberOfItemsPerPage=10] - number of items per page
   * @param {function} params.mapPageToItems - a method that will take
   *    the raw response and find the "array" of results that we want
   *    to aggregate. For Inventory this typically would be the resource
   *    name i.e. { pageAggregator: ({ carrier_lanes: lanes }) => lanes }
   * @param {function} [params.mapItemsToResponse] - controls the final
   *    output from this function. By default it will just return the
   *    list of aggregated items. But it can be used to mimic more of
   *    that standard Inventory response i.e. { resource_name: [] }
   * @param {...*} restParams - these will be fed to request().
   */


  function requestAllPagesFromInventory(params = {}) {
    const {
      numberOfPages = 10,
      numberOfItemsPerPage = 10,
      mapPageToItems,
      mapItemsToResponse = ({
        items
      }) => items
    } = params,
          restParams = _objectWithoutProperties(params, ["numberOfPages", "numberOfItemsPerPage", "mapPageToItems", "mapItemsToResponse"]);

    const pageNumbers = generateArrayOfNumbersStartingAtOneGoingUpTo(numberOfPages);
    const metaAggregate = {
      page_total: 0
    };
    const promisedResults = (0, _promiseAllChunked.default)(pageNumbers, {
      concurrency: 1
    }, async function (page) {
      const pacControls = this;
      const size = numberOfItemsPerPage;
      const from = Math.max((page - 1) * numberOfItemsPerPage, 0);

      const data = _objectSpread(_objectSpread({}, restParams.data), {}, {
        $offset: from,
        $limit: size
      });

      const items = await request(_objectSpread(_objectSpread({}, restParams), {}, {
        data
      })).then(mapPageToItems); // found no more items so discard this chunk + stop processing more

      if (!items.length) {
        pacControls.stopThisAndFurtherChunks();
        return null;
      } // found less than a pageful, so stop processing more chunks


      if (items.length < numberOfItemsPerPage) {
        pacControls.stopFurtherChunks();
      }

      metaAggregate.page_total++;
      return items;
    });
    return promisedResults.then(list => [...list.flat()].filter(v => v)).then(items => mapItemsToResponse({
      meta: metaAggregate,
      items
    }));
  }
  /**
   * Supports using request() but across Promenade-style pagination.
   * It will Promise.all() each page request and aggregate the results
   * into a single list.
   * @param {object} params
   * @param {function} params.mapPageToItems - a method that will take
   *    the raw response and find the "array" of results that we want
   *    to aggregate. For Promenade this typically would be the resource
   *    name i.e. { pageAggregator: ({ carrier_lanes: lanes }) => lanes }
   * @param {function} [params.mapItemsToResponse] - controls the final
   *    output from this function. By default it will just return the
   *    list of aggregated items. But it can be used to mimic more of
   *    that standard Promenade response i.e. { resource: [], meta: {..} }
   */


  function requestAllPages(params = {}) {
    const maxAllowedPages = 10;
    const maxAllowedItemsInPage = 500;

    const {
      mapPageToItems,
      mapItemsToResponse = ({
        items
      }) => items
    } = params,
          restParams = _objectWithoutProperties(params, ["mapPageToItems", "mapItemsToResponse"]);

    if (!mapPageToItems) {
      throw new Error('You must supply a mapPageToItems() to use requestAllPages()');
    } // if one of our page requests comes back with an unexpected page
    // offset. Something is wrong with either our requests or Promenade.


    const createPageOffsetChecker = expectedPage => {
      return pageOfData => {
        if (pageOfData.meta.page !== expectedPage) {
          throw new Error(`requestAllPages() got an unexpected page of data back`);
        }

        return pageOfData;
      };
    };

    return request(restParams).then(firstPageOfResults => {
      const firstResultSet = firstPageOfResults && mapPageToItems(firstPageOfResults);

      if (!firstResultSet || !firstPageOfResults) {
        throw new Error('requestAllPages() failed to fetch the first page of results');
      }

      const {
        meta
      } = firstPageOfResults;
      let {
        page: pageStart,
        page_total: pageTotal
      } = meta;

      const metaAggregate = _objectSpread({
        page_total: 0,
        pages: 0,
        total: 0
      }, meta);

      const metaAggregation = pageOfData => {
        metaAggregate.pages++;
        metaAggregate.page_total += pageOfData.meta.page_total;
        metaAggregate.total += pageOfData.meta.total;
        return pageOfData;
      };

      if (pageStart != 1) {
        throw new Error('requestAllPages() the first page of results was not page 1');
      }

      if (firstResultSet.length > maxAllowedItemsInPage) {
        throw new Error(`requestAllPages() with more than ${maxAllowedItemsInPage} results in each page is not supported`);
      }

      if (pageTotal > maxAllowedPages) {
        throw new Error(`requestAllPages() over more than ${maxAllowedPages} pages is not supported`);
      }

      const pageNumbers = generateArrayOfNumbersStartingAtOneGoingUpTo(pageTotal); // we've already loaded page one

      pageNumbers.shift();
      const promisedResults = (0, _promiseAllChunked.default)(pageNumbers, {
        concurrency: 2
      }, page => {
        const data = _objectSpread(_objectSpread({}, restParams.data), {}, {
          page
        });

        return request(_objectSpread(_objectSpread({}, restParams), {}, {
          data
        })).then(createPageOffsetChecker(page)).then(metaAggregation).then(mapPageToItems);
      });
      return promisedResults.then(list => [...firstResultSet, ...list.flat()]).then(items => mapItemsToResponse({
        meta: metaAggregate,
        items
      }));
    });
  }

  function throwError(error) {
    throw error.responseJSON ? error.responseJSON.errors[0].detail : error.message;
  }

  function throwCurateError(error) {
    if (!error.responseJSON) {
      throw error.message;
    }

    if (Ember.isArray(error.responseJSON.message)) {
      throw error.responseJSON.message[0];
    }

    throw error.responseJSON.message;
  }

  function apiURL(endpoint, version) {
    return _environment.default.apiURL + (version ? `/${version}/` : '/v2/') + (endpoint ? endpoint : '');
  }

  function inventoryApiURL(endpoint, version = '2023/api/filter') {
    if (['carrier_lanes', 'shipping_paths'].includes(endpoint)) {
      return `${_environment.default.inventoryApiURL}/${version}/${endpoint}/latest`;
    }

    return `${_environment.default.inventoryApiURL}/${version}/${endpoint}`;
  }

  function packagingApiURL(endpoint) {
    return `${_environment.default.packagingApiURL}/api/${endpoint}`;
  }

  function curateApiURL(endpoint, version = 'v1') {
    return `${_environment.default.curateApiURL}/api/${version}/${endpoint}`;
  }
});