define("backalley/pods/components/area-form/component", ["exports", "backalley/models/boutique", "ember-concurrency"], function (_exports, _boutique, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    area: null,
    _boutiqueMarkers: [],
    notify: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.fetchBoutiquesTask.perform();
    },

    fetchBoutiquesTask: (0, _emberConcurrency.task)(function* () {
      this.set('_boutiqueMarkers', []);
      const areaId = this.get('area._id');
      const boutiquesResult = yield _boutique.default.find({
        areas: areaId,
        fields: 'location,name'
      });
      const markers = boutiquesResult.map(boutique => {
        if (boutique.location) {
          return {
            title: boutique.name,
            position: {
              lat: boutique.location.lat,
              lng: boutique.location.lon
            }
          };
        } else {
          return {
            title: boutique.name,
            position: {
              lat: 0,
              lng: 0
            }
          };
        }
      });
      this.set('_boutiqueMarkers', markers);
    }).restartable(),
    didToggleMapEnabled: Ember.observer('area.map_enabled', function () {
      if (this.get('area.map_enabled') && !this.get('_boutiqueMarkers.length')) {
        this.fetchBoutiquesTask.perform();
      }
    }),
    actions: {
      updateMapCoordinates(map) {
        // Extract center of map
        const mapCenter = map.getCenter();
        const center = {
          lat: mapCenter.lat(),
          lon: mapCenter.lng()
        }; // Extract zoom level

        const zoom = map.getZoom(); // Set them on the area

        this.set('area.map_options', {
          center,
          zoom
        });
        this.notify.show('success', 'Updated coordinates & zoom level. Remember to save!');
      }

    }
  });

  _exports.default = _default;
});