define("backalley/pods/components/publisherlink-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['publisherlink-form'],
    imageFolder: Ember.computed('publisherlink', function () {
      return `publisherlink/${this.get('publisherlink._id')}`;
    })
  });

  _exports.default = _default;
});