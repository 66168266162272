define("backalley/pods/main/feeds/inspect/route", ["exports", "backalley/models/sync-integration", "backalley/models/product_sync_pending_differences", "backalley/config/environment"], function (_exports, _syncIntegration, _product_sync_pending_differences, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    async model({
      syncintegration_id,
      item_id
    }) {
      const syncIntegration = await _syncIntegration.default.findById(syncintegration_id, {
        populate: 'settings.boutique'
      });
      const productSyncPendingDifferences = await _product_sync_pending_differences.default.find(item_id);

      if (!productSyncPendingDifferences) {
        return {
          syncIntegration,
          feedSyncDiffProduct: null,
          feedSyncDiffItems: [],
          trouvaWebURL: _environment.default.trouvaWebURL
        };
      }

      const feedSyncDiffItems = [];
      const shownProperties = ["item_id", "sync_ref_id", "name", "description", "details", "price", "brand", "size_option", "quantity", "thumbnail", "images"];

      if (productSyncPendingDifferences) {
        shownProperties.forEach(key => {
          const isAdditionalImages = key === "images";
          const isThumbnail = key === "thumbnail";
          const isBrand = key === "brand";
          const isItemId = key === "item_id";
          const isSyncRef = key === "sync_ref_id";
          const diffItem = {
            key,
            trouva: productSyncPendingDifferences.product[key]
          };

          if (isSyncRef) {
            feedSyncDiffItems.push({
              key: "Feed Product Id",
              trouva: productSyncPendingDifferences.product["sync_ref_id"],
              feed: productSyncPendingDifferences.feed["sync_ref_id"],
              note: "sync_ref_id"
            });
            return;
          }

          if (isItemId) {
            feedSyncDiffItems.push({
              key: "Feed Variant Id",
              trouva: productSyncPendingDifferences.product["sync_remote_id"],
              feed: productSyncPendingDifferences.feed["sync_remote_id"],
              note: "sync_remote_id"
            });
            return;
          }

          if (isBrand) {
            feedSyncDiffItems.push({
              key: "brand",
              trouva: productSyncPendingDifferences.product["manufacturer"],
              feed: productSyncPendingDifferences.feed["manufacturer"]
            });
            return;
          }

          if (isAdditionalImages) {
            productSyncPendingDifferences.product[key].forEach((image, idx) => {
              feedSyncDiffItems.push({
                key: "additional_image",
                trouva: image.url,
                feed: productSyncPendingDifferences.feed[key] ? productSyncPendingDifferences.feed[key][idx] : ""
              });
            });
            return;
          }

          if (isThumbnail) {
            feedSyncDiffItems.push({
              key: "image",
              trouva: productSyncPendingDifferences.product[key].url,
              feed: productSyncPendingDifferences.feed[key] ? productSyncPendingDifferences.feed[key].url : ""
            });
            return;
          }

          feedSyncDiffItems.push(_objectSpread(_objectSpread({}, diffItem), {}, {
            feed: productSyncPendingDifferences.feed[key]
          }));
        });
      }

      return {
        syncIntegration,
        feedSyncDiffProduct: productSyncPendingDifferences.product,
        feedSyncDiffItems,
        trouvaWebURL: _environment.default.trouvaWebURL
      };
    }

  });

  _exports.default = _default;
});