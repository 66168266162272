define("backalley/pods/components/create-quote/component", ["exports", "backalley/util/default-to", "backalley/models/quote"], function (_exports, _defaultTo, _quote) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    classNames: ['create-quote'],
    // Public API
    user: (0, _defaultTo.default)({}),
    // Current user
    entityId: (0, _defaultTo.default)(null),
    // Id of entity
    entityType: (0, _defaultTo.default)(null),
    // [material, product, boutique, manufacturer]
    list: (0, _defaultTo.default)([]),
    published: true,
    // Published flag
    comment: '',
    // Quote comment
    url: (0, _defaultTo.default)(null),
    created_at: (0, _defaultTo.default)(new Date()),
    about_us: false,
    showForm: false,
    showHeadline: true,
    canBeAboutUs: false,
    // In case the entity can quote Trouva, show about us flag
    // Private API
    loading: false,
    emptyList: Ember.computed.empty('list'),

    didReceiveAttrs() {
      this._super(...arguments);

      this._fetchContent();
    },

    _fetchContent() {
      const entityType = this.entityType;
      const data = {
        populate: 'user',
        [entityType]: this.entityId,
        sort: '-created_at'
      };
      return _quote.default.find(data).then(res => {
        Ember.trySet(this, 'list', res);
      });
    },

    actions: {
      toggleShowForm(about = false) {
        this.set('about_us', about);
        this.toggleProperty('showForm');
      },

      create() {
        const quote = _quote.default.create({
          user: this.user._id,
          comment: this.comment,
          published: this.published,
          about_us: this.about_us
        });

        if (this.url) {
          quote.url = this.url;
        }

        if (this.created_at) {
          quote.created_at = this.created_at;
        }

        quote.set(this.entityType, this.entityId);
        this.set('loading', true);
        quote.post().then(() => {
          this.setProperties({
            comment: '',
            url: '',
            created_at: new Date(),
            showForm: false
          }); // Reload information

          this._fetchContent();

          this.notify.show('success', 'Your quote has been added!');
        }).catch(err => this.notify.show('danger', err)).finally(() => this.set('loading', false));
      },

      remove(id) {
        const quote = _quote.default.create({
          _id: id
        });

        if (confirm('Are you sure you want to delete this quote?')) {
          quote.remove().then(() => this._fetchContent());
        }
      }

    }
  });

  _exports.default = _default;
});