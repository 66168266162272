define("backalley/models/sync-integration", ["exports", "joi", "backalley/mixins/request", "backalley/util/models"], function (_exports, _joi, _request, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const SyncIntegration = Ember.Object.extend(_request.default, {
    /**
     * Create new sync-integration
     * @return {Object} Promise
     */
    post() {
      return this.validate().then(integration => {
        const payload = this._adaptPayload(integration);

        return this.request({
          method: 'POST',
          url: this.apiURL('syncIntegrations'),
          data: JSON.stringify(payload)
        });
      }).then(res => {
        const data = res.syncIntegration;
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    /**
     * Save syncIntegration
     * @return {Object} Promise
     */
    save() {
      return this.validate().then(integration => {
        const payload = this._adaptPayload(integration);

        return this.request({
          method: 'PUT',
          url: this.apiURL(`syncIntegrations/${integration._id}`),
          data: JSON.stringify(payload),
          populate: 'settings.boutique'
        });
      }).then(res => {
        const data = res.syncIntegrations[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    validate() {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(SyncIntegration.schema, data).catch(({
        label,
        message
      }) => {
        // resolve this once we've removed "brand" from being needed
        message = message.replace(/"brand"/i, '"boutique"');
        this.set(`errors.${label}`, message);
        this.set(`errors.latest`, message);
        throw new Error(message);
      });
    },

    /**
     * Remove syncIntegration
     * @return {Object} Promise
     */
    remove() {
      const integration = this;
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`syncIntegrations/${integration._id}`)
      });
    },

    _adaptPayload(payload) {
      payload = Object.assign({}, payload);
      payload.settings = Object.assign({}, payload.settings);
      payload.settings.boutique = payload.settings.boutique._id;

      if (payload.settings.collect_attrs && !Ember.isArray(payload.settings.collect_attrs)) {
        payload.settings.collect_attrs = payload.settings.collect_attrs.split(',').map(i => i.trim());
      }

      return payload;
    }

  });
  SyncIntegration.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data = {}) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  SyncIntegration.reopenClass(_request.default, {
    schema: _joi.default.object().min(4).max(5).keys({
      _id: _joi.default.string(),
      provider: _joi.default.string().valid('feed').required(),
      status: _joi.default.string().valid('active', 'inactive', 'onboarding').required(),
      brand: _joi.default.string().required(),
      settings: _joi.default.object().required().keys({
        url: _joi.default.string().uri().required(),
        boutique: _joi.default.object().required().keys({
          _id: _joi.default.string().required()
        }),
        name: _joi.default.string().required(),
        target_attr: _joi.default.string().required(),
        collect_attrs: [_joi.default.string(), _joi.default.array()],
        rules: _joi.default.array().items(_joi.default.object().keys({
          _id: _joi.default.string(),
          action: _joi.default.string(),
          params: _joi.default.array().items(_joi.default.string()),
          target_fields: _joi.default.array().items(_joi.default.string()),
          target_products: _joi.default.array().items(_joi.default.string()),
          target_products_by_brand: _joi.default.array().items(_joi.default.string()),
          target_products_by_category: _joi.default.array().items(_joi.default.string()),
          target_products_min_price_in_local_currency_pence: _joi.default.string(),
          target_products_max_price_in_local_currency_pence: _joi.default.string()
        }))
      })
    }),

    create(data) {
      let c = this._super(data);

      c.setOriginalData(data);
      return c;
    },

    /**
     * Find syncIntegrations
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.findWithMeta(data).then(res => res.syncintegrations);
    },

    /**
     * Find syncIntegrations and returns meta too
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMeta(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('syncIntegrations'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => {
        return {
          syncIntegrations: res.syncintegrations.map(integration => this.create(integration)),
          meta: res.meta
        };
      });
    },

    /**
     * Find syncIntegration by id
     * @param  {String} id syncIntegration _id
     * @return {Object}    Promise
     */
    findById(id, data = {}) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`syncIntegrations/${id}`),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => this.create(res.syncintegrations[0]));
    },

    update(id, data = {}) {
      return this.request({
        method: 'PUT',
        url: this.apiURL(`syncIntegrations/${id}`),
        data: JSON.stringify(data)
      });
    }

  });
  var _default = SyncIntegration;
  _exports.default = _default;
});