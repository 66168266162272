define("backalley/pods/main/curate/products/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MainCurateProductsRoute extends Ember.Route {}

  _exports.default = MainCurateProductsRoute;
});