define("backalley/pods/main/publishers/new/route", ["exports", "backalley/models/publisher"], function (_exports, _publisher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return {
        publisher: _publisher.default.create({
          published: true
        }),
        actions: {
          create: create.bind(this)
        }
      };
    }

  });

  _exports.default = _default;

  function create(publisher) {
    return publisher.post().then(() => this.transitionTo('main.publishers'), err => this.notify.show('err', err.message));
  }
});