define("backalley/enums/boutique-feature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    UNLIMITED_STOCK: 'unlimited_stock',
    INSIGHTS: 'insights',
    CURATE: 'curate',
    QUICKSHIP: 'quickship'
  };
  _exports.default = _default;
});