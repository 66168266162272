define("backalley/pods/components/banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EaebwIPh",
    "block": "{\"symbols\":[\"&default\",\"@namedBlocksInfo\",\"@text\",\"@title\",\"&attrs\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"banner banner--\",[23,0,[\"type\"]],\" banner--\",[23,0,[\"theme\"]],\" \",[23,0,[\"styleNamespace\"]]]]],[13,5],[8],[0,\"\\n\"],[4,\"if\",[[28,\"-has-block\",[[23,2,[]],\"icon\",false],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"banner__icon\"],[8],[0,\"\\n      \"],[14,1,[[28,\"-named-block-invocation\",[\"icon\"],null]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"banner__text\"],[8],[0,\"\\n\"],[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"      \"],[7,\"strong\",true],[8],[1,[23,4,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"      \"],[1,[23,3,[]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"-has-block\",[[23,2,[]],\"text\",false],null]],null,{\"statements\":[[0,\"      \"],[14,1,[[28,\"-named-block-invocation\",[\"text\"],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"-has-block\",[[23,2,[]],\"action\",false],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"banner__cta\"],[8],[0,\"\\n      \"],[14,1,[[28,\"-named-block-invocation\",[\"action\"],null]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/banner/template.hbs"
    }
  });

  _exports.default = _default;
});