define("backalley/pods/components/create-manual-adjustment-form/component", ["exports", "backalley/util/default-to", "moment", "backalley/models/manual-adjustment", "backalley/models/country"], function (_exports, _defaultTo, _moment, _manualAdjustment, _country) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['create-manual-adjustment-form'],
    // Public API
    boutiques: (0, _defaultTo.default)([]),
    products: (0, _defaultTo.default)([]),
    adjustments: [],
    // Private API
    notify: Ember.inject.service(),
    disableBoutiqueSelect: false,
    disableProductSelect: false,
    showAdjustmentModal: false,
    showNewAdjustmentForm: Ember.computed.equal('selectedBoutique.statements_version', 3),
    adjustment: null,
    selectedTransactionType: null,
    transactionTypes: Ember.computed('adjustmentConfig', function () {
      const {
        ADJUSTMENT_TYPES
      } = this.adjustmentConfig;
      return ADJUSTMENT_TYPES.map(x => x.split('_').lastObject).uniq();
    }),
    selectedAdjustmentType: null,
    adjustmentTypes: Ember.computed('adjustmentConfig', 'selectedTransactionType', function () {
      const {
        ADJUSTMENT_TYPES,
        ADJUSTMENT_MAPPING
      } = this.adjustmentConfig;
      const typeWithLimiter = `_${this.selectedTransactionType}`;
      return ADJUSTMENT_TYPES.filter(adjustmentType => {
        const mapping = ADJUSTMENT_MAPPING[adjustmentType];
        return adjustmentType.includes(typeWithLimiter) && mapping.transaction_entity_type === 'order';
      }).map(type => type.split(typeWithLimiter)[0]);
    }),

    didReceiveAttrs() {
      if (this.boutiques.length === 1) {
        this.set('selectedBoutique', this.boutiques[0]);
        this.set('disableBoutiqueSelect', true);
      }
    },

    selectedReservation: Ember.computed('reservations.[]', 'selectedProduct', function () {
      return this.reservations.find(r => r.product.id === this.selectedProduct.id);
    }),
    availableProducts: Ember.computed('selectedBoutique.email', function () {
      const selectedBoutique = this.selectedBoutique;
      const filterProducts = this.products.filter(product => product.boutique_email === selectedBoutique.email);

      if (filterProducts.length === 1) {
        this.set('selectedProduct', filterProducts[0]);
        this.set('disableProductSelect', true);
      }

      return filterProducts;
    }),
    formUrl: Ember.computed('selectedBoutique', 'selectedProduct', function () {
      const baseUrl = 'https://docs.google.com/forms/d/e/1FAIpQLScuM5fYtmiuu8X7oJH9rfO4gVYsYUlafHujbybsdeeYzw80vQ/viewform?usp=pp_url'; // Maps shipping provider slugs to form answers

      const shippingProviders = {
        'p2g': 'Parcel2Go',
        'weengs': 'Weengs',
        'easypost': 'Easypost',
        'other': 'Boutique shipped themselves'
      };
      const reservation = this.reservations.find(r => r.product.id === this.get('selectedProduct.id')); // Each input ID corresponds to a question on the Google form

      const inputs = {
        '2094247933': this.get('order.ordernumber'),
        // Order number
        '751675839': (0, _moment.default)(this.get('order.created_at')).format('YYYY-MM-DD'),
        // Order date YYYY-MM-DD
        '1251686957': encodeURIComponent(this.get('selectedBoutique.name')),
        // Boutique name
        '301549189': this.get('selectedBoutique.currency'),
        // Boutique currency
        '1123925659': this.get('selectedBoutique.currency'),
        // Reimburse currency (normally boutique currency)
        '1022184376': encodeURIComponent(this.get('selectedProduct.name')),
        // Product names
        '1843159201': this.get('selectedBoutique.commission_rate'),
        // Commission rate
        '960010826': encodeURIComponent(shippingProviders[this.get('selectedBoutique.shipping_provider')]),
        // Shipping provider (i.e. Parcel2Go, etc.)
        '1575220658': this.get('selectedProduct.unit_price') / 100,
        // Item price on order
        '2040383714': this.get('order.transaction_amount.currency'),
        // Customer payment currency
        '49246111': reservation._id,
        // ID of reservation
        '682136084': this.get('selectedBoutique.id'),
        // ID of boutique
        '1273473044': reservation.quantity // Quantity on reservation

      };
      return Object.keys(inputs).reduce((p, id) => p + `&entry.${id}=${inputs[id]}`, baseUrl);
    }),
    actions: {
      selectBoutique(boutique) {
        this.setProperties({
          selectedBoutique: boutique,
          selectedProduct: null
        });
      },

      selectProduct(product) {
        this.setProperties({
          selectedProduct: product
        });
      },

      async showManualAdjustmentForm() {
        const adjustmentType = `${this.selectedAdjustmentType}_${this.selectedTransactionType}`;
        const {
          iso_code_alpha_3
        } = await _country.default.findById(this.selectedBoutique.address_country);
        const vatRate = this.adjustmentConfig.ADJUSTMENT_MAPPING[adjustmentType].vat_rate[iso_code_alpha_3];

        const adjustment = _manualAdjustment.default.create({
          boutique: this.selectedBoutique.id,
          order: this.order._id,
          reservation: this.selectedReservation._id,
          product_quantity: this.selectedReservation.quantity,
          request_amount: this.selectedReservation.sales ? this.selectedReservation.transaction_sales_price : this.selectedReservation.transaction_price,
          zendesk_ticket_ids: [],
          vat_rate: vatRate || 0,
          adjustment_type: adjustmentType,
          // used to make the UI work properly
          converted_amount: this.selectedReservation.sales ? this.selectedReservation.transaction_sales_price : this.selectedReservation.transaction_price
        });

        this.setProperties({
          showAdjustmentModal: true,
          adjustment
        });
      },

      selectedTransactionType(selectedTransactionType) {
        this.setProperties({
          selectedTransactionType,
          selectedAdjustmentType: null
        });
      },

      onCompleteAdjustmentCreate(adjustment) {
        this.setProperties({
          showAdjustmentModal: false,
          selectedTransactionType: null,
          selectedAdjustmentType: null,
          adjustment: null
        });
        this.adjustments.manual_adjustments.addObject(adjustment);
        this.notify.show('success', `Manual Adjustment created successfuly ${adjustment._id}`, 5000);
      }

    }
  });

  _exports.default = _default;
});