define("backalley/models/delighted", ["exports", "backalley/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Delighted = Ember.Object.extend(_request.default, {});
  Delighted.reopenClass(_request.default, {
    /**
     * Get Responses for Email
     * @param  {Object} email Email
     * @return {Object}      Promise
     */
    search({
      email
    }) {
      return this.request({
        method: 'POST',
        headers: {
          'x-trouva-service': 'backalley'
        },
        url: this.apiURL('delighted/search'),
        data: JSON.stringify({
          email
        })
      });
    }

  });
  var _default = Delighted;
  _exports.default = _default;
});