define("backalley/models/curate/product", ["exports", "joi", "tracked-built-ins", "backalley/models/common/money", "backalley/models/curate/category", "backalley/enums/curate-product-status", "backalley/enums/curate-product-gender", "backalley/enums/curate-product-stock-line-status", "backalley/util/request", "backalley/util/models"], function (_exports, _joi, _trackedBuiltIns, _money, _category, _curateProductStatus, _curateProductGender, _curateProductStockLineStatus, _request, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _class3, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _class4, _temp2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const getDefaultMoney = (currency = 'GBP') => ({
    amount: null,
    currency
  });

  const getDefaultDimension = () => ({
    value: null,
    unit: null
  });

  const getDefaultDimensions = () => ({
    width: null,
    height: null,
    length: null
  });

  const getDefaultVariants = () => ({
    size: null,
    color: null,
    material: null,
    other: null
  });

  const getNextDisplayOrder = (array = []) => {
    if (array.length === 0) {
      return 0;
    }

    const max = array.map(({
      display_order
    }) => display_order).reduce((a, b) => Math.max(a, b));
    return max + 1;
  };

  const dimensionValidation = _joi.default.object({
    value: _joi.default.number().allow(null, ''),
    unit: _joi.default.string().messages({
      'string.empty': 'dimension units is required'
    })
  }).allow(null);

  const stockLineValidations = {
    _id: _joi.default.string(),
    sku: _joi.default.string().required(),
    name: _joi.default.string().required(),
    description: _joi.default.string().allow(''),
    status: _joi.default.string().valid(...Object.values(_curateProductStockLineStatus.default)).required(),
    quantity: _joi.default.number().default(1),
    minimum_order_quantity: _joi.default.number().min(0).default(1),
    pack_size: _joi.default.number().default(1),
    wholesale_price: _models.amountWithCurrency.required(),
    retail_price: _models.amountWithCurrency.allow(null),
    display_order: _joi.default.number().min(0).required(),
    gtin: _joi.default.string().allow(''),
    care_instructions: _joi.default.string().allow(''),
    variants: _joi.default.object({
      size: _joi.default.string().allow(null),
      material: _joi.default.string().allow(null),
      color: _joi.default.string().allow(null),
      other: _joi.default.string().allow(null)
    }).required(),
    dimensions: _joi.default.object({
      width: dimensionValidation,
      height: dimensionValidation,
      length: dimensionValidation
    }).required(),
    images: _joi.default.array().items({
      url: _joi.default.string().uri().required(),
      display_order: _joi.default.number().min(0).required()
    })
  };
  const bulkUpdateStockLineValidations = {
    sku: _joi.default.string(),
    name: _joi.default.string(),
    description: _joi.default.string().allow(''),
    status: _joi.default.string().valid(...Object.values(_curateProductStockLineStatus.default)),
    quantity: _joi.default.number(),
    minimum_order_quantity: _joi.default.number().min(0),
    pack_size: _joi.default.number(),
    wholesale_price: _models.amountWithCurrency,
    retail_price: _models.amountWithCurrency,
    display_order: _joi.default.number(),
    gtin: _joi.default.string().allow(''),
    care_instructions: _joi.default.string().allow(''),
    variants: _joi.default.object({
      size: _joi.default.string().allow(null),
      material: _joi.default.string().allow(null),
      color: _joi.default.string().allow(null),
      other: _joi.default.string().allow(null)
    }),
    dimensions: _joi.default.object({
      width: dimensionValidation,
      height: dimensionValidation,
      length: dimensionValidation
    }),
    images: _joi.default.array().items({
      url: _joi.default.string().uri().required(),
      display_order: _joi.default.number().min(0).required()
    })
  };

  const mustHavePublishedStockLineValidations = _joi.default.array().has(_objectSpread(_objectSpread({}, stockLineValidations), {}, {
    status: _joi.default.string().valid(_curateProductStockLineStatus.default.PUBLISHED).required()
  }));

  const createValidations = {
    name: _joi.default.string().required(),
    brand: _models.resourceRef.required(),
    status: _joi.default.string().valid(...Object.values(_curateProductStatus.default)).required(),
    gender: _joi.default.string().allow(null).valid(...Object.values(_curateProductGender.default)),
    category: _models.resourceRef,
    stock_lines: _joi.default.array().items(stockLineValidations).when('status', {
      is: _joi.default.string().valid(_curateProductStatus.default.PUBLISHED),
      then: mustHavePublishedStockLineValidations
    }).unique('sku').min(1).messages({
      'array.hasUnknown': "At least one stock line must be published",
      'array.unique': `Stock line at position {{#pos}} has a duplicate
                SKU "{#dupeValue.sku}" already existent in stock line at
                position {{#dupePos}}`
    })
  };

  const updateValidations = _objectSpread({}, createValidations);

  const bulkUpdateValidations = {
    _id: _joi.default.string(),
    name: _joi.default.string(),
    brand: _joi.default.string(),
    status: _joi.default.string().valid(...Object.values(_curateProductStatus.default)),
    gender: _joi.default.string().allow(null).valid(...Object.values(_curateProductGender.default)),
    category: _models.resourceRef,
    stock_lines: _joi.default.array().items(bulkUpdateStockLineValidations).unique('sku').messages({
      'array.unique': `Stock line at position {{#pos}} has a duplicate
                SKU "{#dupeValue.sku}" already existent in stock line at
                position {{#dupePos}}`
    })
  };
  let CurateProductStockLine = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, (_class = (_temp = class CurateProductStockLine {
    constructor({
      _id,
      sku,
      name,
      description,
      status = _curateProductStockLineStatus.default.PUBLISHED,
      quantity = 1,
      minimum_order_quantity = 1,
      pack_size = 1,
      wholesale_price = getDefaultMoney(),
      retail_price = getDefaultMoney(),
      display_order,
      gtin,
      care_instructions,
      variants = getDefaultVariants(),
      dimensions = getDefaultDimensions(),
      images = [],
      marked_as_out_of_stock_at
    } = {}) {
      _initializerDefineProperty(this, "_id", _descriptor, this);

      _initializerDefineProperty(this, "sku", _descriptor2, this);

      _initializerDefineProperty(this, "name", _descriptor3, this);

      _initializerDefineProperty(this, "description", _descriptor4, this);

      _initializerDefineProperty(this, "status", _descriptor5, this);

      _initializerDefineProperty(this, "quantity", _descriptor6, this);

      _initializerDefineProperty(this, "minimum_order_quantity", _descriptor7, this);

      _initializerDefineProperty(this, "pack_size", _descriptor8, this);

      _initializerDefineProperty(this, "display_order", _descriptor9, this);

      _initializerDefineProperty(this, "gtin", _descriptor10, this);

      _initializerDefineProperty(this, "care_instructions", _descriptor11, this);

      _initializerDefineProperty(this, "dimensions", _descriptor12, this);

      this._id = _id;
      this.sku = sku;
      this.name = name;
      this.description = description;
      this.status = status;
      this.quantity = quantity;
      this.minimum_order_quantity = minimum_order_quantity;
      this.pack_size = pack_size;
      this.wholesale_price = new _money.default(wholesale_price);
      this.retail_price = new _money.default(retail_price !== null && retail_price !== void 0 ? retail_price : getDefaultMoney(wholesale_price.currency));
      this.display_order = display_order;
      this.gtin = gtin;
      this.care_instructions = care_instructions;
      this.marked_as_out_of_stock_at = marked_as_out_of_stock_at;
      this.variants = new _trackedBuiltIns.TrackedObject(variants);
      this.dimensions = dimensions;
      const sortedImages = images.map(image => new _trackedBuiltIns.TrackedObject(image)).sort((a, b) => a.display_order - b.display_order);
      this.images = new _trackedBuiltIns.TrackedArray(sortedImages);
      this.enumerableFields = Object.keys(stockLineValidations);
    }

    get isPublished() {
      return this.status === _curateProductStockLineStatus.default.PUBLISHED;
    }

    get isArchived() {
      return this.status === _curateProductStockLineStatus.default.ARCHIVED;
    }

    get isNew() {
      return Ember.isEmpty(this._id);
    }

    get hasDimensions() {
      const {
        width,
        height,
        length
      } = this.dimensions;
      return [width, height, length].every(Boolean);
    }

    set dimensionsUnit(value) {
      this.dimensions.width.unit = value;
      this.dimensions.height.unit = value;
      this.dimensions.length.unit = value;
    }

    addDimensions() {
      this.dimensions = {
        width: new _trackedBuiltIns.TrackedObject(getDefaultDimension()),
        height: new _trackedBuiltIns.TrackedObject(getDefaultDimension()),
        length: new _trackedBuiltIns.TrackedObject(getDefaultDimension())
      };
    }

    removeDimensions() {
      this.dimensions = getDefaultDimensions();
    }

    addImage(url) {
      this.images.push({
        url,
        display_order: getNextDisplayOrder(this.images) || 0
      });
    }

    publish() {
      this.status = _curateProductStockLineStatus.default.PUBLISHED;
    }

    unpublish() {
      this.status = _curateProductStockLineStatus.default.UNPUBLISHED;
    }

    archive() {
      this.status = _curateProductStockLineStatus.default.ARCHIVED;
    }
    /**
     * Tracked properties are non-enumerable, therefore we need
     * to implement a custom toJSON to make JSON.stringify work
     * properly
     *
     * https://github.com/emberjs/ember.js/issues/18220
     */


    toJSON() {
      return this.enumerableFields.reduce((json, field) => {
        json[field] = this[field];
        return json;
      }, {});
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_id", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sku", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "quantity", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "minimum_order_quantity", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "pack_size", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "display_order", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "gtin", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "care_instructions", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "dimensions", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addDimensions", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "addDimensions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeDimensions", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "removeDimensions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addImage", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "addImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "publish", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "publish"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unpublish", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "unpublish"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "archive", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "archive"), _class.prototype)), _class));
  let CurateProduct = (_dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember._tracked, _dec24 = Ember._tracked, _dec25 = Ember._tracked, _dec26 = Ember._tracked, _dec27 = Ember._action, _dec28 = Ember._action, _dec29 = Ember._action, _dec30 = Ember._action, (_class3 = (_temp2 = _class4 = class CurateProduct {
    constructor({
      _id,
      name,
      slug,
      status = _curateProductStatus.default.PUBLISHED,
      brand,
      stock_lines = [],
      gender,
      category
    } = {}) {
      _initializerDefineProperty(this, "name", _descriptor13, this);

      _initializerDefineProperty(this, "slug", _descriptor14, this);

      _initializerDefineProperty(this, "status", _descriptor15, this);

      _initializerDefineProperty(this, "brand", _descriptor16, this);

      _initializerDefineProperty(this, "gender", _descriptor17, this);

      _initializerDefineProperty(this, "category", _descriptor18, this);

      _initializerDefineProperty(this, "gender", _descriptor19, this);

      _initializerDefineProperty(this, "stock_lines", _descriptor20, this);

      this._id = _id;
      this.name = name;
      this.slug = slug;
      this.status = status;
      this.brand = brand;
      this.category = category !== null && category !== void 0 && category._id ? new _category.default(category) : category;
      this.gender = gender;
      this.stockLines = stock_lines;
    }

    get images() {
      return this.stock_lines.reduce((allImages, stockLine) => [...allImages, ...stockLine.images], []);
    }

    get isPublished() {
      return this.status === _curateProductStatus.default.PUBLISHED;
    }

    get isArchived() {
      return this.status === _curateProductStatus.default.ARCHIVED;
    }

    set stockLines(array) {
      const stockLines = array.map(stockLine => new CurateProductStockLine(stockLine)).sort((a, b) => a.display_order - b.display_order);
      this.stock_lines = new _trackedBuiltIns.TrackedArray(stockLines);
    }

    addStockLine(data) {
      var _this$brand$currency, _this$brand$currency2;

      const stockLine = _objectSpread(_objectSpread({}, data), {}, {
        retail_price: getDefaultMoney((_this$brand$currency = this.brand.currency) !== null && _this$brand$currency !== void 0 ? _this$brand$currency : 'GBP'),
        wholesale_price: getDefaultMoney((_this$brand$currency2 = this.brand.currency) !== null && _this$brand$currency2 !== void 0 ? _this$brand$currency2 : 'GBP'),
        display_order: getNextDisplayOrder(this.stock_lines)
      });

      this.stock_lines.push(new CurateProductStockLine(stockLine));
    }

    duplicateStockLine(stockLine) {
      const duplicate = JSON.parse(JSON.stringify(stockLine));
      const blacklistedFields = ['_id', 'sku', 'name'];
      blacklistedFields.forEach(field => delete duplicate[field]);
      this.addStockLine(duplicate);
    }

    removeStockLine(index) {
      this.stock_lines.splice(index, 1);
    }

    static find(query = {}) {
      return (0, _request.request)({
        url: (0, _request.curateApiURL)('products'),
        data: _objectSpread({}, query)
      }).then(({
        products,
        meta = {
          page: 1,
          page_total: 1,
          total: 0
        }
      }) => ({
        products: products.map(product => new CurateProduct(product)),
        meta
      })).catch(err => {
        (0, _request.throwError)(err);
      });
    }

    static async findById(id) {
      return (0, _request.request)({
        url: (0, _request.curateApiURL)(`products/${id}`)
      }).then(({
        products: [product]
      }) => product ? new CurateProduct(product) : null).catch(error => (0, _request.throwError)(error));
    }

    async create() {
      const fields = Ember.getProperties(this, Object.keys(createValidations));
      const data = JSON.stringify(fields);

      try {
        await (0, _models.validateSchema)(CurateProduct.createSchema, JSON.parse(data));
        await (0, _request.request)({
          method: 'POST',
          url: (0, _request.curateApiURL)('products'),
          data
        });
      } catch (error) {
        (0, _request.throwError)(error);
      }
    }

    static bulkCreate(products = []) {
      if (!Ember.isArray(products)) {
        throw new Error('products must be an array');
      }

      return (0, _request.request)({
        method: 'POST',
        url: (0, _request.curateApiURL)('products/bulk'),
        data: JSON.stringify(products)
      }).then(({
        products
      }) => ({
        products: products.map(product => new CurateProduct(product))
      })).catch(error => (0, _request.throwError)(error));
    }

    static async bulkUpdate(data = []) {
      if (!Ember.isArray(data)) {
        throw new Error('products must be an array');
      }

      try {
        await (0, _models.validateSchema)(_joi.default.array().items(CurateProduct.bulkUpdateSchema), data);
        const {
          products
        } = await (0, _request.request)({
          method: 'PUT',
          url: (0, _request.curateApiURL)('products/bulk'),
          data: JSON.stringify(data)
        });
        return {
          products: products.map(product => new CurateProduct(product))
        };
      } catch (error) {
        (0, _request.throwCurateError)(error);
      }
    }

    async update() {
      const fields = Ember.getProperties(this, Object.keys(updateValidations));

      const filteredFields = _objectSpread(_objectSpread({}, fields), {}, {
        stock_lines: this.stock_lines.filter(stockLine => !stockLine.isArchived)
      });

      const data = JSON.stringify(filteredFields);

      try {
        await (0, _models.validateSchema)(CurateProduct.updateSchema, JSON.parse(data));
        const {
          products: [product]
        } = await (0, _request.request)({
          method: 'PUT',
          url: (0, _request.curateApiURL)(`products/${this._id}`),
          data
        });
        const {
          stock_lines
        } = product;
        this.stockLines = stock_lines;
      } catch (error) {
        (0, _request.throwCurateError)(error);
      }
    }

    archive() {
      return (0, _request.request)({
        method: 'DELETE',
        url: (0, _request.curateApiURL)(`products/${this._id}`)
      }).then(() => {
        this.status = _curateProductStatus.default.ARCHIVED;
        this.stock_lines.forEach(stockLine => stockLine.archive());
      }).catch(error => {
        (0, _request.throwError)(error);
      });
    }

  }, _defineProperty(_class4, "createSchema", _joi.default.object(createValidations)), _defineProperty(_class4, "updateSchema", _joi.default.object(updateValidations)), _defineProperty(_class4, "bulkUpdateSchema", _joi.default.object(bulkUpdateValidations)), _defineProperty(_class4, "imageTransformation", ["w_80", "h_80", "f_auto", "q_auto:eco", "dpr_auto", "c_pad", "b_white"].join(",")), _temp2), (_descriptor13 = _applyDecoratedDescriptor(_class3.prototype, "name", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class3.prototype, "slug", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class3.prototype, "status", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class3.prototype, "brand", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class3.prototype, "gender", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class3.prototype, "category", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class3.prototype, "gender", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class3.prototype, "stock_lines", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "addStockLine", [_dec27], Object.getOwnPropertyDescriptor(_class3.prototype, "addStockLine"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "duplicateStockLine", [_dec28], Object.getOwnPropertyDescriptor(_class3.prototype, "duplicateStockLine"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "removeStockLine", [_dec29], Object.getOwnPropertyDescriptor(_class3.prototype, "removeStockLine"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "archive", [_dec30], Object.getOwnPropertyDescriptor(_class3.prototype, "archive"), _class3.prototype)), _class3));
  _exports.default = CurateProduct;
});