define("backalley/pods/main/discounts/index/route", ["exports", "backalley/models/discount", "backalley/util/escape-regex"], function (_exports, _discount, _escapeRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      type: {
        refreshModel: true
      },
      channel: {
        refreshModel: true
      }
    },

    model({
      q,
      sort,
      channel,
      type,
      page,
      limit
    }) {
      return _discount.default.findWithMeta({
        page,
        limit,
        code: q ? {
          $regex: `^${(0, _escapeRegex.default)(q)}`,
          $options: 'i'
        } : undefined,
        sort: sort,
        type: type === 'null' ? undefined : type,
        channel: channel === 'null' ? undefined : channel,
        populate: 'currency'
      }).then(({
        discounts,
        meta
      }) => {
        return {
          discounts,
          meta,
          search: q,
          sort,
          channel,
          type
        };
      });
    }

  });

  _exports.default = _default;
});