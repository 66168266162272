define("backalley/pods/components/google-map/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Aqd7n8/B",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\" Map will be inserted by component.js \"],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"map\"],[10,\"style\",\"height: 350px; width: 700px\"],[8],[9],[0,\"\\n\\n\"],[14,1,[[28,\"hash\",null,[[\"map\",\"center\",\"zoom\"],[[24,[\"_map\"]],[24,[\"center\"]],[24,[\"zoom\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/google-map/template.hbs"
    }
  });

  _exports.default = _default;
});