define("backalley/pods/main/areas/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'q', 'sort', 'featured', 'published'],
    page: 1,
    q: '',
    sort: {
      label: 'Date: last updated',
      value: '-updated_at'
    },
    featured: false,
    published: false
  });

  _exports.default = _default;
});