define("backalley/pods/main/frames/edit/route", ["exports", "backalley/models/frame"], function (_exports, _frame) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MainFramesEditRoute extends Ember.Route {
    async model({
      slug
    }) {
      try {
        return await _frame.default.findBySlug(slug);
      } catch (e) {
        return this.transitionTo('main.frame.index');
      }
    }

  }

  _exports.default = MainFramesEditRoute;
});