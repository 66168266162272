define("backalley/pods/main/statements/boutique/route", ["exports", "backalley/models/statement", "moment"], function (_exports, _statement, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      until: {
        refreshModel: true
      },
      lock_until: {
        refreshModel: true
      }
    },

    serializeQueryParam(value = {}, urlKey = '') {
      if (urlKey === 'until') {
        return (0, _moment.default)(value).endOf('day').toISOString();
      }

      if (urlKey === 'lock_until') {
        return (0, _moment.default)(value).endOf('day').toISOString();
      }

      return value;
    },

    model({
      until,
      lock_until,
      boutique_id
    }) {
      const query = {
        until,
        boutique: boutique_id
      };

      if (lock_until) {
        query.lock_until = lock_until;
      }

      return _statement.default.find(query).then(statements => statements.filter(s => s.boutique === boutique_id)) // VERY Important cuz api also returns "adjusment only" statements as well for other boutiques!
      .then(res => {
        return {
          until,
          lock_until,
          boutique_id,
          queryParams: {
            until: (0, _moment.default)(until).format('YYYY-MM-DD'),
            lock_until
          },
          statements: res,
          actions: {
            setUntilDate: setUntilDate.bind(this),
            setLockUntilDate: setLockUntilDate.bind(this),
            markAsPaid: markAsPaid.bind(this),
            sendStatement: sendStatement.bind(this)
          }
        };
      });
    }

  });

  _exports.default = _default;

  function setUntilDate(event) {
    this.set('currentModel.loading', true);

    function debounce() {
      const date = event.target ? event.target.value : event;

      if ((0, _moment.default)(date).isValid() && (0, _moment.default)(date).isBefore((0, _moment.default)().startOf('day').subtract(1, 'days'))) {
        this.set('currentModel.loading', false);
        this.set('controller.until', date);
      } else {
        this.notify.show('danger', 'Make sure that the date chosen is more than 1 day ago.');
      }
    }

    debounce(this, debounce, 3000);
  }

  function setLockUntilDate(event) {
    this.set('currentModel.loading', true);

    function debounce() {
      const date = event.target ? event.target.value : event;
      this.set('controller.lock_until', date);
      this.set('currentModel.loading', false);
    }

    debounce(this, debounce, 3000);
  }

  function markAsPaid(statement) {
    return statement.markAsPaid().then(() => {
      this.notify.show('success', 'Marked as paid!');
    }).catch(() => {
      this.notify.show('danger', 'Oops. Something went wrong.');
    });
  }

  function sendStatement(statement) {
    statement.set('until', this.get('currentModel.until'));
    statement.set('lock_until', this.get('currentModel.lock_until'));
    if (statement.get('total_payout') > 0) return this.notify.show('danger', 'There is no outstanding amount, only the boutique owns us, so no statement is being sent.');
    return statement.send().then(() => {
      this.notify.show('success', 'Relax, your statement went through! Going back to statements.');
    }).catch(() => {
      this.notify.show('danger', 'Oops. Something went wrong.');
    });
  }
});