define("backalley/pods/main/materials/new/route", ["exports", "backalley/models/material"], function (_exports, _material) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return {
        material: _material.default.create({}),
        actions: {
          create: create.bind(this)
        }
      };
    }

  });

  _exports.default = _default;

  function create(material) {
    return material.post().then(() => this.transitionTo('main.materials'), err => this.notify.show('err', err.message));
  }
});