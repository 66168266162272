define("backalley/pods/components/landing-page/partials/image-upload/component", ["exports", "backalley/util/default-to", "backalley/helpers/cloudinary-image"], function (_exports, _defaultTo, _cloudinaryImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    // Public API
    item: (0, _defaultTo.default)(null),
    videoItem: (0, _defaultTo.default)(null),
    imageFolder: (0, _defaultTo.default)(null),
    onRemoveDesktopImage: (0, _defaultTo.default)(() => {}),
    onRemoveMobileImage: (0, _defaultTo.default)(() => {}),
    onUploadDesktopImage: (0, _defaultTo.default)(() => {}),
    onUploadMobileImage: (0, _defaultTo.default)(() => {}),
    desktopLabel: (0, _defaultTo.default)('Desktop Image'),
    mobileLabel: (0, _defaultTo.default)('Mobile Image'),
    // mobile image can either be an object or a string.
    // string: Old Landing page components (box v1, ...)
    // object: New Landing page components (box v2, ...)
    // This computed property picks out the correct url path
    mobileUrl: Ember.computed('item.mobile', 'item.mobile.url', function () {
      const mobile = this.get('item.mobile'); // Exit if the mobile property is null,undefined or empty string.

      if (!mobile) return;

      if (typeof mobile === 'string') {
        return mobile;
      }

      return mobile.url;
    }),
    hasVideo: Ember.computed('videoItem.desktop', 'videoItem.desktop.url', function () {
      const videoItem = this.videoItem;
      return videoItem && videoItem.desktop && videoItem.desktop.url;
    }),
    // Similar to the mobile image, this checks if the image is a string or object and returns
    // the correct value for the image
    desktopUrl: Ember.computed('videoItem.desktop', 'videoItem.desktop.url', 'item.desktop', 'item.desktop.url', function () {
      const desktop = this.get('item.desktop');
      const desktopVideo = this.get('videoItem.desktop'); // Exit if the desktop property is null,undefined or empty string.

      if (!desktopVideo && !desktop) return;

      if (desktopVideo) {
        if (typeof desktopVideo === 'string') {
          return desktopVideo;
        } else if (desktopVideo.url) {
          return desktopVideo.url;
        }
      }

      if (typeof desktop === 'string') return desktop;
      return desktop.url;
    }),
    maxFileSizeLabel: Ember.computed('maxFileSize', function () {
      const maxFileSize = this.maxFileSize;
      if (!maxFileSize) return ''; // Divide by 1000000 to convert to MB

      return `Max ${maxFileSize / 1000000} MB`;
    }),
    actions: {
      setDesktopImage(image) {
        // Replace mobile url with transformed image for mobile payload
        const mobileImage = _objectSpread(_objectSpread({}, image), {}, {
          url: (0, _cloudinaryImage.toCloudinary)(image.url, 'w_480,h_240', 'jpg')
        });

        this.onUploadDesktopImage(image);
        this.onUploadMobileImage(mobileImage);
      },

      setMobileImage(image) {
        this.onUploadMobileImage(image);
      }

    }
  });

  _exports.default = _default;
});