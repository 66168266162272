define("backalley/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    server.createList('zone', 10);
    server.createList('currency', 5);
    server.createList('country', 5); // Create 5 boutiques with GBP currency and 3 with EUR
    // to test under various currencies

    server.createList('boutique', 20, {
      currency: 'GBP'
    });
    server.createList('boutique', 15, {
      currency: 'EUR'
    });
    server.db.boutiques.forEach(boutique => {
      server.createList('currencyStatement', 1, {
        currency: boutique.currency,
        boutique: boutique._id
      });
    });
  }
});