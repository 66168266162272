define("backalley/pods/components/boolean-label/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public
    booleanValue: false,
    // The value
    trueLabel: "Yes",
    // If true
    falseLabel: "No" // If false

  });

  _exports.default = _default;
});