define("backalley/mixins/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    beforeModel() {
      if (this.currentModel) this.set('currentModel.loading', true);
      return this._super();
    },

    afterModel() {
      if (this.currentModel) this.set('currentModel.loading', false);
      return this._super();
    }

  });

  _exports.default = _default;
});