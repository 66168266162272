define("backalley/pods/login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      redirect: {
        refreshModel: false
      }
    },

    model(params) {
      return {
        user: Ember.Object.create(),
        params
      };
    },

    actions: {
      login(params, user) {
        return this.session.authenticate(user.email, user.password).then(() => {
          // decode the redirect param and go back to that page
          if (params.redirect) {
            window.location.replace(params.redirect.replace('@', '='));
          } else {
            return this.transitionTo('main');
          }
        }).catch(err => {
          let errResponse = err.responseJSON;

          if (errResponse && errResponse.errors && errResponse.errors[0]) {
            this.notify.show('error', errResponse.errors[0].detail);
          } else {
            this.notify.show('error', 'Something went wrong!');
          }
        });
      }

    }
  });

  _exports.default = _default;
});