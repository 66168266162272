define("backalley/models/color", ["exports", "joi", "backalley/util/models", "backalley/util/request"], function (_exports, _joi, _models, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const validations = {
    _id: _joi.default.string(),
    created_at: _joi.default.date(),
    updated_at: _joi.default.date(),
    label: _joi.default.string().min(3).message('must be at least {#limit} characters').max(10).required(),
    value: _joi.default.string().length(7).regex(/^#(?:[0-9a-fA-F]{3}){1,2}$/).message('must be 6 hexadecimal characters. eg.: 00ff00').required()
  };
  const Color = Ember.Object.extend({
    post() {
      return this.validate().then(color => {
        return (0, _request.request)({
          method: 'POST',
          url: (0, _request.apiURL)('colors'),
          data: JSON.stringify(color)
        });
      }).then(res => {
        const data = res.colors[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    save() {
      return this.validate().then(data => (0, _models.getChanges)(this._orig, data, Object.keys(validations))).then(color => {
        return (0, _request.request)({
          method: 'PUT',
          url: (0, _request.apiURL)(`colors/${color._id}`),
          data: JSON.stringify(color)
        });
      }).then(res => {
        const data = res.colors[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    validate() {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(Color.schema, data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    /**
     * Remove color
     * @return {Object} Promise
     */
    remove() {
      const color = this;
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`colors/${color._id}`)
      });
    }

  });
  Color.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  Color.reopenClass({
    schema: _joi.default.object().min(2).max(5).keys(validations).and('label', 'value'),

    create(data = {}) {
      let c = this._super(data);

      c.setOriginalData(data);
      return c;
    },

    /**
     * Find colors
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.findWithMeta(data).then(res => res.colors);
    },

    /**
     * Find colors and returns meta too
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMeta(data) {
      return (0, _request.request)({
        url: (0, _request.apiURL)('colors'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => {
        return {
          colors: res.colors.map(color => this.create(color)),
          meta: res.meta
        };
      });
    },

    /**
     * Find color by id
     * @param  {String} id color _id
     * @return {Object}    Promise
     */
    findById(id) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.apiURL)(`colors/${id}`),
        data: {
          plain: true
        }
      }).then(res => this.create(res.colors[0]));
    }

  });
  var _default = Color;
  _exports.default = _default;
});