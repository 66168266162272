define("backalley/services/logger", ["exports", "@streethub/browser-logger", "backalley/config/environment"], function (_exports, _browserLogger, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Service.extend({
    _browserLogger: null,

    init() {
      this._super(...arguments);

      const logger = new _browserLogger.default(_objectSpread(_objectSpread({}, _environment.default.logger), {}, {
        environment: _environment.default.environment
      }));
      this.set('_browserLogger', logger);
    },

    info() {
      return this._browserLogger.info(...arguments);
    },

    warn() {
      return this._browserLogger.warn(...arguments);
    },

    debug() {
      return this._browserLogger.debug(...arguments);
    },

    error() {
      return this._browserLogger.error(...arguments);
    }

  });

  _exports.default = _default;
});