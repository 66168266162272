define("backalley/pods/components/product-amendment-card/common_mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    classNames: ['product-amendment-card'],
    classNameBindings: ['_cardState'],
    isEditing: Ember.computed.alias('amendmentChangeset._edit'),
    isPending: Ember.computed.equal('amendmentChangeset.status', 'pending'),
    isResolved: Ember.computed.equal('amendmentChangeset.status', 'resolved'),
    isApproved: Ember.computed.equal('amendmentChangeset.status', 'approved'),
    isRejected: Ember.computed.equal('amendmentChangeset.status', 'rejected'),
    _cardState: Ember.computed('amendment.status', function () {
      // product-amendment-card--pending when the change is awaiting action from the boutique
      // product-amendment-card--resolved when the change has been made by the boutique
      // product-amendment-card--approved when the change has been approved by the curation team
      // product-amendment-card--rejected when the change has been rejected by the curation team
      const state = Ember.get(this, 'amendment.status');
      return `product-amendment-card--${state}`;
    })
  });

  _exports.default = _default;
});