define("backalley/pods/main/boutiques/edit/sections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = ['Basic info', 'Profile', 'Accounting', 'Logistics', 'T&Cs', 'Detailed info', 'Analytics', 'Feed'].map((section, i) => ({
    label: section,
    value: Ember.String.dasherize(section),
    index: i
  }));

  _exports.default = _default;
});