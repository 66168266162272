define("backalley/pods/main/shipping-group-configurations/edit/route", ["exports", "backalley/models/shipping-group-configuration", "backalley/util/error-nicely"], function (_exports, _shippingGroupConfiguration, _errorNicely) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShippingGroupConfigurationEditRoute = (_dec = Ember.inject.service, (_class = (_temp = class ShippingGroupConfigurationEditRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "pathsAndLanes", _descriptor, this);
    }

    async model({
      id: _id
    } = {}) {
      try {
        const pathAndLanesFromInventory = await this.pathsAndLanes.fetchPathsAndLanesFromInventory().catch((0, _errorNicely.errorNicely)('Failed to load Paths & Lanes', this.logger));
        const sgcList = await _shippingGroupConfiguration.default.find({
          _id
        }).catch((0, _errorNicely.errorNicely)(`Failed to load the specifc SGC ${_id}`, this.logger));
        const [sgc] = sgcList || []; // store snapshot for comparison

        const sgcBeforeChanges = new _shippingGroupConfiguration.default(sgc);
        return {
          sgc,
          sgcBeforeChanges,
          carrierLanes: pathAndLanesFromInventory.carrierLanes,
          shippingPaths: pathAndLanesFromInventory.shippingPaths
        };
      } catch (exception) {
        this.logger.error(exception);
        return {
          error: exception.message
        };
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "pathsAndLanes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ShippingGroupConfigurationEditRoute;
});