define("backalley/models/shipment-rate", ["exports", "joi", "backalley/util/request", "backalley/util/models"], function (_exports, _joi, _request, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ShipmentRate {
    constructor({
      rate_id,
      zone,
      weight,
      rate
    }) {
      const [source, upsZone] = zone.split('_');
      this.rateId = rate_id;
      this.source = source;
      this.upsZone = upsZone;
      this.weight = weight;
      this.rate = rate;
    }

    static bulkUpdateOrCreate(params = {}) {
      return (0, _models.validateSchema)(_joi.default.array().items(this.schema), params).then(() => {
        return (0, _request.request)({
          method: 'PUT',
          url: (0, _request.apiURL)('shipment_rates'),
          data: JSON.stringify(params)
        });
      });
    }

    static find(params = {}) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.apiURL)('shipment_rates'),
        data: _objectSpread({}, params)
      }).then(({
        shipment_rates,
        meta
      }) => {
        return {
          shipmentRates: shipment_rates.map(shipmentRate => new ShipmentRate(shipmentRate)),
          meta
        };
      }).catch(err => {
        throw err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
      });
    }

  }

  _exports.default = ShipmentRate;

  _defineProperty(ShipmentRate, "schema", _joi.default.object().keys({
    weight: _joi.default.number().required()
  }));
});