define("backalley/pods/components/curate-product-version-two-form/stock-line/component", ["exports", "@glimmer/component", "backalley/enums/currencies"], function (_exports, _component, _currencies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurateProductVersionTwoFormStockLineComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class CurateProductVersionTwoFormStockLineComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isOpen", _descriptor, this);

      _defineProperty(this, "currencyOptions", _currencies.default.currencies);

      _defineProperty(this, "maxImageSize", 15000000);
    }

    toggleIsOpen() {
      if (this.args.stockLine.isArchived) {
        return;
      }

      this.isOpen = !this.isOpen;
    }

    parseInt(string) {
      return parseInt(string, 10);
    }

    onChangeCurrency(currency) {
      this.args.stockLine.retail_price.currency = currency.currency_code;
      this.args.stockLine.wholesale_price.currency = currency.currency_code;
    }

    addImages(images) {
      images.forEach(image => this.args.stockLine.addImage(image.url));
    }

    duplicateStockLine() {
      const {
        product,
        stockLine,
        onDuplicateStockLine
      } = this.args;
      product.duplicateStockLine(stockLine);
      onDuplicateStockLine === null || onDuplicateStockLine === void 0 ? void 0 : onDuplicateStockLine();
      this.isOpen = !this.isOpen;
    }

    removeStockLine(index) {
      const {
        product,
        stockLine,
        onRemoveStockLine
      } = this.args;

      if (!confirm(`Are you sure you want to ${stockLine.isNew ? "remove" : "archived"} this stock line? This action cannot be undone.`)) {
        return;
      }

      stockLine.isNew ? product.removeStockLine(index) : stockLine.archive();
      onRemoveStockLine === null || onRemoveStockLine === void 0 ? void 0 : onRemoveStockLine();
      this.isOpen = !this.isOpen;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isOpen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleIsOpen", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "toggleIsOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "parseInt", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "parseInt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeCurrency", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeCurrency"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addImages", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "addImages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "duplicateStockLine", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "duplicateStockLine"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeStockLine", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "removeStockLine"), _class.prototype)), _class));
  _exports.default = CurateProductVersionTwoFormStockLineComponent;
});