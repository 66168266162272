define("backalley/helpers/or-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.orSwitch = orSwitch;
  _exports.default = void 0;

  function orSwitch([...args]) {
    return args.reduce((sum, elem) => {
      return sum || elem;
    }, false);
  }

  var _default = Ember.Helper.helper(orSwitch);

  _exports.default = _default;
});