define("backalley/pods/main/currencies/new/route", ["exports", "backalley/models/currency"], function (_exports, _currency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return {
        currency: _currency.default.create({}),
        actions: {
          create: create.bind(this)
        }
      };
    }

  });

  _exports.default = _default;

  function create(currency) {
    return currency.post().then(() => this.transitionTo('main.currencies'), err => this.notify.show('err', err.message));
  }
});