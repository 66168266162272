define("backalley/pods/main/shipment-zones/new/route", ["exports", "backalley/models/shipment-zone"], function (_exports, _shipmentZone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return {
        source: null,
        destination: null,
        upsZone: null
      };
    },

    actions: {
      create({
        source,
        destination,
        upsZone
      }) {
        return _shipmentZone.default.create({
          source,
          destination,
          ups_zone: upsZone
        }).then(() => this.transitionTo('main.shipment-zones'));
      }

    }
  });

  _exports.default = _default;
});