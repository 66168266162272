define("backalley/pods/main/tasks/index/route", ["exports", "backalley/mixins/loading", "backalley/models/task", "backalley/models/product", "backalley/mixins/product-build-query"], function (_exports, _loading, _task, _product, _productBuildQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const TYPE_KEY = 'type';
  const STATUS_KEY = 'status';
  const SORT_KEY = 'sort';
  const PAGE_KEY = 'page';

  var _default = Ember.Route.extend(_loading.default, _productBuildQuery.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      type: {
        refreshModel: true
      }
    },

    serializeQueryParam(value = {}, urlKey = '') {
      // don't show objects in url
      if (urlKey === TYPE_KEY) return value.value;
      if (urlKey === STATUS_KEY) return value.value;
      if (urlKey === SORT_KEY) return value.value;
      if (urlKey === PAGE_KEY) return Number(value);
      return value;
    },

    deserializeQueryParam(value = {}, urlKey = '') {
      if (urlKey === TYPE_KEY) return this.availableTypes.findBy('value', value);
      if (urlKey === SORT_KEY) return this.availableFilters.findBy('value', value);
      if (urlKey === STATUS_KEY) return this.availableStatus.findBy('value', value);
      return value;
    },

    availableFilters: [{
      label: 'Date: All',
      value: 'all'
    }, {
      label: 'Date: Start date',
      value: '-created_at'
    }, {
      label: 'Date: End date',
      value: '-completed_at'
    }, {
      label: 'Date: Last updated',
      value: '-updated_at'
    }],
    availableStatus: [{
      label: 'Status: Show all',
      value: 'all'
    }, {
      label: 'Status: Completed',
      value: 'true'
    }, {
      label: 'Status: Non completed',
      value: 'false'
    }],
    availableTypes: [{
      label: 'Task: Show all',
      value: undefined
    }, {
      label: 'Task: Published to feature',
      value: 'published_to_feature'
    }, {
      label: 'Task: Published to review',
      value: 'published_to_review'
    }],

    model({
      q,
      page = 1,
      limit = 10,
      sort = {},
      status = {},
      type = {}
    }) {
      let query = {
        page,
        limit,
        sort: sort.value,
        populate: 'assignee'
      };

      if (status.value !== 'all') {
        query.completed = status.value === 'true';
      }

      if (type.value) {
        query.type = type.value;
      }

      return _task.default.findWithMeta(query).then(({
        tasks,
        meta = {
          total: 0
        }
      }) => {
        tasks.map(this._addLeftToWorkOnPromise.bind(this));
        return {
          tasks: tasks,
          showFilter: false,
          availableTypes: this.availableTypes,
          availableFilters: this.availableFilters,
          availableStatus: this.availableStatus,
          sort: sort,
          status,
          type,
          q,
          total: Math.ceil(meta.total / limit),
          page
        };
      });
    },

    actions: {
      readableType(type) {
        return type.split('_').map(i => i[0].toUpperCase() + i.substring(1)).join(' ');
      },

      toggleFilters() {
        this.toggleProperty('currentModel.showFilter');
      },

      updateFilter() {},

      setStatus(status) {
        this.set('controller.status', status);
      },

      setSort(sort) {
        this.set('controller.sort', sort);
      },

      setType(type) {
        this.set('controller.type', type);
      },

      goToPage(page) {
        this.transitionTo({
          queryParams: {
            page
          }
        });
      },

      nextPage() {
        const page = this.get('controller.page');
        if (page < this.get('currentModel.total')) this.transitionTo({
          queryParams: {
            page: Number(page) + 1
          }
        });
      },

      prevPage() {
        const page = this.get('controller.page');
        if (page > 1) this.transitionTo({
          queryParams: {
            page: Number(page) - 1
          }
        });
      }

    },

    _addLeftToWorkOnPromise(task) {
      const params = _objectSpread(_objectSpread({}, this.getProperties(this.getProductQueryParamsNames())), this.getParams(task.url));

      ['q_boutique', 'q_category', 'q_color', 'q_tag', 'q_manufacturer'].forEach(label => {
        if (params[label]) {
          let [value] = params[label].split('|');
          params[label] = {
            label,
            value
          };
        }
      });
      task.set('leftToWorkOn', _product.default.findWithMetaAndCreate(this.buildQuery(params, 1)).then(({
        meta
      }) => meta ? meta.total : 0));
    }

  });

  _exports.default = _default;
});