define("backalley/pods/main/transactions/index/route", ["exports", "backalley/models/transaction", "backalley/models/currency", "backalley/mixins/loading", "backalley/mixins/request"], function (_exports, _transaction, _currency, _loading, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_loading.default, {
    model() {
      const defaultCurrency = {
        name: 'Every Currency'
      };
      return _currency.default.find().then(currencies => {
        return {
          selected: {
            from: '',
            to: '',
            currency: defaultCurrency
          },
          options: {
            currencies: [defaultCurrency].concat(currencies)
          }
        };
      });
    },

    actions: {
      selectCurrency: function (option) {
        this.set('currentModel.selected.currency', option);
      },
      export: function (from, to, currency) {
        if (!from || !to) {
          this.notify.show('error', 'Start/End dates are required', 14000);
        } else {
          const url = _transaction.default.getExportTransactionsUrl({
            currency,
            start_date: from,
            end_date: to
          });

          window.open(url, '_blank');
        }
      }
    }
  });

  _exports.default = _default;
});