define("backalley/pods/main/publishers/edit/route", ["exports", "backalley/models/publisher"], function (_exports, _publisher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      publisher_id
    }) {
      return _publisher.default.findById(publisher_id).then(publisher => {
        return {
          publisher,
          user: this.session.user,
          actions: {
            update: update.bind(this)
          }
        };
      });
    }

  });

  _exports.default = _default;

  function update(publisher) {
    return publisher.save().then(() => this.transitionTo('main.publishers'), err => {
      this.notify.show('err', err.message);
    });
  }
});