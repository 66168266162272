define("backalley/models/curate/category", ["exports", "joi", "backalley/util/models", "backalley/util/request", "backalley/enums/curate-category-status"], function (_exports, _joi, _models, _request, _curateCategoryStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _class2, _temp;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const updateValidations = {
    name: _joi.default.string(),
    status: _joi.default.string().valid(...Object.values(_curateCategoryStatus.default))
  };
  let CurateCategory = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, (_class = (_temp = _class2 = class CurateCategory {
    constructor({
      _id,
      name,
      status,
      ancestors = [],
      google_shop_taxonomy_id,
      google_shop_taxonomy_name
    }) {
      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "status", _descriptor2, this);

      _initializerDefineProperty(this, "ancestors", _descriptor3, this);

      _initializerDefineProperty(this, "descendants", _descriptor4, this);

      this._id = _id;
      this.name = name;
      this.status = status;
      this.ancestors = ancestors.map(ancestor => new CurateCategory(ancestor)).sort((a, b) => a.ancestors.length - b.ancestors.length);
      this.google_shop_taxonomy_id = google_shop_taxonomy_id;
      this.google_shop_taxonomy_name = google_shop_taxonomy_name;
    }

    get hierarchyName() {
      return [...this.ancestors, this].map(({
        name
      }) => name).join(" > ");
    }

    get isPublished() {
      return this.status === _curateCategoryStatus.default.PUBLISHED;
    }

    async attachDescendants() {
      const {
        categories: descendants
      } = await CurateCategory.find({
        parent: this._id,
        limit: 56,
        sort: 'name'
      });
      this.descendants = descendants;
    }

    static find(query = {}) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.curateApiURL)('categories'),
        data: _objectSpread({}, query)
      }).then(({
        categories,
        meta
      }) => {
        return {
          categories: categories.map(category => new CurateCategory(category)),
          meta
        };
      }).catch(error => (0, _request.throwError)(error));
    }

    static async findById(id, query = {}) {
      try {
        const {
          categories: [category]
        } = await (0, _request.request)({
          url: (0, _request.curateApiURL)(`categories/${id}`),
          data: _objectSpread({}, query)
        });
        return !category ? null : new CurateCategory(category);
      } catch (error) {
        (0, _request.throwError)(error);
      }
    }

    async update() {
      const data = Ember.getProperties(this, Object.keys(updateValidations));

      try {
        await (0, _models.validateSchema)(CurateCategory.updateSchema, data);
        await (0, _request.request)({
          method: 'PUT',
          url: (0, _request.curateApiURL)(`categories/${this._id}`),
          data: JSON.stringify(data)
        });
      } catch (error) {
        (0, _request.throwError)(error);
      }
    }

  }, _defineProperty(_class2, "updateSchema", _joi.default.object(updateValidations)), _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ancestors", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "descendants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  })), _class));
  _exports.default = CurateCategory;
});