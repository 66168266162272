define("backalley/pods/main/prospective-boutique/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8ApLTUM/",
    "block": "{\"symbols\":[\"HotLoadProspectiveBoutiqueFormohju5kcgiu\"],\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"ProspectiveBoutiqueForm\",[23,0,[]],\"ProspectiveBoutiqueForm\",\"ProspectiveBoutiqueForm\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"ProspectiveBoutiqueForm\",\"ProspectiveBoutiqueForm\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[],[[\"@pageTitle\",\"@prospectiveBoutique\",\"@saveAction\",\"@cancelAction\"],[\"New\",[22,\"model\"],[28,\"route-action\",[\"save\"],null],[28,\"route-action\",[\"cancel\"],null]]]]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/prospective-boutique/new/template.hbs"
    }
  });

  _exports.default = _default;
});