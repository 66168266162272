define("backalley/pods/main/prospective-boutique/new/route", ["exports", "backalley/models/prospective_boutique"], function (_exports, _prospective_boutique) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return _prospective_boutique.default.create();
    },

    actions: {
      save() {
        if (this.currentModel.isBoutiqueNameInUse) {
          return this.notify.show('error', 'Boutique Name already in use');
        }

        this.currentModel.post().then(() => this.transitionTo('main.prospective-boutique')).catch(err => {
          this.notify.show('error', err.responseJSON ? err.responseJSON.errors[0].detail : err.message);
        });
      },

      cancel() {
        this.transitionTo('main.prospective-boutique');
      }

    }
  });

  _exports.default = _default;
});