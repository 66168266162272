define("backalley/models/product_sync_pending_differences", ["exports", "joi", "backalley/util/request"], function (_exports, _joi, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ProductSyncPendingDifferences {
    constructor({
      sync_ref_id
    }) {
      this.sync_ref_id = sync_ref_id;
    }

    static find(sync_ref_id) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.apiURL)(`product-sync-pending-differences/get-latest-update`),
        data: {
          sync_ref_id: sync_ref_id
        }
      }).then(res => res).catch(() => {});
    }

  }

  _exports.default = ProductSyncPendingDifferences;

  _defineProperty(ProductSyncPendingDifferences, "schema", _joi.default.object().keys({
    sync_ref_id: _joi.default.string().required()
  }));
});