define("backalley/pods/main/manual-adjustments/index/route", ["exports", "backalley/models/currency-statement", "backalley/models/manual-adjustment"], function (_exports, _currencyStatement, _manualAdjustment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    queryParams: {
      period: {
        refreshModel: true
      },
      year: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      adjustmentType: {
        refreshModel: true
      },
      statusType: {
        refreshModel: true
      }
    },

    serializeQueryParam(value = '', urlKey = '') {
      if (urlKey === 'statusType') return value.value;
      if (['page', 'period', 'year'].includes(urlKey)) return Number(value);
      return value;
    },

    deserializeQueryParam(value = '', urlKey = '') {
      if (urlKey === 'statusType') return this.statusTypes.findBy('value', value);
      return value;
    },

    statusTypes: [{
      label: 'Status: Show all',
      value: 'all'
    }, {
      label: 'Status: Only Approved',
      value: 'approved'
    }, {
      label: 'Status: Only pending',
      value: 'pending'
    }],

    async model({
      page = 1,
      limit = 50,
      period,
      year,
      adjustmentType,
      statusType
    }) {
      const {
        selectedPeriod,
        periodConfig
      } = await setPeriod(period, year);
      const {
        manual_adjustments,
        meta = {
          total: 0
        }
      } = await getAdjustments(selectedPeriod, adjustmentType, page, limit, statusType);
      const {
        ADJUSTMENT_TYPES
      } = await _manualAdjustment.default.getConfig();
      return {
        total: Math.ceil(meta.total / limit),
        page,
        // Dropdown options
        periods: periodConfig.periods,
        adjustmentTypes: ADJUSTMENT_TYPES,
        // Dropdown selected values
        selectedPeriod,
        selectedAdjustmentType: adjustmentType || null,
        selectedAdjustment: null,
        statusTypes: this.statusTypes,
        selectedStatusType: statusType,
        // Modal preview value
        openModal: false,
        // Data
        manual_adjustments
      };
    },

    setupController(controller, model) {
      controller.setProperties({
        model: model,
        year: model.selectedPeriod.year,
        period: model.selectedPeriod.period
      });
    },

    actions: {
      approveAdjustment,

      viewAdjustment(adjustment) {
        this.set('currentModel.openModal', true);
        this.set('currentModel.selectedAdjustment', adjustment);
      },

      async removeAdjustment(adjustment) {
        if (window.confirm('Are you sure you want to DELETE this adjustment?')) {
          await adjustment.remove();
          this.refresh();
        }
      },

      selectPeriod(period) {
        this.setProperties({
          'controller.period': period.period,
          'controller.year': period.year
        });
      },

      selectAdjustmentType(type) {
        this.setProperties({
          'controller.adjustmentType': type
        });
      },

      onCompleteAdjustmentUpdate() {
        this.set('currentModel.openModal', false);
        this.set('currentModel.selectedAdjustment', null);
      },

      selectAdjustmentStatusType(type) {
        this.set('controller.statusType', type);
      }

    }
  });

  _exports.default = _default;

  async function setPeriod(period, year) {
    const periodConfig = await _currencyStatement.default.config();
    let selectedPeriod = periodConfig.periods.find(p => {
      return Number(p.year) === Number(year) && Number(p.period) === Number(period);
    });

    if (!selectedPeriod) {
      selectedPeriod = periodConfig.periods.find(p => {
        return Number(p.year) === Number(periodConfig.most_recently_concluded_period.year) && Number(p.period) === Number(periodConfig.most_recently_concluded_period.period);
      });
    }

    return {
      selectedPeriod,
      periodConfig
    };
  }

  function getAdjustments(selectedPeriod, adjustmentType, page, limit, statusType) {
    const query = {
      date: {
        $gte: selectedPeriod.from,
        $lte: selectedPeriod.to
      },
      populate: 'order boutique reservation created_by',
      page,
      limit,
      sort: '-created_at'
    };

    if (statusType.value !== 'all') {
      query.approved = statusType.value === 'approved';
    }

    if (adjustmentType) {
      query.adjustment_type = adjustmentType;
    }

    return _manualAdjustment.default.findWithMeta(query);
  }

  function approveAdjustment(adjustment) {
    if (window.confirm('Are you sure you want to approve this adjustment?')) {
      adjustment.set('approved', true);
      adjustment.update();
    }
  }
});