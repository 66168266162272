define("backalley/pods/components/boutique-form/accounting-info/component", ["exports", "backalley/models/curate/payment-company", "backalley/util/dates", "ember-concurrency"], function (_exports, _paymentCompany, _dates, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    boutiqueEuVatStatusOptions: [{
      value: 'oss',
      name: 'OSS'
    }],
    statementVersionOptions: [{
      version: 2,
      name: 'v2 (old statements)'
    }, {
      version: 3,
      name: 'v3 (new statements)'
    }],
    selectedStatementVersion: Ember.computed('boutique.statements_version', function () {
      const {
        statements_version
      } = this.boutique;
      return this.statementVersionOptions.find(option => option.version === statements_version);
    }),
    selectedBoutiqueEuVatStatusOption: Ember.computed('boutique.eu_vat.status', function () {
      const {
        eu_vat: euVat = {}
      } = this.boutique;
      const euVatStatus = euVat.status || '';
      return this.boutiqueEuVatStatusOptions.find(option => option.value === euVatStatus);
    }),
    isBoutiqueEuVatStatusNotYetSaved: Ember.computed('boutique.{eu_vat,_savedValuesForComparison.eu_vat}', function () {
      const currentEuVat = this.boutique.eu_vat || {};
      const savedEuVat = this.boutique._savedValuesForComparison && this.boutique._savedValuesForComparison.eu_vat || {};
      return currentEuVat.status !== savedEuVat.status;
    }),
    boutiqueEuVatStatusChangedAt: Ember.computed('boutique.eu_vat.status_changed_at', function () {
      const {
        eu_vat: {
          status_changed_at: statusChangedAt = ''
        } = {}
      } = this.boutique || {};
      return statusChangedAt ? (0, _dates.niceDate)(statusChangedAt) : '';
    }),
    searchCuratePaymentCompany: (0, _emberConcurrency.task)(function* (query) {
      yield (0, _emberConcurrency.timeout)(300);
      const {
        companies
      } = yield _paymentCompany.default.find({
        name: query,
        country: this.boutique.populatedRegisteredCountry.iso_code
      });
      return companies;
    }).restartable(),
    actions: {
      setBankDetails(field, value) {
        if (Ember.isEmpty(value)) {
          return Ember.set(this.boutique, field, null);
        }

        Ember.set(this.boutique, field, value);
      },

      setBoutiqueEuVatStatus(boutiqueEuVatStatusOptions) {
        const valueAppliedWhenStatusRemoved = '';
        const {
          value: newStatusValue = valueAppliedWhenStatusRemoved
        } = boutiqueEuVatStatusOptions || {};
        const currentEuVat = this.boutique.eu_vat || {};
        const savedEuVat = this.boutique._savedValuesForComparison.eu_vat || {};
        const isUndefinedInDB = savedEuVat.status === undefined;
        const isBeingRemoved = newStatusValue === valueAppliedWhenStatusRemoved;
        const isSameAsSaved = newStatusValue === savedEuVat.status;
        const isSameAsCurrent = newStatusValue === currentEuVat.status;

        let euVat = _objectSpread({}, currentEuVat);

        if (isUndefinedInDB && isBeingRemoved) {
          // don't store anything, this boutique hasn't yet
          // properly set any EU VAT status yet.
          euVat = undefined;
        } else if (isSameAsSaved) {
          // preserve key fields from saved data, unknown fields
          // will be kept from current data
          euVat.status_changed_at = savedEuVat.status_changed_at;
          euVat.status = savedEuVat.status;
          euVat.registration_number = savedEuVat.registration_number;
        } else if (!isSameAsCurrent) {
          // update changed_at and set the new status, this is
          // used to report outwardly on the UI that the date
          // will be updated -- but the final say is in Promenade
          euVat.status_changed_at = Date.now();
          euVat.status = newStatusValue;
        }

        if (euVat && isBeingRemoved) {
          euVat.registration_number = '';
        }

        Ember.set(this.boutique, 'eu_vat', euVat);
      },

      setHokodoCompany(company) {
        Ember.set(this.boutique, 'populatedHokodoCompany', company);

        if (Ember.isEmpty(this.boutique.registered_name) && Ember.isPresent(company.name)) {
          Ember.set(this.boutique, 'registered_name', company.name);
          this.notify.show('success', `Updated boutique's registered name to ${company.name}`);
        }

        if (Ember.isEmpty(this.boutique.registered_number) && Ember.isPresent(company.registered_number)) {
          Ember.set(this.boutique, 'registered_number', company.registered_number);
          this.notify.show('success', `Updated boutique's registered number to ${company.registered_number}`);
        }
      }

    }
  });

  _exports.default = _default;
});