define("backalley/pods/main/packaging/products/edit/route", ["exports", "backalley/models/packaging/product"], function (_exports, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PackagingProductsEditRoute extends Ember.Route {
    model({
      id
    }) {
      return _product.default.findById(id);
    }

  }

  _exports.default = PackagingProductsEditRoute;
});