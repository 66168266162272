define("backalley/pods/components/landing-page/youtube/display/component", ["exports", "backalley/util/default-to"], function (_exports, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const noop = () => {};

  var _default = Ember.Component.extend({
    tagName: '',
    // Public API
    entry: (0, _defaultTo.default)(null),
    index: (0, _defaultTo.default)(null),
    onSelectEntry: (0, _defaultTo.default)(noop),
    boxStyle: Ember.computed('device', 'entry.content.general.{marginLeft,marginRight,marginTop,marginBottom,backgroundHexColor}', function () {
      const device = this.device;
      const general = this.get('entry.content.general');
      const backgroundHexColorStyle = general.backgroundHexColor ? `background-color: ${general.backgroundHexColor};` : '';
      if (device === 'mobile') return '';
      return `margin-left: ${general.marginLeft}; margin-top: ${general.marginTop}; margin-right: ${general.marginRight}; margin-bottom: ${general.marginBottom}; ${backgroundHexColorStyle}`;
    })
  });

  _exports.default = _default;
});