define("backalley/components/power-calendar-range", ["exports", "ember-power-calendar/components/power-calendar-range"], function (_exports, _powerCalendarRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _powerCalendarRange.default;
    }
  });
});