define("backalley/pods/components/path-selector-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PathSelectorForm = (_dec = Ember.computed('pathTargets'), _dec2 = Ember.computed('pathTargets'), _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class PathSelectorForm extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "classNames", ['path-selector-form']);

      _defineProperty(this, "pathsOrLanes", undefined);

      _defineProperty(this, "supplyCountry", undefined);

      _defineProperty(this, "demandCountry", undefined);

      _defineProperty(this, "supplyCountryRaw", '');

      _defineProperty(this, "demandCountryRaw", '');

      _defineProperty(this, "pathSearch", '');

      _defineProperty(this, "pathTargets", []);

      _defineProperty(this, "delaySearchStartBy", 350);

      _defineProperty(this, "onUseSelection", undefined);

      _defineProperty(this, "labelForUseSelection", 'Use Selected Paths');

      _defineProperty(this, "onUseSelectionAlt", undefined);

      _defineProperty(this, "labelForUseSelectionAlt", undefined);
    }

    get pathTargetsLabel() {
      if (!this.pathTargets.length) {
        return 'No paths selected';
      }

      return this.pathTargets.length === 1 ? this.pathTargets[0].path_codes : `${this.pathTargets.length} paths selected`;
    }

    get pathTargetsLabels() {
      if (!this.pathTargets.length) {
        return '';
      }

      const fullList = this.pathTargets.map(path => `${path.source} to ${path.destination}`);

      if (fullList.length <= 13) {
        return fullList;
      }

      return [].concat(fullList.splice(0, 6), '...', fullList.slice(-6));
    }

    delayedSearchForPaths() {
      Ember.run.debounce(this, this.searchForPaths, {}, this.delaySearchStartBy || 0);
    }

    searchForPaths() {
      const list = this.pathsOrLanes.findByPathSelector(this.pathSearch);
      this.set('pathTargets', list);
      return;
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "pathTargetsLabel", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "pathTargetsLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pathTargetsLabels", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "pathTargetsLabels"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delayedSearchForPaths", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "delayedSearchForPaths"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchForPaths", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "searchForPaths"), _class.prototype)), _class));
  _exports.default = PathSelectorForm;
});