define("backalley/pods/main/curate/products/index/route", ["exports", "backalley/models/curate/product"], function (_exports, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MainCurateProductsIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        },
        sort: {
          refreshModel: true
        },
        brand: {
          refreshModel: true
        },
        query: {
          refreshModel: true
        },
        status: {
          refreshModel: true
        }
      });
    }

    model({
      page,
      limit,
      sort,
      brand,
      query,
      status
    }) {
      const queryParams = {
        page,
        limit,
        sort,
        brand,
        populate: 'brand'
      };
      Object.assign(queryParams, query ? {
        free_text_search: query
      } : {});
      Object.assign(queryParams, status ? {
        status
      } : {});
      return _product.default.find(queryParams);
    }

  }

  _exports.default = MainCurateProductsIndexRoute;
});