define("backalley/pods/main/frames/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MainFramesRoute extends Ember.Route {}

  _exports.default = MainFramesRoute;
});