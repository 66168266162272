define("backalley/mirage/factories/currency", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // mirage/factories/category.js
  var _default = _emberCliMirage.default.Factory.extend({
    name: i => `name ${i}`,
    symbol: '£',
    iso_4217: 'GBP',
    conversion_rate: 1.235,
    address_mismatch_fraud_threshold_in_cents: 7000,
    total_order_fraud_threshold_in_cents: 40000,

    updated_at() {
      return new Date(_emberCliMirage.faker.date.past(Math.round(Math.random() * 10)));
    },

    created_at() {
      return new Date(_emberCliMirage.faker.date.past(Math.round(Math.random() * 10)));
    },

    _id: i => String(i + 1) // id starts at 1

  });

  _exports.default = _default;
});