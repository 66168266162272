define("backalley/enums/diff-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getAsValues = getAsValues;
  _exports.default = void 0;
  const diffTypes = {
    DIFF_NONE: ' ',
    DIFF_CHANGE: '~',
    DIFF_ADD: '+',
    DIFF_REMOVE: '-'
  };

  function getAsValues() {
    return Object.values(diffTypes);
  }

  var _default = diffTypes;
  _exports.default = _default;
});