define("backalley/pods/components/form-images/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormImagesComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class FormImagesComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "styleNamespace", _podNames.default['form-images']);

      _initializerDefineProperty(this, "selectedImage", _descriptor, this);
    }

    get multiple() {
      var _this$args$multiple;

      return (_this$args$multiple = this.args.multiple) !== null && _this$args$multiple !== void 0 ? _this$args$multiple : true;
    }

    get maxFileSize() {
      var _this$args$maxFileSiz;

      return (_this$args$maxFileSiz = this.args.maxFileSize) !== null && _this$args$maxFileSiz !== void 0 ? _this$args$maxFileSiz : 5000000; // 5Mb
    }

    isFunction(fn) {
      return typeof fn === "function";
    }

    updateDisplayOrders() {
      this.args.images.forEach((image, index) => Ember.set(image, 'display_order', index));
    }

    addImages(images) {
      const uploaded = Ember.isArray(images) ? images : [images];
      const urls = uploaded.map(({
        url
      }) => ({
        url
      }));
      this.selectedImage = urls[0];

      if (this.isFunction(this.args.onAddImages)) {
        return this.args.onAddImages(urls);
      }

      this.args.images.addObjects(urls);
      this.updateDisplayOrders();
    }

    removeImage(image) {
      if (this.isFunction(this.args.onRemoveImage)) {
        return this.args.onRemoveImage(image);
      }

      this.args.images.removeObject(image);
      this.updateDisplayOrders();
    }

    setThumbnail(image) {
      if (this.isFunction(this.args.onSetThumbnail)) {
        return this.args.onSetThumbnail(image);
      }

      this.args.images.removeObject(image);
      this.args.images.unshiftObject(image);
      this.updateDisplayOrders();
    }

    reorderImages(images) {
      if (this.isFunction(this.args.onReorderImages)) {
        return this.args.onReorderImages(images);
      }

      this.args.images.setObjects(images);
      this.updateDisplayOrders();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedImage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.images[0];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "addImages", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "addImages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeImage", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "removeImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setThumbnail", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setThumbnail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reorderImages", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "reorderImages"), _class.prototype)), _class));
  _exports.default = FormImagesComponent;
});