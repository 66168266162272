define("backalley/pods/main/frame-tags/new/route", ["exports", "backalley/models/frame-tag"], function (_exports, _frameTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MainFrameTagsNewRoute extends Ember.Route {
    model() {
      return new _frameTag.default();
    }

  }

  _exports.default = MainFrameTagsNewRoute;
});