define("backalley/models/order", ["exports", "joi", "@streethub/calcs", "backalley/mixins/request", "backalley/config/environment", "backalley/util/models", "backalley/enums/cko-payment-channel", "backalley/enums/channel"], function (_exports, _joi, _calcs, _request, _environment, _models, _ckoPaymentChannel, _channel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const validations = {
    _id: _joi.default.string().required(),
    gift: _joi.default.boolean(),
    completed: _joi.default.boolean(),
    manual_mode: _joi.default.boolean(),
    assigned: _joi.default.string().allow(''),
    mode: _joi.default.string().allow('clickcollect', 'delivery', 'shipping'),
    full_name: _joi.default.string().required(),
    user_phone: _joi.default.string().allow(''),
    email: _joi.default.string().required(),
    mobile: _joi.default.string().allow(''),
    status: _joi.default.string().allow('creating', 'active', 'success', 'partial success', 'fail', 'fail payment'),
    delivery_full_name: _joi.default.string(),
    delivery_address_line_1: _joi.default.string(),
    delivery_address_line_2: _joi.default.string().allow('', null),
    delivery_postcode: _joi.default.string(),
    delivery_company: _joi.default.string().allow(''),
    delivery_city: _joi.default.string().allow(''),
    delivery_county: _joi.default.string().allow(''),
    delivery_country: _joi.default.string().allow(''),
    delivery_cost: _joi.default.number().min(0),
    discount_code: _joi.default.string().allow(''),
    discount_type: _joi.default.string().allow('amount', 'percentage', 'free_shipping', 'free_delivery'),
    comments: _joi.default.string(),
    transaction_shipping_cost: _joi.default.object().keys({
      amount: _joi.default.number().min(0),
      currency: _joi.default.string()
    }),
    transaction_customer_refunded: _joi.default.object().keys({
      amount: _joi.default.number().min(0),
      currency: _joi.default.string()
    }),
    transaction_customer_recharged: _joi.default.object().keys({
      amount: _joi.default.number().min(0),
      currency: _joi.default.string()
    }),
    transaction_discount_amount: _joi.default.object().keys({
      amount: _joi.default.number().min(0),
      currency: _joi.default.string()
    }),
    transaction_details: _joi.default.object(),
    pending: _joi.default.boolean(),
    outstanding_balance: _joi.default.number()
  };
  const Order = Ember.Object.extend(_request.default, {
    isToF: Ember.computed.equal('channel_specific.bridge_origin', 'tof'),
    isChannelMadeDotCom: Ember.computed.equal('channel', _channel.default.MADE_DOT_COM),
    isChannelFy: Ember.computed.equal('channel', _channel.default.FY),
    isChannelFyOrToFOrMade: Ember.computed.or('isChannelMadeDotCom', 'isChannelFy', 'isToF'),
    braintreeTransactionURL: Ember.computed('braintree_transaction_id', function () {
      const transactionId = this.braintree_transaction_id;
      return `https://www.braintreegateway.com/merchants/999vfkvtbxdd8srf/transactions/${transactionId}`;
    }),
    ckoRefundUrl: Ember.computed('payment_transaction_id', 'payment_channel', function () {
      const isCkoPaymentChannel = Object.values(_ckoPaymentChannel.default).find(v => v === this.payment_channel);

      if (!isCkoPaymentChannel) {
        return;
      }

      return `${_environment.default.ckoURL}/payments?search=${this.payment_transaction_id}`;
    }),
    paymentChannelBraintree: Ember.computed.equal('payment_channel', 'braintree'),
    paymentChannelCKO: Ember.computed('payment_channel', function () {
      return !!Object.values(_ckoPaymentChannel.default).find(v => v === this.payment_channel);
    }),
    paymentChannelKlarna: Ember.computed('payment_channel', function () {
      return [_ckoPaymentChannel.default.KLARNA, _ckoPaymentChannel.default.OLD_KLARNA].includes(this.payment_channel);
    }),

    /**
     * one_click_refund is used to determine which refund ui
     * will be shown by default to the end user.
     * true for one click refund ui
     * false for manual refund ui
     *
     * @type {Boolean}
     */
    one_click_refund: Ember.computed.or('paymentChannelCKO', 'paymentChannelBraintree'),

    /**
     *    update this instance
     *    @return {Object} the updated instance
     */
    put() {
      this.set('loading', true);
      return this.validate().then(data => {
        let changes = (0, _models.getChanges)(this._orig, data, Object.keys(validations)); // Add the version field, to avoid overriding with stale data

        changes._ver = this._orig._ver;
        return changes;
      }).then(data => {
        // if there are no changes do not send an update
        // _id and _ver are always there
        if (Object.keys(data).length <= 2) return;
        return this.request({
          method: 'PUT',
          url: this.apiURL(`orders/${this._id}`),
          data: JSON.stringify(data)
        }).then(order => {
          // reset the original data to get the correct diff the next time
          this.setOriginalData(order); // update the document with the response

          this.setProperties(order);
          this.set('loading', false);
        });
      }).catch(err => {
        this.reset();
        throw err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
      });
    },

    validate(schema = 'put_schema') {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(Order[schema], data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    reset() {
      this.setProperties(this._orig);
    },

    sendConfirmation() {
      return this.request({
        method: 'POST',
        url: this.apiURL(`orders/${this._id}/send_confirmation`),
        data: JSON.stringify({})
      }).then(({
        orders
      }) => {
        // reset the original data to get the correct diff the next time
        this.setOriginalData(orders[0]); // update the document with the response

        this.setProperties(orders[0]);
        return this;
      }).catch(err => {
        throw err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
      });
    },

    sendUpdateToFy() {
      return this.request({
        method: 'POST',
        url: this.apiURL(`orders/${this._id}/send_update_to_fy`),
        data: JSON.stringify({})
      }).then(() => {
        return this;
      }).catch(err => {
        throw err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
      });
    },

    refundRequestParams(amount) {
      const refundEndpointParamsByPaymentChannel = ({
        order_id,
        amount,
        currency
      }) => {
        const refundsEndpoint = {
          braintree: ['braintree/refund', {
            order_id,
            amount
          }]
        };
        Object.values(_ckoPaymentChannel.default).forEach(value => {
          refundsEndpoint[`${value}`] = [`orders/${order_id}/refunds`, {
            transaction_amount: {
              amount,
              currency
            }
          }];
        });
        return refundsEndpoint;
      };

      const [apiUrl, reqData] = refundEndpointParamsByPaymentChannel({
        order_id: this._id,
        amount,
        currency: this.transaction_currency
      })[this.payment_channel];
      return {
        method: 'POST',
        url: this.apiURL(apiUrl),
        data: JSON.stringify(reqData)
      };
    },

    /**
     * Calls the one click refund endpoint to create a refund and updates the state
     * of the one click refund ui accordingly.
     *
     * @param  {Number} amount  Amount in pences to refund
     * @return {Object}        Returns response of api endpoint
     */
    refund(amount) {
      return this.request(this.refundRequestParams(amount)).then(order => {
        // reset the original data to get the correct diff the next time
        this.setOriginalData(order); // update the document with the response

        this.setProperties(order);
        return order;
      }).catch(err => {
        Ember.set(this, 'one_click_refund', false);
        const errorMessage = err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
        Ember.set(this, 'one_click_refund_error', errorMessage);
      });
    },

    discount_type: Ember.computed('discount_amount', {
      get() {
        // set defaults for old orders
        return this.discount_amount > 99 ? 'amount' : 'percentage';
      }

    }),
    set_outstanding_balance: Ember.observer('customer_refund', 'customer_recharge', function () {
      let total = _calcs.default.sum([_calcs.default.math.bignumber(this.customer_refund), _calcs.default.math.bignumber(this.customer_recharge)]);

      this.set('outstanding_balance', _calcs.default.inPences({
        total
      }).total);
    })
  });
  Order.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  Order.reopenClass(_request.default, {
    put_schema: _joi.default.object().keys(validations),

    // override to cache a copy of the retrived object
    create(data = {}) {
      let c = this._super(data);

      c.setOriginalData(data);
      return c;
    },

    find(data) {
      return this.findWithMeta(data);
    },

    findWithMeta(data) {
      if (data && typeof data.status === 'undefined') {
        data.status = '*';
      }

      if (data && typeof data.resource_state === 'undefined') {
        data.resource_state = '*';
      }

      return this.request({
        method: 'POST',
        headers: {
          'X-HTTP-Method-Override': 'GET'
        },
        url: this.apiURL('orders'),
        data: JSON.stringify(_objectSpread(_objectSpread({}, data), {}, {
          plain: true
        }))
      }).then(res => {
        return {
          orders: res.orders.map(order => this.create(order)),
          meta: res.meta || {
            total: 0
          }
        };
      });
    },

    /**
     *    Find one specific order popualted with a changelog
     *    @param  {[type]} id      [description]
     *    @param  {[type]} data={} [description]
     *    @return {[type]}         [description]
     */
    findById(id, data = {}) {
      if (data && typeof data.status === 'undefined') {
        data.status = '*';
      }

      if (data && typeof data.resource_state === 'undefined') {
        data.resource_state = '*';
      }

      data._id = id;
      return this.request({
        method: 'GET',
        url: this.apiURL('orders'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => {
        const [order] = res.orders;

        if (!order) {
          throw new Error('Order not found');
        }

        return this.create(order);
      });
    }

  });
  var _default = Order;
  _exports.default = _default;
});