define("backalley/mirage/factories/monthlies", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Ember seems to require this unused (by the non-test code) construction
  // so will see if this fixes the tests.
  var _default = _emberCliMirage.default.Factory.extend({});

  _exports.default = _default;
});