define("backalley/pods/main/currency_conversion_rates/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['sort', 'source', 'destination'],
    source: 'All currencies',
    destination: 'All currencies',

    init() {
      this._super(...arguments);

      this.sort = {
        label: 'Source currency: A-Z',
        value: 'source_iso_4217'
      };
    }

  });

  _exports.default = _default;
});