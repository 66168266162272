define("backalley/pods/components/order-notes/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    resource: 'order',
    classNames: ['order-notes'],
    newComponent: Ember.computed('mode', 'expanded', function () {
      const mode = this.mode;

      if (mode === 'quick') {
        return 'order-notes/quick';
      }

      return 'basic-notes/new';
    })
  });

  _exports.default = _default;
});