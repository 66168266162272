define("backalley/helpers/ordinal-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ordinalIndicator = ordinalIndicator;
  _exports.default = void 0;

  function ordinalIndicator([number]) {
    const stringNumber = String(number);
    const lastDigit = stringNumber[stringNumber.length - 1];

    switch (lastDigit) {
      case '1':
        return 'st';

      case '2':
        return 'nd';

      case '3':
        return 'rd';
      // 0,4,5,6,7,8,9

      default:
        return 'th';
    }
  }

  var _default = Ember.Helper.helper(ordinalIndicator);

  _exports.default = _default;
});