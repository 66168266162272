define("backalley/pods/components/character-count/component", ["exports", "backalley/util/default-to"], function (_exports, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['character-count'],
    // Public
    text: (0, _defaultTo.default)(''),
    textLength: (0, _defaultTo.default)(140),
    // Private
    charactersLeft: Ember.computed('text', function () {
      const currentTextLength = this.get('text.length') || 0;
      return this.textLength - currentTextLength;
    })
  });

  _exports.default = _default;
});