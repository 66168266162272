define("backalley/pods/components/product-edit/component", ["exports", "jquery", "backalley/util/product", "backalley/enums/fieldExcludedFromAutomation"], function (_exports, _jquery, _product, _fieldExcludedFromAutomation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editDimensions: false,
    hasDimensions: Ember.computed('model.product.stock_lines.@each.dimensions.{width,height,length}', function () {
      const [stock_line] = this.get('model.product.stock_lines');
      return !!(stock_line && stock_line.dimensions && stock_line.dimensions.width && stock_line.dimensions.length && stock_line.dimensions.height);
    }),
    dimensions: Ember.computed('model.product.stock_lines.@each.dimensions.{width,height,length}', 'hasDimensions', function () {
      const [stock_line] = this.get('model.product.stock_lines');
      return this.hasDimensions ? stock_line.dimensions : {};
    }),
    hasCareInstructions: Ember.computed('model.product.stock_lines.@each.care_instructions', function () {
      const [stock_line] = this.get('model.product.stock_lines');
      return !!(stock_line && !Ember.isEmpty(stock_line.care_instructions));
    }),
    careInstructions: Ember.computed('model.product.stock_lines.@each.care_instructions', 'hasCareInstructions', function () {
      if (this.hasCareInstructions) {
        const [stock_line] = this.get('model.product.stock_lines');
        return stock_line.care_instructions;
      }
    }),
    unsuitableToPublish: Ember.computed('model.product.{unsuitableDisabled,currentShippingPolicy}', function () {
      if (this.get('model.product.unsuitableDisabled') || this.get('model.product.currentShippingPolicy') === 'unshippable') {
        return true;
      }

      return false;
    }),
    isWeightBracketExcludedFromAutomation: Ember.computed('model.product.isWeightBracketExcludedFromAutomation', {
      get() {
        return this.model.product.isWeightBracketExcludedFromAutomation;
      },

      set(key, shouldBeExcluded) {
        let fieldsExcludedFromAutomation = this.get('model.product.fields_excluded_from_automation') || [];
        fieldsExcludedFromAutomation = shouldBeExcluded ? (0, _product.addToFieldsExcludedFromAutomation)(_fieldExcludedFromAutomation.default.WEIGHT_BRACKET, fieldsExcludedFromAutomation) : (0, _product.removeFromFieldsExcludedFromAutomation)(_fieldExcludedFromAutomation.default.WEIGHT_BRACKET, fieldsExcludedFromAutomation);
        this.set('model.product.fields_excluded_from_automation', fieldsExcludedFromAutomation);
        this.set('model.product.isWeightBracketExcludedFromAutomation', shouldBeExcluded);
        return shouldBeExcluded;
      }

    }),
    hsCode: Ember.computed('model.product.hs_code.{default,custom}', function () {
      const {
        hs_code
      } = this.model.product;

      if (!hs_code) {
        return null;
      }

      if (hs_code.custom) {
        return hs_code.custom;
      }

      return hs_code.default;
    }),
    hasCustomHSCode: Ember.computed.notEmpty('model.product.hs_code.custom'),
    vatRate: Ember.computed.alias('model.product.vat_rate'),
    actions: {
      setDeliveryTags: function (tags) {
        const uniqueTags = tags.filter((tag, index, self) => index === self.findIndex(innerTag => innerTag._id === tag._id));
        this.set('model.product.delivery_tags', uniqueTags);
      },

      scrollTo(element) {
        (0, _jquery.default)('.products-preview').animate({
          scrollTop: (0, _jquery.default)(element).offset().top
        }, 300);
      },

      readableType(type) {
        return type.split('_').map(i => Ember.String.capitalize(i)).join(' ');
      },

      resetFilters() {
        let {
          actions: {
            resetFilters
          }
        } = this.modelFor('products');
        return resetFilters();
      },

      updateCareInstructions(product, value) {
        product.updateCareInstructions(value);
      },

      updateDimension(dimension, event) {
        const value = event.target.value;
        return Ember.set(this.dimensions, dimension, value);
      },

      updateDimensions(product) {
        Ember.set(this, 'editDimensions', false);
        return product.updateDimensions(this.dimensions);
      },

      excludeWeightBracketFromAutomation() {
        Ember.set(this, 'isWeightBracketExcludedFromAutomation', !this.model.product.isWeightBracketExcludedFromAutomation);
      },

      setHSCode(product, code) {
        if (!product.hs_code) {
          Ember.set(product, 'hs_code', {
            default: '42022290',
            custom: null
          });
        }

        if (product.hs_code.default === code) {
          Ember.set(product.hs_code, 'custom', null);
          return;
        }

        Ember.set(product.hs_code, 'custom', code);
      }

    }
  });

  _exports.default = _default;
});