define("backalley/mirage/factories/curate-product", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const getPrice = () => ({
    amount: _faker.default.random.number({
      min: 100,
      max: 420000
    }),
    currency: _faker.default.random.arrayElement(['GBP', 'EUR'])
  });

  const getStockLine = () => ({
    sku: _faker.default.lorem.word(),
    wholesale_price: getPrice(),
    quantity: _faker.default.random.number({
      min: 1,
      max: 20
    }),
    images: [{
      url: _faker.default.image.imageUrl()
    }],
    minimum_order_quantity: _faker.default.random.number({
      min: 0,
      max: 250
    })
  });

  var _default = _emberCliMirage.Factory.extend({
    name() {
      return _faker.default.commerce.productName();
    },

    stock_lines() {
      return [getStockLine(), getStockLine()];
    },

    afterCreate(product, server) {
      if (product.brand) {
        return;
      }

      const {
        attrs: brand
      } = server.create('curate-brand');
      product.update({
        brands: [brand]
      });
    }

  });

  _exports.default = _default;
});