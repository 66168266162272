define("backalley/mirage/factories/currencyStatement", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const VAT_RATE = 0;

  function createRandomArrayLength(maxLength = 5) {
    const arrayLength = _emberCliMirage.faker.random.number({
      min: 1,
      max: maxLength
    });

    return Array.from({
      length: arrayLength
    }, (value, index) => index);
  }

  function createRandomSaleRecords(maxLength) {
    return createRandomArrayLength(maxLength).map(function () {
      const quantity = _emberCliMirage.faker.random.number({
        min: 1,
        max: 10
      });

      const unit_amount = _emberCliMirage.faker.random.number(100);

      return {
        order_date: new Date(),
        order_number: _emberCliMirage.faker.random.number(10000),
        product_name: _emberCliMirage.faker.commerce.productName(),
        manufacturer_name: _emberCliMirage.faker.lorem.words(),
        quantity,
        unit_amount,
        total_amount: unit_amount * quantity
      };
    });
  }

  function createRandomReimbursementRecords(maxLength, replaceAttributes = {}) {
    return createRandomArrayLength(maxLength).map(function () {
      const unit_amount = _emberCliMirage.faker.random.number(100);

      const quantity = _emberCliMirage.faker.random.number({
        min: 1,
        max: 10
      });

      return _objectSpread({
        order_date: new Date(),
        order_number: _emberCliMirage.faker.random.number(10000),
        description: _emberCliMirage.faker.lorem.sentence(),
        quantity,
        unit_amount,
        total_amount: unit_amount * quantity
      }, replaceAttributes);
    });
  }

  function createRandomCommisions(maxLength) {
    return createRandomArrayLength(maxLength).map(function () {
      const amount_without_vat = _emberCliMirage.faker.random.number(1000);

      const vat_amount = amount_without_vat * (VAT_RATE / 100);
      return {
        description: _emberCliMirage.faker.lorem.sentence(),
        amount_without_vat,
        vat_amount,
        amount_with_vat: amount_without_vat * vat_amount
      };
    });
  }

  function createRandomShippingContributions(maxLength) {
    return createRandomArrayLength(maxLength).map(function () {
      const unit_amount_without_vat = _emberCliMirage.faker.random.number(1000);

      const quantity = _emberCliMirage.faker.random.number(10);

      const amount_without_vat = unit_amount_without_vat * quantity;
      const vat_amount = amount_without_vat * (VAT_RATE / 100);
      return {
        description: _emberCliMirage.faker.lorem.sentence(),
        unit_amount_without_vat,
        quantity,
        amount_without_vat,
        vat_amount,
        amount_with_vat: amount_without_vat + vat_amount
      };
    });
  }

  function calculateTotalOnRecords(records, key) {
    return records.reduce(function (prev, curr) {
      const isFunction = typeof curr[key] === 'function';
      return prev + (isFunction ? curr[key]() : curr[key]);
    }, 0);
  }

  function calculateTotal(records) {
    return records.reduce(function (prev, curr) {
      return prev + curr.total_amount;
    }, 0);
  }

  var _default = _emberCliMirage.default.Factory.extend({
    _id: i => i,
    // Things can't fake in the factory because we don't have access to mirage's db.
    boutique: null,
    boutique_name: 'Boutique name',
    boutique_iban: 'Boutique IBAN',
    boutique_swift_code: 'Boutique SWIFT code',
    boutique_address_line_1: 'Boutique Address line 1',
    boutique_address_line_2: 'Boutique Address line 2',
    // Everything below here can be automatically generated.
    currency: 'EUR',
    sent: false,
    can_send: true,
    total_amount: _emberCliMirage.faker.random.number(100000),
    period: 1,
    year: 2018,
    invoice_date: () => new Date(),
    invoice_number: function () {
      const number = _emberCliMirage.faker.random.number(100000);

      return `${this.currency} ${number}`;
    },
    trouva_vat_number: function () {
      const number = _emberCliMirage.faker.random.number(100000);

      return `${this.currency} ${number}`;
    },
    sales_period_name: function () {
      return `${this.period} ${this.year}`;
    },
    vat_rate: VAT_RATE,
    sales: createRandomSaleRecords(),
    total_sales: function () {
      return calculateTotal(this.sales);
    },
    oos_charges: createRandomSaleRecords(),
    total_oos_charges: function () {
      return calculateTotal(this.sales);
    },
    refunds_current_period: createRandomSaleRecords(),
    total_refunds_current_period: function () {
      return calculateTotal(this.refunds_current_period);
    },
    refunds_earlier_period: createRandomSaleRecords(),
    total_refunds_earlier_period: function () {
      return calculateTotal(this.refunds_earlier_period);
    },
    shipping_reimbursements: createRandomReimbursementRecords(2, {
      quantity: 1
    }),
    total_shipping_reimbursements: function () {
      return calculateTotal(this.shipping_reimbursements);
    },
    // We will only allow one manual reimbursement
    manual_reimbursements: [{
      description: _emberCliMirage.faker.lorem.sentence(),
      amount: _emberCliMirage.faker.random.number(1000)
    }],
    total_manual_reimbursements: function () {
      return this.manual_reimbursements.reduce((prev, curr) => prev + curr, 0);
    },
    commissions: createRandomCommisions(),
    total_commissions_without_vat: function () {
      return calculateTotalOnRecords(this.commissions, 'amount_without_vat');
    },
    total_commissions_vat: function () {
      return calculateTotalOnRecords(this.commissions, 'vat_amount');
    },
    total_commissions_with_vat: function () {
      return calculateTotalOnRecords(this.commissions, 'amount_with_vat');
    },
    shipping_contributions: createRandomShippingContributions(),
    total_shipping_contributions_without_vat: function () {
      return calculateTotalOnRecords(this.shipping_contributions, 'amount_without_vat');
    },
    total_shipping_contributions_vat: function () {
      return calculateTotalOnRecords(this.shipping_contributions, 'vat_amount');
    },
    total_shipping_contributions_with_vat: function () {
      return calculateTotalOnRecords(this.shipping_contributions, 'amount_with_vat');
    },
    manual_fees: [{
      description: _emberCliMirage.faker.lorem.sentence(),
      amount_without_vat: _emberCliMirage.faker.random.number(1000),
      vat_amount: function () {
        return this.amount_without_vat * (VAT_RATE / 100);
      },
      amount_with_vat: function () {
        return this.vat_amount() + this.amount_without_vat;
      }
    }],
    total_manual_fees_without_vat: function () {
      return calculateTotalOnRecords(this.manual_fees, 'amount_without_vat');
    },
    total_manual_fees_vat: function () {
      return calculateTotalOnRecords(this.manual_fees, 'vat_amount');
    },
    total_manual_fees_with_vat: function () {
      return calculateTotalOnRecords(this.manual_fees, 'amount_with_vat');
    },
    total_payout_without_vat: {},
    total_payout_vat: {},
    total_payout_with_vat: {},
    account_number_end: '123',
    trouva_address: '300 St John St, Clerkenwell, London EC1V 4PA'
  });

  _exports.default = _default;
});