define("backalley/mirage/factories/shipment-rate", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    zone() {
      const source = _faker.default.random.arrayElement(['PT', 'GB', 'ES', 'FR']);

      const upsZone = _faker.default.random.word();

      return `${source}_${upsZone}`;
    },

    weight() {
      return _faker.default.random.number({
        min: 1,
        max: 70
      });
    },

    rate() {
      return {
        amount: _faker.default.random.number({
          min: 100,
          max: 85000
        }),
        currency: _faker.default.random.arrayElement(['GBP', 'EUR', 'USD'])
      };
    },

    rate_id() {
      return `${this.zone}#${this.weight}`;
    }

  });

  _exports.default = _default;
});