define("backalley/pods/components/order-notes/quick/issueOptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.issueList = void 0;
  let issueList = [{
    value: 'Active Cancelled',
    label: 'Active Cancelled'
  }, {
    value: 'Customer Cancelled',
    label: 'Customer Cancelled'
  }, {
    value: 'Adjustment entered',
    label: 'Adjustment entered'
  }, {
    value: 'OOS - Same boutique alternatives',
    label: 'OOS - Same boutique alternatives'
  }, {
    value: 'OOS - Cross boutique alternatives',
    label: 'OOS - Cross boutique alternatives'
  }, {
    value: 'OOS - Alternatives accepted',
    label: 'OOS - Alternatives accepted'
  }, {
    value: 'OOS - Alternatives rejected',
    label: 'OOS - Alternatives rejected'
  }, {
    value: 'OOS - Delay',
    label: 'OOS - Delay'
  }, {
    value: 'OOS - Straight to refund',
    label: 'OOS - Straight to refund'
  }, {
    value: 'OOS - Feed issue',
    label: 'OOS - Feed issue'
  }, {
    value: 'Incorrect Item',
    label: 'Incorrect Item'
  }, {
    value: 'Incorrect quantity',
    label: 'Incorrect quantity'
  }, {
    value: 'Item Faulty',
    label: 'Item Faulty'
  }, {
    value: 'Chasing',
    label: 'Chasing'
  }, {
    value: 'Damage reported',
    label: 'Damage reported'
  }, {
    value: 'Damage - replacement arranged',
    label: 'Damage - replacement arranged'
  }, {
    value: 'Damage - replacement not available',
    label: 'Damage - replacement not available'
  }, {
    value: 'Loss - investigating',
    label: 'Loss - investigating'
  }, {
    value: 'Loss - replacement arranged',
    label: 'Loss - replacement arranged'
  }, {
    value: 'Loss replacement not available',
    label: 'Loss replacement not available'
  }, {
    value: 'FRAUD caught',
    label: 'FRAUD caught'
  }, {
    value: 'FRAUD missed',
    label: 'FRAUD missed'
  }, {
    value: 'Fraud suspected - order cancelled',
    label: 'Fraud suspected - order cancelled'
  }, {
    value: 'Return processed',
    label: 'Return processed'
  }, {
    value: 'Cancelled return',
    label: 'Cancelled return'
  }, {
    value: 'Rejected return',
    label: 'Rejected return'
  }, {
    value: 'Customs issues reported',
    label: 'Customs issues reported'
  }, {
    value: 'Customs issues resolved',
    label: 'Customs issues resolved'
  }, {
    value: 'Shipping restrictions',
    label: 'Shipping restrictions'
  }, {
    value: 'Shipping delay',
    label: 'Shipping delay'
  }, {
    value: 'Delivery refunded',
    label: 'Delivery refunded'
  }, {
    value: 'Delivery issue',
    label: 'Delivery issue'
  }, {
    value: 'VAT invoice',
    label: 'VAT invoice'
  }, {
    value: 'Customer details amendment',
    label: 'Customer details amendment'
  }, {
    value: 'Duplicate order',
    label: 'Duplicate order'
  }, {
    value: 'Damage claim',
    label: 'Damage claim'
  }, {
    value: 'Loss claim',
    label: 'Loss claim'
  }, {
    value: 'Claim approved',
    label: 'Claim approved'
  }, {
    value: 'Delay accepted',
    label: 'Delay accepted'
  }, {
    value: 'Returned to sender',
    label: 'Returned to sender'
  }, {
    value: 'Quantity accepted',
    label: 'Quantity accepted'
  }, {
    value: 'Claim not approved',
    label: 'Claim not approved'
  }, {
    value: 'Claim submitted',
    label: 'Claim submitted'
  }, {
    value: 'Returned without tracking',
    label: 'Returned without tracking'
  }, {
    value: 'Other',
    label: 'Other'
  }];
  _exports.issueList = issueList;
});