define("backalley/pods/components/form-button-long-press/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6EHme6ER",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"button\",false],[12,\"class\",[22,\"calculatedClass\"]],[12,\"disabled\",[22,\"isDisabled\"]],[12,\"title\",[22,\"title\"]],[3,\"on\",[\"mousedown\",[23,0,[\"mousedown\"]]]],[3,\"on\",[\"mouseup\",[23,0,[\"mouseup\"]]]],[3,\"on\",[\"mouseleave\",[23,0,[\"mouseleave\"]]]],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/form-button-long-press/template.hbs"
    }
  });

  _exports.default = _default;
});