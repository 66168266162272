define("backalley/pods/components/prospective-boutique-form/component", ["exports", "moment", "@streethub/calcs", "ember-concurrency", "backalley/models/user", "backalley/models/country", "backalley/models/boutique", "backalley/enums/currencies", "backalley/enums/boutique-sign-up-tier", "backalley/enums/shipping-charge-per-currency-type"], function (_exports, _moment, _calcs, _emberConcurrency, _user, _country, _boutique, _currencies, _boutiqueSignUpTier, _shippingChargePerCurrencyType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showAdditionalInfo: false,
    isBoutiqueNameInUse: false,
    emailSent: false,
    currencies: _currencies.default.currencies,
    isShippingFeeModalOpen: false,
    signUpTierOptions: Object.values(_boutiqueSignUpTier.default),

    triggerEmail() {},

    isEmailExpired: Ember.computed('prospectiveBoutique.email_sent_at', function () {
      const {
        email_sent_at: emailSentAt
      } = this.prospectiveBoutique;

      if (!emailSentAt) {
        return false;
      }

      return (0, _moment.default)().diff((0, _moment.default)(emailSentAt), 'days') >= 6;
    }),
    isNotVatRegistered: Ember.computed('prospectiveBoutique.is_vat_registered', {
      get() {
        return !this.prospectiveBoutique.is_vat_registered;
      },

      set(_, value) {
        Ember.set(this.prospectiveBoutique, 'is_vat_registered', !value);
        return value;
      }

    }),
    waiveFulfilmentFee: Ember.computed('prospectiveBoutique.enable_fix_charge', {
      get() {
        return !this.prospectiveBoutique.enable_fix_charge;
      },

      set(_, waiveFulfilment) {
        Ember.set(this.prospectiveBoutique, 'enable_fix_charge', !waiveFulfilment);

        if (waiveFulfilment) {
          Ember.set(this.prospectiveBoutique, 'fix_shipping_charge', 0);
          Ember.set(this, 'isShippingFeeModalOpen', true);
          return waiveFulfilment;
        }

        if (this.prospectiveBoutique.selectedCurrency) {
          Ember.set(this.prospectiveBoutique, 'fix_shipping_charge', _shippingChargePerCurrencyType.default[this.prospectiveBoutique.selectedCurrency.currency_code]);
        }

        return waiveFulfilment;
      }

    }),
    signedBy: Ember.computed('prospectiveBoutique.signed_by', function () {
      return this._findUserById(this.prospectiveBoutique.signed_by);
    }),
    fixShippingPrice: Ember.computed('prospectiveBoutique.{fix_shipping_charge,enable_fix_charge}', {
      get() {
        if (!this.prospectiveBoutique.enable_fix_charge) {
          return 0;
        }

        return this.prospectiveBoutique.fix_shipping_charge ? this.prospectiveBoutique.fix_shipping_charge / 100 : null;
      },

      set(_, value) {
        let parsedValue = parseFloat(value, 10);

        if (isNaN(parsedValue)) {
          parsedValue = 0;
        }

        const {
          val: valueInPences
        } = _calcs.default.inPences({
          val: _calcs.default.math.eval(`${parsedValue}` * 100)
        });

        this.set('prospectiveBoutique.fix_shipping_charge', valueInPences);
        return value;
      }

    }),
    membershipFee: Ember.computed('prospectiveBoutique.membership_fee', {
      get() {
        return Number.isInteger(this.prospectiveBoutique.membership_fee) ? this.prospectiveBoutique.membership_fee / 100 : null;
      },

      set(_, value) {
        if (Ember.isBlank(value)) {
          Ember.set(this.prospectiveBoutique, 'membership_fee', null);
          return null;
        }

        Ember.set(this.prospectiveBoutique, 'membership_fee', value * 100);
        return value;
      }

    }),
    ometriaURL: Ember.computed('prospectiveBoutique.email', function () {
      const {
        email
      } = this.prospectiveBoutique;
      const url = ['https://app.ometria.com/app/23e5c9f4e06d0b8e/segments?state=', // Filter the contact list to only show this customer
      encodeURIComponent(`{"reportOffset":0,"query":{"filters":[{"value":["${email}"],"op":"eq","field":"email"}],"group":"all","query_type":"and"}}`), // Opens the Ometria dashboard to the second tab that shows the contact
      '#1'].join('');
      return url;
    }),

    _findUserById(userId) {
      let users = userId ? _user.default.find({
        _id: userId
      }) : Ember.RSVP.resolve([null]);
      return users.then(([user]) => user);
    },

    _searchUser: (0, _emberConcurrency.task)(function* (email) {
      yield (0, _emberConcurrency.timeout)(500);
      return yield _user.default.find({
        email: {
          $regex: `^${email}[A-Za-z0-9._%+-]*@(trouva|streethub)\.(com)$`,
          $options: 'i'
        }
      });
    }).restartable(),
    _searchCountry: (0, _emberConcurrency.task)(function* (name = '+') {
      yield (0, _emberConcurrency.timeout)(500);
      return yield _country.default.find({
        name: {
          $regex: '^' + name,
          $options: 'i'
        }
      });
    }).restartable(),
    _searchBoutiqueName: (0, _emberConcurrency.task)(function* (name = '+') {
      yield (0, _emberConcurrency.timeout)(500);
      const boutiques = yield _boutique.default.find({
        name: {
          $regex: `^${name.trim()}$`,
          $options: 'i'
        }
      });
      return this.prospectiveBoutique.set('isBoutiqueNameInUse', Ember.isPresent(boutiques));
    }).restartable(),
    actions: {
      selectSignedBy(signedBy) {
        Ember.set(this.prospectiveBoutique, 'signed_by', signedBy._id);
      },

      setRegisteredCountry(country) {
        if (!country || !country._id) {
          return;
        }

        this.setProperties({
          'prospectiveBoutique.populatedRegisteredCountry': country,
          'prospectiveBoutique.registered_address_country': country._id
        });
      },

      setCurrency(currency) {
        this.setProperties({
          'prospectiveBoutique.selectedCurrency': currency,
          'prospectiveBoutique.currency': currency.currency_code,
          'prospectiveBoutique.fix_shipping_charge': _shippingChargePerCurrencyType.default[currency.currency_code]
        });
      },

      handleFocusOut() {
        if (Ember.isBlank(this.prospectiveBoutique.commission_rate)) {
          Ember.set(this.prospectiveBoutique, 'commission_rate', 23);
        }
      },

      triggerEmail() {
        this.set('emailSent', true);
        return this.triggerEmail();
      },

      validateBoutiqueName() {
        this._searchBoutiqueName.perform(this.prospectiveBoutique.name);
      },

      setEmailAddress(email) {
        Ember.set(this.prospectiveBoutique, 'email', email.toLowerCase());
      },

      onCloseClick() {
        this.set('waiveFulfilmentFee', false);
        this.toggleProperty('isShippingFeeModalOpen');
      }

    }
  });

  _exports.default = _default;
});