define("backalley/pods/components/order-edit/order-changes/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    order: null,
    reservationBoutiques: null,
    classNames: ['box-view', 'box-view-events'],
    boutiqueIds: Ember.computed('reservationBoutiques.@each._id', function () {
      const {
        reservationBoutiques = []
      } = this;
      return reservationBoutiques.map(reservationBoutique => ({
        _id: reservationBoutique.boutique,
        name: Ember.get(reservationBoutique, 'reservations.firstObject.boutique.name')
      }));
    })
  });

  _exports.default = _default;
});