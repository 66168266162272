define("backalley/pods/main/colors/new/route", ["exports", "backalley/models/color"], function (_exports, _color) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return {
        color: _color.default.create({}),
        actions: {
          create: create.bind(this)
        }
      };
    }

  });

  _exports.default = _default;

  function create(color) {
    return color.post().then(() => this.transitionTo('main.colors'), err => this.notify.show('err', err.message));
  }
});