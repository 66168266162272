define("backalley/pods/components/category-tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S5PNL7mK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\",true],[10,\"class\",\"tag\"],[8],[0,\"\\n  \"],[7,\"em\",true],[8],[1,[24,[\"tree\",\"macro\",\"name\"]],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"tree\",\"child\",\"name\"]]],null,{\"statements\":[[0,\"    \"],[7,\"em\",true],[8],[1,[24,[\"tree\",\"parent\",\"name\"]],false],[9],[0,\"\\n    \"],[1,[24,[\"tree\",\"child\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[24,[\"tree\",\"parent\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[7,\"a\",false],[3,\"action\",[[23,0,[]],[24,[\"action\"]],[24,[\"tree\"]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/category-tag/template.hbs"
    }
  });

  _exports.default = _default;
});