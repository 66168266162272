define("backalley/enums/boutique-restock-information-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    TOP_100_OOS: 'top-100-oos',
    TOP_100_QTY: 'top-100-qty',
    TOP_100_GMV: 'top-100-gmv',
    TOP_100_HIGH_RISK: 'top-100-high-risk'
  };
  _exports.default = _default;
});