define("backalley/pods/components/curate-order-form/reservation/component", ["exports", "@glimmer/component", "backalley/models/curate/product", "backalley/enums/curate-order-reservation-status", "backalley/enums/curate-order-reservation-cancelled-reason", "backalley/enums/curate-order-status"], function (_exports, _component, _product, _curateOrderReservationStatus, _curateOrderReservationCancelledReason, _curateOrderStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CurateOrderFormReservationComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "imageTransformation", _product.default.imageTransformation);

      _defineProperty(this, "cancelledStatusOptions", Object.values(_curateOrderReservationCancelledReason.default).map(reason => ({
        value: _curateOrderReservationStatus.default.CANCELLED,
        reason
      })));
    }

    get statusOptions() {
      return [{
        value: _curateOrderReservationStatus.default.CREATED
      }, ...this.cancelledStatusOptions, {
        value: _curateOrderReservationStatus.default.CONFIRMED,
        disabled: true
      }, {
        value: _curateOrderReservationStatus.default.SHIPPED,
        disabled: true
      }];
    }

    get thumbnail() {
      return this.args.reservation.stock_line.images[0];
    }

    get selectedStatus() {
      return this.statusOptions.find(({
        value,
        reason
      }) => {
        const {
          status,
          events
        } = this.args.reservation;

        if (status === _curateOrderReservationStatus.default.CANCELLED) {
          return status === value && reason === events.cancelled.reason;
        }

        return status === value;
      });
    }

  }

  _exports.default = CurateOrderFormReservationComponent;
});