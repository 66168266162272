define("backalley/enums/shipping-charge-per-currency-type", ["exports", "backalley/enums/types"], function (_exports, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _types.default.create({
    EUR: 199,
    GBP: 199,
    SEK: 2300,
    DKK: 1800
  });

  _exports.default = _default;
});