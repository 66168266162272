define("backalley/pods/main/boutique-categories/index/route", ["exports", "backalley/models/boutique-category"], function (_exports, _boutiqueCategory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return _boutiqueCategory.default.find().then(boutique_categories => {
        return {
          boutique_categories
        };
      });
    }

  });

  _exports.default = _default;
});