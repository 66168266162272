define("backalley/pods/main/boutique-restock-information/edit/route", ["exports", "backalley/models/boutique-restock-information"], function (_exports, _boutiqueRestockInformation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MainBoutiqueRestockInformationEditRoute extends Ember.Route {
    model({
      id
    }) {
      return _boutiqueRestockInformation.default.findById(id);
    }

  }

  _exports.default = MainBoutiqueRestockInformationEditRoute;
});