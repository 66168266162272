define("backalley/util/array-unique", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = arrayUnique;

  /**
   * Quite a small piece of code, but often find it needs explanation
   * here the function name helps.
   */
  function arrayUnique(list) {
    return [...new Set(Array.from(list))];
  }
});