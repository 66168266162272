define("backalley/enums/channel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    TROUVA: 'trouva',
    FY: 'fy',
    MADE_DOT_COM: 'madecom',
    FASHION_VERTICAL: 'fashion'
  };
  _exports.default = _default;
});