define("backalley/pods/main/packaging/categories/edit/route", ["exports", "backalley/models/packaging/category"], function (_exports, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PackagingCategoriesEditRoute extends Ember.Route {
    model({
      id
    }) {
      return _category.default.findById(id);
    }

  }

  _exports.default = PackagingCategoriesEditRoute;
});