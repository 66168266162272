define("backalley/mirage/factories/boutique", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name() {
      return _faker.default.company.companyName();
    },

    slug() {
      return Ember.String.dasherize(this.name);
    },

    vat_number() {
      return _faker.default.random.number();
    },

    description: i => `description ${i}`,
    address: i => `address ${i}`,
    currency: 'GBP',
    location: {
      lat: i => `lat ${i}`,
      lon: i => `lon ${i}`
    },
    postcode: i => `postcode ${i}`,
    oh: {
      day: i => `${i % 31}`,
      hours: i => `${i % 24}`
    },
    support_email: 'support_email@streethub.com',
    support_phone: '120 4341 8034',
    images: [],
    cover: i => {
      return {
        url: `cover ${i}`
      };
    },
    cover_thumbnail: i => {
      return {
        url: `cover thumbnail ${i}`
      };
    },
    logo: i => {
      return {
        url: `logo ${i}`
      };
    },
    founder_quote: i => {
      return {
        url: `founder quote ${i}`
      };
    },
    styles: [{
      _id: 1,
      label: 'one'
    }, {
      _id: 2,
      label: 'two'
    }],
    tags: [{
      _id: 1,
      label: 'one'
    }, {
      _id: 2,
      label: 'two'
    }],
    founder_image: i => {
      return {
        url: `founder quote ${i}`
      };
    },
    brand: {},
    areas: [{
      _id: 1,
      label: 'one'
    }, {
      _id: 2,
      label: 'two'
    }],
    ver_token: i => `ver_token ${i}`,
    ss_token: i => `ss_token ${i}`,
    published: i => i % 2 === 0,
    featured: i => i % 2 === 0,
    experiment: i => i % 2 === 0,
    same_day: i => i % 2 === 0,
    tote_bags: i => i % 2 === 0,
    order_email: 'order_email@streethub.com',

    created_at() {
      return _faker.default.date.past(Math.random() * 100);
    },

    _id: i => String(i + 1)
  });

  _exports.default = _default;
});