define("backalley/enums/language-type", ["exports", "backalley/enums/types"], function (_exports, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _types.default.create({
    DEUTSCH: 'de',
    ENGLISH: 'en',
    SPANISH: 'es',
    FRENCH: 'fr',
    ITALIAN: 'it'
  });

  _exports.default = _default;
});