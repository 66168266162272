define("backalley/pods/main/tags/edit/route", ["exports", "backalley/models/tag", "backalley/models/category"], function (_exports, _tag, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MainTagsEditRoute extends Ember.Route {
    model({
      tag_id
    }) {
      return Ember.RSVP.hash({
        tag: _tag.default.findById(tag_id),
        categories: _category.default.find({
          tags: tag_id,
          limit: 100
        })
      });
    }

    serialize(model) {
      return {
        tag_id: model._id
      };
    }

  }

  _exports.default = MainTagsEditRoute;
});