define("backalley/pods/components/modal-drawer/component", ["exports", "ember-keyboard", "jquery"], function (_exports, _emberKeyboard, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    classNames: ['modal-drawer'],
    title: '',
    isOpen: false,
    isSmall: false,

    onCloseClick() {
      this.set('isOpen', false);
    },

    isOpenChanged: Ember.observer('isOpen', function () {
      const className = this.isOpen ? 'addClass' : 'removeClass';
      (0, _jquery.default)('html')[className]('disable-scroll');
      (0, _jquery.default)('html')[className]('modal-drawer--open');
      this.set('keyboardActivated', this.isOpen);
    }),
    onKeyboardDismiss: Ember.on((0, _emberKeyboard.keyDown)('Escape'), function () {
      this.set('isOpen', false);
    }),
    actions: {
      onCloseClick() {
        this.onCloseClick();
      }

    }
  });

  _exports.default = _default;
});