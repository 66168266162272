define("backalley/pods/main/statements/boutique/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['until'],
    until: (0, _moment.default)().startOf('day').subtract({
      weeks: 2,
      seconds: 1
    }).toISOString(),
    lock_until: null
  });

  _exports.default = _default;
});