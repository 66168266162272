define("backalley/enums/product-pipeline-sort-type", ["exports", "backalley/enums/types"], function (_exports, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _types.default.create({
    NOT_PUBLISHED_TO_PUBLISH: {
      stage: 'not_published',
      state: 'to_publish',
      sortProperty: 'not_published_to_publish',
      query: {
        published: false,
        featured: false,
        unsuitable_state: 'published_suitable',
        product_notes_state: 'no_notes_to_resolve'
      }
    },
    NOT_PUBLISHED_TO_RESOLVE: {
      stage: 'not_published',
      state: 'to_resolve',
      sortProperty: 'not_published_to_resolve',
      query: {
        featured: null,
        published: false,
        unsuitable_state: 'published_suitable',
        product_notes_state: 'notes_to_resolve'
      }
    },
    NOT_PUBLISHABLE_NONE: {
      stage: 'not_publishable',
      state: 'none',
      sortProperty: 'not_publishable_none',
      query: {
        published: false,
        featured: false,
        unsuitable_state: 'published_unsuitable',
        product_notes_state: null
      }
    },
    PUBLISHED_TO_FEATURE: {
      stage: 'published',
      state: 'to_feature',
      sortProperty: 'published_to_feature',
      query: {
        featured: false,
        published: true,
        unsuitable_state: null,
        product_notes_state: 'no_notes_to_review_or_resolve'
      }
    },
    NOT_PUBLISHED_TO_FEATURE: {
      stage: 'not_published',
      state: 'to_feature',
      sortProperty: 'not_published_to_feature',
      query: {
        featured: false,
        unsuitable_state: null,
        product_notes_state: 'no_notes_to_review_or_resolve'
      }
    },
    PUBLISHED_TO_REVIEW: {
      stage: 'published',
      state: 'to_review',
      sortProperty: 'published_to_review',
      query: {
        featured: false,
        published: true,
        unsuitable_state: 'featured_suitable',
        product_notes_state: 'only_notes_to_review'
      }
    },
    PUBLISHED_TO_RESOLVE: {
      stage: 'published',
      state: 'to_resolve',
      sortProperty: 'published_to_resolve',
      query: {
        featured: false,
        published: true,
        unsuitable_state: 'featured_suitable',
        product_notes_state: 'notes_to_resolve'
      }
    },
    NOT_FEATUREABLE_NONE: {
      stage: 'not_featureable',
      state: 'none',
      sortProperty: 'not_featureable_none',
      query: {
        featured: false,
        published: true,
        unsuitable_state: 'featured_unsuitable',
        product_notes_state: null
      }
    },
    FEATURED_DONE: {
      stage: 'featured',
      state: 'done',
      sortProperty: 'featured_done',
      query: {
        published: true,
        featured: true,
        unsuitable_state: 'featured_suitable',
        product_notes_state: null
      }
    }
  });

  _exports.default = _default;
});