define("backalley/util/remote-feed", ["exports", "backalley/util/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Simple class to load a boutique's feed and search it for a particular item.
   */
  class RemoteFeed {
    async fetch(url) {
      this.status = 200;
      this.error = null;
      this.data = await (0, _request.request)({
        method: 'GET',
        url
      }).catch(ex => {
        if (ex.message.includes('404')) {
          this.status = 404;
          this.error = ex;
          return;
        }

        this.status = 500;
        this.error = ex;
      });
      this.data && this.parse();
    }

    parse() {
      if (this.error || !this.data) return null;

      if (typeof DOMParser === 'undefined') {
        this.error = new Error('DOMParser is not supported by your browser.');
        return null;
      }

      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(this.data, 'application/xml');
      const errorNode = xmlDoc.querySelector('parsererror');

      if (errorNode) {
        this.status = 415;
        this.error = new Error(errorNode.innerHTML);
        return;
      }

      this.doc = xmlDoc;
    }

    findItemByItemId(itemId) {
      if (this.error || !this.doc) return null;
      let targetItem = null;

      for (const item of this.doc.getElementsByTagName('item_id')) {
        const value = item.innerHTML.replace('<![CDATA[', '').replace(']]>', '').trim();

        if (value === itemId) {
          targetItem = item.parentNode;
          break;
        }
      }

      return targetItem;
    }

    findItemByParentId(parentId) {
      if (this.error || !this.doc) return null;
      let targetItem = null;

      for (const item of this.doc.getElementsByTagName('parent_id')) {
        const value = item.innerHTML.replace('<![CDATA[', '').replace(']]>', '').trim();

        if (value === parentId) {
          targetItem = item.parentNode;
          break;
        }
      }

      return targetItem;
    }

  }

  _exports.default = RemoteFeed;
});