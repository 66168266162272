define("backalley/pods/main/packaging/categories/new/route", ["exports", "backalley/models/packaging/category"], function (_exports, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PackagingCategoryNewRoute extends Ember.Route {
    model() {
      return new _category.default({});
    }

  }

  _exports.default = PackagingCategoryNewRoute;
});