define("backalley/pods/main/boutique-categories/edit/route", ["exports", "backalley/models/boutique-category"], function (_exports, _boutiqueCategory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      boutique_category_id
    }) {
      return _boutiqueCategory.default.findById(boutique_category_id, {
        populate: 'product_categories'
      }).then(boutiqueCategory => {
        return {
          boutiqueCategory
        };
      });
    },

    actions: {
      remove(boutiqueCategory) {
        return boutiqueCategory.remove().then(() => {
          this.notify.show('success', 'Boutique category deleted');
          this.transitionTo('main.boutique_categories');
        });
      },

      save(boutiqueCategory) {
        return boutiqueCategory.save().then(() => {
          this.notify.show('success', 'Boutique category saved');
          this.transitionTo('main.boutique_categories');
        });
      }

    }
  });

  _exports.default = _default;
});