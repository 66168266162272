define("backalley/pods/components/landing-page/layout-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4fA72yKd",
    "block": "{\"symbols\":[\"displayOption\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"style\",\"display: flex;margin: 0 -8px\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"option\",\"display\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"style\",[29,[\"width: \",[23,1,[\"width_percentage\"]],\";text-align: center; border: 1px solid lightgray;\"]]],[8],[0,\"\\n      W: \"],[1,[23,1,[\"width\"]],false],[0,\"px \"],[7,\"br\",true],[8],[9],[0,\"\\n      H: \"],[1,[23,1,[\"height\"]],false],[0,\"px\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/landing-page/layout-option/template.hbs"
    }
  });

  _exports.default = _default;
});