define("backalley/pods/components/boutique-reservation-item/boutique-reservation-item-product/options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.returnOptions = void 0;
  const returnOptions = [{
    value: 'No longer needed',
    label: 'I no longer need this'
  }, {
    value: 'Size didn\'t fit',
    label: 'The size didn\'t fit'
  }, {
    value: 'It isn\'t suitable',
    label: 'It isn\'t suitable'
  }, {
    value: 'Not as expected',
    label: 'Not what I expected'
  }, {
    value: 'incorrect product',
    label: 'Incorrect product received'
  }, {
    value: 'Damaged/Faulty product',
    label: 'It\'s damaged/faulty'
  }, {
    value: 'Other',
    label: 'Other'
  }];
  _exports.returnOptions = returnOptions;
});