define("backalley/enums/boutique-tier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    NONE: 'none',
    STAR: 'star',
    STAR_GOLD: 'star-gold',
    STAR_SILVER: 'star-silver',
    STAR_BRONZE: 'star-bronze'
  };
  _exports.default = _default;
});