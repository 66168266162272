define("backalley/models/user", ["exports", "backalley/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const User = Ember.Object.extend(_request.default, {
    isAdmin: Ember.computed.equal('role', 'admin'),
    hasUserManagementPermission: Ember.computed('permissions', function () {
      return this.permissions && this.permissions.includes('user_management');
    }),
    hasFeedsAdvancedSettingsPermission: Ember.computed('permissions', function () {
      return this.permissions && this.permissions.includes('feeds_advanced_settings');
    }),
    hasFeedsAdvancedUsagePermission: Ember.computed('permissions', function () {
      return this.permissions && this.permissions.includes('feeds_advanced_usage');
    }),

    save() {
      const method = this._id ? 'put' : 'post';
      const endpoint = this._id ? `users/${this._id}` : 'users';
      const data = Object.assign({}, this);

      if (method === 'put') {
        delete data.email;
        delete data.password;
        delete data.__v;
        delete data.feature_flags;
      }

      return this.request({
        method,
        url: this.apiURL(endpoint),
        data: JSON.stringify(data)
      }, false);
    }

  });
  User.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data = {}) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  User.reopenClass(_request.default, {
    create(data) {
      let c = this._super(data);

      c.setOriginalData(data);
      return c;
    },

    /**
     * Find users
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.findWithMeta(data).then(res => res.users);
    },

    /**
     * Find users and returns meta too
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMeta(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('users'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(({
        users,
        meta
      }) => {
        return {
          users: users.map(this.create.bind(this)),
          meta
        };
      });
    }

  });
  var _default = User;
  _exports.default = _default;
});