define("backalley/pods/main/customers/controller", ["exports", "backalley/mixins/request", "ember-concurrency"], function (_exports, _request, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_request.default, {
    emailToAnonymize: null,
    hasFinishedAnonymization: false,
    anonymizeTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(5000);
      yield this.request({
        method: 'POST',
        url: this.apiURL('anonymizations'),
        data: JSON.stringify({
          email: this.emailToAnonymize
        })
      });
      this.set('hasFinishedAnonymization', true);
      this.set('emailToAnonymize', null);
      Ember.run.later(() => {
        Ember.trySet(this, 'hasFinishedAnonymization', false);
      }, 2500);
    }),
    actions: {
      anonymize(e) {
        e.preventDefault();
        const regExp = new RegExp(/@streethub.com|@trouva.com/gi);
        const isTrouvaEmail = regExp.test(this.emailToAnonymize);

        if (isTrouvaEmail) {
          alert('Please, don\'t try to anonymize your colleagues\' data :)');
          return;
        }

        this.anonymizeTask.perform();
      },

      cancelAnonymize() {
        this.anonymizeTask.cancelAll();
      },

      resetEmailToAnonymize() {
        this.set('emailToAnonymize', null);
      }

    }
  });

  _exports.default = _default;
});