define("backalley/pods/components/input-percentage/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d8dB+puB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"inputs\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"disabled\"]]],null,{\"statements\":[[0,\"    \"],[1,[22,\"valueInPercentage\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"input\",[],[[\"@class\",\"@type\",\"@min\",\"@max\",\"@value\",\"@input\"],[\"input\",\"number\",[22,\"min\"],[22,\"max\"],[22,\"valueInPercentage\"],[28,\"action\",[[23,0,[]],\"updatePercentage\"],null]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"percentage\"],[8],[0,\"%\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showError\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[8],[0,\"Percentage must be between \"],[1,[22,\"min\"],false],[0,\" and a \"],[1,[22,\"max\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/input-percentage/template.hbs"
    }
  });

  _exports.default = _default;
});