define("backalley/enums/shipment-context", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Object.freeze({
    LATE_BEING_BOOKED: 'late-being-booked',
    LATE_BEING_TRACKED: 'late-being-tracked',
    HAS_STATUS_NOTE: 'has-status-note',
    HAS_REBOOK_ATTEMPTS: 'has-rebook-attempts'
  });

  _exports.default = _default;
});