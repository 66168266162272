define("backalley/enums/reservation-boutique-status-type", ["exports", "backalley/enums/types"], function (_exports, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _types.default.create({
    CREATING: 'creating',
    ACTIVE: 'active',
    PRINT: 'print',
    COLLECTION: 'collection',
    CLICK_AND_COLLECT: 'click-and-collect',
    RETURN: 'return',
    COMPLETED: 'completed',
    FAIL_PAYMENT: 'fail payment'
  });

  _exports.default = _default;
});