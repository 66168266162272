define("backalley/mirage/factories/category", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // mirage/factories/category.js
  var _default = _emberCliMirage.default.Factory.extend({
    name: i => `name ${i}`,
    full_hierarchy_name: i => `name > ${i}`,
    tags: [{
      _id: 1,
      label: 'one'
    }, {
      _id: 2,
      label: 'too'
    }],
    macro: 1,
    parent: 1,
    _id: i => String(i + 1),

    // id starts at 1
    created_at() {
      return _emberCliMirage.faker.date.past(Math.round(Math.random() * 100));
    },

    updated_at() {
      return _emberCliMirage.faker.date.past(Math.round(Math.random() * 10));
    }

  });

  _exports.default = _default;
});