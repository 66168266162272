define("backalley/router", ["exports", "jquery", "backalley/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('login');
    this.route('main', {
      path: '/'
    }, function () {
      this.route('colors', function () {
        this.route('new');
        this.route('edit', {
          path: '/:color_id'
        });
      });
      this.route('currencies', function () {
        this.route('new');
        this.route('edit', {
          path: '/:currency_id'
        });
      });
      this.route('campaigns', function () {
        this.route('new');
        this.route('edit', {
          path: '/:campaign_id'
        });
      });
      this.route('transactions', function () {});
      this.route('transactions-view', function () {});
      this.route('tags', function () {
        this.route('new');
        this.route('edit', {
          path: '/:tag_id'
        });
      });
      this.route('categories', function () {
        this.route('edit', {
          path: '/:category_id'
        });
      });
      this.route('sizes', function () {
        this.route('edit', {
          path: '/:size_id'
        });
        this.route('new');
      });
      this.route('landing', function () {
        this.route('new');
        this.route('edit', {
          path: '/new/:page_id'
        });
      });
      this.route('info', function () {
        this.route('new');
        this.route('edit', {
          path: '/new/:page_id'
        });
      });
      this.route('discounts', function () {
        this.route('new');
        this.route('edit', {
          path: '/:discount_id'
        });
      });
      this.route('boutiques', function () {
        this.route('edit', {
          path: '/:boutique_id'
        });
      });
      this.route('areas', function () {
        this.route('new');
        this.route('edit', {
          path: '/:area_id'
        });
      });
      this.route('countries', function () {
        this.route('edit', {
          path: '/:country_id'
        });
      });
      this.route('zones', function () {
        this.route('new');
        this.route('edit', {
          path: '/:zone_id'
        });
      });
      this.route('orders', function () {
        this.route('edit', {
          path: '/:order_id'
        });
      });
      this.route('statements', function () {
        this.route('edit', {
          path: '/:boutique_id/edit'
        });
        this.route('boutique', {
          path: '/:boutique_id'
        });
        this.route('all');
      });
      this.route('feeds', function () {
        this.route('edit', {
          path: '/:syncintegration_id/edit'
        });
        this.route('trigger', {
          path: '/:syncintegration_id/trigger'
        });
        this.route('permanent-rules', {
          path: '/:syncintegration_id/permanent-rules'
        });
        this.route('inspect', {
          path: '/:syncintegration_id/inspect/:item_id'
        });
        this.route('new');
      });
      this.route('productpipeline', function () {});
      this.route('product-amendments');
      this.route('manufacturers', function () {
        this.route('edit', {
          path: '/:manufacturer_id'
        });
        this.route('new');
      });
      this.route('materials', function () {
        this.route('edit', {
          path: '/:material_id'
        });
        this.route('new');
      });
      this.route('quotes', function () {
        this.route('edit', {
          path: '/:quote_id'
        });
        this.route('new');
      });
      this.route('publishers', function () {
        this.route('edit', {
          path: '/:publisher_id'
        });
        this.route('new');
      });
      this.route('publisherlinks', function () {
        this.route('edit', {
          path: '/:publisherlink_id'
        });
        this.route('new');
      });
      this.route('tasks', function () {
        this.route('edit', {
          path: '/:task_id'
        });
        this.route('new');
      });
      this.route('currency_statements', function () {
        this.route('index_v2');
        this.route('view', {
          path: 'preview'
        });
      });
      this.route('boutique_categories', function () {
        this.route('new');
        this.route('edit', {
          path: '/:boutique_category_id'
        });
      });
      this.route('manual_adjustments', function () {
        this.route('import');
        this.route('edit', {
          path: '/:adjustment_id'
        });
      });
      this.route('shipments', function () {});
      this.route('currency_conversion_rates', function () {});
      this.route('user_management', function () {
        this.route('edit', {
          path: '/:user_id'
        });
        this.route('new');
      });
      this.route('prospective-boutique', {
        path: '/contracts'
      }, function () {
        this.route('edit', {
          path: '/:prospective_boutique_id'
        });
        this.route('new');
      });
      this.route('customers');
      this.route('shipment-zones', function () {
        this.route('edit', {
          path: '/:id'
        });
        this.route('new');
      });
      this.route('shipment-rates', function () {});
      this.route('currency_conversion_margins');
      this.route('notifications', function () {
        this.route('new');
        this.route('edit', {
          path: '/:id'
        });
      });
      this.route('product-delivery-tags', function () {
        this.route('new');
      });
      this.route('shipping-group-configurations', function () {
        this.route('new');
        this.route('edit', {
          path: '/:id'
        });
      });
      this.route('curate', function () {
        this.route('orders', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('brands', function () {
          this.route('new');
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('products', function () {
          this.route('upload');
          this.route('edit', {
            path: '/:id'
          });
          this.route('new');
        });
        this.route('brand-tags', function () {
          this.route('new');
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('categories', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
      });
      this.route('packaging', function () {
        this.route('products', function () {
          this.route('new');
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('categories', function () {
          this.route('new');
          this.route('edit', {
            path: '/:id'
          });
        });
        this.route('orders', function () {
          this.route('edit', {
            path: '/:id'
          });
        });
      });
      this.route('frame-tags', function () {
        this.route('new');
      });
      this.route('frames', function () {
        this.route('edit', {
          path: '/:slug'
        });
        this.route('new');
      });
      this.route('boutique-restock-information', function () {
        this.route('new');
        this.route('edit', {
          path: '/:id'
        });
      });
      this.route('paths-and-lanes', function () {
        this.route('new');
      });
      this.route('triggers');
      this.route('made', function () {
        this.route('orders', function () {
          this.route('edit', {
            path: '/:order_reference'
          });
        });
        this.route('invoices');
      });
    });
    this.route('products', function () {
      this.route('edit', {
        path: '/:product_id'
      });
    });
  });
  Ember.Route.reopen({
    globals: Ember.inject.service(),

    beforeModel(transition) {
      this._super(...arguments);

      (0, _jquery.default)('html').addClass('loading');
      const routeName = this.routeName;
      const isLoggedIn = this.get('session.isLoggedIn');
      const isAdmin = this.get('session.user.role') === 'admin';

      const isRouteWhitelisted = _environment.default.unauthenticatedRoutes.includes(routeName);

      const routeRequiresAuthentication = !isRouteWhitelisted; // integrate with Ember Intl, initialized via initializers/intl.js

      const intl = this.intl;
      const nav = typeof navigator !== 'undefined' ? navigator : {};
      let locales = nav.languages && nav.languages.length ? nav.languages : nav.language ? [nav.language] : []; // fix for strange behaviour of nav.language whilst testing in CircleCI
      // Which is set to 'S' rather unexpected. Haven't been able to trace the
      // real cause, but this fixes the symptom and allows tests to function.

      if (window && window.Intl && window.Intl.getCanonicalLocales) {
        try {
          locales = window.Intl.getCanonicalLocales(locales);
        } catch (ex) {
          this.logger.info('navigator.language holds unexpected value, forcing en-GB');
          locales = ['en-GB'];
        }
      }

      if (locales.length) {
        intl && intl.setLocale(locales);
      } // If the page is /login and the user is already logged in
      // redirect the user to the homepage


      if (routeName === 'login' && isLoggedIn && isAdmin) {
        return this.transitionTo('main.index');
      }

      if (routeRequiresAuthentication && (!isLoggedIn || !isAdmin)) {
        return this.transitionTo('login');
      } // Hide the global search ui on every transition


      this.globals.setGlobalSearch(false);
      return this._super(transition);
    },

    afterModel() {
      this._super();

      (0, _jquery.default)('html').removeClass('loading');
    },

    activate() {
      this._super();

      let cssClass = this.toCssClass(this.routeName);
      (0, _jquery.default)('html').addClass(cssClass);
    },

    deactivate() {
      this._super();

      let cssClass = this.toCssClass(this.routeName);
      (0, _jquery.default)('html').removeClass(cssClass);
    },

    toCssClass(name) {
      return `route-${Ember.String.dasherize(name.replace(/\./g, '-'))}`;
    }

  });
});