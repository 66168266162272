define("backalley/mixins/shipment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    shipments: [],
    shipmentMostRecent: Ember.computed.alias('shipments.0'),

    /**
     * Shipments can be loaded by other means (usually from the order
     * level). This function allows us to pass a list of shipments
     * and have only those that apply to this reservation be attached.
     *
     * @param {Array} shipments
     */
    filterAndAssignShipments(shipments) {
      const getResourceId = this.getPossiblyPopulatedId;
      const orderId = getResourceId(this.order);
      const boutiqueId = getResourceId(this.boutique);

      if (!orderId || !boutiqueId) {
        return false;
      }

      this.set('shipments', (shipments || []).filter(shipment => {
        const shipmentBoutiqueId = getResourceId(shipment.boutique);
        const shipmentOrderId = getResourceId(shipment.order);

        if (!shipmentOrderId || !shipmentBoutiqueId) {
          return false;
        }

        const isThisOrder = shipmentOrderId === orderId;
        const isThisBoutique = shipmentBoutiqueId === boutiqueId;
        return isThisOrder && isThisBoutique;
      })); // if it exists for this context, trigger the shipment selector

      this.configureShipmentSelector && this.configureShipmentSelector();
      return true;
    }

  });

  _exports.default = _default;
});