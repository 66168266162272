define("backalley/pods/components/status-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U9cY/l8W",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"hasItems\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[28,\"concat\",[\"status-box \",[24,[\"status\"]]],null]],[8],[0,\"\\n    \"],[14,1,[[24,[\"items\"]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/status-box/template.hbs"
    }
  });

  _exports.default = _default;
});