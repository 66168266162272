define("backalley/pods/main/made/orders/edit/route", ["exports", "backalley/models/order", "backalley/models/shipment", "backalley/models/reservation", "backalley/models/manual-adjustment", "backalley/models/event-store", "backalley/models/reservation_boutique", "backalley/enums/channel", "backalley/util/group-by"], function (_exports, _order, _shipment, _reservation, _manualAdjustment, _eventStore, _reservation_boutique, _channel, _groupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const shippingVariantsStartedAt = new Date('2020-12-29T11:45:28.483Z');
  let MainMadeOrdersEditRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class MainMadeOrdersEditRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "notify", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);
    }

    async model({
      order_reference
    }) {
      const {
        orders: [order]
      } = await _order.default.find({
        channel: _channel.default.MADE_DOT_COM,
        'channel_specific.order_reference': order_reference
      });

      if (!order) {
        this.notify.show('error', `Couldn't find MADE order with reference #${order_reference}`);
        return this.router.transitionTo('main.made.orders');
      }

      const orderId = order._id;
      const [reservations, shipments] = await Promise.all([_reservation.default.find({
        order: orderId,
        populate: 'product,boutique'
      }), _shipment.default.find({
        order: orderId,
        sort: '-created_at'
      })]);
      reservations.forEach(reservation => reservation.filterAndAssignShipments(shipments));
      const reservationsGroupedByPath = new Date(order.created_at) >= shippingVariantsStartedAt ? Object.values((0, _groupBy.default)(reservations, 'shipping_variant._id')).filter(v => v) : [];
      const reservationsGroupedByBoutique = Object.values((0, _groupBy.default)(reservations, 'boutique._id'));
      const reservationBoutiques = await Promise.all(reservationsGroupedByBoutique.map(reservationsPerBoutique => {
        const {
          _id: boutiqueId
        } = reservationsPerBoutique[0].boutique;
        return _reservation_boutique.default.find(orderId, boutiqueId, reservationsPerBoutique);
      }));
      reservationBoutiques.forEach(reservationBoutique => reservationBoutique.filterAndAssignShipments(shipments));
      return Ember.RSVP.hash({
        adjustments: _manualAdjustment.default.findWithMeta({
          order: orderId
        }),
        adjustmentConfig: _manualAdjustment.default.getConfig(),
        customerOrders: _order.default.find({
          email: order.email,
          fields: '_id,status'
        }).then(({
          orders
        }) => orders),
        editing: {},
        eventsFired: _eventStore.default.findByEntityAndId({
          entity: 'order',
          entityId: orderId
        }),
        order,
        reservations,
        reservationBoutiques,
        reservationsGroupedByPath,
        shipments,
        zendeskTickets: []
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MainMadeOrdersEditRoute;
});