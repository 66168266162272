define("backalley/models/currency-statement", ["exports", "backalley/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CurrencyStatement = Ember.Object.extend(_request.default, {
    save() {
      const statementObj = this.getProperties(Object.keys(this));
      return this.request({
        method: 'post',
        url: this.apiURL('currency_statements'),
        data: JSON.stringify(statementObj)
      }).then(response => {
        const data = response.currency_statements[0];
        this.setProperties(data);
      });
    },

    renderPreview() {
      this.set('statementHtmlString', ''); // Re-build statement object

      const statementObj = this.getProperties(Object.keys(this));
      return this.request({
        method: 'post',
        url: this.apiURL('currency_statements/render'),
        data: JSON.stringify(statementObj)
      }).then(response => {
        this.set('statementHtmlString', response);
      });
    }

  });
  CurrencyStatement.reopenClass(_request.default, {
    config(data) {
      return this.request({
        method: 'get',
        url: this.apiURL('currency_statements/config'),
        data
      });
    },

    boutiques(data) {
      return this.request({
        method: 'get',
        url: this.apiURL('currency_statements/boutiques'),
        data
      });
    },

    find(data) {
      return this.request({
        method: 'get',
        url: this.apiURL('currency_statements'),
        data
      }).then(response => {
        response.currency_statements = response.currency_statements.map(cs => CurrencyStatement.create(cs));
        return response;
      });
    }

  });
  var _default = CurrencyStatement;
  _exports.default = _default;
});