define("backalley/pods/components/color-tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R9xZibpp",
    "block": "{\"symbols\":[\"@color\"],\"statements\":[[7,\"li\",true],[10,\"class\",\"tag\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"color\"],[11,\"style\",[29,[\"background: \",[23,1,[\"value\"]],\";\"]]],[8],[9],[0,\"\\n  \"],[1,[23,1,[\"label\"]],false],[0,\"\\n  \"],[7,\"a\",false],[3,\"action\",[[23,0,[]],[24,[\"action\"]],[24,[\"color\"]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/color-tag/template.hbs"
    }
  });

  _exports.default = _default;
});