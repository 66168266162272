define("backalley/models/task", ["exports", "backalley/mixins/request", "joi", "backalley/util/models"], function (_exports, _request, _joi, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const validations = {
    _id: _joi.default.string(),
    assignee: _joi.default.string().required(),
    type: _joi.default.string().required(),
    filters: _joi.default.array().items(_joi.default.object()),
    url: _joi.default.string().min(1).required(),
    completed: _joi.default.boolean(),
    description: _joi.default.string(),
    completed_at: _joi.default.date(),
    updated_at: _joi.default.date(),
    created_at: _joi.default.date()
  };
  const Task = Ember.Object.extend(_request.default, {
    /**
     * Create new task
     * @return {Object} Promise
     */
    post() {
      return this.validate().then(task => {
        return this.request({
          method: 'POST',
          url: this.apiURL('tasks'),
          data: JSON.stringify(task)
        });
      }).then(({
        tasks
      }) => {
        const data = tasks[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    /**
     * Save task
     * @return {Object} Promise
     */
    save() {
      // task.dfields = computed property
      return this.validate().then(data => (0, _models.getChanges)(this._orig, data, Object.keys(validations))).then(task => {
        return this.request({
          method: 'PUT',
          url: this.apiURL(`tasks/${task._id}`),
          data: JSON.stringify(task)
        });
      }).then(({
        tasks
      }) => {
        const data = tasks[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    validate() {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(Task.schema, data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    /**
     * Remove task
     * @return {Object} Promise
     */
    remove() {
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`tasks/${this._id}`)
      });
    }

  });
  Task.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data = {}) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  Task.reopenClass(_request.default, {
    defaultTask() {
      return {
        filters: []
      };
    },

    schema: _joi.default.object().min(1).max(10).keys(validations),

    create(data) {
      let c = this._super(_objectSpread(_objectSpread({}, this.defaultTask()), data));

      c.setOriginalData(data);
      return c;
    },

    /**
     * Find tasks
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.findWithMeta(data).then(res => res.tasks);
    },

    /**
     * Find tasks and returns meta too
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMeta(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('tasks'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(({
        tasks,
        meta
      }) => {
        return {
          tasks: tasks.map(this.create.bind(this)),
          meta
        };
      });
    },

    /**
     * Find task by id
     * @param  {String} id task _id
     * @return {Object}    Promise
     */
    findById(id) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`tasks/${id}`),
        data: {
          plain: true
        }
      }).then(({
        tasks
      }) => this.create(tasks[0]));
    }

  });
  var _default = Task;
  _exports.default = _default;
});