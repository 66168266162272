define("backalley/util/group-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = groupBy;

  function groupBy(array, prop, perEntries = null) {
    const grouped = array.reduce(function (groups, item) {
      const val = prop.call ? prop(item) : Ember.get(item, prop);
      groups[val] = groups[val] || [];
      groups[val].push(item);
      return groups;
    }, {});

    if (perEntries && perEntries.call) {
      Object.entries(grouped).forEach((...args) => {
        const changedValues = perEntries(...args);
        const [[key]] = args;

        if (changedValues !== undefined) {
          grouped[key] = changedValues;
        }
      });
    }

    return grouped;
  }
});