define("backalley/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cJ6WZIiG",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Trouva\"],null],false],[0,\"\\n\\n\"],[1,[28,\"component\",[[28,\"hot-load\",[\"head-layout\",[23,0,[]],[24,[\"head-layout\"]],\"head-layout\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"head-layout\",[24,[\"head-layout\"]],false,false]]],false],[0,\"\\n\"],[1,[28,\"component\",[[28,\"hot-load\",[\"notify-popup\",[23,0,[]],[24,[\"notify-popup\"]],\"notify-popup\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"notify-popup\",[24,[\"notify-popup\"]],false,false]]],false],[0,\"\\n\"],[1,[28,\"component\",[[28,\"hot-load\",[\"iframe-preview\",[23,0,[]],[24,[\"iframe-preview\"]],\"iframe-preview\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"iframe-preview\",[24,[\"iframe-preview\"]],false,false]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"site-container \",[28,\"if\",[[24,[\"globals\",\"displayGlobalSearch\"]],\"no-scroll\"],null]]]],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/application/template.hbs"
    }
  });

  _exports.default = _default;
});