define("backalley/pods/main/boutique-categories/new/route", ["exports", "backalley/models/boutique-category"], function (_exports, _boutiqueCategory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      const boutiqueCategory = _boutiqueCategory.default.create();

      return {
        boutiqueCategory
      };
    },

    actions: {
      save(boutiqueCategory) {
        return boutiqueCategory.save().then(() => {
          this.notify.show('success', 'Boutique category created');
          this.transitionTo('main.boutique_categories');
        });
      },

      remove(boutiqueCategory) {
        return boutiqueCategory.remove().then(() => {
          this.notify.show('success', 'Boutique category deleted');
          this.transitionTo('main.boutique_categories');
        });
      }

    }
  });

  _exports.default = _default;
});