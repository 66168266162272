define("backalley/helpers/multiply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function multiply([a, b]) {
    return Number(a) * Number(b);
  });

  _exports.default = _default;
});