define("backalley/pods/main/currency-statements/view/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['period', 'year', 'currency', 'boutique'],
    currency: 'GBP',
    period: '',
    year: '',
    boutique: '',
    editMode: false
  });

  _exports.default = _default;
});