define("backalley/helpers/hot-load", ["exports", "ember-ast-hot-load/helpers/hot-load"], function (_exports, _hotLoad) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _hotLoad.default;
    }
  });
  Object.defineProperty(_exports, "hotLoad", {
    enumerable: true,
    get: function () {
      return _hotLoad.hotLoad;
    }
  });
});