define("backalley/pods/main/currency-statements/index/route", ["exports", "backalley/models/currency-statement", "backalley/models/boutique", "ember-concurrency"], function (_exports, _currencyStatement, _boutique, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    queryParams: {
      period: {
        refreshModel: true
      },
      year: {
        refreshModel: true
      },
      currency: {
        refreshModel: true
      },
      statements_version: {
        refreshModel: true
      },
      published: {
        refreshModel: true
      },
      archived: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      boutique: {
        refreshModel: true
      }
    },

    async fetchBoutiques(currency, period) {
      const {
        boutiques
      } = await _currencyStatement.default.boutiques({
        period: period.period,
        year: period.year,
        currency: currency
      });
      return boutiques.map(boutiqueId => ({
        _id: boutiqueId,
        name: ''
      }));
    },

    model({
      archived,
      published,
      period,
      year,
      currency,
      statements_version,
      page,
      limit,
      boutique
    }) {
      const boutiqueFilters = {
        statements_version: statements_version === 'all' ? undefined : statements_version,
        archived: archived === 'all' ? undefined : archived,
        published: published === 'all' ? undefined : published
      };
      return _currencyStatement.default.config().then(config => {
        // Find the selected period
        let selectedPeriod = config.periods.find(p => {
          return Number(p.year) === Number(year) && Number(p.period) === Number(period);
        }); // If no period was found, use the most recently concluded

        if (!selectedPeriod) {
          selectedPeriod = config.periods.find(p => {
            return Number(p.year) === Number(config.most_recently_concluded_period.year) && Number(p.period) === Number(config.most_recently_concluded_period.period);
          });
        } // Find the selected currency


        let selectedCurrency = config.currencies.find(currencyFromList => {
          return currencyFromList === currency;
        });

        if (!selectedCurrency) {
          selectedCurrency = config.default_currency;
        } // Find currency statements


        return this.fetchBoutiques(selectedCurrency, selectedPeriod, boutiqueFilters).then(boutiques => {
          let allBoutiques = boutiques;

          if (boutique && boutique !== '') {
            boutiques = boutiques.filter(({
              _id
            }) => _id === boutique);
          }

          let slicedBoutiques = boutiques.slice((page - 1) * limit, page * limit); // Cancel any pending boutique loads

          this.loadBoutiqueCurrencyStatementTask.cancelAll(); // Load all the boutique statements after the When the model has returned.

          Ember.run.next(() => {
            this.loadAllBoutiqueCurrencyStatementsTask.perform(selectedPeriod, selectedCurrency, slicedBoutiques);
          });
          return {
            // Dropdown options
            periods: config.periods,
            currencies: config.currencies,
            boutiques,
            boutiquesOptions: [{
              _id: '',
              name: 'All boutiques'
            }].concat(allBoutiques),
            // Dropdown selected values
            selectedCurrency,
            selectedPeriod,
            // Currency statements to be filled in by #loadBoutiqueCurrencyStatementTask
            currencyStatements: Array.from(slicedBoutiques.map(({
              _id
            }) => _id)),
            // Loading flag
            loading: true,
            loadingStatementsOverlay: false,
            meta: {
              page,
              page_total: Math.ceil(boutiques.length / limit)
            },
            isGeneratingStatements: false
          };
        });
      });
    },

    loadAllBoutiqueCurrencyStatementsTask: (0, _emberConcurrency.task)(function* (selectedPeriod, currency, boutiques) {
      // Assign the task to a variable for readability.
      const loadBoutiqueCurrencyStatementTask = this.loadBoutiqueCurrencyStatementTask; // Load all boutiqueCurrencyStatement serially.

      yield* boutiques.map(({
        _id: boutiqueId
      }) => loadBoutiqueCurrencyStatementTask.perform(selectedPeriod, currency, boutiqueId)); // The above serial execution has finished. Remove the loading state.

      yield this.set('currentModel.loading', false);
    }).keepLatest(),
    loadBoutiqueCurrencyStatementTask: (0, _emberConcurrency.task)(function* (selectedPeriod, currency, boutiqueId) {
      const {
        period,
        year
      } = selectedPeriod;
      let result;

      try {
        const {
          currency_statements
        } = yield _currencyStatement.default.find({
          boutique: boutiqueId,
          currency,
          period,
          year
        });
        result = currency_statements;
      } catch (error) {
        const errorMessage = Ember.getWithDefault(error, 'responseJSON.errors.0.detail', error.message) || 'No reason specified';
        const boutique = yield _boutique.default.findById(boutiqueId, {
          fields: 'boutique_name'
        });
        const boutiqueURL = this.router.urlFor('main.boutiques.edit', boutiqueId);
        result = [{
          errored: true,
          message: errorMessage,
          boutique,
          boutiqueURL
        }];
      } // Find the index of which boutique to replace in to the placeholders


      const currencyStatementModelIndex = this.currentModel.currencyStatements.findIndex(statement => statement === boutiqueId); // Load the statements 1 by 1 and queue them up.

      yield this.currentModel.currencyStatements.replace(currencyStatementModelIndex, 1, result);
    }).maxConcurrency(10).enqueue(),
    actions: {
      setStatementsVersion(option) {
        this.controller.setProperties({
          statements_version: option.value,
          page: 1
        });
      },

      setPublished(option) {
        this.controller.setProperties({
          published: option.value,
          page: 1
        });
      },

      setArchived(option) {
        this.controller.setProperties({
          archived: option.value,
          page: 1
        });
      },

      setBoutique(boutique) {
        this.controller.setProperties({
          boutique: boutique._id,
          page: 1
        });
      },

      // User is navigating to another page. Cancel all requests
      willTransition() {
        this.loadBoutiqueCurrencyStatementTask.cancelAll();
        this.loadAllBoutiqueCurrencyStatementsTask.cancelAll();
      },

      generateStatement(statement) {
        return statement.save().then(() => {
          this.notify.show('success', 'Generated successfully');
        }).catch(error => {
          this.notify.show('warning', error.responseJSON.errors[0].detail);
        });
      },

      selectCurrency(currency) {
        this.setProperties({
          'controller.currency': currency,
          'controller.page': 1,
          'currentModel.loadingStatementsOverlay': true
        });
      },

      selectPeriod(period) {
        this.setProperties({
          'controller.period': period.period,
          'controller.year': period.year,
          'controller.page': 1,
          'currentModel.loadingStatementsOverlay': true
        });
      },

      nextPage() {
        this.controller.set('page', this.controller.page + 1);
      },

      prevPage() {
        this.controller.set('page', this.controller.page - 1);
      },

      goToPage(page) {
        this.controller.set('page', page);
      },

      async generateStatements() {
        this.set('currentModel.isGeneratingStatements', true);
        const generatableStatements = this.currentModel.currencyStatements.filter(statement => {
          return statement.can_generate && !statement.statement_number;
        });
        const processingInterval = setInterval(() => {
          this.notify.showWarning('Still processing the statements...');
        }, 2000);

        try {
          this.notify.showWarning('Generating all statements for the current page, this might take a few seconds');
          await Promise.all(generatableStatements.map(statement => statement.save()));
          clearInterval(processingInterval);
          this.notify.showSuccess('All statements for the current page generated');
        } catch (error) {
          clearInterval(processingInterval);
          this.notify.showError('Some of the statements failed to be generated');
        }

        this.set('currentModel.isGeneratingStatements', false);
      }

    }
  });

  _exports.default = _default;
});