define("backalley/models/currency-conversion-margin", ["exports", "joi", "backalley/util/models", "backalley/util/request", "moment"], function (_exports, _joi, _models, _request, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CurrencyConversionMargin {
    constructor({
      _id,
      source,
      destination,
      margin,
      updated_at
    }) {
      this.id = _id;
      this.source = source;
      this.destination = destination;
      this.margin = margin;
      this.updatedAt = (0, _moment.default)(updated_at);
    }

    static create(params = {}) {
      return (0, _models.validateSchema)(CurrencyConversionMargin.schema, params).then(() => {
        return (0, _request.request)({
          method: 'POST',
          url: (0, _request.apiURL)('currency_conversion_margins'),
          data: JSON.stringify(params)
        });
      });
    }

    static find(params = {}) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.apiURL)('currency_conversion_margins'),
        data: params
      }).then(({
        currency_conversion_margins,
        meta
      }) => {
        return {
          currencyConversionMargins: currency_conversion_margins.map(currencyConversionMargin => new CurrencyConversionMargin(currencyConversionMargin)),
          meta
        };
      });
    }

    save() {
      return (0, _models.validateSchema)(CurrencyConversionMargin.schema, this).then(currencyConversionMargin => {
        return (0, _request.request)({
          method: 'PUT',
          url: (0, _request.apiURL)(`currency_conversion_margins/${this.id}`),
          data: JSON.stringify(currencyConversionMargin)
        });
      });
    }

  }

  _exports.default = CurrencyConversionMargin;

  _defineProperty(CurrencyConversionMargin, "schema", _joi.default.object().keys({
    id: _joi.default.string().required(),
    margin: _joi.default.number().min(0).required()
  }));
});