define("backalley/mirage/factories/contact", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    This is an example factory definition.
  
    Create more files in this directory to define additional factories.
  */
  var _default = _emberCliMirage.default.Factory.extend({// name: 'Pete',                         // strings
    // age: 20,                              // numbers
    // tall: true,                           // booleans
    // email: function(i) {                  // and functions
    //   return 'person' + i + '@test.com';
    // },
    // firstName: faker.name.firstName,       // using faker
    // lastName: faker.name.firstName,
    // zipCode: faker.address.zipCode
  });

  _exports.default = _default;
});