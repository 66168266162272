define("backalley/models/prospective_boutique", ["exports", "backalley/mixins/request", "joi", "backalley/util/models", "backalley/enums/boutique-sign-up-tier"], function (_exports, _request, _joi, _models, _boutiqueSignUpTier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const ProspectiveBoutique = Ember.Object.extend(_request.default, {
    /**
     * Create new prospective boutique
     * @return {Object} Promise
     */
    post() {
      return this.validate().then(prospectiveBoutique => {
        return this.request({
          method: 'POST',
          url: this.apiURL('prospective_boutiques'),
          data: JSON.stringify(prospectiveBoutique)
        });
      });
    },

    /**
     * Save prospective boutique
     * @return {Object} Promise
     */
    put() {
      return this.validate().then(prospectiveBoutique => {
        return this.request({
          method: 'PUT',
          url: this.apiURL(`prospective_boutiques/${prospectiveBoutique._id}`),
          data: JSON.stringify(prospectiveBoutique)
        });
      });
    },

    validate() {
      let data = JSON.parse(JSON.stringify(this));

      if (data.populatedRegisteredCountry && data.populatedRegisteredCountry._id) {
        data.registered_address_country = data.populatedRegisteredCountry._id;
      }

      this.set('errors', {});
      return (0, _models.validateSchema)(ProspectiveBoutique.schema, data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    remove() {
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`prospective_boutiques/${this._id}`)
      });
    },

    triggerEmail() {
      return this.validate().then(prospectiveBoutique => {
        return this.request({
          method: 'PUT',
          url: this.apiURL(`prospective_boutiques/${prospectiveBoutique._id}/setup-retailer`),
          data: JSON.stringify(prospectiveBoutique)
        });
      }).then(({
        prospective_boutiques
      }) => {
        const [prospective_boutique] = prospective_boutiques;
        return prospective_boutique;
      });
    }

  });
  ProspectiveBoutique.reopenClass(_request.default, {
    schema: _joi.default.object().keys({
      signed_by: _joi.default.string().required(),
      status: _joi.default.string(),
      _id: _joi.default.string(),
      name: _joi.default.string().required(),
      email: _joi.default.string().email({
        tlds: false
      }).required(),
      currency: _joi.default.string().required(),
      membership_fee: _joi.default.number().required(),
      commission_rate: _joi.default.number().min(0).max(100).required(),
      fix_shipping_charge: _joi.default.alternatives().conditional('enable_fix_charge', {
        is: true,
        then: _joi.default.number().min(1).message('fulfilment fee is required'),
        otherwise: _joi.default.number().valid(0).required()
      }),
      enable_fix_charge: _joi.default.boolean().required(),
      transit_protection: _joi.default.string().allow('').allow(null),
      responsibility_for_packaging: _joi.default.string().allow('').allow(null),
      other_commercial_terms: _joi.default.string().allow('').allow(null),
      primary_phone: _joi.default.string().allow('').allow(null),
      contract_owner_name: _joi.default.string().allow('').allow(null),
      registered_name: _joi.default.string().allow('').allow(null),
      registered_number: _joi.default.string().allow('').allow(null),
      vat_number: _joi.default.string().allow('').allow(null),
      is_vat_registered: _joi.default.boolean(),
      registered_address_line_1: _joi.default.string().allow('').allow(null),
      registered_address_line_2: _joi.default.string().allow('').allow(null),
      postcode: _joi.default.string().required(),
      registered_address_country: _joi.default.string().allow('').allow(null),
      user: _joi.default.string().allow('').allow(null),
      created_at: _joi.default.date().allow(null),
      created_by: _joi.default.object().allow(null),
      updated_at: _joi.default.date().allow(null),
      updated_by: _joi.default.object().allow(null),
      email_sent_at: _joi.default.date().allow(null),
      email_sent_by: _joi.default.object().allow(null),
      accepted_by: _joi.default.object().allow(null),
      accepted_at: _joi.default.date().allow(null),
      boutique: _joi.default.string().allow(null),
      sign_up_tier: _joi.default.string().valid(...Object.values(_boutiqueSignUpTier.default))
    }),

    create(data = {}) {
      const defaultData = {
        commission_rate: 23,
        transit_protection: 'Included as standard',
        responsibility_for_packaging: 'Retailer',
        is_vat_registered: true,
        enable_fix_charge: true,
        sign_up_tier: _boutiqueSignUpTier.default.STANDARD
      };
      return this._super(_objectSpread(_objectSpread({}, defaultData), data));
    },

    /**
     * Find boutiques
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.findWithMeta(data).then(res => res.prospectiveBoutiques);
    },

    /**
     * Find boutiques and returns meta too
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMeta(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('prospective_boutiques'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => {
        return {
          prospectiveBoutiques: res.prospective_boutiques.map(prospectiveBoutique => ProspectiveBoutique.create(prospectiveBoutique)),
          meta: res.meta
        };
      });
    }

  });
  var _default = ProspectiveBoutique;
  _exports.default = _default;
});