define("backalley/mirage/factories/tag", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // mirage/factories/tag.js
  var _default = _emberCliMirage.default.Factory.extend({
    label: i => `label ${i}`,
    is_filter: false,
    description: i => `description ${i}`,

    updated_at() {
      return _emberCliMirage.faker.date.past(Math.round(Math.random() * 100));
    },

    created_at() {
      return _emberCliMirage.faker.date.past(Math.round(Math.random() * 100));
    },

    categories: [{
      _id: 1,
      label: 'one'
    }],
    products: [{
      _id: 1,
      label: 'one'
    }],
    _id: i => String(i + 1) // id starts at 1

  });

  _exports.default = _default;
});