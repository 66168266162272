define("backalley/pods/main/boutiques/index/route", ["exports", "backalley/mixins/loading", "backalley/models/boutique", "backalley/config/environment", "backalley/util/escape-regex"], function (_exports, _loading, _boutique, _environment, _escapeRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ARCHIVED_FILTER_ANY = 'any';
  const ARCHIVED_FILTER_NOT_ARCHIVED = 'not_archived';
  const ARCHIVED_FILTER_ONLY_ARCHIVED = 'only_archived';
  const CURRENCY_FILTER_ANY = 'any';
  const CURRENCY_FILTER_ONLY_GBP = 'GBP';
  const CURRENCY_FILTER_ONLY_EUR = 'EUR';
  const TIER_FILTER_ANY = 'any';
  const TIER_FILTER_STAR = 'star';
  const TIER_FILTER_NONE = 'none';

  var _default = Ember.Route.extend(_loading.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      archived: {
        refreshModel: true
      },
      currency: {
        refreshModel: true
      },
      tier: {
        refreshModel: true
      }
    },
    boutiqueCache: new Map(),

    serializeQueryParam(value = {}, urlKey = '') {
      if (['sort', 'archived', 'currency', 'tier'].includes(urlKey)) {
        return value.value;
      }

      if (urlKey === 'page') return Number(value);
      return value;
    },

    deserializeQueryParam(value = {}, urlKey = '') {
      if (urlKey === 'sort') return this.availableFilters.findBy('value', value);
      if (urlKey === 'archived') return this.archivedFilters.findBy('value', value);
      if (urlKey === 'currency') return this.currencyFilters.findBy('value', value);
      if (urlKey === 'tier') return this.tierFilters.findBy('value', value);
      return value;
    },

    availableFilters: [{
      label: 'Date: Last updated',
      value: '-updated_at'
    }, {
      label: 'Date: First updated',
      value: 'updated_at'
    }, {
      label: 'Date: Last created',
      value: '-created_at'
    }, {
      label: 'Date: First created',
      value: 'created_at'
    }, {
      label: 'Date: Last published',
      value: '-published_at'
    }, {
      label: 'Date: First published',
      value: 'published_at'
    }, {
      label: 'Date: Last featured',
      value: '-featured_at'
    }, {
      label: 'Date: First featured',
      value: 'featured_at'
    }, {
      label: 'Name: A-Z',
      value: '-name'
    }, {
      label: 'Name: Z-A',
      value: 'name'
    }],
    archivedFilters: [{
      label: 'Archived: any',
      value: ARCHIVED_FILTER_ANY
    }, {
      label: 'Archived: not archived',
      value: ARCHIVED_FILTER_NOT_ARCHIVED
    }, {
      label: 'Archived: only archived',
      value: ARCHIVED_FILTER_ONLY_ARCHIVED
    }],
    currencyFilters: [{
      label: 'Currency: any',
      value: CURRENCY_FILTER_ANY
    }, {
      label: 'Currency: only boutiques in GBP',
      value: CURRENCY_FILTER_ONLY_GBP
    }, {
      label: 'Currency: only boutiques in EUR',
      value: CURRENCY_FILTER_ONLY_EUR
    }],
    tierFilters: [{
      label: 'Tier: Any',
      value: TIER_FILTER_ANY
    }, {
      label: 'Tier: Star',
      value: TIER_FILTER_STAR
    }, {
      label: 'Tier: None',
      value: TIER_FILTER_NONE
    }],

    model({
      q,
      page = 1,
      limit = 30,
      sort = {},
      archived = {},
      currency = {},
      tier = {}
    }) {
      let query = {
        page,
        limit,
        sort: sort.value
      };

      if (q) {
        query.name = {
          $regex: `${(0, _escapeRegex.default)(q)}`,
          $options: 'i'
        };
      }

      if (query.sort === 'published_at' || query.sort === '-published_at') {
        query.published = true;
      }

      if (query.sort === 'featured_at' || query.sort === '-featured_at') {
        query.featured = true;
      }

      if (archived.value !== ARCHIVED_FILTER_ANY) {
        query.archived = archived.value === ARCHIVED_FILTER_ONLY_ARCHIVED ? {
          $eq: true
        } : {
          $ne: true
        };
      }

      if (currency.value !== CURRENCY_FILTER_ANY) {
        query.currency = currency.value;
      }

      if (tier.value !== TIER_FILTER_ANY) {
        query.tier = {
          $regex: `^${tier.value}`,
          $options: 'i'
        };
      }

      return _boutique.default.findWithMeta(query).then(({
        boutiques,
        meta = {
          total: 3
        }
      }) => {
        return {
          search: q,
          boutiques,
          archived,
          currency,
          tier,
          total: Math.ceil(meta.total / limit),
          sort: sort,
          user: this.session.user,
          availableFilters: this.availableFilters,
          archivedFilters: this.archivedFilters,
          currencyFilters: this.currencyFilters,
          tierFilters: this.tierFilters,
          trouvaWebURL: _environment.default.trouvaWebURL,
          retailURL: _environment.default.retailURL
        };
      });
    },

    actions: {
      goToPage,
      nextPage,
      prevPage,
      updateSearch,
      updateFilter,
      updateArchivedFilter,
      updateCurrencyFilter,
      updateTierFilter,
      remove
    }
  });

  _exports.default = _default;

  function goToPage(page) {
    this.transitionTo({
      queryParams: {
        page: page
      }
    });
  }

  function nextPage() {
    const page = this.get('controller.page');
    if (page < this.get('currentModel.total')) this.transitionTo({
      queryParams: {
        page: Number(page) + 1
      }
    });
  }

  function prevPage() {
    const page = this.get('controller.page');
    if (page > 1) this.transitionTo({
      queryParams: {
        page: Number(page) - 1
      }
    });
  }

  function updateSearch(q) {
    this.set('controller.q', q);
    this.set('controller.page', 1);
  }

  function updateFilter(filter) {
    this.set('controller.sort', filter);
  }

  function updateArchivedFilter(filter) {
    this.set('controller.archived', filter);
  }

  function updateCurrencyFilter(filter) {
    this.set('controller.currency', filter);
  }

  function updateTierFilter(filter) {
    this.set('controller.tier', filter);
  }

  function remove(boutique) {
    if (confirm(`THIS OPERATION ARCHIVES THE BOUTIQUE (${boutique.name}) AND ALL THEIR PRODUCTS!!!!`)) {
      boutique.remove().then(() => {
        boutique.set('archived', true);
      });
    }
  }
});