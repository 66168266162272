define("backalley/pods/components/paths-and-lanes/new/review/component", ["exports", "backalley/util/diff", "backalley/enums/carrier-lane-variant", "backalley/enums/shipping-path-variant", "backalley/enums/path-and-lane-release-type", "backalley/util/cast-object-fields-to-string", "lodash.clonedeep"], function (_exports, _diff, _carrierLaneVariant, _shippingPathVariant, _pathAndLaneReleaseType, _castObjectFieldsToString, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  var _default = Ember.Component.extend({
    shippingPathChangesMessage: {
      additions: undefined,
      deletions: undefined,
      modifications: undefined,
      warnings: undefined
    },
    carrierLaneChangesMessage: {
      additions: undefined,
      deletions: undefined,
      modifications: undefined,
      warnings: undefined
    },
    warningAcknowledged: {
      shippingPath: false,
      carrierLane: false
    },

    init() {
      this._super();

      this.computeChanges();
    },

    canProceed: Ember.computed('shippingPaths', 'carrierLanes', 'shippingPathsReleaseDescription', 'carrierLanesReleaseDescription', 'shippingPathChangesMessage', 'carrierLaneChangesMessage', 'warningAcknowledged.{shippingPath,carrierLane}', function () {
      const isShippingPathReviewed = !this.shippingPaths || this.shippingPaths && this.shippingPathsReleaseDescription;
      const isCarrierLaneReviewed = !this.carrierLanes || this.carrierLanes && this.carrierLanesReleaseDescription;
      const isWarningAcknowledged = (!this.shippingPathChangesMessage.warnings || this.warningAcknowledged.shippingPath) && (!this.carrierLaneChangesMessage.warnings || this.warningAcknowledged.carrierLane);
      return isShippingPathReviewed && isCarrierLaneReviewed && isWarningAcknowledged;
    }),

    createUnifiedReleaseFromVariants(variants, releaseType) {
      const subVariantKeys = releaseType === _pathAndLaneReleaseType.default.CARRIER_LANE ? Object.values(_carrierLaneVariant.default) : Object.values(_shippingPathVariant.default);
      const subVariantNestedKeys = releaseType === _pathAndLaneReleaseType.default.CARRIER_LANE ? ['service_id', 'max_allowed_pence', 'provider', 'carrier', 'carrier_account'] : ['cost_cap', 'enabled', 'has_cost_cap', 'transit_time_max_days', 'transit_time_min_days', 'unit_cost'];
      const standardVariant = variants.find(variant => variant.variant === 'standard');

      if (!standardVariant) {
        console.error(`Unable to find "standard" variant in ${releaseType} variants: ${JSON.stringify(variants)}`);
        throw new Error(`Unexpected error: ${releaseType} must contain "standard" variant`);
      }

      const subVariants = variants.filter(variant => subVariantKeys.includes(variant.variant));
      const unifiedPathOrLane = (0, _lodash.default)(standardVariant);
      delete unifiedPathOrLane.variant;
      delete unifiedPathOrLane.all;
      subVariants.forEach(subVariant => {
        const {
          variant: variantName
        } = subVariant;
        unifiedPathOrLane[variantName] = {};
        subVariantNestedKeys.forEach(key => {
          if (subVariant[key] === undefined) {
            return;
          }

          if (key === 'provider') {
            unifiedPathOrLane[variantName][key] = subVariant[key].toLowerCase();
            return;
          }

          unifiedPathOrLane[variantName][key] = subVariant[key];
        });

        if (!unifiedPathOrLane[variantName].has_cost_cap) {
          delete unifiedPathOrLane[variantName].cost_cap;
        }

        delete unifiedPathOrLane[variantName].variant;
      });
      return unifiedPathOrLane;
    },

    /**
     * Returns an object containing Shipping Paths with the same field structure and values
     * casted as strings, so that they can be more easily compared against each other.
     *
     * @param {Object[]} shippingPaths - each element contains information of a Shipping Path
     * with all its enabled variants included in it with their own nested field. It has the
     * correct structure for Promenade's POST Shipping Path request.
     * @param {Object[]} activeShippingPaths - each element contains information of a
     * Shipping Path variant, in the same structure as returned by Promenade's GET Shipping
     * Path request.
     * @returns {Object} preprocessData
     * @returns {Object[]} preprocessData.shippingPaths
     * @returns {Object[]} preprocessData.activeShippingPaths
     */
    preprocessShippingPathsForComparison(shippingPaths, activeShippingPaths) {
      const preprocessedShippingPaths = shippingPaths.map(sp => {
        const preprocessedActiveShippingPath = (0, _castObjectFieldsToString.default)((0, _lodash.default)(sp));
        delete preprocessedActiveShippingPath.has_express_shipping;
        delete preprocessedActiveShippingPath.express_shipping_transit_min_days_taken;
        delete preprocessedActiveShippingPath.express_shipping_transit_max_days_taken;
        delete preprocessedActiveShippingPath.express_shipping_unit_cost;
        return preprocessedActiveShippingPath;
      });
      const activeShippingPathsGroupedByPath = activeShippingPaths.reduce((spGroupedByPath, sp) => {
        if (!spGroupedByPath[sp.path_codes]) {
          spGroupedByPath[sp.path_codes] = [];
        }

        spGroupedByPath[sp.path_codes].push((0, _lodash.default)(sp));
        return spGroupedByPath;
      }, {});
      const unifiedShippingPaths = Object.keys(activeShippingPathsGroupedByPath).map(path => {
        const unifiedShippingPath = this.createUnifiedReleaseFromVariants(activeShippingPathsGroupedByPath[path], _pathAndLaneReleaseType.default.SHIPPING_PATH);

        const {
          /* eslint-disable no-unused-vars */
          _id,
          enabled,
          grouping_key,
          release_datetime,
          created_at,
          updated_at,
          objectID,
          path_code_variant,
          links,
          owner,
          path_ids,
          zone_path_codes,
          eu_vat_status_reactions
        } = unifiedShippingPath,
              fieldsToKeep = _objectWithoutProperties(unifiedShippingPath, ["_id", "enabled", "grouping_key", "release_datetime", "created_at", "updated_at", "objectID", "path_code_variant", "links", "owner", "path_ids", "zone_path_codes", "eu_vat_status_reactions"]);

        if (!unifiedShippingPath.free_shipping_available) {
          delete fieldsToKeep.free_shipping_threshold_currency;
        }

        if (!unifiedShippingPath.has_cost_cap) {
          delete fieldsToKeep.cost_cap;
        }

        if (!fieldsToKeep.is_standard_disabled) {
          fieldsToKeep.is_standard_disabled = false;
        }

        return fieldsToKeep;
      });
      const preprocessedActiveShippingPaths = (0, _castObjectFieldsToString.default)(unifiedShippingPaths);
      return {
        shippingPaths: preprocessedShippingPaths,
        activeShippingPaths: preprocessedActiveShippingPaths
      };
    },

    /**
     * Returns an object containing Carrier Lanes with the same field structure and values
     * casted as strings, so that they can be more easily compared against each other.
     *
     * @param {Object[]} carrierLanes - each element contains information of a Carrier Lane
     * with all its enabled variants included in it with their own nested field. It has the
     * correct structure for Promenade's POST Carrier Lane request.
     * @param {Object[]} activeCarrierLanes - each element contains information of a
     * Carrier Lane variant, in the same structure as returned by Promenade's GET Carrier
     * Lane request.
     * @returns {Object} preprocessData
     * @returns {Object[]} preprocessData.carrierLanes
     * @returns {Object[]} preprocessData.activeCarrierLanes
     */
    preprocessCarrierLanesForComparison(carrierLanes, activeCarrierLanes) {
      const preprocessedCarrierLanes = carrierLanes.map(cl => {
        const preprocessedCarrierLane = (0, _castObjectFieldsToString.default)((0, _lodash.default)(cl));

        if (preprocessedCarrierLane.provider) {
          preprocessedCarrierLane.provider = preprocessedCarrierLane.provider.toLowerCase();
        }

        const subVariantKeys = Object.values(_carrierLaneVariant.default);
        subVariantKeys.forEach(subVariant => {
          if (preprocessedCarrierLane[subVariant] && preprocessedCarrierLane[subVariant].provider) {
            preprocessedCarrierLane[subVariant].provider = preprocessedCarrierLane[subVariant].provider.toLowerCase();
          }
        });
        return preprocessedCarrierLane;
      });
      const activeCarrierLanesGroupedByLane = activeCarrierLanes.reduce((clGroupedByLane, cl) => {
        if (!clGroupedByLane[cl.path_codes]) {
          clGroupedByLane[cl.path_codes] = [];
        }

        clGroupedByLane[cl.path_codes].push(JSON.parse(JSON.stringify(cl)));
        return clGroupedByLane;
      }, {});
      const unifiedCarrierLanes = Object.keys(activeCarrierLanesGroupedByLane).map(lane => {
        const _this$createUnifiedRe = this.createUnifiedReleaseFromVariants(activeCarrierLanesGroupedByLane[lane], _pathAndLaneReleaseType.default.CARRIER_LANE),
              {
          /* eslint-disable no-unused-vars */
          _id,
          grouping_key,
          release_datetime,
          created_at,
          updated_at,
          whitelist,
          whitelist_base,
          objectID,
          is_domestic,
          path_code_variant,
          owner,
          path_ids,
          zone_path_codes
        } = _this$createUnifiedRe,
              unifiedCarrierLane = _objectWithoutProperties(_this$createUnifiedRe, ["_id", "grouping_key", "release_datetime", "created_at", "updated_at", "whitelist", "whitelist_base", "objectID", "is_domestic", "path_code_variant", "owner", "path_ids", "zone_path_codes"]);

        return unifiedCarrierLane;
      });
      const preprocessedActiveCarrierLanes = (0, _castObjectFieldsToString.default)(unifiedCarrierLanes);
      return {
        carrierLanes: preprocessedCarrierLanes,
        activeCarrierLanes: preprocessedActiveCarrierLanes
      };
    },

    convertDiffToDisplayMessage(diff) {
      const isObject = object => object !== null && typeof object === 'object';

      const diffType = {
        ADDITION: '+',
        DELETION: '-',
        MODIFICATION: '~'
      };
      const [field, type, value] = diff;

      if (type === diffType.DELETION) {
        return `Deleted "${field}"`;
      }

      if (type === diffType.ADDITION) {
        if (isObject(value)) {
          return `Added "${field}":\n${JSON.stringify(value, null, 2)}`;
        }

        return `Added "${field}": ${value}`;
      }

      if (type === diffType.MODIFICATION) {
        if (value['__old'] !== undefined && value['__new'] === undefined) {
          return `Deleted "${field}"`;
        }

        if (value['__old'] === undefined && value['__new'] !== undefined) {
          return `Added "${field}"`;
        }

        return `Changed "${field}" from "${value['__old']}" to "${value['__new']}"`;
      }

      throw new Error('Unexpected diff type');
    },

    orderDisplayMessages(messages) {
      const orderedMessages = [];
      const orderByKeywords = ['Added', 'Deleted', 'Changed'];
      orderByKeywords.forEach(keyword => {
        let foundMatch = false;
        messages.forEach((message, index) => {
          // Move messages between arrays if they contain the keyword
          if (message.includes(keyword)) {
            orderedMessages.push(messages[index]);
            foundMatch = true;
          }
        }); // Add line break between groups of message with the same keyword

        if (foundMatch) {
          orderedMessages.push('\n');
        }
      });
      return orderedMessages;
    },

    /**
     * Converts differences between objects into simple human readable messages.
     * It is able to handle differencesPerPath in the format returned by
     * generateDiffAsKeysAndValues().
     *
     * @param {Object|Object[]]} differencesPerPath
     * @param {String} releaseType - expecting "path" or "lane", which will be included
     * in error messages
     * @param {Boolean} processDiff - set to true if differencesPerPath contains an array of
     * diff objects that need to be processed further, whose structure follows the output of
     * generateDiffAsKeysAndValues().
     */
    convertDifferencesPerPathOrLaneToDisplayMessages(differencesPerPathOrLane, releaseType, processDiff = false) {
      const releaseTypeName = releaseType === _pathAndLaneReleaseType.default.CARRIER_LANE ? 'Lane' : 'Path';
      let displayMessage = '';

      for (const pathOrLane in differencesPerPathOrLane) {
        const pathOrLaneDifferences = differencesPerPathOrLane[pathOrLane];
        const noDifferences = !pathOrLaneDifferences || pathOrLaneDifferences.length === 0;

        if (noDifferences) {
          continue;
        }

        const separator = displayMessage ? '\n\n' : '';
        displayMessage += `${separator}${releaseTypeName} ${pathOrLane}:`;

        if (!processDiff) {
          displayMessage += `\n  ‣ ${JSON.stringify(pathOrLaneDifferences, null, 2)}`;
          continue;
        }

        if (!Array.isArray(pathOrLaneDifferences)) {
          console.error('Expected "differencesPerPathOrLane" to be an array with differences to process');
          displayMessage += `\n  ‣ ${JSON.stringify(pathOrLaneDifferences, null, 2)}`;
          continue;
        }

        const processedDisplayMessages = [];
        pathOrLaneDifferences.forEach(diff => {
          processedDisplayMessages.push(`\n  ‣ ${this.convertDiffToDisplayMessage(diff)}`);
        });
        displayMessage += this.orderDisplayMessages(processedDisplayMessages).join('');
      }

      return displayMessage;
    },

    /**
     * Returns true if at least one path contains a Cross-Border Margin change.
     *
     * @param {Object} differencesPerPath
     * @param {Object} differencesPerPath.additions - in the format returned by
     * generateDiffAsKeysAndValues()
     * @param {Object} differencesPerPath.deletions - in the format returned by
     * generateDiffAsKeysAndValues()
     * @param {Object} differencesPerPath.modifications - in the format returned by
     * generateDiffAsKeysAndValues()
     */
    releaseContainsCbmChanges(differencesPerPath) {
      const {
        additions,
        deletions,
        modifications
      } = differencesPerPath;

      if (Object.keys(additions).length) {
        return true;
      }

      if (Object.keys(deletions).length) {
        return true;
      }

      for (const pathModifications of Object.values(modifications)) {
        const noDifferences = !pathModifications || pathModifications.length === 0;

        if (noDifferences) {
          continue;
        }

        for (const [field] of pathModifications) {
          if (field === 'additional_product_margin') {
            return true;
          }
        }
      }

      return false;
    },

    /**
     * Returns warning messages based on the addition/deletion/modification of paths or lanes.
     *
     * @param {Object} differencesPerPath
     * @param {Object} differencesPerPath.additions - contains path codes as keys and the
     * respective path or lane as value, for every path or lane that was added in the release.
     * @param {Object} differencesPerPath.deletions - contains path codes as keys and the
     * respective path or lane as value, for every path or lane that was deleted in the release.
     * @param {Object[]} differencesPerPath.modifications - elements are in the format returned by
     * generateDiffAsKeysAndValues()
     * @param {Object|Object[]]} differencesPerPath
     * @param {String} releaseType - expecting "path" or "lane", which will be included
     * in error messages
     */
    convertDifferencesPerPathOrLaneToWarningMessages(differencesPerPathOrLane, releaseType) {
      let warningMessage = '';

      if (releaseType === _pathAndLaneReleaseType.default.SHIPPING_PATH && this.releaseContainsCbmChanges(differencesPerPathOrLane)) {
        warningMessage += 'This release contains changes to Cross-Border Margins and therefore' + ' requires manual intervention.\nPlease submit a Product Task Request and' + " make sure it's being handled before publishing this release.";
      }

      return warningMessage;
    },

    computeChanges(releaseType) {
      if (!releaseType) {
        this.computeChanges(_pathAndLaneReleaseType.default.SHIPPING_PATH);
        this.computeChanges(_pathAndLaneReleaseType.default.CARRIER_LANE);
        return;
      }

      const release = {
        pathsOrLanes: [],
        activePathsOrLanes: []
      };

      if (releaseType === _pathAndLaneReleaseType.default.SHIPPING_PATH) {
        if (!this.shippingPaths) {
          return;
        }

        const {
          shippingPaths,
          activeShippingPaths
        } = this.preprocessShippingPathsForComparison(this.shippingPaths, this.activeShippingPaths);
        release.pathsOrLanes = shippingPaths;
        release.activePathsOrLanes = activeShippingPaths;
      }

      if (releaseType === _pathAndLaneReleaseType.default.CARRIER_LANE) {
        if (!this.carrierLanes) {
          return;
        }

        const {
          carrierLanes,
          activeCarrierLanes
        } = this.preprocessCarrierLanesForComparison(this.carrierLanes, this.activeCarrierLanes);
        release.pathsOrLanes = carrierLanes;
        release.activePathsOrLanes = activeCarrierLanes;
      }

      const pathOrLaneDiff = {
        additions: {},
        deletions: {},
        modifications: {}
      };

      const PathsOrLanesMatch = (pol1, pol2) => pol1.path_codes === pol2.path_codes;

      release.pathsOrLanes.forEach(pathOrLane => {
        const matchIndex = release.activePathsOrLanes.findIndex(activePathOrLane => PathsOrLanesMatch(activePathOrLane, pathOrLane));
        const foundMatch = matchIndex >= 0; // An unmatched Path/Lane means it has been added after the active release. Hence it is an addition

        if (!foundMatch) {
          if (pathOrLaneDiff.additions[pathOrLane.path_codes]) {
            throw new Error(`Unexpected error: attempting to mark ${releaseType} "${pathOrLane.path_codes}"` + 'as an addition, but it was already processed');
          }

          pathOrLaneDiff.additions[pathOrLane.path_codes] = pathOrLane;
          return;
        } // A matched Path/Lane may have been subject to changes


        const difference = (0, _diff.generateDiffAsKeysAndValues)(release.activePathsOrLanes[matchIndex], pathOrLane);

        if (difference) {
          if (pathOrLaneDiff.modifications[pathOrLane.path_codes]) {
            throw new Error('Unexpected error: attempting to store modification of ' + `${releaseType} "${pathOrLane.path_codes}", but it was already processed`);
          }

          pathOrLaneDiff.modifications[pathOrLane.path_codes] = difference;
        } // By removing the matched Active Path/Lane, the release.activePathsOrLanes array will be
        // left only with unmatched Active Paths/Lanes


        release.activePathsOrLanes.splice(matchIndex, 1);
      }); // Unmatched Active Paths/Lanes have been deleted after the active release

      release.activePathsOrLanes.forEach(activePathOrLane => {
        if (pathOrLaneDiff.deletions[activePathOrLane.path_codes]) {
          throw new Error(`Unexpected error: attempting to mark ${releaseType} "${activePathOrLane.path_codes}"` + 'as a deletion, but it was already processed');
        }

        pathOrLaneDiff.deletions[activePathOrLane.path_codes] = activePathOrLane;
      }); // Store the result of path/lane json difference comparison as human readable messages

      const changes = {
        additions: this.convertDifferencesPerPathOrLaneToDisplayMessages(pathOrLaneDiff.additions, releaseType),
        deletions: this.convertDifferencesPerPathOrLaneToDisplayMessages(pathOrLaneDiff.deletions, releaseType),
        modifications: this.convertDifferencesPerPathOrLaneToDisplayMessages(pathOrLaneDiff.modifications, releaseType, true),
        warnings: this.convertDifferencesPerPathOrLaneToWarningMessages(pathOrLaneDiff, releaseType)
      };

      if (releaseType === _pathAndLaneReleaseType.default.SHIPPING_PATH) {
        this.set('shippingPathChangesMessage.additions', changes.additions);
        this.set('shippingPathChangesMessage.deletions', changes.deletions);
        this.set('shippingPathChangesMessage.modifications', changes.modifications);
        this.set('shippingPathChangesMessage.warnings', changes.warnings);
      }

      if (releaseType === _pathAndLaneReleaseType.default.CARRIER_LANE) {
        this.set('carrierLaneChangesMessage.additions', changes.additions);
        this.set('carrierLaneChangesMessage.deletions', changes.deletions);
        this.set('carrierLaneChangesMessage.modifications', changes.modifications);
        this.set('carrierLaneChangesMessage.warnings', changes.warnings);
      }
    },

    actions: {
      storeDescription(releaseType, description) {
        this.storeDescription(releaseType, description);
      },

      completeStep() {
        return this.completeStep();
      },

      goBackStep() {
        this.set('warningAcknowledged.shippingPath', false);
        this.set('warningAcknowledged.carrierLane', false);
        return this.goBackStep();
      }

    }
  });

  _exports.default = _default;
});