define("backalley/helpers/array-contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayContains = arrayContains;
  _exports.default = void 0;

  function arrayContains([array, path, value]) {
    return array.some(iteration => Ember.get(iteration, path).includes(value));
  }

  var _default = Ember.Helper.helper(arrayContains);

  _exports.default = _default;
});