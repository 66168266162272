define("backalley/pods/main/shipments/index/route", ["exports", "moment", "backalley/models/shipment", "backalley/util/error-nicely", "backalley/enums/shipment-context", "backalley/config/environment"], function (_exports, _moment, _shipment, _errorNicely, _shipmentContext, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ShipmentsIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        },
        sort: {
          refreshModel: true
        },
        type: {
          refreshModel: true
        },
        status: {
          refreshModel: true
        },
        context: {
          refreshModel: true
        },
        boutique: {
          refreshModel: true
        },
        // enforcing this to be ordernumber to avoid having to map it in
        // multiple places so that everything works.
        ordernumber: {
          refreshModel: true
        }
      });
    }

    async model({
      page,
      limit,
      sort,
      boutique,
      status,
      context,
      type,
      ordernumber
    } = {}) {
      try {
        const queryParams = {
          page,
          limit,
          sort,
          boutique,
          status,
          type,
          ordernumber
        };

        const query = _objectSpread({}, queryParams); // we only need the name, but we have to surface all the data
        // with the way Promenade currently works.


        query.populate = 'boutique';

        if (context === _shipmentContext.default.LATE_BEING_BOOKED) {
          const oneDayAgo = (0, _moment.default)().startOf('day').subtract(1, 'day');
          const startOfCorrectStatusInfo = (0, _moment.default)('2021-09-10').startOf('day');
          query.queryModifiers = {};
          query.queryModifiers['created_at__min'] = startOfCorrectStatusInfo.toISOString();
          query.queryModifiers['created_at__max'] = oneDayAgo.toISOString();
          query.queryModifiers['status__not'] = ['booked', 'failed', 'expired', 'manual'];
          query.sort = '-updated_at';
        }

        if (context === _shipmentContext.default.LATE_BEING_TRACKED) {
          const startOfYesterday = (0, _moment.default)().startOf('day').subtract(1, 'day');
          const startOfTrackingInfo = (0, _moment.default)('2021-09-10').startOf('day');
          query.queryModifiers = {};
          query.queryModifiers['created_at__min'] = startOfTrackingInfo.toISOString();
          query.queryModifiers['estimated_collection_date__max'] = startOfYesterday.toISOString();
          query.queryModifiers['tracking_status.status__not'] = 'collected';
          query.sort = '-updated_at';
        }

        if (context === _shipmentContext.default.HAS_STATUS_NOTE) {
          query.queryModifiers = {};
          query.queryModifiers['status_note__exists'] = true;
          query.queryModifiers['status_note__not'] = '';
          query.sort = '-updated_at';
        }

        if (context === _shipmentContext.default.HAS_REBOOK_ATTEMPTS) {
          const sixMonthsAgo = (0, _moment.default)().startOf('day').subtract(6, 'months');
          query.queryModifiers = {};
          query.queryModifiers['created_at__min'] = sixMonthsAgo.toISOString();
          query.queryModifiers['tracking_status.status__not'] = 'collected';
          query.queryModifiers['rebook_pickup_attempts__min'] = 1;
          query.sort = '-updated_at';
        }

        const {
          meta,
          shipments
        } = await _shipment.default.findWithMeta(query).catch((0, _errorNicely.errorNicely)('Failed to load any Shipments', this.logger));
        return {
          shipments,
          promenadeBaseUrl: _environment.default.apiURL,
          meta: _objectSpread(_objectSpread({}, meta), {}, {
            queryParams
          })
        };
      } catch (exception) {
        this.logger.error(exception);
        return {
          error: exception.message
        };
      }
    }

  }

  _exports.default = ShipmentsIndexRoute;
});