define("backalley/util/color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getColors = getColors;
  _exports.getColorsHSV = getColorsHSV;
  _exports.hexToRgb = hexToRgb;
  _exports.rgbToHsv = rgbToHsv;
  _exports.hexToHsv = hexToHsv;

  function getColors(colors) {
    return [].concat(colors).map(function (c) {
      return {
        value: c[0],
        percent: c[1]
      };
    });
  }

  function getColorsHSV(colors) {
    return [].concat(colors).map(function (c) {
      const hsv = hexToHsv(c[0]);
      return {
        h: hsv.h,
        s: hsv.s,
        v: hsv.v,
        amount: c[1]
      };
    });
  } // Following implementation borrowed from tinycolor library.
  // Parse a base-16 hex value into a base-10 integer


  const parseIntFromHex = val => parseInt(val, 16);

  function hexToRgb(hex) {
    const hex6Regex = /^#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/;
    const match = hex6Regex.exec(hex);
    return {
      r: parseIntFromHex(match[1]),
      g: parseIntFromHex(match[2]),
      b: parseIntFromHex(match[3])
    };
  }

  function rgbToHsv({
    r,
    g,
    b
  }) {
    r /= 255, g /= 255, b /= 255;
    const max = Math.max(r, g, b),
          min = Math.min(r, g, b);
    let h,
        s,
        v = max;
    const d = max - min;
    s = max === 0 ? 0 : d / max;

    if (max === min) {
      h = 0; // achromatic
    } else {
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;

        case g:
          h = (b - r) / d + 2;
          break;

        case b:
          h = (r - g) / d + 4;
          break;
      }

      h /= 6;
    }

    return {
      h: h * 360,
      s,
      v
    };
  }

  function hexToHsv(hex) {
    return rgbToHsv(hexToRgb(hex));
  }
});