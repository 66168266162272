define("backalley/pods/components/form-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jQcN5quQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"button\",false],[12,\"class\",[29,[\"button \",[28,\"if\",[[24,[\"loading\"]],\"loading\",\"\"],null],\" \",[28,\"if\",[[24,[\"customClass\"]],[24,[\"customClass\"]],\"button-primary\"],null],\" \",[28,\"if\",[[24,[\"isDisabled\"]],\"disabled\"],null],\" \",[22,\"buttonClass\"]]]],[12,\"disabled\",[22,\"isDisabled\"]],[12,\"title\",[22,\"title\"]],[3,\"action\",[[23,0,[]],\"go\",[24,[\"action\"]]]],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/form-button/template.hbs"
    }
  });

  _exports.default = _default;
});