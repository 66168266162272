define("backalley/pods/components/form-submit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'footer',
    classNames: ['form-submit'],
    loading: false,
    title: null,
    actions: {
      action(action) {
        this.set('loading', true);
        let result = action(); // if action returns a promise let's show a loading state until it resolves

        if (result && typeof result.then === 'function') result.then(() => this.set('loading', false), () => this.set('loading', false));else this.set('loading', false);
      }

    }
  });

  _exports.default = _default;
});