define("backalley/pods/components/product-amendment-card/simple/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3VDkRGr8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"product-amendment-card--avatar\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"product-amendment-card--content\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"product-amendment-card--name\"],[8],[0,\"Nell\"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"product-amendment-card--timestamp\"],[8],[1,[28,\"moment-format\",[[24,[\"amendment\",\"created_at\"]],\"DD MMM YYYY\"],null],false],[9],[0,\"\\n\\n\"],[0,\"  \"],[7,\"h5\",true],[10,\"class\",\"product-amendment-card--field\"],[8],[0,\"\\n    \"],[1,[28,\"capitalize\",[[24,[\"amendment\",\"attribute\"]]],null],false],[0,\" (\"],[4,\"if\",[[24,[\"isResolved\"]]],null,{\"statements\":[[0,\"Awaiting review\"]],\"parameters\":[]},{\"statements\":[[1,[28,\"capitalize\",[[24,[\"amendment\",\"status\"]]],null],false]],\"parameters\":[]}],[0,\")\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"product-amendment-card--message\"],[8],[1,[24,[\"amendment\",\"note\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/product-amendment-card/simple/template.hbs"
    }
  });

  _exports.default = _default;
});