define("backalley/enums/p2g-services", ["exports", "backalley/enums/types"], function (_exports, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.p2gServiceDescriptionById = _exports.p2gServiceIdsSorted = _exports.p2gServiceByDescription = _exports.p2gServiceIds = _exports.default = void 0;

  const p2gServicesEnum = _types.default.create({
    "3": {
      "carrier": "Yodel",
      "service": "yodel-northern-ireland",
      "type": "Collection",
      "id": "3"
    },
    "89": {
      "carrier": "FedEx Express",
      "service": "fedex-world-express",
      "type": "Collection",
      "id": "89"
    },
    "90": {
      "carrier": "Yodel",
      "service": "yodel-highland-and-islands",
      "type": "Collection",
      "id": "90"
    },
    "113": {
      "carrier": "Parcelforce",
      "service": "parcelforce-express-am",
      "type": "Collection",
      "id": "113"
    },
    "114": {
      "carrier": "Parcelforce",
      "service": "parcelforce-express-24",
      "type": "Collection",
      "id": "114"
    },
    "115": {
      "carrier": "Parcelforce",
      "service": "parcelforce-express-48",
      "type": "Collection",
      "id": "115"
    },
    "118": {
      "carrier": "PalletForce",
      "service": "palletforce-delivery-48-hours",
      "type": "Collection",
      "id": "118"
    },
    "124": {
      "carrier": "FedEx Express",
      "service": "fedex-world-economy",
      "type": "Collection",
      "id": "124"
    },
    "125": {
      "carrier": "Parcel2Go Service",
      "service": "parcel-safe",
      "type": "Collection",
      "id": "125"
    },
    "141": {
      "carrier": "Nightline",
      "service": "ireland-to-ireland-delivery",
      "type": "Collection",
      "id": "141"
    },
    "142": {
      "carrier": "Nightline",
      "service": "ireland-to-uk-delivery",
      "type": "Collection",
      "id": "142"
    },
    "146": {
      "carrier": "Nightline",
      "service": "ireland-to-northern-ireland-delivery",
      "type": "Collection",
      "id": "146"
    },
    "147": {
      "carrier": "Nightline",
      "service": "northern-ireland-to-ireland-delivery",
      "type": "Collection",
      "id": "147"
    },
    "148": {
      "carrier": "Nightline",
      "service": "northern-ireland-to-northern-ireland-delivery",
      "type": "Collection",
      "id": "148"
    },
    "153": {
      "carrier": "Nightline",
      "service": "northern-ireland-to-uk-mainland",
      "type": "Collection",
      "id": "153"
    },
    "159": {
      "carrier": "UPS",
      "service": "parcel2go-uk-express",
      "type": "Collection",
      "id": "159"
    },
    "162": {
      "carrier": "Parcelforce",
      "service": "parcelforce-multi-12-noon",
      "type": "Collection",
      "id": "162"
    },
    "164": {
      "carrier": "FedEx Express",
      "service": "fedex-world-express",
      "type": "Collection",
      "id": "164"
    },
    "166": {
      "carrier": "Parcelforce",
      "service": "parcelforce-large-48",
      "type": "Collection",
      "id": "166"
    },
    "167": {
      "carrier": "TNT",
      "service": "tnt-express",
      "type": "Collection",
      "id": "167"
    },
    "168": {
      "carrier": "TNT",
      "service": "tnt-economy-express",
      "type": "Collection",
      "id": "168"
    },
    "169": {
      "carrier": "PalletForce",
      "service": "palletforce-delivery-next-day",
      "type": "Collection",
      "id": "169"
    },
    "173": {
      "carrier": "Nightline",
      "service": "nightline-express",
      "type": "Collection",
      "id": "173"
    },
    "174": {
      "carrier": "Nightline",
      "service": "nightline-express-saver",
      "type": "Collection",
      "id": "174"
    },
    "201": {
      "carrier": "Parcelforce",
      "service": "parcelforce-euro-priority",
      "type": "Collection",
      "id": "201"
    },
    "205": {
      "carrier": "TNT",
      "service": "tnt-uk-saturday-express",
      "type": "Collection",
      "id": "205"
    },
    "206": {
      "carrier": "TNT",
      "service": "tnt-uk-12-00-express",
      "type": "Collection",
      "id": "206"
    },
    "207": {
      "carrier": "TNT",
      "service": "tnt-uk-express-service",
      "type": "Collection",
      "id": "207"
    },
    "208": {
      "carrier": "Hermes",
      "service": "hermes-uk-economy",
      "type": "Collection",
      "id": "208"
    },
    "211": {
      "carrier": "TNT",
      "service": "tnt-uk-10-00-express",
      "type": "Collection",
      "id": "211"
    },
    "212": {
      "carrier": "TNT",
      "service": "tnt-uk-09-00-express",
      "type": "Collection",
      "id": "212"
    },
    "213": {
      "carrier": "Yodel",
      "service": "yodel-24",
      "type": "Collection",
      "id": "213"
    },
    "214": {
      "carrier": "UPS",
      "service": "parcel2go-express-saver",
      "type": "Collection",
      "id": "214"
    },
    "216": {
      "carrier": "Collect+",
      "service": "collect-plus-drop-off-service",
      "type": "Shop",
      "id": "216"
    },
    "220": {
      "carrier": "UPS",
      "service": "parcel2go-uk-standard",
      "type": "Collection",
      "id": "220"
    },
    "222": {
      "carrier": "GLS",
      "service": "gls-ireland",
      "type": "Collection",
      "id": "222"
    },
    "225": {
      "carrier": "UPS",
      "service": "parcel2go-standard",
      "type": "Collection",
      "id": "225"
    },
    "226": {
      "carrier": "Yodel",
      "service": "yodel-48",
      "type": "Collection",
      "id": "226"
    },
    "230": {
      "carrier": "Fastway",
      "service": "fastway",
      "type": "Collection",
      "id": "230"
    },
    "231": {
      "carrier": "GLS",
      "service": "gls-europe",
      "type": "Collection",
      "id": "231"
    },
    "232": {
      "carrier": "UPS",
      "service": "parcel2go-express",
      "type": "Collection",
      "id": "232"
    },
    "233": {
      "carrier": "Fastway",
      "service": "fastway-northern-ireland",
      "type": "Collection",
      "id": "233"
    },
    "234": {
      "carrier": "GLS",
      "service": "gls-uk",
      "type": "Collection",
      "id": "234"
    },
    "241": {
      "carrier": "GLS",
      "service": "gls",
      "type": "Collection",
      "id": "241"
    },
    "244": {
      "carrier": "GLS",
      "service": "gls-europa",
      "type": "Collection",
      "id": "244"
    },
    "250": {
      "carrier": "MyHermes",
      "service": "myhermes-parcelshop",
      "type": "Shop",
      "id": "250"
    },
    "251": {
      "carrier": "CitySprint",
      "service": "small-van-delivery",
      "type": "Collection",
      "id": "251"
    },
    "252": {
      "carrier": "CitySprint",
      "service": "large-van-delivery",
      "type": "Collection",
      "id": "252"
    },
    "253": {
      "carrier": "CitySprint",
      "service": "extra-large-van-delivery",
      "type": "Collection",
      "id": "253"
    },
    "254": {
      "carrier": "Menzies",
      "service": "ajg-highlands-and-islands",
      "type": "Collection",
      "id": "254"
    },
    "255": {
      "carrier": "TNT",
      "service": "tnt-uk-express-(ni)",
      "type": "Collection",
      "id": "255"
    },
    "257": {
      "carrier": "Parcelforce",
      "service": "parcelforce-global-priority",
      "type": "Collection",
      "id": "257"
    },
    "258": {
      "carrier": "Correos",
      "service": "correos-postal-48-72",
      "type": "Collection",
      "id": "258"
    },
    "259": {
      "carrier": "ASM",
      "service": "asm-24h",
      "type": "Collection",
      "id": "259"
    },
    "260": {
      "carrier": "TNT",
      "service": "tnt-express-(ni)",
      "type": "Collection",
      "id": "260"
    },
    "261": {
      "carrier": "TNT",
      "service": "tnt-economy-express-(ni)",
      "type": "Collection",
      "id": "261"
    },
    "262": {
      "carrier": "TNT",
      "service": "tnt-express-ireland",
      "type": "Collection",
      "id": "262"
    },
    "263": {
      "carrier": "TNT",
      "service": "tnt-express",
      "type": "Collection",
      "id": "263"
    },
    "264": {
      "carrier": "TNT",
      "service": "tnt-economy-express",
      "type": "Collection",
      "id": "264"
    },
    "266": {
      "carrier": "Collect+",
      "service": "collect-plus-drop-off-service-(ni)",
      "type": "Shop",
      "id": "266"
    },
    "276": {
      "carrier": "DPD",
      "service": "dpd-classic-european",
      "type": "Collection",
      "id": "276"
    },
    "277": {
      "carrier": "UK-EUR collectionUPS Access Point",
      "service": "ups-access-point",
      "type": "Shop",
      "id": "277"
    },
    "278": {
      "carrier": "UPS Access Point",
      "service": "ups-access-point-euro",
      "type": "Shop",
      "id": "278"
    },
    "294": {
      "carrier": "Norsk",
      "service": "p2g-international-saver",
      "type": "Collection",
      "id": "294"
    },
    "297": {
      "carrier": "UPS Access Point",
      "service": "ups-shop-to-shop",
      "type": "Shop",
      "id": "297"
    },
    "298": {
      "carrier": "Norsk",
      "service": "p2g-international-saver-dropshop",
      "type": "Shop",
      "id": "298"
    },
    "320": {
      "carrier": "InPost",
      "service": "inpost",
      "type": "Locker",
      "id": "320"
    },
    "322": {
      "carrier": "UPS",
      "service": "parcel2go-express-saver-import",
      "type": "Collection",
      "id": "322"
    },
    "323": {
      "carrier": "UPS",
      "service": "parcel2go-express-import",
      "type": "Collection",
      "id": "323"
    },
    "324": {
      "carrier": "UPS",
      "service": "parcel2go-standard-import",
      "type": "Collection",
      "id": "324"
    },
    "334": {
      "carrier": "Correos",
      "service": "correos-shop-to-shop",
      "type": "Shop",
      "id": "334"
    },
    "335": {
      "carrier": "ASM",
      "service": "asm-economy",
      "type": "Collection",
      "id": "335"
    },
    "339": {
      "carrier": "UPS",
      "service": "parcel2go-ie-express-saver-international",
      "type": "Collection",
      "id": "339"
    },
    "340": {
      "carrier": "UPS",
      "service": "parcel2go-ie-express-international",
      "type": "Collection",
      "id": "340"
    },
    "341": {
      "carrier": "AnPost",
      "service": "anpost",
      "type": "Shop",
      "id": "341"
    },
    "343": {
      "carrier": "Nightline",
      "service": "nightline-standard",
      "type": "Collection",
      "id": "343"
    },
    "344": {
      "carrier": "Mondial",
      "service": "punto-pack",
      "type": "Shop",
      "id": "344"
    },
    "345": {
      "carrier": "Impact Express",
      "service": "impact-express",
      "type": "Collection",
      "id": "345"
    },
    "347": {
      "carrier": "Impact Express",
      "service": "impact-express-drop-off",
      "type": "Shop",
      "id": "347"
    },
    "348": {
      "carrier": "Correos",
      "service": "correos-drop-shop",
      "type": "Shop",
      "id": "348"
    },
    "350": {
      "carrier": "Hermes",
      "service": "hermes-international-economy",
      "type": "Collection",
      "id": "350"
    },
    "351": {
      "carrier": "MyHermes",
      "service": "myhermes-international-parcelshop",
      "type": "Shop",
      "id": "351"
    },
    "353": {
      "carrier": "Mondial",
      "service": "punto-pack-int",
      "type": "Shop",
      "id": "353"
    },
    "354": {
      "carrier": "Asendia",
      "service": "asendia",
      "type": "Collection",
      "id": "354"
    },
    "355": {
      "carrier": "Asendia",
      "service": "asendia-drop-shop",
      "type": "Shop",
      "id": "355"
    },
    "357": {
      "carrier": "DPD",
      "service": "dpd-ireland",
      "type": "Collection",
      "id": "357"
    },
    "358": {
      "carrier": "Nightline",
      "service": "nightline-uk-to-ie",
      "type": "Collection",
      "id": "358"
    },
    "359": {
      "carrier": "Parcelforce",
      "service": "parcelforce-express-48-drop-off",
      "type": "Shop",
      "id": "359"
    },
    "360": {
      "carrier": "Fastway",
      "service": "fastway-uk",
      "type": "Collection",
      "id": "360"
    },
    "361": {
      "carrier": "DPD",
      "service": "dpd-air-classic",
      "type": "Collection",
      "id": "361"
    },
    "362": {
      "carrier": "Parcelforce",
      "service": "parcelforce-express-24-drop-off",
      "type": "Shop",
      "id": "362"
    },
    "363": {
      "carrier": "DX",
      "service": "dx-24",
      "type": "Collection",
      "id": "363"
    },
    "364": {
      "carrier": "DX",
      "service": "dx-48",
      "type": "Collection",
      "id": "364"
    },
    "365": {
      "carrier": "Parcelforce",
      "service": "parcelforce-euro-priority-drop-shop",
      "type": "Shop",
      "id": "365"
    },
    "366": {
      "carrier": "Parcelforce",
      "service": "parcelforce-global-priority-drop-shop",
      "type": "Shop",
      "id": "366"
    },
    "368": {
      "carrier": "InPost",
      "service": "inpost-24",
      "type": "Locker",
      "id": "368"
    },
    "369": {
      "carrier": "Norsk",
      "service": "p2g-international-saver-multi",
      "type": "Collection",
      "id": "369"
    },
    "370": {
      "carrier": "Norsk",
      "service": "p2g-international-saver-multi-drop",
      "type": "Shop",
      "id": "370"
    },
    "371": {
      "carrier": "Fastway",
      "service": "fastway-ni",
      "type": "Collection",
      "id": "371"
    },
    "372": {
      "carrier": "UPS",
      "service": "parcel2go-ie-standard-international",
      "type": "Collection",
      "id": "372"
    },
    "373": {
      "carrier": "DPD",
      "service": "dpd",
      "type": "Shop",
      "id": "373"
    },
    "374": {
      "carrier": "DPD",
      "service": "dpd-classic",
      "type": "Shop",
      "id": "374"
    },
    "375": {
      "carrier": "DPD",
      "service": "dpd-pickup-ireland",
      "type": "Shop",
      "id": "375"
    },
    "387": {
      "carrier": "DPD",
      "service": "dpd-air-express",
      "type": "Collection",
      "id": "387"
    },
    "388": {
      "carrier": "DPD",
      "service": "dpd-pickup-air-express",
      "type": "Shop",
      "id": "388"
    },
    "390": {
      "carrier": "Yodel",
      "service": "yodel-xpress-24",
      "type": "Collection",
      "id": "390"
    },
    "391": {
      "carrier": "Yodel",
      "service": "yodel-xpress-48",
      "type": "Collection",
      "id": "391"
    },
    "393": {
      "carrier": "UPS",
      "service": "parcel2go-express-uk",
      "type": "Collection",
      "id": "393"
    },
    "394": {
      "carrier": "UPS",
      "service": "parcel2go-uk-express-saver",
      "type": "Collection",
      "id": "394"
    },
    "395": {
      "carrier": "Parcelforce",
      "service": "parcelforce-by-10am",
      "type": "Collection",
      "id": "395"
    },
    "396": {
      "carrier": "Parcelforce",
      "service": "parcelforce-by-9am",
      "type": "Collection",
      "id": "396"
    },
    "397": {
      "carrier": "DPD",
      "service": "dpd-pickup-air-classic",
      "type": "Shop",
      "id": "397"
    },
    "398": {
      "carrier": "Parcelforce",
      "service": "parcelforce-channel-islands",
      "type": "Collection",
      "id": "398"
    },
    "399": {
      "carrier": "OCS",
      "service": "usps-via-ocs",
      "type": "Collection",
      "id": "399"
    },
    "400": {
      "carrier": "OCS",
      "service": "upsc-via-ocs-drop-shop",
      "type": "Shop",
      "id": "400"
    },
    "402": {
      "carrier": "OCS",
      "service": "ocs-australia-post",
      "type": "Collection",
      "id": "402"
    },
    "403": {
      "carrier": "OCS",
      "service": "ocs-australia-post-drop-off",
      "type": "Shop",
      "id": "403"
    },
    "404": {
      "carrier": "Hermes",
      "service": "hermes-uk-economy-small",
      "type": "Collection",
      "id": "404"
    },
    "405": {
      "carrier": "Hermes",
      "service": "hermes-uk-economy-medium",
      "type": "Collection",
      "id": "405"
    },
    "406": {
      "carrier": "MyHermes",
      "service": "myhermes-parcelshop-small",
      "type": "Shop",
      "id": "406"
    },
    "407": {
      "carrier": "MyHermes",
      "service": "myhermes-parcelshop-medium",
      "type": "Shop",
      "id": "407"
    },
    "413": {
      "carrier": "OCS",
      "service": "ocs",
      "type": "Collection",
      "id": "413"
    },
    "414": {
      "carrier": "OCS",
      "service": "ocs-drop-off",
      "type": "Shop",
      "id": "414"
    },
    "415": {
      "carrier": "OCS",
      "service": "ocs-new-zealand-post",
      "type": "Collection",
      "id": "415"
    },
    "416": {
      "carrier": "OCS",
      "service": "ocs-new-zealand-post-drop-off",
      "type": "Shop",
      "id": "416"
    },
    "417": {
      "carrier": "UPS",
      "service": "ups-express-saver-es",
      "type": "Collection",
      "id": "417"
    },
    "418": {
      "carrier": "UPS",
      "service": "ups-standard-es",
      "type": "Collection",
      "id": "418"
    },
    "419": {
      "carrier": "Collect+",
      "service": "collect-plus-drop-off-service-24hr",
      "type": "Shop",
      "id": "419"
    },
    "422": {
      "carrier": "UPS Access Point",
      "service": "ups-access-point-express",
      "type": "Shop",
      "id": "422"
    },
    "423": {
      "carrier": "TNT",
      "service": "tnt-euro-express-import",
      "type": "Collection",
      "id": "423"
    },
    "424": {
      "carrier": "TNT",
      "service": "tnt-euro-economy-express-import",
      "type": "Collection",
      "id": "424"
    },
    "426": {
      "carrier": "Landmark",
      "service": "landmark-canadapost-collection",
      "type": "Collection",
      "id": "426"
    },
    "427": {
      "carrier": "Landmark",
      "service": "landmark-canadapost-dropoff",
      "type": "Shop",
      "id": "427"
    },
    "428": {
      "carrier": "Landmark",
      "service": "landmark-collection",
      "type": "Collection",
      "id": "428"
    },
    "429": {
      "carrier": "Landmark",
      "service": "landmark-dropoff",
      "type": "Shop",
      "id": "429"
    },
    "430": {
      "carrier": "DPD",
      "service": "dpd-express-classic-v2",
      "type": "Collection",
      "id": "430"
    },
    "431": {
      "carrier": "DPD",
      "service": "dpd-pickup-import",
      "type": "Shop",
      "id": "431"
    },
    "433": {
      "carrier": "UPS",
      "service": "ups-standard-es-dom",
      "type": "Collection",
      "id": "433"
    },
    "434": {
      "carrier": "UPS Access Point",
      "service": "ups-access-point-standard-es-dom",
      "type": "Shop",
      "id": "434"
    },
    "435": {
      "carrier": "Corroes Express",
      "service": "correos-express-24hr-paq",
      "type": "Collection",
      "id": "435"
    },
    "437": {
      "carrier": "TNT",
      "service": "tnt-uk-standard-service",
      "type": "Collection",
      "id": "437"
    },
    "438": {
      "carrier": "Landmark",
      "service": "landmark-global-world-heavy",
      "type": "Collection",
      "id": "438"
    },
    "440": {
      "carrier": "Parcelforce",
      "service": "parcelforce-large-24",
      "type": "Collection",
      "id": "440"
    },
    "441": {
      "carrier": "ASM",
      "service": "asm-euro-business-parcel",
      "type": "Collection",
      "id": "441"
    },
    "443": {
      "carrier": "Landmark",
      "service": "landmark-canadapost-heavy-collection",
      "type": "Collection",
      "id": "443"
    },
    "445": {
      "carrier": "OCS",
      "service": "ocs-austria-post-coll",
      "type": "Collection",
      "id": "445"
    },
    "446": {
      "carrier": "OCS",
      "service": "ocs-austria-post-drop",
      "type": "Shop",
      "id": "446"
    },
    "448": {
      "carrier": "TNT",
      "service": "tnt-12-00-euro-express",
      "type": "Collection",
      "id": "448"
    },
    "449": {
      "carrier": "TNT",
      "service": "tnt-09-00-euro-express",
      "type": "Collection",
      "id": "449"
    },
    "450": {
      "carrier": "TNT",
      "service": "tnt-10-00-euro-express",
      "type": "Collection",
      "id": "450"
    },
    "451": {
      "carrier": "DPD",
      "service": "dpd-direct-pick",
      "type": "Shop",
      "id": "451"
    },
    "452": {
      "carrier": "DPD",
      "service": "dpd-int-ire",
      "type": "Shop",
      "id": "452"
    },
    "453": {
      "carrier": "DPD",
      "service": "dpd-int-dom",
      "type": "Shop",
      "id": "453"
    },
    "552": {
      "carrier": "DPD",
      "service": "dpd-int-ukire",
      "type": "Shop",
      "id": "552"
    },
    "553": {
      "carrier": "DPD",
      "service": "dpd-ukire-dom",
      "type": "Shop",
      "id": "553"
    },
    "554": {
      "carrier": "Palletways",
      "service": "palletways",
      "type": "Collection",
      "id": "554"
    },
    "555": {
      "carrier": "Aramex",
      "service": "upsc-via-aramex-drop-shop",
      "type": "Shop",
      "id": "555"
    },
    "556": {
      "carrier": "One World Express",
      "service": "one-world-express",
      "type": "Shop",
      "id": "556"
    },
    "557": {
      "carrier": "Yodel",
      "service": "yodel-by-12",
      "type": "Collection",
      "id": "557"
    },
    "559": {
      "carrier": "Hermes",
      "service": "hermes-light-and-large",
      "type": "Collection",
      "id": "559"
    },
    "560": {
      "carrier": "UPS",
      "service": "parcel2go-multi-parcel-express-saver_560",
      "type": "Collection",
      "id": "560"
    },
    "561": {
      "carrier": "OCS",
      "service": "usps-economy",
      "type": "Collection",
      "id": "561"
    },
    "562": {
      "carrier": "DPD",
      "service": "dpd-direct-pick-ire",
      "type": "Shop",
      "id": "562"
    },
    "580": {
      "carrier": "B2C Europe",
      "service": "b2c-int",
      "type": "Shop",
      "id": "580"
    },
    "581": {
      "carrier": "B2C Europe",
      "service": "b2c-int-col",
      "type": "Collection",
      "id": "581"
    },
    "600": {
      "carrier": "My Flying Box",
      "service": "mfb-bpost-24",
      "type": "Collection",
      "id": "600"
    },
    "601": {
      "carrier": "My Flying Box",
      "service": "mfb-bpost-world-business",
      "type": "Collection",
      "id": "601"
    },
    "602": {
      "carrier": "My Flying Box",
      "service": "mfb-dhl-economy-select",
      "type": "Collection",
      "id": "602"
    },
    "603": {
      "carrier": "My Flying Box",
      "service": "mfb-dhl-express-09",
      "type": "Collection",
      "id": "603"
    },
    "604": {
      "carrier": "My Flying Box",
      "service": "mfb-dhl-express-12",
      "type": "Collection",
      "id": "604"
    },
    "605": {
      "carrier": "My Flying Box",
      "service": "mfb-dhl-express-18",
      "type": "Collection",
      "id": "605"
    },
    "606": {
      "carrier": "My Flying Box",
      "service": "mfb-dhl-express-ww",
      "type": "Collection",
      "id": "606"
    },
    "607": {
      "carrier": "My Flying Box",
      "service": "mfb-ups-express",
      "type": "Collection",
      "id": "607"
    },
    "608": {
      "carrier": "My Flying Box",
      "service": "mfb-ups-express-plus",
      "type": "Collection",
      "id": "608"
    },
    "609": {
      "carrier": "My Flying Box",
      "service": "mfb-ups-express-saver",
      "type": "Collection",
      "id": "609"
    },
    "610": {
      "carrier": "My Flying Box",
      "service": "mfb-ups-standard",
      "type": "Collection",
      "id": "610"
    },
    "611": {
      "carrier": "DPD",
      "service": "dpd-classic-europe",
      "type": "Collection",
      "id": "611"
    },
    "612": {
      "carrier": "DPD",
      "service": "dpd-domestic-europe",
      "type": "Collection",
      "id": "612"
    },
    "613": {
      "carrier": "DHL",
      "service": "dhl-uk",
      "type": "Collection",
      "id": "613"
    },
    "620": {
      "carrier": "AnPost",
      "service": "anpost-international",
      "type": "Shop",
      "id": "620"
    },
    "622": {
      "carrier": "DPD",
      "service": "dpd-pickup-classic",
      "type": "Shop",
      "id": "622"
    }
  });

  var _default = p2gServicesEnum;
  _exports.default = _default;

  const alphabeticalOrder = (a, b) => {
    if (a === b) {
      return 0;
    }

    return a > b ? 1 : -1;
  };

  const p2gServiceIds = Object.keys(p2gServicesEnum);
  _exports.p2gServiceIds = p2gServiceIds;
  const p2gServiceByDescription = p2gServiceIds.reduce((snowball, serviceId) => {
    const service = p2gServicesEnum[serviceId];
    service.description = `${service.carrier} - ${service.service}`;
    snowball[service.description] = service;
    return snowball;
  }, {});
  _exports.p2gServiceByDescription = p2gServiceByDescription;
  const p2gServiceIdsSorted = Object.keys(p2gServiceByDescription).sort(alphabeticalOrder).map(serviceDesc => p2gServiceByDescription[serviceDesc].id);
  _exports.p2gServiceIdsSorted = p2gServiceIdsSorted;
  const p2gServiceDescriptionById = p2gServiceIds.reduce((snowball, serviceId) => {
    snowball[serviceId] = p2gServicesEnum[serviceId].description;
    return snowball;
  }, {});
  _exports.p2gServiceDescriptionById = p2gServiceDescriptionById;
});