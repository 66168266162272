define("backalley/pods/components/banner/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BannerComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "styleNamespace", _podNames.default.banner);
    }

    get theme() {
      var _this$args$theme;

      return (_this$args$theme = this.args.theme) !== null && _this$args$theme !== void 0 ? _this$args$theme : 'info';
    }

    get type() {
      var _this$args$type;

      return (_this$args$type = this.args.type) !== null && _this$args$type !== void 0 ? _this$args$type : 'regular';
    }

  }

  _exports.default = BannerComponent;
});