define("backalley/helpers/item-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.itemClass = itemClass;
  _exports.default = void 0;

  function itemClass([type, index]) {
    index++;
    return `list-row-entry list-row-entry-${type} list-row-entry-${index}`;
  }

  var _default = Ember.Helper.helper(itemClass);

  _exports.default = _default;
});