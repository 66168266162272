define("backalley/pods/components/order-edit/discount/component", ["exports", "backalley/pods/main/orders/options"], function (_exports, _options) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function NOOP() {}

  var _default = Ember.Component.extend({
    // Public API
    order: null,
    onSave: NOOP,
    onUpdateTransactionValue: NOOP,
    // Private API
    classNames: ['box-view', 'box-view-discount'],
    _expandSection: false,
    availableDiscountTypes: _options.availableDiscountTypes,
    hasDiscountCode: Ember.computed('order.{discount_code,discount_type}', function () {
      const {
        discount_code,
        discount_type
      } = this.order;
      return !['none', 'None'].includes(discount_code) || discount_type !== 'none';
    }),
    actions: {
      setDiscountType(type) {
        this.order.set('discount_type', type);
        this.onSave();
      }

    }
  });

  _exports.default = _default;
});