define("backalley/pods/main/sizes/index/route", ["exports", "backalley/models/size", "backalley/models/product", "backalley/mixins/loading", "backalley/util/escape-regex"], function (_exports, _size, _product, _loading, _escapeRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_loading.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },

    serializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      // don't show objects in url
      if (urlKey === 'sort') return value.value;
      if (urlKey === 'page') return Number(value);
      return value;
    },

    deserializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      if (urlKey === 'sort') return this.availableFilters.findBy('value', value);
      return value;
    },

    availableFilters: [{
      label: 'Date: last updated',
      value: '-updated_at'
    }, {
      label: 'Date: first updated',
      value: 'updated_at'
    }, {
      label: 'Date: last created',
      value: '-created_at'
    }, {
      label: 'Date: first created',
      value: 'created_at'
    }, {
      label: 'Name: A-Z',
      value: 'label'
    }, {
      label: 'Name: Z-A',
      value: '-label'
    }],
    productCache: new Map(),

    model({
      q,
      page = 1,
      limit = 30,
      sort = {}
    }) {
      return _size.default.findWithMeta({
        page,
        limit,
        $or: [{
          label: {
            $regex: `^${(0, _escapeRegex.default)(q)}`,
            $options: 'i'
          }
        }, {
          filter_label: {
            $regex: (0, _escapeRegex.default)(q),
            $options: 'i'
          }
        }],
        sort: sort.value
      }).then(getProductCount.bind(this)).then(({
        sizes,
        meta = {
          total: 3
        }
      }) => {
        return {
          sizes,
          total: Math.ceil(meta.total / limit),
          search: q,
          sort: sort,
          availableFilters: this.availableFilters,
          actions: {
            goToPage: goToPage.bind(this),
            nextPage: nextPage.bind(this),
            prevPage: prevPage.bind(this),
            updateSearch: updateSearch.bind(this),
            updateFilter: updateFilter.bind(this),
            remove: remove.bind(this)
          }
        };
      });
    }

  });

  _exports.default = _default;

  function getProductCount({
    sizes,
    meta
  }) {
    return Ember.RSVP.map(sizes, size => {
      // check in cache
      if (this.productCache.has(size)) return this.productCache.get(size._id);
      return _product.default.findWithMeta({
        'sizes.size': size._id,
        limit: 1,
        fields: '_id'
      }).then(({
        meta = {
          total: 0
        }
      }) => {
        size.products = meta.total;
        this.productCache.set(size, size);
        return size;
      });
    }).then(sizesWithProducts => {
      return {
        sizes: sizesWithProducts,
        meta
      };
    });
  }

  function goToPage(page) {
    this.transitionTo({
      queryParams: {
        page: page
      }
    });
  }

  function nextPage() {
    const page = this.get('controller.page');
    if (page < this.get('currentModel.total')) this.transitionTo({
      queryParams: {
        page: Number(page) + 1
      }
    });
  }

  function prevPage() {
    const page = this.get('controller.page');
    if (page > 1) this.transitionTo({
      queryParams: {
        page: Number(page) - 1
      }
    });
  }

  function updateSearch(q) {
    this.set('controller.q', q);
    this.set('controller.page', 1);
  }

  function updateFilter(filter) {
    this.set('controller.sort', filter);
  }

  function remove(size) {
    if (confirm(`Are you sure you want to delete the size: ${size.label}`)) {
      size.remove().then(() => {
        this.get('currentModel.sizes').removeObject(size);
      });
    }
  }
});