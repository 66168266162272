define("backalley/mirage/factories/curate-brand", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    id() {
      return _faker.default.random.uuid();
    },

    name() {
      return _faker.default.company.companyName();
    },

    slug() {
      return Ember.String.dasherize(this.name);
    },

    description() {
      return _faker.default.lorem.paragraph();
    },

    discount_message() {
      return _faker.default.lorem.sentence();
    },

    lead_time_message() {
      return _faker.default.lorem.sentence();
    },

    payment_terms() {
      return _faker.default.lorem.sentence();
    },

    delivery_info() {
      return _faker.default.lorem.sentence();
    },

    minimum_order_value() {
      return {
        description: _faker.default.lorem.sentence()
      };
    },

    logo() {
      return {
        url: _faker.default.image.avatar()
      };
    },

    images() {
      return Array(6).fill().map(() => ({
        url: _faker.default.image.fashion()
      }));
    },

    categories() {
      return Array(2).fill().map(() => {
        const name = _faker.default.random.words();

        return {
          name,
          main: false
        };
      });
    },

    catalogues() {
      return [{
        url: '',
        filename: 'this-is-a-very-long-brand-name-catalogue_price-list-2020'
      }, {
        url: '',
        filename: 'Brand-name_price-list-2021'
      }];
    },

    afterCreate(item) {
      item.update({
        _id: item.id
      });
    }

  });

  _exports.default = _default;
});