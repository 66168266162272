define("backalley/pods/main/packaging/products/index/route", ["exports", "backalley/models/packaging/product"], function (_exports, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PackagingProductsIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        },
        sort: {
          refreshModel: true
        },
        country: {
          refreshModel: true
        },
        category: {
          refreshModel: true
        },
        status: {
          refreshModel: true
        },
        full_text_search: {
          refreshModel: true
        }
      });
    }

    model({
      page,
      limit,
      sort,
      country,
      category,
      status,
      full_text_search
    }) {
      return _product.default.find({
        page,
        limit,
        sort,
        populate: 'category',
        variants_country: country || undefined,
        category: category || undefined,
        status: status || undefined,
        full_text_search: full_text_search || undefined
      });
    }

  }

  _exports.default = PackagingProductsIndexRoute;
});