define("backalley/pods/components/curate-brand-form/component", ["exports", "ember-concurrency-decorators", "ember-concurrency", "backalley/enums/curate-brand-status", "backalley/enums/currencies", "backalley/enums/xhr-ready-state", "backalley/util/request", "backalley/models/manufacturer", "backalley/models/curate/brand-tag"], function (_exports, _emberConcurrencyDecorators, _emberConcurrency, _curateBrandStatus, _currencies, _xhrReadyState, _request, _manufacturer, _brandTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurateBrandFormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class CurateBrandFormComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "notify", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "tags", _descriptor3, this);

      _defineProperty(this, "isEdit", false);

      _defineProperty(this, "curateBrandStatusOptions", [_curateBrandStatus.default.PUBLISHED, _curateBrandStatus.default.UNPUBLISHED, _curateBrandStatus.default.PREVIEW]);

      _defineProperty(this, "currencyOptions", [..._currencies.default.currencies, {
        name: 'Dollar',
        currency_code: 'USD',
        sign: '$'
      }]);
    }

    init() {
      super.init(...arguments);

      _brandTag.default.find({
        limit: 48
      }).then(({
        brandTags
      }) => this.tags = brandTags);
    }

    addLogo({
      url
    }) {
      Ember.set(this.brand, 'logo.url', url);
    }

    addDocument(document) {
      this.brand.terms_and_conditions_url = document.url;
    }

    addCatalogue(event) {
      this.set('isUploadingCatalogue', true);
      const catalogue = event.target.files[0];
      const key = `curate-product-catalogues/${catalogue.name}`;
      const contentType = 'application/pdf';
      const xhr = new XMLHttpRequest();
      const fd = new FormData();
      fd.append('file', catalogue);
      xhr.withCredentials = true;
      xhr.open('POST', `${(0, _request.apiURL)()}static/?key=${key}&contentType=${contentType}`, true);
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.upload.addEventListener('progress', event => {
        const progress = Math.round(event.loaded / event.total * 100);
        this.set('uploadCatalogueProgress', progress);
      });

      xhr.onreadystatechange = e => {
        this.set('isUploadingCatalogue', false);
        event.target.value = '';

        if (xhr.readyState === _xhrReadyState.default.DONE && xhr.status === 200) {
          const {
            url
          } = JSON.parse(xhr.responseText);
          return this.brand.addCatalogue(catalogue.name, url);
        }

        if (xhr.readyState === _xhrReadyState.default.DONE) {
          this.logger.error('error uploading curate\'s brand catalogue', e);
          this.notify.show('error', 'Oops! An error occured while uploading the catalogue. Please try again.');
        }
      };

      xhr.send(fd);
    }

    removeCatalogue(catalogue) {
      this.brand.catalogues.removeObject(catalogue);
    }

    *searchManufacturer(query) {
      yield (0, _emberConcurrency.timeout)(500);
      return _manufacturer.default.find({
        name: {
          $regex: `^${query}`,
          $options: 'i'
        }
      });
    }

    onChangeCurrency(currency) {
      this.brand.currency = currency.currency_code;
      Ember.set(this.brand, 'minimum_order_value.value.currency', currency.currency_code);
    }

    async save() {
      try {
        const createOrUpdate = this.isEdit ? this.brand.update() : this.brand.create();
        await createOrUpdate;
        this.notify.show('success', 'The brand was successfully updated!');
        this.router.transitionTo('main.curate.brands');
      } catch (err) {
        const message = err || 'An error ocurred while saving the brand. Please try again.';
        this.notify.show('error', message);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tags", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "addLogo", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "addLogo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addDocument", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "addDocument"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addCatalogue", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "addCatalogue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeCatalogue", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "removeCatalogue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchManufacturer", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchManufacturer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeCurrency", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeCurrency"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = CurateBrandFormComponent;
});