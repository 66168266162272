define("backalley/pods/main/campaigns/new/route", ["exports", "backalley/models/campaign"], function (_exports, _campaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return {
        campaign: _campaign.default.create({
          cta_show: false,
          locale: 'en'
        })
      };
    },

    actions: {
      setImage(property, cloudinaryImage) {
        this.set(`currentModel.campaign.${property}`, cloudinaryImage.url);
      },

      saveCampaign() {
        const campaign = this.currentModel.campaign; // Simple validations to make sure we have everything

        const requiredProperties = ['name', 'verticals', 'start_at', 'end_at', 'url', 'cta_show', 'display_in_product_page', 'product_card_position', 'image_desktop_url', 'image_tablet_url', 'image_mobile_landscape_url', 'image_mobile_url', 'image_card_url', 'locale'];
        const missingProperties = [];
        requiredProperties.forEach(prop => {
          if (campaign[prop] === undefined || campaign[prop] === null || campaign[prop] === '') {
            missingProperties.push(prop);
          }
        });

        if (missingProperties.length === 0) {
          // All good, save
          return campaign.post().then(() => {
            this.notify.show('success', 'Campaign successfully created!');
            this.transitionTo('main.campaigns');
          }).catch(message => {
            this.notify.show('error', message, 5000);
          });
        }

        return this.notify.show('error', `Required fields missing: ${missingProperties.join(', ')}`, 5000);
      }

    }
  });

  _exports.default = _default;
});