define("backalley/pods/components/order-edit/notes/component", ["exports", "ember-keyboard"], function (_exports, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    // Public API
    order: null,
    reservations: null,
    reservationBoutiques: null,
    // Private API
    classNames: ['box-view', 'box-view-events'],
    _notesMode: 'normal',
    _expandedSection: false,

    init() {
      this._super();

      this.set('keyboardActivated', true);
    },

    openNotesShortcut: Ember.on((0, _emberKeyboard.keyUp)('ctrl+Space'), (0, _emberKeyboard.keyUp)('alt+KeyN'), function () {
      this.toggleComment('quick');
      if (!this._expandedSection) return;
      return Ember.run.scheduleOnce('afterRender', this, function () {
        this.element.querySelector('.order-notes textarea').focus();
      });
    }),
    requesters: Ember.computed('order._id', function () {
      const {
        order,
        reservationBoutiques = []
      } = this;
      const customer = {
        email: order.email,
        name: order.full_name,
        label: 'Customer'
      };
      const boutiques = reservationBoutiques.map(reservationBoutique => {
        const boutique = Ember.get(reservationBoutique, 'reservations.0.boutique');
        return {
          email: boutique.order_email || boutique.support_email,
          name: boutique.name,
          label: 'Boutique'
        };
      });
      return [customer, ...boutiques];
    }),
    products: Ember.computed('order._id', function () {
      const {
        reservations = []
      } = this;
      return reservations.map(reservation => {
        const {
          product,
          boutique
        } = reservation;
        return {
          id: product.id,
          boutique_email: boutique.order_email,
          name: product.name,
          slug: product.slug,
          boutique_name: boutique.name,
          unit_price: reservation.local_price.amount
        };
      });
    }),
    boutiques: Ember.computed('order._id', function () {
      const {
        reservationBoutiques = []
      } = this;
      return reservationBoutiques.map(reservationBoutique => {
        const boutique = Ember.get(reservationBoutique, 'reservations.0.boutique');
        const shippingProvider = Ember.get(reservationBoutique, 'reservations.0.shipping_provider');
        const address = [boutique.address_line_1, boutique.address_line_2, boutique.address_line_3, boutique.address_postcode, boutique.address_city];
        return {
          id: reservationBoutique.boutique,
          email: boutique.order_email,
          name: boutique.name,
          commission_rate: boutique.commission_rate,
          shipping_provider: shippingProvider,
          address: address.join('\n'),
          currency: reservationBoutique.boutique_currency,
          address_city: boutique.adress_city || ' ',
          primary_name: boutique.primary_contact_name || ' '
        };
      });
    }),

    toggleComment(type) {
      this.toggleProperty('_expandedSection');
      this.set('_notesMode', type);
    },

    actions: {
      toggleComment(type) {
        this.toggleComment(type);
      }

    }
  });

  _exports.default = _default;
});