define("backalley/models/statement-invoice", ["exports", "backalley/util/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class StatementInvoice {
    constructor({
      _id,
      filename,
      url,
      presigned_url,
      type,
      fortnight_date,
      boutique,
      zone,
      created_at,
      updated_at
    }) {
      this._id = _id;
      this.filename = filename;
      this.url = url;
      this.presigned_url = presigned_url;
      this.type = type;
      this.fortnight_date = fortnight_date;
      this.boutique = boutique;
      this.zone = zone;
      this.created_at = created_at;
      this.updated_at = updated_at;
    }

    static find(query = {}) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.apiURL)('statements_invoice'),
        data: _objectSpread({}, query)
      }).then(({
        statements_invoice,
        meta
      }) => ({
        statementsInvoice: statements_invoice.map(statementInvoice => new StatementInvoice(statementInvoice)),
        meta
      })).catch(err => {
        throw err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
      });
    }

  }

  _exports.default = StatementInvoice;
});