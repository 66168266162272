define("backalley/models/reservation_boutique", ["exports", "backalley/mixins/request", "backalley/mixins/shipment", "backalley/mixins/shipping", "joi", "@streethub/calcs", "backalley/util/dates", "backalley/enums/shipping-providers", "backalley/util/models"], function (_exports, _request, _shipment, _shipping, _joi, _calcs, _dates, _shippingProviders, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function pick(key) {
    return elem => elem[key];
  }

  const validations = {
    order: _joi.default.string().required(),
    boutique: _joi.default.string().required(),
    nationwide_delivery_cost: _joi.default.number().integer().min(0).required()
  };
  const updateValidations = {
    _id: _joi.default.string().required(),
    order: _joi.default.string().required(),
    boutique: _joi.default.string().required(),
    nationwide_delivery_cost: _joi.default.number().integer().min(0).required(),
    shipping_provider: _joi.default.string().allow(_shippingProviders.default.DECIDED_AT_COURIER_BOOKING.value, _shippingProviders.default.EASYPOST.value, _shippingProviders.default.P2G.value, _shippingProviders.default.OTHER.value, _shippingProviders.default.WEENGS.value),
    status: _joi.default.string(),
    printed_shipping_label: _joi.default.boolean(),
    estimated_collection_date: _joi.default.string().allow(null),
    marked_as_collected: _joi.default.boolean(),
    marked_as_collected_at: _joi.default.any()
  };
  const ReservationBoutique = Ember.Object.extend(_request.default, _shipment.default, _shipping.default, {
    shipmentForDisplay: null,
    shipmentOptionSelected: null,
    shipmentOptions: null,

    init() {
      this.calculate();
    },

    calculate: Ember.observer('nationwide_delivery_cost', 'reservations.@each.price_total', 'reservations.@each.commission_exc_vat', 'reservations.@each.editing_quantity', 'reservations.@each.editing_price', 'reservations.@each.commission_rate', 'reservations.@each.pass_through_sales', 'reservations.@each.status', 'reservations.@each.pass_through_shipping', function () {
      const reservations = this.reservations;

      if (!reservations) {
        return;
      }

      this.set('shipmentOptions', []); // from the reservation

      const reservation_pass_through_saleses = reservations.map(pick('pass_through_sales'));
      const reservation_price_totals = reservations.map(pick('price_total'));
      const reservation_commissions_exc_vat = reservations.map(pick('commission_exc_vat'));
      const reservations_shipped = reservations.map(pick('shipped'));
      const transaction_currency = reservations.map(pick('transaction_currency'))[0]; // Both from reservationBoutique (assumed GBP, so requires translation)
      // @TODO in order to convert these to the same transaction currency I
      // need the rate at the time the order was placed. Either that or we
      // apply the same approach as the other changes and create a
      // transaction version recorded in the reservationBoutique. Or, perhaps
      // they can be sourced/copied from another source i.e. order.

      let fix_shipping_charge = this.fix_shipping_charge;
      let nationwide_delivery_cost = this.nationwide_delivery_cost; // this call embues calculation with addition calculated properties
      // you'll only find set inside calcs.js

      const calculation = _calcs.default.calculateBoutique({
        // source reservation
        reservation_pass_through_saleses,
        reservation_commissions_exc_vat,
        reservation_price_totals,
        reservations_shipped,
        // source reservationBoutique
        nationwide_delivery_cost,
        fix_shipping_charge
      });

      this.setProperties(_calcs.default.inPences(calculation));
      this.set('transaction_currency', transaction_currency);
    }).on('init'),

    postOrPut() {
      return this._id ? this.put() : this.post();
    },

    post() {
      return this.validate('post_schema').then(reservationBoutique => {
        return (0, _models.getChanges)(this._orig, reservationBoutique, Object.keys(validations));
      }).then(res => {
        return this.request({
          method: 'POST',
          url: this.apiURL('reservation_boutiques'),
          data: JSON.stringify(res)
        });
      }).then(({
        reservation_boutiques
      }) => {
        // reset the original data to get the correct diff the next time
        this.setOriginalData(reservation_boutiques[0]); // update the document with the response

        this.setProperties(reservation_boutiques[0]);
        return this;
      });
    },

    put() {
      return this.validate('put_schema').then(reservationBoutique => {
        return (0, _models.getChanges)(this._orig, reservationBoutique, Object.keys(updateValidations));
      }).then(res => {
        return this.request({
          method: 'PUT',
          url: this.apiURL(`reservation_boutiques/${this._id}`),
          data: JSON.stringify(res)
        });
      }).then(({
        reservation_boutiques
      }) => {
        // reset the original data to get the correct diff the next time
        this.setOriginalData(reservation_boutiques[0]); // update the document with the response

        this.setProperties(reservation_boutiques[0]);
        return this;
      });
    },

    validate(schema = 'post_schema') {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(ReservationBoutique[schema], data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    generateReturnLabel() {
      let {
        boutique,
        order
      } = this;
      return this.request({
        method: 'POST',
        url: this.apiURL('courier/return-label'),
        data: JSON.stringify({
          boutique,
          order
        })
      });
    },

    /**
     * Set up the properties needed for allowing a "shipment" to be
     * selected as the current one for display. So we can handle
     * reviewing mutli-shipment orders.
     */
    configureShipmentSelector() {
      const shipments = this.shipments || [];
      const shipmentForDisplay = shipments[0];
      const shipmentOptions = shipments.map(shipment => ({
        label: (0, _dates.niceDate)(shipment.created_at),
        id: shipment._id
      }));
      const shipmentOptionSelected = shipmentOptions[0];
      this.set('shipmentOptions', shipmentOptions);
      this.set('shipmentForDisplay', shipmentForDisplay);
      this.set('shipmentOptionSelected', shipmentOptionSelected);
    },

    /**
     * Called by the template action, allows us to change which shipment
     * is the one being displayed. So we can show multiple shipment
     * information in the same space.
     *
     * @param {String} shipmentId i.e. shipment._id
     */
    changeDisplayedShipment(shipmentId) {
      const shipments = this.shipments || [];
      const shipmentOptions = this.shipmentOptions || [];
      const shipmentOptionSelected = shipmentOptions.find(option => {
        return option.id === shipmentId;
      });
      const shipmentForDisplay = shipments.find(shipment => {
        return shipment._id === shipmentId;
      });
      this.set('shipmentForDisplay', shipmentForDisplay);
      this.set('shipmentOptionSelected', shipmentOptionSelected);
    }

  });
  ReservationBoutique.reopen({
    setOriginalData(data) {
      this._orig = this.pojo(data);
      return data;
    },

    pojo(reservationBoutique) {
      let data = _objectSpread({}, reservationBoutique);

      delete data.reservations;
      return JSON.parse(JSON.stringify(data));
    }

  });
  ReservationBoutique.reopenClass(_request.default, _shipment.default, _shipping.default, {
    post_schema: _joi.default.object().keys(validations),
    put_schema: _joi.default.object().keys(updateValidations),

    /**
     * @param {Object} data Data to create an object with
     * @return {Object} Returns an initialized object
     */
    create(data = {}) {
      let c = this._super(data);

      c.setOriginalData(data);
      return c;
    },

    /**
     * Find reservation_boutiques
     * @param  {String} order_id
     * @param  {String} boutique_id
     * @param  {Number} default_nationwide_delivery_cost
     * @param  {Array}  reservations
     * @return {Array}      Returns back a reservations array
     */
    find(order_id, boutique_id, reservations) {
      return this.request({
        method: 'GET',
        url: this.apiURL('reservation_boutiques'),
        data: {
          order: order_id,
          boutique: boutique_id,
          status: '*',
          plain: true
        }
      }).then(({
        reservation_boutiques
      }) => {
        return reservation_boutiques.map(reservationBoutique => this.create(reservationBoutique));
      }).then(([first]) => {
        if (!first) {
          throw new Error('No reservation boutiques found!');
        }

        if (first.get('order') !== order_id) {
          throw new Error('order_id was a populated object or did not match');
        }

        if (first.get('boutique') !== boutique_id) {
          throw new Error('boutique_id was a populated object or did not match');
        } // save a reference to the rservations and the order


        first.set('reservations', reservations);
        return first;
      });
    }

  });
  var _default = ReservationBoutique;
  _exports.default = _default;
});