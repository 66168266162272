define("backalley/pods/components/format-price/component", ["exports", "backalley/util/default-to"], function (_exports, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FormatPriceComponent = Ember.Component.extend({
    tagName: '',
    price: 0,
    currency: (0, _defaultTo.default)('GBP')
  });
  FormatPriceComponent.reopenClass({
    // Enable usage with positional params:
    // e.g. {{format-price <price> <currency>}}
    positionalParams: ['price', 'currency']
  });
  var _default = FormatPriceComponent;
  _exports.default = _default;
});