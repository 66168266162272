define("backalley/pods/components/zone-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    countryResults: [],
    actions: {
      searchCountries(q) {
        if (!q) return this.set('countryResults', []);
        const countries = this.countries.filter(curr => curr.name.toLowerCase().indexOf(q.toLowerCase()) === 0);
        return this.set('countryResults', countries);
      },

      addCountry(zone, country) {
        zone.countries.addObject(country);
      },

      removeCountry(zone, country) {
        zone.countries.removeObject(country);
      }

    }
  });

  _exports.default = _default;
});