define("backalley/pods/components/form-price/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4Pd2F+Ms",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\\n\"],[1,[28,\"component\",[[28,\"hot-load\",[\"form-text\",[23,0,[]],[24,[\"form-text\"]],\"form-text\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"placeholder\",\"error\",\"disabled\",\"type\",\"step\",\"value\",\"action\"],[[23,0,[]],\"form-text\",[24,[\"form-text\"]],false,true,[24,[\"placeholder\"]],[24,[\"error\"]],[24,[\"disabled\"]],\"number\",\"0.01\",[24,[\"_value\"]],[28,\"action\",[[23,0,[]],\"change\"],null]]]],false],[0,\"\\n\\n\"],[1,[22,\"strLabel\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/form-price/template.hbs"
    }
  });

  _exports.default = _default;
});