define("backalley/models/shipment", ["exports", "backalley/mixins/request", "backalley/enums/shipping-providers"], function (_exports, _request, _shippingProviders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  // P2G and Easypost are on the shipment level (reservation boutique)
  const Shipment = Ember.Object.extend(_request.default, {
    hasNoShippingProvider: Ember.computed.equal('shipping_provider', undefined),
    isEasypost: Ember.computed.equal('shipping_provider', 'easypost'),
    isP2G: Ember.computed.equal('shipping_provider', 'p2g'),
    isWeengs: Ember.computed.equal('shipping_provider', 'weengs'),
    shippingProviderLabel: Ember.computed('shipping_provider', _shippingProviders.computeShippingProviderLabel),
    typeLabel: Ember.computed('type', function () {
      if (this.type === 'network') {
        return 'QuickShip';
      }

      if (this.type === 'trouva') {
        return 'Trouva';
      }

      return this.type;
    })
  });
  Shipment.reopenClass(_request.default, {
    /**
     * Request raw shipment data from Promenade and then convert into
     * instances of Shipment class.
     * @param  {Object} data Data
     * @return {Promise} resolving to {Object}
     */
    find(data) {
      return this.request({
        method: 'POST',
        url: this.apiURL('shipments'),
        headers: _objectSpread({}, this.getHeaderHTTPMethodOverride('GET')),
        data: JSON.stringify(data)
      }).then(({
        shipments
      }) => {
        return shipments.map(shipment => Shipment.create(shipment));
      });
    },

    async findWithMeta(params = {}) {
      const response = await this.request({
        method: 'POST',
        url: this.apiURL('shipments'),
        headers: _objectSpread({}, this.getHeaderHTTPMethodOverride('GET')),
        data: JSON.stringify(params)
      });
      const {
        shipments,
        meta
      } = response || {};
      return {
        shipments: shipments.map(shipment => Shipment.create(shipment)),
        meta
      };
    }

  });
  var _default = Shipment;
  _exports.default = _default;
});