define("backalley/pods/components/order-edit/path-detail-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    order: null,
    reservationsForPath: null,
    classNames: ['path-detail-item'],
    pathTitle: Ember.computed('reservationsForPath.0.shipping_variant.{source_country_code,destination_country_code}', function () {
      if (!this.reservationsForPath || !this.reservationsForPath[0]) {
        return '';
      }

      const firstReservation = this.reservationsForPath[0];
      const a = firstReservation.shipping_variant.source_country_code;
      const b = firstReservation.shipping_variant.destination_country_code;
      return `${a} to ${b}`;
    }),
    firstShippingVariant: Ember.computed.alias('reservationsForPath.0.shipping_variant'),
    costCapUsedOnPath: Ember.computed('reservationsForPath.0.shipping_variant.{cost_cap_breached,variant}', function () {
      if (!this.reservationsForPath || !this.reservationsForPath[0]) {
        return null;
      }

      const variants = this.reservationsForPath.map(r => r.shipping_variant);
      const caps = new Set(variants.map(v => v.cost_cap_breached));
      const opts = new Set(variants.map(v => v.variant));
      const isCapViable = caps.size === 1 && caps.has(true);
      const isOptionViableForCap = opts.size === 1 && opts.has('standard');
      return isCapViable && isOptionViableForCap;
    }),
    shippingWasChargedPer: Ember.computed('costCapUsedOnPath', function () {
      if (this.costCapUsedOnPath === null) {
        return 'Unknown';
      }

      return this.costCapUsedOnPath ? 'Path' : 'Boutique';
    }),
    freeShippingUsedOnPath: Ember.computed('reservationsForPath.0.shipping_variant.boutique_free_shipping_threshold_breached', function () {
      if (!this.reservationsForPath || !this.reservationsForPath[0]) {
        return null;
      }

      const variants = this.reservationsForPath.map(r => r.shipping_variant);
      return variants.some(v => v.boutique_free_shipping_threshold_breached);
    })
  });

  _exports.default = _default;
});