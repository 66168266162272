define("backalley/pods/main/prospective-boutique/edit/route", ["exports", "backalley/enums/currencies", "backalley/models/prospective_boutique"], function (_exports, _currencies, _prospective_boutique) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model({
      prospective_boutique_id
    }) {
      const [prospectiveBoutique] = await this._findById(prospective_boutique_id);
      prospectiveBoutique.set('populatedRegisteredCountry', prospectiveBoutique.registered_address_country);
      prospectiveBoutique.set('selectedCurrency', _currencies.default.currencies.find(currency => currency.currency_code === prospectiveBoutique.currency));
      return prospectiveBoutique;
    },

    _findById(prospective_boutique_id) {
      return _prospective_boutique.default.find({
        _id: prospective_boutique_id,
        populate: 'registered_address_country,created_by,updated_by,email_sent_by,accepted_by,boutique'
      });
    },

    actions: {
      save() {
        if (this.currentModel.isBoutiqueNameInUse) {
          return this.notify.show('error', 'Boutique Name already in use');
        }

        this.currentModel.put().then(() => this.transitionTo('main.prospective-boutique')).catch(err => this.notify.show('error', err.responseJSON ? err.responseJSON.errors[0].detail : err.message));
      },

      cancel() {
        this.transitionTo('main.prospective-boutique');
      },

      triggerEmail() {
        return this.currentModel.triggerEmail().then(async prospectiveBoutique => {
          Ember.setProperties(this.currentModel, await this._findById(prospectiveBoutique._id));
        }).then(this.notify.show('success', 'Email sent to boutique owner')).catch(error => {
          this.notify.show('error', 'Oops! An error ocurred while triggering the email. Please try again.');
        });
      }

    }
  });

  _exports.default = _default;
});