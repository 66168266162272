define("backalley/util/chunk-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = chunkArray;

  function chunkArray([...items], chunkSize = 10) {
    const chunks = [];
    let lastLength;

    while (items.length !== lastLength) {
      lastLength = items.length;
      const chunk = items.splice(0, chunkSize);

      if (chunk.length) {
        chunks.push(chunk);
      } else {
        break;
      }
    }

    return chunks;
  }
});