define("backalley/enums/boutique-sign-up-tier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    STANDARD: 'standard',
    PREMIUM: 'premium'
  };
  _exports.default = _default;
});