define("backalley/models/boutique", ["exports", "backalley/mixins/request", "backalley/mixins/shipping", "joi", "moment-timezone", "backalley/enums/shipping-providers", "backalley/util/models", "backalley/enums/boutique-tier", "backalley/enums/boutique-feature", "backalley/enums/vertical", "backalley/util/request"], function (_exports, _request, _shipping, _joi, _momentTimezone, _shippingProviders, _models, _boutiqueTier, _boutiqueFeature, _vertical, _request2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const validations = {
    _id: _joi.default.string(),
    name: _joi.default.string(),
    featured: _joi.default.boolean(),
    featured_at: _joi.default.date().allow(null),
    same_day: _joi.default.boolean(),
    published: _joi.default.boolean(),
    archived: _joi.default.boolean(),
    is_beta: _joi.default.boolean(),
    allow_partial_self_shipping: _joi.default.boolean(),
    gift_wrap: _joi.default.boolean(),
    currency: _joi.default.string(),
    statements_version: _joi.default.number(),
    published_at: _joi.default.date().allow(null),
    last_visited_at: _joi.default.date().allow(null),
    from_warehouse: _joi.default.boolean(),
    tags: _joi.default.array().items(_joi.default.string()),
    areas: _joi.default.array().items(_joi.default.string()).min(1),
    commission_rate: _joi.default.number().min(0),
    weengs: _joi.default.boolean(),
    shipping_provider: _joi.default.string().allow(_shippingProviders.default.DECIDED_AT_COURIER_BOOKING.value, _shippingProviders.default.EASYPOST.value, _shippingProviders.default.P2G.value, _shippingProviders.default.OTHER.value),
    p2g_configuration: _joi.default.object().keys({
      allowDropOffService: _joi.default.boolean(),
      blacklist: _joi.default.array().items(_joi.default.number().integer().min(1)),
      whitelist: _joi.default.array().items(_joi.default.number().integer().min(1))
    }),
    fix_shipping_charge: _joi.default.number().integer().min(0),
    enable_fix_charge: _joi.default.boolean(),
    bank_account_number: _joi.default.string().regex(/^[0-9]{8}$/).allow('').allow(null),
    bank_sort_code: _joi.default.string().regex(/^[0-9]{2}-[0-9]{2}-[0-9]{2}$/).allow('').allow(null),
    bank_account_iban: _joi.default.string().allow('').allow(null),
    bank_account_bic: _joi.default.string().allow('').allow(null),
    boutique_categories: _joi.default.array().items(_joi.default.object()),
    accounting_address_line_1: _joi.default.string().allow('').allow(null),
    accounting_address_line_2: _joi.default.string().allow('').allow(null),
    statement_email: _joi.default.string().email({
      tlds: false
    }).allow('').allow(null),
    primary_email: _joi.default.string().email({
      tlds: false
    }).allow('').allow(null),
    support_email: _joi.default.string().email({
      tlds: false
    }).allow('').allow(null),
    order_email: _joi.default.string().email({
      tlds: false
    }).allow('').allow(null),
    primary_contact_name: _joi.default.string().allow('').allow(null),
    info_competitors: _joi.default.string().allow('').allow(null),
    info_web_platform: _joi.default.string().allow('').allow(null),
    info_website_url: _joi.default.string().allow('').allow(null),
    info_pos: _joi.default.string().allow('').allow(null),
    info_card_payment_system: _joi.default.string().allow('').allow(null),
    postcode: _joi.default.string(),
    address: _joi.default.string(),
    address_line_1: _joi.default.string().allow(null).allow(''),
    address_line_2: _joi.default.string().allow(null).allow(''),
    address_line_3: _joi.default.string().allow(null).allow(''),
    address_city: _joi.default.string().required(),
    address_country: _joi.default.string().allow(null),
    operating_manager: _joi.default.string().allow(null).allow(''),
    accounting_name: _joi.default.string().allow(null).allow(''),
    timezone: _joi.default.string().allow(null),
    address_postcode: _joi.default.string().allow(null),
    description: _joi.default.string().allow('').allow(null),
    mini_description: _joi.default.string().allow('').allow(null),
    story_title: _joi.default.string().allow('').allow(null),
    story_text: _joi.default.string().allow('').allow(null),
    story_image: _joi.default.object().allow(null),
    logo: _joi.default.object().allow(null),
    founder_quote: _joi.default.string().allow(null).allow(''),
    founder_image: _joi.default.object().allow(null).allow(''),
    cover: _joi.default.object().allow(null).allow(''),
    cover_thumbnail: _joi.default.object().allow(null).allow(''),
    prefers_thermal_label: _joi.default.boolean(),
    quality_score: _joi.default.number().min(0).max(100),
    cut_off_time_offset_in_days: _joi.default.number().min(0),
    eu_vat: _joi.default.object().keys({
      status: _joi.default.string().allow(null).allow(''),
      status_changed_at: _joi.default.date().allow(null),
      registration_number: _joi.default.string().allow(null).allow('')
    }),
    vat_number: _joi.default.string().allow(null).allow(''),
    is_vat_registered: _joi.default.boolean(),
    account_manager: _joi.default.string().allow(null).allow(''),
    is_express_shipping: _joi.default.boolean(),
    sync_integration_activated: _joi.default.boolean(),
    sync_integration_type: _joi.default.string().allow(null),
    contract_owner_name: _joi.default.string().allow(null).allow(''),
    membership_fee: _joi.default.number().min(0),
    registered_name: _joi.default.string().allow(null).allow(''),
    registered_number: _joi.default.string().allow(null).allow(''),
    registered_address_line_1: _joi.default.string().allow(null).allow(''),
    registered_address_line_2: _joi.default.string().allow(null).allow(''),
    registered_address_country: _joi.default.string().allow(null),
    other_commercial_terms: _joi.default.string().allow(null).allow(''),
    signed_by: _joi.default.string().allow(null),
    left_us_at: _joi.default.date().allow(null),
    projected_product_count: _joi.default.number(),
    metrics: _joi.default.object().keys({
      gross_annual_sales: _joi.default.number(),
      annual_rent: _joi.default.number(),
      online_sales_percentage: _joi.default.number(),
      operating_profit: _joi.default.number(),
      average_markup: _joi.default.number(),
      average_margin_percentage: _joi.default.number(),
      average_vat_rate_percentage: _joi.default.number()
    }),
    tier: _joi.default.string().valid(...Object.values(_boutiqueTier.default)),
    trouva_review: _joi.default.string().allow(null).allow(''),
    promotions: _joi.default.array(),
    operational_status: _joi.default.string().valid('paused', 'partial', 'operational').allow(null),
    features: _joi.default.array().items(_joi.default.string().valid(...Object.values(_boutiqueFeature.default))),
    click_and_collect: _joi.default.object({
      enabled: _joi.default.boolean()
    }),
    is_curate_brand: _joi.default.boolean(),
    is_d2c_brand: _joi.default.boolean(),
    hokodo: _joi.default.object({
      company: _joi.default.string()
    }),
    service_fee: _joi.default.object({
      plan: _joi.default.string(),
      exceptions: _joi.default.array().items(_joi.default.object({
        _id: _joi.default.string(),
        type: _joi.default.string(),
        from: _joi.default.date(),
        till: _joi.default.date()
      })).optional()
    }).optional(),
    verticals: _joi.default.array().items(_joi.default.string().valid(...Object.values(_vertical.default))),
    feed_type: _joi.default.string().allow(null).allow(''),
    restore: _joi.default.object({
      enabled: _joi.default.boolean()
    })
  };
  const Boutique = Ember.Object.extend(_request.default, _shipping.default, {
    // Find the timezone of the boutique.
    // Ideally this will be stored on the boutique schema,
    // creating a temporary variable for now.
    timezone: 'Europe/London',
    isOnHoliday: Ember.computed('holiday_from', 'holiday_to', {
      get() {
        const from = this.holiday_from;
        const to = this.holiday_to;
        if (!from || !to) return false;
        return (0, _momentTimezone.default)().isBetween(from, to);
      }

    }),
    todayOH: Ember.computed('oh.[].day', {
      get() {
        const today = (0, _momentTimezone.default)(); // isoWeekday has standardised indexes 1-7, Monday-Sunday.

        const day = today.isoWeekday() - 1;
        const fromWarehouse = this.from_warehouse;
        const openingHours = fromWarehouse ? Ember.getWithDefault(this, 'warehouse_oh', []) : Ember.getWithDefault(this, 'oh', []);
        return openingHours.find(oh => Number(Ember.get(oh, 'day')) === Number(day));
      }

    }),
    isOpen: Ember.computed('oh.[].active', 'isOnHoliday', 'timezone', {
      get() {
        const now = (0, _momentTimezone.default)();
        const todayOH = this.todayOH;
        const boutiqueTimezone = this.timezone;
        if (!todayOH) return {
          error: 'No opening hours'
        };

        const openingTime = _momentTimezone.default.tz(todayOH.selectedStart, 'HH[:]mm', boutiqueTimezone);

        const closingTime = _momentTimezone.default.tz(todayOH.selectedEnd, 'HH[:]mm', boutiqueTimezone); // If boutique is on holiday, then they're closed


        if (this.isOnHoliday) return false; // If boutique has the day disabled, then they're closed

        if (!Ember.get(todayOH, 'active')) return false; // Boutique has today marked as active, check the opening times

        return now.isBetween(openingTime, closingTime);
      }

    }),
    isClosingSoon: Ember.computed('isOpen', {
      get() {
        const todayOH = this.todayOH;
        const closingTime = (0, _momentTimezone.default)(todayOH.selectedEnd, 'HH[:]mm');
        const diff = closingTime.diff((0, _momentTimezone.default)());

        const minutesToClose = _momentTimezone.default.duration(diff).asMinutes();

        return this.isOpen && minutesToClose >= 0 && minutesToClose <= 30;
      }

    }),
    // Extend opening hours, to include the equivalent time for the UK.
    ohInUK: Ember.computed('oh.[].time', 'from_warehouse', 'timezone', function () {
      // Aggregate boutique opening hours with warehouse opening hours
      // depending if the boutique is shipping from a warehouse.
      const fromWarehouse = this.from_warehouse;
      const openingHours = fromWarehouse ? Ember.getWithDefault(this, 'warehouse_oh', []) : Ember.getWithDefault(this, 'oh', []);
      const boutiqueTimezone = this.timezone; // Extend every day's opening hours

      return openingHours.map(day => {
        // Create a moment object in the boutique's timezone, then convert it
        // to London timezone for displaying purposes
        const openingTime = _momentTimezone.default.tz(day.selectedStart, 'HH[:]mm', boutiqueTimezone).tz('Europe/London');

        const closingTime = _momentTimezone.default.tz(day.selectedEnd, 'HH[:]mm', boutiqueTimezone).tz('Europe/London');

        const isClosedThisDay = (day.time || '').toLowerCase() === 'closed' || !day.active;
        const ukTime = `${openingTime.format('HH[:]mm')}-${closingTime.format('HH[:]mm')}`;
        return _objectSpread(_objectSpread({}, day), {}, {
          ukTime: isClosedThisDay ? 'Closed' : ukTime
        });
      });
    }),
    isStar: Ember.computed('tier', function () {
      return this.tier.includes('star');
    }),

    /**
     * Create new boutique
     * @return {Object} Promise
     */
    post() {
      return this.validate().then(boutique => {
        return this.request({
          method: 'POST',
          url: this.apiURL('boutiques'),
          data: JSON.stringify(boutique)
        });
      }).then(res => {
        const data = res.boutiques[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    /**
     * Save boutique
     * @return {Object} Promise
     */
    save() {
      return this.validate().then(data => (0, _models.getChanges)(this._orig, data, Object.keys(validations))).then(boutique => {
        // Replace category objects with strings
        const boutique_categories = this.boutique_categories.map(boutiqueCategory => {
          if (typeof boutiqueCategory === 'object') {
            return boutiqueCategory._id;
          }

          return boutiqueCategory;
        });
        return this.request({
          method: 'PUT',
          url: this.apiURL(`boutiques/${boutique._id}`),
          data: JSON.stringify(_objectSpread(_objectSpread({}, boutique), {}, {
            boutique_categories
          }))
        });
      }).then(res => {
        const data = res.boutiques[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    validate() {
      var _data$populatedRegist, _data$populatedHokodo;

      const data = JSON.parse(JSON.stringify(this));

      if (data.populatedCountry && data.populatedCountry._id) {
        data.address_country = data.populatedCountry._id;
      }

      if (data.populatedRegisteredCountry && (_data$populatedRegist = data.populatedRegisteredCountry) !== null && _data$populatedRegist !== void 0 && _data$populatedRegist._id) {
        data.registered_address_country = data.populatedRegisteredCountry._id;
      }

      if ((_data$populatedHokodo = data.populatedHokodoCompany) !== null && _data$populatedHokodo !== void 0 && _data$populatedHokodo.id) {
        data.hokodo = _objectSpread(_objectSpread({}, data.hokodo || {}), {}, {
          company: data.populatedHokodoCompany.id
        });
      }

      this.set('errors', {});
      return (0, _models.validateSchema)(Boutique.schema, data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    /**
     * Remove boutique
     * @return {Object} Promise
     */
    remove() {
      const boutique = this;
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`boutiques/${boutique._id}`)
      });
    },

    quickPublish() {
      const boutique = this;
      return this.request({
        method: 'GET',
        url: this.apiURL(`boutiques/${boutique._id}/quickpublish`)
      });
    }

  });
  Boutique.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  Boutique.reopenClass(_request.default, _shipping.default, {
    schema: _joi.default.object().keys(validations).and('label', 'value'),

    create(data = {}) {
      // Default "boutique_categories" value.
      // Default value needs to be here, so that a new reference is created
      // for the array.
      const boutique_categories = [];

      const originalData = _objectSpread({
        boutique_categories
      }, data);

      let c = this._super(originalData);

      c.setOriginalData(originalData);
      return c;
    },

    /**
     * Find boutiques
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.findWithMeta(data).then(res => res.boutiques);
    },

    /**
     * Find boutiques and returns meta too
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMeta(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('boutiques'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => {
        return {
          boutiques: res.boutiques.map(boutique => Boutique.create(boutique)),
          meta: res.meta
        };
      });
    },

    /**
     * Find boutique by id
     * @param  {String} id boutique _id
     * @return {Object}    Promise
     */
    findById(id, data = {}) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`boutiques/${id}`),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => this.create(res.boutiques[0]));
    },

    findByIds(ids) {
      return (0, _request2.request)({
        url: (0, _request2.apiURL)(`boutiques/${ids}`),
        data: {
          plain: true
        }
      }).then(res => ({
        boutiques: res.boutiques.map(boutique => Boutique.create(boutique)),
        meta: res.meta
      }));
    },

    findRelativeProductStats(data) {
      const query = {
        boutique: data._id
      };
      if (data.since) query.since = data.since;
      if (data.until) query.until = data.until;
      return (0, _request2.request)({
        method: 'GET',
        url: (0, _request2.apiURL)('products/relative-stats'),
        data: query
      });
    },

    findAbsoluteProductStats(data) {
      const query = {
        boutique: data._id
      };
      return (0, _request2.request)({
        method: 'GET',
        url: (0, _request2.apiURL)('products/absolute-stats'),
        data: query
      });
    }

  });
  var _default = Boutique;
  _exports.default = _default;
});