define("backalley/pods/components/display-transactions/component", ["exports", "backalley/models/transaction", "ember-concurrency"], function (_exports, _transaction, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filterTransactions: null,
    transactions: [],

    didInsertElement() {
      this._super(...arguments);

      if (this.filterTransactions !== null) {
        this.fetchTransactions.perform();
      }
    },

    fetchTransactions: (0, _emberConcurrency.task)(function* () {
      const {
        transactions
      } = yield _transaction.default.find(this.filterTransactions);
      this.set('transactions', transactions);
    }).restartable()
  });

  _exports.default = _default;
});