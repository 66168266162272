define("backalley/pods/components/paths-and-lanes/new/publish/component", ["exports", "backalley/models/shipping-path-release", "backalley/models/carrier-lane-release", "backalley/enums/path-and-lane-release-type", "backalley/enums/path-and-lane-release-details-status", "backalley/models/team-note"], function (_exports, _shippingPathRelease, _carrierLaneRelease, _pathAndLaneReleaseType, _pathAndLaneReleaseDetailsStatus, _teamNote) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const status = {
    NONE: 'none',
    READY: 'ready',
    PUBLISHING: 'publishing',
    PUBLISHED: 'published',
    RELEASED: 'released',
    FAILED: 'failed',
    TIMEOUT: 'timeout'
  };
  const teamNotesBaseFilePath = 'paths-and-lanes';

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    shippingPathsStatus: status.READY,
    carrierLanesStatus: status.READY,
    shippingPathRelease: undefined,
    carrierLaneRelease: undefined,
    session: Ember.inject.service(),

    init() {
      this._super();

      const shippingPathRelease = new _shippingPathRelease.default({
        shippingPaths: this.shippingPaths
      });
      this.set('shippingPathRelease', shippingPathRelease);
      const carrierLaneRelease = new _carrierLaneRelease.default({
        carrierLanes: this.carrierLanes
      });
      this.set('carrierLaneRelease', carrierLaneRelease);
      this.publish();
    },

    atLeastOneFailed: Ember.computed('shippingPathsStatus', 'carrierLanesStatus', function () {
      return this.shippingPathsStatus === status.FAILED || this.shippingPathsStatus === status.TIMEOUT || this.carrierLanesStatus === status.FAILED || this.carrierLanesStatus === status.TIMEOUT;
    }),
    isFinished: Ember.computed('shippingPathsStatus', 'carrierLanesStatus', function () {
      return ![status.READY, status.PUBLISHING, status.PUBLISHED].includes(this.shippingPathsStatus) && ![status.READY, status.PUBLISHING, status.PUBLISHED].includes(this.carrierLanesStatus);
    }),
    isReleased: Ember.computed('shippingPathsStatus', 'carrierLanesStatus', 'isFInished', function () {
      return (this.shippingPathsStatus === status.RELEASED || this.carrierLanesStatus === status.RELEASED) && this.isFinished;
    }),

    async checkUntilReachedReleasesStatus(releaseType, releaseDate, retryWaitingTimeInMs, maximumRetryCount) {
      const statusPropertyName = releaseType === _pathAndLaneReleaseType.default.CARRIER_LANE ? 'carrierLanesStatus' : 'shippingPathsStatus';

      if (!maximumRetryCount) {
        this.set(statusPropertyName, status.TIMEOUT);
        return;
      }

      const retry = () => setTimeout(() => this.checkUntilReachedReleasesStatus(releaseType, releaseDate, retryWaitingTimeInMs, maximumRetryCount - 1), retryWaitingTimeInMs);

      try {
        const currentStatus = await this.getStatus(releaseType, releaseDate);
        this.set(statusPropertyName, currentStatus);

        if ([status.RELEASED, status.FAILED].includes(currentStatus)) {
          return;
        }
      } catch (err) {
        console.error(err);
      }

      retry();
    },

    async getStatus(releaseType, releaseDate) {
      const releaseDetails = releaseType === _pathAndLaneReleaseType.default.CARRIER_LANE ? await this.carrierLaneRelease.findDetails({
        release_datetime: releaseDate
      }) : await this.shippingPathRelease.findDetails({
        release_datetime: releaseDate
      });
      const statusMap = new Map();
      statusMap.set(_pathAndLaneReleaseDetailsStatus.default.CREATED, status.PUBLISHED);
      statusMap.set(_pathAndLaneReleaseDetailsStatus.default.PROCESSING_ITEMS, status.PUBLISHED);
      statusMap.set(_pathAndLaneReleaseDetailsStatus.default.PROCESSING_RULES, status.PUBLISHED);
      statusMap.set(_pathAndLaneReleaseDetailsStatus.default.ERRORED, status.FAILED);
      statusMap.set(_pathAndLaneReleaseDetailsStatus.default.CRITICAL, status.FAILED);
      statusMap.set(_pathAndLaneReleaseDetailsStatus.default.STABLE, status.RELEASED);
      statusMap.set(_pathAndLaneReleaseDetailsStatus.default.ACTIVE, status.RELEASED);

      if (!statusMap.has(releaseDetails.status)) {
        throw new Error('Invalid status response');
      }

      return statusMap.get(releaseDetails.status);
    },

    async publish(releaseType) {
      if (!releaseType) {
        await this.publish(_pathAndLaneReleaseType.default.SHIPPING_PATH);
        await this.publish(_pathAndLaneReleaseType.default.CARRIER_LANE);
        return;
      }

      const statusPropertyName = releaseType === _pathAndLaneReleaseType.default.CARRIER_LANE ? 'carrierLanesStatus' : 'shippingPathsStatus';

      if (releaseType === _pathAndLaneReleaseType.default.SHIPPING_PATH && !this.shippingPaths) {
        this.set(statusPropertyName, status.NONE);
        return;
      }

      if (releaseType === _pathAndLaneReleaseType.default.CARRIER_LANE && !this.carrierLanes) {
        this.set(statusPropertyName, status.NONE);
        return;
      }

      this.set(statusPropertyName, status.PUBLISHING);

      try {
        const release = releaseType === _pathAndLaneReleaseType.default.CARRIER_LANE ? await this.carrierLaneRelease.release() : await this.shippingPathRelease.release();

        if (!release || release.status !== 'success' || release.write_errors && release.write_errors.length) {
          console.info(release);
          throw new Error('Release did not succeed');
        }

        this.set(statusPropertyName, status.PUBLISHED);

        if (!release.inserted_ids || !release.inserted_ids.length) {
          console.error('Expecting API response to contain "inserted_ids". ' + 'Response:', release);
          throw new Error('Unexpected API response. ' + `May have failed to publish ${releaseType}s`);
        }

        const WAITING_TIME_IN_MS = 3 * 60 * 1000;
        const POLLING_TIME_IN_MS = 30 * 1000; // Let it keep checking in the background until the release is stable/active

        this.checkUntilReachedReleasesStatus(releaseType, release.release_datetime, POLLING_TIME_IN_MS, Math.ceil(WAITING_TIME_IN_MS / POLLING_TIME_IN_MS));
        const description = releaseType === _pathAndLaneReleaseType.default.CARRIER_LANE ? this.carrierLanesReleaseDescription : this.shippingPathsReleaseDescription;
        await this.storeReleaseNote(releaseType, release.release_datetime, description);
      } catch (e) {
        console.error(e);
        this.set(statusPropertyName, status.FAILED);
      }
    },

    createReleaseDatetimeDelayedBy(delayInMinutes = 65) {
      const nowPlusDelay = new Date();
      nowPlusDelay.setMinutes(new Date().getMinutes() + delayInMinutes);
      const releaseDatetime = nowPlusDelay.toISOString();
      return releaseDatetime;
    },

    storeReleaseNote(releaseType, releaseDate, content) {
      if (!content) {
        return;
      }

      const data = {
        last_updated: new Date(),
        email: this.session.user.email,
        text: content
      };
      return _teamNote.default.save(`${teamNotesBaseFilePath}/${releaseType}/${releaseDate}`, data);
    },

    resetShippingPaths() {
      this.storeShippingPaths(undefined, undefined);
    },

    resetCarrierLanes() {
      this.storeCarrierLanes(undefined, undefined);
    },

    actions: {
      completeStep() {
        return this.completeStep();
      },

      goBackStep() {
        if (this.shippingPathsStatus === status.PUBLISHED) {
          // If we go back, we don't want to republish Shipping Paths, so we discard them
          this.notify.show('success', 'Shipping Paths have been published successfully and will be removed from this interface');
          this.resetShippingPaths();
        }

        if (this.carrierLanesStatus === status.PUBLISHED) {
          // If we go back, we don't want to republish Carrier Lanes, so we discard them
          this.notify.show('success', 'Carrier Lanes have been published successfully and will be removed from this interface');
          this.resetCarrierLanes();
        }

        return this.goBackStep();
      }

    }
  });

  _exports.default = _default;
});