define("backalley/pods/components/form-cloudinary/component", ["exports", "backalley/config/environment", "backalley/util/cloudinary-upload"], function (_exports, _environment, _cloudinaryUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    multiple: false,
    fileTypes: 'image/*',
    maxFileSize: 5000000,
    // 5MB
    folder: '',
    onUpload: () => {},
    classNames: ['form-cloudinary'],
    // Cached selector of the hidden input
    input: Ember.computed(function () {
      return this.element.querySelector('input');
    }),

    /**
     * File upload progress handler.
     * Updates progress key of the current file.
     *
     * @param event
     * @param file
     * @param progress
     */
    uploadProgressHandler(event, file, progress) {
      Ember.set(file, 'progress', progress);
    },

    /**
     * Handle multiple files being uploaded to cloudinary.
     *
     * Responsibilities:
     * 1. Validate file size
     * 2. Take care of component loading state
     * 3. Map cloudinary responses to promenade image
     *
     * @param files
     * @returns {Promise<image[] | never>}
     */
    uploadFiles(files) {
      const {
        multiple,
        maxFileSize,
        onUpload,
        folder
      } = this;
      const {
        APP
      } = _environment.default;
      const appTag = `app:${APP.name}`;
      const filesArray = Array.from(files);
      const formData = {
        folder,
        tags: [appTag]
      }; // Instantiate cloudinary upload

      const cloudinaryConfig = (0, _cloudinaryUpload.getCloudinaryConfig)();
      const cloudinaryUpload = new _cloudinaryUpload.default(cloudinaryConfig); // Validate files are correct size

      filesArray.forEach(file => {
        if (file.size > maxFileSize) {
          this.notify.show('error', 'Maximum file size of 5Mb exceeded');
          throw new Error('Maximum file size exceeded');
        }
      }); // Start component loading state

      this.setProperties({
        isUploading: true,
        files: filesArray
      }); // Upload files and batch up the uploads, so we can change the loading state

      const promises = filesArray.map(file => {
        return this.uploadFile(cloudinaryUpload, file, formData, this.uploadProgressHandler);
      }); // When all uploads are finished, stop the component loading state

      return Ember.RSVP.all(promises).then(images => {
        // Map cloudinary objects to promenade object
        // and create ember objects for backwards compatibility
        // with old cloudinary-form component
        return images.map(_cloudinaryUpload.convertImageFromCloudinaryToPromenade).map(image => Ember.Object.create(image));
      }) // Inform the parent component that upload is completed
      .then(images => {
        return onUpload(!multiple ? images[0] : images);
      }).finally(() => {
        this.set('isUploading', false);
      });
    },

    /**
     * Upload a single resource to cloudinary
     *
     * @param cloudinaryUpload
     * @param file
     * @returns {Promise}
     */
    uploadFile(cloudinaryUpload, file, formData, progressHandler) {
      return cloudinaryUpload.upload(file, formData, progressHandler).then(res => {
        this.logger.info('File uploaded successfully:', res);
        return res;
      }).catch(err => {
        this.logger.error('File could not be uploaded:', err);
        throw err;
      });
    },

    actions: {
      openUploadDialog() {
        this.input.click();
      },

      handleFiles() {
        const files = this.input.files;
        return this.uploadFiles(files);
      },

      onDropzoneDragOver(event) {
        event.preventDefault();
      },

      onDropzoneDrop(event) {
        event.stopPropagation();
        event.preventDefault();
        const files = event.dataTransfer.files;
        return this.uploadFiles(files);
      }

    }
  });

  _exports.default = _default;
});