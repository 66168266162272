define("backalley/helpers/to-pence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toPence = toPence;
  _exports.default = void 0;

  function toPence(price) {
    if (Ember.isEmpty(price)) return '';
    return parseFloat(price / 100).toFixed(2);
  }

  var _default = Ember.Helper.helper(toPence);

  _exports.default = _default;
});