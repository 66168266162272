define("backalley/enums/shipment-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Object.freeze({
    BOOKED: 'booked',
    PENDING: 'pending',
    UNBOOKED: 'unbooked',
    FAILED: 'failed',
    EXPIRED: 'expired',
    MANUAL: 'manual',
    BOOK_PICKUP_ENQUEUED: 'book-pickup-enqueued',
    // set when shipment enters book_pickups queue
    BOOK_PICKUP_ERRORED: 'book-pickup-errored' // set when pickup booking fails an attempt

  });

  _exports.default = _default;
});