define("backalley/pods/main/frame-tags/index/route", ["exports", "backalley/models/frame-tag"], function (_exports, _frameTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MainFrameTagsIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        },
        sort: {
          refreshModel: true
        },
        name: {
          refreshModel: true
        }
      });
    }

    model({
      page,
      limit,
      sort,
      name
    }) {
      const query = {
        page,
        limit,
        sort
      };

      if (name) {
        query.name = {
          $regex: `^${name}`,
          $options: 'i'
        };
      }

      return _frameTag.default.find(query);
    }

  }

  _exports.default = MainFrameTagsIndexRoute;
});