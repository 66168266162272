define("backalley/pods/main/categories/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GWx5KlhH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"component\",[[28,\"hot-load\",[\"page-heading\",[23,0,[]],[24,[\"page-heading\"]],\"page-heading\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"fa\"],[[23,0,[]],\"page-heading\",[24,[\"page-heading\"]],false,true,\"sitemap\"]],{\"statements\":[[0,\"  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"main.categories\"]],{\"statements\":[[0,\"Categories\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"New category\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"page-title\",[\"New category\"],null],false],[0,\"\\n\\n\"],[1,[28,\"component\",[[28,\"hot-load\",[\"category-form\",[23,0,[]],[24,[\"category-form\"]],\"category-form\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"category\",\"tags\",\"search\",\"deleteTag\",\"select\",\"action\",\"onCancel\"],[[23,0,[]],\"category-form\",[24,[\"category-form\"]],false,true,[24,[\"model\",\"category\"]],[24,[\"model\",\"tags\"]],[24,[\"model\",\"actions\",\"searchTags\"]],[24,[\"model\",\"actions\",\"deleteTag\"]],[24,[\"model\",\"actions\",\"addTag\"]],[28,\"action\",[[23,0,[]],[24,[\"model\",\"actions\",\"create\"]],[24,[\"model\",\"category\"]]],null],[24,[\"model\",\"actions\",\"cancel\"]]]]],false],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/categories/new/template.hbs"
    }
  });

  _exports.default = _default;
});