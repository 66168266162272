define("backalley/mixins/product-build-query", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Mixin.create({
    page: 1,
    q_product: '',
    q_productId: '',
    q_syncRefId: '',
    q_syncRemoteId: '',
    q_syncIntegrationId: '',
    q_manufacturer: undefined,
    q_boutique: undefined,
    q_category: undefined,
    q_color: undefined,
    q_tag: undefined,
    new_products: false,
    published: 'null',
    featured: 'null',
    pending: 'null',
    unsuitable_state: 'null',
    product_notes_state: 'null',
    latest_products: false,
    sort: '-updated_at',
    feed: 'null',
    archived: 'null',
    stock: 'null',
    sales: 'null',
    boutique: 'null',
    not_tagged: false,
    no_category: false,
    from_feed: false,
    min_price: 0,
    max_price: 'null',
    q_gtin: 'null',
    can_be_published: 'null',
    task: null,
    getProductQueryParamsNames: getProductQueryParamsNames.bind(void 0),
    buildQuery: buildQuery.bind(void 0),
    getParams: getParams.bind(void 0)
  });

  _exports.default = _default;

  function getProductQueryParamsNames() {
    return ['page', 'q_product', 'q_productId', 'q_syncRefId', 'q_syncRemoteId', 'q_syncIntegrationId', 'q_boutique', 'q_manufacturer', 'q_category', 'q_color', 'q_tag', 'latest_products', 'sort', 'feed', 'archived', 'published', 'featured', 'pending', 'unsuitable_state', 'product_notes_state', 'new_products', 'stock', 'sales', 'boutique', 'not_tagged', 'from_feed', 'no_category', 'min_price', 'max_price', 'q_gtin', 'can_be_published', 'task'];
  }

  function buildQuery({
    min_price,
    max_price,
    q_gtin,
    q_product,
    q_productId,
    q_syncRefId,
    q_syncRemoteId,
    q_syncIntegrationId,
    featured,
    published,
    pending,
    unsuitable_state,
    product_notes_state,
    new_products,
    latest_products,
    feed,
    archived,
    sales,
    stock,
    not_tagged,
    from_feed,
    no_category,
    q_manufacturer,
    q_boutique,
    q_category = {},
    q_color = {},
    q_tag = {},
    page = 1,
    sort = {},
    can_be_published
  }, limit) {
    let boutiques = {};
    let stockQuery = {};
    let saleQuery = {};
    /**
     * Aggregator for top-level $or queries, prevents conflict with an $or that
     * gets inserted on promenade when archived is true. Push all $or queries into this array.
     */

    let $andQuery = [];

    if (stock === 'true') {
      stockQuery = {
        $or: [{
          boutique: q_boutique ? q_boutique.value : undefined,
          sizes: {
            $elemMatch: {
              stock: true
            }
          }
        }, {
          boutique: q_boutique ? q_boutique.value : undefined,
          $and: [{
            stock: true
          }, {
            sizes: {
              $size: 0
            }
          }]
        }]
      };
    } else if (stock === 'false') {
      stockQuery = {
        $or: [// Match variations
        {
          boutique: q_boutique ? q_boutique.value : undefined,
          sizes: {
            $gt: {
              $size: 0
            },
            $not: {
              $elemMatch: {
                stock: {
                  $ne: false
                }
              }
            }
          }
        }, // Match non variation products
        {
          boutique: q_boutique ? q_boutique.value : undefined,
          sizes: {
            $size: 0
          },
          stock: false
        }]
      };
    }

    if (sales === 'true') {
      saleQuery = {
        boutique: q_boutique ? q_boutique.value : undefined,
        sales: true
      };
    } else if (sales === 'false') {
      saleQuery = {
        boutique: q_boutique ? q_boutique.value : undefined,
        sales: false
      };
    }

    const hasStockQuery = Object.keys(stockQuery).length > 0;
    const hasSaleQuery = Object.keys(saleQuery).length > 0;

    if (hasStockQuery || hasSaleQuery) {
      boutiques = {
        $elemMatch: _objectSpread(_objectSpread({}, stockQuery), saleQuery)
      };
    }

    if (q_boutique) {
      boutiques.$elemMatch = _objectSpread(_objectSpread({}, boutiques.$elemMatch || {}), {}, {
        boutique: q_boutique.value
      });
    } // If both stock and sale are selected, remove boutiques.$elemMatch.boutique
    // so that stockQuery doesn't complain
    // TODO: Need to fix stock & sales combination


    if (hasStockQuery) {
      delete boutiques.$elemMatch.boutique;
    }

    let tags = {
      tags: q_tag.value
    };

    if (not_tagged) {
      $andQuery.push({
        $or: [{
          tags: {
            $exists: false
          }
        }, {
          tags: {
            $size: 0
          }
        }]
      });
    }

    let categories = q_category.value;

    if (no_category) {
      $andQuery.push({
        $or: [{
          categories: {
            $exists: false
          }
        }, {
          categories: {
            $size: 0
          }
        }]
      });
    }

    let sync_provider = undefined;

    if (feed === 'true') {
      sync_provider = 'feed';
    } else if (feed === 'false') {
      sync_provider = {
        $nin: ['feed']
      };
    }

    let unsuitable;
    let unsuitable_for_trouva;

    switch (unsuitable_state) {
      case 'null':
        unsuitable = undefined;
        unsuitable_for_trouva = undefined;
        break;

      case 'published_suitable':
        unsuitable = {
          $ne: 'true'
        };
        unsuitable_for_trouva = undefined;
        break;

      case 'published_unsuitable':
        unsuitable = 'true';
        unsuitable_for_trouva = undefined;
        break;

      case 'featured_suitable':
        unsuitable = undefined;
        unsuitable_for_trouva = {
          $ne: 'true'
        };
        break;

      case 'featured_unsuitable':
        unsuitable = undefined;
        unsuitable_for_trouva = 'true';
        break;
    }

    const gtinFilters = _defineGtinFilters(q_gtin, $andQuery);

    const productNotesCountsFilters = _defineProductNotesFilters(product_notes_state);

    const created_at = latest_products ? {
      $gte: (0, _moment.default)().subtract(30, 'days').startOf('day').toDate()
    } : undefined;
    const standard_manufacturer = q_manufacturer ? q_manufacturer.value : undefined;

    let query = _objectSpread(_objectSpread({
      archived: archived === 'null' ? undefined : archived,
      featured: featured === 'null' ? undefined : featured,
      published: published === 'null' ? undefined : published,
      pending: pending === 'null' ? undefined : pending,
      unsuitable,
      unsuitable_for_trouva,
      can_be_published: can_be_published === 'null' ? undefined : can_be_published,
      price: max_price !== 'null' ? {
        $gte: min_price,
        $lte: max_price
      } : undefined,
      colors: q_color.value,
      sync_provider,
      standard_manufacturer,
      categories,
      limit,
      page,
      sort,
      fields: 'name,_id,qs,thumbnail,published,featured,archived,pending,unsuitable,unsuitable_for_trouva,boutiques,sync_ref_id,sync_remote_id,created_at,manufacturer,standard_manufacturer,local_price',
      created_at
    }, productNotesCountsFilters), gtinFilters);

    const hasBoutiquesQuery = Object.keys(boutiques).length > 0;

    if (hasBoutiquesQuery) {
      query.boutiques = boutiques;
    }

    if (q_product) {
      query.$text = {
        $search: `${q_product}`,
        $language: 'en'
      };
    }

    if (q_productId) {
      query._id = q_productId;
    }

    if (q_syncIntegrationId) {
      query.sync_provider = 'feed';
      query.sync_integration_id = q_syncIntegrationId;
    }

    const searchBySyncIdEnabled = q_boutique || q_syncIntegrationId;

    if (q_syncRefId && searchBySyncIdEnabled) {
      query.sync_provider = 'feed';
      query.sync_ref_id = q_syncRefId;
    }

    if (q_syncRemoteId && searchBySyncIdEnabled) {
      query.sync_provider = 'feed';
      query.sync_remote_id = q_syncRemoteId;
    }

    if (from_feed) {
      query.exists = 'feed_ref_id';
    }

    if (!not_tagged) query = Object.assign({}, query, tags);

    if (no_category) {
      query = Object.assign({}, query, {
        sync_provider: 'feed',
        created_at: {
          $gte: (0, _moment.default)().subtract(30, 'days').startOf('day').toDate()
        }
      });
    }

    if ($andQuery.length) query = Object.assign({}, query, {
      $and: $andQuery
    });

    if (new_products) {
      query = _objectSpread(_objectSpread({}, query), {
        featured: false,
        published: false,
        archived: false,
        // Need this to support old products
        pending: {
          $ne: true
        },
        unsuitable: {
          $ne: true
        }
      });
    }

    return query;
  }

  function getParams(query = '') {
    if (!query) {
      return {};
    }

    query = query.replace(/.*\?/g, '');
    return query.split('&').reduce((params, param) => {
      let [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
  }

  function _defineProductNotesFilters(productNotesState) {
    let productNotesCountsFilters = {};

    switch (productNotesState) {
      case 'notes_to_resolve':
        productNotesCountsFilters = {
          'amendment_stats.pending': {
            '$gt': 0
          }
        };
        break;

      case 'no_notes_to_resolve':
        productNotesCountsFilters = {
          'amendment_stats.pending': 0
        };
        break;

      case 'only_notes_to_review':
        productNotesCountsFilters = {
          'amendment_stats.pending': 0,
          'amendment_stats.resolved': {
            '$gt': 0
          }
        };
        break;

      case 'no_notes_to_review_or_resolve':
        productNotesCountsFilters = {
          'amendment_stats.pending': 0,
          'amendment_stats.resolved': 0
        };
        break;
    }

    return productNotesCountsFilters;
  }

  function _defineGtinFilters(gtinQuery, $andQuery) {
    switch (gtinQuery) {
      case 'null':
        return {
          'gtin': undefined,
          'gtin_unavailable': undefined
        };

      case 'has_gtin':
        return {
          'gtin': {
            $nin: [null, '']
          }
        };

      case 'gtin_unavailable':
        return {
          'gtin_unavailable': true
        };

      case 'no_gtin_info':
        return {
          'gtin': {
            $in: [null, '']
          },
          'gtin_unavailable': null
        };

      case 'has_gtin_or_gtin_unavailable':
        // Special case where we need a conjuctive query
        $andQuery.push({
          $or: [{
            gtin: {
              $nin: [null, '']
            }
          }, {
            gtin_unavailable: true
          }]
        });
        break;
    }
  }
});