define("backalley/pods/components/radio-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aH+cxiIl",
    "block": "{\"symbols\":[\"@value\",\"@name\",\"@autofocus\",\"@disabled\",\"@required\",\"@tabindex\",\"&default\"],\"statements\":[[7,\"label\",true],[10,\"class\",\"radio-button__label\"],[8],[0,\"\\n  \"],[7,\"input\",false],[12,\"value\",[23,1,[]]],[12,\"name\",[23,2,[]]],[12,\"autofocus\",[23,3,[]]],[12,\"disabled\",[23,4,[]]],[12,\"required\",[23,5,[]]],[12,\"checked\",[23,0,[\"checked\"]]],[12,\"tabindex\",[23,6,[]]],[12,\"type\",\"radio\"],[3,\"on\",[\"change\",[23,0,[\"change\"]]]],[8],[9],[0,\"\\n  \"],[14,7],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/radio-button/template.hbs"
    }
  });

  _exports.default = _default;
});