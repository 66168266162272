define("backalley/models/publisherlink", ["exports", "backalley/mixins/request", "joi", "backalley/util/models"], function (_exports, _request, _joi, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const validations = {
    _id: _joi.default.string(),
    created_at: _joi.default.date(),
    publisher: _joi.default.string(),
    comment: _joi.default.string(),
    url: _joi.default.string().optional().allow(null),
    published: _joi.default.boolean(),
    product: _joi.default.string().allow(null),
    boutique: _joi.default.string().allow(null),
    manufacturer: _joi.default.string().allow(null),
    material: _joi.default.string().allow(null)
  };
  const Publisherlink = Ember.Object.extend(_request.default, {
    /**
     * Create new publisherlink
     * @return {Object} Promise
     */
    post() {
      return this.validate().then(publisherlink => {
        return this.request({
          method: 'POST',
          url: this.apiURL('publisherlinks'),
          data: JSON.stringify(publisherlink)
        });
      }).then(res => {
        const data = res.publisherlinks[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    /**
     * Save publisherlink
     * @return {Object} Promise
     */
    save() {
      return this.validate().then(publisherlink => {
        return this.request({
          method: 'PUT',
          url: this.apiURL(`publisherlinks/${publisherlink._id}`),
          data: JSON.stringify(publisherlink)
        });
      }).then(res => {
        const data = res.publisherlinks[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    validate() {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(Publisherlink.schema, data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    /**
     * Remove publisherlink
     * @return {Object} Promise
     */
    remove() {
      const publisherlink = this;
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`publisherlinks/${publisherlink._id}`)
      });
    }

  });
  Publisherlink.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  Publisherlink.reopenClass(_request.default, {
    schema: _joi.default.object().keys(validations),

    create(data = {}) {
      let c = this._super(data);

      c.setOriginalData(data);
      return c;
    },

    /**
     * Find colors
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.findWithMeta(data).then(res => res.publisherlinks);
    },

    /**
     * Find colors and returns meta too
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMeta(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('publisherlinks'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => {
        return {
          publisherlinks: res.publisherlinks.map(publisherlink => this.create(publisherlink)),
          meta: res.meta
        };
      });
    },

    /**
     * Find publisherlink by id
     * @param  {String} id publisherlink _id
     * @return {Object}    Promise
     */
    findById(id) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`publisherlinks/${id}?populate=material,manufacturer,product,boutique,publisher`),
        data: {
          plain: true
        }
      }).then(res => this.create(res.publisherlinks[0]));
    }

  });
  var _default = Publisherlink;
  _exports.default = _default;
});