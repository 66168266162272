define("backalley/pods/main/campaigns/locales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.languages = void 0;
  const languages = [{
    label: 'English',
    locale: 'en'
  }, {
    label: 'German',
    locale: 'de'
  }, {
    label: 'French',
    locale: 'fr'
  }, {
    label: 'Spanish',
    locale: 'es'
  }, {
    label: 'Italian',
    locale: 'it'
  }];
  _exports.languages = languages;
});