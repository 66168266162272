define("backalley/pods/components/feed-sync/component", ["exports", "backalley/models/sync-job", "backalley/util/default-to"], function (_exports, _syncJob, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    // Public
    disabled: false,
    isCoolingDown: false,
    onSyncTrigger: null,
    onErrorMessage: null,
    integrationId: (0, _defaultTo.default)(null),
    // The syncIntegrationId of the feed
    advancedSettings: null,
    // advanced settings to control the feed
    label: (0, _defaultTo.default)('Trigger feed'),
    // Button label
    loading: (0, _defaultTo.default)(false),
    // Loading state of button
    // Private
    notify: Ember.inject.service('notify'),

    calculateRealErrorMessage(exception, job = null) {
      if (!job || !job._meta) return exception.message;
      const meta = job._meta;
      const resp = meta.error && meta.error.responseJSON;

      if (meta.status === 'error' && resp && resp.errors) {
        return resp.errors.map(error => {
          return error.detail || error.title;
        }).join("\n");
      }

      return exception.message;
    },

    jobStarted(job) {
      // eslint-disable-line no-unused-vars
      if (this.isCoolingDown) {
        return;
      }

      this.set('loading', true);
      this.set('isCoolingDown', setTimeout(() => {
        this.set('isCoolingDown', false);
      }, 10 * 1000));

      if (this.onSyncTrigger) {
        this.onSyncTrigger();
      }
    },

    jobErrored(job, exception) {
      let notifyMessage = exception.message;

      if (notifyMessage === '500: Internal Server Error') {
        notifyMessage = 'Syncing currently not available';
      }

      if (notifyMessage === '422: Unprocessable Entity') {
        notifyMessage = 'Validation of sync request failed';
      }

      this.notify.show('danger', notifyMessage);
      this.set('loading', false);
      this.set('isCoolingDown', false);

      if (this.onErrorMessage) {
        this.onErrorMessage(this.calculateRealErrorMessage(exception, job));
      }
    },

    jobCreated(job) {
      // eslint-disable-line no-unused-vars
      this.notify.show('success', 'Fresh data coming in!');
      this.set('loading', false);
    },

    async triggerJob() {
      const job = _syncJob.default.create({
        provider: 'feed',
        type: 'reconcile',
        args: {},
        sync_integration: this.integrationId,
        onetime_behaviour_settings: this.advancedSettings || {}
      });

      job._events.started.add(this.jobStarted.bind(this));

      job._events.created.add(this.jobCreated.bind(this));

      job._events.errored.add(this.jobErrored.bind(this));

      return job.post();
    }

  }, (_applyDecoratedDescriptor(_obj, "triggerJob", [_dec], Object.getOwnPropertyDescriptor(_obj, "triggerJob"), _obj)), _obj)));

  _exports.default = _default;
});