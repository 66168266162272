define("backalley/pods/main/orders/edit/route", ["exports", "backalley/models/order", "backalley/models/event-store", "backalley/models/reservation", "backalley/models/reservation_boutique", "backalley/models/manual-adjustment", "backalley/models/shipment", "backalley/util/group-by"], function (_exports, _order, _eventStore, _reservation, _reservation_boutique, _manualAdjustment, _shipment, _groupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    router: Ember.inject.service(),

    model({
      order_id
    }) {
      return Ember.RSVP.hash({
        order: _order.default.findById(order_id, {
          populate: 'user'
        }),
        reservations: _reservation.default.find({
          order: order_id,
          populate: 'product,boutique,stock_line'
        }),
        shipments: _shipment.default.find({
          order: order_id,
          sort: '-created_at'
        }),
        adjustments: _manualAdjustment.default.findWithMeta({
          order: order_id
        }),
        adjustmentConfig: _manualAdjustment.default.getConfig(),
        eventsFired: _eventStore.default.findByEntityAndId({
          entity: 'order',
          entityId: order_id
        })
      }).then(({
        order,
        reservations,
        shipments,
        adjustments,
        adjustmentConfig,
        eventsFired
      }) => {
        if (order.isChannelMadeDotCom) {
          return this.router.transitionTo('main.made.orders.edit', order.channel_specific.order_reference);
        } // first map reservations to add useful modeling


        reservations.forEach(reservation => {
          // filter and attach relevant shipment records
          reservation.filterAndAssignShipments(shipments);
        }); // group reservations by boutique

        const reservationsGroupedByBoutique = (0, _groupBy.default)(reservations, 'boutique._id');
        const orderCreatedAt = order && new Date(order.created_at);
        const shippingVariantsStartedAt = new Date('2020-12-29T11:45:28.483Z');
        const reservationsGroupedByPath = orderCreatedAt >= shippingVariantsStartedAt ? Object.values((0, _groupBy.default)(reservations, 'shipping_variant._id')).filter(v => v) : []; // map to array

        let reservationBoutiques = Object.values(reservationsGroupedByBoutique).map(reservationsPerBoutique => {
          return _reservation_boutique.default.find(order_id, reservationsPerBoutique[0].boutique._id, reservationsPerBoutique);
        }); // promisify the array of reservation boutiques

        reservationBoutiques = Ember.RSVP.all(reservationBoutiques).then(reservationBoutiques => {
          // attach the shipment information
          reservationBoutiques.forEach(resBoutique => {
            resBoutique.filterAndAssignShipments(shipments);
          });
          return reservationBoutiques;
        });
        return Ember.RSVP.hash({
          order,
          reservations,
          reservationBoutiques,
          reservationsGroupedByPath,
          adjustments,
          adjustmentConfig,
          eventsFired
        });
      }).then(resources => {
        const {
          reservations,
          reservationBoutiques,
          reservationsGroupedByPath,
          adjustments,
          adjustmentConfig,
          order,
          eventsFired
        } = resources;
        return {
          order,
          reservations,
          reservationBoutiques,
          reservationsGroupedByPath,
          editing: Ember.Object.create(),
          adjustments,
          adjustmentConfig,
          zendeskTickets: [],
          eventsFired
        };
      });
    },

    async afterModel(model) {
      this._super(...arguments);

      const customerEmail = model.order.email;
      const {
        orders
      } = await _order.default.find({
        email: customerEmail,
        fields: '_id,status'
      });
      Ember.set(model, 'customerOrders', orders);
    }

  });

  _exports.default = _default;
});