define("backalley/enums/curate-order-reservation-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    CREATED: 'created',
    CONFIRMED: 'confirmed',
    CANCELLED: 'cancelled',
    RETURN_REQUESTED: 'return-requested',
    RETURNED: 'returned',
    SHIPPED: 'shipped',
    COMPLETED: 'completed'
  };
  _exports.default = _default;
});