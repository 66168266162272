define("backalley/pods/components/info-form/component", ["exports", "backalley/enums/language-type"], function (_exports, _languageType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    classNames: ['info-form'],
    page: null,
    selectableLanguages: _languageType.default.toKeyValue(),
    selectedLanguage: _languageType.default.toKeyValue().findBy('value', _languageType.default.ENGLISH),
    pageContent: Ember.computed('selectedLanguage', 'page.content_en', {
      get() {
        return Ember.get(this.page, `content_${this.selectedLanguage.value}`);
      },

      set(_, value) {
        let selectedLanguage = this.selectedLanguage.value;
        this.set(`page.content_${selectedLanguage}`, value);
        /*
         * "page.content" represents the fallback in case a certain translation
         * does not exist. Therefore, we're keeping it updated with the English
         * content.
         */

        if (selectedLanguage === _languageType.default.ENGLISH) {
          this.set('page.content', value);
        }

        return value;
      }

    }),

    onSave() {},

    onRemove() {}

  });

  _exports.default = _default;
});