define("backalley/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.slugify = slugify;

  function slugify(str) {
    return str.toLowerCase() // Replace trailing or leading whitespace
    .replace(/^\s+|\s+$/g, '') // Remove any bad chars
    .replace(/[^\w ]+/g, '') // Replace whitespace with -
    .replace(/ +/g, '-');
  }
});