define("backalley/pods/components/order-edit/zendesk-tickets/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u1gu5Xlb",
    "block": "{\"symbols\":[\"zendeskSearch\"],\"statements\":[[7,\"p\",true],[10,\"class\",\"box-view-heading\"],[8],[0,\"\\n  Zendesk Tickets\\n  \"],[7,\"a\",false],[12,\"tabindex\",\"-1\"],[12,\"target\",\"_blank\"],[3,\"action\",[[23,0,[]],[28,\"toggle\",[\"_expandSection\",[23,0,[]]],null]]],[8],[0,\"Create ticket\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"component\",[[28,\"hot-load\",[\"zendesk-search\",[23,0,[]],[24,[\"zendesk-search\"]],\"zendesk-search\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"extraTickets\",\"ordernumber\",\"notifySuccess\"],[[23,0,[]],\"zendesk-search\",[24,[\"zendesk-search\"]],false,true,[24,[\"zendeskTickets\"]],[24,[\"order\",\"ordernumber\"]],[28,\"action\",[[23,0,[]],\"notifySuccess\"],null]]],{\"statements\":[[4,\"if\",[[24,[\"_expandSection\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[28,\"hot-load\",[\"zendesk-create-ticket\",[23,0,[]],[24,[\"zendesk-create-ticket\"]],\"zendesk-create-ticket\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"order\",\"requesters\",\"products\",\"boutiques\",\"orderSummary\",\"ordernumber\",\"postCreate\"],[[23,0,[]],\"zendesk-create-ticket\",[24,[\"zendesk-create-ticket\"]],false,true,[24,[\"order\"]],[24,[\"zendeskRequesters\"]],[24,[\"zendeskProducts\"]],[24,[\"zendeskBoutiques\"]],[24,[\"orderSummary\"]],[24,[\"order\",\"ordernumber\"]],[28,\"pipe\",[[23,1,[\"refreshAndInsertTicket\"]],[28,\"toggle\",[\"_expandSection\",[23,0,[]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/order-edit/zendesk-tickets/template.hbs"
    }
  });

  _exports.default = _default;
});