define("backalley/pods/main/colors/index/controller", ["exports", "tracked-built-ins", "backalley/models/product"], function (_exports, _trackedBuiltIns, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MainColorsIndexController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class MainColorsIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _defineProperty(this, "queryParams", ['page', 'limit', 'sort', 'query']);

      _initializerDefineProperty(this, "page", _descriptor2, this);

      _initializerDefineProperty(this, "limit", _descriptor3, this);

      _initializerDefineProperty(this, "sort", _descriptor4, this);

      _initializerDefineProperty(this, "query", _descriptor5, this);

      _defineProperty(this, "productsCountCache", new _trackedBuiltIns.TrackedMap());

      _defineProperty(this, "sortOptions", [{
        label: 'Date: last updated',
        value: '-updated_at'
      }, {
        label: 'Date: first updated',
        value: 'updated_at'
      }, {
        label: 'Date: last created',
        value: '-created_at'
      }, {
        label: 'Date: first created',
        value: 'created_at'
      }, {
        label: 'Name: A-Z',
        value: 'label'
      }, {
        label: 'Name: Z-A',
        value: '-label'
      }]);
    }

    get selectedSort() {
      return this.sortOptions.find(({
        value
      }) => value === this.sort);
    }

    getProductsCount(color) {
      if (this.productsCountCache.has(color._id)) {
        return this.productsCountCache.get(color._id);
      }

      Ember.set(color, '_loadingProductCount', true);
      return _product.default.findWithMeta({
        colors: color._id,
        limit: 1,
        fields: '_id'
      }).then(({
        meta = {
          total: 0
        }
      }) => {
        this.productsCountCache.set(color._id, color);
        Ember.set(color, 'products', meta.total);
        Ember.set(color, '_loadedProductCount', true);
        return color;
      }).finally(() => {
        Ember.set(color, '_loadingProductCount', false);
      });
    }

    onChangeSort({
      value
    }) {
      return this.router.transitionTo({
        queryParams: {
          page: 1,
          sort: value
        }
      });
    }

    onChangeQuery(query) {
      return this.router.transitionTo({
        queryParams: {
          page: 1,
          query: Ember.isPresent(query) ? query : undefined
        }
      });
    }

    archive(color) {
      if (!confirm(`Are you sure you want to delete the color: ${color.label}`)) {
        return;
      }

      return color.remove().then(() => {
        this.model.colors.removeObject(color);
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 24;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sort", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '-updated_at';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "query", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getProductsCount", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "getProductsCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeSort", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeSort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeQuery", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "archive", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "archive"), _class.prototype)), _class));
  _exports.default = MainColorsIndexController;
});