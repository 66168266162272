define("backalley/mirage/factories/landingjson", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    This is an example factory definition.
  
    Create more files in this directory to define additional factories.
  */
  var _default = _emberCliMirage.default.Factory.extend({
    name: 'Trouva, the new brand from StreetHub',
    origid: id => `${id + 1}`,
    default: false,
    itemArr: [{
      title: '',
      class: 'row-50-1',
      height: 50,
      length: 1,
      itemArr: [{
        type: 'box',
        content: {
          desktop: '',
          mobile: '',
          type: 'pages',
          title: '',
          link_to_title: 'our-story'
        },
        class: 'col-50-1',
        height: 50,
        flex: 1,
        selected: true
      }]
    }]
  });

  _exports.default = _default;
});