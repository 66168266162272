define("backalley/pods/components/form-submit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R0T7Z2rk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"dangerAction\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",[29,[\"button button-danger \",[28,\"if\",[[24,[\"loading\"]],\"loading\",\"\"],null]]]],[3,\"action\",[[23,0,[]],\"action\",[24,[\"dangerAction\"]]]],[8],[0,\"\\n    \"],[1,[22,\"danger\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",[29,[\"button button-primary \",[28,\"if\",[[24,[\"disabled\"]],\"button-disabled\",\"\"],null],\" \",[28,\"if\",[[24,[\"loading\"]],\"loading\",\"\"],null],\" \",[22,\"customClass\"]]]],[12,\"title\",[22,\"title\"]],[3,\"action\",[[23,0,[]],\"action\",[24,[\"action\"]]]],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"secondaryAction\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",[29,[\"button button-secondary \",[28,\"if\",[[24,[\"loading\"]],\"loading\",\"\"],null]]]],[3,\"action\",[[23,0,[]],\"action\",[24,[\"secondaryAction\"]]]],[8],[0,\"\\n    \"],[1,[22,\"secondary\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/form-submit/template.hbs"
    }
  });

  _exports.default = _default;
});