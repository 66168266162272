define("backalley/models/boutique-restock-information", ["exports", "joi", "backalley/util/request", "backalley/util/models", "backalley/enums/boutique-restock-information-status", "backalley/enums/boutique-restock-information-reason", "backalley/enums/boutique-restock-information-report"], function (_exports, _joi, _request, _models, _boutiqueRestockInformationStatus, _boutiqueRestockInformationReason, _boutiqueRestockInformationReport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _class2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const commonValidations = {
    boutique: _models.resourceRef,
    product: _models.resourceRef,
    stock_line: _models.resourceRef,
    status: _joi.default.string().valid(...Object.values(_boutiqueRestockInformationStatus.default)),
    report: _joi.default.string().valid(...Object.values(_boutiqueRestockInformationReport.default)),
    quantity: _joi.default.number().min(1).allow(null),
    lead_date: _joi.default.date().allow(null),
    reason: _joi.default.object({
      type: _joi.default.string().valid(...Object.values(_boutiqueRestockInformationReason.default)),
      notes: _joi.default.string().allow('')
    }),
    notes: _joi.default.string().allow('')
  };

  const createValidations = _objectSpread(_objectSpread({}, commonValidations), {}, {
    boutique: _models.resourceRef.required(),
    product: _models.resourceRef.required(),
    stock_line: _models.resourceRef.required()
  });

  const updateValidations = _objectSpread({}, commonValidations);

  let BoutiqueRestockInformation = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, (_class = (_temp = _class2 = class BoutiqueRestockInformation {
    constructor({
      _id = null,
      boutique,
      product,
      stock_line,
      status = _boutiqueRestockInformationStatus.default.RESTOCKING,
      report,
      quantity,
      lead_date,
      notes,
      reason = {},
      updated_at
    } = {}) {
      _initializerDefineProperty(this, "boutique", _descriptor, this);

      _initializerDefineProperty(this, "product", _descriptor2, this);

      _initializerDefineProperty(this, "stock_line", _descriptor3, this);

      _initializerDefineProperty(this, "status", _descriptor4, this);

      _initializerDefineProperty(this, "report", _descriptor5, this);

      _initializerDefineProperty(this, "quantity", _descriptor6, this);

      _initializerDefineProperty(this, "reason", _descriptor7, this);

      _initializerDefineProperty(this, "lead_date", _descriptor8, this);

      _initializerDefineProperty(this, "notes", _descriptor9, this);

      this._id = _id;
      this.boutique = boutique;
      this.product = product;
      this.stock_line = stock_line;
      this.status = status;
      this.report = report;
      this.reason = reason;
      this.quantity = quantity;
      this.lead_date = lead_date;
      this.notes = notes;
      this.updated_at = updated_at;
    }

    get isRestocked() {
      return this.status === _boutiqueRestockInformationStatus.default.RESTOCKED;
    }

    get isNotAvailable() {
      return this.status === _boutiqueRestockInformationStatus.default.NOT_AVAILABLE;
    }

    async create() {
      const data = Ember.getProperties(this, Object.keys(createValidations));
      await (0, _models.validateSchema)(BoutiqueRestockInformation.createSchema, data, {
        abortEarly: false
      });

      try {
        return await (0, _request.request)({
          method: 'POST',
          url: (0, _request.apiURL)('boutique_restock_information'),
          data: JSON.stringify(data)
        });
      } catch (error) {
        (0, _request.throwError)(error);
      }
    }

    static async find(params = {}) {
      try {
        const {
          boutique_restock_information,
          meta
        } = await (0, _request.request)({
          method: 'GET',
          url: (0, _request.apiURL)('boutique_restock_information'),
          data: _objectSpread(_objectSpread({}, params), {}, {
            skip_links: true
          })
        });
        return {
          boutiqueRestockInformation: boutique_restock_information.map(bri => new BoutiqueRestockInformation(bri)),
          meta
        };
      } catch (error) {
        (0, _request.throwError)(error);
      }
    }

    static async findById(id) {
      const {
        boutiqueRestockInformation
      } = await this.find({
        _id: id
      });
      return boutiqueRestockInformation[0];
    }

    async update() {
      const data = Ember.getProperties(this, Object.keys(updateValidations));
      await (0, _models.validateSchema)(BoutiqueRestockInformation.updateSchema, data);

      try {
        return await (0, _request.request)({
          method: 'PUT',
          url: (0, _request.apiURL)(`boutique_restock_information/${this._id}`),
          data: JSON.stringify(data)
        });
      } catch (error) {
        (0, _request.throwError)(error);
      }
    }

  }, _defineProperty(_class2, "createSchema", _joi.default.object().keys(_objectSpread({}, createValidations))), _defineProperty(_class2, "updateSchema", _joi.default.object().keys(_objectSpread({}, updateValidations))), _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "boutique", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "product", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stock_line", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "report", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "quantity", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "reason", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "lead_date", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "notes", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BoutiqueRestockInformation;
});