define("backalley/services/hot-loader", ["exports", "ember-ast-hot-load/services/hot-loader"], function (_exports, _hotLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _hotLoader.default;
    }
  });
});