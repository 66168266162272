define("backalley/pods/main/orders/options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.availableHandlingModes = _exports.availableDiscountTypes = _exports.availableReservationStatusWhileCreating = _exports.availableReservationStatus = void 0;
  let availableReservationStatus = [{
    label: 'Active',
    value: 'active'
  }, {
    label: 'Success',
    value: 'success'
  }, {
    label: 'Fail (Out of stock)',
    value: 'fail'
  }, {
    label: 'Fail (Customer Cancelled)',
    value: 'fail cancelled'
  }, {
    label: 'Fail Returned',
    value: 'fail returned'
  }, {
    label: 'Fail Other',
    value: 'fail other'
  }];
  _exports.availableReservationStatus = availableReservationStatus;
  let availableReservationStatusWhileCreating = [{
    label: 'Creating',
    value: 'creating'
  }, {
    label: 'Fail Payment',
    value: 'fail payment'
  }, ...availableReservationStatus];
  _exports.availableReservationStatusWhileCreating = availableReservationStatusWhileCreating;
  let availableDiscountTypes = [{
    label: 'Percentage',
    value: 'percentage'
  }, {
    label: 'Amount',
    value: 'amount'
  }];
  _exports.availableDiscountTypes = availableDiscountTypes;
  let availableHandlingModes = [{
    label: 'Manual',
    value: true
  }, {
    label: 'Automatic',
    value: false
  }];
  _exports.availableHandlingModes = availableHandlingModes;
});