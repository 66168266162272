define("backalley/pods/main/feeds/index/route", ["exports", "backalley/models/sync-integration", "backalley/mixins/loading"], function (_exports, _syncIntegration, _loading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_loading.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      boutique: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      }
    },

    async model({
      boutique = undefined,
      status = undefined,
      page = 1,
      limit = 30,
      sort = {}
    }) {
      const query = {
        page,
        limit,
        sort: sort.value,
        provider: 'feed',
        populate: 'settings.boutique'
      };

      if (boutique) {
        query['settings.boutique'] = boutique;
      }

      if (status) {
        query.status = status;
      }

      const {
        syncIntegrations,
        meta = {
          total: 0
        }
      } = await _syncIntegration.default.findWithMeta(query);
      return {
        syncIntegrations,
        boutique,
        status,
        meta,
        sort,
        user: this.session.user
      };
    },

    actions: {
      nextPage() {
        const page = this.get('controller.page');
        if (page < this.get('currentModel.meta.total')) this.transitionTo({
          queryParams: {
            page: Number(page) + 1
          }
        });
      },

      prevPage() {
        const page = this.get('controller.page');
        if (page > 1) this.transitionTo({
          queryParams: {
            page: Number(page) - 1
          }
        });
      },

      goToPage(page) {
        this.transitionTo({
          queryParams: {
            page
          }
        });
      }

    }
  });

  _exports.default = _default;
});