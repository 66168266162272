define("backalley/pods/components/order-edit/customer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function NOOP() {}

  var _default = Ember.Component.extend({
    // Public API
    order: null,
    editingToggles: null,
    customerOrders: null,
    classNames: ['box-view', 'box-view-general'],
    onEditField: NOOP,
    onSave: NOOP,
    ometriaURL: Ember.computed('order.email', function () {
      const {
        email
      } = this.order;
      const url = ['https://app.ometria.com/app/23e5c9f4e06d0b8e/segments?state=', // Filter the contact list to only show this customer
      encodeURIComponent(`{"reportOffset":0,"query":{"filters":[{"value":["${email}"],"op":"eq","field":"email"}],"group":"all","query_type":"and"}}`), // Opens the Ometria dashboard to the second tab that shows the contact
      '#1'].join('');
      return url;
    }),
    customerOrderStats: Ember.computed('customerOrders.@each._id', function () {
      const customerOrders = this.customerOrders || [];
      return {
        active: customerOrders.filter(o => o.status === 'active').length.toString(),
        problems: customerOrders.filter(o => o.status !== 'active' && o.status !== 'success').length.toString(),
        total: customerOrders.length.toString()
      };
    })
  });

  _exports.default = _default;
});