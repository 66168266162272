define("backalley/pods/components/product-notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/dKAYMTq",
    "block": "{\"symbols\":[\"notes\"],\"statements\":[[4,\"component\",[[28,\"hot-load\",[\"basic-notes\",[23,0,[]],[24,[\"basic-notes\"]],\"basic-notes\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"title\",\"resource\",\"item\"],[[23,0,[]],\"basic-notes\",[24,[\"basic-notes\"]],false,true,\"Notes\",[24,[\"resource\"]],[24,[\"item\"]]]],{\"statements\":[[0,\"  \"],[7,\"h1\",true],[8],[0,\"Internal Notes\"],[9],[0,\"\\n  \"],[1,[23,1,[\"list\"]],false],[0,\"\\n  \"],[1,[23,1,[\"new\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/product-notes/template.hbs"
    }
  });

  _exports.default = _default;
});