define("backalley/pods/main/shipment-rates/index/controller", ["exports", "backalley/models/shipment-rate"], function (_exports, _shipmentRate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend({
    country: Ember.inject.service(),
    queryParams: ['page', 'source', 'upsZone', 'weight'],
    page: 1,
    isCalculateShippingRateOpen: false,
    countries: Ember.computed.readOnly('country.countries'),
    selectedSource: Ember.computed('source', 'countries.[]', function () {
      return this.countries.findBy('iso_code', this.source);
    }),

    init() {
      this._super(...arguments);

      this.set('calculation', {
        width: null,
        height: null,
        length: null,
        weight: null,
        source: null,
        destination: null
      });
    },

    calculateShippingRate: Ember._action(function (e) {
      e.preventDefault();
      return _shipmentRate.default.find(_objectSpread(_objectSpread({}, this.calculation), {}, {
        source: this.calculation.source.iso_code,
        destination: this.calculation.destination.iso_code
      })).then(({
        shipmentRates
      }) => this.set('calculationResult', shipmentRates[0])).catch(error => this.notify.show('error', error));
    }),
    onChangeSource: Ember._action(function (country) {
      this.setProperties({
        source: country ? country.iso_code : null,
        page: 1
      });
    }),
    onChangeUpsZone: Ember._action(function (upsZone) {
      this.setProperties({
        upsZone,
        page: 1
      });
    }),
    onChangeWeight: Ember._action(function (weight) {
      this.setProperties({
        weight,
        page: 1
      });
    }),
    onFinishImportCsv: Ember._action(function () {
      this.notify.show('success', 'Upload of the CSV completed ✅');
    })
  });

  _exports.default = _default;
});