define("backalley/pods/main/made/orders/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MainMadeOrdersRoute extends Ember.Route {}

  _exports.default = MainMadeOrdersRoute;
});