define("backalley/pods/main/zones/index/route", ["exports", "backalley/models/zone", "backalley/mixins/loading", "backalley/util/escape-regex"], function (_exports, _zone, _loading, _escapeRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_loading.default, {
    availableFilters: [{
      label: 'Name: A-Z',
      value: 'name'
    }, {
      label: 'Name: Z-A',
      value: '-name'
    }],
    queryParams: {
      q: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },

    serializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      // don't show objects in url
      if (urlKey === 'sort') return value.value;
      if (urlKey === 'page') return Number(value);
      return value;
    },

    deserializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      if (urlKey === 'sort') return this.availableFilters.findBy('value', value);
      return value;
    },

    model({
      q,
      page = 1,
      limit = 30,
      sort = {}
    }) {
      return _zone.default.findWithMeta({
        name: {
          $regex: `^${(0, _escapeRegex.default)(q)}`,
          $options: 'i'
        },
        page,
        limit,
        sort: sort.value
      }).then(({
        zones,
        meta
      }) => {
        return {
          zones,
          sort,
          availableFilters: this.availableFilters,
          search: (0, _escapeRegex.default)(q),
          meta
        };
      });
    },

    actions: {
      updateSort(sort) {
        this.set('controller.sort', sort);
      },

      updateSearch(query) {
        this.set('controller.q', query);
        this.set('controller.page', 1);
      },

      remove(zone) {
        if (confirm(`Are you sure you want to delete the "${zone.name}" zone?`)) {
          return zone.remove().then(() => {
            this.get('currentModel.zones').removeObject(zone);
          });
        }
      },

      nextPage() {
        const page = this.get('controller.page');
        if (page < this.get('currentModel.meta.total')) this.transitionTo({
          queryParams: {
            page: Number(page) + 1
          }
        });
      },

      prevPage() {
        const page = this.get('controller.page');
        if (page > 1) this.transitionTo({
          queryParams: {
            page: Number(page) - 1
          }
        });
      },

      goToPage(page) {
        this.transitionTo({
          queryParams: {
            page
          }
        });
      }

    }
  });

  _exports.default = _default;
});