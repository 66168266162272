define("backalley/enums/discount-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    AMOUNT: 'amount',
    PERCENTAGE: 'percentage',
    FREE_SHIPPING: 'free_shipping'
  };
  _exports.default = _default;
});