define("backalley/pods/main/manual-adjustments/import/route", ["exports", "backalley/models/manual-adjustment", "moment"], function (_exports, _manualAdjustment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    model() {
      return _manualAdjustment.default.getConfig().then(() => {
        return {
          ADJUSTMENT_TYPES: _manualAdjustment.default.config.ADJUSTMENT_TYPES,
          errors: [],
          success: []
        };
      });
    },

    mapToAmountWithCurrency(data) {
      const {
        amount,
        currency
      } = data;
      data.request_amount = {
        amount: Math.round(Number(amount) * 100),
        currency
      };
      delete data.amount;
      delete data.currency;
      return data;
    },

    mapToZendeskTicketsIds(data) {
      if (data.zendesk_ticket_ids) {
        data.zendesk_ticket_ids = data.zendesk_ticket_ids.split('-');
      }

      return data;
    },

    /**
     * Imports the ManualAdjustments from the CSV
     *
     * Depending on request:
     * - If fails updates errors[]
     * - If success update success[]
     * @param {Array} manualAdjustments
     */
    async importManualAdjustments(manualAdjustments) {
      for (let adjustmentData of manualAdjustments) {
        let resultsKey = 'currentModel.success';
        adjustmentData = this.mapToAmountWithCurrency(adjustmentData);
        adjustmentData = this.mapToZendeskTicketsIds(adjustmentData);
        adjustmentData.date = _moment.default.utc(adjustmentData.date, 'DD-MM-YYYY').toDate();

        const adjustment = _manualAdjustment.default.create(adjustmentData);

        try {
          await adjustment.post();
        } catch (err) {
          resultsKey = 'currentModel.errors';
          adjustment.errors = err.responseJSON.errors;
        }

        this.get(resultsKey).addObject(adjustment);
      }
    },

    resetPreviousResults() {
      Ember.setProperties(this.currentModel, {
        errors: [],
        success: []
      });
    },

    actions: {
      onCSVImportFail(err) {
        this.notify.show('warning', err);
      },

      onCSVImportCompleted({
        data
      }) {
        this.resetPreviousResults();
        return this.importManualAdjustments(data);
      }

    }
  });

  _exports.default = _default;
});