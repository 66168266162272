define("backalley/helpers/str-anonymize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.strAnonymize = strAnonymize;
  _exports.default = void 0;

  function strAnonymize([str = '', len]) {
    let res = str.substr(len);
    let anonymization = str.substr(0, len).replace(/./g, '*');
    return `${anonymization}${res}`;
  }

  var _default = Ember.Helper.helper(strAnonymize);

  _exports.default = _default;
});