define("backalley/models/packaging/category", ["exports", "joi", "backalley/util/request", "backalley/util/models"], function (_exports, _joi, _request, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const validations = {
    name: _joi.default.string()
  };

  class PackagingCategory {
    constructor({
      _id = null,
      name = '',
      slug
    } = {}) {
      this.id = _id;
      this.name = name;
      this.slug = slug;
    }

    create() {
      const data = (0, _models.getProperties)(this, Object.keys(validations));
      return (0, _models.validateSchema)(PackagingCategory.schema, data).then(() => {
        return (0, _request.request)({
          method: 'POST',
          url: (0, _request.packagingApiURL)('categories'),
          data: JSON.stringify(data)
        }).catch(err => {
          throw err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
        });
      });
    }

    static find(params = {}) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.packagingApiURL)('categories'),
        data: _objectSpread({}, params)
      }).then(({
        categories,
        meta
      }) => {
        return {
          categories: categories.map(category => new PackagingCategory(category)),
          meta
        };
      }).catch(err => {
        throw err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
      });
    }

    static findById(id) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.packagingApiURL)(`categories/${id}`)
      }).then(({
        categories
      }) => {
        let [category] = categories;
        return Ember.isPresent(category) ? new PackagingCategory(category) : null;
      }).catch(err => {
        throw err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
      });
    }

    update() {
      const data = (0, _models.getProperties)(this, Object.keys(validations));
      return (0, _models.validateSchema)(PackagingCategory.schema, data).then(() => {
        return (0, _request.request)({
          method: 'PUT',
          url: (0, _request.packagingApiURL)(`categories/${this.id}`),
          data: JSON.stringify(data)
        }).catch(err => {
          throw err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
        });
      });
    }

  }

  _exports.default = PackagingCategory;

  _defineProperty(PackagingCategory, "schema", _joi.default.object().keys(_objectSpread({}, validations)));
});