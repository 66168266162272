define("backalley/pods/components/notion-help/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ullNnuCv",
    "block": "{\"symbols\":[\"HotLoadFaIcon2aj9ubxcy7r\"],\"statements\":[[7,\"a\",true],[10,\"target\",\"_blank\"],[11,\"href\",[22,\"url\"]],[10,\"rel\",\"noopener\"],[8],[0,\"\\n  \"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"FaIcon\",[23,0,[]],\"FaIcon\",\"FaIcon\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"FaIcon\",\"FaIcon\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"external-link-alt\"]]]],\"parameters\":[1]},null],[0,\"\\n  Need help?\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/notion-help/template.hbs"
    }
  });

  _exports.default = _default;
});