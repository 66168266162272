define("backalley/pods/main/shipping-group-configurations/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ShippingGroupConfigurationsRoute extends Ember.Route {}

  _exports.default = ShippingGroupConfigurationsRoute;
});