define("backalley/pods/main/index/route", ["exports", "backalley/mixins/loading"], function (_exports, _loading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GREETINGS = ['🇬🇧 Hello', '🇬🇧 Welcome', '🇫🇷 Bonjour', '🇩🇪 Guten tag', '🇸🇪 Godagg', '🇪🇸 Buenos dias', '🇫🇮 Hei', '🇳🇱 Halo', '🇵🇹 Bom dia', '🇨🇿 Ahoj', '🇮🇹 Buongiorno', '🇬🇷 γειά σου'];
  const MAX = GREETINGS.length;
  const RANDOM = Math.floor(Math.random() * MAX);

  var _default = Ember.Route.extend(_loading.default, {
    model() {
      return {
        greeting: GREETINGS[RANDOM],
        user: this.session.user,
        loading: false
      };
    }

  });

  _exports.default = _default;
});