define("backalley/pods/components/form-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EUr5E64o",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"error\"],[8],[1,[22,\"error\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"addon\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"addon\"],[8],[1,[22,\"addon\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"readonly\"]]],null,{\"statements\":[[0,\"  \"],[7,\"input\",true],[11,\"class\",[22,\"inputClass\"]],[11,\"disabled\",[22,\"disabled\"]],[11,\"maxlength\",[22,\"maxlength\"]],[11,\"value\",[22,\"value\"]],[11,\"autofocus\",[22,\"autofocus\"]],[11,\"placeholder\",[22,\"placeholder\"]],[10,\"readonly\",\"true\"],[11,\"required\",[22,\"required\"]],[11,\"oninput\",[28,\"action\",[[23,0,[]],\"_onInput\"],null]],[11,\"onblur\",[28,\"action\",[[23,0,[]],\"blur\"],null]],[11,\"onkeypress\",[28,\"action\",[[23,0,[]],\"keypress\"],null]],[11,\"type\",[22,\"type\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"input\",true],[11,\"class\",[22,\"inputClass\"]],[11,\"disabled\",[22,\"disabled\"]],[11,\"maxlength\",[22,\"maxlength\"]],[11,\"value\",[22,\"value\"]],[11,\"autofocus\",[22,\"autofocus\"]],[11,\"placeholder\",[22,\"placeholder\"]],[11,\"required\",[22,\"required\"]],[11,\"oninput\",[28,\"action\",[[23,0,[]],\"_onInput\"],null]],[11,\"onblur\",[28,\"action\",[[23,0,[]],\"blur\"],null]],[11,\"onkeypress\",[28,\"action\",[[23,0,[]],\"keypress\"],null]],[11,\"type\",[22,\"type\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/form-text/template.hbs"
    }
  });

  _exports.default = _default;
});