define("backalley/pods/components/basic-editable/component", ["exports", "backalley/util/default-to"], function (_exports, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public API
    edit: (0, _defaultTo.default)(false),
    autoformatEnabled: (0, _defaultTo.default)(false),
    value: (0, _defaultTo.default)(''),

    onupdate() {},

    // Private API
    tagName: '',
    _value: Ember.computed('value', {
      get() {
        return Ember.get(this, 'value');
      },

      set(_, newVal) {
        return newVal !== undefined ? newVal : '';
      }

    }),

    init() {
      this._super();

      Ember.set(this, '_value', Ember.get(this, 'value'));
    },

    classNames: ['basic-editable'],
    initialValue: Ember.computed('edit', function () {
      if (Ember.get(this, 'edit') === true) return Ember.get(this, 'value');
      return Ember.get(this, '_value');
    }),

    _sendAction(path, ...args) {
      const action = Ember.get(this, path);
      if (typeof action === 'function') return action(...args);
      if (typeof action === 'string') return this.sendAction(path, ...args);
    },

    actions: {
      save(value) {
        this._sendAction('onupdate', value);

        Ember.set(this, 'edit', false);
      },

      cancel() {
        const value = Ember.get(this, 'value');
        Ember.setProperties(this, {
          _value: value,
          edit: false
        });
      },

      _autoformat() {
        const value = Ember.get(this, '_value');
        const titleCase = value.toLowerCase().split(' ').map(i => i[0].toUpperCase() + i.substring(1)).join(' ');
        Ember.set(this, '_value', titleCase);
      },

      toggleEdit() {
        this.toggleProperty('edit');
      },

      _onInput(e) {
        Ember.set(this, '_value', Ember.get(e, 'target.value'));
      }

    }
  });

  _exports.default = _default;
});