define("backalley/pods/components/order-audit-log/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ubfHaNHD",
    "block": "{\"symbols\":[\"logItem\",\"val\",\"key\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"_auditLog\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"list-event\"],[8],[0,\"\\n    Updated\\n    \"],[7,\"p\",true],[10,\"class\",\"list-event-author\"],[8],[1,[23,1,[\"updatedBy\"]],false],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"list-event-date\"],[8],[1,[28,\"moment-format\",[[23,1,[\"updatedAt\"]],\"dddd D MMM YYYY [@] HH:mm\"],null],false],[9],[0,\"\\n\"],[4,\"each\",[[28,\"-each-in\",[[23,1,[\"updatedFields\"]]],null]],null,{\"statements\":[[4,\"unless\",[[28,\"or\",[[28,\"eq\",[[23,3,[]],\"_id\"],null],[28,\"eq\",[[23,3,[]],\"_ver\"],null]],null]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,3,[]],\"comments\"],null]],null,{\"statements\":[[0,\"          \"],[7,\"p\",true],[10,\"class\",\"list-event-content\"],[8],[0,\"Commented: \"],[7,\"span\",true],[10,\"class\",\"comment\"],[8],[1,[23,2,[]],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"p\",true],[10,\"class\",\"list-event-content\"],[8],[1,[23,3,[]],false],[0,\": \"],[1,[23,2,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[2,3]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"empty\"],[8],[1,[28,\"if\",[[24,[\"_loading\"]],\"Loading..\",\"No events yet\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[14,4],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/order-audit-log/template.hbs"
    }
  });

  _exports.default = _default;
});