define("backalley/pods/main/boutique-restock-information/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MainBoutiqueRestockInformationRoute extends Ember.Route {}

  _exports.default = MainBoutiqueRestockInformationRoute;
});