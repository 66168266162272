define("backalley/pods/main/made/invoices/controller", ["exports", "ember-concurrency", "ember-concurrency-decorators", "backalley/models/boutique", "backalley/enums/statement-invoice-type"], function (_exports, _emberConcurrency, _emberConcurrencyDecorators, _boutique, _statementInvoiceType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MainMadeInvoicesController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class MainMadeInvoicesController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "type", _descriptor, this);

      _initializerDefineProperty(this, "boutique", _descriptor2, this);

      _initializerDefineProperty(this, "page", _descriptor3, this);

      _defineProperty(this, "queryParams", ['type', 'boutique', 'page', 'limit']);

      _defineProperty(this, "limit", 48);

      _defineProperty(this, "typeOptions", [{
        label: "Boutique to MADE",
        value: _statementInvoiceType.default.BOUTIQUE_TO_MADE
      }, {
        label: "MADE to Trouva",
        value: _statementInvoiceType.default.MADE_TO_TROUVA
      }, {
        label: "Trouva to MADE",
        value: _statementInvoiceType.default.TROUVA_TO_MADE
      }]);
    }

    get selectedType() {
      return this.typeOptions.find(({
        value
      }) => value === this.type);
    }

    get selectedBoutique() {
      return this.boutique ? _boutique.default.findById(this.boutique) : null;
    }

    *searchBoutique(query) {
      yield (0, _emberConcurrency.timeout)(500);
      return _boutique.default.find({
        name: {
          $regex: `^${query.trim()}`,
          $options: 'i'
        }
      });
    }

    onChangeType({
      value
    }) {
      this.type = value;
      this.boutique = undefined;
      this.page = 1;
    }

    onChangeBoutique(boutique) {
      this.boutique = Ember.isPresent(boutique) ? boutique === null || boutique === void 0 ? void 0 : boutique._id : undefined;
      this.page = 1;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "type", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Object.values(_statementInvoiceType.default)[0];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "boutique", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "searchBoutique", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchBoutique"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeType", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeBoutique", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeBoutique"), _class.prototype)), _class));
  _exports.default = MainMadeInvoicesController;
});