define("backalley/models/landing", ["exports", "backalley/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let landing = null;
  const Landing = Ember.Object.extend(_request.default, {
    save() {
      let page = this;
      const origId = this.origid;
      page = Landing.transformLandingPage(page);
      page = Landing.updateTagsOnImages(page); // Temporary validation

      if (!page.name || page.name === '' || typeof page.id === 'undefined' || typeof page.id === 'null' || page.id === '') {
        return Ember.RSVP.reject('This page needs a name & an id yo.');
      }

      if (!page.description) {
        return Ember.RSVP.reject('The page description is mandatory!');
      } // load fresh to update the landing cache


      return Landing.find().then(() => {
        // If default true, make sure all others are updated as well
        if (page.default) {
          landing.pages.forEach(page => page.default = false);
        } // Edit or create this is the question


        const index = landing.pages.findIndex(p => p.origid === origId); // Count how many landing pages have the same unique identifier.

        const landingPagesWithSameId = landing.pages.filter(l => l.id === page.id); // Update page

        if (Ember.isPresent(origId) && index > -1) {
          // If there is another landing page with the same page identifier, don't save.
          if (landingPagesWithSameId.length && !landingPagesWithSameId.includes(landing.pages[index])) {
            throw 'Page identifier is not unique. Make sure the it is unique before saving.';
          } // If we're editing a page replace


          landing.pages[index] = page; // Create new page
        } else {
          // If there is another landing page with the same identifier, don't save.
          if (landingPagesWithSameId.length) {
            throw 'Page identifier is not unique. Make sure the it is unique before saving.';
          }

          page.origid = page.id;
          landing.pages.push(page); // Else push in the new page
        } // Sanitizing (removing all the stupid ember stuff from the object)


        const sanitized = JSON.parse(JSON.stringify(landing)); // If this page is set as the default, update the homepage.json

        if (page.default) Landing.saveHomepage(page); // Update individual landing pages

        return Landing.saveIndividual(page) // Update the landing.json file
        .then(() => Landing.save(sanitized));
      });
    },

    remove() {
      let page = this;
      let selectedPageIndex = page.get('id'); // If there's no index, there's nothing to delete

      if (!selectedPageIndex && selectedPageIndex !== 0) {
        return alert('No page selected!');
      } // load fresh to update the landing cache


      return Landing.find().then(() => {
        landing.pages = landing.pages.filter(p => p.id !== selectedPageIndex); // Sanitizing (removing all the stupid ember stuff from the object)

        let sanitized = JSON.parse(JSON.stringify(landing));
        return Landing.save(sanitized);
      });
    }

  });
  Landing.reopenClass(_request.default, {
    /**
     * Retrieve static file
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find() {
      return this.request({
        method: 'GET',
        url: this.apiURL('static/landing.json')
      }).then(res => {
        // updating landing pages cache
        // Set the original id of the page.
        let pages = res.static.pages.map(land => {
          return _objectSpread(_objectSpread({}, land), {
            origid: land.id
          });
        });
        landing = {
          pages
        };
        return landing;
      });
    },

    /**
     * Retrieve static file
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findOneOrCreate(id) {
      return this.find().then(({
        pages
      }) => {
        if (id && pages[id]) {
          let page = pages[id];

          if (page.itemArr) {
            page.itemArr = page.itemArr.map(item => {
              // Replace each instance in the itemArr with ember objects to utilise get/set-ters
              if (item.itemArr) {
                item.itemArr = item.itemArr.map(currentItem => {
                  // Remove the selected property from each item
                  delete currentItem.selected;
                  return Ember.Object.create(currentItem);
                });
              }

              return Ember.Object.create(item);
            });
          }

          return Landing.create(page);
        } else return Landing.create({
          name: '',
          default: false,
          itemArr: []
        });
      }).then(page => {
        // Add layout information if it doesn't exist.
        page.layout = page.layout ? page.layout : {
          enableNotificationBar: true,
          enableHeading: true,
          enableNavigation: true,
          enableBanner: true,
          enableFooter: true,
          enablePressQuotes: true,
          enableModal: true,
          style: {
            backgroundColor: 'transparent'
          }
        };
        return page;
      });
    },

    columnImageExtractor(previousImages, currentColumn) {
      switch (currentColumn.type) {
        case 'box-v2':
          {
            // Check if the image exists and push it into the current array
            const desktopImage = currentColumn.content.image.desktop;
            const hasDesktopImage = desktopImage && desktopImage.url;
            const hasTags = desktopImage && desktopImage.tags;

            if (hasDesktopImage && !hasTags) {
              return [...previousImages, desktopImage.url];
            } else {
              return previousImages;
            }
          }

        default:
          return previousImages;
      }
    },

    rowImageExtractor(previousImages, currentRow) {
      return currentRow.itemArr.reduce(Landing.columnImageExtractor, previousImages);
    },

    updateTagsOnImages(page) {
      const images = page.itemArr.reduce(Landing.rowImageExtractor, []) // Remove any null images
      .filter(image => Boolean(image)) // Extract all cloudinary id's
      .map(image => {
        const imageSegments = image.split('/');
        const fileName = imageSegments[imageSegments.length - 1].split('.');
        return fileName[0];
      });
      console.info('images not tagged on cloudinary::', images);
      return page;
    },

    transformLandingPage(page) {
      // Remove excess cloudinary data
      const modRows = page.itemArr.map(row => {
        return _objectSpread(_objectSpread({}, row), {}, {
          itemArr: row.itemArr.map(column => {
            if (column.type === 'box-v2') {
              // Remove duplicated itemArr object in the link component
              if (column.content.link.itemArr) {
                delete column.content.link.itemArr;
              } // Remove cloudinary desktop&mobile data


              if (column.content.image.desktop) {
                delete column.content.image.desktop.cloudinary_google_colors;
                delete column.content.image.desktop.cloudinary_top_colors;
                delete column.content.image.desktop.cloudinary_top_colors_hsv;
              }

              if (column.content.image.mobile) {
                delete column.content.image.mobile.cloudinary_google_colors;
                delete column.content.image.mobile.cloudinary_top_colors;
                delete column.content.image.mobile.cloudinary_top_colors_hsv;
              }
            }

            return column;
          })
        });
      }); // Add layout information if it doesn't exist.

      const layout = page.layout ? page.layout : {
        enableNotificationBar: true,
        enableHeading: true,
        enableNavigation: true,
        enableBanner: true,
        enableFooter: true,
        enablePressQuotes: true,
        enableModal: true,
        style: {
          backgroundColor: 'transparent'
        }
      };

      const modPage = _objectSpread(_objectSpread({}, page), {}, {
        layout,
        itemArr: modRows
      });

      return modPage;
    },

    /**
     * Replace static file
     * @return {Object} Promise
     */
    save(data) {
      const transformedPayload = {
        pages: data.pages.map(Landing.transformLandingPage)
      };
      return this.request({
        method: 'POST',
        url: this.apiURL('static/landing.json'),
        data: JSON.stringify(transformedPayload)
      });
    },

    findIndividual(id) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`static/landing/${id}.json`)
      }).then(page => {
        if (page.err) return Ember.RSVP.reject(page.err);
        return Landing.create(page.static);
      });
    },

    saveIndividual(data) {
      return this.request({
        method: 'POST',
        url: this.apiURL(`static/landing/${data.id}.json`),
        data: JSON.stringify(data)
      });
    },

    saveHomepage(data) {
      return this.request({
        method: 'POST',
        url: this.apiURL('static/homepage.json'),
        data: JSON.stringify(data)
      });
    },

    generateCopyId(pages, originalId) {
      // Change if the new page postfix needs to be modified
      const duplicatePagePostfix = '-copy'; // Check if the newly generated id exists, generate a new one if it already exists
      // Start off with the original id.

      let newPageId = originalId;
      let newPageIdExists = true;

      do {
        newPageId = `${newPageId}${duplicatePagePostfix}`;
        newPageIdExists = !!pages.find(p => p.id === newPageId);
      } while (newPageIdExists);

      return newPageId;
    },

    duplicatePage(id) {
      return this.find().then(({
        pages
      }) => {
        // Current page to duplicate
        const page = Landing.create(Object.assign({}, pages.find(p => p.id === id)));
        const newPageId = this.generateCopyId(pages, id); // Update id and name properties.

        Ember.set(page, 'id', newPageId);
        Ember.set(page, 'origid', newPageId);
        Ember.set(page, 'name', `${page.name} - Copy`);
        Ember.set(page, 'description', `${page.description} - Copy`);
        return page.save().then(() => {
          return {
            page,
            newIndex: pages.length
          };
        });
      });
    }

  });
  var _default = Landing;
  _exports.default = _default;
});