define("backalley/pods/main/countries/edit/route", ["exports", "backalley/models/country", "backalley/models/zone", "backalley/models/currency", "backalley/util/clone-deep"], function (_exports, _country, _zone, _currency, _cloneDeep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    model({
      country_id
    }) {
      return Ember.RSVP.hash({
        zone: _zone.default.findWithMeta(),
        currency: _currency.default.findWithMeta(),
        country: _country.default.findById(country_id, {
          populate: 'zone'
        })
      }).then(({
        zone,
        currency,
        country
      }) => {
        if (!country.delivery_tags) {
          country.delivery_tags = [];
        }

        const modelResponse = {
          zone: zone.zones,
          currencies: currency.currencies,
          country,
          zoneResults: []
        };

        if (!country.delivery_tags) {
          return modelResponse;
        }

        const deliveryTagsExtension = {
          savedDeliveryTags: (0, _cloneDeep.default)(country.delivery_tags),
          workingDeliveryTags: [...country.delivery_tags],
          newDeliveryTagOption: {
            __isNotYetAttached: true,
            label: '-- NEW TAG --',
            type: 'postcode-ban-covid19',
            method: '',
            value: ''
          },
          editingDeliveryTag: {},
          deliveryTagMethods: [{
            label: 'Postcode (singular)',
            value: 'single'
          }, {
            label: 'Postcodes (range)',
            value: 'range'
          }],
          deliveryTagTypes: [{
            label: 'Postcode Ban (covid19)',
            value: 'postcode-ban-covid19'
          }]
        };
        return _objectSpread(_objectSpread({}, modelResponse), deliveryTagsExtension);
      });
    },

    actions: {
      shortcutToCreateNewDeliveryTag() {
        const tagOptions = this.controller.liveDeliveryTagsWithNewOption;
        const newTagOption = tagOptions.find(tag => tag.label === '-- NEW TAG --');

        if (newTagOption) {
          this.set('currentModel.editingDeliveryTag', newTagOption);
        }
      },

      attachNewlyEditedDeliveryTag() {
        const tag = this.get('currentModel.editingDeliveryTag');
        const label = this.controller.calculateTagLabel(tag);

        if (!label) {
          this.notify.show('error', 'Please set all tag fields before attaching!', 5000);
          return;
        } // remove this special flag before we store as a real tag


        Ember.set(tag, '__isNotYetAttached', false);
        delete tag.__isNotYetAttached;
        Ember.set(tag, 'label', label); //tag = cloneDeep(tag);
        // remove runtime __isNotYetAttached flag
        // update the possible tags that can be attached

        this.get('currentModel.workingDeliveryTags').pushObject(tag); // actually attach the new tag to the country

        this.get('currentModel.country.delivery_tags').pushObject(tag);
      },

      setEditingDeliveryTag(tag) {
        let editingTag = tag; // if we are using the "new" template, don't mutate template

        if (editingTag.__isNotYetAttached) {
          editingTag = (0, _cloneDeep.default)(editingTag);
        }

        this.set('currentModel.editingDeliveryTag', editingTag);
      },

      setDeliveryTags(tags) {
        this.set('currentModel.country.delivery_tags', tags);
      },

      updateCurrency(currency) {
        this.set('currentModel.country.currency', currency);
      },

      update(country) {
        return country.save().then(() => this.transitionTo('main.countries.index')).catch(exception => {
          const {
            errors = []
          } = exception.responseJSON || {};

          if (errors.length && errors[0]) {
            const [error] = errors;
            this.notify.show('error', error.detail || error.title || exception.message, 5000);
          } else {
            this.notify.show('error', exception.message, 5000);
          }

          throw exception;
        });
      },

      searchZones(q) {
        if (!q) return this.set('currentModel.zoneResults', []);
        const zones = this.get('currentModel.zones').filter(curr => curr.name.toLowerCase().indexOf(q.toLowerCase()) === 0);
        return this.set('currentModel.zoneResults', zones);
      },

      selectZone(zone) {
        return this.set('currentModel.country.zone', zone);
      },

      removeZone() {
        return this.set('currentModel.country.zone', undefined);
      },

      goBack() {
        this.transitionTo('main.countries');
      }

    }
  });

  _exports.default = _default;
});