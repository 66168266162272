define("backalley/pods/main/boutiques/edit/controller", ["exports", "backalley/config/environment", "backalley/pods/main/boutiques/edit/sections", "backalley/enums/boutique-feature", "backalley/enums/vertical", "backalley/enums/boutique-feed-type"], function (_exports, _environment, _sections, _boutiqueFeature, _vertical, _boutiqueFeedType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['section'],
    section: 'basic-info',
    tabs: _sections.default.map(({
      label
    }) => label),
    boutiqueFeature: _boutiqueFeature.default,
    supportedVerticals: Object.values(_vertical.default),
    boutiqueFeedType: Object.values(_boutiqueFeedType.default),
    currentSection: Ember.computed('section', function () {
      return _sections.default.findBy('value', this.section);
    }),
    shopifyPluginURL: Ember.computed('shopifyPluginValue', function () {
      const shopifyPluginValue = this.shopifyPluginValue;
      const clientId = _environment.default.shopifyClientId;
      const redirectUri = _environment.default.shopifyRedirectUri;
      let hostname;

      try {
        hostname = new URL(shopifyPluginValue).hostname;
      } catch (e) {
        hostname = shopifyPluginValue;
      }

      return ['https://', hostname, '/admin/oauth/authorize', `?client_id=${clientId}`, `&redirect_uri=${redirectUri}`, '&scope=read_products,write_products,read_inventory,write_inventory,read_locations', '&state=+1Mp%2F41lMiupBFBmtGSVKQ%3D%3D'].join('');
    }),
    canExpressShip: Ember.computed.or('model.boutique.isUnspecifiedShipping', 'model.boutique.isEasypostSpecific', 'model.boutique.isP2GSpecific'),
    actions: {
      setSection(sectionIndex) {
        let {
          value
        } = _sections.default[sectionIndex];
        this.set('section', value);
      },

      toggleFeature(feature, event) {
        event.target.checked ? this.model.boutique.features.addObject(feature) : this.model.boutique.features.removeObject(feature);
      },

      toggleRestore(event) {
        if (!this.model.boutique.restore) {
          const restore = {
            enabled: false,
            boutique_name: '',
            boutique_owner: ''
          };
          Ember.set(this.model.boutique, 'restore', restore);
        }

        event.target.checked ? Ember.set(this.model.boutique.restore, 'enabled', true) : Ember.set(this.model.boutique.restore, 'enabled', false);
      }

    }
  });

  _exports.default = _default;
});