define("backalley/pods/components/date-between/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public
    tagName: '',
    target: null,
    start: new Date(),
    // Start date default today
    endType: 'months',
    // Subtract type to calculate end [months, days, hours]
    endNumber: 3,
    // Number of endType to subtract
    // Private
    _isBetween: Ember.computed('start', function () {
      // Normalize the values to ensure avoiding issues
      const end = (0, _moment.default)(this.start).subtract(this.endNumber, this.endType).format('YYYY-MM-DD');
      const start = (0, _moment.default)(this.start).format('YYYY-MM-DD');
      const target = (0, _moment.default)(this.target).format('YYYY-MM-DD'); // Tried moment.js isBefore function however 2.9 doesn't support
      // dates being equal the dates passed into isBetween, which would
      // cause issues so reverted to good old separate checking.

      const isBefore = (0, _moment.default)(target).isBefore(start) || (0, _moment.default)(target).isSame(start);
      const isAfter = (0, _moment.default)(target).isAfter(end) || (0, _moment.default)(target).isSame(end);
      return isBefore && isAfter; // isBetween
    })
  });

  _exports.default = _default;
});