define("backalley/pods/main/curate/categories/edit/route", ["exports", "backalley/models/curate/category"], function (_exports, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MainCurateCategoriesEditRoute extends Ember.Route {
    async model({
      id
    }) {
      const category = await _category.default.findById(id);

      if (!category) {
        return this.router.transitionTo('main.curate.categories');
      }

      await category.attachDescendants();
      return category;
    }

  }

  _exports.default = MainCurateCategoriesEditRoute;
});