define("backalley/pods/main/categories/index/route", ["exports", "backalley/models/category"], function (_exports, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },

    serializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      // don't show objects in url
      if (urlKey === 'sort') return value.value;
      if (urlKey === 'page') return Number(value);
      return value;
    },

    deserializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      if (urlKey === 'sort') return this.availableFilters.findBy('value', value);
      if (urlKey === 'page') return Number(value);
      return value;
    },

    availableFilters: [{
      label: 'Date: last updated',
      value: '-updated_at'
    }, {
      label: 'Date: first updated',
      value: 'updated_at'
    }, {
      label: 'Date: last created',
      value: '-created_at'
    }, {
      label: 'Date: first created',
      value: 'created_at'
    }, {
      label: 'Name: A-Z',
      value: 'full_hierarchy_name'
    }, {
      label: 'Name: Z-A',
      value: '-full_hierarchy_name'
    }],

    model({
      q,
      page = 1,
      limit = 30,
      sort = {}
    }) {
      let query = {
        page,
        limit,
        sort: sort.value
      };
      if (q) query.$text = {
        $search: `${q}`,
        $language: 'en'
      };
      return _category.default.findWithMeta(query).then(({
        categories,
        meta = {
          total: 0
        }
      }) => {
        return {
          categories,
          total: Math.ceil(meta.total / limit),
          search: q,
          sort: sort,
          availableFilters: this.availableFilters,
          actions: {
            goToPage: goToPage.bind(this),
            nextPage: nextPage.bind(this),
            prevPage: prevPage.bind(this),
            updateSearch: updateSearch.bind(this),
            updateFilter: updateFilter.bind(this),
            remove: remove.bind(this)
          }
        };
      });
    }

  });

  _exports.default = _default;

  function goToPage(page) {
    this.transitionTo({
      queryParams: {
        page: page
      }
    });
  }

  function nextPage() {
    const page = this.get('controller.page');
    if (page < this.get('currentModel.total')) this.transitionTo({
      queryParams: {
        page: Number(page) + 1
      }
    });
  }

  function prevPage() {
    const page = this.get('controller.page');
    if (page > 1) this.transitionTo({
      queryParams: {
        page: Number(page) - 1
      }
    });
  }

  function updateSearch(q) {
    this.set('controller.q', q);
  }

  function updateFilter(filter) {
    this.set('controller.sort', filter);
  }

  function remove(category) {
    if (window.confirm('Are you sure you want to delete this category?')) {
      this.set('currentModel.loading', true);
      return category.remove().then(() => {
        this.get('currentModel.categories').removeObject(category);
        this.set('currentModel.loading', false);
      });
    }
  }
});