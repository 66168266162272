define("backalley/util/error-nicely", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.errorNicely = errorNicely;

  function errorNicely(message, logger = null) {
    return exception => {
      if (logger) {
        logger.error(exception);
      }

      throw new Error(message);
    };
  }
});