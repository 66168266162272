define("backalley/pods/main/boutique-restock-information/index/route", ["exports", "backalley/models/boutique-restock-information"], function (_exports, _boutiqueRestockInformation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MainBoutiqueRestockInformationIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        },
        sort: {
          refreshModel: true
        },
        status: {
          refreshModel: true
        },
        boutique: {
          refreshModel: true
        }
      });
    }

    async model({
      page,
      limit,
      sort,
      status,
      boutique
    }) {
      const query = {
        page,
        limit,
        sort
      };
      Ember.assign(query, status ? {
        status
      } : {});
      Ember.assign(query, boutique ? {
        boutique
      } : {});
      const {
        boutiqueRestockInformation,
        meta
      } = await _boutiqueRestockInformation.default.find(query);
      return {
        boutiqueRestockInformation,
        meta: _objectSpread(_objectSpread({}, meta), {}, {
          queryParams: {
            page,
            limit,
            sort,
            status,
            boutique
          }
        })
      };
    }

  }

  _exports.default = MainBoutiqueRestockInformationIndexRoute;
});