define("backalley/pods/components/zendesk-create-ticket/component", ["exports", "backalley/util/default-to", "backalley/models/ticket", "backalley/enums/channel"], function (_exports, _defaultTo, _ticket, _channel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public API
    requesters: (0, _defaultTo.default)([]),
    boutiques: (0, _defaultTo.default)([]),
    products: (0, _defaultTo.default)([]),
    ordernumber: (0, _defaultTo.default)(0),
    orderSummary: (0, _defaultTo.default)(''),
    order: (0, _defaultTo.default)({}),
    ticket: (0, _defaultTo.default)(null),
    preCreate: (0, _defaultTo.default)(() => {}),
    postCreate: (0, _defaultTo.default)(() => {}),
    // Private API
    _loading: false,
    classNames: ['zendesk-create-ticket'],
    classNameBindings: ['_loading:loading'],

    didReceiveAttrs() {
      const ticket = _ticket.default.create({
        ordernumber: this.ordernumber,
        order_date: Ember.get(this, 'order.created_at'),
        order_summary: this.orderSummary
      });

      this.set('ticket', ticket);
    },

    getChannelTag(channel) {
      if (channel) {
        return `channel_${channel}`;
      } else {
        return '';
      }
    },

    availableProducts: Ember.computed('selectedBoutique.email', function () {
      const selectedBoutique = this.selectedBoutique;
      const filterProducts = this.products.filter(product => product.boutique_email === selectedBoutique.email);
      return filterProducts;
    }),
    actions: {
      selectRequester(requester) {
        const {
          name,
          email,
          support_email,
          label
        } = requester;
        this.setProperties({
          'ticket.requester_name': name,
          'ticket.requester_email': support_email || email,
          selectedRequester: requester
        });

        if (label === 'Boutique') {
          const selectedBoutique = this.boutiques.find(boutique => boutique.email === email);
          this.send('selectBoutique', selectedBoutique);
        }
      },

      selectBoutique(boutique) {
        const {
          name,
          primary_name,
          address_city,
          address
        } = boutique;
        this.setProperties({
          'ticket.boutique_name': name,
          'ticket.boutique_primary_name': primary_name,
          'ticket.boutique_city': address_city,
          'ticket.boutique_address': address,
          selectedBoutique: boutique,
          selectedProduct: null
        });
      },

      selectProduct(product) {
        const {
          name,
          slug
        } = product;
        this.setProperties({
          'ticket.product_name': name,
          'ticket.product_slug': slug,
          selectedProduct: product
        });
      },

      createTicket(ticket) {
        this.set('_loading', true);
        const orderChannel = Ember.get(this, 'order.channel');
        const isMadeOrder = Ember.get(this, 'order.isChannelMadeDotCom');
        const newSubject = isMadeOrder ? `Trouva - ${this.order.channel_specific.order_reference} - #${ticket.ordernumber} - ${ticket.subject}` : `Trouva - #${ticket.ordernumber} - ${ticket.subject}`;
        const newComment = isMadeOrder ? `Ticket for order ${ticket.ordernumber} \n MADE Reference: ${this.order.channel_specific.order_reference}` : `Ticket for order ${ticket.ordernumber}`;
        const channelTag = this.getChannelTag(orderChannel);
        ticket.set('subject', newSubject);
        ticket.set('comment', newComment);
        ticket.set('channel', orderChannel);
        ticket.set('tags', [channelTag]);

        if (isMadeOrder) {
          ticket.set('channel_specific_order_reference', this.order.channel_specific.order_reference);
        }

        return Ember.RSVP.resolve().then(() => {
          const preCreate = this.preCreate;
          if (typeof preCreate === 'function') return preCreate();
          return null;
        }).then(() => {
          return ticket.save().finally(() => {
            this.set('_loading', false);
          });
        }).then(ticket => {
          const postCreate = this.postCreate;
          if (typeof postCreate === 'function') return postCreate(ticket);
        }).catch(error => {
          this.logger.error(error);
        });
      }

    }
  });

  _exports.default = _default;
});