define("backalley/pods/main/made/orders/index/route", ["exports", "backalley/models/order", "backalley/enums/channel"], function (_exports, _order, _channel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MainMadeOrdersIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        email: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        },
        name: {
          refreshModel: true
        },
        page: {
          refreshModel: true
        },
        sort: {
          refreshModel: true
        }
      });
    }

    model({
      email,
      limit,
      name,
      page,
      sort
    }) {
      const query = {
        channel: _channel.default.MADE_DOT_COM,
        limit,
        page,
        sort
      };
      Object.assign(query, email ? {
        email: email.trim()
      } : {});
      Object.assign(query, name ? {
        full_name: name.trim()
      } : {});
      return _order.default.find(query);
    }

  }

  _exports.default = MainMadeOrdersIndexRoute;
});