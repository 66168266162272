define("backalley/pods/components/manufacturer-pipeline/component", ["exports", "backalley/models/manufacturer"], function (_exports, _manufacturer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    loading: true,

    init() {
      this._super(...arguments);

      _manufacturer.default.on('created', this, this.load);

      _manufacturer.default.on('updated', this, this.load);

      _manufacturer.default.on('removed', this, this.load);

      _manufacturer.default.on('merged', this, this.load);
    },

    didDestroyElement() {
      this._super(...arguments);

      _manufacturer.default.off('created', this, this.load);

      _manufacturer.default.off('updated', this, this.load);

      _manufacturer.default.off('removed', this, this.load);

      _manufacturer.default.off('merged', this, this.load);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.load();
    },

    load() {
      Ember.set(this, 'loading', true);

      const handleResponse = ({
        meta = {}
      }) => {
        return Ember.getWithDefault(meta, 'total', 0);
      };

      return Ember.RSVP.all([_manufacturer.default.findWithMeta({
        limit: 1,
        archived: {
          $ne: true
        }
      }).then(handleResponse), _manufacturer.default.findWithMeta({
        status: 'pending',
        limit: 1,
        archived: {
          $ne: true
        }
      }).then(handleResponse), _manufacturer.default.findWithMeta({
        status: 'approved',
        limit: 1,
        archived: {
          $ne: true
        }
      }).then(handleResponse), _manufacturer.default.findWithMeta({
        status: 'approved',
        limit: 1,
        $or: [{
          url: {
            $exists: false
          }
        }, {
          logo: {
            $exists: false
          }
        }],
        archived: {
          $ne: true
        }
      }).then(handleResponse), _manufacturer.default.findWithMeta({
        status: 'approved',
        limit: 1,
        url: {
          $exists: true
        },
        logo: {
          $exists: true
        },
        archived: {
          $ne: true
        }
      }).then(handleResponse)]).then(([total, pending, approved, missingContent, ready]) => Ember.setProperties(this, {
        total,
        pending,
        approved,
        missingContent,
        ready,
        loading: false
      }));
    },

    goToMain: values => {}
  });

  _exports.default = _default;
});