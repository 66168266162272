define("backalley/pods/components/category-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    classNames: ['category-form'],

    onCancel() {}

  });

  _exports.default = _default;
});