define("backalley/mirage/factories/statementindex", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.default.Factory.extend({
    boutique: i => `${i + 1}`,
    boutique_name: i => `label ${i}`,
    boutique_bank_sort_code: i => `${i}`,
    boutique_bank_account_number: i => `${i}-${i}-${i}`,
    sent: false,
    total_payout: -13500,
    orders_count: i => i
  });

  _exports.default = _default;
});