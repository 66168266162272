define("backalley/pods/main/notifications/new/route", ["exports", "backalley/models/notifications-centre"], function (_exports, _notificationsCentre) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MainNotificationsNewRoute extends Ember.Route {
    async model() {
      const {
        notifications
      } = await _notificationsCentre.default.getAll();
      return {
        notification: new _notificationsCentre.Notification(),
        notifications
      };
    }

  }

  _exports.default = MainNotificationsNewRoute;
});