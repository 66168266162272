define("backalley/pods/main/areas/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IJPWKWnT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"component\",[[28,\"hot-load\",[\"page-heading\",[23,0,[]],[24,[\"page-heading\"]],\"page-heading\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"fa\"],[[23,0,[]],\"page-heading\",[24,[\"page-heading\"]],false,true,\"map-marker-alt\"]],{\"statements\":[[0,\"  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"main.areas\"]],{\"statements\":[[0,\"Areas\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    Edit area\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"page-title\",[\"Areas\"],null],false],[0,\"\\n\"],[1,[28,\"page-title\",[[24,[\"model\",\"area\",\"name\"]]],null],false],[0,\"\\n\\n\"],[1,[28,\"component\",[[28,\"hot-load\",[\"area-form\",[23,0,[]],[24,[\"area-form\"]],\"area-form\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"area\",\"action\",\"boutiques\"],[[23,0,[]],\"area-form\",[24,[\"area-form\"]],false,true,[24,[\"model\",\"area\"]],[28,\"action\",[[23,0,[]],[24,[\"model\",\"actions\",\"updateArea\"]],[24,[\"model\",\"area\"]]],null],[24,[\"model\",\"boutiques\"]]]]],false],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/areas/edit/template.hbs"
    }
  });

  _exports.default = _default;
});