define("backalley/pods/main/tasks/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'q', 'sort', 'status', 'type'],
    page: 1,
    limit: 30,
    q: '',
    sort: {
      label: 'Date: Last updated',
      value: '-updated_at'
    },
    status: {
      label: 'Status: Non completed',
      value: 'false'
    },
    type: {
      label: 'Task: Show all',
      value: undefined
    }
  });

  _exports.default = _default;
});