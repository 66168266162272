define("backalley/utils/cleaners", ["exports", "ember-ast-hot-load/utils/cleaners"], function (_exports, _cleaners) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _cleaners.default;
    }
  });
});