define("backalley/models/country", ["exports", "backalley/mixins/request", "joi", "backalley/util/models"], function (_exports, _request, _joi, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const validations = {
    _id: _joi.default.string(),
    name: _joi.default.string().min(2).required().label('label').messages({
      'string.min': 'must be at least {#limit} characters'
    }),
    iso_code: _joi.default.string().length(2),
    short_name: _joi.default.string(),
    weengs_name: _joi.default.string(),
    zone: _joi.default.string(),
    is_destination_market: _joi.default.boolean(),
    currency: _models.resourceRef,
    documents: _joi.default.object({
      shipping_rates: _joi.default.object({
        url: _joi.default.string().allow(null)
      }),
      shipping_surcharges: _joi.default.object({
        url: _joi.default.string().allow(null)
      })
    }),
    christmas_last_order_date: _joi.default.date(),
    delivery_tags: _joi.default.array().items(_joi.default.object({
      _id: _joi.default.string(),
      type: _joi.default.string(),
      method: _joi.default.string(),
      label: _joi.default.string(),
      value: _joi.default.string().allow(''),
      min: _joi.default.number(),
      max: _joi.default.number()
    })).optional()
  };
  const Country = Ember.Object.extend(_request.default, {
    /**
     * Create new country
     * @return {Object} Promise
     */
    post() {
      return this.validate().then(country => {
        return this.request({
          method: 'POST',
          url: this.apiURL('countries'),
          data: JSON.stringify(country)
        });
      }).then(res => {
        const data = res.country;
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    /**
     * Save country
     * @return {Object} Promise
     */
    save() {
      if (this.get('zone._id')) this.set('zone', this.get('zone._id'));
      const deliveryTags = this.get('delivery_tags') || [];
      deliveryTags.forEach(tag => {
        if (tag.method === 'single') {
          Ember.set(tag, 'max', undefined);
          Ember.set(tag, 'min', undefined);
          delete tag.max;
          delete tag.min;
        }

        if (tag.method === 'range') {
          Ember.set(tag, 'value', undefined);
          delete tag.value;
        }
      });
      return this.validate().then(data => (0, _models.getChanges)(this._orig, data, Object.keys(validations))).then(country => {
        return this.request({
          method: 'PUT',
          url: this.apiURL(`countries/${country._id}`),
          data: JSON.stringify(country)
        });
      }).then(res => {
        const data = res.countries[0];
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    validate() {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(Country.schema, data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    /**
     * Remove country
     * @return {Object} Promise
     */
    remove() {
      const country = this;
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`countries/${country._id}`)
      });
    }

  });
  Country.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data = {}) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  Country.reopenClass(_request.default, {
    schema: _joi.default.object().min(1).max(10).keys(validations),

    create(data) {
      let c = this._super(data);

      c.setOriginalData(data);
      return c;
    },

    /**
     * Find countries
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.findWithMeta(data).then(res => res.countries);
    },

    /**
     * Find countries and returns meta too
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMeta(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('countries'),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => {
        return {
          countries: res.countries.map(country => this.create(country)),
          meta: res.meta
        };
      });
    },

    /**
     * Find country by id
     * @param  {String} id country _id
     * @return {Object}    Promise
     */
    findById(id, data = {}) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`countries/${id}`),
        data: _objectSpread(_objectSpread({}, data), {}, {
          plain: true
        })
      }).then(res => this.create(res.countries[0]));
    }

  });
  var _default = Country;
  _exports.default = _default;
});