define("backalley/pods/main/curate/categories/index/controller", ["exports", "backalley/enums/curate-category-status"], function (_exports, _curateCategoryStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MainCurateCategoriesIndexController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class MainCurateCategoriesIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "notify", _descriptor, this);

      _defineProperty(this, "queryParams", ['page', 'limit', 'sort', 'status', 'parent', 'query']);

      _initializerDefineProperty(this, "page", _descriptor2, this);

      _initializerDefineProperty(this, "limit", _descriptor3, this);

      _initializerDefineProperty(this, "sort", _descriptor4, this);

      _initializerDefineProperty(this, "status", _descriptor5, this);

      _initializerDefineProperty(this, "parent", _descriptor6, this);

      _initializerDefineProperty(this, "query", _descriptor7, this);

      _defineProperty(this, "statusOptions", Object.values(_curateCategoryStatus.default));

      _defineProperty(this, "parentOptions", [{
        name: 'All categories',
        value: undefined
      }, {
        name: 'Top-level categories',
        value: 'none'
      }]);

      _defineProperty(this, "categoriesStructureSheetURL", 'https://docs.google.com/spreadsheets/d/1z2ukSBY4pza5xb-MUU_PMKEFtTOfEI01Jf2DOIB9OMY');
    }

    get selectedParent() {
      return this.parentOptions.find(({
        value
      }) => value === this.parent);
    }

    togglePublished(category) {
      if (category.isPublished && !confirm(`Are you sure you want to unpublish "${category.name}"?`)) {
        return;
      }

      category.status = category.isPublished ? _curateCategoryStatus.default.UNPUBLISHED : _curateCategoryStatus.default.PUBLISHED;
      return category.update().then(() => {
        this.notify.show('success', `Category was successfully ${category.isPublished ? "published" : "unpublished"}!`);
      }).catch(() => {
        this.notify.show('error', 'Oops! An error occured while updating the category. Please try again.');
      });
    }

    onChangeParent(parent) {
      const {
        value
      } = parent !== null && parent !== void 0 ? parent : {
        value: undefined
      };
      this.parent = value;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 32;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sort", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'name';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "parent", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "query", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "togglePublished", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "togglePublished"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeParent", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeParent"), _class.prototype)), _class));
  _exports.default = MainCurateCategoriesIndexController;
});