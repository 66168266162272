define("backalley/mirage/factories/order", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    ordernumber(i) {
      return i + 1;
    }

  });

  _exports.default = _default;
});