define("backalley/models/carrier-lane", ["exports", "backalley/util/request", "backalley/models/common/path-or-lane-collection-base"], function (_exports, _request, _pathOrLaneCollectionBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CarrierLaneCollection = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * Typically you'd keep classes to one per file, but I can't find a good place
   * to put or catergorise this class. It isn't a model really, it is a
   * multi-model collection. Keeping it with the same file as CarrierLane because
   * typically you'd use both together.
   * @class
   * @classdesc Should be used as a wrapper to contain many CarrierLanes
   */
  class CarrierLaneCollection extends _pathOrLaneCollectionBase.default {
    constructor(list) {
      super(list);
      this.list = list.map(item => {
        if (item instanceof CarrierLane) {
          return item;
        }

        return new CarrierLane(item);
      });
      this.byCode = {};
      this.listOfCodes = this.list.map(({
        path_codes: pathCode
      }) => {
        this.byCode[pathCode] = pathCode;
        return pathCode;
      });
    }

  }
  /**
   * @class
   * @classdesc Represents a singular Carrier Lane
   */


  _exports.CarrierLaneCollection = CarrierLaneCollection;

  class CarrierLane {
    constructor({
      _id,
      path_codes,
      is_domestic,
      source,
      source_id,
      source_country_code,
      destination,
      destination_id,
      destination_country_code
    }) {
      this.type = 'lane';
      this.source = source;
      this.destination = destination;
      this.is_domestic = is_domestic;
      this.source_id = source_id;
      this.destination_id = destination_id;
      this.source_country_code = source_country_code;
      this.destination_country_code = destination_country_code;
      this.path_codes = path_codes;
      this._id = _id;
    }

    static async findFromPromenade(params = {}) {
      const {
        carrier_lanes: list = [],
        meta
      } = await (0, _request.requestAllPages)({
        mapPageToItems: ({
          carrier_lanes: items
        }) => items,
        mapItemsToResponse: ({
          items,
          meta
        }) => ({
          carrier_lanes: items,
          meta
        }),
        method: 'GET',
        url: (0, _request.apiURL)('carrier_lanes'),
        data: _objectSpread({}, params)
      });
      return {
        meta,
        carrier_lanes: new CarrierLaneCollection(list)
      };
    }

    static async findFromInventory(params = {}) {
      const {
        carrier_lanes: list = [],
        meta
      } = await (0, _request.requestAllPagesFromInventory)({
        numberOfPages: 10,
        numberOfItemsPerPage: 300,
        mapPageToItems: ({
          carrier_lanes: items
        }) => items,
        mapItemsToResponse: ({
          items,
          meta
        }) => ({
          carrier_lanes: items,
          meta
        }),
        method: 'GET',
        url: (0, _request.inventoryApiURL)('carrier_lanes'),
        data: _objectSpread({
          $fields: 'carrier_account,enabled,source_country_code' + ',destination_country_code,provider,carrier,service_id,source_country_id' + ',source_country_code_iso_2,destination_country_id' + ',destination_country_code_iso_2,path_codes,path_code_variant' + ',force_easypost_return,variant,rate_2kg,is_domestic,max_allowed_pence' + ',zone_path_codes,projected_max_allowed_pence,owner,release_datetime'
        }, params)
      });
      return {
        meta,
        carrier_lanes: new CarrierLaneCollection(list)
      };
    }

  }

  _exports.default = CarrierLane;
});