define("backalley/pods/main/product-delivery-tags/new/route", ["exports", "backalley/models/product-delivery-tag"], function (_exports, _productDeliveryTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return new _productDeliveryTag.default({
        type: null,
        label: null
      });
    },

    actions: {
      create({
        type,
        label
      }) {
        return _productDeliveryTag.default.create({
          type,
          label
        }).then(() => this.transitionTo('main.product-delivery-tags'));
      }

    }
  });

  _exports.default = _default;
});