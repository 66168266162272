define("backalley/pods/main/tags/index/route", ["exports", "backalley/models/tag", "backalley/models/category", "backalley/models/product", "backalley/mixins/loading"], function (_exports, _tag, _category, _product, _loading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_loading.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      only_filter: {
        refreshModel: true
      }
    },

    serializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      // don't show objects in url
      if (urlKey === 'sort') return value.value;
      if (urlKey === 'page') return Number(value);
      return value;
    },

    deserializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      if (urlKey === 'sort') return this.availableFilters.findBy('value', value);
      return value;
    },

    availableFilters: [{
      label: 'Date: last updated',
      value: '-updated_at'
    }, {
      label: 'Date: first updated',
      value: 'updated_at'
    }, {
      label: 'Date: last created',
      value: '-created_at'
    }, {
      label: 'Date: first created',
      value: 'created_at'
    }, {
      label: 'Name: A-Z',
      value: 'label'
    }, {
      label: 'Name: Z-A',
      value: '-label'
    }],

    model({
      q,
      page = 1,
      limit = 30,
      sort = {},
      only_filter
    }) {
      let query = {
        page,
        limit,
        is_filter: only_filter ? only_filter : undefined,
        sort: sort.value
      };
      if (q) query.$text = {
        $search: `${q}`,
        $language: 'en'
      };
      return _tag.default.findWithMeta(query) // .then(this.getCategoryAndProductCount.bind(this))
      .then(({
        tags,
        meta = {
          total: 0
        }
      }) => {
        return {
          only_filter,
          tags,
          search: q,
          sort: sort,
          total: Math.ceil(meta.total / limit),
          availableFilters: this.availableFilters
        };
      });
    },

    // getCategoryAndProductCount({tags, meta}) {
    //     return Ember.RSVP.map(tags, (tag) => {
    //         // check in cache
    //         if (this.get('tagCache').has(tag)) return this.get('tagCache').get(tag._id);
    //
    //         return Ember.RSVP.hash({
    //             categories: this.getCategoryCount(tag),
    //             products: this.getProductCount(tag)
    //         })
    //         .then((hash) => {
    //             tag.categories = hash.categories;
    //             tag.products = hash.products;
    //             this.get('tagCache').set(tag, tag);
    //             return tag;
    //         });
    //
    //     })
    //     .then((tagsWithProducts) => {
    //         return {
    //             tags: tagsWithProducts,
    //             meta
    //         };
    //     });
    // },
    async getProductCount(tag) {
      Ember.set(tag, '_loadingProductCount', true);
      const products = await _product.default.findWithMetaAndCreate({
        tags: tag._id,
        limit: 1,
        fields: '_id'
      }).finally(() => Ember.set(tag, '_loadingProductCount', false));
      const meta = products.meta || {
        total: 0
      };
      Ember.set(tag, 'products', meta.total);
      Ember.set(tag, '_loadedProductCount', true);
      return tag;
    },

    async getCategoryCount(tag) {
      Ember.set(tag, '_loadingCategoryCount', true);
      const categories = await _category.default.findWithMeta({
        tags: tag._id,
        limit: 1,
        fields: '_id'
      }).finally(() => Ember.set(tag, '_loadingCategoryCount', false));
      const meta = categories.meta || {
        total: 0
      };
      Ember.set(tag, 'categories', meta.total);
      Ember.set(tag, '_loadedCategoryCount', true);
      return tag;
    },

    actions: {
      goToPage(page) {
        this.transitionTo({
          queryParams: {
            page: page
          }
        });
      },

      nextPage() {
        const page = this.get('controller.page');
        if (page < this.get('currentModel.total')) this.transitionTo({
          queryParams: {
            page: Number(page) + 1
          }
        });
      },

      prevPage() {
        const page = this.get('controller.page');
        if (page > 1) this.transitionTo({
          queryParams: {
            page: Number(page) - 1
          }
        });
      },

      updateFilter(filter) {
        this.set('controller.sort', filter);
      },

      updateSearch(q) {
        this.set('controller.q', q);
        this.set('controller.page', 1);
      },

      countProducts(tag) {
        return this.getProductCount(tag);
      },

      countCategories(tag) {
        return this.getCategoryCount(tag);
      },

      remove(tag) {
        if (confirm(`Are you sure you want to archive the tag: ${tag.label}`)) {
          tag.remove().then(() => {
            this.get('currentModel.tags').removeObject(tag);
          });
        }
      },

      setOnlyFilter(bool) {
        if (typeof bool === 'boolean') this.controller.set('only_filter', bool);
      }

    }
  });

  _exports.default = _default;
});