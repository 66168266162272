define("backalley/pods/components/landing-page/tile/options/component", ["exports", "backalley/pods/main/landing/new/selectOptions", "backalley/util/default-to"], function (_exports, _selectOptions, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public API
    selectedEntry: (0, _defaultTo.default)(null),
    // Private API
    availableColors: _selectOptions.availableColors,
    availableTypes: _selectOptions.availableTypes,
    selectedTextColor: Ember.computed('availableColors', 'selectedEntry.content.color', function () {
      const availableColors = this.availableColors;
      const selectedColor = this.get('selectedEntry.content.color');
      return availableColors.find(color => color.value === selectedColor);
    }),
    selectedBackgroundColor: Ember.computed('availableColors', 'selectedEntry.content.backgroundColor', function () {
      const availableColors = this.availableColors;
      const selectedColor = this.get('selectedEntry.content.backgroundColor');
      return availableColors.find(color => color.value === selectedColor);
    }),
    // TODO: Move in it's own generic component
    selectedLinkType: Ember.computed('availableTypes', 'selectedEntry.content.type', function () {
      return this.availableTypes.find(type => type.value === this.get('selectedEntry.content.type'));
    }),
    actions: {
      setText(text) {
        const selectedEntry = this.selectedEntry;
        Ember.set(selectedEntry, 'content.text', text);
      },

      setTextColor(color) {
        const selectedEntry = this.selectedEntry;
        Ember.set(selectedEntry, 'content.color', color.value);
      },

      setBackgroundColor(color) {
        const selectedEntry = this.selectedEntry;
        Ember.set(selectedEntry, 'content.backgroundColor', color.value);
      },

      // TODO: Move in it's own generic component
      setCustomUrlLinkTo(linkTo) {
        const selectedEntry = this.selectedEntry;
        Ember.setProperties(selectedEntry.content, {
          id: linkTo,
          link_to_title: linkTo
        });
      },

      // TODO: Move in it's own generic component
      setLinkType(linkType) {
        const selectedEntry = this.selectedEntry; // If a new type is selected, clear the link_to_title and title

        Ember.setProperties(selectedEntry.content, {
          type: linkType.value,
          id: '',
          slug: '',
          has_product_unique_slug: null,
          title: '',
          link_to_title: ''
        });
        delete selectedEntry.content.slug;
        delete selectedEntry.content.has_product_unique_slug;
      },

      // TODO: Generalise linkType search
      searchLinkType(type, q) {
        if (Ember.isEmpty(q)) return;
        if (Ember.isEmpty(type)) return;
        if (type.value === 'customUrl') return;
        return type.model.find(type.query(q)).then(res => {
          // Landing pages && Info pages for now until refactor
          // TODO: Normalise these across the board
          if (res.pages) res = res.pages;
          if (res.static && res.static.pages) res = res.static.pages;
          Ember.set(this, 'searchResults', res);
        });
      },

      // TODO: Generalise setLinkTypeValue
      setLinkTypeValue(selectedLinkType, linkTo) {
        const selectedEntry = this.selectedEntry;

        if (selectedLinkType.value === 'product') {
          Ember.set(selectedEntry, 'content.id', linkTo.boutiques[0]._id);
          Ember.set(selectedEntry, 'content.slug', linkTo.slug);
          Ember.set(selectedEntry, 'content.has_product_unique_slug', Boolean(linkTo.has_product_unique_slug));
        } else {
          Ember.set(selectedEntry, 'content.id', linkTo._id);
        }

        Ember.setProperties(selectedEntry.content, {
          type: selectedLinkType.value,
          title: linkTo[selectedLinkType.key],
          link_to_title: linkTo[selectedLinkType.key]
        });
      }

    }
  });

  _exports.default = _default;
});