define("backalley/models/reservation", ["exports", "backalley/mixins/request", "joi", "@streethub/calcs", "backalley/models/boutique", "backalley/mixins/shipment", "backalley/util/models"], function (_exports, _request, _joi, _calcs, _boutique, _shipment, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const validations = {
    order: _joi.default.string().required(),
    product: _joi.default.string().required(),
    boutique: _joi.default.string().required(),
    product_slug: _joi.default.string().required(),
    boutique_slug: _joi.default.string().required(),
    transaction_currency: _joi.default.string().required(),
    transaction_price: _joi.default.object().keys({
      amount: _joi.default.number().integer().min(0).required(),
      currency: _joi.default.string().required()
    }),
    quantity: _joi.default.number().integer().min(1).required(),
    sales_price: _joi.default.number().integer().min(0).required(),
    original_price: _joi.default.number().integer().min(0).required(),
    sales: _joi.default.boolean(),
    shipped: _joi.default.boolean(),
    user: _joi.default.string().allow(''),
    status: _joi.default.string().allow(''),
    requested_return_reason: _joi.default.string().allow(''),
    requested_return_explanation: _joi.default.string().allow(''),
    requested_return_at: _joi.default.date().allow(null),
    selected_size: _joi.default.string().allow(null).optional(),
    weengs_note: _joi.default.string().allow(''),
    commission_rate: _joi.default.number().min(0)
  };
  const updateValidations = {
    _id: _joi.default.string().required(),
    transaction_currency: _joi.default.string().required(),
    transaction_price: _joi.default.object().keys({
      amount: _joi.default.number().integer().min(0).required(),
      currency: _joi.default.string().required()
    }),
    quantity: _joi.default.number().min(0),
    shipped: _joi.default.boolean(),
    sales: _joi.default.boolean(),
    sales_price: _joi.default.number().min(0),
    original_price: _joi.default.number().min(0),
    status: _joi.default.string().allow('active', 'success', 'fail', 'fail cancelled', 'fail returned', 'fail other'),
    requested_return_reason: _joi.default.string().allow(''),
    requested_return_explanation: _joi.default.string().allow(''),
    requested_return_at: _joi.default.date().allow(null),
    selected_size: _joi.default.string().allow(null).optional(),
    weengs_note: _joi.default.string().allow(''),
    commission_rate: _joi.default.number().min(0),
    boutique_discount: _joi.default.number()
  };
  let Reservation = Ember.Object.extend(_request.default, _shipment.default, {
    init() {
      this.calculate();
    },

    calculate: Ember.observer('editing_price', 'editing_quantity', 'commission_rate', 'status', function () {
      const userPrice = this.editing_price === undefined ? this.get('transaction_price.amount') : this.editing_price;
      const userQuantity = this.editing_quantity === undefined ? this.quantity : this.editing_quantity;

      let calculation = _calcs.default.calculateReservation({
        price: userPrice,
        quantity: userQuantity,
        status: this.status,
        commission_rate: this.commission_rate
      });

      const calculations = _calcs.default.inPences(calculation);

      const transaction_currency = this.transaction_currency;
      this.setProperties(_objectSpread(_objectSpread(_objectSpread({}, calculations), {
        transaction_price: {
          currency: transaction_currency,
          amount: calculations.price
        }
      }), {}, {
        commission_rate: calculation.commission_rate.toNumber(),
        transaction_currency
      }));
    }),
    // defaults to boutique.comission_rate, db data overwrites it which is fine as it's not a calculation
    commission_rate: Ember.computed.reads('boutique.commission_rate'),

    post() {
      return this.validate('post_schema').then(res => {
        return this.request({
          method: 'POST',
          url: this.apiURL(`orders/${this.order}/reservations`),
          data: JSON.stringify(res)
        });
      }).then(({
        reservations
      }) => {
        // reset the original data to get the correct diff the next time
        this.setOriginalData(reservations[0]);
        Reservation.attachPopulatedRelationships(reservations[0]); // update the document with the response

        this.setProperties(reservations[0]);
        return this;
      });
    },

    put({
      populate
    } = {}) {
      return this.validate('put_schema').then(reservation => (0, _models.getChanges)(this._orig, reservation, Object.keys(updateValidations))).then(res => {
        return this.request({
          method: 'PUT',
          url: this.apiURL(`orders/${this.order}/reservations/${this._id}`),
          data: JSON.stringify(res)
        });
      }).then(({
        orders
      }) => {
        // update the audit log
        return Ember.RSVP.all([Reservation.find({
          _id: this._id,
          populate
        }), orders[0]]);
      }).then(([reservations, order]) => {
        // reset the original data to get the correct diff the next time
        this.setOriginalData(reservations[0]); // update the document with the response

        this.setProperties(reservations[0]);
        Reservation.attachPopulatedRelationships(reservations[0]); // return the updated data from the response!

        return {
          reservation: reservations[0],
          order
        };
      }).catch(err => {
        this.reset();
        throw err.responseJSON ? err.responseJSON.errors[0].detail : err.statusText;
      });
    },

    validate(schema = 'post_schema') {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(Reservation[schema], data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    reset() {
      this.setProperties(this._orig);
    },

    /**
     * Confirm a reservation return
     * @return {Object} Promise
     */
    confirmReturn() {
      return this.request({
        method: 'POST',
        url: this.apiURL(`orders/${this.order}/reservations/${this._id}/confirm-return`),
        // Needed because request mixin sends a non-stringified empty Object in this field
        data: JSON.stringify({})
      }).then(({
        reservations: {
          return_confirmed_at
        }
      }) => {
        this.setOriginalData(_objectSpread(_objectSpread({}, this._orig), {}, {
          return_confirmed_at
        }));
        this.reset();
        return this;
      }).catch(err => {
        throw err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
      });
    }

  });
  Reservation.reopen({
    setOriginalData(data) {
      this._orig = this.pojo(data);
      return data;
    },

    pojo(reservation) {
      let data = _objectSpread({}, reservation);

      return JSON.parse(JSON.stringify(data));
    }

  });
  Reservation.reopenClass(_request.default, _shipment.default, {
    post_schema: _joi.default.object().keys(validations),
    put_schema: _joi.default.object().keys(updateValidations),

    /**
     * @param {Object} data Data to create an object with
     * @return {Object} Returns an initialized object
     */
    create(data = {}) {
      let c = this._super(data);

      c.setOriginalData(data);
      return c;
    },

    attachPopulatedRelationships(reservation) {
      if (typeof reservation.boutique === 'object') {
        Ember.set(reservation, 'boutique', _boutique.default.create(reservation.boutique));
      }
    },

    /**
     * Find reservations based on order id
     * @param  {Object} data Data to included in the API call
     * @return {Array}      Returns back a reservations array
     */
    find(data, loadVersions = true) {
      if (data && typeof data.status === 'undefined') {
        data.status = '*';
      }

      return this.request({
        method: 'GET',
        url: this.apiURL('reservations'),
        data: data
      }).then(res => res.reservations.map(reservation => {
        // Hotfix: 11/04/2016 - sales_price was set to null instead of zero. put_schema validation failed
        if (reservation.sales_price === null) reservation.sales_price = 0;
        Reservation.attachPopulatedRelationships(reservation);
        return this.create(reservation);
      }));
    }

  });
  var _default = Reservation;
  _exports.default = _default;
});