define("backalley/models/manufacturer", ["exports", "backalley/mixins/request", "joi", "backalley/util/models"], function (_exports, _request, _joi, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const validations = {
    _id: _joi.default.string(),
    name: _joi.default.string().min(3).required().messages({
      'string.min': 'must be at least {#limit} characters',
      'string.max': 'must be at max {#limit} characters'
    }),
    alternate_names: _joi.default.array().optional().default([]),
    url: _joi.default.string().optional().allow(''),
    logo: _joi.default.object().allow(null).allow(''),
    status: _joi.default.string().allow('pending', 'approved'),
    created_at: _joi.default.date(),
    updated_at: _joi.default.date(),
    archived: _joi.default.boolean(),
    description: _joi.default.string().optional().allow(''),
    enhanced_visibility: _joi.default.array().optional().default([])
  };
  const Manufacturer = Ember.Object.extend(_request.default, {
    /**
     * Create new manufacturer
     * @return {Object} Promise
     */
    post() {
      return this.validate().then(manufacturer => {
        manufacturer.name = this.name; // always include the name

        return this.request({
          method: 'POST',
          url: this.apiURL('manufacturers'),
          data: JSON.stringify(manufacturer)
        });
      }).then(res => {
        const data = res.manufacturers[0];
        this.setOriginalData(data);
        this.setProperties(data);
        Manufacturer.trigger('created', this);
      });
    },

    /**
     * Save manufacturer
     * @return {Object} Promise
     */
    save() {
      return this.validate().then(data => (0, _models.getChanges)(this._orig, data, Object.keys(validations))).then(manufacturer => {
        manufacturer.name = this.name; // always include the name

        return this.request({
          method: 'PUT',
          url: this.apiURL(`manufacturers/${manufacturer._id}`),
          data: JSON.stringify(manufacturer)
        });
      }).then(res => {
        const data = res.manufacturers[0];
        this.setOriginalData(data);
        this.setProperties(data);
        Manufacturer.trigger('updated', this);
        return true;
      });
    },

    validate() {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(Manufacturer.schema, data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    /**
     * Remove manufacturer
     * @return {Object} Promise
     */
    remove() {
      const manufacturer = this;
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`manufacturers/${manufacturer._id}`)
      }).then(() => Manufacturer.trigger('removed', manufacturer));
    }

  });
  Manufacturer.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  Manufacturer.reopenClass(_request.default, Ember.Evented, {
    schema: _joi.default.object().keys(validations),

    create(data) {
      let c = this._super(data);

      c.setOriginalData(data);
      return c;
    },

    /**
     * Find manufacturers
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.findWithMeta(data).then(res => res.manufacturers);
    },

    /**
     * Find manufacturers and returns meta too
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMeta(data) {
      return this.request({
        method: 'POST',
        headers: {
          'X-HTTP-Method-Override': 'GET'
        },
        url: this.apiURL('manufacturers'),
        data: JSON.stringify(_objectSpread(_objectSpread({}, data), {}, {
          plain: true
        }))
      }).then(res => {
        return {
          manufacturers: res.manufacturers.map(manufacturer => this.create(manufacturer)),
          meta: res.meta
        };
      });
    },

    /**
     * Find manufacturer by id
     * @param  {String} id manufacturer _id
     * @return {Object}    Promise
     */
    findById(id) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`manufacturers/${id}`),
        data: {
          plain: true
        }
      }).then(res => this.create(res.manufacturers[0]));
    },

    shuffle(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('manufacturers/shuffle/fuzzy_matches'),
        data: JSON.stringify(_objectSpread(_objectSpread({}, data), {}, {
          plain: true
        }))
      }).then(res => {
        /**
         * Shuffle endpoint provides us a list of manufacturers that
         * have been fuzzy matched. Important to bear in mind is:
         * 1. At first position of the array is the manufacturer selected
         * by the server (based on # of products / popularity)
         * 2. The rest are fuzzy matched results from Algolia
         *
         * We're therefore picking the first element in the array as our
         * shuffleManufacturer and removing it from our list of manufacturers
         *
         *
         * @param  {Object}         Query object for endpoint
         * @return {Object}         Results
         */
        let manufacturerList = res.manufacturers.map(manufacturer => this.create(manufacturer));
        const shuffleManufacturer = manufacturerList.get('firstObject');
        manufacturerList.removeAt(0);
        return {
          shuffleManufacturer: shuffleManufacturer,
          manufacturers: manufacturerList,
          meta: res.meta
        };
      });
    },

    /**
     * merge manufacturers
     * @return {Object} Promise
     */
    merge(from = [], to = '') {
      return this.request({
        method: 'POST',
        url: this.apiURL('manufacturers/merge'),
        data: JSON.stringify({
          from,
          to
        })
      }).then(res => {
        Manufacturer.trigger('merged', from);
        return this.create(res.manufacturers[0]);
      });
    }

  });
  var _default = Manufacturer;
  _exports.default = _default;
});