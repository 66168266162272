define("backalley/pods/components/order-edit/warning-box/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public API
    order: null,
    reservations: null,
    tagName: '',
    orderStatusCreating: Ember.computed.equal('order.status', 'creating'),
    orderStatusFailedPayment: Ember.computed.equal('order.status', 'fail payment'),

    /**
     * The new 'creating' state does not protect us from the process
     * terminating half way through creation of order structure.
     * It does however allow us to better manage the fallout.
     * If an order has been 'creating' for longer than a minute, there
     * is either a large slowdown in Promenade (which could lead to
     * processes dying) or the process creating this order died before
     * reaching the payment step.
     *
     * It is remotely possible that the payment step was triggered
     * and the process just failed at the point where we were about
     * to update our records. So it could have had a payment success.
     *
     * The process should be:
     *
     * - to check our payment providers, confirm no payment from the
     *   customer (that isn't attached to a subsequent order). If there
     *   are subsequent successful orders (with same products) the
     *   order is fine to set as 'fail payment' and ignored. We could
     *   introduce more statuses to better describe, but for now keeping
     *   changes to as small as possible.
     *
     * - If no payment, then we failed during the insert into the DB.
     *    - no payment has been recorded so the customer is free to
     *      try again, and as they saw an error in Checkout, they
     *      probably will.
     *
     * - If payment, then we failed at a manually recoverable step.
     *    - set the order, res, and res bout. manually to 'active'.
     *    - the hooks after persisting in db need to be triggered.
     */
    orderRemainedInCreatingStatus: Ember.computed('order.{status,created_at}', function () {
      const isCreating = this.order.status === 'creating';
      const createdAt = this.order.created_at || ''; // Check if order has been creating for long enough

      return isCreating && (0, _moment.default)().diff(createdAt, 'minutes') > 5;
    }),
    wasAccounted: Ember.computed('reservations.@each.sent_in_statement', function () {
      const reservations = this.reservations || [];
      return reservations.some(({
        sent_in_statement
      }) => sent_in_statement);
    })
  });

  _exports.default = _default;
});