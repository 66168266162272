define("backalley/pods/components/boutique-product-activity/component", ["exports", "@glimmer/component", "backalley/models/boutique", "ember-component-css/pod-names"], function (_exports, _component, _boutique, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BoutiqueProductActivityComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = (_temp = class BoutiqueProductActivityComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "loading", _descriptor, this);

      _initializerDefineProperty(this, "activitySinceSpecifiedDate", _descriptor2, this);

      _initializerDefineProperty(this, "activityReportInternal", _descriptor3, this);

      _initializerDefineProperty(this, "isIgnoringSnapshot", _descriptor4, this);
    }

    get activityReport() {
      if (!this.isIgnoringSnapshot && this.args.snapshot) {
        return this.generateActivityReportContent();
      }

      return this.activityReportInternal;
    }

    get styleNamespace() {
      return _podNames.default['boutique-product-activity'];
    }

    get classNames() {
      return _objectSpread({
        wrapper: 'boutique-product-activity',
        output: 'boutique-product-activity__output'
      }, this.args.classNames);
    }

    get boutiqueId() {
      return this.args.boutiqueId;
    }

    get activitySinceDate() {
      const {
        snapshot = {}
      } = this.args; // if snapshot, make sure activity is tracked from snapshot's generation

      if (!this.isIgnoringSnapshot && snapshot.generated_at) {
        return new Date(snapshot.generated_at);
      }

      return this.activitySinceSpecifiedDate || this.args.activitySinceSuggestedDate;
    }

    get activitySinceDateAsISOString() {
      return this.activitySinceDate.toISOString().substring(0, 16);
    }

    setActivitySinceSpecifiedDate(e) {
      this.activitySinceSpecifiedDate = new Date(e.target.value);
    }

    async getProductsStats() {
      const {
        relative_product_stats: relStats
      } = await _boutique.default.findRelativeProductStats({
        _id: this.args.boutiqueId,
        since: this.activitySinceDateAsISOString
      });
      const {
        absolute_product_stats: absStats
      } = await _boutique.default.findAbsoluteProductStats({
        _id: this.args.boutiqueId
      });
      return _objectSpread(_objectSpread({}, relStats), absStats);
    }

    generateActivityReportContent(stats = {}) {
      // if we've been provided with a snapshot, show these details instead
      if (!this.isIgnoringSnapshot && this.args.snapshot) {
        stats = this.args.snapshot;
      }

      const valueOrUnknown = v => v != undefined ? v : 'unknown'; // very simplistic UI that is quick and easy to update. @TODO improve


      return [`Checking for product activity for ${this.args.boutiqueName || this.args.boutiqueId} since ${this.activitySinceDateAsISOString}`, '', `  - products modified = ${valueOrUnknown(stats.updated)}`, `  - products created  = ${valueOrUnknown(stats.created)}`, `  - products archived = ${valueOrUnknown(stats.archived)}`, '', 'Overall product stats for boutique (not time based):', '', ` - with_integration          = ${valueOrUnknown(stats.with_integration)}`, ` - without_integration       = ${valueOrUnknown(stats.without_integration)}`, ` - published_and_archived    = ${valueOrUnknown(stats.published_and_archived)}`, ` - published_not_archived    = ${valueOrUnknown(stats.published_not_archived)}`, ` - unpublished_and_archived  = ${valueOrUnknown(stats.unpublished_and_archived)}`, ` - unpublished_not_archived  = ${valueOrUnknown(stats.unpublished_not_archived)}`, '', stats.generatedAt || stats.generated_at ? `last generated at: ${stats.generatedAt || stats.generated_at}` : 'Please wait...'].join('\n');
    }

    async checkActivity() {
      this.loading = true;
      this.isIgnoringSnapshot = true;
      this.activityReportInternal = this.generateActivityReportContent(this.lastStats);
      const stats = await this.getProductsStats();
      this.lastStats = stats;
      stats.generatedAt = new Date().toLocaleTimeString();
      this.activityReportInternal = this.generateActivityReportContent(stats);
      this.loading = false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loading", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "activitySinceSpecifiedDate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "activityReportInternal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isIgnoringSnapshot", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "checkActivity", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "checkActivity"), _class.prototype)), _class));
  _exports.default = BoutiqueProductActivityComponent;
});