define("backalley/models/carrier-lane-release", ["exports", "backalley/util/request", "backalley/enums/path-and-lane-release-type"], function (_exports, _request, _pathAndLaneReleaseType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CarrierLaneRelease {
    constructor({
      carrierLanes = []
    } = {}) {
      this.carrierLanes = carrierLanes;
    }

    release() {
      return (0, _request.request)({
        method: 'POST',
        url: (0, _request.apiURL)('carrier_lanes'),
        data: JSON.stringify(this.carrierLanes)
      });
    }
    /**
     * Fetch the details of a release.
     * @param {Object} params
     * @param {Number} params.release_datetime - release date as a millisecond epoch
     * @returns {Object[]} releaseDetails
     * @returns {String} releaseDetails[].status
     * @returns {String} releaseDetails[].resource_type
     * @returns {String} releaseDetails[].updated_at
    */


    findDetails(params = {}) {
      return (0, _request.request)({
        method: 'POST',
        url: (0, _request.apiURL)('carrier_lanes/release_details'),
        data: JSON.stringify(params)
      }).then(({
        carrier_lane_release_details: releaseDetails = []
      } = {}) => releaseDetails[0]);
    }
    /**
     * List Carrier Lane releases.
     *
     * @param {Object} params
     * @returns {Object} data
     * @returns {Object[]} data.releases
     * @returns {Object} data.meta
     * @returns {Object.Number} data.meta.page
     * @returns {Object.Number} data.meta.page_total
     * @returns {String} data.release_type
    */


    static list(params = {
      page: 1
    }) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.apiURL)('carrier_lanes/releases'),
        data: params
      }).then(result => {
        const {
          carrier_lane_releases: releases = [],
          meta = {}
        } = result;
        return {
          releases,
          meta,
          release_type: _pathAndLaneReleaseType.default.CARRIER_LANE
        };
      });
    }
    /**
     * Fetch active Carrier Lanes.
     * @param {Object} params
     * @return {Object[]} data
    */


    static async findActive(params = {
      skip_projections: true // skip_full_validations: true // @TODO add once it's implemented in Promenade

    }, previousCarrierLanes = []) {
      try {
        const result = await (0, _request.request)({
          method: 'GET',
          url: (0, _request.apiURL)('carrier_lanes'),
          data: params,
          headers: {
            'Trouva-Use-Features': 'dynamic-paths-and-lanes'
          }
        });
        const {
          carrier_lanes: carrierLanes = [],
          meta = {}
        } = result;
        const carrierLaneAggregate = previousCarrierLanes.concat(carrierLanes);

        if (meta.page < meta.page_total) {
          params.page = meta.page + 1;
          return this.findActive(params, carrierLaneAggregate);
        }

        return carrierLaneAggregate;
      } catch (err) {
        console.error(err);
        return [];
      }
    }

  }

  _exports.default = CarrierLaneRelease;
});