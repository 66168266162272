define("backalley/pods/main/boutiques/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'q', 'sort', 'archived', 'currency'],
    page: 1,
    q: '',
    sort: {
      label: 'Date: last updated',
      value: '-updated_at'
    },
    archived: {
      label: 'Archived: not archived',
      value: 'not_archived'
    },
    currency: {
      label: 'Currency: any',
      value: 'any'
    },
    tier: {
      label: 'Tier: Any',
      value: 'any'
    }
  });

  _exports.default = _default;
});