define("backalley/pods/components/order-edit/zendesk-tickets/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MADE_SUPPORT_EMAIL = 'madetrouva@madecom.zendesk.com';

  var _default = Ember.Component.extend({
    order: null,
    reservations: null,
    reservationBoutiques: null,
    zendeskTickets: null,
    classNames: ['box-view', 'box-view-zendesk'],
    notify: Ember.inject.service(),
    zendeskRequesters: Ember.computed('order._id', function () {
      const {
        order,
        reservationBoutiques = []
      } = this;
      const customer = {
        email: order.email,
        name: order.full_name,
        label: 'Customer'
      };
      const madedotcom = {
        email: MADE_SUPPORT_EMAIL,
        name: 'Support',
        label: 'MADE'
      };
      const boutiques = reservationBoutiques.map(reservationBoutique => {
        const boutique = Ember.get(reservationBoutique, 'reservations.0.boutique');
        return {
          email: boutique.order_email || boutique.support_email,
          support_email: boutique.support_email,
          name: boutique.name,
          label: 'Boutique'
        };
      });
      return [order.isChannelMadeDotCom ? madedotcom : customer, ...boutiques];
    }),
    zendeskProducts: Ember.computed('order._id', function () {
      const {
        reservations = []
      } = this;
      return reservations.map(reservation => {
        const {
          product,
          boutique
        } = reservation;
        return {
          id: product.id,
          boutique_email: boutique.order_email,
          name: product.name,
          slug: product.slug,
          boutique_name: boutique.name,
          unit_price: reservation.local_price.amount
        };
      });
    }),
    zendeskBoutiques: Ember.computed('order._id', function () {
      const {
        reservationBoutiques = []
      } = this;
      return reservationBoutiques.map(reservationBoutique => {
        const boutique = Ember.get(reservationBoutique, 'reservations.0.boutique');
        const shippingProvider = Ember.get(reservationBoutique, 'reservations.0.shipping_provider');
        const address = [boutique.address_line_1, boutique.address_line_2, boutique.address_line_3, boutique.address_postcode, boutique.address_city];
        return {
          id: reservationBoutique.boutique,
          email: boutique.order_email,
          name: boutique.name,
          commission_rate: boutique.commission_rate,
          shipping_provider: shippingProvider,
          address: address.join('\n'),
          currency: reservationBoutique.boutique_currency,
          address_city: boutique.adress_city || ' ',
          primary_name: boutique.primary_contact_name || ' '
        };
      });
    }),
    orderSummary: Ember.computed('order._id', 'reservations.@each._id', function () {
      return this.reservations.map(res => {
        return `${res.boutique.name} - ${res.product.name} x ${res.quantity}`;
      }).join('\n');
    }),
    actions: {
      notifySuccess(message) {
        return this.notify.showSuccess(message);
      }

    }
  });

  _exports.default = _default;
});