define("backalley/pods/components/publisher-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['publisher-form'],
    imageFolder: Ember.computed('publisher', function () {
      return `publisher/${this.get('publisher._id')}`;
    })
  });

  _exports.default = _default;
});