define("backalley/pods/components/service-fee-exceptions/component", ["exports", "@glimmer/component", "moment", "lodash.clonedeep"], function (_exports, _component, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const SrcRefSymbol = Symbol('srcRef');
  let ServiceFeeExceptionsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = (_temp = class ServiceFeeExceptionsComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notify", _descriptor, this);

      _initializerDefineProperty(this, "logger", _descriptor2, this);

      _initializerDefineProperty(this, "isEditModalOpen", _descriptor3, this);

      _initializerDefineProperty(this, "isRemoveModalOpen", _descriptor4, this);

      _initializerDefineProperty(this, "selectedServiceFeeException", _descriptor5, this);

      _initializerDefineProperty(this, "modifiedOrNewServiceFeeException", _descriptor6, this);

      _initializerDefineProperty(this, "validationErrors", _descriptor7, this);

      _initializerDefineProperty(this, "boutiqueServiceFee", _descriptor8, this);

      this.reinitialise();
    }

    get editSaveIsDisabled() {
      return !!this.validationErrors.length;
    }

    get editSaveStatus() {
      return this.editSaveIsDisabled ? 'disabled' : 'enabled';
    }

    get existingServiceFeeExceptions() {
      if (!this.boutiqueServiceFee || !this.boutiqueServiceFee.exceptions) {
        return [];
      }

      return this.boutiqueServiceFee.exceptions.map(exceptionRecord => {
        this.attachLabelForException(exceptionRecord);
        return exceptionRecord;
      });
    }

    set existingServiceFeeExceptions(value) {
      this.boutiqueServiceFee = _objectSpread(_objectSpread({}, this.args.boutique.service_fee), {}, {
        exceptions: value
      });
    }

    reinitialise() {
      if (!this.args.boutique) {
        return;
      } // break the link between the model ref and what we're working on.
      // this way we aren't mutating and explicity have to update the model
      // using triggerOnChange.


      this.selectedServiceFeeException = null;
      this.boutiqueServiceFee = (0, _lodash.default)(this.args.boutique.service_fee);
      if (!this.boutiqueServiceFee) this.boutiqueServiceFee = {
        plan: 'standard'
      };
      if (!this.boutiqueServiceFee.exceptions) this.boutiqueServiceFee.exceptions = [];
    }

    generateLabelForException(exceptionRecord) {
      const niceDate = date => (0, _moment.default)(date).utc().format('Do MMM YYYY');

      const {
        type
      } = exceptionRecord;
      const from = niceDate(exceptionRecord.from);
      const till = niceDate(exceptionRecord.till);
      return `${type} from ${from} till ${till}`;
    }

    attachLabelForException(exceptionRecord) {
      Ember.set(exceptionRecord, 'label', this.generateLabelForException(exceptionRecord));
    }

    validateDateRanges(errors = []) {
      const niceLabel = this.generateLabelForException.bind(this);
      const editedRecord = this.modifiedOrNewServiceFeeException;

      const isDateInvalid = date => !(0, _moment.default)(date).isValid();

      const isDateRangeInvalid = range => {
        return !(0, _moment.default)(range.till).isAfter(range.from);
      };

      const isDateWithinRange = (date, fromDate, tillDate) => {
        return (0, _moment.default)(date).isBetween(fromDate, tillDate);
      }; // merge in the record we're currently updating


      const existingRecords = this.existingServiceFeeExceptions.map(record => {
        return record === editedRecord[SrcRefSymbol] ? editedRecord : record;
      }); // add the new record if it is a full date range

      if (!editedRecord[SrcRefSymbol] && editedRecord.from && editedRecord.till) {
        existingRecords.push(editedRecord);
      }

      for (let existingRecord of existingRecords) {
        const otherRecords = existingRecords.filter(record => record !== existingRecord);

        if (isDateInvalid(existingRecord.from)) {
          errors.push(`'${niceLabel(existingRecord)}' has invalid 'from' date`);
          continue;
        }

        if (isDateInvalid(existingRecord.till)) {
          errors.push(`'${niceLabel(existingRecord)}' has invalid 'till' date`);
          continue;
        }

        if (isDateRangeInvalid(existingRecord)) {
          errors.push(`'${niceLabel(existingRecord)}' is invalid`);
          continue;
        }

        const fromCollision = otherRecords.find(record => isDateWithinRange(existingRecord.from, record.from, record.till));
        const tillCollision = otherRecords.find(record => isDateWithinRange(existingRecord.till, record.from, record.till));
        const collision = fromCollision || tillCollision;

        if (collision) {
          const aa = niceLabel(existingRecord);
          const bb = niceLabel(collision);
          const msg = `'${aa}' collides with '${bb}'`;
          const inverseMsg = `'${bb}' collides with '${aa}'`; // insert a unique message

          if (!errors.includes(inverseMsg) && !errors.includes(msg)) {
            errors.push(msg);
          }
        }
      }

      return errors;
    }

    triggerOnChange() {
      try {
        this.args.onChange(this.boutiqueServiceFee);
      } catch (exception) {
        console.error(exception);
        this.notify.show('warning', `unable to write service fee changes to your boutique form, please check your browser's console.`, 5000);
        this.logger.error(`unable to write changes to boutique model, ${exception.message}`); // reset to avoid confusing state shown to user

        this.reinitialise();
      }
    }

    selectServiceFeeException(item) {
      this.selectedServiceFeeException = item;
    }

    onChangeDateField(fieldName, e) {
      if (!['from', 'till'].includes(fieldName) || !e.target) {
        return;
      }

      const editedRecord = this.modifiedOrNewServiceFeeException;
      const dateValue = e.target.value;
      const simpleDate = dateValue.split('T')[0];
      const dateAndTime = `${simpleDate}T00:00:00.000Z`;
      Ember.set(editedRecord, fieldName, dateAndTime);
      Ember.set(editedRecord, `${fieldName}Simple`, simpleDate);
      this.attachLabelForException(editedRecord);
      Ember.set(this, 'validationErrors', this.validateDateRanges());
    }

    openRemoveModal() {
      this.isRemoveModalOpen = true;
    }

    openEditModal() {
      const getDate = value => value && value.split('T')[0];

      const selectedRecord = this.selectedServiceFeeException;
      this.isEditModalOpen = true;
      this.modifiedOrNewServiceFeeException = _objectSpread(_objectSpread({
        type: 'waived'
      }, selectedRecord), {}, {
        [SrcRefSymbol]: selectedRecord,
        // preserve a ref for ref compare
        fromSimple: getDate(selectedRecord && selectedRecord.from),
        tillSimple: getDate(selectedRecord && selectedRecord.till)
      });
    }

    closeAnyModal() {
      this.isEditModalOpen = false;
      this.isRemoveModalOpen = false;
    }

    addException() {
      const editedRecord = this.modifiedOrNewServiceFeeException;
      this.selectedServiceFeeException = editedRecord;
      this.existingServiceFeeExceptions = [...this.existingServiceFeeExceptions, editedRecord];
      this.triggerOnChange();
      this.closeAnyModal();
    }

    updateException() {
      const editedRecord = this.modifiedOrNewServiceFeeException;
      const targetRecord = editedRecord[SrcRefSymbol];
      Ember.set(targetRecord, 'from', editedRecord.from);
      Ember.set(targetRecord, 'till', editedRecord.till);
      Ember.set(targetRecord, 'label', editedRecord.label);
      this.triggerOnChange();
      this.closeAnyModal();
    }

    removeException() {
      if (this.selectedServiceFeeException) {
        this.existingServiceFeeExceptions = this.existingServiceFeeExceptions.filter(item => item !== this.selectedServiceFeeException);
        this.selectedServiceFeeException = null;
      }

      this.triggerOnChange();
      this.closeAnyModal();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "logger", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isEditModalOpen", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isRemoveModalOpen", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedServiceFeeException", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "modifiedOrNewServiceFeeException", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "validationErrors", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "boutiqueServiceFee", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectServiceFeeException", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "selectServiceFeeException"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeDateField", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeDateField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openRemoveModal", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "openRemoveModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openEditModal", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "openEditModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeAnyModal", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "closeAnyModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addException", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "addException"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateException", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "updateException"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeException", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "removeException"), _class.prototype)), _class));
  _exports.default = ServiceFeeExceptionsComponent;
});