define("backalley/services/session", ["exports", "backalley/models/user", "backalley/mixins/request"], function (_exports, _user, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_request.default, {
    user: _user.default.create(),
    isLoggedIn: false,

    /**
     * Logout the user
     * @return {Object} Promise
     */
    logout() {
      return this.request({
        url: this.apiURL('logout'),
        method: 'GET'
      }).then(() => {
        this.set('user', _user.default.create());
        this.set('isLoggedIn', false);
      });
    },

    /**
     * Fetch isLoggedIn from the server and refreshes data in the session
     * @return {Object} Promise
     */
    isAuthenticated() {
      return this.getLoggedIn().then(res => {
        this.set('isLoggedIn', res.isLoggedIn);
        this.user.setProperties(res.user);
      }).then(() => {
        if (!this.isLoggedIn || this.get('user.role') !== 'admin') {
          throw new Error('Not logged in!');
        }
      }).then(this._identify.bind(this));
    },

    /**
     * Calls the api to see if the user is logged in
     * @return {Object} Promise
     */
    getLoggedIn() {
      return this.request({
        url: this.apiURL('isloggedin'),
        method: 'GET'
      }, false).then(res => {
        this.set('isLoggedIn', res.isLoggedIn);
        this.user.setProperties(res.user);
        return res.user;
      }).catch(err => {
        const message = err.responseJSON ? err.responseJSON.errors[0].detail : 'Some error occured!';
        throw message;
      });
    },

    /**
     * Authenticates an user based on the email and password and updates the infos
     * in the session (user, isLoggedIn)
     * @param  {String} email    Email
     * @param  {String} password Password
     * @return {Object}          Promise
     */
    authenticate(email, password) {
      return this.request({
        url: this.apiURL('login'),
        data: JSON.stringify({
          email,
          password
        }),
        method: 'POST'
      }, false).then(user => {
        this.set('user', _user.default.create(user));
        this.set('isLoggedIn', true);
        return this.user;
      }).then(this._identify.bind(this));
    },

    /**
     * Register user on analytics
     */
    _identify() {
      const _id = this.get('user._id');

      const email = this.get('user.email');

      if (!email || !_id || !window.analytics) {
        return Ember.RSVP.resolve();
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        window.analytics.identify(_id, {
          email
        }, err => err ? reject(err) : resolve());
      });
    }

  });

  _exports.default = _default;
});