define("backalley/models/sync-job", ["exports", "backalley/mixins/request", "joi", "backalley/util/models"], function (_exports, _request, _joi, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SyncJob = Ember.Object.extend(_request.default, {
    /**
     * Create new sync-job
     * @return {Object} Promise
     */
    post() {
      this.setStatus('validating');
      return this.validate().then(job => {
        this.setStatus('requesting');

        this._events.started.trigger(this);

        return this.request({
          method: 'POST',
          url: this.apiURL('syncJobs'),
          data: JSON.stringify(job)
        });
      }).then(res => {
        const data = res.syncJob;
        this.setOriginalData(data);
        this.setProperties(data);
        this.setStatus('created');

        this._events.created.trigger(this);
      }).catch(exception => {
        this.setStatus(exception);

        this._events.errored.trigger(this, exception);

        throw exception;
      });
    },

    validate() {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(SyncJob.schema, data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    }

  });
  SyncJob.reopen({
    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data = {}) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    },

    /**
     * Very simplistic event interface (that perhaps Ember has already?) but
     * wasn't able to find it at the model level. Only a few lines of code
     * however that give us a signal-like interface. We do need to exclude
     * _events from the normal model data however.
     */
    setupEvents() {
      const createSetWithTrigger = () => {
        const list = new Set();

        list.trigger = function (...args) {
          this.forEach(fn => fn(...args));
        };

        return list;
      };

      this._events = {
        started: createSetWithTrigger(),
        created: createSetWithTrigger(),
        errored: createSetWithTrigger()
      };
    },

    /**
     * Set meta data about this object that should not be saved
     * @param {String} status
     * @return {void}
    */
    setStatus(status) {
      this._meta = {};

      if (status === null) {
        this._meta.status = 'init';
        this._meta.error = status;
        return;
      }

      if (status instanceof Error) {
        this._meta.status = 'error';
        this._meta.error = status;
        return;
      }

      this._meta.status = status;
      this._meta.error = null;
    }

  });
  SyncJob.reopenClass(_request.default, {
    schema: _joi.default.object().min(5).max(5).keys({
      provider: _joi.default.string().valid('feed'),
      sync_integration: _joi.default.string(),
      type: _joi.default.string().valid('reconcile'),
      args: _joi.default.object(),
      onetime_behaviour_settings: _joi.default.object()
    }),

    create(data) {
      let c = this._super(data);

      c.setOriginalData(data);
      c.setupEvents();
      return c;
    }

  });
  var _default = SyncJob;
  _exports.default = _default;
});