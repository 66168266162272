define("backalley/pods/components/basic-notes/component", ["exports", "backalley/models/comment", "backalley/util/default-to"], function (_exports, _comment, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    assign
  } = Object;

  var _default = Ember.Component.extend({
    listComponent: (0, _defaultTo.default)('basic-notes/list'),
    newComponent: (0, _defaultTo.default)('basic-notes/new'),
    title: '',
    resource: '',
    // [product, order]
    item: '',
    sort: (0, _defaultTo.default)('-created_at'),
    classNames: ['basic-notes'],
    classNameBindings: ['_loading:loading'],
    _comments: (0, _defaultTo.default)([]),
    _loading: true,
    _request: null,

    init() {
      this._super();

      (true && !(this.resource) && Ember.assert('basic-notes: "resource" parameter cannot be empty. Check API comment_schema for available types.', this.resource));
      (true && !(this.item) && Ember.assert('basic-notes: "item" parameter must be a valid mongo _id.', this.item));

      _comment.default.on('created', this, 'refreshComments');

      this.set('publicAPI', {});
      const publicAPI = this.updateState({
        comment: this.newComment(),
        title: this.title || '',
        resource: this.resource,
        item: this.item,
        sort: this.sort,
        actions: {
          save: this.save.bind(this),
          reset: this.reset.bind(this)
        }
      });
    },

    willDestroyElement() {
      _comment.default.off('created', this, 'refreshComments');
    },

    updateState(changes) {
      const newState = Ember.set(this, 'publicAPI', assign({}, this.publicAPI, changes));
      return newState;
    },

    newComment() {
      const resource = this.resource;
      const data = {
        text: '',
        [resource]: this.item
      };
      return _comment.default.create(data);
    },

    didReceiveAttrs() {
      Ember.set(this, 'publicAPI.comment', this.newComment());
      Ember.set(this, 'publicAPI.item', this.item);
      Ember.set(this, 'publicAPI.sort', this.sort);
      return this.refreshComments.call(this);
    },

    refreshComments() {
      const resource = Ember.get(this, 'publicAPI.resource');
      const data = {
        sort: Ember.get(this, 'publicAPI.sort'),
        [resource]: Ember.get(this, 'publicAPI.item')
      };
      Ember.set(this, '_loading', true);
      return _comment.default.find(data).then(({
        comments
      }) => {
        Ember.setProperties(this, {
          _loading: false,
          _comments: comments
        });
      }).catch(() => {
        Ember.setProperties(this, {
          _comments: [],
          _loading: false,
          _request: null
        });
      });
    },

    // Methods
    save(comment) {
      Ember.set(this, '_loading', true);
      return comment.post().then(() => {
        Ember.set(this, 'publicAPI.comment', this.newComment());
      });
    },

    reset(comment) {
      Ember.set(comment, 'text', '');
      this.updateState({
        comment
      });
    }

  });

  _exports.default = _default;
});