define("backalley/pods/products/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gSTiDrxM",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[[28,\"hot-load\",[\"product-edit\",[23,0,[]],[24,[\"product-edit\"]],\"product-edit\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"model\",\"createAmendment\",\"saveAmendment\",\"deleteAmendment\",\"setAmendmentOnField\"],[[23,0,[]],\"product-edit\",[24,[\"product-edit\"]],false,true,[24,[\"model\"]],[28,\"route-action\",[\"createAmendment\"],null],[28,\"route-action\",[\"saveAmendment\"],null],[28,\"route-action\",[\"deleteAmendment\"],null],[28,\"route-action\",[\"setAmendmentOnField\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/products/edit/template.hbs"
    }
  });

  _exports.default = _default;
});