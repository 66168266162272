define("backalley/pods/components/status-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    status: 'success',
    items: [],
    hasItems: Ember.computed.gt('items.length', 0)
  });

  _exports.default = _default;
});