define("backalley/pods/products/route", ["exports", "backalley/models/product", "backalley/models/category", "backalley/models/boutique", "backalley/models/color", "backalley/models/tag", "backalley/models/task", "backalley/models/manufacturer", "backalley/mixins/loading", "backalley/mixins/product-build-query", "backalley/util/escape-regex", "jquery"], function (_exports, _product, _category, _boutique, _color, _tag, _task, _manufacturer, _loading, _productBuildQuery, _escapeRegex, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const TASK_PARAM_KEY = 'task';

  var _default = Ember.Route.extend(_loading.default, _productBuildQuery.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      q_product: {
        refreshModel: true
      },
      q_productId: {
        refreshModel: true
      },
      q_syncRefId: {
        refreshModel: true
      },
      q_syncRemoteId: {
        refreshModel: true
      },
      q_syncIntegrationId: {
        refreshModel: true
      },
      q_manufacturer: {
        refreshModel: true
      },
      q_boutique: {
        refreshModel: true
      },
      q_category: {
        refreshModel: true
      },
      q_color: {
        refreshModel: true
      },
      q_tag: {
        refreshModel: true
      },
      latest_products: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      feed: {
        refreshModel: true
      },
      archived: {
        refreshModel: true
      },
      published: {
        refreshModel: true
      },
      featured: {
        refreshModel: true
      },
      unsuitable_state: {
        refreshModel: true
      },
      product_notes_state: {
        refreshModel: true
      },
      new_products: {
        refreshModel: true
      },
      sales: {
        refreshModel: true
      },
      stock: {
        refreshModel: true
      },
      boutique: {
        refreshModel: true
      },
      not_tagged: {
        refreshModel: true
      },
      from_feed: {
        refreshModel: true
      },
      no_category: {
        refreshModel: true
      },
      min_price: {
        refreshModel: true
      },
      max_price: {
        refreshModel: true
      },
      can_be_published: {
        refreshModel: true
      },
      task: {
        refreshModel: true
      },
      q_gtin: {
        refreshModel: true
      }
    },

    init() {
      this._super(...arguments);

      this.maxPriceFilters = [{
        label: 'Price: any',
        value: 'null'
      }, {
        label: 'Under £15',
        value: 1500
      }, {
        label: 'Under £30',
        value: 3000
      }, {
        label: 'Under £75',
        value: 7500
      }, {
        label: 'Under £150',
        value: 15000
      }];
      this.featuredFilters = [{
        label: 'Featured: all',
        value: 'null'
      }, {
        label: 'Only featured',
        value: 'true'
      }, {
        label: 'Not featured',
        value: 'false'
      }];
      this.sortingFilters = [{
        label: 'Date: last updated',
        value: '-updated_at'
      }, {
        label: 'Date: first updated',
        value: 'updated_at'
      }, {
        label: 'Date: last created',
        value: '-created_at'
      }, {
        label: 'Date: first created',
        value: 'created_at'
      }, {
        label: 'Quality score: highest first',
        value: '-qs'
      }, {
        label: 'Quality score: lowest first',
        value: 'qs'
      }, {
        label: 'Name: A-Z',
        value: 'name'
      }, {
        label: 'Name: Z-A',
        value: '-name'
      }];
      this.publishFilters = [{
        label: 'Published: all',
        value: 'null'
      }, {
        label: 'Only published',
        value: 'true'
      }, {
        label: 'Only unpublished',
        value: 'false'
      }];
      this.stockFilters = [{
        label: 'Stock: all',
        value: 'null'
      }, {
        label: 'Only in stock',
        value: 'true'
      }, {
        label: 'Only out of stock',
        value: 'false'
      }];
      this.salesFilters = [{
        label: 'Sales: all',
        value: 'null'
      }, {
        label: 'Only on sale',
        value: 'true'
      }, {
        label: 'Only not on sale',
        value: 'false'
      }];
      this.boutiqueFilters = [{
        label: 'Boutique published: all',
        value: 'null'
      }, {
        label: 'Only published',
        value: 'true'
      }, {
        label: 'Only unpublished',
        value: 'false'
      }];
      this.archivedFilters = [{
        label: 'Archived: all',
        value: 'null'
      }, {
        label: 'Only archived',
        value: 'true'
      }, {
        label: 'Exclude archived',
        value: 'false'
      }];
      this.feedFilters = [{
        label: 'Feed: all',
        value: 'null'
      }, {
        label: 'Only Feed products',
        value: 'true'
      }, {
        label: 'Exclude Feed products',
        value: 'false'
      }];
      this.unsuitableFilters = [{
        label: 'Unsuitable: All',
        value: 'null'
      }, {
        label: 'Suitable for publishing',
        value: 'published_suitable'
      }, {
        label: 'Unsuitable for publishing',
        value: 'published_unsuitable'
      }, {
        label: 'Suitable for featuring',
        value: 'featured_suitable'
      }, {
        label: 'Unsuitable for featuring',
        value: 'featured_unsuitable'
      }];
      this.gtinFilters = [{
        label: 'GTIN: all',
        value: 'null'
      }, {
        label: 'Has GTIN or unavailable',
        value: 'has_gtin_or_gtin_unavailable'
      }, {
        label: 'Has GTIN only',
        value: 'has_gtin'
      }, {
        label: 'Unavailable GTIN only',
        value: 'gtin_unavailable'
      }, {
        label: 'No GTIN info',
        value: 'no_gtin_info'
      }];
      this.productNotesFilters = [{
        label: 'Product notes: All',
        value: 'null',
        meaning: 'No filters considering the products notes.'
      }, {
        label: 'Only notes to review',
        value: 'only_notes_to_review',
        meaning: 'Only show products that have no notes to be resolved by the boutique and at least 1 note awaiting to be reviewed by Trouva. These products might have notes in other statuses.'
      }, {
        label: 'With notes to resolve',
        value: 'notes_to_resolve',
        meaning: 'Only show products that have at least 1 note awaiting to be resolved by the boutique. Theses products might have notes in other statuses.'
      }, {
        label: 'Without notes to resolve',
        value: 'no_notes_to_resolve',
        meaning: 'Only show products that do not have notes to be resolved by the boutique. These products might have notes in other statuses.'
      }, {
        label: 'Without notes to tackle',
        value: 'no_notes_to_review_or_resolve',
        meaning: 'Only show products that neither have notes to be resolved by the boutique nor have notes to be reviewed by Trouva. These products might have notes in other statuses.'
      }];
      this.canBePublishedFilters = [{
        label: 'Can be published: all',
        value: 'null'
      }, {
        label: 'Only valid products',
        value: 'true'
      }, {
        label: 'Only invalid products',
        value: 'false'
      }];
    },

    serializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      // don't show objects in url
      if (['q_boutique', 'q_category', 'q_color', 'q_tag', 'q_manufacturer'].indexOf(urlKey) > -1) {
        return String(value.value) + '|' + String(value.label);
      }

      if (['sort', 'featured', 'published', 'sales', 'stock', 'feed', 'archived'].indexOf(urlKey) > -1) {
        return String(value);
      }

      return value;
    },

    deserializeQueryParam(val = '', urlKey = '', defaultValueType = '') {
      if (['new_products'].indexOf(urlKey) > -1) {
        return Boolean(urlKey);
      }

      if (['q_boutique', 'q_category', 'q_color', 'q_tag', 'q_manufacturer'].indexOf(urlKey) > -1) {
        let [all, value, label] = val.match(/(\w+)\|(.+)/);
        return {
          value,
          label
        };
      }

      if (urlKey === 'max_price' || urlKey === 'min_price') {
        return Number(val);
      }

      return val;
    },

    limit: 30,

    beforeModel(transition) {
      const params = Ember.get(transition, 'to.queryParams');

      if (Object.keys(params).length && this.currentModel) {
        Ember.setProperties(this, {
          'currentModel.loading': true,
          'currentModel.overview': false
        });
      }

      return this._super(...arguments);
    },

    model(params) {
      // If the parameters are the default, show overview page
      const controller = this.controllerFor('products');
      const overview = isOverviewPage(Ember.get(controller, 'defaults'), params);
      const insideTask = Ember.isPresent(params.task);
      const {
        id: userId,
        upworker: isUpworker
      } = this.session.user;
      const showTaskDetails = false; // Bundle actions to be used for both overview/non-overview

      const actions = {
        goToPage: goToPage.bind(this),
        nextPage: nextPage.bind(this),
        prevPage: prevPage.bind(this),
        updateSearch: updateSearch.bind(this),
        updateOnController: updateOnController.bind(this),
        updateFilter: updateFilter.bind(this),
        resetFilters: resetFilters.bind(this),
        searchManufacturer: searchManufacturers.bind(this),
        searchCategories: searchCategories.bind(this),
        searchBoutiques: searchBoutiques.bind(this, params.boutique),
        searchColors: searchColors.bind(this),
        searchTags: searchTags.bind(this),
        nextProduct: nextProduct.bind(this),
        toggleFilters: toggleFilters.bind(this),
        goToProducts: goToProducts.bind(this),
        logout: logout.bind(this),
        incrementCounter: incrementCounter.bind(this),
        productsToFeature: productsToFeature.bind(this),
        productsToReview: productsToReview.bind(this),
        readableType: readableType.bind(this),
        toggleTaskDetails: toggleTaskDetails.bind(this)
      };
      const filters = {
        // Filter values
        max_price: params.max_price,
        q_gtin: params.q_gtin,
        published: params.published,
        featured: params.featured,
        unsuitable_state: params.unsuitable_state,
        product_notes_state: params.product_notes_state,
        new_products: params.new_products,
        archived: params.archived,
        feed: params.feed,
        sort: params.sort,
        stock: params.stock,
        sales: params.sales,
        boutique: params.boutique,
        manufacturer: params.manufacturer,
        latest_products: params.latest_products,
        // Filter options
        maxPriceFilters: this.maxPriceFilters,
        gtinFilters: this.gtinFilters,
        feedFilters: this.feedFilters,
        archivedFilters: this.archivedFilters,
        featuredFilters: this.featuredFilters,
        sortingFilters: this.sortingFilters,
        publishFilters: this.publishFilters,
        stockFilters: this.stockFilters,
        salesFilters: this.salesFilters,
        boutiqueFilters: this.boutiqueFilters,
        unsuitableFilters: this.unsuitableFilters,
        productNotesFilters: this.productNotesFilters,
        canBePublishedFilters: this.canBePublishedFilters
      };
      const values = {
        not_tagged: params.not_tagged,
        from_feed: params.from_feed,
        no_category: params.no_category,
        can_be_published: params.can_be_published,
        manufacturerSearch: params.q_manufacturer,
        productSearch: params.q_product,
        searchBySyncIdDisabled: !params.q_boutique && !params.q_syncIntegrationId,
        boutiqueSearch: params.q_boutique,
        categorySearch: params.q_category,
        colorSearch: params.q_color,
        tagSearch: params.q_tag
      };

      if (overview) {
        return _task.default.findWithMeta({
          populate: 'assignee',
          assignee: userId,
          completed: false
        }).then(({
          tasks
        }) => {
          tasks.map(this._addLeftToWorkOnPromise.bind(this));
          return _objectSpread(_objectSpread(_objectSpread({
            user: this.session.user,
            overview,
            loading: false
          }, filters), values), {}, {
            isUpworker,
            showFilter: false,
            results: false,
            tasks,
            actions,
            showTaskDetails
          });
        });
      } // Find products and show the product publishing.


      return Ember.RSVP.all([_product.default.findWithMetaAndCreate(this.buildQuery(params, this.limit)), insideTask ? _task.default.findById(params.task) : Ember.RSVP.resolve()]).then(([{
        products,
        meta = {
          total: 0
        }
      }, taskData]) => {
        this.notify.show('success', `Found ${meta.total} products!`);
        return _objectSpread(_objectSpread(_objectSpread({
          loading: false,
          isUpworker,
          insideTask,
          overview,
          products,
          taskData,
          page: Number(params.page) || 1,
          results: meta.total > 0,
          total: Math.ceil(meta.total / this.limit),
          totalCount: meta.total
        }, values), filters), {}, {
          actions,
          showTaskDetails
        });
      }).catch(() => {
        this.notify.show('error', 'Oops. Something went wrong.', 4000);
        return _objectSpread(_objectSpread(_objectSpread({
          loading: false
        }, values), filters), {}, {
          actions
        });
      });
    },

    afterModel() {
      // scroll top on next page
      (0, _jquery.default)('.products-list').scrollTop(0);
    },

    _addLeftToWorkOnPromise(task) {
      const params = _objectSpread(_objectSpread({}, this.getProperties(this.getProductQueryParamsNames())), this.getParams(task.url));

      ['q_boutique', 'q_category', 'q_color', 'q_tag', 'q_manufacturer'].forEach(label => {
        if (params[label]) {
          let [value] = params[label].split('|');
          params[label] = {
            label,
            value
          };
        }
      });
      task.set('leftToWorkOn', _product.default.findWithMetaAndCreate(this.buildQuery(params, 1)).then(({
        meta
      }) => meta ? meta.total : 0));
    }

  });

  _exports.default = _default;

  function isOverviewPage(defaults, params) {
    const showOverviewProducts = Object.keys(params).filter(key => {
      if (key === TASK_PARAM_KEY) return false;
      return Ember.get(params, key) !== Ember.get(defaults, key);
    });
    return showOverviewProducts.length === 0;
  }

  function goToPage(page) {
    this.transitionTo({
      queryParams: {
        page: page
      }
    });
  }

  function toggleFilters() {
    this.toggleProperty('currentModel.showFilter');
    this.toggleProperty('currentModel.results');
    this.set('currentModel.overview', true);
  }

  function nextPage() {
    const page = this.get('controller.page');
    if (page < this.get('currentModel.total')) this.transitionTo({
      queryParams: {
        page: Number(page) + 1
      }
    });
  }

  function prevPage() {
    const page = this.get('controller.page');
    if (page > 1) this.transitionTo({
      queryParams: {
        page: Number(page) - 1
      }
    });
  }

  function updateSearch(key, query) {
    this.query = query;
    this.key = key;
    Ember.run.debounce(this, updateQ, 300);
  }

  function updateQ() {
    this.set('controller.q_' + this.key, this.query);
    this.set('controller.page', 1);
  }

  function updateOnController(key, value) {
    this.controller.set(key, value);
    this.set('controller.page', 1);
  }

  function updateFilter(key, value) {
    this.controller.set(key, value.value);
    this.set('controller.page', 1);
  }

  function readableType(type) {
    return type.split('_').map(i => i[0].toUpperCase() + i.substring(1)).join(' ');
  }

  function logout() {
    this.toggleProperty('currentModel.loading');
    return this.session.logout().finally(() => {
      this.toggleProperty('currentModel.loading');
      this.transitionTo('login');
    });
  }

  function resetFilters() {
    this.transitionTo('/products');
  }

  function goToProducts() {
    this.set('currentModel.results', false);
    this.set('currentModel.overview', true);
  }

  function searchManufacturers(q) {
    if (Ember.isEmpty(q)) return;
    return _manufacturer.default.find({
      $text: {
        $search: q,
        $language: 'en'
      },
      archived: {
        $ne: true
      },
      limit: 10
    }).then(manufacturers => manufacturers.map(({
      name,
      _id
    }) => {
      return {
        label: name,
        value: _id
      };
    })).then(manufacturers => this.set('currentModel.manufacturers', manufacturers));
  }

  function searchCategories(q) {
    if (Ember.isEmpty(q)) return;
    return _category.default.find({
      name: {
        $regex: `^${(0, _escapeRegex.default)(q)}`,
        $options: 'i'
      },
      limit: 10
    }).then(categories => categories.map(({
      full_hierarchy_name,
      _id
    }) => {
      return {
        label: full_hierarchy_name,
        value: _id
      };
    })).then(categories => this.set('currentModel.categories', categories));
  }

  function searchBoutiques(published, q) {
    if (Ember.isEmpty(q)) return;
    let query = {
      name: {
        $regex: `^${(0, _escapeRegex.default)(q)}`,
        $options: 'i'
      },
      limit: 10
    };
    if (published === 'true') query.published = true;else if (published === 'false') query.published = false;
    return _boutique.default.find(query).then(boutiques => boutiques.map(({
      name,
      _id
    }) => {
      return {
        label: name,
        value: _id
      };
    })).then(boutiques => this.set('currentModel.boutiques', boutiques));
  }

  function searchColors(q) {
    if (Ember.isEmpty(q)) return;
    return _color.default.find({
      label: {
        $regex: `^${(0, _escapeRegex.default)(q)}`,
        $options: 'i'
      },
      fields: 'label',
      limit: 10
    }).then(colors => colors.map(({
      label,
      _id
    }) => {
      return {
        label,
        value: _id
      };
    })).then(colors => this.set('currentModel.colors', colors));
  }

  function searchTags(q) {
    if (Ember.isEmpty(q)) return;
    return _tag.default.find({
      label: {
        $regex: `^${(0, _escapeRegex.default)(q)}`,
        $options: 'i'
      },
      fields: 'label',
      limit: 10
    }).then(tags => tags.map(({
      label,
      _id
    }) => {
      return {
        label,
        value: _id
      };
    })).then(tags => this.set('currentModel.tags', tags));
  }

  function nextProduct(product) {
    const products = this.get('currentModel.products');
    const current = products.map(p => p._id).indexOf(product._id);
    const next = products.objectAt(current + 1);

    if (next) {
      this.transitionTo('products.edit', next._id);
    }
  }

  function productsToFeature(checked) {
    this.set('controller.published', checked ? 'true' : null);
    this.set('controller.featured', checked ? 'false' : null);
    this.set('controller.stock', checked ? 'true' : null);
    this.set('controller.archived', checked ? 'false' : null);
    this.set('controller.product_notes_state', checked ? 'no_notes_to_review_or_resolve' : null);
  }

  function productsToReview(checked) {
    this.set('controller.published', checked ? 'true' : null);
    this.set('controller.featured', checked ? 'false' : null);
    this.set('controller.stock', checked ? 'true' : null);
    this.set('controller.archived', checked ? 'false' : null);
    this.set('controller.product_notes_state', checked ? 'only_notes_to_review' : null);
  }

  function incrementCounter() {
    this.incrementProperty('currentModel.taskProductCount');
  }

  function toggleTaskDetails() {
    this.toggleProperty('currentModel.showTaskDetails');
  }
});