define("backalley/pods/components/tag-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    router: Ember.inject.service(),
    tagName: 'form',
    classNames: ['tag-form'],
    tagStatus: ['published', 'archived'],
    imageFolder: Ember.computed('tag._id', function () {
      const _id = this.get('tag._id');

      return `tag/${_id}`;
    }),
    isPromotedOnAllEditsPage: Ember.computed('tag.discoverable', function () {
      return this.tag && Ember.isPresent(this.tag.discoverable) && this.tag.discoverable[0].page === 'edits-hub';
    }),
    actions: {
      async save() {
        try {
          const createOrUpdate = this.isEdit ? this.tag.save() : this.tag.post();
          await createOrUpdate;
          this.router.transitionTo('main.tags');
        } catch (error) {
          this.notify.show('error', error);
        }
      },

      updateDisplayOrder(displayOrder) {
        this.tag.updateDisplayOrder(displayOrder);
      },

      updateDiscoverable(value) {
        return value ? this.tag.instantiateDiscoverable() : this.tag.destroyDiscoverable();
      },

      updateStatus() {
        return {};
      }

    }
  });

  _exports.default = _default;
});