define("backalley/pods/main/orders/edit/controller", ["exports", "@streethub/calcs"], function (_exports, _calcs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function pick(key) {
    return elem => elem[key];
  }

  var _default = Ember.Controller.extend({
    // eslint-disable-next-line ember/no-observers
    orderCalculations: Ember.observer('model.reservations.@each.{status,price_total,commission_exc_vat,out_of_stock_price_total}', 'model.reservationBoutiques.@each.{pass_through_sales,pass_through_shipping}', 'model.order.{_id,amount,discount_amount,delivery_cost,transaction_shipping_cost.amount,transaction_customer_refunded.amount,transaction_customer_recharged.amount}', function () {
      const order = this.get('model.order'); // get the original reservations and reservationBoutiques

      let reservations = this.get('model.reservations');
      let reservationBoutiques = this.get('model.reservationBoutiques'); // original source values direct from the order payload,
      // projections pre-calc'd (thanks George!)

      const channel = order.get('channel');
      const amount = order.get('transaction_amount.amount');
      let discount = order.get('transaction_discount_amount.amount');
      let discount_type = order.get('discount_type');
      const sdd_inc_vat = order.get('transaction_delivery_cost.amount');
      const customer_refunded = order.get('transaction_customer_refunded.amount');
      const customer_recharged = order.get('transaction_customer_recharged.amount');
      const nationwide_delivery_income = order.get('transaction_shipping_cost.amount');
      const outstanding_balance = order.get('transaction_outstanding_balance.amount');
      const transaction_currency = order.get('transaction_amount.currency'); // manipulated values, keep in mind: reservation & reserv. boutique

      const reservation_price_totals = reservations.map(pick('price_total'));
      const failed_price_totals = reservations.map(pick('out_of_stock_price_total'));
      const reservation_statuses = reservations.map(pick('status'));
      const boutique_pass_through_saleses = reservationBoutiques.map(pick('pass_through_sales'));
      const boutique_pass_through_shipping = reservationBoutiques.map(pick('pass_through_shipping'));
      const boutique_fulfilment_charges_exc_vat = reservationBoutiques.map(pick('fix_fulfilment_charge_exc_vat'));
      const boutique_commissions_exc_vat = reservations.map(pick('commission_exc_vat'));
      const hasTotalDiscountAmount = order.get('transaction_details.totals.discount.amount') > 0;

      if (discount_type === 'percentage' && hasTotalDiscountAmount) {
        discount = order.transaction_details.totals.discount.amount;
        discount_type = 'amount';
      } // let calcs do its thing


      let calculationTransactionProjection = _calcs.default.calculateOrder({
        amount,
        discount,
        discount_type,
        sdd_inc_vat,
        customer_refunded,
        customer_recharged,
        nationwide_delivery_income,
        reservation_price_totals,
        failed_price_totals,
        reservation_statuses,
        boutique_pass_through_saleses,
        boutique_pass_through_shipping,
        boutique_commissions_exc_vat,
        channel,
        boutique_fulfilment_charges_exc_vat
      });

      const c = _calcs.default.inPences(calculationTransactionProjection);

      order.setProperties(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, c), {
        transaction_customer_recharge: {
          amount: c.customer_recharge,
          currency: transaction_currency
        }
      }), {
        transaction_customer_recharged: {
          amount: customer_recharged,
          currency: transaction_currency
        }
      }), {
        transaction_customer_refund: {
          amount: c.customer_refund,
          currency: transaction_currency
        }
      }), {
        transaction_customer_refunded: {
          amount: customer_refunded,
          currency: transaction_currency
        }
      }), {
        transaction_shipping_cost: {
          amount: nationwide_delivery_income,
          currency: transaction_currency
        }
      }), {}, {
        outstanding_balance,
        transaction_currency
      }));
    })
  });

  _exports.default = _default;
});