define("backalley/enums/cko-payment-channel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    APPLE_PAY: 'cko:apple_pay',
    KLARNA: 'cko:klarna',
    CREDITCARD: 'cko:credit_card',
    PAYPAL: 'cko:paypal',
    OLD_KLARNA: 'checkout_klarna',
    GOOGLE_PAY: 'cko:google_pay'
  };
  _exports.default = _default;
});