define("backalley/pods/main/zones/edit/route", ["exports", "backalley/models/zone", "backalley/models/country"], function (_exports, _zone, _country) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      zone_id
    }) {
      return Ember.RSVP.hash({
        zone: _zone.default.findById(zone_id, {
          populate: 'countries'
        }),
        countries: _country.default.findWithMeta().then(({
          countries
        }) => countries)
      });
    },

    actions: {
      update(zone) {
        return zone.save().then(() => this.transitionTo('main.zones'));
      },

      goBack() {
        this.transitionTo('main.zones');
      }

    }
  });

  _exports.default = _default;
});