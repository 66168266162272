define("backalley/pods/components/landing-layout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wW6iOQt1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"landing-preview-container\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"button\"],[3,\"action\",[[23,0,[]],\"changeView\",\"desktop\"]],[8],[0,\"Desktop view\"],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"button\"],[3,\"action\",[[23,0,[]],\"changeView\",\"mobile\"]],[8],[0,\"Mobile view\"],[9],[0,\"\\n  \"],[7,\"hr\",true],[8],[9],[0,\"\\n\\n  \"],[7,\"h1\",true],[8],[0,\"Previewing \"],[1,[22,\"view\"],false],[0,\" mode\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"view\"]],\"desktop\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[28,\"hot-load\",[\"landing-preview\",[23,0,[]],[24,[\"landing-preview\"]],\"landing-preview\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"page\",\"device\",\"selectEntry\",\"selectLink\"],[[23,0,[]],\"landing-preview\",[24,[\"landing-preview\"]],false,true,[24,[\"page\"]],\"desktop\",[24,[\"selectEntry\"]],[24,[\"selectLink\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"view\"]],\"mobile\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[28,\"hot-load\",[\"landing-preview\",[23,0,[]],[24,[\"landing-preview\"]],\"landing-preview\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"page\",\"device\",\"selectEntry\",\"selectLink\"],[[23,0,[]],\"landing-preview\",[24,[\"landing-preview\"]],false,true,[24,[\"page\"]],\"mobile\",[24,[\"selectEntry\"]],[24,[\"selectLink\"]]]]],false],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/landing-layout/template.hbs"
    }
  });

  _exports.default = _default;
});