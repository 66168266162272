define("backalley/pods/components/landing-page/box-version-two/display/element/component", ["exports", "backalley/util/default-to"], function (_exports, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // "element" is a reserved word
    boxElement: (0, _defaultTo.default)({}),
    elementStyle: Ember.computed('boxElement.options.{hexColor,text,tag,type}', 'boxElement.options.style.textAlign', function () {
      const boxElement = this.boxElement;
      let style = '';
      const hexColor = Ember.get(boxElement, 'options.hexColor');

      if (hexColor) {
        style += `color:${hexColor}`;
      }

      const styleProperties = Ember.getWithDefault(boxElement, 'options.style', {});
      return Object.entries(styleProperties).reduce((prev, [key, value]) => {
        return `${style};${key.dasherize()}:${value}`;
      }, style);
    }),
    shareIcons: {
      facebook: 'facebook',
      facebookMessenger: 'facebook-messenger',
      twitter: 'twitter',
      pinterest: 'pinterest',
      whatsapp: 'whatsapp',
      email: 'envelope',
      copy: 'share-alt'
    }
  });

  _exports.default = _default;
});