define("backalley/pods/main/campaigns/new/controller", ["exports", "backalley/pods/main/campaigns/locales", "backalley/enums/vertical"], function (_exports, _locales, _vertical) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    supportedLocales: _locales.languages,
    selectedLocale: Ember.computed('model.campaign.locale', function () {
      const selectedLocale = this.model.campaign.locale;
      return this.supportedLocales.find(option => option.locale === selectedLocale);
    }),
    supportedVerticals: Object.values(_vertical.default),
    selectedVerticals: Ember.computed('model.campaign.verticals', function () {
      return this.model.campaign.verticals || [_vertical.default.TROUVA];
    }),
    actions: {
      changeSelectedLocale(label) {
        const language = this.supportedLocales.find(option => option.label === label);
        this.set('model.campaign.locale', language.locale);
      }

    }
  });

  _exports.default = _default;
});