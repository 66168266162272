define("backalley/pods/main/user-management/index/route", ["exports", "backalley/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async loadAllAdmins(page = 1, prevUsers = []) {
      const {
        users,
        meta
      } = await _user.default.findWithMeta({
        role: 'admin',
        page,
        sort: 'email'
      });

      if (page < meta.page_total) {
        return await this.loadAllAdmins(page + 1, [...prevUsers, ...users]);
      }

      return [...prevUsers, ...users];
    },

    async model() {
      const users = await this.loadAllAdmins();
      return {
        users
      };
    },

    actions: {
      async findUserByEmail() {
        const email = window.prompt('Please enter the email of the user you would like to find');
        if (!email) return;
        const [user] = await _user.default.find({
          email
        });

        if (!user) {
          return this.notify.showError('User not found. Please create a new user instead');
        }

        this.transitionTo('main.user_management.edit', user._id);
      }

    }
  });

  _exports.default = _default;
});