define("backalley/pods/components/order-edit/order-ups-access-point/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    country: Ember.inject.service(),
    classNames: ['box-view', 'box-view-general'],
    upsAccessPoint: Ember.computed.alias('order.ups_access_point'),
    countryById: Ember.computed('upsAccessPoint.country', function () {
      return this.country.countries.findBy('_id', this.upsAccessPoint.country);
    })
  });

  _exports.default = _default;
});