define("backalley/pods/components/feed-sync-behaviour-explainer/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const SetFieldAction = {
    OVERWRITE: 'overwrite',
    IGNORE: 'ignore',
    CONDITIONAL_UPDATE: 'conditional-update'
  };
  const SetProductAction = {
    OVERWRITE: 'overwrite',
    IGNORE: 'ignore',
    CONDITIONAL_UPDATE: 'conditional-update'
  };

  class FeedSyncBehaviourExplainerComponent extends _component.default {
    get styleNamespace() {
      return _podNames.default['feed-sync-behaviour-explainer'];
    }

    get classNames() {
      return _objectSpread({
        wrapper: 'feed-sync-behaviour-explainer',
        note: 'feed-sync-behaviour-explainer__note',
        title: 'feed-sync-behaviour-explainer__title',
        details: 'feed-sync-behaviour-explainer__details'
      }, this.args.classNames);
    }

    joinListWithAnd(rawList) {
      return rawList.join(', ').replace(/, ([^,]+?)$/, ' and $1');
    }

    getProductList(rawProductList) {
      if (!rawProductList || !rawProductList.length) return '*';
      const isListValid = rawProductList.every(item => {
        return item.startsWith('TROUVA:') || item.startsWith('REMOTE:');
      });

      if (!isListValid) {
        return new Error('Product ids must be prefixed with either TROUVA: or REMOTE:');
      }

      return this.joinListWithAnd(rawProductList);
    }

    getFieldList(rawFieldList) {
      if (!rawFieldList || !rawFieldList.length) return '*';
      return this.joinListWithAnd(rawFieldList);
    }

    convertRuleToWarning(rule) {
      let mode;
      const isFieldAction = rule.action === 'set-field';
      const isProductAction = rule.action === 'set-product';
      const targetFields = this.getFieldList(rule.target_fields);
      const targetProducts = this.getProductList(rule.target_products);
      const setFieldActionValues = Object.values(SetFieldAction);
      const setFieldProductValues = Object.values(SetProductAction);

      if (isFieldAction || isProductAction) {
        mode = Array.isArray(rule.params) ? rule.params[0] : rule.params;
      }

      if (targetProducts instanceof Error) {
        return targetProducts.message;
      }

      if (!isFieldAction && !isProductAction) {
        return `The action of a rule must either be "set-field" or "set-product". The value "${rule.action}" is unexpected.`;
      }

      if (isFieldAction && !setFieldActionValues.includes(mode)) {
        return `A field level action only supports ${this.joinListWithAnd(setFieldActionValues)}`;
      }

      if (isProductAction && !setFieldProductValues.includes(mode)) {
        return `A product level action only supports ${this.joinListWithAnd(setFieldProductValues)}`;
      }

      if (isProductAction && targetFields !== '*') {
        return `Rules at the product level cannot have field targets, these will be ignored.`;
      }

      return '';
    }

    calculateModeAsVerb(mode, {
      isFieldAction,
      isProductAction
    }) {
      if (isFieldAction && mode === SetFieldAction.CONDITIONAL_UPDATE) {
        return 'conditionally update';
      }

      if (isProductAction && mode === SetProductAction.CONDITIONAL_UPDATE) {
        return 'conditionally update';
      }

      return mode;
    }

    convertRuleToExplanatoryNote(rule) {
      let mode;
      const isFieldAction = rule.action === 'set-field';
      const isProductAction = rule.action === 'set-product';
      const targetFields = this.getFieldList(rule.target_fields);
      const targetProducts = this.getProductList(rule.target_products);
      const fieldPlural = rule.target_fields && rule.target_fields.length > 1 ? 's' : '';
      const productPlural = rule.target_products && rule.target_products.length > 1 ? 's' : '';
      const hasWarning = this.convertRuleToWarning(rule);

      if (isFieldAction || isProductAction) {
        mode = Array.isArray(rule.params) ? rule.params[0] : rule.params;
      }

      const modeAsVerb = this.calculateModeAsVerb(mode, {
        isFieldAction,
        isProductAction
      });

      if (hasWarning) {
        return 'ILLEGAL RULE (see warning)';
      }

      if (isProductAction && targetProducts === '*') {
        return `Will ${modeAsVerb} ALL products by default`;
      }

      if (isProductAction) {
        return `Will ${modeAsVerb} products: ${targetProducts}`;
      }

      if (isFieldAction && targetFields === '*' && targetProducts === '*') {
        return `Will ${modeAsVerb} ALL fields by default`;
      }

      if (isFieldAction && targetProducts === '*') {
        return `Will ${modeAsVerb} the ${targetFields} field${fieldPlural} — for all processed products`;
      }

      if (isFieldAction && targetFields === '*') {
        return `Will ${modeAsVerb} ALL fields — for product${productPlural}: ${targetProducts}`;
      }

      if (isFieldAction) {
        return `Will ${modeAsVerb} the ${targetFields} field${fieldPlural} — for product${productPlural}: ${targetProducts}`;
      }

      return 'UNKNOWN RULE';
    }

    get advancedSettingsAsExplanatoryNotes() {
      if (!this.args.advancedSettings) {
        return [];
      }

      const {
        rules
      } = this.args.advancedSettings;

      if (!rules || !rules.length) {
        return [{
          index: 1,
          about: 'No rules defined. This will run the sync like a standard scheduled feed.'
        }];
      }

      const notes = rules.map((rule, index) => ({
        index: index + 1,
        warning: this.convertRuleToWarning(rule),
        about: this.convertRuleToExplanatoryNote(rule)
      }));
      const firstNoteWithWarning = notes.find(note => note.warning);

      if (this.args.onErrorMessage && firstNoteWithWarning) {
        this.args.onErrorMessage(firstNoteWithWarning.warning);
      }

      return notes;
    }

  }

  _exports.default = FeedSyncBehaviourExplainerComponent;
});