define("backalley/pods/components/order-edit/misc/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    order: null,
    classNames: ['box-view', 'box-view-misc'],
    _expandSection: false
  });

  _exports.default = _default;
});