define("backalley/pods/main/feeds/new/route", ["exports", "backalley/models/sync-integration"], function (_exports, _syncIntegration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return {
        syncIntegration: _syncIntegration.default.create({
          provider: 'feed',
          settings: {}
        })
      };
    },

    actions: {
      goBack() {
        this.transitionTo('main.feeds');
      },

      create(integration) {
        return integration.post().then(() => this.transitionTo('main.feeds'));
      }

    }
  });

  _exports.default = _default;
});