define("backalley/services/secrets", ["exports", "backalley/mixins/request", "backalley/models/brain"], function (_exports, _request, _brain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_request.default, {
    secrets: Ember.Object.create({}),

    /**
     * Get secrets
     * @return {Object} Promise
     */
    fetch() {
      return _brain.default.getSecrets().then(({
        secrets
      }) => {
        this.set('secrets', secrets);
        return secrets;
      });
    }

  });

  _exports.default = _default;
});