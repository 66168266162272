define("backalley/pods/components/date-handler/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public
    emptyLabel: null,
    // Label to show if no date exists
    dateToFormat: null,
    // The date that should be formatted
    momentFormatString: 'DD/MM/YYYY [at] HH:mm',
    // You can overwrite the momentFormatString
    // Private
    _dateExists: Ember.computed('dateToFormat', function () {
      return (0, _moment.default)(this.dateToFormat).isValid();
    })
  });

  _exports.default = _default;
});