define("backalley/mixins/request", ["exports", "backalley/config/environment", "fetch"], function (_exports, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Mixin.create({
    /**
     * Request method
     * @param  {Object} params Parameters
     * @return {Object}      Promise
     */
    request(params = {}, allowLoginRedirects = true) {
      (true && !({
        id: 'backalley.request-mixin',
        until: '1.0.0'
      }) && Ember.deprecate('Using "request" mixin has been deprecated, use "request" util instead.', {
        id: 'backalley.request-mixin',
        until: '1.0.0'
      }));
      const method = params.method.toLowerCase() || 'get';
      const headers = params.headers || {};
      const contentType = params.contentType || 'application/json; charset=UTF-8';
      const data = method === 'get' ? this.serialize(params.data) : params.data || '{}';
      const url = method === 'get' ? `${params.url}?${data}` : params.url;
      const body = ['get', 'delete'].includes(method) ? undefined : data;
      return (0, _fetch.default)(url, {
        method,
        headers: _objectSpread(_objectSpread({}, headers), {}, {
          'Content-Type': contentType
        }),
        credentials: 'include',
        body
      }).then(async res => {
        const response = await res.text();
        const parse = JSON.parse;
        const reject = Ember.RSVP.reject;

        if (res.ok) {
          try {
            return parse(response);
          } catch (e) {
            return response;
          }
        } else {
          try {
            return reject([res, parse(response)]);
          } catch (e) {
            return reject([res, response]);
          }
        }
      }).catch(([response, errResponse]) => {
        if (allowLoginRedirects && Ember.get(errResponse, 'errors.0.status') === 403) {
          // get the path and the query params and encode them
          const location = encodeURI(window.location.pathname + window.location.search).replace('=', '@'); // go to login page

          window.location.replace(`/login?redirect=${location}`);
          throw new Error('You seem to be logged out, please login again.');
        } else {
          const errorObj = new Error(`${response.status}: ${response.statusText}`);
          errorObj.responseJSON = errResponse;
          errorObj.extra = {
            method: params.method,
            url: params.url,
            headers: params.headers,
            data: params.data
          };
          throw errorObj;
        }
      });
    },

    /**
     * To cut down on duplication (and support quicker future changes)
     * consolidate how we switch a POST to a GET
     */
    getHeaderHTTPMethodOverride(method = 'GET') {
      return {
        'X-HTTP-Method-Override': method
      };
    },

    /**
     * To cut down on duplication (and support quicker future changes)
     * consolidate how we add our auth header.
     */
    getHeaderAuthorisation(authToken) {
      return {
        'x-auth': authToken
      };
    },

    /**
     * In many areas we can end up with data that is either just an
     * id or is fully populated to its object. This is just a DRY
     * function to avoid repeating the same logic check. Whilst it is
     * not directly linked to request logic, it is often needed in
     * the same context a request is used.
     */
    getPossiblyPopulatedId(resource, fallback = undefined) {
      if (resource && typeof resource === 'string') {
        return resource;
      }

      if (resource && resource._id) {
        return resource._id;
      }

      return fallback;
    },

    /**
     *   Returns query params    - serialized string
     *   @param {Object} obj     - Object to serialize
     *   @param {String} prefix  - Prefix of final result
     *   @return {String}        - Serialized query parameters
     */
    serialize(obj = {}, prefix) {
      // Iterate over all of the values of an object
      return Object.entries(obj).reduce((str, [key, value]) => {
        if (obj.hasOwnProperty(key)) {
          // If the value of the property is a key, serialise it in an object style
          const stringKey = prefix ? prefix + '[' + key + ']' : key;

          if (value !== null && typeof value === 'object') {
            str.push(this.serialize(value, stringKey)); // Guard from sending undefined
          } else if (value !== undefined) {
            const property = encodeURIComponent(stringKey) + '=' + encodeURIComponent(value);
            str.push(property);
          }

          return str;
        }
      }, []).join('&');
    },

    /**
     * Returns the apiURL
     * @param  {String} endpoint Endpoint (products)
     * @return {String}          Full URL
     */
    apiURL(endpoint, version) {
      return _environment.default.apiURL + (version ? `/${version}/` : '/v2/') + (endpoint ? endpoint : '');
    },

    courierApiURL(endpoint, version = 'v2') {
      return `${_environment.default.courierApiURL}/${version}/${endpoint}`;
    },

    podioApiURL(endpoint = '', version = 'v1') {
      return _environment.default.podioApiURL + (version ? `/${version}/` : '/v1/') + (endpoint ? endpoint : '');
    },

    /**
     * Remove all the ember validation BS to avoid circular json errors
     * @param {Array} keys Keys to sanitize on top of the validation ones
     * @return {Object} Object
     */
    sanitize(keys = []) {
      let obj = {};
      const product = this;
      const toFilter = Ember.A(['container', 'errors', 'validators', 'dependentValidationKeys', '_orig'].concat(keys));

      for (let key in product) {
        if (product.hasOwnProperty(key) && !toFilter.includes(key)) {
          obj[key] = product[key];
        }
      }

      return obj;
    }

  });

  _exports.default = _default;
});