define("backalley/pods/main/categories/new/route", ["exports", "backalley/models/category", "backalley/models/tag", "backalley/util/escape-regex"], function (_exports, _category, _tag, _escapeRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return {
        category: _category.default.create({
          tags: Ember.A()
        }),
        tags: Ember.A(),
        actions: {
          create: create.bind(this),
          searchTags: searchTags.bind(this),
          deleteTag: deleteTag.bind(this),
          addTag: addTag.bind(this),
          cancel: cancel.bind(this)
        }
      };
    }

  });

  _exports.default = _default;

  function create(category) {
    let tags = category.tags.map(tag => tag._id);
    category.set('tags', tags);
    return category.post().then(() => this.transitionTo('main.categories')).catch(err => this.notify.show('err', err.message));
  }

  function deleteTag(tag) {
    let categoryTags = this.get('currentModel.category.tags').filter(cTag => cTag._id !== tag._id);
    this.set('currentModel.category.tags', categoryTags);
  }

  function addTag(tag) {
    let currentTags = this.get('currentModel.category.tags');
    currentTags.addObject(tag);
    this.set('currentModel.category.tags', currentTags);
  }

  function searchTags(q) {
    if (Ember.isEmpty(q)) return;
    return _tag.default.find({
      label: {
        $regex: `^${(0, _escapeRegex.default)(q)}`,
        $options: 'i'
      },
      limit: 10
    }).then(tags => this.set('currentModel.tags', tags));
  }

  function cancel() {
    this.transitionTo('main.categories');
  }
});