define("backalley/enums/lane-variant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    EXPRESS: 'express',
    CHRISTMAS: 'christmas',
    UPS_AP: 'upsap',
    EXTRA_SHIPPING: 'extra_shipping'
  };
  _exports.default = _default;
});