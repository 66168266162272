define("backalley/pods/components/zendesk-search-ticket/component", ["exports", "backalley/config/environment", "backalley/models/ticket"], function (_exports, _environment, _ticket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    openZendesk: false,
    url: Ember.computed('ticket', function () {
      return `${_environment.default.zendeskUrl}/agent/tickets/${this.get('ticket.id')}`;
    }),
    requester: Ember.computed('ticket', function () {
      return _ticket.default.getRequesterById(this.get('ticket.requester_id'));
    })
  });

  _exports.default = _default;
});