define("backalley/mirage/factories/infojson", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const getPage = name => {
    let englishContent = _emberCliMirage.faker.lorem.paragraph();

    return {
      id: Ember.String.dasherize(name.toLowerCase()),
      name,
      content: englishContent,
      content_en: englishContent,
      content_de: _emberCliMirage.faker.lorem.paragraph(),
      content_fr: _emberCliMirage.faker.lorem.paragraph()
    };
  };

  var _default = _emberCliMirage.Factory.extend({
    meta: {
      status: true,
      name: 'info.json'
    },
    static: {
      pages: [getPage('FAQs'), getPage('Careers')]
    }
  });

  _exports.default = _default;
});