define("backalley/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "product": {
      "html": {
        "info": "<strong>{product}</strong> will cost <em>{price, number, USD}</em> if ordered by {deadline, date, time}"
      },
      "info": "{product} will cost {price, number, USD} if ordered by {deadline, date, time}",
      "title": "Hello world!"
    }
  };
  _exports.default = _default;
});