define("backalley/pods/main/notifications/index/route", ["exports", "backalley/models/notifications-centre"], function (_exports, _notificationsCentre) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MainNotificationsIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        }
      });
    }

    async model({
      page,
      limit
    }) {
      const queryParams = {
        page,
        limit
      };
      return Ember.RSVP.hash({
        notificationsCentre: _notificationsCentre.default.getAll(queryParams),
        calloutBanner: _notificationsCentre.CalloutBanner.find()
      });
    }

  }

  _exports.default = MainNotificationsIndexRoute;
});