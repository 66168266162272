define("backalley/pods/components/feed-form/component", ["exports", "backalley/models/boutique", "backalley/util/escape-regex"], function (_exports, _boutique, _escapeRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    boutiqueResults: [],
    possibleStatus: [{
      label: 'active',
      value: 'active'
    }, {
      label: 'inactive',
      value: 'inactive'
    }, {
      label: 'onboarding',
      value: 'onboarding'
    }],
    teamNotesFile: Ember.computed('syncIntegration._id', function () {
      return `feeds/${this.get('syncIntegration._id')}`;
    }),
    actions: {
      searchBoutiques(name) {
        if (!name) return this.set('boutiqueResults', []);
        return _boutique.default.find({
          name: {
            $regex: `^${(0, _escapeRegex.default)(name)}`,
            $options: 'i'
          }
        }).then(boutiques => {
          this.set('boutiqueResults', boutiques);
        });
      },

      selectBoutique(syncIntegration, boutique) {
        return Ember.RSVP.all([this.set('syncIntegration.settings.boutique', boutique), this.set('syncIntegration.brand', boutique.brand)]);
      },

      updateStatus(statusSelected) {
        this.set('syncIntegration.status', statusSelected.value);
      },

      removeBoutique() {
        return Ember.RSVP.all([this.set('syncIntegration.settings.boutique', undefined), this.set('syncIntegration.brand', undefined)]);
      }

    }
  });

  _exports.default = _default;
});