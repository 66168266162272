define("backalley/mirage/factories/zone", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.default.Factory.extend({
    _id: i => `${i + 1}`,
    name: i => `Zone ${i}`,
    free_shipping_available: true,
    free_shipping_threshold: 5000,
    small_shipping_cost: 500,
    medium_shipping_cost: 1000,
    many_boutique_cost: 1500,
    many_boutique_threshold: 5,
    created_at: i => {
      return new Date();
    }
  });

  _exports.default = _default;
});