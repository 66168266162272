define("backalley/pods/components/task-filter-label/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const rightSideOfPipe = (label = '') => {
    let [, readableLabel] = label.split('|');
    return readableLabel;
  };

  const readableFunctionsByType = {
    category: rightSideOfPipe,
    boutique: rightSideOfPipe,
    tag: rightSideOfPipe
  };

  var _default = Ember.Component.extend({
    labelType: null,
    value: null,
    labelTypes: ['category', 'boutique', 'tag'],
    label: Ember.computed('value', 'labelType', function () {
      let {
        value,
        labelType
      } = this;
      return this._getReadableFunction(labelType)(value);
    }),

    _getReadableFunction(labelType) {
      if (this.labelTypes.includes(labelType)) {
        return readableFunctionsByType[labelType];
      }

      return label => label;
    }

  });

  _exports.default = _default;
});