define("backalley/pods/main/currency-statements/index-v2/controller", ["exports", "backalley/pods/main/currency-statements/index/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    statements_version: '3',
    routeName: 'main.currency_statements.index_v2'
  });

  _exports.default = _default;
});