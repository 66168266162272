define("backalley/pods/main/packaging/orders/index/controller", ["exports", "backalley/models/boutique", "ember-concurrency-decorators", "ember-concurrency"], function (_exports, _boutique, _emberConcurrencyDecorators, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PackagingOrdersIndexController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class PackagingOrdersIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['page', 'limit', 'sort', 'boutique', {
        orderNumber: 'order-number'
      }]);

      _defineProperty(this, "availableSort", [{
        label: 'Order Number: Descending',
        value: '-order_number'
      }, {
        label: 'Order Number: Ascending',
        value: 'order_number'
      }, {
        label: 'Amount in GBP: Highest value first',
        value: '-pricing.total_price.amount'
      }, {
        label: 'Amount in GBP: Lowest value first',
        value: 'pricing.total_price.amount'
      }, {
        label: 'Updated At: Newer first',
        value: '-updated_at'
      }, {
        label: 'Updated At: Older first',
        value: 'updated_at'
      }]);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "limit", 32);

      _defineProperty(this, "orderNumber", undefined);

      _initializerDefineProperty(this, "boutique", _descriptor, this);

      _initializerDefineProperty(this, "sort", _descriptor2, this);
    }

    get selectedSort() {
      return this.availableSort.find(sortItem => sortItem.value === this.sort);
    }

    get selectedBoutique() {
      if (!this.boutique) {
        return null;
      }

      return _boutique.default.findById(this.boutique);
    }

    onChangeOrderNumber(value) {
      const orderNumber = Ember.isPresent(value) ? value : undefined;
      return this.transitionToRoute({
        queryParams: {
          orderNumber
        }
      });
    }

    *searchBoutiques(searchQuery) {
      yield (0, _emberConcurrency.timeout)(300);

      if (!searchQuery) {
        return null;
      }

      const query = {
        name: {
          $regex: `^${searchQuery}`,
          $options: 'i'
        },
        limit: 10
      };
      const boutiques = yield _boutique.default.find(query);
      return boutiques;
    }

    selectBoutique(boutique) {
      return this.transitionToRoute({
        queryParams: {
          boutique: boutique === null || boutique === void 0 ? void 0 : boutique._id,
          page: 1
        }
      });
    }

    selectSort(sort) {
      return this.transitionToRoute({
        queryParams: {
          sort: sort.value
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "boutique", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sort", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.availableSort[0].value;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onChangeOrderNumber", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeOrderNumber"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchBoutiques", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchBoutiques"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectBoutique", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectBoutique"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectSort", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "selectSort"), _class.prototype)), _class));
  _exports.default = PackagingOrdersIndexController;
});