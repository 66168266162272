define("backalley/enums/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    toKeyValue() {
      return Object.keys(this).map(key => {
        return {
          key,
          value: this[key]
        };
      });
    },

    toValues() {
      return Object.keys(this).map(key => this[key]);
    }

  });

  _exports.default = _default;
});