define("backalley/pods/main/made/orders/index/controller", ["exports", "backalley/enums/channel"], function (_exports, _channel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MainMadeOrdersIndexController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, (_class = (_temp = class MainMadeOrdersIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['email', 'limit', 'name', 'page', 'sort']);

      _initializerDefineProperty(this, "email", _descriptor, this);

      _initializerDefineProperty(this, "limit", _descriptor2, this);

      _initializerDefineProperty(this, "name", _descriptor3, this);

      _initializerDefineProperty(this, "page", _descriptor4, this);

      _initializerDefineProperty(this, "sort", _descriptor5, this);

      _defineProperty(this, "dateFormat", 'ddd[,] D MMM YY');

      _defineProperty(this, "channel", _channel.default.MADE_DOT_COM);

      _defineProperty(this, "sortOptions", [{
        label: 'Order Number: Descending',
        value: '-ordernumber'
      }, {
        label: 'Order Number: Ascending',
        value: 'ordernumber'
      }, {
        label: 'Updated At: Newer first',
        value: '-updated_at'
      }, {
        label: 'Updated At: Older first',
        value: 'updated_at'
      }]);
    }

    get selectedSort() {
      return this.sortOptions.find(({
        value
      }) => this.sort === value);
    }

    onChangeSort({
      value
    }) {
      this.sort = value;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "email", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 24;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sort", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '-ordernumber';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onChangeSort", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeSort"), _class.prototype)), _class));
  _exports.default = MainMadeOrdersIndexController;
});