define("backalley/pods/components/price-with-currency/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XmoIMCzH",
    "block": "{\"symbols\":[\"HotLoadPowerSelectqio21ts131j\",\"option\"],\"statements\":[[4,\"if\",[[24,[\"disabled\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[28,\"hot-load\",[\"format-price\",[23,0,[]],[24,[\"format-price\"]],\"format-price\"],null],[24,[\"amount\"]],[24,[\"currency\"]]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"format-price\",[24,[\"format-price\"]],true,false]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"input\",[],[[\"@class\",\"@type\",\"@value\",\"@input\"],[\"input\",\"number\",[22,\"amountInPences\"],[28,\"action\",[[23,0,[]],\"amountChanged\"],null]]]],[0,\"\\n\\n  \"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"PowerSelect\",[23,0,[]],\"PowerSelect\",\"PowerSelect\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"PowerSelect\",\"PowerSelect\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[],[[\"@placeholder\",\"@options\",\"@onChange\"],[[22,\"currency\"],[22,\"currencyOptions\"],[28,\"action\",[[23,0,[]],\"selectCurrency\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[23,2,[\"iso_4217\"]],false],[0,\"\\n  \"]],\"parameters\":[2]}]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/price-with-currency/template.hbs"
    }
  });

  _exports.default = _default;
});