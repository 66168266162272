define("backalley/pods/components/form-checkbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['form-checkbox'],
    checked: Ember.computed('value', function () {
      return this.value;
    }),
    change: Ember.observer('value', function () {
      this.sendAction('action', this.checked);
    })
  });

  _exports.default = _default;
});