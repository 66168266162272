define("backalley/pods/main/quotes/edit/route", ["exports", "backalley/models/quote"], function (_exports, _quote) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      quote_id: id
    }) {
      return _quote.default.findById(id);
    },

    actions: {
      update(quote) {
        const newQuote = _quote.default.create({
          _id: quote._id,
          comment: quote.comment,
          published: quote.published,
          about_us: quote.about_us,
          created_at: quote.created_at
        });

        if (quote.url) {
          newQuote.url = quote.url;
        }

        return newQuote.save().then(() => this.transitionTo('main.quotes'), err => this.notify.show('err', err.message));
      }

    }
  });

  _exports.default = _default;
});