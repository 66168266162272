define("backalley/pods/components/frame-form/spotify/component", ["exports", "ember-component-css/pod-names"], function (_exports, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FrameFormSpotifyComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class FrameFormSpotifyComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isModalOpen", _descriptor, this);

      _initializerDefineProperty(this, "url", _descriptor2, this);
    }

    didInsertElement() {
      super.didInsertElement(...arguments);
      this.url = this.music ? this.music.url : '';
    }

    get styleNamespace() {
      return _podNames.default['frame-form/spotify'];
    }

    get generateEmbedUrl() {
      if (this.url.includes('/embed/')) {
        return this.url;
      }

      const spotifyDomain = 'https://open.spotify.com/';
      const [, track] = this.url.split('https://open.spotify.com/');
      return `${spotifyDomain}embed/${track}`;
    }

    saveUrl() {
      const payload = this.url ? {
        type: 'spotify',
        url: this.generateEmbedUrl
      } : {};
      this.onUpdateMusic(payload);
      this.closeModal();
    }

    clearUrl() {
      this.url = null;
      this.onUpdateMusic({});
    }

    closeModal() {
      this.isModalOpen = false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isModalOpen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "saveUrl", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "saveUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearUrl", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "clearUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class));
  _exports.default = FrameFormSpotifyComponent;
});