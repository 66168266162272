define("backalley/pods/main/manufacturers/edit/route", ["exports", "backalley/models/manufacturer"], function (_exports, _manufacturer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      manufacturer_id
    }) {
      return _manufacturer.default.findById(manufacturer_id).then(manufacturer => {
        return {
          manufacturer,
          user: this.session.user,
          googleTerm: `${manufacturer.name}`,
          imageFolder: `manufacturer/${manufacturer._id}`,
          alternate: '',
          alternateValidationError: ''
        };
      });
    },

    actions: {
      setStatus(manufacturer, status) {
        Ember.set(manufacturer, 'status', status.value);
      },

      setEnhanced(manufacturer, status) {
        if (status.value !== 'disabled') {
          Ember.set(manufacturer, 'enhanced_visibility.0', {});
          Ember.set(manufacturer, 'enhanced_visibility.0.location', status.value);
        } else {
          Ember.set(manufacturer, 'enhanced_visibility', []);
        }
      },

      setImageEnhanced(manufacturer, file) {
        Ember.set(manufacturer, 'enhanced_visibility.0.cover_image_url', file.url);
      },

      update(manufacturer) {
        return manufacturer.save().then(() => {
          this.transitionTo('main.manufacturers.index');
        }).catch(message => {
          this.notify.show('error', message, 5000);
        });
      },

      goBack() {
        this.transitionTo('main.manufacturers');
      },

      removeAlternate(manufacturer, alternate) {
        manufacturer.get('alternate_names').removeObject(alternate);
      },

      addAlternate(manufacturer, alternate = '') {
        if (alternate.length < 3) return this.set('currentModel.alternateValidationError', 'Must be at least 3 characters');
        manufacturer.get('alternate_names').addObject(alternate);
        this.set('currentModel.alternate', '');
        this.set('currentModel.alternateValidationError', '');
      }

    }
  });

  _exports.default = _default;
});