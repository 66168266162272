define("backalley/pods/main/quotes/index/route", ["exports", "backalley/mixins/loading", "backalley/models/quote", "backalley/config/environment", "backalley/util/escape-regex"], function (_exports, _loading, _quote, _environment, _escapeRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_loading.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      is_about_us: {
        refreshModel: true
      },
      has_entity: {
        refreshModel: true
      }
    },
    availableFilters: [{
      label: 'Date: Last updated',
      value: '-updated_at'
    }, {
      label: 'Date: First updated',
      value: 'updated_at'
    }, {
      label: 'Date: Last created',
      value: '-created_at'
    }, {
      label: 'Date: First created',
      value: 'created_at'
    }],
    availableEntities: [{
      label: 'Entity: Show all',
      value: 'all'
    }, {
      label: 'Entity: Publisher',
      value: 'publisher'
    }, {
      label: 'Entity: Boutique',
      value: 'boutique'
    }, {
      label: 'Entity: Manufacturer',
      value: 'manufacturer'
    }, {
      label: 'Entity: Product',
      value: 'product'
    }],
    isAboutUs: [{
      label: 'About us: Show only quotes about us',
      value: true
    }, {
      label: 'About us: Show all',
      value: false
    }],

    serializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      // don't show objects in url
      if (urlKey === 'sort') {
        return value.value;
      }

      if (urlKey === 'is_about_us') {
        return value.value;
      }

      if (urlKey === 'has_entity') {
        return value.value;
      }

      if (urlKey === 'page') return Number(value);
      return value;
    },

    deserializeQueryParam(value = {}, urlKey = '', defaultValueType = '') {
      if (urlKey === 'sort') return this.availableFilters.findBy('value', value);
      if (urlKey === 'is_about_us') return this.isAboutUs.findBy('value', value);
      if (urlKey === 'has_entity') return this.availableEntities.findBy('value', value);
      return value;
    },

    model({
      q,
      page = 1,
      limit = 30,
      sort = {},
      is_about_us = {},
      has_entity = {}
    }) {
      ;
      let query = {
        page,
        limit,
        sort: sort.value,
        populate: 'material,manufacturer,product,boutique,publisher'
      };

      if (q) {
        query.comment = {
          $regex: `${(0, _escapeRegex.default)(q)}`,
          $options: 'i'
        };
      }

      if (is_about_us.value) {
        query.about_us = true;
      }

      if (has_entity.value && has_entity.value !== 'all') {
        query[has_entity.value] = {
          $exists: true
        };
      }

      return _quote.default.findWithMeta(query).then(({
        quotes,
        meta = {
          total: 3
        }
      }) => {
        return {
          search: q,
          quotes,
          total: Math.ceil(meta.total / limit),
          sort,
          is_about_us,
          has_entity,
          user: this.session.user,
          availableFilters: this.availableFilters,
          availableEntities: this.availableEntities,
          isAboutUs: this.isAboutUs
        };
      });
    },

    actions: {
      goToPage,
      nextPage,
      prevPage,
      updateSearch,
      updateAboutUs,
      updateEntity,
      updateFilter,
      remove
    }
  });

  _exports.default = _default;

  function goToPage(page) {
    this.transitionTo({
      queryParams: {
        page: page
      }
    });
  }

  function nextPage() {
    const page = this.get('controller.page');
    if (page < this.get('currentModel.total')) this.transitionTo({
      queryParams: {
        page: Number(page) + 1
      }
    });
  }

  function prevPage() {
    const page = this.get('controller.page');
    if (page > 1) this.transitionTo({
      queryParams: {
        page: Number(page) - 1
      }
    });
  }

  function updateSearch(q) {
    this.set('controller.q', q);
    this.set('controller.page', 1);
  }

  function updateAboutUs(about) {
    this.set('controller.is_about_us', about);
    this.set('controller.page', 1);
  }

  function updateEntity(entity) {
    this.set('controller.has_entity', entity);
    this.set('controller.page', 1);
  }

  function updateFilter(filter) {
    this.set('controller.sort', filter);
  }

  function remove(quote) {
    if (confirm(`Are you sure you want to delete this quote?`)) {
      quote.remove().then(() => {
        this.get('currentModel.quotes').removeObject(quote);
      });
    }
  }
});