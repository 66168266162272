define("backalley/pods/main/paths-and-lanes/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['pathPage', 'lanePage', 'limit'],
    limit: 5,
    pathPage: 1,
    lanePage: 1,
    nextPathPage: Ember.computed('model.shippingPath.meta.{page,page_total}', function () {
      return Math.min(this.get('model.shippingPath.meta.page') + 1, this.get('model.shippingPath.meta.page_total'));
    }),
    nextLanePage: Ember.computed('model.carrierLane.meta.{page,page_total}', function () {
      return Math.min(this.get('model.carrierLane.meta.page') + 1, this.get('model.carrierLane.meta.page_total'));
    }),
    prevPathPage: Ember.computed('model.shippingPath.meta.{page,page_total}', function () {
      return Math.max(this.get('model.shippingPath.meta.page') - 1, 0);
    }),
    prevLanePage: Ember.computed('model.carrierLane.meta.{page,page_total}', function () {
      return Math.max(this.get('model.carrierLane.meta.page') - 1, 0);
    }),
    actions: {
      setPathPage(page) {
        return this.transitionToRoute({
          queryParams: {
            pathPage: page
          }
        });
      },

      setLanePage(page) {
        return this.transitionToRoute({
          queryParams: {
            lanePage: page
          }
        });
      }

    }
  });

  _exports.default = _default;
});