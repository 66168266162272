define("backalley/models/stock-line", ["exports", "joi", "backalley/util/request"], function (_exports, _joi, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class StockLine {
    constructor({
      _id,
      sku,
      size
    }) {
      this._id = _id;
      this.sku = sku;
      this.size = size;
    }

    static find(params = {}) {
      return (0, _request.request)({
        method: 'GET',
        url: (0, _request.apiURL)('stock_lines'),
        data: params
      }).then(({
        stock_lines,
        meta
      }) => {
        return {
          stockLines: stock_lines.map(stockLine => new StockLine(stockLine)),
          meta: Ember.isPresent(meta) ? meta : {
            total: 0,
            page_total: 0,
            page: 0
          }
        };
      });
    }

  }

  _exports.default = StockLine;

  _defineProperty(StockLine, "schema", _joi.default.object().keys({
    sku: _joi.default.string()
  }));
});