define("backalley/pods/main/frames/new/route", ["exports", "backalley/models/frame"], function (_exports, _frame) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MainFramesEditRoute extends Ember.Route {
    async model() {
      try {
        let data = {};
        return new _frame.default(data);
      } catch (e) {
        return this.transitionTo('main.frame.index');
      }
    }

  }

  _exports.default = MainFramesEditRoute;
});