define("backalley/pods/main/frames/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let MainFrameTagsIndexController = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class MainFrameTagsIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['page', 'limit', 'sort', 'name', 'published', 'archived', 'maintainedBy']);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "limit", 24);

      _defineProperty(this, "sort", '-created_at');

      _defineProperty(this, "maintainedBy", undefined);

      _defineProperty(this, "availableSort", [{
        label: 'Date: Last created',
        value: '-created_at'
      }, {
        label: 'Date: First created',
        value: 'created_at'
      }, {
        label: 'Date: Last updated',
        value: '-updated_at'
      }, {
        label: 'Date: First updated',
        value: 'updated_at'
      }, {
        label: 'Name: A-Z',
        value: '-name'
      }, {
        label: 'Name: Z-A',
        value: 'name'
      }]);

      _defineProperty(this, "availablePublished", [{
        label: 'Published: All',
        value: undefined
      }, {
        label: 'Published: Only',
        value: true
      }, {
        label: 'Published: None',
        value: false
      }]);

      _defineProperty(this, "availableArchived", [{
        label: 'Archived: All',
        value: undefined
      }, {
        label: 'Archived: Only',
        value: true
      }, {
        label: 'Archived: None',
        value: false
      }]);
    }

    onChangeName(name) {
      return this.transitionToRoute({
        queryParams: {
          page: 1,
          name
        }
      });
    }

    onMaintainedBy(type, value) {
      const maintainedBy = value ? type : undefined;
      return this.transitionToRoute({
        queryParams: {
          maintainedBy
        }
      });
    }

    selectSort(sort) {
      return this.transitionToRoute({
        queryParams: {
          sort: sort.value
        }
      });
    }

    selectPublished(published) {
      return this.transitionToRoute({
        queryParams: {
          published: published.value
        }
      });
    }

    selectArchived(archived) {
      return this.transitionToRoute({
        queryParams: {
          archived: archived.value
        }
      });
    }

    async publish(frame) {
      try {
        await frame.publish();
        this.notify.show('success', 'Congrats! You new Frame was successfully published 🎉<br />Share it with your customers and Instagram followers by tapping the share button below.', 7500);
      } catch (e) {
        this.notify.show('error', 'Oops! We\'re unable to publish your Frame until all content has been provided. Please fill all the missing fields.', 7500);
      }
    }

    async archive(frame) {
      try {
        await frame.archive();
        this.notify.show('success', 'Your Frame was successfully archived!');
      } catch (e) {
        this.notify.show('error', 'Oops! An error occured while archiving your Frame.');
      }
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "onChangeName", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMaintainedBy", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onMaintainedBy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectSort", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "selectSort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectPublished", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectPublished"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectArchived", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "selectArchived"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "publish", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "publish"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "archive", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "archive"), _class.prototype)), _class));
  _exports.default = MainFrameTagsIndexController;
});