define("backalley/pods/components/order-quick-search/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "backalley/models/order", "backalley/enums/channel"], function (_exports, _component, _emberConcurrencyDecorators, _order, _channel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OrderQuickSearchComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class OrderQuickSearchComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "notify", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "query", _descriptor3, this);
    }

    *searchOrdersTask() {
      const orderReference = this.query.replace('#', '');
      const query = {
        'channel_specific.order_reference': orderReference
      };
      let {
        orders
      } = yield _order.default.find(query);
      const isFySoNumber = orderReference.startsWith('SO');

      if (!orders.length && !isFySoNumber) {
        ({
          orders
        } = yield _order.default.find({
          'ordernumber': orderReference
        }));
      }

      if (!orders.length) {
        return this.notify.show('danger', `Oops! #${orderReference} isn't a valid order reference.`);
      }

      if (this.args.channel === _channel.default.MADE_DOT_COM) {
        const [{
          channel_specific: {
            order_reference
          }
        }] = orders;
        return this.router.transitionTo('main.made.orders.edit', order_reference);
      }

      return this.router.transitionTo('main.orders.edit', orders[0]._id);
    }

    onPaste(event) {
      event.preventDefault();
      this.query = (event.clipboardData || window.clipboardData).getData('Text');
      return this.searchOrdersTask.perform();
    }

    searchOrders(event) {
      event.preventDefault();
      return this.searchOrdersTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "query", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "searchOrdersTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchOrdersTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onPaste", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onPaste"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchOrders", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "searchOrders"), _class.prototype)), _class));
  _exports.default = OrderQuickSearchComponent;
});